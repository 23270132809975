import { getAppConfig } from '@/config/config';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, ICellRendererParams, ModuleRegistry, StatusPanelDef } from '@ag-grid-community/core';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';

import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { Box, CircularProgress, Paper, Stack, Typography, useTheme } from '@mui/material';

import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-material.min.css';

// configure AG.GRID
import { getColumnTypes } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { CSSProperties, LegacyRef, ReactElement, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';

LicenseManager.setLicenseKey(getAppConfig().AG_GRID.LICENCE_KEY);
ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, ExcelExportModule]);

export type AgGridWrapperProps<TData> = AgGridReactProps<TData> & {
    compact?: boolean;
    gridId?: string;
    initRef?: (node: AgGridReact<TData>) => void;
    disableAutoSize?: boolean;
    toolbarActions?: ReactElement;
    loading: boolean;
};

export function AgGridWrapper<TData>({
    initRef,
    gridId,
    defaultColDef,
    compact = true,
    columnDefs,
    rowData,
    onRowClicked,
    excelStyles,
    disableAutoSize = false,
    defaultExcelExportParams,
    toolbarActions,
    selectionColumnDef,
    loading,
    ...rest
}: AgGridWrapperProps<TData>): ReactElement {
    const { t } = useTranslation();
    const gridRef = useRef<AgGridReact<TData>>();

    const theme = useTheme();

    const defaultVars = {
        '--ag-font-family': 'Inter',
        '--ag-header-foreground-color': theme.palette.text.primary,
        '--ag-header-background-color': theme.palette.common.white,
        '--ag-header-cell-hover-background-color': theme.palette.common.white,
        '--ag-header-column-separator-color': 'transparent',
        '--ag-header-column-separator-height': '50%',
        '--ag-header-column-separator-display': 'block',
        '--ag-header-column-resize-handle-width': '10px',
        '--ag-header-column-resize-handle-color': 'transparent',
        '--ag-header-column-resize-handle-display': 'block',
        '--ag-border-radius': '8px',
        '--ag-border-color': theme.palette.grey[300],
        '--ag-row-border-style': 'solid',
        '--ag-row-border-width': '1px',
        '--ag-cell-horizontal-padding': '8px',

        '--ag-checkbox-background-color': theme.palette.common.white,
        '--ag-checkbox-checked-color': theme.palette.primary.main,
        '--ag-checkbox-unchecked-color': theme.palette.grey[700],
        '--ag-checkbox-indeterminate-color': theme.palette.grey[700],
        '--ag-row-hover-color': theme.palette.grey[100],

        ...((!compact
            ? {
                  '--ag-borders-critical': 'none',
              }
            : ({
                  '--ag-cell-horizontal-border': '1px solid ' + theme.palette.grey[300],
              } as CSSProperties)) as CSSProperties),
    } as CSSProperties;

    // Use to compact the grid
    useEffect(() => {
        if (compact) {
            const el = document.querySelector<HTMLElement>('.ag-theme-material');
            el?.classList.toggle('compact');
        }
    }, [compact]);

    const getColumnDefs = (): ColDef[] => {
        return (columnDefs as ColDef[]) || [];
    };

    // Display a footer with the total number of rows
    const statusBar = useMemo<{
        statusPanels: StatusPanelDef[];
    }>(() => {
        return {
            statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }],
        };
    }, []);

    // Ag grid wrapper is rerendered when the row is selected due to internal state change in hook useAgGridWrapper
    const hasSelectedRows = gridRef.current?.api?.getSelectedRows()?.length;

    return (
        <Stack direction='row' height='100%' position='relative'>
            <Box
                style={{
                    overflow: 'hidden',
                    flexGrow: '1',
                }}
            >
                {/* This box is needed to make the grid full height when the parent is flex
                https://github.com/ag-grid/ag-grid/issues/628#issuecomment-182278186
            */}
                <Box height='100%' position={'relative'}>
                    <Box position={'absolute'} sx={{ inset: 0 }} className='ag-theme-material' style={defaultVars}>
                        <AgGridReact<TData>
                            gridId={gridId}
                            // TODO Fix this type
                            ref={gridRef as LegacyRef<AgGridReact<TData>>}
                            // TODO: This property should be use only in specific cases, so we should remove it from the default props
                            suppressRowTransform={true}
                            getLocaleText={params => t(params.key, { ns: 'ag_grid' })}
                            suppressCellFocus
                            suppressCsvExport
                            // Hide the column when dragging it out of the grid
                            suppressDragLeaveHidesColumns
                            suppressContextMenu
                            // This is hack to fix autosize all issue https://www.ag-grid.com/javascript-data-grid/column-sizing/#auto-size-columns
                            suppressColumnVirtualisation
                            enableBrowserTooltips
                            enableCellTextSelection
                            paginationAutoPageSize={false}
                            pagination={false}
                            statusBar={statusBar}
                            animateRows={true}
                            cacheQuickFilter={true}
                            defaultColDef={{
                                rowDrag: false,
                                resizable: true,
                                sortable: true,
                                useValueFormatterForExport: true,
                                menuTabs: [],
                                autoHeight: true,
                                ...defaultColDef,
                            }}
                            columnDefs={getColumnDefs()}
                            rowData={rowData}
                            // To avoid frustration, we disable the row click/navigation when there are selected rows
                            onRowClicked={hasSelectedRows ? undefined : onRowClicked}
                            rowStyle={!hasSelectedRows && onRowClicked ? { cursor: 'pointer' } : {}}
                            selectionColumnDef={{
                                resizable: false,
                                sortable: false,
                                pinned: 'left',
                                lockPinned: true,
                                suppressSizeToFit: true,
                                suppressAutoSize: true,
                                maxWidth: 34,

                                ...selectionColumnDef,
                            }}
                            columnTypes={getColumnTypes(compact) as Record<string, ColDef<TData>>}
                            accentedSort={true}
                            excelStyles={[
                                {
                                    id: 'dateCH',
                                    dataType: 'DateTime',
                                    numberFormat: {
                                        format: getAppConfig().DEFAULT_DATE_FORMAT,
                                    },
                                },
                                ...(excelStyles ?? []),
                            ]}
                            onFirstDataRendered={params => {
                                if (disableAutoSize) {
                                    return;
                                }
                                // In Roger by default we want to auto size all columns
                                // Info 1 : New visible columns are not resized event if we call autoSizeAllColumns on onColumnVisible event
                                params.api.autoSizeAllColumns();
                            }}
                            onGridSizeChanged={params => {
                                if (disableAutoSize) {
                                    return;
                                }
                                params.api.autoSizeAllColumns();
                            }}
                            onGridReady={() => {
                                if (gridRef?.current) {
                                    initRef?.(gridRef?.current);
                                }
                            }}
                            defaultExcelExportParams={{
                                author: 'Roger HR',
                                ...defaultExcelExportParams,
                            }}
                            loading={loading}
                            loadingOverlayComponent={LoadingOverlayComponent}
                            {...rest}
                        />
                    </Box>
                </Box>
            </Box>
            {!!hasSelectedRows && !!toolbarActions && (
                <Stack id='grid-actions-menu' zIndex={8} direction='row' justifyContent='center' position='absolute' left={0} right={0} bottom={32}>
                    <Stack component={Paper} p={2} gap={2} direction='row' elevation={1} alignItems='center' bgcolor={theme => theme.palette.grey[100]}>
                        <Typography variant='body1bold' sx={{ fontVariantNumeric: 'tabular-nums' }}>
                            {t('grid.selected_rows', { count: hasSelectedRows })}
                        </Typography>
                        {toolbarActions}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}

// TODO Add definition file to reinforce the ColDef type
// https://github.com/ag-grid/ag-grid/issues/6464
export type RogerColDef<TData = unknown> = Omit<ColDef<TData>, 'cellRenderer' | 'cellRendererParams'> & {
    cellRenderer?: string | ((params: ICellRendererParams<TData>) => ColDef['cellRenderer']);
    cellRendererParams?: (params: ICellRendererParams<TData>) => ColDef['cellRendererParams'];
};

export type AgGridWrapperColumnType = keyof ReturnType<typeof getColumnTypes>;

// We don't want to spread params directly to CircularProgress, it will cause error in console log
const LoadingOverlayComponent = () => {
    return <CircularProgress />;
};
