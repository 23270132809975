import { IconButtonProps, PaperProps, PopperProps, SxProps, Theme, useAutocomplete } from '@mui/material';
import { FC, JSXElementConstructor, PropsWithChildren, Ref } from 'react';

export const usePopperlessAutocomplete = (): {
    disablePortal: boolean;
    open: boolean;
    disableCloseOnSelect: boolean;
    disableClearable: boolean;
    PopperComponent: JSXElementConstructor<PopperProps>;
    slotProps?: {
        clearIndicator?: Partial<IconButtonProps>;
        paper?: PaperProps;
        popper?: Partial<PopperProps>;
        popupIndicator?: Partial<IconButtonProps>;
    };
    popupIcon: JSX.Element;
    renderTags: () => JSX.Element;
    ListboxProps: ReturnType<ReturnType<typeof useAutocomplete>['getListboxProps']> & {
        sx?: SxProps<Theme>;
        ref?: Ref<Element>;
    };
} => {
    return {
        // The autocomplete is in a Popper element, so disable the portal.
        disablePortal: true,
        // When the popper is open we want to display the options
        open: true,
        // When the popper is open we don't want to close it when the user selects an option
        disableCloseOnSelect: true,
        // By default autocomplete use a Popper element to display the options,
        // but we want to use our own Popper element
        // Input and options are in a Popper element
        PopperComponent: PopperlessContainer as JSXElementConstructor<PopperProps>,
        popupIcon: <></>,
        renderTags: () => <></>,
        disableClearable: true,
        slotProps: { paper: { sx: { mx: -1, mb: -1, borderTopRightRadius: 0 } } },
        ListboxProps: {
            sx: {
                '& .MuiAutocomplete-option[aria-selected="true"], & .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                    backgroundColor: 'transparent',
                },
            },
        },
    };
};

// eslint-disable-next-line react-refresh/only-export-components
const PopperlessContainer: FC<PropsWithChildren> = ({ children }) => children;
