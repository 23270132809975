import { Components, Theme } from '@mui/material';

export const MuiToggleButton: Components<Omit<Theme, 'components'>>['MuiToggleButton'] = {
    defaultProps: {
        size: 'small',
    },
    styleOverrides: {
        root: ({ theme }) => ({
            background: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            '&.Mui-selected': {
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
                '&:hover': {
                    background: theme.palette.primary.main,
                },
            },
            '&:hover': {
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
        }),
        sizeSmall: {
            padding: 4,
        },
    },
};
