import { API_BASE_URL, client } from '@/api/common';
import { ObjectiveHistory } from '@/domain/objective-history/ObjectiveHistory.model';
import { mapObjectiveDTO, mapObjectiveStatusUpdateDTO, ObjectiveDTO, ObjectiveStatusUpdateDTO } from '@/api/objective/Objective.api';
import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import { DepartmentDTO } from '@/api/department/Department.api';
import { ObjectiveCompletionStatusDTO } from '@/api/objective-completion-status/ObjectiveCompletionStatus.api';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

export type ObjectiveHistoryDTO = Overwrite<
    DateToString<ObjectiveHistory>,
    {
        assignee: EmployeeAvatarDTO;
        objective: ObjectiveDTO;
        department: DepartmentDTO;
        parent: ObjectiveDTO | undefined;
        lastStatusUpdate: ObjectiveStatusUpdateDTO | undefined;
        completionStatus: ObjectiveCompletionStatusDTO | undefined;
        createdBy: EmployeeAvatarDTO;
    }
>;

const mapObjectiveHistoryDTO = (dto: ObjectiveHistoryDTO[]): ObjectiveHistory[] => {
    return dto.map(dto => {
        return {
            ...dto,
            objective: mapObjectiveDTO(dto.objective),
            parent: dto.parent ? mapObjectiveDTO(dto.parent) : undefined,
            lastStatusUpdate: dto.lastStatusUpdate ? mapObjectiveStatusUpdateDTO(dto.lastStatusUpdate) : undefined,
            completedAt: convertUTCIsoStringToDate(dto.completedAt),
            createdAt: convertUTCIsoStringToDate(dto.createdAt),
        };
    });
};

const getObjectiveHistories = async (objectiveId: number): Promise<ObjectiveHistory[]> => {
    const { data } = await client.get<ObjectiveHistoryDTO[]>(API_BASE_URL + `/objective-histories/objective/${objectiveId}`);
    return mapObjectiveHistoryDTO(data);
};

export const objectiveHistoryAPI = {
    getObjectiveHistories: getObjectiveHistories,
};
