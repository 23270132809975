import { FC, PropsWithChildren } from 'react';
import { Button, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StepperBreadCrumb } from '@/components/stepper-breadcrumb/StepperBreadcrumb';
import { StepperValues } from '@/components/stepper-form/StepperForm.util';
import { useSideBar } from '@/hooks/layout/Sidebar.hook';

export type StepForm = {
    stepComponent: JSX.Element;
    stepName: string;
    stepTranslationKey: string;
};
type StepperFormProps = {
    steps: StepForm[];
    useStepperValues: StepperValues;
    onNextStep: () => void;
    onSave: () => void;
};

/**
 * The StepperForm component is a wrapper component that allows you to create a multi-step form on a single page.
 * @param steps is an array of Step objects that define the steps in the stepper.
 * @param useStepperValues is a hook that provides the current step, next step, and previous step functions, as well as other values.
 * @param onNextStep is a callback function that is called when the user clicks the next button.
 * @param onSave is a callback function that is called when the user clicks the save button.
 * @param children is an optional prop that allows you to add custom buttons or components to the stepper.
 */
export const StepperForm: FC<PropsWithChildren<StepperFormProps>> = ({ steps, useStepperValues, onNextStep, onSave, children }) => {
    const { currentStep, onPrevStep, nextStepDisabled, prevStepDisabled, showBackButton, showNextButton, showSaveButton } = useStepperValues;
    const { t } = useTranslation();
    const { drawerWidth } = useSideBar();

    return (
        <>
            <Stack
                overflow={'auto'}
                flex={1}
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
                mb={2}
            >
                {currentStep?.stepComponent}
            </Stack>

            <Paper
                square
                component='footer'
                sx={{
                    mb: -2,
                    mx: -2,
                    width: `calc(100vw - ${drawerWidth}px)`,
                }}
            >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} p={2}>
                    <StepperBreadCrumb steps={steps} currentStep={currentStep} />
                    <Stack direction={'row'} gap={1}>
                        {children}

                        {showBackButton && (
                            <Button variant={'outlined'} onClick={() => onPrevStep()} disabled={prevStepDisabled}>
                                {t('stepper.back')}
                            </Button>
                        )}
                        {showNextButton && (
                            <Button onClick={() => onNextStep()} disabled={nextStepDisabled}>
                                {t('stepper.next')}
                            </Button>
                        )}
                        {showSaveButton && <Button onClick={onSave}> {t('stepper.save')}</Button>}
                    </Stack>
                </Stack>
            </Paper>
        </>
    );
};
