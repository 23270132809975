import { Checkbox, FormControlLabel, FormControlLabelProps, FormHelperText, Stack } from '@mui/material';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { ReactNode } from 'react';
import { RemoveSquareIcon } from 'hugeicons-react';

export type FieldCheckboxProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    onChange?: (value: boolean) => void;
    differenceOnNotTouched?: boolean;
} & Omit<ControllerProps<TFieldValues, TName>, 'render'> &
    Omit<FormControlLabelProps, 'control' | 'label' | 'onChange'> & {
        label?: ReactNode;
    };

export const FieldCheckbox = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldCheckboxProps<TFieldValues, TName>,
): JSX.Element => {
    const { disabled, onChange, label, labelPlacement, differenceOnNotTouched = false, ...controllerProps } = props;
    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState }) => (
                <Stack>
                    <FormControlLabel
                        name={field.name}
                        labelPlacement={labelPlacement}
                        label={label ?? ''}
                        control={
                            <Checkbox
                                checked={field.value ?? false}
                                //use isDirty and isTouched to determine if the field has been interacted with or not (isTouched is only updated onBlur so we need the isDirty check as well)
                                icon={differenceOnNotTouched && !fieldState.isDirty && !fieldState.isTouched ? <RemoveSquareIcon size={20} /> : undefined}
                                {...field}
                                onChange={(e, checked) => {
                                    field.onChange(e, checked);
                                    onChange?.(checked);
                                }}
                                inputProps={{
                                    'aria-label': controllerProps.name,
                                }}
                                disabled={disabled}
                            />
                        }
                    />

                    {!!fieldState.error && (
                        <FormHelperText sx={{ pl: 2 }} error>
                            {fieldState?.error?.message}
                        </FormHelperText>
                    )}
                </Stack>
            )}
        />
    );
};
