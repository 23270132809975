import { RecursiveValue } from '@/components/tree-select/TreeSelect';

export const findItemById = (items: RecursiveValue[], itemId: number): RecursiveValue | undefined => {
    for (const item of items) {
        if (item.id === itemId) {
            return item;
        }
        if (item.children.length > 0) {
            const found = findItemById(item.children, itemId);
            if (found) {
                return found;
            }
        }
    }
    return undefined;
};

export const findParents = (items: RecursiveValue[], itemId: number): RecursiveValue[] => {
    return items.reduce<RecursiveValue[]>((acc, currentValue) => {
        if (currentValue.children.some(child => child.id === itemId)) {
            return [currentValue, ...acc];
        }
        if (currentValue.children.length > 0) {
            const found = findParents(currentValue.children, itemId);
            if (found.length > 0) {
                return [currentValue, ...found];
            }
        }
        return acc;
    }, []);
};

export const findParent = (items: RecursiveValue[], itemId: number): RecursiveValue | undefined => {
    for (const item of items) {
        if (item.children.some(child => child.id === itemId)) {
            return item;
        }
        const found = findParent(item.children, itemId);
        if (found) {
            return found;
        }
    }
    return undefined;
};
