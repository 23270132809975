import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { getTableHeight } from '@/components/ag-grid-wrapper/AgGridWrapper.util';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getAppConfig } from '@/config/config';
import { ThirdPartyIntegrationLog } from '@/domain/third-party/ThirdParty.model';
import { useGetThirdPartyIntegrationLogs } from '@/hooks/third-party/ThirdParty.hook';
import { ThirdPartyConnector } from '@/page/setting/third-party/third-party-setting-page/ThirdPartySettingPage';
import { formatDate } from '@/utils/datetime.util';
import { DialogContent, Paper } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type ThirdPartyConnectorLogDialogProps = DialogWrapperProps & {
    connector?: ThirdPartyConnector;
};

export const ThirdPartyConnectorLogDialog: FC<ThirdPartyConnectorLogDialogProps> = ({ connector, ...rest }) => {
    const { t } = useTranslation();
    const config = getAppConfig();
    const thirdPartyIntegrationId = connector?.id ?? undefined;
    const { data: thirdPartyIntegrationLogs = [], isLoading, error, isError } = useGetThirdPartyIntegrationLogs(thirdPartyIntegrationId);

    const columnDefs: RogerColDef<ThirdPartyIntegrationLog>[] = [
        {
            field: 'eventType',
            headerName: t('third_party.connector_logs.event_type'),
        },
        {
            field: 'messageId',
            headerName: t('third_party.connector_logs.message_id'),
            hide: true,
        },
        {
            field: 'message',
            headerName: t('third_party.connector_logs.message'),
            maxWidth: 500,
            wrapText: true,
            autoHeight: true,
        },
        {
            field: 'errorMessage',
            headerName: t('third_party.connector_logs.error_message'),
            maxWidth: 500,
            wrapText: true,
            autoHeight: true,
        },
        {
            field: 'publishedAt',
            headerName: t('third_party.connector_logs.published_at'),
            valueFormatter: ({ value }) => formatDate(value, config.DEFAULT_DATE_HOURS_SECONDS_FORMAT),
        },
        {
            field: 'processedAt',
            headerName: t('third_party.connector_logs.processed_at'),
            valueFormatter: ({ value }) => formatDate(value, config.DEFAULT_DATE_HOURS_SECONDS_FORMAT),
        },
    ];

    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<ThirdPartyIntegrationLog>();

    const tableHeight = getTableHeight({ rowsLength: thirdPartyIntegrationLogs.length, disableFooter: true });

    const handleDownloadData = () => {
        gridRef.current?.api?.exportDataAsExcel();
    };

    return (
        <DialogWrapper header={`${t('third_party.connector_logs.dialog_title')}`} fullScreen {...rest}>
            <Stack component={DialogContent} minHeight={100} height={tableHeight}>
                <Stack gap={2} flex={1}>
                    <Stack component={Paper} gap={1} p={1} direction='row-reverse' justifyContent='space-between' alignItems='center'>
                        <DatatableAdditionalAction quickFilter={quickFilter} onBtnExport={handleDownloadData} disabled={!thirdPartyIntegrationLogs.length} />
                    </Stack>
                    <StateHandler isLoading={isLoading} isError={isError} error={error} isEmpty={!thirdPartyIntegrationLogs.length}>
                        <Stack flex={1}>
                            <AgGridWrapper rowData={thirdPartyIntegrationLogs} columnDefs={columnDefs} initRef={setGridRef} loading={false} />
                        </Stack>
                    </StateHandler>
                </Stack>
            </Stack>
        </DialogWrapper>
    );
};
