import * as yup from 'yup';
import { hoursMinutesSchema } from '@/utils/datetime.schema';

export const getBulkAdjustmentFormSchema = () => {
    return yup
        .object()
        .shape({
            hoursMinutes: hoursMinutesSchema.required(),
            comment: yup.string().default(''),
            effectiveDate: yup.string<LocalDate>().required(),
        })
        .required();
};

export type BulkAdjustmentFormSchema = yup.InferType<ReturnType<typeof getBulkAdjustmentFormSchema>>;
