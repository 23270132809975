import { FC, useState } from 'react';
import { Backdrop, BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

type ImageBoxProps = BoxProps & { src: string; alt: string; maxHeight?: string };
export const ImageBox: FC<ImageBoxProps> = ({ src, alt, maxHeight }) => {
    const [openPreview, setOpenPreview] = useState(false);
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'grey.100',
                    maxHeight: maxHeight ?? { xs: '500px', md: '700px' },
                    overflow: 'hidden',
                    borderRadius: 2,
                    cursor: 'pointer',
                }}
                onClick={() => setOpenPreview(true)}
            >
                <Box
                    component='img'
                    src={src}
                    alt={alt}
                    sx={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'contain',
                    }}
                />
            </Box>
            <Backdrop open={openPreview} onClick={() => setOpenPreview(false)} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
                <Box
                    component='img'
                    src={src}
                    alt={alt}
                    sx={{
                        maxHeight: '90%',
                        maxWidth: '90%',
                        objectFit: 'contain',
                    }}
                />
            </Backdrop>
        </>
    );
};
