import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Document, DocumentLink } from '@/domain/document/Document.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type DocumentLinkDialogProps = Omit<DialogWrapperProps, 'onSave'> & {
    document?: Document;
    onSave: (values: DocumentLink) => void;
};

const schema = yup.object().shape({
    name: yup.string().trim().required(),
    externalUrl: yup.string().trim().required(),
});

export const DocumentLinkDialog: FC<DocumentLinkDialogProps> = ({ onSave, document, ...rest }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{
        name: string;
        externalUrl: string;
    }>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: document?.name,
            externalUrl: document?.externalUrl,
        },
    });

    const handleSave = (values: DocumentLink) => {
        let url = values.externalUrl;
        if (!/^https?:\/\//i.test(values.externalUrl)) {
            url = 'https://' + url;
        }
        onSave({ ...values, externalUrl: url });
    };

    return (
        <DialogWrapper header={t('folders.add_link_dialog.title')} {...rest}>
            <Stack gap={1} component={DialogContent}>
                <FormControlLabel
                    label={t('folders.link_form.name')}
                    control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
                />
                <FormControlLabel
                    label={t('folders.link_form.url')}
                    control={<TextField fullWidth error={!!errors.name} helperText={errors.externalUrl?.message} {...register('externalUrl')} />}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave)}>{t('general.save')}</Button>
            </DialogActions>
        </DialogWrapper>
    );
};
