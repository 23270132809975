import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Paper, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { FC, Ref, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';

import {
    EmployeeReviewFeedbackQuestionFormType,
    EmployeeReviewFeedbackSkillQuestionFormType,
} from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import {
    EmployeeReviewSummaryFeedbackQuestionFormType,
    EmployeeReviewSummaryFeedbackSkillQuestionFormType,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { EmployeeReviewFeedbackFromPreparation } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewFeedbackFromPreparation';
import { EmployeeReviewFeedbackQuestion } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackQuestion';
import { useTranslation } from 'react-i18next';
import { hasAtLeastOneFeedback, isAtLeastOnePeerOrUpwardFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { ArrowDown01Icon, ArrowUp01Icon, ViewIcon, ViewOffIcon } from 'hugeicons-react';
import { getLabelTranslation } from '@/utils/language.util';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';

type EmployeeReviewFeedbackSummaryQuestionProps = {
    employeeReviewFeedbackSummaryQuestionForm: EmployeeReviewSummaryFeedbackQuestionFormType | EmployeeReviewSummaryFeedbackSkillQuestionFormType;
    onUpdate: (item: EmployeeReviewFeedbackQuestionFormType | EmployeeReviewFeedbackSkillQuestionFormType) => void;
    innerRef: Ref<HTMLDivElement>;
    disabled: boolean;
    error: FieldError | undefined;
    expandAll: boolean;
} & StackProps;

export const EmployeeReviewFeedbackSummaryQuestion: FC<EmployeeReviewFeedbackSummaryQuestionProps> = ({
    innerRef,
    employeeReviewFeedbackSummaryQuestionForm,
    onUpdate,
    disabled,
    error,
    expandAll,
    ...rest
}) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const theme = useTheme();
    const [showPeersUpwards, setShowPeersUpwards] = useState(false);

    useEffect(() => {
        setExpanded(expandAll);
    }, [expandAll]);

    const getEmployeeReviewFeedbackSummaryQuestionFormTitle = (
        employeeReviewFeedbackSummaryQuestionForm: EmployeeReviewSummaryFeedbackQuestionFormType | EmployeeReviewSummaryFeedbackSkillQuestionFormType,
    ) => {
        {
            /* string for skill question, label for question */
        }
        return (
            (typeof employeeReviewFeedbackSummaryQuestionForm.title === 'string'
                ? employeeReviewFeedbackSummaryQuestionForm.title
                : getLabelTranslation(employeeReviewFeedbackSummaryQuestionForm.title)) + (employeeReviewFeedbackSummaryQuestionForm.required ? '*' : '')
        );
    };

    const getEmployeeReviewFeedbackSummaryInstruction = (
        employeeReviewFeedbackSummaryQuestionForm: EmployeeReviewSummaryFeedbackQuestionFormType | EmployeeReviewSummaryFeedbackSkillQuestionFormType,
    ) => {
        {
            /* string for skill question, label for question */
        }
        return typeof employeeReviewFeedbackSummaryQuestionForm.instruction === 'string'
            ? employeeReviewFeedbackSummaryQuestionForm.instruction
            : getLabelTranslation(employeeReviewFeedbackSummaryQuestionForm.instruction);
    };

    return (
        <Stack
            component={Paper}
            ref={innerRef}
            {...rest}
            sx={{
                border: error ? `1px solid ${theme.palette.error.main}` : 'none',
            }}
        >
            <Accordion expanded={expanded} disableGutters>
                <AccordionSummary onClick={() => setExpanded(!expanded)}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} flex={1}>
                        {employeeReviewFeedbackSummaryQuestionForm.title && (
                            <Typography variant='h2' sx={{ whiteSpace: 'pre-wrap' }}>
                                {getEmployeeReviewFeedbackSummaryQuestionFormTitle(employeeReviewFeedbackSummaryQuestionForm)}
                            </Typography>
                        )}

                        <IconButton disabled>{expanded ? <ArrowUp01Icon /> : <ArrowDown01Icon />}</IconButton>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 0 }}>
                    <Stack gap={1}>
                        {employeeReviewFeedbackSummaryQuestionForm.description && (
                            <RichTextTypography variant='body2'>{employeeReviewFeedbackSummaryQuestionForm.description}</RichTextTypography>
                        )}

                        {employeeReviewFeedbackSummaryQuestionForm.instruction && (
                            <RichTextTypography variant='body2'>
                                {getEmployeeReviewFeedbackSummaryInstruction(employeeReviewFeedbackSummaryQuestionForm)}
                            </RichTextTypography>
                        )}

                        {hasAtLeastOneFeedback(employeeReviewFeedbackSummaryQuestionForm) && (
                            <Stack gap={1} pb={1}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant='body1bold'>{t('reviews.summary.preparation_subtitle')}</Typography>
                                    {isAtLeastOnePeerOrUpwardFeedback(employeeReviewFeedbackSummaryQuestionForm) && (
                                        <Button
                                            onClick={() => {
                                                setShowPeersUpwards(prev => !prev);
                                            }}
                                            endIcon={showPeersUpwards ? <ViewOffIcon /> : <ViewIcon />}
                                            variant='text'
                                        >
                                            {t('reviews.summary.show_peers_upwards')}
                                        </Button>
                                    )}
                                </Stack>
                                {employeeReviewFeedbackSummaryQuestionForm.selfFeedbackQuestion && (
                                    <EmployeeReviewFeedbackFromPreparation
                                        employeeReviewFeedbackForm={employeeReviewFeedbackSummaryQuestionForm.selfFeedbackQuestion}
                                        ratingItems={employeeReviewFeedbackSummaryQuestionForm?.rating?.items ?? []}
                                    />
                                )}

                                {employeeReviewFeedbackSummaryQuestionForm.managerFeedbackQuestion?.map((managerFeedbackSkillQuestion, index) => (
                                    <EmployeeReviewFeedbackFromPreparation
                                        key={index}
                                        employeeReviewFeedbackForm={managerFeedbackSkillQuestion}
                                        ratingItems={employeeReviewFeedbackSummaryQuestionForm?.rating?.items ?? []}
                                    />
                                ))}

                                {showPeersUpwards &&
                                    employeeReviewFeedbackSummaryQuestionForm.upwardFeedbackQuestion?.map((upwardFeedbackSkillQuestion, index) => (
                                        <EmployeeReviewFeedbackFromPreparation
                                            key={index}
                                            employeeReviewFeedbackForm={upwardFeedbackSkillQuestion}
                                            ratingItems={employeeReviewFeedbackSummaryQuestionForm?.rating?.items ?? []}
                                        />
                                    ))}
                                {showPeersUpwards &&
                                    employeeReviewFeedbackSummaryQuestionForm.peerFeedbackQuestion?.map((peerFeedbackSkillQuestion, index) => (
                                        <EmployeeReviewFeedbackFromPreparation
                                            key={index}
                                            employeeReviewFeedbackForm={peerFeedbackSkillQuestion}
                                            ratingItems={employeeReviewFeedbackSummaryQuestionForm?.rating?.items ?? []}
                                        />
                                    ))}
                            </Stack>
                        )}

                        <Stack>
                            <Typography variant='body1bold'>{t('reviews.summary.summary_subtitle')}</Typography>

                            <EmployeeReviewFeedbackQuestion
                                sx={{
                                    pt: 0,
                                }}
                                questionItem={employeeReviewFeedbackSummaryQuestionForm}
                                onUpdate={onUpdate}
                                disabled={disabled}
                                // have to cast the error because the type of the error in the output of fieldState error is not the right one
                                error={
                                    error as {
                                        comment?: FieldError;
                                        score?: FieldError;
                                    }
                                }
                                innerRef={innerRef}
                                isSummary={true}
                            />
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};
