import { FC } from 'react';
import { EmployeeReviewFeedbackFormType } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { Stack, Typography, useTheme } from '@mui/material';
import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { RatingItemFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import {
    getRatingBackground,
    getRatingBackgroundOpacity,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewFeedbackFromPreparation.util';

type EmployeeReviewFeedbackFromPreparationProps = {
    employeeReviewFeedbackForm: EmployeeReviewFeedbackFormType;
    ratingItems: RatingItemFormType[];
};

export const EmployeeReviewFeedbackFromPreparation: FC<EmployeeReviewFeedbackFromPreparationProps> = ({ employeeReviewFeedbackForm, ratingItems }) => {
    const theme = useTheme();

    if (!employeeReviewFeedbackForm) {
        return <></>;
    }

    const ratingItem = (ratingItems ?? []).find(ratingItem => ratingItem && ratingItem.score === employeeReviewFeedbackForm.score);

    const getRatingTitleFontColor = (ratingItem: RatingItemFormType, ratingItems: RatingItemFormType[]) => {
        const backgroundOpacity = getRatingBackgroundOpacity(ratingItem, ratingItems);
        return backgroundOpacity > 0.65 ? theme.palette.common.white : theme.palette.text.primary;
    };

    return (
        <Stack direction={ratingItem?.label ? 'column' : 'row'} alignItems={ratingItem?.label ? 'flex-start' : 'center'} gap={ratingItem?.label ? 0.25 : 1}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <EmployeeAvatar employeeAvatar={employeeReviewFeedbackForm.reviewer} />
                {ratingItem?.label && ratingItems && (
                    <Stack p={0.5} px={1} width={250} bgcolor={getRatingBackground(ratingItem, ratingItems)} borderRadius={1}>
                        <Typography color={getRatingTitleFontColor(ratingItem, ratingItems)} variant='body1'>
                            {ratingItem.label}
                        </Typography>
                    </Stack>
                )}
            </Stack>
            <Typography variant='body1'>{employeeReviewFeedbackForm.comment}</Typography>
        </Stack>
    );
};
