import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import { Button, DialogActions, FormControlLabel } from '@mui/material';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { getAvailableStatuses, resetEmployeeReview } from '@/domain/employee-review/EmployeeReview.service';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { handleError } from '@/utils/api.util';

type EmployeeReviewChangeStatusDialogProps = DialogWrapperProps & {
    employeeReview: EmployeeReview;
};

export const EmployeeReviewChangeStatusDialog: FC<EmployeeReviewChangeStatusDialogProps> = ({ employeeReview, ...restDialog }) => {
    const { t } = useTranslation();

    const employeeReviewChangeStatusDialogFormSchema = yup.object().shape({
        step: yup.string().required().oneOf(getAvailableStatuses(employeeReview)),
    });

    type EmployeeReviewChangeStatusDialogForm = yup.InferType<typeof employeeReviewChangeStatusDialogFormSchema>;

    const { control, handleSubmit } = useForm<EmployeeReviewChangeStatusDialogForm>({
        resolver: yupResolver(employeeReviewChangeStatusDialogFormSchema),
    });

    const onSave = async (data: EmployeeReviewChangeStatusDialogForm, employeeReviewId: number) => {
        try {
            await resetEmployeeReview(employeeReviewId, data.step);
            restDialog.onClose();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <DialogWrapper header={t('reviews.change_status_dialog.title')} maxWidth={'sm'} {...restDialog}>
            <DialogContent>
                <FormControlLabel
                    label={t('reviews.change_status_dialog.new_step')}
                    sx={{ width: '100%' }}
                    control={
                        <FieldSelect
                            name='step'
                            control={control}
                            fullWidth
                            options={getAvailableStatuses(employeeReview)}
                            getOptionLabel={option =>
                                t('reviews.employee_review.status', {
                                    context: option,
                                })
                            }
                            getOptionKey={option => option}
                        />
                    }
                />
            </DialogContent>

            <DialogActions>
                <Button variant='contained' color='primary' onClick={handleSubmit(data => onSave(data, employeeReview.id), console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
