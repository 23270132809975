import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export type LoadingButtonProps = {
    loading?: boolean;
    loadingIndicator?: React.ReactNode;
    loadingPosition?: 'start' | 'end';
} & ButtonProps;

export const LoadingButton: FC<PropsWithChildren<LoadingButtonProps>> = ({
    children,
    disabled,
    loading = false,
    loadingIndicator: loadingIndicatorProp,
    loadingPosition = 'center',
    ...props
}) => {
    // size is set to 23 to match the size of button with text
    const loadingIndicator = loadingIndicatorProp ?? <CircularProgress color='inherit' size={23} />;
    return (
        <Button
            {...props}
            disabled={disabled || loading}
            startIcon={loading && loadingPosition === 'start' ? loadingIndicator : props.startIcon}
            endIcon={loading && loadingPosition === 'end' ? loadingIndicator : props.endIcon}
        >
            {loading && loadingPosition === 'center' ? loadingIndicator : children}
        </Button>
    );
};
