import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeesSelection } from '@/components/employees-selection/EmployeesSelection';
import { AsyncSelectFilter, FiltersBar, SelectFilter } from '@/components/filters-bar/FiltersBar';
import { getNestedValueByPath, getSelectFilterNumberValues } from '@/components/filters-bar/FiltersBar.util';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { Employee, EmployeeAvatar } from '@/domain/employee/Employee.model';
import { doesEmployeeMatchFilter, filterEmployeesByAdvancedSearch, isValidEmployeeFilterType } from '@/domain/employee/Employee.service';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import useDebounce from '@/hooks/Debounce.hook';
import { EmployeeSelectionFilter, useEmployeeSelectionFilters } from '@/hooks/employee/EmployeeSelectionFilters.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Search01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type EmployeesSelectionDialogProps = {
    onSave: (assignedEmployees: Employee[]) => void;
    assignedEmployees: EmployeeAvatar[];
    allEmployees: Employee[];
    isFilterActive?: boolean;
} & DialogWrapperProps;

export const EmployeesSelectionDialog: FC<EmployeesSelectionDialogProps> = ({ open, onClose, onSave, assignedEmployees, allEmployees, isFilterActive }) => {
    const { t } = useTranslation();
    const [assignedEmployeesAvatar, setAssignedEmployeesAvatar] = useState<EmployeeAvatar[]>(assignedEmployees);
    const allEmployeesFiltered = allEmployees.filter(e => e.employmentStatus !== EmploymentStatus.TERMINATED);
    return (
        <DialogWrapper
            //stop event propagation to prevent submitting a parent form
            onSubmit={event => event.stopPropagation()}
            onClose={onClose}
            open={open}
            header={t('permissions_setting_page.applies_to')}
            maxWidth='md'
        >
            <DialogContent>
                <EmployeesSelectionForm
                    assignedEmployees={assignedEmployees}
                    allEmployees={allEmployeesFiltered}
                    handleSave={onSave}
                    isFilterActive={isFilterActive}
                    onEmployeeSelectionChange={setAssignedEmployeesAvatar}
                />
            </DialogContent>
            <DialogActions>
                <Stack direction={'row'} spacing={2}>
                    <StackedAvatars employeeAvatars={assignedEmployeesAvatar} />
                    <Button type={'submit'} form={'employees-selection-form'}>
                        {t('general.save')}
                    </Button>
                </Stack>
            </DialogActions>
        </DialogWrapper>
    );
};
const employeeSelectionFormSchema = yup.object().shape({
    assignedEmployees: yup.array().of(
        yup.object().shape({
            id: yup.number().required(),
        }),
    ),
});
type EmployeeSelectionFormValues = yup.InferType<typeof employeeSelectionFormSchema>;

type EmployeesSelectionProps = {
    assignedEmployees: EmployeeAvatar[];
    allEmployees: Employee[];
    handleSave: (assignedEmployees: Employee[]) => void;
    onEmployeeSelectionChange: (employees: Employee[]) => void;
    isFilterActive?: boolean;
};

const EmployeesSelectionForm: FC<EmployeesSelectionProps> = ({
    assignedEmployees,
    allEmployees,
    handleSave,
    isFilterActive = true,
    onEmployeeSelectionChange,
}) => {
    const { filters: availableFilters } = useEmployeeSelectionFilters();
    const [filters, setFilters] = useState<EmployeeSelectionFilter[]>(availableFilters);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [searchName, setSearchName] = useState<string>('');
    const { t } = useTranslation();
    const debounce = useDebounce();

    const getInitialValues = (initialAssignedEmployees: EmployeeAvatar[]): EmployeeSelectionFormValues => {
        return {
            assignedEmployees: initialAssignedEmployees,
        };
    };

    const { control, handleSubmit } = useForm<EmployeeSelectionFormValues>({
        defaultValues: getInitialValues(assignedEmployees),
        resolver: yupResolver(employeeSelectionFormSchema),
    });

    const onSubmitSuccess = (data: EmployeeSelectionFormValues) => {
        const { assignedEmployees: assignedEmployeesIds } = data;
        const assignedEmployees = allEmployees.filter(e => (assignedEmployeesIds ?? []).some(a => a.id === e.id));
        handleSave(assignedEmployees);
    };

    const getFilteredEmployees = (employees: Employee[], filters: EmployeeSelectionFilter[], searchName: string): Employee[] => {
        const employeesFilteredByName = searchName ? filterEmployeesByAdvancedSearch(employees, searchName) : employees;

        const filtersFilled = filters?.filter(filter => !!filter.value?.length);
        return !filtersFilled?.length
            ? employeesFilteredByName
            : employeesFilteredByName.filter(employee => {
                  return filtersFilled.every(f => isFilterMatched(f, employee));
              });
    };

    const isFilterMatched = (filter: SelectFilter | AsyncSelectFilter, employee: Employee): boolean => {
        const key = filter.key;

        if (isValidEmployeeFilterType(key)) {
            const ids = getSelectFilterNumberValues(filter);
            return doesEmployeeMatchFilter(employee, ids, key);
        } else {
            const valueFromEmployee = getNestedValueByPath(employee, key);
            return !!filter.value?.find(option => option.value === valueFromEmployee);
        }
    };

    return (
        <Stack id={'employees-selection-form'} component={'form'} onSubmit={handleSubmit(onSubmitSuccess, console.error)} gap={2}>
            <Stack direction={isMobile ? 'column' : 'row'} gap={2} justifyContent={'space-between'} flexWrap={'wrap'}>
                {isFilterActive && <FiltersBar filters={filters} onFiltersChange={setFilters} />}
                <TextField
                    placeholder={t('general.search')}
                    onChange={event => {
                        debounce(() => setSearchName(event.target.value), 300);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Search01Icon size={20} />
                            </InputAdornment>
                        ),
                        sx: { pl: 1 },
                    }}
                    inputProps={{
                        sx: { py: '5.5px' },
                    }}
                    sx={{ width: isMobile || !isFilterActive ? '100%' : '200px' }}
                />
            </Stack>

            <Controller
                control={control}
                name={'assignedEmployees'}
                render={({ field }) => (
                    <EmployeesSelection
                        assignedEmployeesId={field.value ?? []}
                        employeesOptions={allEmployees}
                        onChange={(employees: Employee[]) => {
                            field.onChange(employees);
                            onEmployeeSelectionChange(employees);
                        }}
                        filteredEmployees={getFilteredEmployees(allEmployees, filters, searchName)}
                    />
                )}
            />
        </Stack>
    );
};
