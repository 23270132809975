import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewStatus } from '@/domain/review/Review.model';

type Props = {
    status?: ReviewStatus;
};

export const ReviewStatusChip: FC<Props> = ({ status }) => {
    const { t } = useTranslation();

    const getStatusLabel = (status: ReviewStatus) => {
        switch (status) {
            case ReviewStatus.DRAFT:
                return t('reviews.review_status.draft');
            case ReviewStatus.STARTED:
                return t('reviews.review_status.started');
            case ReviewStatus.CLOSED:
                return t('reviews.review_status.past');
        }
    };
    const getStatusColor = (status: ReviewStatus) => {
        switch (status) {
            case ReviewStatus.DRAFT:
                return 'warning';
            case ReviewStatus.STARTED:
                return 'success';
            case ReviewStatus.CLOSED:
                return 'default';
        }
    };

    return status && <Chip color={getStatusColor(status)} size='small' label={getStatusLabel(status)} />;
};
