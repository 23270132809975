import { Job, JobCreateMutation, JobSearch, JobUpdateMutation } from '@/domain/job/Job.model';
import { jobAPI } from '@/api/job/Job.api';

export const getJobs = (search: JobSearch = {}): Promise<Job[]> => {
    return jobAPI.getJobs(search);
};

export const getJobById = (jobId: number): Promise<Job> => {
    return jobAPI.getJobById(jobId);
};

export const createJob = (mutation: JobCreateMutation): Promise<Job> => {
    return jobAPI.createJob(mutation);
};

export const updateJob = (jobId: number, mutation: JobUpdateMutation): Promise<Job> => {
    return jobAPI.updateJob(jobId, mutation);
};

export const deleteJob = (jobId: number): Promise<void> => {
    return jobAPI.deleteJob(jobId);
};
