import { useCallback, useEffect, useState } from 'react';
import { handleError } from '@/utils/api.util';
import { UseQueryResult } from '@/page/Query.type';
import { LongLeave } from '@/domain/long-leave/LongLeave.model';
import { searchLongLeaves } from '@/domain/long-leave/LongLeave.service';

export const useGetLongLeaves = (employeeId: number, enabled = true): UseQueryResult<LongLeave[]> => {
    const [longLeaves, setLongLeaves] = useState<LongLeave[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLongLeaves = useCallback(async () => {
        if (!enabled) {
            setIsLoading(false);
            return;
        }
        const searchLongLeavesRequest = {
            employeeId: employeeId,
        };
        try {
            const longLeaves = await searchLongLeaves(searchLongLeavesRequest);
            setLongLeaves(longLeaves);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, [employeeId, enabled]);

    useEffect(() => {
        fetchLongLeaves().catch(handleError);
    }, [fetchLongLeaves]);

    return {
        data: longLeaves,
        setData: setLongLeaves,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchLongLeaves,
    };
};
