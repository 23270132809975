import { StateHandler } from '@/components/state-handler/StateHandler';
import { StepForm, StepperForm } from '@/components/stepper-form/StepperForm';
import { useStepper } from '@/components/stepper-form/StepperForm.util';
import { getCurrentContract } from '@/domain/employment/Employment.service';
import { mapOffboardingFormValuesToMutation, offboardEmployee } from '@/domain/offboarding/Offboarding.service';
import { useGetOngoingEmployeeReviews } from '@/hooks/employee-review/EmployeeReview.hook';
import { useGetEmployeeById, useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useSearchReviewTemplates } from '@/hooks/review-template/ReviewTemplate.hook';
import { CleanupStepForm } from '@/page/people/cleanup-form/CleanupStepForm';
import { cleanupStepFormSchema, CleanupStepFormValues } from '@/page/people/cleanup-form/CleanupStepForm.schema';
import { ReviewStepForm } from '@/page/people/review-form/ReviewStepForm';
import { ReviewStepFormValues, reviewStepSchema } from '@/page/people/review-form/ReviewStepForm.schema';
import { TerminationStepForm } from '@/page/people/termination-form/TerminationStepForm';
import { TerminationStepFormValues, terminationStepSchema } from '@/page/people/termination-form/TerminationStepForm.schema';
import { handleError } from '@/utils/api.util';
import { getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { useGetEmployments } from '@/hooks/employment/Employment.hook';

export const OffboardingPage: FC = () => {
    const employeeId = Number(useParams().employeeId);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { data: employee, isLoading: isLoadingEmployee, isError: isErrorEmployee, error: employeeError } = useGetEmployeeById(employeeId);
    const {
        data: allEmployments = [],
        isLoading: isEmploymentsLoading,
        isError: isErrorEmployments,
        error: employmentsError,
    } = useGetEmployments({ employeeIds: [employeeId] });

    const { data: employeeReviews } = useGetOngoingEmployeeReviews(employeeId);
    const { data: allEmployees = [] } = useGetEmployees();
    const { data: reviewTemplates = [] } = useSearchReviewTemplates({ reviewType: 'OFFBOARDING' });

    const hasReviewsFeature = useRealmFeatureEnabled(RealmFeaturesType.REVIEWS);

    const contract = getCurrentContract(allEmployments);

    const submitOffboarding = async (offBoardingFormValues: OffboardingAllStepFormValues) => {
        try {
            const offboardingMutation = mapOffboardingFormValuesToMutation(offBoardingFormValues);
            await offboardEmployee(offboardingMutation);
            showSnackbar(t('offboarding_form.create_success'), 'success');
            navigate(`/profile/${offBoardingFormValues.employeeId}/job-info`);
        } catch (error) {
            handleError(error);
        }
    };

    type STEPS_NAME = 'REASON' | 'CLEANUP' | 'REVIEW';

    const steps: StepForm[] = [
        {
            stepComponent: <TerminationStepForm />,
            stepName: 'REASON',
            stepTranslationKey: 'employee.employment.termination.dates_reason',
        },
    ];

    // Display cleanup step only if employee has subordinates or ongoing reviews and is not in edit mode
    const displayCleanupStep = !!employee && (!!employee?.subordinates?.length || !!employeeReviews?.length);
    if (displayCleanupStep) {
        steps.push({
            stepComponent: <CleanupStepForm employeeId={employee?.id} allEmployees={allEmployees} employeeReviews={employeeReviews} />,
            stepName: 'CLEANUP',
            stepTranslationKey: 'offboarding_form.cleanup_step.title',
        });
    }
    if (hasReviewsFeature) {
        steps.push({
            stepComponent: <ReviewStepForm employeeId={employee?.id} allEmployees={allEmployees} templates={reviewTemplates} />,
            stepName: 'REVIEW',
            stepTranslationKey: 'offboarding_form.review_step.name',
        });
    }

    const useStepperValues = useStepper({
        steps,
    });
    const { currentStep, onNextStep } = useStepperValues;

    const getSchemaFromStepName = (step_name: STEPS_NAME): yup.ObjectSchema<TerminationStepFormValues | ReviewStepFormValues | CleanupStepFormValues> => {
        switch (step_name) {
            case 'REASON':
                return terminationStepSchema;
            case 'CLEANUP':
                return cleanupStepFormSchema;
            case 'REVIEW':
                return reviewStepSchema;
        }
    };

    const valuesWithLoadedData: Partial<OffboardingAllStepFormValues> = {
        employeeId: employeeId,
        currentEmploymentId: contract?.id,
        endDate: contract?.endDate ?? undefined,
        terminationNoticeDate: contract?.terminationNoticeDate ?? undefined,
        terminationLastDayAtWork: contract?.terminationLastDayAtWork ?? undefined,
        terminationType: contract?.terminationType,
        terminationReason: contract?.terminationReason,
        terminationComment: contract?.terminationComment ?? '',
        ongoingReviewIds: [],
        assignments: employee?.subordinates?.map(subordinate => ({
            employee: subordinate,
            manager: getNull(),
        })),
        assignNewManagerReviews: false,
        organizeMeeting: true,
    };

    const stepName = (steps?.find(step => step?.stepName === currentStep?.stepName)?.stepName ?? 'REASON') as STEPS_NAME;
    const schema = getSchemaFromStepName(stepName);

    // Using two types to make difference between acceptable default values for the form and the final values after submitting
    // This usage is necessary because we use values props to refresh default values after loading data
    const formMethods = useForm<OffboardingStepFormValues, undefined, OffboardingAllStepFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            employeeId: employeeId,
            ongoingReviewIds: [],
            assignments: [],
            assignNewManagerReviews: false,
            organizeMeeting: true,
        },
        values: valuesWithLoadedData as OffboardingAllStepFormValues,
    });

    const { handleSubmit } = formMethods;

    const validateAndNextStep = async () => {
        return handleSubmit(onNextStep, console.error)();
    };

    return (
        <StateHandler
            isLoading={isLoadingEmployee || isEmploymentsLoading}
            isError={isErrorEmployee || isErrorEmployments}
            error={employeeError ?? employmentsError}
        >
            <FormProvider {...formMethods}>
                <StepperForm
                    steps={steps}
                    useStepperValues={useStepperValues}
                    onNextStep={validateAndNextStep}
                    onSave={handleSubmit(submitOffboarding, console.error)}
                />
            </FormProvider>
        </StateHandler>
    );
};

export type OffboardingAllStepFormValues = TerminationStepFormValues & ReviewStepFormValues & CleanupStepFormValues;
export type OffboardingStepFormValues = TerminationStepFormValues | ReviewStepFormValues | CleanupStepFormValues;
