import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { LeavesEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { BirthdayEmptyStateIcon } from '@/components/empty-state/icons/BirthdayEmptyStateIcon';
import { NewJoinerEmptyStateIcon } from '@/components/empty-state/icons/NewJoinerEmptyStateIcon';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { CalendarDay } from '@/domain/calendar/Calendar.model';
import { EmployeeAnniversaryRequest } from '@/domain/employee/Employee.model';
import { getNewJoiners } from '@/domain/employee/Employee.service';
import { employmentService } from '@/domain/employment/Employment.service';
import { LeaveActivityType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { canSeeOtherEmployeeLeaves, hasViewEmployeeBirthdaysPolicy, hasViewEmployeesWorkAnniversariesPolicy } from '@/domain/permission/Permission.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { EmployeeShift, LeaveShiftSearchRequest } from '@/domain/shift/Shift.model';
import { useGetCalendar } from '@/hooks/calendar/Calendar.hook';
import { useGetEmployeesBirthdays, useGetEmployeesWorkAnniversaries } from '@/hooks/employee/Employee.hook';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { useGetLeaveShifts } from '@/page/employee-shift/EmployeeShift.hook';
import { MAX_DATE_BIRTHDAYS, MAX_DATE_PUBLIC_HOLIDAYS, MAX_DATE_WORK_ANNIVERSARIES } from '@/page/home/events/Events.constants';
import { useAppSelector, useCurrentEmployee, useCurrentPolicies } from '@/stores/store';
import { compareAsc, formatInDayMonthYear, formatRelativeDate, getCurrentLocalDate, getTodayDate, toDate } from '@/utils/datetime.util';
import { getLabelTranslation, getLocale, useUserLanguage } from '@/utils/language.util';
import { Link, StackProps, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack/Stack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router';

export const Events: FC<StackProps & { mobile?: boolean }> = ({ mobile, ...rest }) => {
    const { t } = useTranslation();
    const userLanguage = useUserLanguage();
    const locale = getLocale(userLanguage);

    const policies = useCurrentPolicies();
    const currentEmployee = useCurrentEmployee();

    const { data: calendar } = useGetCalendar(currentEmployee?.currentWorkingPattern?.calendar?.id);
    const publicHolidays: CalendarDay[] = employmentService.getPublicHolidays(calendar?.days ?? [], getCurrentLocalDate(), MAX_DATE_PUBLIC_HOLIDAYS);

    // BIRTHDAYS DATA
    const canViewEmployeeBirthday = hasViewEmployeeBirthdaysPolicy(policies);
    const birthdaySearchRequest: EmployeeAnniversaryRequest = {
        startDate: getCurrentLocalDate(),
        endDate: MAX_DATE_BIRTHDAYS,
        employeeIds: [],
    };
    const { data: employeeBirthdays, isError: isEmployeeBirthdaysError } = useGetEmployeesBirthdays(birthdaySearchRequest, canViewEmployeeBirthday);
    const employeeBirthdaysSorted = [...(employeeBirthdays ?? [])].sort((birthday1, birthday2) =>
        compareAsc(birthday1.anniversaryDate, birthday2.anniversaryDate),
    );

    // NEW JOINERS DATA
    const canViewEmployeeWorkAnniversaries = hasViewEmployeesWorkAnniversariesPolicy(policies);
    const workAnniversarySearchRequest: EmployeeAnniversaryRequest = {
        startDate: getCurrentLocalDate(),
        endDate: MAX_DATE_WORK_ANNIVERSARIES,
        employeeIds: [],
    };
    const { data: employeeWorkAnniversaries, isError: isEmployeeWorkAnniversariesError } = useGetEmployeesWorkAnniversaries(
        workAnniversarySearchRequest,
        canViewEmployeeWorkAnniversaries,
    );

    const newJoiners = employeeWorkAnniversaries ? getNewJoiners(employeeWorkAnniversaries) : [];

    // ON LEAVE DATA
    const realm = useAppSelector(state => state.ui.currentRealm);
    const hasLeavesFeature = useRealmFeatureEnabled(RealmFeaturesType.LEAVES);
    const canViewLeaves = hasLeavesFeature && canSeeOtherEmployeeLeaves(realm?.realmFeatures, policies, currentEmployee?.id ?? 0);
    const shiftRequest: LeaveShiftSearchRequest = {
        startDate: getCurrentLocalDate(),
        endDate: getCurrentLocalDate(),
        employeeIds: [],
        sortDirection: 'ASC',
    };
    const { data: leaves } = useGetLeaveShifts(shiftRequest, canViewLeaves);

    const showEmployeesBirthday = canViewEmployeeBirthday && !!employeeBirthdaysSorted?.length;
    const showOnLeave = canViewLeaves && !!leaves?.length;
    const showNewJoiners = canViewEmployeeWorkAnniversaries && !!newJoiners?.length;
    const showPublicHolidays = hasLeavesFeature && !!publicHolidays.length;

    const { data: leaveTypes, isLoading: isLoadingLeaveTypes } = useGetLeaveTypes(showOnLeave);

    if ((!showEmployeesBirthday && !showOnLeave && !showNewJoiners && !showPublicHolidays) || isLoadingLeaveTypes) {
        return;
    }

    const workingLeaveTypesWithLeaves = mapWorkingLeaveTypesWithLeaves(leaves ?? [], leaveTypes ?? []);
    const otherLeaves = leaves?.filter(leave => !workingLeaveTypesWithLeaves.find(workingLeaveType => workingLeaveType.shifts.includes(leave))) ?? [];

    if (mobile) {
        return <EventsMobile countOnLeave={leaves?.length ?? 0} countNewJoiners={newJoiners?.length ?? 0} countBirthdays={employeeBirthdays?.length ?? 0} />;
    }

    return (
        <Stack component={Paper} p={2} gap={2} {...rest}>
            {/*BIRTHDAYS*/}
            {showEmployeesBirthday && (
                <Stack gap={2}>
                    <Typography variant={'h2'}>{t('home_page.events.birthdays')}</Typography>

                    {employeeBirthdaysSorted.map(birthDay => (
                        <Stack key={birthDay.employeeId} direction={'row'} alignItems={'center'} gap={1}>
                            <Stack gap={1} direction={'row'} alignItems={'center'} flex={2}>
                                <EmployeeAvatar employeeAvatar={{ ...birthDay, id: birthDay.employeeId }} profileLink={true} />
                                <Typography variant={'body1'}>{birthDay.displayName}</Typography>
                            </Stack>
                            <Typography variant={'body1'} flex={1}>
                                {formatRelativeDate(toDate(birthDay.anniversaryDate), {
                                    baseDate: getTodayDate(),
                                    locale,
                                })}
                            </Typography>
                        </Stack>
                    ))}
                    {isEmployeeBirthdaysError && <Typography variant={'body1'}>{t('general.errors.default_title')}</Typography>}
                </Stack>
            )}

            {/*LEAVES*/}
            {showOnLeave && (
                <Stack gap={2}>
                    {workingLeaveTypesWithLeaves.map(workingLeaveType => (
                        <Stack key={workingLeaveType.leaveType.id} gap={1}>
                            <Typography variant={'h2'}>{getLabelTranslation(workingLeaveType.leaveType.name)}</Typography>
                            <StackedAvatars employeeAvatars={workingLeaveType.shifts.map(shift => shift.assignee)} profileLink={true} />
                        </Stack>
                    ))}
                    {otherLeaves?.length > 0 && (
                        <Stack gap={2}>
                            <Typography variant={'h2'}>{t('home_page.events.employees_on_leave')}</Typography>
                            <StackedAvatars employeeAvatars={otherLeaves?.map(leave => leave.assignee) || []} profileLink={true} />
                        </Stack>
                    )}
                </Stack>
            )}

            {/*NEW JOINERS*/}
            {showNewJoiners && (
                <Stack gap={2}>
                    <Typography variant={'h2'}>{t('home_page.events.new_joiners')}</Typography>
                    {newJoiners?.map(newJoiner => (
                        <Stack key={newJoiner.employeeId} direction={'row'} alignItems={'center'} gap={1}>
                            <Stack gap={1} direction={'row'} alignItems={'center'} flex={2}>
                                <EmployeeAvatar employeeAvatar={{ ...newJoiner, id: newJoiner.employeeId }} profileLink={true} />
                                <Typography variant={'body1'}>{newJoiner.displayName}</Typography>
                            </Stack>
                            <Typography variant={'body1'} flex={1}>
                                {formatRelativeDate(toDate(newJoiner.anniversaryDate), {
                                    baseDate: getTodayDate(),
                                    locale,
                                })}
                            </Typography>
                        </Stack>
                    ))}
                    {isEmployeeWorkAnniversariesError && <Typography variant={'body1'}>{t('general.errors.default_title')}</Typography>}
                </Stack>
            )}

            {/*PUBLIC HOLIDAYS*/}
            {showPublicHolidays && (
                <Stack gap={2}>
                    <Typography variant={'h2'}>{t('home_page.events.public_holidays')}</Typography>

                    {publicHolidays.map(holiday => (
                        <Typography key={holiday.id} variant={'body1'} flex={1}>
                            <b>{holiday.name}</b> : {formatInDayMonthYear(holiday.date)}
                        </Typography>
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

type EventsMobileProps = {
    countBirthdays: number;
    countNewJoiners: number;
    countOnLeave: number;
};
export const EventsMobile: FC<EventsMobileProps> = ({ countOnLeave, countBirthdays, countNewJoiners }) => {
    const { t } = useTranslation();
    const sxIcon = {
        width: '67px',
        height: '55px',
    };

    if (!countOnLeave && !countNewJoiners && !countBirthdays) {
        return;
    }
    return (
        <Stack component={Paper} p={2} flex={{ md: 1 }} gap={1}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'h1'}>{t('home_page.events.mobile_title')}</Typography>
                <Link to='/events' component={RouterLink} color='primary'>
                    {t('general.view_all')}
                </Link>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-around'}>
                {countOnLeave > 0 && (
                    <Stack alignItems={'center'} gap={1}>
                        <LeavesEmptyStateIcon sx={sxIcon} />
                        <Typography variant={'body1'}>{t('home_page.events.count_on_leave', { count: countOnLeave })}</Typography>
                    </Stack>
                )}
                {countNewJoiners > 0 && (
                    <Stack alignItems={'center'} gap={1}>
                        <NewJoinerEmptyStateIcon sx={sxIcon} />
                        <Typography variant={'body1'}>{t('home_page.events.count_new_joiner', { count: countNewJoiners })}</Typography>
                    </Stack>
                )}
                {countBirthdays > 0 && (
                    <Stack alignItems={'center'} gap={1}>
                        <BirthdayEmptyStateIcon sx={sxIcon} />
                        <Typography variant={'body1'}>{t('home_page.events.count_birthday', { count: countBirthdays })}</Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

const mapWorkingLeaveTypesWithLeaves = (employeeShifts: EmployeeShift[], leaveTypes: LeaveType[]) => {
    const workingLeaveTypes = leaveTypes.filter(leaveType => leaveType.leaveActivityType === LeaveActivityType.WORKING);
    const mappedWorkingLeaveTypes = workingLeaveTypes.map(leaveType => {
        const leaves = employeeShifts.filter(employeeShift => employeeShift.shifts?.[0].leaveTypeId === leaveType.id);
        return {
            leaveType,
            shifts: leaves,
        };
    });
    return mappedWorkingLeaveTypes.filter(leaveType => leaveType.shifts.length > 0) ?? [];
};
