import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ViewIcon, ViewOffIcon } from 'hugeicons-react';
import { LoadingButton } from '@/components/loading-button/LoadingButton';

type Props = {
    loading: boolean;
    onLoginClicked: (email: string, password: string) => void;
    email?: string;
};

export const StandardLoginForm: FC<Props> = ({ loading, onLoginClicked, email }) => {
    const { t } = useTranslation();
    const emailMessage = t('general.forms.invalid_email');

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const schema = yup.object().shape({
        email: yup.string().email(emailMessage).required(),
        password: yup.string().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: email ?? '',
            password: '',
        },
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Stack direction='column' spacing={2}>
            <FormControlLabel
                label={t('login_form.email')}
                labelPlacement='top'
                control={<TextField fullWidth error={!!errors.email} helperText={errors.email?.message} {...register('email')} />}
            />
            <FormControlLabel
                label={t('login_form.password')}
                labelPlacement='top'
                control={
                    <TextField
                        {...register('password')}
                        id='input-password'
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        placeholder={t('login_form.password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge='end'
                                        size='large'
                                    >
                                        {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={!!errors?.password}
                    />
                }
            />
            <LoadingButton
                fullWidth={true}
                color='primary'
                variant='contained'
                loading={loading}
                loadingPosition={'start'}
                startIcon={<></>}
                type='submit'
                onClick={handleSubmit(data => onLoginClicked(data?.email, data?.password), console.error)}
            >
                {t('login_form.login')}
            </LoadingButton>
        </Stack>
    );
};
