import { FC, PropsWithChildren, ReactNode } from 'react';
import { Dialog, DialogProps, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Cancel01Icon } from 'hugeicons-react';

export type DialogWrapperProps = {
    onClose: () => void;
    open: boolean;
    header?: string | ReactNode;
} & Omit<DialogProps, 'title'>;

export const DialogWrapper: FC<PropsWithChildren<DialogWrapperProps>> = ({ open, onClose, header, children, ...props }: DialogWrapperProps) => {
    const theme = useTheme();

    // All dialogs should be full screen on mobile
    const fullScreenOnMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = (reason: 'backdropClick' | 'escapeKeyDown') => {
        // we don't want to close the dialog when the user clicks outside of it
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    return (
        <Dialog open={open} fullScreen={fullScreenOnMobile} onClose={(_, reason) => handleClose(reason)} {...props}>
            <DialogTitle display={'flex'} justifyContent={header ? 'space-between' : 'flex-end'} alignItems={'center'} sx={{ py: 2 }}>
                {header}
                <IconButton aria-label='close' onClick={() => onClose()} size='large'>
                    <Cancel01Icon />
                </IconButton>
            </DialogTitle>
            {children}
        </Dialog>
    );
};
