import { RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { UnitType } from '@/domain/date/Date.model';
import { WorkingPatternTemplate } from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { useGetWorkingTemplates } from '@/hooks/working-pattern-template/WorkingPatternTemplate.hook';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import {
    getDailyWorkingTime,
    getDaysWorkPerWeek,
    getWeeklyWorkingTime,
    getWorkingRate,
    mapWorkingPatternTemplateToFormValues,
} from '@/page/setting/time-management/working-pattern/WorkingPatternFormPage.util';
import { ConfigType } from '@/page/setting/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteWorkingPatternTemplate } from '@/domain/working-pattern-template/WorkingPatternTemplate.service';
import { showSnackbar } from '@/utils/snackbar.util';
import { useNavigate } from 'react-router';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';

export const WorkingPatternsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: workingPatterns, isLoading: loading, error, isError, refetch: refetchWorkingPatterns } = useGetWorkingTemplates();

    const getWeeklyWorkingTimeDisplay = (workingPatternTemplate: WorkingPatternTemplate) => {
        const workingPatternTemplateForm = mapWorkingPatternTemplateToFormValues(workingPatternTemplate);
        return t('duration.formatDuration', {
            duration: getWeeklyWorkingTime(workingPatternTemplateForm) / 60,
            unit: UnitType.HOURS,
        });
    };

    const getDailyWorkingTimeDisplay = (workingPatternTemplate: WorkingPatternTemplate) => {
        const workingPatternTemplateForm = mapWorkingPatternTemplateToFormValues(workingPatternTemplate);
        return t('duration.formatDuration', {
            duration: getDailyWorkingTime(workingPatternTemplateForm) / 60,
            unit: UnitType.HOURS,
        });
    };

    const getDaysWorkPerWeekDisplay = (workingPatternTemplate: WorkingPatternTemplate) => {
        const workingPatternTemplateForm = mapWorkingPatternTemplateToFormValues(workingPatternTemplate);
        const workingRate = getWorkingRate(workingPatternTemplateForm, workingPatternTemplate.weeklyWorkingTime.weeklyAmountWorked);
        const workingRateDisplay = ' (' + workingRate + '%)';
        return getDaysWorkPerWeek(workingPatternTemplateForm) + workingRateDisplay;
    };

    const handleDeleteClicked = async (workingPatternId: number) => {
        try {
            await deleteWorkingPatternTemplate(Number(workingPatternId));
            showSnackbar(t('contract_page.delete_with_success'), 'success');
        } catch {
            showSnackbar(t('contract_page.unable_to_delete'), 'error');
        }
        await refetchWorkingPatterns();
    };

    const getMoreButton = (workingPattern: WorkingPatternTemplate) => {
        const menuItems: BasicMenuItem[] = [
            {
                title: t('general.edit'),
                onClick: () => navigate(`/settings/time-management/working-patterns/${workingPattern.id}`),
            },
            {
                title: t('general.delete'),
                onClick: () => {
                    handleDeleteClicked(workingPattern.id);
                },
            },
        ];
        return <BasicMenu items={menuItems} />;
    };

    const columnsDef: RogerColDef<WorkingPatternTemplate>[] = [
        {
            field: 'name',
            headerName: t('contracts_settings_page.table_headers.contract_name'),
        },
        {
            headerName: t('contracts_settings_page.weekly_working_time'),
            valueFormatter: ({ data }) => (data ? getWeeklyWorkingTimeDisplay(data) : ''),
        },
        {
            headerName: t('contracts_settings_page.daily_working_time'),
            valueFormatter: ({ data }) => (data ? getDailyWorkingTimeDisplay(data) : ''),
        },
        {
            headerName: t('contracts_settings_page.days_week'),
            valueFormatter: cellRenderParams => (cellRenderParams.data ? getDaysWorkPerWeekDisplay(cellRenderParams.data) : ''),
        },
        {
            type: 'actionMenu',
            cellRenderer: ({ data }) => (data ? getMoreButton(data) : undefined),
        },
    ];

    const config: ConfigType<WorkingPatternTemplate> = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('general.add')} ${t('contracts_settings_page.contract_type')}`,
        },
        isOnRowClickActive: true,
        table: {
            columns: columnsDef,
        },
    };
    const getConfig = () => config;

    return (
        <StateHandler isLoading={loading} isError={isError} error={error}>
            <CompanySettingsLayout options={getConfig()} data={workingPatterns} />
        </StateHandler>
    );
};
