import { PlanningTag, PlanningTagCreationMutation, PlanningTagsSearch, PlanningTagUpdateMutation } from '@/domain/planning-tag/PlanningTag.model';
import { planningTagApi } from '@/api/planning-tag/PlanningTag.api';

import { OrderMutation } from '@/domain/common';

export const searchPlanningTags = (planningTagSearch: PlanningTagsSearch = {}): Promise<PlanningTag[]> => {
    return planningTagApi.searchPlanningTags(planningTagSearch);
};

export const createPlanningTag = (mutation: PlanningTagCreationMutation): Promise<PlanningTag> => {
    return planningTagApi.createPlanningTag(mutation);
};

export const updatePlanningTag = (id: number, mutation: PlanningTagUpdateMutation): Promise<PlanningTag> => {
    return planningTagApi.updatePlanningTag(id, mutation);
};

export const deletePlanningTag = (id: number): Promise<void> => {
    return planningTagApi.deletePlanningTag(id);
};

export const updatePlanningTagOrders = (mutation: OrderMutation[]): Promise<PlanningTag> => {
    return planningTagApi.updatePlanningTagOrders(mutation);
};
