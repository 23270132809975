import { API_BASE_URL, client } from '@/api/common';
import { EmployeeAvailability, EmployeeAvailabilitySearchRequest } from '@/domain/employee-availability/EmployeeAvailability.model';
import { convertDateToUTCIsoString, toDate } from '@/utils/datetime.util';
import { AxiosResponse } from 'axios';

const getEmployeesAvailability = async (request: EmployeeAvailabilitySearchRequest): Promise<EmployeeAvailability[]> => {
    const url = API_BASE_URL + '/employee-availabilities/search';
    const { data } = await client.post<EmployeeAvailabilityDTO[], AxiosResponse<EmployeeAvailabilityDTO[]>, EmployeeAvailabilitySearchRequestDTO>(url, {
        ...request,
        atDateTime: convertDateToUTCIsoString(toDate(request.atDateTime)),
    });
    return data;
};

export const employeeAvailabilityApi = {
    getEmployeesAvailability,
};

export type EmployeeAvailabilityDTO = EmployeeAvailability;
export type EmployeeAvailabilitySearchRequestDTO = Overwrite<EmployeeAvailabilitySearchRequest, { atDateTime: string }>;
