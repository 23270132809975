import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { ThirdPartyMapping, ThirdPartyMappingValue } from '@/domain/third-party-mapping/ThirdPartyMapping.model';
import { getThirdPartyMappingValues, searchThirdPartyMappings } from '@/domain/third-party-mapping/ThirdPartyMapping.service.';

export const useGetThirdPartyMappings = (): UseQueryResult<ThirdPartyMapping[]> => {
    const [thirdPartyMappings, setThirdPartyMappings] = useState<ThirdPartyMapping[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchThirdPartyMappings = useCallback(async () => {
        try {
            const thirdPartyMappings = await searchThirdPartyMappings();
            setThirdPartyMappings(thirdPartyMappings);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchThirdPartyMappings().catch(handleError);
    }, [fetchThirdPartyMappings]);

    return {
        data: thirdPartyMappings,
        setData: setThirdPartyMappings,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchThirdPartyMappings(),
    };
};

export const useGetThirdPartyMappingValues = (thirdPartyMappingId?: number): UseQueryResult<ThirdPartyMappingValue[]> => {
    const [thirdPartyMappingValues, setThirdPartyMappingValues] = useState<ThirdPartyMappingValue[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchThirdPartyMappingValues = useCallback(async () => {
        if (!thirdPartyMappingId) {
            return;
        }

        try {
            const thirdPartyMappingValues = await getThirdPartyMappingValues(thirdPartyMappingId);
            setThirdPartyMappingValues(thirdPartyMappingValues);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, [thirdPartyMappingId]);

    useEffect(() => {
        fetchThirdPartyMappingValues().catch(handleError);
    }, [fetchThirdPartyMappingValues]);

    return {
        data: thirdPartyMappingValues,
        setData: setThirdPartyMappingValues,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchThirdPartyMappingValues(),
    };
};
