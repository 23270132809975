import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Omit<Theme, 'components'>>['MuiButton'] = {
    defaultProps: {
        variant: 'contained',
        disableElevation: true,
        size: 'small',
    },
    variants: [
        {
            props: {
                variant: 'contained',
                color: 'primary',
            },
            style: ({ theme }) => ({
                color: theme.palette.common.white,
                borderRadius: theme.shape.borderRadius,
            }),
        },
        {
            props: {
                variant: 'contained',
                color: 'warning',
            },
            style: ({ theme }) => ({
                color: theme.palette.common.white,
                borderRadius: theme.shape.borderRadius,
            }),
        },
        {
            props: {
                variant: 'text',
                color: 'primary',
            },
            style: ({ theme }) => ({
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.common.white,
            }),
        },
    ],
};
