import { WeeklyWorkingTime, WeeklyWorkingTimeMutation } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';

const searchWeeklyWorkingTimes = async (): Promise<WeeklyWorkingTime[]> => {
    const url = API_BASE_URL + '/weekly-working-times/search';
    return (await client.post<WeeklyWorkingTimeDTO[], AxiosResponse<WeeklyWorkingTimeDTO[]>>(url, {})).data;
};

const createWeeklyWorkingTime = async (mutation: WeeklyWorkingTimeMutation): Promise<WeeklyWorkingTime> => {
    const url = API_BASE_URL + '/weekly-working-times';
    return (await client.post<WeeklyWorkingTimeDTO, AxiosResponse<WeeklyWorkingTimeDTO>, WeeklyWorkingTimeMutation>(url, mutation)).data;
};

const updateWeeklyWorkingTime = async (weeklyWorkingTimeId: number, request: WeeklyWorkingTimeMutation): Promise<WeeklyWorkingTime> => {
    const url = API_BASE_URL + `/weekly-working-times/${weeklyWorkingTimeId}`;
    return (await client.put<WeeklyWorkingTimeDTO, AxiosResponse<WeeklyWorkingTimeDTO>, WeeklyWorkingTimeMutation>(url, request)).data;
};

const deleteWeeklyWorkingTime = async (weeklyWorkingTimeId: number): Promise<void> => {
    const url = API_BASE_URL + `/weekly-working-times/${weeklyWorkingTimeId}`;
    await client.delete(url);
};

export const weeklyWorkingTime = {
    searchWeeklyWorkingTimes,
    createWeeklyWorkingTime,
    updateWeeklyWorkingTime,
    deleteWeeklyWorkingTime,
};

type WeeklyWorkingTimeDTO = WeeklyWorkingTime;
