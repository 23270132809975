import { getAppConfig } from '@/config/config';
import { BaseDocument, CustomDocumentPreviewTypes, DocumentContentDisposition, PreviewData } from '@/domain/document/Document.model';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { handleError } from '@/utils/api.util';
import { Link, LinkProps, useTheme } from '@mui/material';
import { FC, useState } from 'react';

const config = getAppConfig();

export type EmployeeSectionDocumentLinkProps = {
    document: BaseDocument;
    onDownload: (contentDisposition: DocumentContentDisposition) => Promise<string>;
} & LinkProps;

export const EmployeeSectionDocumentLink: FC<EmployeeSectionDocumentLinkProps> = ({ document, onDownload, ...rest }) => {
    const theme = useTheme();
    const [previewData, setPreviewData] = useState<PreviewData>();

    const showDocumentPreview = async (document: BaseDocument, type: CustomDocumentPreviewTypes) => {
        try {
            const response = await onDownload('INLINE');
            const previewUrl = type === 'OFFICE' ? `${config.OFFICE_PREVIEW_URL}${encodeURIComponent(response)}` : `${response}`;
            setPreviewData({
                document: { ...document, documentType: 'DOCUMENT' },
                url: previewUrl,
            });
        } catch (error) {
            handleError(error);
        }
    };
    const handleClick = async () => {
        try {
            // form value name is the employee section id
            if (config.MIME_TYPES.MICROSOFT.some((mimeType: string) => mimeType === document.mimeType)) {
                await showDocumentPreview(document, 'OFFICE');
            } else {
                await showDocumentPreview(document, 'STANDARD');
            }
        } catch (error) {
            handleError(error);
        }
    };

    const onDownloadClick = async () => {
        try {
            const response = await onDownload('ATTACHMENT');
            // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
            setTimeout(() => {
                window.open(response, '_blank');
            });
        } catch (error) {
            handleError(error);
        }
    };

    const onClosePreviewDialog = () => {
        setPreviewData(undefined);
    };

    return (
        <>
            <Link
                component='button'
                color={theme.palette.primary.main}
                underline={'always'}
                onClick={handleClick}
                {...rest}
                sx={{ lineHeight: '24px' }}
                noWrap={true}
            >
                {document?.name}
            </Link>
            {!!previewData && <DocumentPreviewDialog onClose={onClosePreviewDialog} previewData={previewData} onDownloadClick={onDownloadClick} />}
        </>
    );
};
