import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { ReviewStatus } from '@/domain/review/Review.model';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';

type Props = {
    onUpdateReview: () => void;
    reviewStatus: ReviewStatus;
    onDelete: () => void;
    onReopen: () => void;
    onClose: () => void;
};

export const ManageReviewsContextMenu: FC<Props> = ({ onUpdateReview, reviewStatus, onDelete, onClose, onReopen }) => {
    const { t } = useTranslation();

    const menuItems: BasicMenuItem[] = [
        {
            title: t('reviews.manage_reviews.update_review'),
            onClick: () => {
                onUpdateReview();
            },
            hide: reviewStatus === ReviewStatus.CLOSED,
        },
        {
            title: t('general.delete'),
            onClick: onDelete,
            hide: !(reviewStatus === ReviewStatus.DRAFT || reviewStatus === ReviewStatus.CLOSED),
        },
        {
            title: t('general.close'),
            onClick: onClose,
            hide: reviewStatus !== ReviewStatus.STARTED,
        },
        {
            title: t('reviews.manage_reviews.reopen'),
            onClick: onReopen,
            hide: reviewStatus !== ReviewStatus.CLOSED,
        },
    ];
    return <BasicMenu items={menuItems} />;
};
