import { weeklyWorkingTime } from '@/api/weekly-working-time/WeeklyWorkingTime.api';
import { WeeklyWorkingTime, WeeklyWorkingTimeMutation } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';

export const searchWeeklyWorkingTimes = (): Promise<WeeklyWorkingTime[]> => {
    return weeklyWorkingTime.searchWeeklyWorkingTimes();
};

export const createWeeklyWorkingTime = (mutation: WeeklyWorkingTimeMutation): Promise<WeeklyWorkingTime> => {
    return weeklyWorkingTime.createWeeklyWorkingTime(mutation);
};

export const updateWeeklyWorkingTime = (weeklyWorkingTimeId: number, mutation: WeeklyWorkingTimeMutation): Promise<WeeklyWorkingTime> => {
    return weeklyWorkingTime.updateWeeklyWorkingTime(weeklyWorkingTimeId, mutation);
};

export const deleteWeeklyWorkingTime = (weeklyWorkingTimeId: number): Promise<void> => {
    return weeklyWorkingTime.deleteWeeklyWorkingTime(weeklyWorkingTimeId);
};
