import { RealmFeature, RealmUpdateMutation } from '@/domain/realm/Realm.model';
import { updateRealm } from '@/domain/realm/Realm.service';
import useDebounce from '@/hooks/Debounce.hook';
import { FormControlLabel, Paper, Stack, Switch, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentRealm } from '@/stores/store';

export const RealmSettingsPage: FC = () => {
    const { t } = useTranslation();
    const debounce = useDebounce();

    const realm = useCurrentRealm();

    const [features, setFeatures] = useState(realm?.realmFeatures ?? []);

    const handleToggle = (id: number, checked: boolean) => {
        const newState = features.map(f => (f.id === id ? { ...f, enabled: checked } : f));
        setFeatures(newState);
        debounceSave(newState);
    };

    const debounceSave = (features: RealmFeature[]) =>
        debounce(() => {
            const payload: RealmUpdateMutation = {
                name: realm.name,
                realmStatus: realm.realmStatus,
                availableLoginMethods: realm.availableLoginMethods,
                defaultLanguage: realm.defaultLanguage,
                defaultTimezone: realm.defaultTimezone,
                enabledFeatures: features.filter(f => f.enabled).map(f => f.feature),
            };
            updateRealm(payload, realm.tenantId);
        });

    const sortedFeatures = [...features].sort((a, b) => a.feature.localeCompare(b.feature));

    return (
        <Stack component={Paper} flex={1} p={2} gap={2}>
            <Typography variant='h1'>{t('realm_settings_page.features')}</Typography>

            <Stack gap={2}>
                {sortedFeatures.map(({ id, feature, enabled }) => (
                    <FormControlLabel
                        key={id}
                        label={t(`realm_settings_page.features_enum`, {
                            context: feature,
                            defaultValue: feature,
                        })}
                        labelPlacement='end'
                        control={<Switch key={id} checked={enabled} onChange={(_, checked) => handleToggle(id, checked)} />}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
