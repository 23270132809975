import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, useTheme } from '@mui/material';

export type StepBreadcrumb = {
    stepTranslationKey: string;
    stepName: string;
};

type StepperBreadCrumbProps = {
    steps: StepBreadcrumb[];
    currentStep: StepBreadcrumb;
};

/**
 * The StepperBreadCrumb component is a wrapper component that allows you to create a breadcrumb for a stepper.
 * @param steps is an array of Step objects (which contain a stepTranslationKey) that define the steps in the stepper.
 * @param currentStep is the current step in the stepper.
 */
export const StepperBreadCrumb: FC<StepperBreadCrumbProps> = ({ steps, currentStep }) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const separator = (
        <Typography variant='body2bold' color={palette.action.disabled}>
            {' > '}
        </Typography>
    );

    return (
        <Stack direction={'row'} gap={1} flex={1}>
            {steps.map((step, index) => (
                <Fragment key={step.stepTranslationKey}>
                    {index !== 0 && separator}
                    <Typography variant='body2bold' color={step.stepName === currentStep.stepName ? palette.primary.main : palette.action.disabled}>
                        {t(step.stepTranslationKey)}
                    </Typography>
                </Fragment>
            ))}
        </Stack>
    );
};
