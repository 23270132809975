import { ObjectiveCategory } from '@/domain/objective/Objective.model';
import { objectiveCategoryAPI } from '@/api/objective-category/ObjectiveCategory.api';
import { ObjectiveCategoryCreateMutation, ObjectiveCategorySearch, ObjectiveCategoryUpdateMutation } from '@/domain/objective-category/ObjectiveCategory.model';

import { OrderMutation } from '@/domain/common';

export const getObjectiveCategory = async (objectiveCategoryId: number): Promise<ObjectiveCategory> => {
    return objectiveCategoryAPI.getObjectiveCategory(objectiveCategoryId);
};

export const updateObjectiveCategory = async (objectiveCategoryId: number, mutation: ObjectiveCategoryUpdateMutation): Promise<ObjectiveCategory> => {
    return objectiveCategoryAPI.updateObjectiveCategory(objectiveCategoryId, mutation);
};

export const deleteObjectiveCategory = async (objectiveCategoryId: number): Promise<void> => {
    return objectiveCategoryAPI.deleteObjectiveCategory(objectiveCategoryId);
};

export const updateObjectiveCategoryOrder = async (request: OrderMutation[]): Promise<void> => {
    return objectiveCategoryAPI.updateObjectiveCategoryOrder(request);
};

export const createObjectiveCategory = async (mutation: ObjectiveCategoryCreateMutation): Promise<ObjectiveCategory> => {
    return objectiveCategoryAPI.createObjectiveCategory(mutation);
};

export const searchObjectiveCategories = async (request: ObjectiveCategorySearch = {}): Promise<ObjectiveCategory[]> => {
    return objectiveCategoryAPI.searchObjectiveCategories(request);
};
