import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { FieldPath, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

/**
 * Custom hook to fill the job family field when the job changes
 */
export const usePreFillJobFamily = <T extends FieldValues>(jobPath: FieldPath<T>, jobFamilyPath: FieldPath<T>): void => {
    const hasJobFamilyFeature = useRealmFeatureEnabled(RealmFeaturesType.JOB_FAMILIES);
    const { setValue, control, getFieldState } = useFormContext<T>();
    const watchedJob = useWatch<T>({
        control,
        name: jobPath,
    });

    useDeepCompareEffect(() => {
        if (!hasJobFamilyFeature) {
            return;
        }
        const isJobFamilyDirty = getFieldState(jobFamilyPath)?.isDirty;
        if (isJobFamilyDirty || !watchedJob?.jobFamily) {
            return;
        }
        const isJobDirty = getFieldState(jobPath)?.isDirty;
        if (isJobDirty) {
            setValue(jobFamilyPath, watchedJob?.jobFamily);
        }
    }, [getFieldState, setValue, watchedJob]);
};
