import { TranslatableLabelInput } from '@/components/translatable-label-input/TranslatableLabelInput';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Label } from '@/domain/label/Label.model';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserLanguage } from '@/utils/language.util';
import { SurveyTemplate } from '@/domain/survey-template/Survey-template.model';

type SurveyInfoSectionProps = {
    onChangeName: (name: Label) => void;
    onChangeDescription: (name: Label) => void;
    surveyTemplate: Omit<SurveyTemplate, 'createdBy'>;
    handleLanguageChange: (language: UserLanguage) => void;
    translationLanguage: UserLanguage;
};

export const SurveyInfoSection: FC<SurveyInfoSectionProps> = ({
    onChangeName,
    onChangeDescription,
    surveyTemplate,
    handleLanguageChange,
    translationLanguage,
}) => {
    const { t } = useTranslation();

    return (
        <Stack direction='column' gap={2} p={2}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='body1bold'>{t('survey_templates_page.about')}</Typography>
                <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={handleLanguageChange} />
            </Stack>

            {/*todo: implement the error helper text message*/}
            <TranslatableLabelInput
                value={surveyTemplate.name}
                fullWidth
                label={t('survey_templates_page.name')}
                onChange={name => onChangeName(name)}
                translationLanguage={translationLanguage}
            />
            {/*todo: implement the error helper text message*/}
            <TranslatableLabelInput
                fullWidth
                InputProps={{
                    multiline: true,
                    minRows: 4,
                }}
                value={surveyTemplate.description}
                label={t('survey_templates_page.description')}
                onChange={description => onChangeDescription(description)}
                translationLanguage={translationLanguage}
            />
        </Stack>
    );
};
