import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { Employee } from '@/domain/employee/Employee.model';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { EmployeeReviewProgress } from '@/page/review/employee-review-progress/EmployeeReviewProgress';
import { DialogContent, useMediaQuery, useTheme } from '@mui/material';

type EmployeeReviewViewProgressDialogProps = {
    onClose: () => void;
    employeeReview: EmployeeReview;
    currentEmployee: Employee;
};

export const EmployeeReviewViewProgressDialog: FC<EmployeeReviewViewProgressDialogProps> = ({ onClose, employeeReview, currentEmployee }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <DialogWrapper open={true} onClose={onClose} header={t('reviews.employee_review.view_progress_modal.title')} maxWidth={'sm'}>
            <DialogContent
                sx={{
                    px: 3,
                    pb: isMobile ? 0 : 3,
                }}
            >
                <EmployeeReviewProgress employeeReview={employeeReview} currentEmployee={currentEmployee} />
            </DialogContent>
        </DialogWrapper>
    );
};
