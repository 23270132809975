import { usePopperlessAutocomplete } from '@/components/autocomplete-wrapper/usePopperlessAutocomplete';
import { AsyncSelectFilter, SelectFilterOption, SelectFilter as SelectFilterType } from '@/components/filters-bar/FiltersBar';
import { Select } from '@/components/form/field-select/Select';
import { getNull } from '@/utils/object.util';
import { FormControlLabel, List, ListItem, Radio, RadioGroup, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectFilter: FC<{
    filter: SelectFilterType | AsyncSelectFilter;
    onFilterUpdated: (filter: SelectFilterType | AsyncSelectFilter) => void;
}> = ({ filter, onFilterUpdated }) => {
    const { t } = useTranslation();

    const availableRulesOptions: {
        type: SelectFilterType['rule'];
        label: string;
    }[] = filter.availableRules?.map(rule => ({
        type: rule,
        label: t('filters_bar.rules.enum', { context: rule }),
    }));

    const autocompleteProps = usePopperlessAutocomplete();

    const [selectOptions, setSelectOptions] = useState<SelectFilterOption[]>([]);
    const [selectOptionsLoading, setSelectOptionsLoading] = useState(true);

    const isOptionChecked = (option: { value?: string | number }, values: { value?: string | number }[] = []) => {
        return values?.some(value => value.value?.toString() === option.value?.toString()) ?? false;
    };

    const handleMultiSelectChange = (filter: SelectFilterType | AsyncSelectFilter, options: SelectFilterOption[]) => {
        const updatedFilter = {
            ...filter,
            value: options,
        };
        onFilterUpdated(updatedFilter);
    };

    const handleSingleSelectChange = (filter: SelectFilterType | AsyncSelectFilter, option: SelectFilterOption) => {
        const updatedFilter = { ...filter, value: [option] };
        onFilterUpdated(updatedFilter);
    };

    const handleSelectChange = (value: SelectFilterOption[] | SelectFilterOption | null) => {
        switch (filter.type) {
            case 'select':
                return handleSingleSelectChange(filter, value as SelectFilterOption);
            case 'multi-select':
                return handleMultiSelectChange(filter, (value as SelectFilterOption[]) ?? []);
        }
    };

    useEffect(() => {
        if (!selectOptions?.length && selectOptionsLoading) {
            if (filter.selectMode === 'SYNC') {
                setSelectOptionsLoading(false);
                setSelectOptions(filter.options ?? []);
            }
            if (filter.selectMode === 'ASYNC') {
                filter.fetchOptions().then(options => {
                    setSelectOptions(options);
                    setSelectOptionsLoading(false);
                });
            }
        }
    }, [filter, filter.type, onFilterUpdated, selectOptions?.length, selectOptionsLoading]);

    const getOptionDisabled = (option: SelectFilterOption) => {
        // Clearable is not set by default, when undefined it should be considered as true
        return filter.clearable === false && (filter.value?.length ?? 0) === 1 && isOptionChecked(option, filter.value);
    };
    const handleRuleChange = (rule: SelectFilterType['rule']) => {
        const updatedFilter = { ...filter, rule };
        onFilterUpdated(updatedFilter);
    };

    const values = selectOptions.filter(option => isOptionChecked(option, filter.value));
    return (
        <Stack gap={0.5} p={1}>
            {!!availableRulesOptions?.length && (
                <RadioGroup value={filter.rule ?? ''}>
                    <List disablePadding dense>
                        {availableRulesOptions.map(option => (
                            <ListItem key={option.type}>
                                <FormControlLabel
                                    value={option.type}
                                    control={
                                        <Radio
                                            edge='start'
                                            disableRipple
                                            size='small'
                                            onChange={e => {
                                                handleRuleChange(e.target.value as SelectFilterType['rule']);
                                            }}
                                            sx={{
                                                py: 0,
                                                pr: 0.25,
                                            }}
                                        />
                                    }
                                    labelPlacement='end'
                                    label={option.label}
                                    sx={{
                                        m: 0,
                                        // We are using the same margin as the Select component
                                        ml: -2,
                                        pl: 0.5,
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </RadioGroup>
            )}
            <Select
                // clearable is not set by default, when undefined it should be considered as true
                disableClearable={filter.clearable === false}
                value={filter.type === 'multi-select' ? values : (values[0] ?? getNull())}
                multiple={filter.type === 'multi-select'}
                loading={selectOptionsLoading}
                options={selectOptions}
                getOptionLabel={option => option.label ?? ''}
                onChange={(value, _reason) => {
                    handleSelectChange(value);
                }}
                getOptionKey={option => option.value}
                fullWidth
                autocompleteProps={{
                    getOptionDisabled: getOptionDisabled,
                    ...autocompleteProps,
                }}
                disableCloseOnSelect={autocompleteProps.disableCloseOnSelect}
                selectAll={filter.type === 'multi-select'}
            />
        </Stack>
    );
};
