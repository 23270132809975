import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { ThirdPartyMapping, ThirdPartyMappingType } from '@/domain/third-party-mapping/ThirdPartyMapping.model';
import { useGetCustomLists } from '@/hooks/custom-list/CustomList.hook';
import { getLabelTranslation } from '@/utils/language.util';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { CustomList } from '@/domain/custom-list/CustomList.model';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    open: boolean;
    onSave: (data: ThirdPartyMappingFormValues) => void;
    closeDialog: () => void;
    thirdPartyMapping: ThirdPartyMapping | undefined;
};

const thirdPartyMappingSchema = yup.object().shape({
    mappingType: yup.mixed<ThirdPartyMappingType>().oneOf(Object.values(ThirdPartyMappingType)).required(),
    customList: yup.object() as unknown as yup.Schema<CustomList>,
    sectionDefinition: yup.object() as unknown as yup.Schema<SectionDefinition>,
});

export type ThirdPartyMappingFormValues = yup.InferType<typeof thirdPartyMappingSchema>;

export const ThirdPartyMappingSettingsDialog: FC<Props> = ({ open, onSave, closeDialog, thirdPartyMapping }) => {
    const { t } = useTranslation();
    const { data: customLists = [] } = useGetCustomLists();
    const { data: sectionDefinitions = [] } = useGetSectionDefinitions();
    const { control, handleSubmit, watch } = useForm<ThirdPartyMappingFormValues>({
        resolver: yupResolver(thirdPartyMappingSchema),
        defaultValues: {
            mappingType: thirdPartyMapping?.mappingType ?? undefined,
            customList: thirdPartyMapping?.customList,
            sectionDefinition: thirdPartyMapping?.sectionDefinition,
        },
    });

    const mappingType = watch('mappingType');

    const handleOnSave = (data: ThirdPartyMappingFormValues) => {
        onSave(data);
    };
    const onClose = () => {
        closeDialog();
    };

    const title = t('third_party_mapping_settings_page.dialog.add_title');

    return (
        <DialogWrapper header={title} open={open} onClose={onClose} maxWidth={'sm'}>
            <DialogContent>
                <FormControlLabel
                    aria-label={'third-party-mapping-input'}
                    label={t('third_party_mapping.mapping_type_input')}
                    sx={{ width: '100%' }}
                    control={
                        <FieldSelect
                            control={control}
                            name='mappingType'
                            options={Object.values(ThirdPartyMappingType)}
                            getOptionLabel={mappingType => t('third_party_mapping.mapping_type', { context: mappingType })}
                            fullWidth
                        />
                    }
                />
                {mappingType && mappingType === ThirdPartyMappingType.CUSTOM_LIST && (
                    <FormControlLabel
                        label={t('third_party_mapping.custom_list_input')}
                        sx={{ width: '100%' }}
                        control={
                            <FieldSelect
                                name='customList'
                                getOptionLabel={customList => getLabelTranslation(customList?.name)}
                                options={customLists}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                fullWidth
                                control={control}
                            />
                        }
                    />
                )}
                {mappingType && mappingType === ThirdPartyMappingType.SECTION_DEFINITION && (
                    <FormControlLabel
                        label={t('third_party_mapping.section_definition_input')}
                        sx={{ width: '100%' }}
                        control={
                            <FieldSelect
                                name='sectionDefinition'
                                getOptionLabel={section => getLabelTranslation(section?.name)}
                                options={sectionDefinitions}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                fullWidth
                                control={control}
                            />
                        }
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(handleOnSave, console.error)} variant='contained' fullWidth>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
