import { calculatePercentage } from '@/utils/math.util';
import { Box, Paper, Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import { ValueIteratee } from 'lodash';
import groupBy from 'lodash.groupby';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { localeCompareString } from '@/utils/strings.util';

type MatrixProps<T extends Record<string, unknown>> = {
    rows: T[];
    groupBy: {
        xAxis: ValueIteratee<T>;
        yAxis?: ValueIteratee<T>;
    };
    children: ({ items, mode }: { items: T[]; mode: 'STACK' | 'MATRIX' }) => ReactNode;
    getLabel?: (item: string) => string;
};

type GroupByResult<T> = Record<string, T[]>;

type MatrixResult<T> = Record<string, GroupByResult<T>>;

export const Matrix = <T extends Record<string, unknown>>({ rows, groupBy, children, getLabel = item => item }: MatrixProps<T>): JSX.Element => {
    const { t } = useTranslation();

    const alphabeticOrder = (a: string, b: string) => localeCompareString(a, b);
    const alphabeticReverseOrder = (valueA: string, valueB: string) => localeCompareString(valueB, valueA);

    if (!!groupBy.xAxis && !groupBy.yAxis) {
        const stackedGroup = buildColumns(rows, groupBy.xAxis);
        return (
            <Stack component={Paper} flex={1} justifyContent='center' margin='0 auto' p={1}>
                <Stack direction='row'>
                    {[...Object.entries(stackedGroup)]
                        .sort((a, b) => localeCompareString(a[0], b[0]))
                        .map(([key, items]) => {
                            return (
                                <Stack key={key} gap={1} justifyContent='flex-end' alignItems='center' width='140px'>
                                    {children({
                                        mode: 'STACK',
                                        items,
                                    })}
                                    <Tooltip title={getLabel(key) || t('matrix.unknown')}>
                                        <Typography variant='body1bold' noWrap width='140px' textAlign='center'>
                                            {getLabel(key) || t('matrix.unknown')}
                                        </Typography>
                                    </Tooltip>
                                    <Percentage groupCount={items.length} totalCount={rows.length} />
                                </Stack>
                            );
                        })}
                </Stack>
            </Stack>
        );
    }

    if (!!groupBy.xAxis && !!groupBy.yAxis) {
        const reportMatrix = buildMatrix(rows, groupBy.xAxis, groupBy.yAxis);
        // Warning : if we have to change the order of the Y axis, we have also to change the order in the tsx
        const arrayLabelY = [...Object.keys(Object.values(reportMatrix)[0])].sort(alphabeticReverseOrder);

        return (
            <Stack component={Paper} flex={1} justifyContent='center' margin='0 auto' p={1}>
                {/*  Use css display grid to display the matrix */}
                <Box
                    display='grid'
                    p={2}
                    // Number of columns is the number of keys in the reportMatrix + 1 for the Y axis label
                    gridTemplateColumns={`160px repeat(${Object.keys(reportMatrix).length}, 148px)`}
                >
                    {/* First column for Y axis label */}
                    {arrayLabelY.map((labelY, index) => (
                        <Stack key={labelY} justifyContent='center' alignItems='flex-end' gridColumn={1} gridRow={index + 1} p={2}>
                            <Tooltip title={getLabel(labelY) || t('matrix.unknown')}>
                                <Typography variant='body1bold' noWrap maxWidth={'116px'}>
                                    {getLabel(labelY) || t('matrix.unknown')}
                                </Typography>
                            </Tooltip>
                        </Stack>
                    ))}

                    {/* Nested grid for the matrix, with border radius */}
                    <Box
                        // Start at column 2
                        gridColumn={`2 / ${Object.keys(reportMatrix).length + 2}`}
                        gridRow={`1 / ${arrayLabelY.length + 1}`}
                        display='grid'
                        gridTemplateColumns={`repeat(${Object.keys(reportMatrix).length}, 148px)`}
                        border={1}
                        borderColor='grey.300'
                        borderRadius={1}
                    >
                        {Object.entries(reportMatrix)
                            .sort(([keyA], [keyB]) => alphabeticOrder(keyA, keyB))
                            .map(([_, stackY], colIndex) => {
                                return [...Object.entries(stackY)]
                                    .sort((a, b) => alphabeticReverseOrder(a[0], b[0]))
                                    .map(([keyY, items], rowIndex) => {
                                        return (
                                            <Stack
                                                key={keyY}
                                                p={2}
                                                gap={1}
                                                alignItems='flex-start'
                                                gridRow={rowIndex + 1}
                                                gridColumn={colIndex + 1}
                                                borderTop={rowIndex === 0 ? 0 : 1}
                                                borderLeft={colIndex === 0 ? 0 : 1}
                                                borderColor='grey.300'
                                            >
                                                <Percentage groupCount={items.length} totalCount={rows.length} />
                                                {children({
                                                    mode: 'MATRIX',
                                                    items,
                                                })}
                                            </Stack>
                                        );
                                    });
                            })}
                    </Box>

                    {Object.keys(reportMatrix)
                        .sort(alphabeticOrder)
                        .map((keyX, index) => {
                            return (
                                <Stack key={keyX} justifyContent='flex-start' alignItems='center' p={2} gridRow={arrayLabelY.length + 1} gridColumn={index + 2}>
                                    <Tooltip title={getLabel(keyX) || t('matrix.unknown')}>
                                        <Typography variant='body1bold' width='116px' noWrap align='center'>
                                            {getLabel(keyX) || t('matrix.unknown')}
                                        </Typography>
                                    </Tooltip>
                                </Stack>
                            );
                        })}
                </Box>
            </Stack>
        );
    }

    return <></>;
};

const buildColumns = <T,>(rows: T[], iteratee: ValueIteratee<T>, keys?: string[]): GroupByResult<T> => {
    const result = groupBy(rows, iteratee) as GroupByResult<T>;

    const defaultState: Record<string, T[]> = keys?.reduce((acc, key) => ({ ...acc, [key]: [] }), {}) || {};
    return { ...defaultState, ...result };
};

const buildMatrix = <T,>(rows: T[], xAxis: ValueIteratee<T>, yAxis: ValueIteratee<T>): MatrixResult<T> => {
    const stackX = buildColumns(rows, xAxis);

    const allYKeys = Object.keys(buildColumns(rows, yAxis));

    return Object.entries(stackX).reduce((acc, [xKey, stackXValues]) => {
        return {
            ...acc,
            [xKey]: buildColumns(stackXValues, yAxis, allYKeys),
        };
    }, {} as MatrixResult<T>);
};

const Percentage: FC<{ groupCount: number; totalCount: number } & TypographyProps> = ({ groupCount, totalCount }) => {
    const percentage = calculatePercentage(groupCount, totalCount);
    return (
        <Typography variant='body2' color='text.secondary'>
            {groupCount} ({percentage}%)
        </Typography>
    );
};
