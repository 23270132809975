import { Employee } from '@/domain/employee/Employee.model';
import { FilteringCondition, PermissionFilteringConditionRequest, PermissionFilterType, PolicyPermission } from '@/domain/permission/Permission.model';

export enum PermissionGroupType {
    CUSTOM = 'CUSTOM',
    ADMIN = 'ADMIN',
    ALL_EMPLOYEES = 'ALL_EMPLOYEES',
    SELF = 'SELF',
    MANAGER = 'MANAGER',
}

export type PermissionGroupPolicy = {
    policy: PolicyPermission;
    resourceId?: number;
};

export type PermissionGroup = {
    id?: number;
    groupName: string;
    description: string;
    groupType: PermissionGroupType;
    filterType: PermissionFilterType;
    targetMembersFilterType: PermissionFilterType;
    groupMembers: Employee[];
    groupPolicies: PermissionGroupPolicy[];
    targetMembers: Employee[];
    notificationsEnabled: boolean;
    groupMemberConditions: FilteringCondition[];
    targetMemberConditions: FilteringCondition[];
};

export type PermissionGroupMutation = Omit<PermissionGroup, 'id'>;

export type PermissionGroupRequest = {
    groupName: string;
    description: string;
    filterType: PermissionFilterType;
    targetMembersFilterType: PermissionFilterType;
    groupMemberIds: number[];
    groupPolicies: PermissionGroupPolicy[];
    targetMemberIds: number[];
    notificationsEnabled: boolean;
    groupMemberConditions: PermissionFilteringConditionRequest[];
    targetMemberConditions: PermissionFilteringConditionRequest[];
};
