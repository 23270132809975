import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { Accordion, AccordionDetails, AccordionSummary, Collapse, FormControlLabel, formHelperTextClasses, Paper, Stack, Typography } from '@mui/material';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldTime } from '@/components/form/field-time/FieldTime';
import { DayOfWeek } from '@/utils/datetime.util';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { getDayOfWeekTranslationKey } from '@/domain/date/Date.service';
import { ArrowDown01Icon } from 'hugeicons-react';

export const TimeManagementBonus: FC = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();

    const [timesheetMode, NightBonusPercentage, isBonusEnabled] = watch(['timesheetMode', 'nightBonusPercentage', 'isBonusEnabled']);

    const isSimplified = timesheetMode === TimesheetMode.SIMPLIFIED;

    const disableNightOptions = NightBonusPercentage == 0;

    return (
        <Paper>
            <Stack component={Accordion} py={1} px={3}>
                <AccordionSummary expandIcon={<ArrowDown01Icon />} aria-controls='panel-bonus' id='panel-bonus' sx={{ p: 0 }}>
                    <Typography variant={'h2'}>{t('time_management_settings_page.time_management_configuration.time_management_bonus')}</Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ p: 0, pb: 3 }}>
                    <Stack direction={'column'}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <FormControlLabel
                                label={t(
                                    `time_management_settings_page.time_management_configuration.${isSimplified ? 'bonus_holiday_weekend' : 'bonus_night_holiday_weekend'}`,
                                )}
                                labelPlacement='end'
                                control={<FieldSwitch name='isBonusEnabled' control={control} />}
                                sx={{ mr: 0 }}
                            />
                        </Stack>

                        <Collapse in={isBonusEnabled}>
                            <Stack gap={2} mt={2}>
                                {!isSimplified && (
                                    <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                                        <Typography width={200} variant={'body1bold'}>
                                            {t('time_management_settings_page.time_management_configuration.night_bonus')}
                                        </Typography>

                                        <FieldNumber name='nightBonusPercentage' control={control} min={0} step={1} prefix='%' sx={fieldNumberSX} />

                                        <Typography variant={'body1'}>{t('general.from')}</Typography>

                                        <FieldTime name={'nightBonusStartTime'} disabled={disableNightOptions} control={control} sx={fieldTimeSx} />

                                        <Typography variant={'body1'}>{t('general.to')}</Typography>

                                        <FieldTime name={'nightBonusEndTime'} disabled={disableNightOptions} control={control} sx={{ width: '8em' }} />
                                    </Stack>
                                )}

                                <TimeManagementSaturdaySundayBonus />
                            </Stack>
                        </Collapse>
                    </Stack>
                </AccordionDetails>
            </Stack>
        </Paper>
    );
};

const TimeManagementSaturdaySundayBonus: FC = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();

    const DaysOfWeekForSaturdayFromDayOfWeek = [DayOfWeek.FRIDAY, DayOfWeek.SATURDAY];
    const DaysOfWeekForSaturdayToDayOfWeek = [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];
    const DaysOfWeekForSundayFromDayOfWeek = [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];
    const DaysOfWeekForSundayToDayOfWeek = [DayOfWeek.SUNDAY, DayOfWeek.MONDAY];

    const saturdayBonusPercentage = watch('saturdayBonusPercentage');
    const disableSaturdayOptions = saturdayBonusPercentage == 0;

    const sundayBonusPercentage = watch('sundayBonusPercentage');
    const disableSundayOptions = sundayBonusPercentage == 0;

    return (
        <>
            <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                <Typography width={200} variant={'body1bold'}>
                    {t('time_management_settings_page.time_management_configuration.saturday_bonus')}
                </Typography>

                <FieldNumber name='saturdayBonusPercentage' control={control} min={0} step={1} prefix='%' sx={fieldNumberSX} />

                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.from')}</Typography>
                <FieldSelect
                    name='saturdayFromDayOfWeek'
                    disabled={disableSaturdayOptions}
                    control={control}
                    disableClearable
                    options={DaysOfWeekForSaturdayFromDayOfWeek}
                    getOptionLabel={option => t(getDayOfWeekTranslationKey(option))}
                    autocompleteProps={{
                        sx: autoCompleteSX,
                    }}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.at')}</Typography>

                <FieldTime name={'saturdayBonusStartTime'} disabled={disableSaturdayOptions} control={control} sx={{ width: '8em' }} />

                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.to')}</Typography>
                <FieldSelect
                    name='saturdayToDayOfWeek'
                    disabled={disableSaturdayOptions}
                    control={control}
                    disableClearable
                    options={DaysOfWeekForSaturdayToDayOfWeek}
                    getOptionLabel={option => t(getDayOfWeekTranslationKey(option))}
                    autocompleteProps={{ sx: autoCompleteSX }}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.at')}</Typography>

                <FieldTime name={'saturdayBonusEndTime'} disabled={disableSaturdayOptions} control={control} sx={{ width: '8em' }} />
            </Stack>

            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Typography width={200} variant={'body1bold'}>
                    {t('time_management_settings_page.time_management_configuration.sunday_holiday_bonus')}
                </Typography>
                <FieldNumber name='sundayBonusPercentage' control={control} min={0} step={1} prefix='%' sx={fieldNumberSX} />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.from')}</Typography>
                <FieldSelect
                    name='sundayFromDayOfWeek'
                    disabled={disableSundayOptions}
                    control={control}
                    disableClearable
                    options={DaysOfWeekForSundayFromDayOfWeek}
                    getOptionLabel={option => t(getDayOfWeekTranslationKey(option))}
                    autocompleteProps={{ sx: autoCompleteSX }}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.at')}</Typography>

                <FieldTime name={'sundayBonusStartTime'} disabled={disableSundayOptions} control={control} sx={fieldTimeSx} />

                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.to')}</Typography>
                <FieldSelect
                    name='sundayToDayOfWeek'
                    disabled={disableSundayOptions}
                    control={control}
                    disableClearable
                    options={DaysOfWeekForSundayToDayOfWeek}
                    getOptionLabel={option => t(getDayOfWeekTranslationKey(option))}
                    autocompleteProps={{
                        sx: {
                            width: '10em',
                        },
                    }}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.at')}</Typography>

                <FieldTime name={'sundayBonusEndTime'} disabled={disableSundayOptions} control={control} sx={{ width: '8em' }} />
            </Stack>
        </>
    );
};

const fieldNumberSX = {
    width: '8em',
    [`& .${formHelperTextClasses.root}`]: {
        whiteSpace: 'nowrap',
    },
};

const fieldTimeSx = {
    width: '8em',
    // has is newly supported by major browsers
    // in case of not supported, the error message will be displayed in a small width
    [`&:has(> .${formHelperTextClasses.root})`]: {
        width: 'fit-content',
    },
};

const autoCompleteSX = {
    alignSelf: 'normal',
    width: '10em',
};
