import { ShiftTemplate } from '@/domain/shift-template/ShiftTemplate.model';

export enum PlanningActionType {
    SHIFT_TEMPLATES_LOADED = 'SHIFT_TEMPLATES_LOADED',
    SHIFT_TEMPLATE_DELETED = 'SHIFT_TEMPLATE_DELETED',
}

export interface ShiftPlanningTemplates {
    type: PlanningActionType.SHIFT_TEMPLATES_LOADED;
    shiftTemplateList: ShiftTemplate[];
}

export interface ShiftPlanningTemplateDeleted {
    type: PlanningActionType.SHIFT_TEMPLATE_DELETED;
    shiftTemplateId: number;
    shiftTemplateList: ShiftTemplate[];
}

export type PlanningActions = ShiftPlanningTemplates | ShiftPlanningTemplateDeleted;
