import { Spinner } from '@/components/spinner/Spinner';
import { EmployeeProfileDocumentsPage } from '@/page/employee-profile/EmployeeProfileDocumentsPage';
import { EmployeeProfilePersonalInfoPage } from '@/page/employee-profile/EmployeeProfileInfoPage';
import { EmployeeProfileJobInfoPage } from '@/page/employee-profile/EmployeeProfileJobInfoPage';
import { EmployeeProfileLayout } from '@/page/employee-profile/EmployeeProfileLayout';
import { EmployeeProfilePlanningLazyPage } from '@/page/employee-profile/EmployeeProfileLazyPages';
import { EmployeeProfileLeavesPage } from '@/page/employee-profile/EmployeeProfileLeavesPage';
import { EmployeeProfileObjectivesPage } from '@/page/employee-profile/EmployeeProfileObjectivesPage';
import { EmployeeProfilePage } from '@/page/employee-profile/EmployeeProfilePage';
import { EmployeeProfileReviewsPage } from '@/page/employee-profile/EmployeeProfileReviewsPage';
import { EmployeeProfileCard } from '@/page/employee-profile/employee-profile-card/EmployeeProfileCard';
import { EmployeeProfileCardMobile } from '@/page/employee-profile/employee-profile-card/EmployeeProfileCardMobile';
import { EmployeeProfileTabs } from '@/page/employee-profile/employee-profile-info/EmployeeProfileTabs/EmployeeProfileTabs';
import { EmployeeProfileSkillsPage } from '@/page/employee-profile/employee-profile-skill/EmployeeProfileSkillsPage';
import { EmployeeProfileTimesheetsPage } from '@/page/employee-profile/employee-profile-timesheet/EmployeeProfileTimesheetsPage';
import { TimesheetsHistory } from '@/page/employee-profile/employee-profile-timesheet/EmployeeProfileTimesheetsHistoryPage';
import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RoutesConfig } from '@/routes/Routes.type';
import { Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router';

/**
 * Utility function to generate profile routes for employee profile, with and without employeeId
 * without employeeId: /profile/personal-info for current employee (logged in user)
 * with employeeId: /profile/:employeeId/personal-info for other employees
 */

const generateProfileRoutes = (path: string, breadCrumbKey: string, component: JSX.Element): RoutesConfig[] => {
    return [
        {
            path,
            breadCrumb: [
                {
                    nameKey: `pages.my_${breadCrumbKey}`,
                },
            ],
            component,
        },
        {
            path: `:employeeId/${path}`,
            breadCrumb: [
                {
                    nameKey: `pages.${breadCrumbKey}`,
                },
            ],
            component,
        },
    ];
};

const config: RoutesConfig[] = [
    ...generateProfileRoutes('personal-info', 'personal_info', <EmployeeProfilePersonalInfoPage />),
    ...generateProfileRoutes('job-info', 'job_info', <EmployeeProfileJobInfoPage />),
    ...generateProfileRoutes('leaves', 'leaves', <EmployeeProfileLeavesPage />),
    ...generateProfileRoutes(
        'planning',
        'planning',
        <Suspense fallback={<Spinner />}>
            <EmployeeProfilePlanningLazyPage />
        </Suspense>,
    ),
    ...generateProfileRoutes('reviews', 'reviews', <EmployeeProfileReviewsPage />),
    ...generateProfileRoutes('skills', 'skills', <EmployeeProfileSkillsPage />),
    ...generateProfileRoutes('objectives', 'objectives', <EmployeeProfileObjectivesPage />),
    ...generateProfileRoutes('timesheets', 'timesheets', <EmployeeProfileTimesheetsPage />),
    ...generateProfileRoutes('documents', 'documents', <EmployeeProfileDocumentsPage />),
];

const subRoutes: RoutesConfig[] = [
    ...generateProfileRoutes('leaves/history/:userLeaveTypeId', 'leave_history', <EmployeeProfileLeavesPage />),
    ...generateProfileRoutes('timesheets/history/:year/:month', 'timesheets_history', <TimesheetsHistory />),
];

export const employeeProfileRoutes: RouteObject[] = [
    {
        path: 'profile',
        children: [
            {
                path: '',
                element: (
                    <PageContainer breadCrumb={[{ nameKey: 'pages.my_profile_card' }]}>
                        <EmployeeProfilePage />
                    </PageContainer>
                ),
                errorElement: <RouterBoundary />,
            },
            {
                path: ':employeeId',
                element: (
                    <PageContainer breadCrumb={[{ nameKey: 'pages.profile_card' }]}>
                        <EmployeeProfilePage />
                    </PageContainer>
                ),
                errorElement: <RouterBoundary />,
            },
            {
                path: '',
                element: (
                    <EmployeeProfileLayout
                        tabs={<EmployeeProfileTabs />}
                        mobileProfile={<EmployeeProfileCardMobile />}
                        content={<Outlet />}
                        profile={<EmployeeProfileCard />}
                    />
                ),
                errorElement: <RouterBoundary />,
                children: [
                    ...config.map(config => ({
                        path: config.path,
                        element: <PageContainer breadCrumb={config.breadCrumb}>{config.component}</PageContainer>,
                        errorElement: <RouterBoundary />,
                    })),
                ],
            },
            ...subRoutes.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}>{config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
