/**
 * Returns the provided value if it's not null or undefined, otherwise returns null.
 * @param value The value to check.
 * @param predicate Optional predicate to conditionally apply the transformation.
 * @template T The type of the input value.
 * @template R The type of the transformed value.
 */

export const defaultToNull = <T>(value: Nullable<T>, predicate = true): T | null => {
    if (!predicate || value === getNull() || value === undefined) {
        return getNull();
    }

    return value;
};

export const getNull = (): null => {
    // eslint-disable-next-line no-null/no-null
    return null;
};

export const nonNullable = <T>(value: Nullable<T>): value is NonNullable<T> => {
    return value !== getNull() && value !== undefined;
};
