import { StateHandler } from '@/components/state-handler/StateHandler';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeDialogHeader } from '../employee-dialog-header/EmployeeDialogHeader';
import { EmployeeReview, UpdateEmployeeReviewMutation } from '@/domain/employee-review/EmployeeReview.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isPeerReviewAsked, isUpwardReviewAsked } from '@/domain/review/Review.service';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { Employee } from '@/domain/employee/Employee.model';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import I18n from '@/i18n/i18n';

type EmployeeReviewUpdateReviewersDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (request: UpdateEmployeeReviewMutation, employeeReviewId: number) => void;
    activeEmployeeReview: EmployeeReview;
};

const employeeReviewUpdateReviewersDialogFormSchema = yup.object().shape({
    managersIds: yup
        .array()
        .min(1, I18n.t('reviews.manage_reviews.error.no_managers'))
        .of(yup.object().shape({ id: yup.number().required(), displayName: yup.string().required() })),
    peerReviewerIds: yup.array().of(
        yup.object().shape({
            id: yup.number().required(),
            displayName: yup.string().required(),
        }),
    ),
    upwardReviewerIds: yup.array().of(
        yup.object().shape({
            id: yup.number().required(),
            displayName: yup.string().required(),
        }),
    ),
});

type EmployeeReviewUpdateReviewersDialogFormType = yup.InferType<typeof employeeReviewUpdateReviewersDialogFormSchema>;

export const EmployeeReviewUpdateReviewersDialog: FC<EmployeeReviewUpdateReviewersDialogProps> = ({ open, onClose, activeEmployeeReview, onSave }) => {
    const { t } = useTranslation();
    const { data: employees = [], isLoading: isLoadingEmployees, isError: isErrorEmployees, error: employeesError } = useGetEmployees();
    const employeeOptions = employees
        .filter(employee => employee.id !== activeEmployeeReview.employee.id)
        .filter(employee => employee.employmentStatus !== EmploymentStatus.TERMINATED);
    return (
        <DialogWrapper open={open} onClose={onClose} header={<EmployeeDialogHeader employee={activeEmployeeReview?.employee} />}>
            <DialogContent>
                <StateHandler isLoading={isLoadingEmployees} isError={isErrorEmployees} error={employeesError}>
                    <EmployeeReviewDialogForm activeEmployeeReview={activeEmployeeReview} employeeOptions={employeeOptions} onSave={onSave} />
                </StateHandler>
            </DialogContent>
            <DialogActions>
                <Button type='submit' form={'employee-review-update-reviewers-form'} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type EmployeeReviewDialogFormProps = {
    activeEmployeeReview: EmployeeReview;
    employeeOptions: Employee[];
    onSave: (request: UpdateEmployeeReviewMutation, employeeReviewId: number) => void;
};

const EmployeeReviewDialogForm: FC<EmployeeReviewDialogFormProps> = ({ activeEmployeeReview, employeeOptions, onSave }) => {
    const { t } = useTranslation();
    const activeReview = activeEmployeeReview?.review;

    const getInitialValues = (activeEmployeeReview: EmployeeReview) => {
        return {
            managersIds: activeEmployeeReview.managers.map(manager => {
                return { id: manager.employee.id, displayName: manager.employee.displayName };
            }),
            peerReviewerIds: activeEmployeeReview.peerReviewers.map(peer => {
                return { id: peer.employee.id, displayName: peer.employee.displayName };
            }),
            upwardReviewerIds: activeEmployeeReview.upwardReviewers.map(upward => {
                return { id: upward.employee.id, displayName: upward.employee.displayName };
            }),
        };
    };

    const { control, handleSubmit } = useForm<EmployeeReviewUpdateReviewersDialogFormType>({
        defaultValues: getInitialValues(activeEmployeeReview),
        resolver: yupResolver(employeeReviewUpdateReviewersDialogFormSchema),
    });

    const onSaveReviewers = (data: EmployeeReviewUpdateReviewersDialogFormType) => {
        const { managersIds, peerReviewerIds, upwardReviewerIds } = data;
        const request = {
            managersIds: (managersIds ?? []).map(manager => manager.id),
            peerReviewerIds: (peerReviewerIds ?? []).map(peer => peer.id),
            upwardReviewerIds: (upwardReviewerIds ?? []).map(upward => upward.id),
        };

        onSave(request, activeEmployeeReview.id);
    };

    return (
        <Stack gap={2} component='form' id={'employee-review-update-reviewers-form'} onSubmit={handleSubmit(onSaveReviewers, console.error)}>
            <FormControlLabel
                sx={{ width: '100%' }}
                label={t('reviews.employee_review.manager')}
                control={
                    <FieldSelect
                        control={control}
                        name={'managersIds'}
                        multiple
                        fullWidth
                        options={employeeOptions}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={option => option.displayName}
                        getOptionKey={option => option.id}
                    />
                }
            />

            {activeReview && isPeerReviewAsked(activeReview) && (
                <FormControlLabel
                    sx={{ width: '100%' }}
                    label={t('reviews.employee_review.peer_reviewers')}
                    control={
                        <FieldSelect
                            control={control}
                            name={'peerReviewerIds'}
                            multiple
                            fullWidth
                            options={employeeOptions}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={option => option.displayName}
                            getOptionKey={option => option.id}
                        />
                    }
                />
            )}

            {activeReview && isUpwardReviewAsked(activeReview) && (
                <FormControlLabel
                    sx={{ width: '100%' }}
                    label={t('reviews.employee_review.upward_reviewers')}
                    control={
                        <FieldSelect
                            control={control}
                            name={'upwardReviewerIds'}
                            multiple
                            fullWidth
                            options={employeeOptions}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={option => option.displayName}
                            getOptionKey={option => option.id}
                        />
                    }
                />
            )}
        </Stack>
    );
};
