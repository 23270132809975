import { Employee } from '@/domain/employee/Employee.model';
import { Button, Checkbox, Divider, List, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { ArrowLeft01Icon, ArrowRight01Icon } from 'hugeicons-react';
import { useTranslation } from 'react-i18next';
import { sortEmployeesByDisplayName } from '@/domain/employee/Employee.service';
import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { useInView } from 'react-intersection-observer';
import { getNull } from '@/utils/object.util';

type EmployeesSelectionProps = {
    assignedEmployeesId: { id: number }[];
    employeesOptions: Employee[];
    filteredEmployees?: Employee[];
    onChange: (assignedEmployees: Employee[]) => void;
};

type SelectableEmployee = {
    employee: Employee;
    selected: boolean;
};

export const EmployeesSelection: FC<EmployeesSelectionProps> = ({ assignedEmployeesId, employeesOptions, onChange, filteredEmployees = [] }) => {
    const assignedEmployees = employeesOptions.filter(e => assignedEmployeesId.some(a => a.id === e.id));
    const { t } = useTranslation();
    const createSelectableEmployees = (employees: Employee[], selected = false): SelectableEmployee[] => {
        return employees.map(employee => ({ employee, selected }));
    };

    const getDefaultAssignedEmployees = (assignedEmployees: Employee[]): SelectableEmployee[] => {
        return createSelectableEmployees(assignedEmployees);
    };

    const getDefaultUnassignedEmployees = (assignedEmployees: Employee[], employeesOptions: Employee[]): SelectableEmployee[] => {
        const unassignedEmployees = employeesOptions.filter(employee => !assignedEmployees.some(e => e.id === employee.id));
        return createSelectableEmployees(unassignedEmployees);
    };

    const [assignedSelectedEmployees, setAssignedSelectedEmployees] = useState<SelectableEmployee[]>(getDefaultAssignedEmployees(assignedEmployees));
    const [unassignedSelectedEmployees, setUnassignedSelectedEmployees] = useState<SelectableEmployee[]>(
        getDefaultUnassignedEmployees(assignedEmployees, employeesOptions),
    );
    const assignedSelectedEmployeesLength = assignedSelectedEmployees.filter(e => e.selected).length;
    const unassignedEmployeesSelectedLength = unassignedSelectedEmployees.filter(e => e.selected).length;
    const noAssignedEmployeesSelected = assignedSelectedEmployeesLength === 0;
    const noUnassignedEmployeesSelected = unassignedEmployeesSelectedLength === 0;

    const handleUpdateSelection = (newAssignedEmployees: SelectableEmployee[], newUnassignedEmployees: SelectableEmployee[]) => {
        setAssignedSelectedEmployees(newAssignedEmployees);
        setUnassignedSelectedEmployees(newUnassignedEmployees);
        onChange(newAssignedEmployees.map(e => e.employee));
    };

    const moveSelectedEmployees = (sourceEmployees: SelectableEmployee[], targetEmployees: SelectableEmployee[]) => {
        const selectedEmployees = sourceEmployees.filter(e => e.selected);
        const updatedSourceEmployees = sourceEmployees.filter(e => !e.selected);
        const updatedTargetEmployees = targetEmployees.concat(selectedEmployees);
        return [updatedSourceEmployees, updatedTargetEmployees];
    };

    const moveRight = () => {
        const [newUnassignedEmployees, newAssignedEmployees] = moveSelectedEmployees(unassignedSelectedEmployees, assignedSelectedEmployees);
        handleUpdateSelection(newAssignedEmployees, newUnassignedEmployees);
    };

    const moveLeft = () => {
        const [newAssignedEmployees, newUnassignedEmployees] = moveSelectedEmployees(assignedSelectedEmployees, unassignedSelectedEmployees);
        handleUpdateSelection(newAssignedEmployees, newUnassignedEmployees);
    };

    const getFilteredSelectableEmployees = (selectableEmployees: SelectableEmployee[], filteredEmployees: Employee[]): SelectableEmployee[] => {
        return selectableEmployees.filter(e => filteredEmployees.some(f => f.id === e.employee.id));
    };

    const updateEmployeeSelection = (employees: SelectableEmployee[], setSelectedEmployees: Dispatch<SetStateAction<SelectableEmployee[]>>) => {
        const getSelectedStatus = (employee: SelectableEmployee) => {
            const foundEmployee = employees.find(e => e.employee.id === employee.employee.id);
            return foundEmployee?.selected ?? employee.selected;
        };

        setSelectedEmployees(prevState =>
            prevState.map(e => ({
                ...e,
                selected: getSelectedStatus(e),
            })),
        );
    };

    const filteredUnassignedEmployees = getFilteredSelectableEmployees(unassignedSelectedEmployees, filteredEmployees);
    const filteredAssignedEmployees = getFilteredSelectableEmployees(assignedSelectedEmployees, filteredEmployees);

    return (
        <Stack gap={1}>
            <Stack gap={2} justifyContent='center' alignItems='flex-start' direction='row'>
                <SelectableEmployeeList
                    title={t('employees_selection.unassigned', {
                        count: filteredUnassignedEmployees.length,
                    })}
                    selectableEmployees={filteredUnassignedEmployees}
                    onChangeChecked={employees => updateEmployeeSelection(employees, setUnassignedSelectedEmployees)}
                />
                <Stack gap={2} alignSelf='center' minWidth='80px' minHeight='300px' justifyContent='center'>
                    <Button onClick={moveRight} disabled={noUnassignedEmployeesSelected} endIcon={<ArrowRight01Icon />} aria-label='move right'>
                        {unassignedEmployeesSelectedLength}
                    </Button>
                    <Button onClick={moveLeft} disabled={noAssignedEmployeesSelected} startIcon={<ArrowLeft01Icon />} aria-label='move left'>
                        {assignedSelectedEmployeesLength}
                    </Button>
                </Stack>
                <SelectableEmployeeList
                    title={t('employees_selection.assigned', { count: filteredAssignedEmployees.length })}
                    selectableEmployees={filteredAssignedEmployees}
                    onChangeChecked={employees => updateEmployeeSelection(employees, setAssignedSelectedEmployees)}
                />
            </Stack>
        </Stack>
    );
};

type SelectableEmployeeListProps = {
    title: string;
    selectableEmployees: SelectableEmployee[];
    onChangeChecked: (employees: SelectableEmployee[]) => void;
};

const SelectableEmployeeList: FC<SelectableEmployeeListProps> = ({ title, selectableEmployees, onChangeChecked }) => {
    const employeesLength = selectableEmployees.length;
    const handleToggle = (selectableEmployee: SelectableEmployee) => {
        const newEmployeesIncluded = selectableEmployees.map(e => {
            if (e.employee.id === selectableEmployee.employee.id) {
                return { ...e, selected: !e.selected };
            }
            return e;
        });
        onChangeChecked(newEmployeesIncluded);
    };
    const getChecked = (selectableEmployees: SelectableEmployee[]) => selectableEmployees.filter(e => e.selected).length;
    const checkedNumber = getChecked(selectableEmployees);
    const selectableEmployeesNumber = selectableEmployees.length;

    const handleToggleAll = (selectableEmployees: SelectableEmployee[]) => {
        const allChecked = selectableEmployees.every(e => e.selected);
        const newEmployeesIncluded = selectableEmployees.map(e => ({ ...e, selected: !allChecked }));
        onChangeChecked(newEmployeesIncluded);
    };

    const selectableEmployeesSorted = sortEmployeesByDisplayName(selectableEmployees.map(e => e.employee)).map(sortedEmployee =>
        selectableEmployees.find(e => e.employee.id === sortedEmployee.id),
    );

    // we have 10 items in the list displayed, so we want to load more when we reach the 12th item to have a smooth scroll
    const ITEMS_PER_PAGE = 12;
    const [page, setPage] = useState(1);
    const selectableEmployeesToDisplay = [...selectableEmployeesSorted].slice(0, ITEMS_PER_PAGE * page);

    const rootRef = useRef(getNull());

    const handleLoadNextEmployees = (inView: boolean) => {
        const hasMoreEmployees = selectableEmployeesToDisplay.length < employeesLength;
        if (inView && hasMoreEmployees) {
            setPage(currentPage => currentPage + 1);
        }
    };

    const { ref } = useInView({
        onChange: handleLoadNextEmployees,
        root: rootRef.current,
    });

    const indexToTriggerNextPage = selectableEmployeesToDisplay.length - 1;

    return (
        <List
            sx={{ width: '100%' }}
            subheader={
                <Stack direction='row' gap={1} alignItems='center' px={2} py={1}>
                    <Checkbox
                        name={'all'}
                        checked={checkedNumber === selectableEmployeesNumber && selectableEmployeesNumber !== 0}
                        indeterminate={checkedNumber !== selectableEmployeesNumber && checkedNumber !== 0}
                        onClick={() => handleToggleAll(selectableEmployees)}
                        disabled={employeesLength === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                    <Typography variant={'body2bold'}>{title}</Typography>
                </Stack>
            }
        >
            <Divider />

            <Stack sx={{ overflow: 'auto', maxHeight: '400px', mt: 1 }} ref={rootRef}>
                {selectableEmployeesToDisplay.map((selectableEmployee, index) => {
                    return (
                        selectableEmployee && (
                            <ListItem disablePadding key={selectableEmployee.employee.id} {...(index === indexToTriggerNextPage ? { ref } : {})}>
                                <SelectableEmployeeItem selectableEmployee={selectableEmployee} handleToggle={handleToggle} />
                            </ListItem>
                        )
                    );
                })}
            </Stack>
        </List>
    );
};

type SelectableEmployeeItemProps = {
    selectableEmployee: SelectableEmployee;
    handleToggle: (employee: SelectableEmployee) => void;
};

const SelectableEmployeeItem: FC<SelectableEmployeeItemProps> = ({ selectableEmployee, handleToggle }) => {
    const { employee } = selectableEmployee;
    const labelId = `list-${employee.id}-label`;

    return (
        <ListItemButton
            role='listitem'
            onClick={() => handleToggle(selectableEmployee)}
            sx={{ justifyContent: 'flex-start', alignItems: 'center', maxHeight: 40, gap: 2 }}
        >
            <Checkbox
                name={employee.displayName}
                aria-label={employee.displayName}
                checked={selectableEmployee.selected}
                tabIndex={-1}
                disableRipple
                inputProps={{
                    'aria-labelledby': labelId,
                    'aria-label': employee.displayName,
                }}
            />
            <Stack direction='row' gap={2} alignItems='center'>
                <EmployeeAvatar employeeAvatar={employee} />
                <Typography variant={'body2'}>{employee.displayName}</Typography>
            </Stack>
        </ListItemButton>
    );
};
