import { RealmFeature } from '@/domain/realm/Realm.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { Page } from '@/page/Pages';
import { canApproveRejectOtherEmployeeLeaveRequests, canManageOtherEmployeeLeaves, canSeeOtherEmployeeLeaves } from '@/domain/permission/Permission.service';

export const getLeavePages = (employeeId: number, realmFeatures: RealmFeature[], policies: EmployeePolicy[]): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'leaves_page.tabs.calendar',
            path: '/manage-leaves/calendar',
            condition: (_, policies: EmployeePolicy[]) => canSeeOtherEmployeeLeaves(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'leaves_page.tabs.pending_my_approval',
            path: '/manage-leaves/pending',
            condition: (_, policies: EmployeePolicy[]) => canApproveRejectOtherEmployeeLeaveRequests(policies, employeeId),
        },
        {
            labelKey: 'leaves_page.tabs.all_leaves',
            path: '/manage-leaves/all',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canManageOtherEmployeeLeaves(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'leaves_page.tabs.balances',
            path: '/manage-leaves/balances',
            condition: (_, policies: EmployeePolicy[]) => canApproveRejectOtherEmployeeLeaveRequests(policies, employeeId),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(realmFeatures, policies));
};
