import { FC, useEffect, useState } from 'react';
import { CompanySettingsLayout } from '../CompanySettingsLayout';
import { ConfigType } from '../types';
import { showSnackbar } from '@/utils/snackbar.util';
import { useTranslation } from 'react-i18next';
import { ShiftTypeDialog, ShiftTypeForm } from './shift/ShiftTypeDialog';
import { createShiftType, deleteShiftType, searchShiftTypes, updateShiftType } from '@/domain/shift-type/ShiftType.service';
import { ICellRendererParams } from '@ag-grid-community/core';
import { handleError } from '@/utils/api.util';
import { ShiftType, ShiftTypeCreationRequest, ShiftTypeUpdateRequest } from '@/domain/shift-type/ShiftType.model';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';

export const ShiftTypeSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [shiftTypeDialogOpen, setShiftTypeDialogOpen] = useState<boolean>(false);
    const [shiftTypeToUpdate, setShiftTypeToUpdate] = useState<ShiftType>();
    const [shiftTypes, setShiftTypes] = useState<ShiftType[]>([]);

    useEffect(() => {
        searchShiftTypes({})
            .then(data => setShiftTypes(data))
            .catch(handleError);
    }, []);

    const handleDeleteShiftType = (id: number) => {
        deleteShiftType(id)
            .then(() => {
                const types = [...shiftTypes];
                types.splice(
                    types.findIndex(item => item.id === id),
                    1,
                );
                setShiftTypes(types);
                showSnackbar(t('planning_setting_page.shift_type_deleted'), 'success');
            })
            .catch(() => {
                showSnackbar(t('planning_setting_page.shift_type_it_is_used'), 'error');
            });
    };

    const cellActionRenderer = (params: ICellRendererParams<ShiftType>) => {
        const menuItems: BasicMenuItem[] = [
            {
                title: t('general.delete'),
                onClick: () => {
                    if (!params.data?.id) {
                        return;
                    }
                    handleDeleteShiftType(params.data.id);
                },
            },
        ];
        return <BasicMenu items={menuItems} />;
    };

    const config: ConfigType<ShiftType> = {
        type: 'table',
        isOnRowClickActive: true,
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => {
                setShiftTypeDialogOpen(true);
            },
        },
        table: {
            agGridProps: {
                onRowClicked: ({ data }) => {
                    setShiftTypeDialogOpen(true);
                    setShiftTypeToUpdate(data);
                },
            },
            columns: [
                {
                    field: 'name',
                    headerName: 'Name',
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    const onSaveShiftType = (data: ShiftTypeForm, id?: number) => {
        setShiftTypeDialogOpen(false);
        if (!id) {
            const shiftTypeCreationRequest: ShiftTypeCreationRequest = {
                name: data.name,
                color: '',
                onSite: data.onSite,
            };
            createShiftType(shiftTypeCreationRequest)
                .then(data => {
                    const types = [...shiftTypes];
                    types.push(data);
                    setShiftTypes(types);
                    showSnackbar(t('planning_setting_page.shift_type_added'), 'success');
                })
                .catch(error => {
                    handleError(error);
                });
        } else {
            const shiftTypeUpdateRequest: ShiftTypeUpdateRequest = {
                name: data.name,
                color: '',
                onSite: data.onSite,
            };
            updateShiftType(id, shiftTypeUpdateRequest)
                .then(data => {
                    const types = [...shiftTypes];
                    const findTagIndex = types.findIndex(type => type.id === data.id);
                    types[findTagIndex] = data;
                    setShiftTypes(types);
                    showSnackbar(t('planning_setting_page.shift_type_edited'), 'success');
                })
                .catch(error => {
                    handleError(error);
                });
        }
    };

    const onCloseShiftType = () => {
        setShiftTypeDialogOpen(false);
        setShiftTypeToUpdate(undefined);
    };

    return (
        <>
            <CompanySettingsLayout options={config} data={shiftTypes} />
            {shiftTypeDialogOpen && (
                <ShiftTypeDialog
                    open={shiftTypeDialogOpen}
                    handleSave={data => onSaveShiftType(data, shiftTypeToUpdate?.id)}
                    closeDialog={onCloseShiftType}
                    shiftType={shiftTypeToUpdate}
                />
            )}
        </>
    );
};
