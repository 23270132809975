import { EmployeeWorkingPattern, EmployeeWorkingPatternType, WorkingDay } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { WorkingPatternTemplateDayForm } from '@/page/setting/time-management/WorkingPatternFormPage';
import {
    getWeeklyWorkingTime,
    getWorkingRate,
    mapWorkingPatternTemplateToFormValues,
} from '@/page/setting/time-management/working-pattern/WorkingPatternFormPage.util';
import { DAY_OF_WEEK_SORT, DayOfWeek, formatDuration } from '@/utils/datetime.util';
import { calculatePercentage } from '@/utils/math.util';
import { TFunction } from 'i18next';
import { DayPeriod, UnitType } from '../date/Date.model';
import { getDayOfWeekShortTranslationKey, getDayPeriodTranslationKey } from '../date/Date.service';
import {
    WorkingPatternTemplate,
    WorkingPatternTemplateCreationRequest,
    WorkingPatternTemplateSearchRequest,
    WorkingPatternTemplateUpdateRequest,
} from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { workingPatternTemplateAPI } from '@/api/working-pattern-template/workingPatternTemplate.api';

export const searchWorkingPatternTemplates = (searchRequest: WorkingPatternTemplateSearchRequest = {}): Promise<WorkingPatternTemplate[]> => {
    return workingPatternTemplateAPI.searchWorkingPatternTemplates(searchRequest);
};

export function getWorkingPatternTemplateById(workingPatternId: number): Promise<WorkingPatternTemplate> {
    return workingPatternTemplateAPI.getWorkingPatternTemplateById(workingPatternId);
}

export function createWorkingPatternTemplate(request: WorkingPatternTemplateCreationRequest): Promise<WorkingPatternTemplate> {
    return workingPatternTemplateAPI.createWorkingPatternTemplate(request);
}

export function updateWorkingPatternTemplate(workingPatternId: number, request: WorkingPatternTemplateUpdateRequest): Promise<WorkingPatternTemplate> {
    return workingPatternTemplateAPI.updateWorkingPatternTemplate(workingPatternId, request);
}

export const deleteWorkingPatternTemplate = (workingPatternId: number): Promise<void> => {
    return workingPatternTemplateAPI.deleteWorkingPatternTemplate(workingPatternId);
};

export const getEmployeeWorkingPatternTitle = (employeeWorkingPattern: EmployeeWorkingPattern | undefined, t: TFunction<'translation'>): string => {
    const getWeeklyWorkingTimeDisplay = (workingPatternTemplate: WorkingPatternTemplate) => {
        const workingPatternTemplateForm = mapWorkingPatternTemplateToFormValues(workingPatternTemplate);
        if (!workingPatternTemplate) {
            return '';
        }
        return t('duration.formatDuration', {
            duration: getWeeklyWorkingTime(workingPatternTemplateForm) / 60,
            unit: UnitType.HOURS,
        });
    };
    const getWorkingRatePerWeekDisplay = (workingPatternTemplate: WorkingPatternTemplate) => {
        const workingPatternTemplateForm = mapWorkingPatternTemplateToFormValues(workingPatternTemplate);
        if (!workingPatternTemplate) {
            return '';
        }
        const workingRate = getWorkingRate(workingPatternTemplateForm, workingPatternTemplate.weeklyWorkingTime.weeklyAmountWorked);
        return workingRate + '%';
    };

    if (!employeeWorkingPattern) {
        return '';
    }

    switch (employeeWorkingPattern.type) {
        case EmployeeWorkingPatternType.FIXED: {
            if (!employeeWorkingPattern.weeklyWorkingTime) {
                return '';
            }
            const fullDays = employeeWorkingPattern?.workingDays?.filter(w => w.dayPeriod === DayPeriod.ALL_DAY && w.amountWorked > 0) ?? [];
            const halfDays = employeeWorkingPattern?.workingDays?.filter(w => w.dayPeriod !== DayPeriod.ALL_DAY && w.amountWorked > 0) ?? [];
            return `${calculatePercentage(fullDays.length * 2 + halfDays.length, 10)}% · 
                ${getHoursPerWeekLabel(employeeWorkingPattern.weeklyWorkingTime, calculatePercentage(fullDays.length * 2 + halfDays.length, 10))} ·
                ${getEmployeeWorkingDayTitles(employeeWorkingPattern.workingDays, t)}`;
        }
        case EmployeeWorkingPatternType.TEMPLATE: {
            if (!employeeWorkingPattern.workingPatternTemplate) {
                return '';
            }
            const workingTime = getWeeklyWorkingTimeDisplay(employeeWorkingPattern.workingPatternTemplate);
            const workingRate = getWorkingRatePerWeekDisplay(employeeWorkingPattern.workingPatternTemplate);
            return `${employeeWorkingPattern.workingPatternTemplate?.name} · ${workingTime} - ${workingRate}`;
        }
        case EmployeeWorkingPatternType.HOURLY:
            return t('employee.work_pattern.type.hourly');
        case EmployeeWorkingPatternType.RATE: {
            if (!employeeWorkingPattern.weeklyWorkingTime || !employeeWorkingPattern.rate) {
                return t('employee.work_pattern.type.rate');
            }
            return `${employeeWorkingPattern.rate}% ·  ${formatDuration(employeeWorkingPattern.weeklyWorkingTime.weeklyAmountWorked)}`;
        }
        default:
            return '';
    }
};

const getEmployeeWorkingDayTitles = (workingDays: WorkingDay[], t: TFunction<'translation'>): string => {
    if (!workingDays?.length) {
        return '';
    }

    return [...workingDays]
        .sort((a, b) => DAY_OF_WEEK_SORT.indexOf(a.dayOfWeek) - DAY_OF_WEEK_SORT.indexOf(b.dayOfWeek))
        .filter(d => d.amountWorked > 0)
        .map(d => getEmployeeWorkingDayTitle(d, t))
        .join(', ');
};

const getEmployeeWorkingDayTitle = (workingDay: WorkingDay, t: TFunction<'translation'>): string => {
    return `${t(getDayOfWeekShortTranslationKey(workingDay.dayOfWeek))}${getDayPeriod(workingDay.dayPeriod, t)}`;
};

const getDayPeriod = (dayPeriod: DayPeriod, t: TFunction<'translation'>): string => {
    switch (dayPeriod) {
        case DayPeriod.MORNING:
        case DayPeriod.AFTERNOON:
            return ` (${t(getDayPeriodTranslationKey(dayPeriod))})`;
        case DayPeriod.ALL_DAY:
        default:
            return '';
    }
};

export const getRate = (morningWorkingDays: DayOfWeek[], afternoonWorkingDays: DayOfWeek[]): number => {
    return calculatePercentage(morningWorkingDays?.length + afternoonWorkingDays?.length, 10);
};

export const getHoursPerWeekLabel = (selectedWeeklyWorkingTime: WeeklyWorkingTime | undefined, rate: number): string => {
    if (!selectedWeeklyWorkingTime || !rate) {
        return `0h`;
    }
    const minutesPerWeek = (selectedWeeklyWorkingTime.weeklyAmountWorked * rate) / 100;
    return `${formatDuration(minutesPerWeek)}`;
};

export const defaultWorkingPatternDays: WorkingPatternTemplateDayForm[] = [
    {
        dayOfWeek: DayOfWeek.MONDAY,
        amountWorked: '08:00',
        dayPeriod: DayPeriod.ALL_DAY,
    },
    {
        dayOfWeek: DayOfWeek.TUESDAY,
        amountWorked: '08:00',
        dayPeriod: DayPeriod.ALL_DAY,
    },
    {
        dayOfWeek: DayOfWeek.WEDNESDAY,
        amountWorked: '08:00',
        dayPeriod: DayPeriod.ALL_DAY,
    },
    {
        dayOfWeek: DayOfWeek.THURSDAY,
        amountWorked: '08:00',
        dayPeriod: DayPeriod.ALL_DAY,
    },
    {
        dayOfWeek: DayOfWeek.FRIDAY,
        amountWorked: '08:00',
        dayPeriod: DayPeriod.ALL_DAY,
    },
    {
        dayOfWeek: DayOfWeek.SATURDAY,
        amountWorked: '00:00',
        dayPeriod: DayPeriod.ALL_DAY,
    },
    {
        dayOfWeek: DayOfWeek.SUNDAY,
        amountWorked: '00:00',
        dayPeriod: DayPeriod.ALL_DAY,
    },
];
