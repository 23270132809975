import { Employee, EmployeeAuthentication, EmployeePolicy } from '@/domain/employee/Employee.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginMethod } from '@/domain/realm/Realm.model';

export type CurrentEmployeeState = {
    authenticated: boolean;
    employee: Employee | undefined;
    loginMethod: LoginMethod | undefined;
    grantedPolicies: EmployeePolicy[];
};

const initialState: CurrentEmployeeState = {
    authenticated: false,
    employee: undefined,
    loginMethod: undefined,
    grantedPolicies: [],
};

export const currentEmployeeSlice = createSlice({
    name: 'currentEmployee',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        employeeAuthenticated: (state: CurrentEmployeeState, action: PayloadAction<EmployeeAuthentication>) => {
            return {
                ...state,
                authenticated: true,
                employee: action.payload?.employee,
                loginMethod: action.payload?.loginMethod,
                grantedPolicies: action.payload?.grantedPolicies,
            };
        },
        employeeSignedOut: (state: CurrentEmployeeState) => ({
            ...state,
            authenticated: false,
            employee: undefined,
            loginMethod: undefined,
            grantedPolicies: [],
            permissionGroups: [],
        }),
        employeeSettingsUpdated: (state: CurrentEmployeeState, action: PayloadAction<Employee>) => ({
            ...state,
            employee: action.payload,
        }),
    },
});

export const { employeeAuthenticated, employeeSignedOut, employeeSettingsUpdated } = currentEmployeeSlice.actions;

export const currentEmployeeReducer = currentEmployeeSlice.reducer;
