import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldText } from '@/components/form/field-text/FieldText';
import { FieldDateTime } from '@/components/form/field-time/FieldTime';
import { SelectResource } from '@/components/select-resource/SelectResource';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Employee } from '@/domain/employee/Employee.model';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { ClockInOutCreationMutation, EmployeeTimesheetMutation, Timesheet, TimesheetMutation } from '@/domain/timesheet/Timesheet.model';
import { useGetAreas } from '@/hooks/area/Area.hook';
import { useGetClockOutForceBreakDuration } from '@/hooks/timesheet/Timesheet.hook';
import { handleError } from '@/utils/api.util';
import {
    addDays,
    differenceInMinutes,
    formatDurationInHours,
    getCurrentLocalDate,
    getDateTimeUTCFromDateAndTime,
    getTimeFormatFromDate,
    getTodayDate,
    getZonedTimeFromUTC,
    isValidDate,
    setTime,
    subDays,
} from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { ArrowRight01Icon } from 'hugeicons-react';
import i18next from 'i18next';
import { FC, useEffect } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type TimesheetDialogClockOutProps = {
    timesheet: Timesheet;
    onClose: () => void;
    onSave: (mutation: ClockInOutCreationMutation, employeeTimesheetMutation: EmployeeTimesheetMutation | undefined) => void;
    timesheetSetting?: TimesheetSetting;
    currentEmployee: Employee;
};

const FETCH_FORCE_BREAK_SECONDS = 30;

export const TimesheetClockOutDialog: FC<TimesheetDialogClockOutProps> = ({ timesheet, onSave, onClose, timesheetSetting, currentEmployee }) => {
    const { t } = useTranslation();

    const endDate = getTodayDate();
    const startDate = timesheet?.startAt;

    const {
        data: areas = [],
        isLoading: isLoadingAreas,
        isError: isErrorAreas,
        error: errorAreas,
    } = useGetAreas({
        locationIds: currentEmployee.currentEmployments.flatMap(employment => employment.location.id) ?? [],
    });

    const {
        data: breakDuration,
        isLoading: isLoadingBreakDuration,
        isError: isErrorBreakDuration,
        error: errorBreakDuration,
        refetch,
    } = useGetClockOutForceBreakDuration({
        employeeId: currentEmployee?.id,
    });

    useEffect(
        function refetchForceBreakDuration() {
            const fetchForceBreakInterval = setInterval(() => {
                refetch().catch(handleError);
            }, FETCH_FORCE_BREAK_SECONDS * 1000);
            // Clean up the interval to avoid memory leaks
            return () => clearInterval(fetchForceBreakInterval);
        },
        [refetch],
    );

    const isBreakRequired = (): boolean => {
        return !(!breakDuration || breakDuration === 0 || !startDate || !endDate);
    };

    const schema = getTimesheetClockOutFormSchema({
        mandatoryComment: timesheetSetting?.mandatoryComment || false,
        breakRequired: isBreakRequired(),
        startDate: startDate,
        endDate: endDate,
    });

    const formMethods = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            areaId: timesheet?.area?.id,
        },
    });

    const { control, handleSubmit } = formMethods;

    if (!currentEmployee) {
        return;
    }
    const onSaveDialog = (formValues: TimesheetClockOutFormValues) => {
        if (!startDate) {
            return;
        }
        const mutation: ClockInOutCreationMutation = {
            employeeId: currentEmployee?.id,
            dateTime: endDate,
            areaId: formValues.areaId,
            comment: formValues.comment,
        };

        const employeeTimesheetMutation = createEmployeeTimesheetMutation(
            startDate,
            mutation,
            formValues.breakStart,
            formValues.breakEnd,
            breakDuration,
            timesheet,
        );

        onSave(mutation, employeeTimesheetMutation);
    };

    const areaResourceOptions = areas.map(area => ({ id: area.id, name: area.name }));
    const isLoading = isLoadingAreas || isLoadingBreakDuration;
    const error = errorAreas || errorBreakDuration;
    const isError = isErrorAreas || isErrorBreakDuration;

    return (
        <DialogWrapper open={!!timesheet} onClose={onClose} header={t('timesheet_clock_out_dialog.dialog_title')}>
            <DialogContent>
                <FormProvider {...formMethods}>
                    <StateHandler isLoading={isLoading} isError={isError} error={error}>
                        <Stack spacing={2}>
                            {!!startDate && !!endDate && <DisplayHoursAndDuration startAt={startDate} endAt={endDate} isBreakRequired={isBreakRequired()} />}
                            {!!areaResourceOptions.length && (
                                <Controller
                                    name={'areaId'}
                                    control={control}
                                    render={({ field: { onChange, ...restField }, fieldState }) => (
                                        <SelectResource
                                            {...restField}
                                            label={t('timesheet_clock_out_dialog.area')}
                                            placeHolder={t('timesheet_clock_out_dialog.area_placeholder')}
                                            isError={!!fieldState.error}
                                            clearButton={true}
                                            options={areaResourceOptions}
                                            onUpdate={value => {
                                                onChange(value);
                                            }}
                                        />
                                    )}
                                />
                            )}

                            <FormControlLabel
                                label={t('timesheet_clock_out_dialog.comment')}
                                style={{ width: '100%' }}
                                control={
                                    <FieldText
                                        name={`comment`}
                                        control={control}
                                        placeholder={t('timesheet_clock_out_dialog.comment_placeholder')}
                                        textFieldProps={{
                                            multiline: true,
                                            minRows: 1,
                                        }}
                                        fullWidth
                                    />
                                }
                            />
                        </Stack>
                    </StateHandler>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button fullWidth onClick={() => handleSubmit(onSaveDialog, console.error)()}>
                    {t('timesheet_clock_out_dialog.confirm_button')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type DisplayHoursAndDurationProps = {
    startAt: Date;
    endAt: Date;
    isBreakRequired: boolean;
};

const DisplayHoursAndDuration: FC<DisplayHoursAndDurationProps> = ({ startAt, endAt, isBreakRequired }) => {
    const { t } = useTranslation();

    const {
        setValue,
        control,
        formState: { errors },
    } = useFormContext<TimesheetClockOutFormValues>();

    const getNewEndDate = (oldEndDate: Date) => {
        const endTime = getTimeFormatFromDate(oldEndDate);
        if (!endTime) {
            return;
        }
        const endDate = setTime(startAt, endTime);

        // TODO the check seems wrong to know if the end date is on the same day as the start date
        const isEndDateOnSameDay = differenceInMinutes(endDate, startAt) >= 0;
        if (isEndDateOnSameDay) {
            return endDate;
        } else {
            return setTime(addDays(startAt, 1), endTime);
        }
    };

    const getNewStartDate = (oldStartDate: Date) => {
        const startTime = getTimeFormatFromDate(oldStartDate);
        if (!startTime) {
            return;
        }
        const startDate = setTime(endAt, startTime);

        // TODO the check seems wrong to know if the end date is on the same day as the start date
        const isStartDateOnSameDay = differenceInMinutes(endAt, startDate) >= 0;
        if (isStartDateOnSameDay) {
            return startDate;
        } else {
            return setTime(subDays(endAt, 1), startTime);
        }
    };

    const getDuration = (): string => {
        const diffInMinutes = differenceInMinutes(endAt, startAt);
        return formatDurationInHours(diffInMinutes);
    };

    const bgColor = isBreakRequired ? 'warning.light' : 'primary.light';
    const color = isBreakRequired ? 'warning.dark' : 'primary.dark';

    return (
        <Stack bgcolor={bgColor} borderRadius={1} justifyContent='center' alignItems='stretch' direction='column' display={'flex'} spacing={1} p={2}>
            <Stack direction='row' component={Typography} variant='body2bold' fontSize={30} color={color} alignItems='center' justifyContent='center' gap={2}>
                {getTimeFormatFromDate(startAt)}
                <ArrowRight01Icon strokeWidth={3} />
                {getTimeFormatFromDate(endAt)}
            </Stack>
            <Typography textAlign={'center'} fontSize={16} color={color}>
                {t('timesheet_clock_out_dialog.duration')} {getDuration()}
            </Typography>
            {isBreakRequired && (
                <>
                    <Typography textAlign={'center'} fontSize={16}>
                        {t('timesheet_clock_out_dialog.mandatory_break_duration')}
                    </Typography>
                    <Stack spacing={2} direction={'row'} alignItems={'flex-start'}>
                        {/* TODO replace FieldDateTime by FieldTime and manage dates outside*/}
                        <FormControlLabel
                            label={t('timesheet_clock_out_dialog.break_start')}
                            control={
                                <FieldDateTime
                                    name={`breakStart`}
                                    control={control}
                                    onChange={endTime => {
                                        const newEndDate = endTime ? getNewEndDate(endTime) : undefined;
                                        setValue('breakStart', newEndDate);
                                    }}
                                    timePickerProps={{
                                        slotProps: {
                                            textField: {
                                                // error is displayed outside of the field
                                                helperText: '',
                                            },
                                        },
                                    }}
                                />
                            }
                        />
                        <FormControlLabel
                            label={t('timesheet_clock_out_dialog.break_end')}
                            control={
                                <FieldDateTime
                                    name={`breakEnd`}
                                    control={control}
                                    onChange={startTime => {
                                        const newStartDate = startTime ? getNewStartDate(startTime) : undefined;
                                        setValue('breakEnd', newStartDate);
                                    }}
                                    timePickerProps={{
                                        slotProps: {
                                            textField: {
                                                // error is displayed outside of the field
                                                helperText: '',
                                            },
                                        },
                                    }}
                                />
                            }
                        />
                    </Stack>
                    {(!!errors?.breakStart || !!errors?.breakEnd) && (
                        <Alert severity='error' elevation={0} sx={{ alignItems: 'center' }}>
                            {!!errors?.breakStart && <Typography variant='body2'>{errors?.breakStart?.message}</Typography>}
                            {!!errors?.breakEnd && <Typography variant='body2'>{errors?.breakEnd?.message}</Typography>}
                        </Alert>
                    )}
                </>
            )}
        </Stack>
    );
};

const createEmployeeTimesheetMutation = (
    startDate: Date,
    clockInOutCreationMutation: ClockInOutCreationMutation,
    breakStart: Date | undefined,
    breakEnd: Date | undefined,
    breakDuration: number | undefined,
    timesheet: Timesheet,
): EmployeeTimesheetMutation | undefined => {
    const doNotCreateMutation =
        breakDuration === 0 ||
        !clockInOutCreationMutation ||
        !timesheet?.referenceDate ||
        !isValidDate(startDate) ||
        !isValidDate(clockInOutCreationMutation.dateTime) ||
        !isValidDate(breakStart) ||
        !isValidDate(breakEnd);

    if (doNotCreateMutation) {
        return undefined;
    }

    const firstTimesheet: TimesheetMutation = {
        id: timesheet.id,
        startTime: startDate,
        endTime: breakStart,
        comment: clockInOutCreationMutation.comment,
        areaId: clockInOutCreationMutation.areaId,
    };
    const secondTimesheet: TimesheetMutation = {
        startTime: breakEnd,
        endTime: clockInOutCreationMutation.dateTime,
        comment: clockInOutCreationMutation.comment,
        areaId: clockInOutCreationMutation.areaId,
    };

    return {
        employeeId: clockInOutCreationMutation.employeeId,
        timesheets: [firstTimesheet, secondTimesheet],
        referenceDate: timesheet.referenceDate,
    };
};

const getTimesheetClockOutFormSchema = ({
    mandatoryComment,
    breakRequired,
    startDate,
    endDate,
}: {
    mandatoryComment: boolean;
    breakRequired: boolean;
    startDate?: Date;
    endDate?: Date;
}) => {
    return yup.object().shape({
        areaId: yup.number(),
        comment: yup
            .string()
            .trim()
            .when({
                is: () => mandatoryComment,
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        breakStart: yup.date().when({
            is: () => !breakRequired,
            then: schema => schema,
            otherwise: schema =>
                schema.test({
                    message: i18next.t('timesheet_clock_out_dialog.break_start_error'),
                    test: (breakStart, context) => {
                        // @JessyBAER & @relkaddari LocalTime to fix
                        const correctedBreakStart = breakStart
                            ? getZonedTimeFromUTC(getDateTimeUTCFromDateAndTime(getCurrentLocalDate(), breakStart))
                            : undefined;
                        if (isValidDate(correctedBreakStart) && isValidDate(context.parent.breakEnd) && startDate && isValidDate(startDate)) {
                            return (
                                differenceInMinutes(correctedBreakStart, startDate) >= 0 &&
                                differenceInMinutes(context.parent.breakEnd, correctedBreakStart) >= 0
                            );
                        }
                        return false;
                    },
                }),
        }),
        breakEnd: yup.date().when({
            is: () => !breakRequired,
            then: () => yup.date(),
            otherwise: () =>
                yup
                    .date()
                    .required()
                    .test({
                        message: i18next.t('timesheet_clock_out_dialog.break_end_error'),
                        test: (breakEnd, context) => {
                            if (isValidDate(breakEnd) && isValidDate(context.parent.breakStart) && isValidDate(endDate)) {
                                return differenceInMinutes(endDate, breakEnd) >= 0 && differenceInMinutes(breakEnd, context.parent.breakStart) >= 0;
                            }
                            return false;
                        },
                    }),
        }),
    });
};

type TimesheetClockOutFormValues = yup.InferType<ReturnType<typeof getTimesheetClockOutFormSchema>>;
