import { RealmFeature, RealmFeaturesType } from '@/domain/realm/Realm.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { Page } from '@/page/Pages';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { canManageOtherEmployeePayrollLock, hasConfigurePayrollPolicy } from '@/domain/permission/Permission.service';

export const getPayrollPages = (realmFeatures: RealmFeature[], policies: EmployeePolicy[], employeeId: number): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'payroll.tabs.leaves',
            path: '/payroll/leaves',
            condition: () => hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.LEAVES) && hasConfigurePayrollPolicy(policies),
        },
        {
            labelKey: 'payroll.tabs.profile_changes',
            path: '/payroll/profile-changes',
            condition: () => hasConfigurePayrollPolicy(policies),
        },
        {
            labelKey: 'payroll.tabs.timesheet_payments',
            path: '/payroll/timesheet-payments',
            condition: () => hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.TIMESHEET),
        },
        {
            labelKey: 'payroll.tabs.employees_locks',
            path: '/payroll/locks',
            condition: () => canManageOtherEmployeePayrollLock(realmFeatures, policies, employeeId),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(realmFeatures, policies));
};
