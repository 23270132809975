import { Stack, Typography, useTheme } from '@mui/material';
import { AlertCircleIcon } from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const MissingCount: FC<{ missingCount?: number }> = ({ missingCount }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    if (!missingCount || missingCount === 0) {
        return '-';
    }
    return (
        <Stack direction={'row'} color={theme.palette.error.main} alignItems={'center'} gap={0.5}>
            <AlertCircleIcon color={theme.palette.error.main} />
            <Typography color={theme.palette.error.main}>
                {missingCount} {t('timesheets.table_headers.missing')}
            </Typography>
        </Stack>
    );
};
