import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { ActuatorInfo } from '@/domain/api-info/ApiInfo.model';
import { getApiInfo } from '@/domain/api-info/ApiInfo.service';

export const useGetApiInfo = (): UseQueryResult<ActuatorInfo> => {
    const [apiInfo, setApiInfo] = useState<ActuatorInfo>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchApiInfo = useCallback(async () => {
        try {
            const apiInfo = await getApiInfo();
            setApiInfo(apiInfo);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchApiInfo().catch(handleError);
    }, [fetchApiInfo]);

    return {
        data: apiInfo,
        setData: setApiInfo,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchApiInfo(),
    };
};
