import { Paper, Stack, Typography } from '@mui/material';
import { ArrowRight01Icon } from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type DaysAvailableComponentProps = {
    available: string;
    remaining: string;
    remainingDuration?: number;
};

export const DaysAvailableComponent: FC<DaysAvailableComponentProps> = ({ available, remaining, remainingDuration }) => {
    const { t } = useTranslation();

    const isRemainingNegative = !!remainingDuration && remainingDuration < 0;

    return (
        <Paper>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                borderRadius={({ shape }) => `${shape.borderRadius}px`}
                border={({ palette }) => `1px solid ${palette.grey[300]}`}
                py={1}
            >
                <Stack flex='1' textAlign='center'>
                    <Typography variant='h2'>{available}</Typography>
                    <Typography variant='body2'>{t('request_leave_dialog.available')}</Typography>
                </Stack>

                <ArrowRight01Icon />
                <Stack flex='1' textAlign='center'>
                    <Typography variant='h2' color={isRemainingNegative ? 'error' : undefined}>
                        {remaining}
                    </Typography>
                    <Typography variant='body2'>{t('request_leave_dialog.remaining')}</Typography>
                </Stack>
            </Stack>
        </Paper>
    );
};
