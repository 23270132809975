import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { ObjectiveSetting, ObjectiveSettingUpdateMutation } from '@/domain/objective-setting/ObjectiveSetting.model';
import { updateObjectiveSetting } from '@/domain/objective-setting/ObjectiveSetting.service';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Paper, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const schema = yup.object().shape({
    objectiveCategoriesEnabled: yup.boolean().required(),
    objectiveWeightEnabled: yup.boolean().required(),
    parentObjectiveEnabled: yup.boolean().required(),
});

type ObjectiveSettingsFormValues = yup.InferType<typeof schema>;

export const ObjectiveSettingsPage: FC = () => {
    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
        refetch: refetchObjectiveSetting,
    } = useGetObjectiveSetting();

    const handleUpdate = async (id: number, objectiveSettingMutation: ObjectiveSettingUpdateMutation) => {
        await updateObjectiveSetting(id, objectiveSettingMutation);
        await refetchObjectiveSetting();
    };

    return (
        <StateHandler isLoading={isLoadingObjectiveSetting} isError={isErrorObjectiveSetting} error={errorObjectiveSetting}>
            {objectiveSetting && <ObjectiveSettingsForm objectiveSetting={objectiveSetting} onUpdate={handleUpdate} />}
        </StateHandler>
    );
};

type ObjectiveSettingsFormProps = {
    objectiveSetting: ObjectiveSetting;
    onUpdate: (id: number, objectiveSettingMutation: ObjectiveSettingUpdateMutation) => void;
};

const ObjectiveSettingsForm: FC<ObjectiveSettingsFormProps> = ({ objectiveSetting, onUpdate }) => {
    const { t } = useTranslation();

    const { handleSubmit, control } = useForm<ObjectiveSettingsFormValues>({
        resolver: yupResolver(schema),
        defaultValues: objectiveSetting,
    });

    const handleUpdate = (data: ObjectiveSettingsFormValues) => {
        onUpdate(objectiveSetting.id, data);
    };

    return (
        <form onChange={handleSubmit(handleUpdate)}>
            <Stack component={Paper} p={2} gap={2}>
                <FormControlLabel
                    label={t('objectives_settings_page.settings.objective_weight')}
                    labelPlacement='end'
                    control={<FieldSwitch name='objectiveCategoriesEnabled' control={control} />}
                />

                <FormControlLabel
                    label={t('objectives_settings_page.settings.objective_categories')}
                    labelPlacement='end'
                    control={<FieldSwitch name='objectiveWeightEnabled' control={control} />}
                />
                <FormControlLabel
                    label={t('objectives_settings_page.settings.parent_objective')}
                    labelPlacement='end'
                    control={<FieldSwitch name='parentObjectiveEnabled' control={control} />}
                />
            </Stack>
        </form>
    );
};
