import { FormControlLabel, FormControlLabelProps, RadioGroup, RadioGroupProps, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Children, cloneElement, FC, PropsWithChildren, ReactElement, useCallback, useState } from 'react';

type RadioLineGroupProps = RadioGroupProps & {
    error?: boolean;
};

export const RadioLineGroup: FC<PropsWithChildren<RadioLineGroupProps>> = ({ error = false, children, ...rest }) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [stackWidth, setStackWidth] = useState(0);
    const radioElements = Children.toArray(children).length;
    const radioWidth = 24;
    const dividerWidth = (stackWidth - radioElements * radioWidth) / (radioElements - 1) / 2;

    const measuredRef = useCallback((node: Element) => {
        if (node) {
            setStackWidth(node.getBoundingClientRect().width);
        }
    }, []);

    const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
            setStackWidth(entry.contentRect.width);
        }
    });

    const targetElement = document.getElementById('radioGroup');
    if (targetElement) {
        resizeObserver.observe(targetElement);
    }

    return (
        <Stack direction={isTablet ? 'row' : 'column'} gap={1} sx={{ flexWrap: 'nowrap' }}>
            <RadioGroup
                ref={measuredRef}
                row={!isTablet}
                sx={{
                    justifyContent: 'space-between',
                    flex: 1,
                }}
                id={'radioGroup'}
                aria-invalid={!!error}
                {...rest}
            >
                {Children.map(children, child => (
                    <Stack
                        justifyContent={'center'}
                        textAlign={'center'}
                        direction={isTablet ? 'column' : 'row'}
                        p={0.5}
                        flex={1}
                        sx={{
                            wordBreak: 'break-word',

                            position: 'relative',
                            '.MuiRadio-root::after ': isTablet
                                ? {}
                                : {
                                      content: '""',
                                      position: 'absolute',
                                      left: '28px',
                                      width: `${dividerWidth}px`,
                                      height: '1px',
                                      backgroundColor: error ? theme.palette.error.main : theme.palette.text.primary,
                                  },
                            '.MuiRadio-root::before': isTablet
                                ? {}
                                : {
                                      content: '""',
                                      position: 'absolute',
                                      width: `${dividerWidth}px`,
                                      right: '28px',
                                      height: '1px',
                                      backgroundColor: error ? theme.palette.error.main : theme.palette.text.primary,
                                  },
                            '&:first-of-type': {
                                '.MuiRadio-root::before': {
                                    display: 'none',
                                },
                            },
                            '&:last-child': {
                                '.MuiRadio-root::after': {
                                    display: 'none',
                                },
                            },
                        }}
                    >
                        {cloneElement(child as ReactElement, { sx: { '> *': { color: error ? theme.palette.error.main : theme.palette.text.primary } } })}
                    </Stack>
                ))}
            </RadioGroup>
        </Stack>
    );
};

type TooltipFormControlLabelProps = FormControlLabelProps & {
    description?: string;
};

export const TooltipFormControlLabel: FC<TooltipFormControlLabelProps> = ({ description, ...rest }) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Tooltip title={description} enterTouchDelay={0} leaveTouchDelay={100000}>
            <FormControlLabel
                {...rest}
                labelPlacement={isTablet ? 'end' : 'bottom'}
                sx={{
                    margin: 0.5,
                    marginRight: isTablet ? 'auto' : 0,
                    textAlign: isTablet ? 'start' : 'center',
                }}
            />
        </Tooltip>
    );
};
