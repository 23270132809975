import { Box, Stack, StackProps, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

type SectionFieldContainerProps = {
    title: string;
    formValueName: string;
    required?: boolean;
} & StackProps;

export const SectionFieldContainer: FC<PropsWithChildren<SectionFieldContainerProps>> = ({ title, formValueName, required, children, ...rest }) => {
    const {
        formState: { errors },
    } = useFormContext<Record<string, unknown>>();

    return (
        <Stack spacing={1} flex={1} justifyContent='space-between' alignItems={'flex-start'} direction='row' {...rest}>
            {/* When there is an error, the error field misalign the typography with the EditableSectionFieldComponent  */}
            {/* So we add a ∞marginBottom to the typography to align it with the EditableSectionFieldComponent */}
            <Typography marginBottom={errors?.[formValueName] ? 2.9 : 0} variant='body1bold' noWrap flex={{ xs: 6, sm: 4, md: 2 }}>
                {title}
                {required ? '*' : ''}
            </Typography>

            <Box display={'flex'} flex={{ xs: 6, sm: 8, md: 10 }}>
                {children}
            </Box>
        </Stack>
    );
};
