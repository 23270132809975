import { FC } from 'react';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, FormControlLabel, Stack, Typography } from '@mui/material';
import { Department, DepartmentUpdateMutation } from '@/domain/department/Department.model';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { ArrowRight02Icon } from 'hugeicons-react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getLocalDateTestConfig } from '@/utils/datetime.util';
import { TreeViewItem } from '@/components/tree-view/TreeView';
import { findItemById, findParents } from '@/components/tree-select/TreeSelect.util';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { archiveDepartment, createDepartment, updateDepartment, updateDepartmentOrders } from '@/domain/department/Department.service';
import { handleError } from '@/utils/api.util';
import { Employee } from '@/domain/employee/Employee.model';
import { EmploymentBulkCreateMutationItem, EmploymentBulkMutation, EmploymentCreateReason } from '@/domain/employment/Employment.model';
import { bulkCreateEmployment } from '@/domain/employment/Employment.service';
import { getLabelTranslation } from '@/utils/language.util';

type DepartmentMoveDialogProps = {
    departmentTreeItems: TreeViewItem[];
    department: Department;
    newParentId: number | undefined; // undefined means the department is moved to the root
    newIndex: number;
    onSave?: () => void;
} & DialogWrapperProps;

export const DepartmentMoveDialog: FC<DepartmentMoveDialogProps> = ({ departmentTreeItems, department, newParentId, newIndex, onSave, ...restDialog }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(getSchema()),
        defaultValues: {
            applyChangesInTheFuture: false,
        },
    });

    const applyChangesInTheFuture = useWatch({ control, name: 'applyChangesInTheFuture' });

    const previousHierarchy = findParents(departmentTreeItems, department.id);
    const newParentItem = newParentId ? findItemById(departmentTreeItems, newParentId) : undefined;
    const newHierarchy = newParentItem ? [...findParents(departmentTreeItems, newParentItem.id), newParentItem] : undefined;

    const handleSave = async ({ applyChangesInTheFuture, effectiveDate }: DepartmentMoveFormValues) => {
        const shouldCreateNewEmployments = applyChangesInTheFuture && effectiveDate;

        try {
            if (shouldCreateNewEmployments) {
                await archiveAndCreateNewDepartment({
                    departmentTreeItems,
                    department,
                    newParentId,
                    newIndex,
                    effectiveDate,
                });
            } else {
                await moveIntoOtherParent({
                    departmentTreeItems,
                    department,
                    newParentId,
                    newIndex,
                });
            }
            restDialog.onClose();
            onSave?.();
        } catch (error) {
            handleError(error);
        }
    };

    const getFullPath = (parents: TreeViewItem[]) => {
        const allNodeNames = [...parents.map(p => p.label), getLabelTranslation(department.name)];
        return '/ ' + allNodeNames.join(' / ');
    };

    return (
        <DialogWrapper {...restDialog} header={t('settings_organization.departments.move_department_dialog.title')} maxWidth={'md'}>
            <Stack gap={3} component={DialogContent}>
                <FieldSwitch
                    name={'applyChangesInTheFuture'}
                    control={control}
                    label={t('settings_organization.departments.move_department_dialog.apply_changes_in_the_future')}
                    labelPlacement={'end'}
                />

                {applyChangesInTheFuture && (
                    <FormControlLabel
                        control={<FieldLocalDate control={control} name={'effectiveDate'} />}
                        label={t('settings_organization.departments.move_department_dialog.effective_date')}
                    />
                )}
                <Stack>
                    <Typography variant={'body1bold'} sx={{ mb: 1 }}>
                        {t('settings_organization.departments.move_department_dialog.parents_changes')}
                    </Typography>

                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                        <Typography
                            variant={'body1bold'}
                            sx={{
                                flex: 1,
                                textDecoration: 'line-through',
                                color: 'red',
                            }}
                        >
                            {getFullPath(previousHierarchy)}
                        </Typography>
                        <Stack direction={'row'} alignItems={'center'} gap={2} flex={1}>
                            <ArrowRight02Icon />
                            <Typography>{getFullPath(newHierarchy ?? [])}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave, console.error)} fullWidth={true}>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getSchema = () => {
    return yup.object().shape({
        applyChangesInTheFuture: yup.boolean().required().default(false),
        effectiveDate: yup
            .string<LocalDate>()
            .test(getLocalDateTestConfig())
            .when('applyChangesInTheFuture', {
                is: true,
                then: schema => schema.required(),
            }),
    });
};
type DepartmentMoveFormValues = yup.InferType<ReturnType<typeof getSchema>>;

type MoveIntoOtherParentParams = {
    departmentTreeItems: TreeViewItem[];
    department: Department;
    newParentId: number | undefined;
    newIndex: number;
};
const moveIntoOtherParent = async ({ departmentTreeItems, department, newParentId, newIndex }: MoveIntoOtherParentParams) => {
    const departmentId = department.id;
    const item = findItemById(departmentTreeItems, departmentId);
    if (!item) {
        return;
    }

    // change the department parent
    try {
        const updateMutation: DepartmentUpdateMutation = {
            name: department.name,
            order: newIndex,
            parentId: newParentId,
            managerIds: department.managers.map(m => m.id),
            departmentCostCenters: department.departmentCostCenters.map(dcc => ({
                costCenterId: dcc.costCenter.id,
                percentage: dcc.percentage,
            })),
        };
        await updateDepartment(departmentId, updateMutation);
    } catch (error) {
        handleError(error);
    }

    // build mutations of the previous children it was in
    // an undefined parentId means the department is at the root
    const childrenSource = department.parentId ? (findItemById(departmentTreeItems, department.parentId)?.children ?? []) : departmentTreeItems;
    const childrenSourceOrderMutation = childrenSource
        .filter(child => child.id !== department.id) // exclude the moved item
        .map((child, index) => ({
            resourceId: child.id,
            order: index,
        }));

    // build mutations of the new children with the moved item
    // an undefined parentId means the department is at the root
    const childrenDestination = newParentId ? (findItemById(departmentTreeItems, newParentId)?.children ?? []) : departmentTreeItems;
    const newChildrenDestination = [...childrenDestination];
    newChildrenDestination.splice(newIndex, 0, item); // insert the moved item

    const childrenDestinationOrderMutation = newChildrenDestination.map((child, index) => ({
        resourceId: child.id,
        order: index,
    }));

    // reorder separately the source and destination children (each branch of the tree has its own order)
    try {
        await updateDepartmentOrders([...childrenSourceOrderMutation, ...childrenDestinationOrderMutation]);
    } catch (error) {
        handleError(error);
    }
};

type ArchiveAndCreateNewDepartment = MoveIntoOtherParentParams & { effectiveDate: LocalDate };
const archiveAndCreateNewDepartment = async ({ departmentTreeItems, department, newParentId, newIndex, effectiveDate }: ArchiveAndCreateNewDepartment) => {
    const departmentId = department.id;
    const item = findItemById(departmentTreeItems, departmentId);
    if (!item) {
        return;
    }

    try {
        // 1- GET employees in department
        const employeesInDepartment = await searchEmployees({
            departmentIds: [department.id],
        });

        // 2- Create a new department, same name but different parent
        const newDepartment = await createDepartment({
            name: department.name,
            parentId: newParentId,
            managerIds: department.managers.map(m => m.id),
            departmentCostCenters: department.departmentCostCenters.map(dcc => ({
                costCenterId: dcc.costCenter.id,
                percentage: dcc.percentage,
            })),
            order: newIndex,
        });

        // 3- Create new employment for each employee with the new department
        await createNewEmployments(employeesInDepartment, newDepartment, effectiveDate);

        // 4- archive the moved department
        await archiveDepartment(departmentId);

        // 5- Reorder the new branch
        // build mutations of the new children with the moved item
        // an undefined parentId means the department is at the root
        const childrenDestination = newParentId ? (findItemById(departmentTreeItems, newParentId)?.children ?? []) : departmentTreeItems;
        const newChildrenDestination = [...childrenDestination];
        newChildrenDestination.splice(newIndex, 0, item); // insert the moved item

        const childrenDestinationOrderMutation = newChildrenDestination.map((child, index) => ({
            resourceId: child.id,
            order: index,
        }));
        await updateDepartmentOrders(childrenDestinationOrderMutation);
    } catch (error) {
        handleError(error);
    }
};

const createNewEmployments = async (employees: Employee[], department: Department, effectiveDate: LocalDate) => {
    if (!employees.length) {
        return;
    }
    const mutations = employees.reduce<EmploymentBulkMutation[]>((acc, employee) => {
        const employmentsByEmployeeMutation = employee.currentEmployments.reduce<EmploymentBulkCreateMutationItem[]>((accByEmployee, employment) => {
            const request: EmploymentBulkCreateMutationItem = {
                // updated attributes
                startDate: effectiveDate,
                employmentCostCenters: department.departmentCostCenters.map(dcc => ({
                    costCenterId: dcc.costCenter.id,
                    percentage: dcc.percentage,
                })),
                employmentCreateReason: EmploymentCreateReason.NEW_ENTITY,
                // fill values from the employment
                managerIds: employment.managers.map(m => m.id),
                principal: employment.principal,
                contractType: employment.contractType,
                locationId: employment.location.id,
                jobId: employment.job.id,
                jobFamilyId: employment.jobFamily?.id,
                departmentId: employment.department.id,
                percentage: employment.percentage,
            };

            return [...accByEmployee, request];
        }, [] as EmploymentBulkCreateMutationItem[]);

        return [...acc, { employeeId: employee.id, action: 'CREATE', creationRequests: employmentsByEmployeeMutation }];
    }, [] as EmploymentBulkMutation[]);

    try {
        await bulkCreateEmployment(mutations);
    } catch (error) {
        handleError(error);
    }
};
