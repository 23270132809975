import { AgGridWrapper, AgGridWrapperProps, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { CalendarDay, CalendarDayType } from '@/domain/calendar/Calendar.model';
import { formatDate } from '@/utils/datetime.util';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    days: CalendarDay[];
    onClick: (day: CalendarDay) => void;
} & AgGridWrapperProps<CalendarDay>;

export const CalendarDaysTable: FC<Props> = ({ days, onClick, ...rest }) => {
    const { t } = useTranslation();

    const columnDefs: RogerColDef<CalendarDay>[] = [
        {
            field: 'name',
            headerName: t('calendar_dialog.table_headers.day_name'),
        },
        {
            field: 'date',
            headerName: t('calendar_dialog.table_headers.date'),
            type: 'date',
            sort: 'asc',
        },
        {
            field: 'date',
            headerName: t('calendar_dialog.table_headers.day'),
            valueGetter: ({ data }) => {
                return data?.date ? formatDate(data.date, 'EEEE') : '';
            },
        },
        {
            field: 'dayType',
            headerName: t('calendar_dialog.table_headers.type'),
            valueFormatter: data => {
                return data?.data?.dayType === CalendarDayType.HOLIDAY ? t('domain.calendar_day_type.holiday') : t('domain.calendar_day_type.working_day');
            },
        },
    ];

    return (
        <AgGridWrapper<CalendarDay>
            rowData={days}
            onRowClicked={({ data }) => {
                if (data) {
                    onClick(data);
                }
            }}
            columnDefs={columnDefs}
            {...rest}
        />
    );
};
