import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { RequestStatusChip } from '@/components/request-status-chip/RequestStatusChip';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Button, DialogActions, DialogContent, FormControlLabel, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatePickerWrapper } from '@/components/date-picker/DatePickerWrapper';
import { getRequestStatusTranslationKey } from '@/domain/leave-request/LeaveRequest.service';
import {
    ApproveTimesheetPaymentMutation,
    DeclineTimesheetPaymentMutation,
    TimesheetPayment,
    TimesheetPaymentSearchRequest,
} from '@/domain/timesheet-payment/TimesheetPayment.model';
import { approveTimesheetPayments, cancelTimesheetPayments, declineTimesheetPayments } from '@/domain/timesheet-payment/TimesheetPayment.service';
import { TimesheetsRequestStatus } from '@/domain/timesheet/Timesheet.model';
import { useTimesheetPaymentsSearch } from '@/hooks/timesheet/Timesheet.hook';
import { handleError } from '@/utils/api.util';
import { formatInDefaultDate, getCurrentLocalDate } from '@/utils/datetime.util';

import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { DateRangePicker } from '@/components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/components/date-range-picker/DateRangePicker.hook';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { AsyncSelectFilter, FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { getDepartments } from '@/domain/department/Department.service';
import { getLocations } from '@/domain/location/Location.service';
import { getLabelTranslation } from '@/utils/language.util';
import { Tick01Icon } from 'hugeicons-react';

/*
TODO: the due filter was removed until we have a better date picker, at the moment is mandatory to pick the 2 filters
If we will not use this filter anymor we can remove it from the page (just delete all the code comments related to it)

For more information on why we removed the filter, please check the following PR-3564
 */
export const PayrollTimesheetPaymentsPage: FC = () => {
    const { t } = useTranslation();

    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
    const [paymentDueDate, setPaymentDueDate] = useState<LocalDate>(getCurrentLocalDate());
    const agGridWrapper = useAgGridWrapper<TimesheetPayment>();

    const { filters: availableFilters } = getPayrollTimesheetPaymentsFilters();
    const [filters, setFilters] = useFiltersStorage('payroll-timesheet-payments-filters', availableFilters);

    const { dateRange, dateRangeViewType, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'payroll-timesheet-payments-date-range',
    });

    const timesheetPaymentSearchRequest: TimesheetPaymentSearchRequest = {
        locationIds: getFilterValueIdsByKey(filters, 'locationIds'),
        departmentIds: getFilterValueIdsByKey(filters, 'departmentIds'),
        requestStartDate: dateRange[0],
        requestEndDate: dateRange[1],
    };

    const { data: timesheetPayments, isFetching = true, refetch: refetchTimesheetPayments } = useTimesheetPaymentsSearch(timesheetPaymentSearchRequest);

    const onBtnExport = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
            // If we don't provide columnKeys, it will  add an empty column for row selection (I suppose)
            columnKeys: columnDefs.map(columnDef => columnDef.colId ?? columnDef.field).filter(c => c !== undefined),
        });
    };

    const refreshAndClearSelection = () => {
        refetchTimesheetPayments().catch(handleError);
        setSelectedRows([]);
    };

    function onSelectionChanged() {
        const selectedRows = agGridWrapper.gridRef.current?.api?.getSelectedRows() ?? [];
        setSelectedRows(selectedRows.map(row => row.id));
    }

    const declinePayments = async () => {
        const declineTimesheetPaymentsRequest: DeclineTimesheetPaymentMutation = {
            paymentIds: selectedRows,
        };
        try {
            await declineTimesheetPayments(declineTimesheetPaymentsRequest);
            refreshAndClearSelection();
        } catch (error) {
            handleError(error);
        }
    };

    const onClose = () => {
        setIsApproveModalOpen(false);
    };

    const onApproveTimesheetPayments = async () => {
        const approveTimesheetPaymentsRequest: ApproveTimesheetPaymentMutation = {
            paymentIds: selectedRows,
            paymentDueDate: paymentDueDate,
        };
        try {
            await approveTimesheetPayments(approveTimesheetPaymentsRequest);
            setIsApproveModalOpen(false);
            refreshAndClearSelection();
        } catch (error) {
            handleError(error);
        }
    };

    if (!timesheetPayments) {
        return <></>;
    }

    const menuRenderer = (data: ICellRendererParams<TimesheetPayment>) => {
        const handleOnCancel = async (id: number) => {
            const approveRequest = {
                paymentIds: [id],
            };
            try {
                await cancelTimesheetPayments(approveRequest);
                refreshAndClearSelection();
            } catch (error) {
                handleError(error);
            }
        };

        const handleOnDecline = async (id: number) => {
            const declineTimesheetPaymentsRequest: DeclineTimesheetPaymentMutation = {
                paymentIds: [id],
            };
            try {
                await declineTimesheetPayments(declineTimesheetPaymentsRequest);
                refreshAndClearSelection();
            } catch (error) {
                handleError(error);
            }
        };

        const menuItems: BasicMenuItem[] = [
            {
                title: t('general.approve'),
                onClick: () => {
                    if (data.data?.id) {
                        setIsApproveModalOpen(true);
                        setSelectedRows([data.data.id]);
                    }
                },
            },
            {
                title: t('general.decline'),
                onClick: async () => {
                    if (data.data?.id) {
                        try {
                            await handleOnDecline(data.data.id);
                        } catch (error) {
                            handleError(error);
                        }
                    }
                },
            },
            {
                title: t('general.cancel'),
                onClick: async () => {
                    if (data.data?.id) {
                        try {
                            await handleOnCancel(data.data.id);
                        } catch (error) {
                            handleError(error);
                        }
                    }
                },
            },
        ];
        return menuItems.some(item => !item.hide) && <BasicMenu items={menuItems} />;
    };

    const columnDefs: RogerColDef<TimesheetPayment>[] = [
        {
            field: 'employee.email',
            headerName: 'Email',
            hide: true,
        },
        {
            field: 'employee',
            type: 'employee',
            headerName: t('general.employee'),
        },
        {
            headerName: t('timesheets.payments_page.table_headers.duration'),
            field: 'amountInMinutes',
            type: 'minutesToHours',
        },
        {
            headerName: t('timesheets.payments_page.table_headers.request_date'),
            field: 'requestDate',
            type: 'date',
        },
        {
            headerName: t('timesheets.payments_page.table_headers.comment'),
            field: 'comment',
            // Avoid big comments to break the table layout
            maxWidth: 400,
            tooltipValueGetter: ({ value }) => value,
        },
        {
            headerName: t('timesheets.payments_page.table_headers.payment_date'),
            field: 'paymentDueDate',
            type: 'date',
        },
        {
            field: 'status',
            headerName: t('timesheets.payments_page.table_headers.status'),
            cellClass: ['display-flex'],
            cellRenderer: StatusChips,
            valueFormatter: ({ value }) => t(getRequestStatusTranslationKey(value)),
        },
        {
            headerName: t('timesheets.payments_page.table_headers.requested_by'),
            field: 'createdBy.displayName',
        },
        {
            field: 'employee.employeeCode',
            headerName: t('payroll.id'),
            hide: true,
        },
        {
            type: 'actionMenu',
            cellRenderer: menuRenderer,
        },
    ];
    return (
        <Stack direction='column' gap={2} flex={1}>
            <Stack spacing={3} alignItems='center' direction='row' justifyContent='space-between' component={Paper} p={1}>
                <Stack direction='row' alignItems='flex-start' gap={1} flexWrap={'wrap'}>
                    <DateRangePicker
                        dates={dateRange}
                        onDatesChanged={onDateRangeChange}
                        defaultViewType={dateRangeViewType}
                        availableViews={['MONTH', 'RANGE']}
                    />
                    <FiltersBar filters={filters} onFiltersChange={setFilters} flex={1} />
                </Stack>

                <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} onBtnExport={onBtnExport} />
            </Stack>

            <Stack component={Paper} flex={1}>
                <AgGridWrapper<TimesheetPayment>
                    rowData={timesheetPayments}
                    initRef={agGridWrapper.setGridRef}
                    onSelectionChanged={onSelectionChanged}
                    rowSelection={{
                        mode: 'multiRow',
                    }}
                    getRowId={params => params.data.id.toString()}
                    columnDefs={columnDefs}
                    loading={isFetching}
                    toolbarActions={
                        <Stack direction='row' gap={1}>
                            <Button onClick={() => setIsApproveModalOpen(true)}> {t('timesheets.approve_payment')}</Button>
                            <Button onClick={declinePayments} color='error'>
                                {' '}
                                {t('timesheets.decline_payment')}{' '}
                            </Button>
                        </Stack>
                    }
                />
            </Stack>

            <DialogWrapper header={t('timesheets.approve_payment')} open={isApproveModalOpen} onClose={onClose}>
                <Stack gap={1} component={DialogContent}>
                    {!!selectedRows?.length && (
                        <Stack direction='row' gap={0.5}>
                            <Tick01Icon />
                            <Typography variant='body1bold'>{t('timesheets.payment_will_be_approved', { count: selectedRows.length })}</Typography>
                        </Stack>
                    )}
                    <FormControlLabel
                        label={t('timesheets.expected_payment_date')}
                        labelPlacement='top'
                        style={{ width: '100%' }}
                        control={
                            <DatePickerWrapper
                                value={paymentDueDate}
                                onChange={newStart => {
                                    if (newStart) {
                                        setPaymentDueDate(newStart);
                                    }
                                }}
                            />
                        }
                    />
                </Stack>
                <DialogActions>
                    <Button onClick={onApproveTimesheetPayments} fullWidth>
                        {t('general.approve')}
                    </Button>
                </DialogActions>
            </DialogWrapper>
        </Stack>
    );
};

const StatusChips = (params: ICellRendererParams<TimesheetPayment>): ReactElement => {
    const { t } = useTranslation();
    const { status, paymentDueDate } = params.data ?? {};
    if (!status) {
        return <></>;
    }

    if (status === TimesheetsRequestStatus.APPROVED && paymentDueDate) {
        const approvedStatusText = t('timesheets.status_chip_payments_due_date', { dueDate: formatInDefaultDate(paymentDueDate) });
        return (
            <Tooltip title={approvedStatusText}>
                <RequestStatusChip status={status} />
            </Tooltip>
        );
    }

    return <RequestStatusChip status={status} />;
};

type PayrollTimesheetPaymentsFilter = AsyncSelectFilter;
const getPayrollTimesheetPaymentsFilters = (): {
    filters: PayrollTimesheetPaymentsFilter[];
} => {
    const filters: PayrollTimesheetPaymentsFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'locationIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            key: 'departmentIds',
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
