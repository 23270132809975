import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { getJobById, getJobs } from '@/domain/job/Job.service';
import { Job } from '@/domain/job/Job.model';
import { handleError } from '@/utils/api.util';

export const useGetJobs = (): UseQueryResult<Job[]> => {
    const [jobs, setJobs] = useState<Job[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchJobs = useCallback(async () => {
        try {
            const jobs = await getJobs();
            setJobs(jobs);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchJobs().catch(handleError);
    }, [fetchJobs]);

    return {
        data: jobs,
        setData: setJobs,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchJobs,
    };
};

export const useGetJob = (jobId: number | undefined): UseQueryResult<Job> => {
    const [job, setJob] = useState<Job>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchJob = useCallback(async () => {
        if (!jobId) {
            setIsLoading(false);
            return;
        }
        try {
            const job = await getJobById(jobId);
            setJob(job);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [jobId]);

    useEffect(() => {
        fetchJob().catch(handleError);
    }, [fetchJob]);

    return {
        data: job,
        setData: setJob,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchJob,
    };
};
