import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { SectionFieldContainer } from '../../SectionFieldContainer';
import { SectionContainer } from '../../SectionContainer';
import { OnboardingProfileStepFormValues } from '@/page/people/on-boarding-form/EmployeeInformationsForm/EmployeeInformationForm';
import {
    EmployeeBasicInfo,
    useEmployeeBasicInfoSectionFields,
} from '@/page/employee-profile/employee-profile-info/EmployeeBasicInfoSection/EmployeeBasicInfoSection.hook';
import { SectionDefinition, SectionFieldDefinition } from '@/domain/section-setting/Section.model';
import { SectionDefinitionFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { getFormValueByFieldType } from '@/domain/section-setting/Section.service';

type BasicInfosSectionProps = {
    baseProfileFormValues: Partial<OnboardingProfileStepFormValues>;
    sectionDefinition: SectionDefinition;
};

export const BasicInfosSection: FC<BasicInfosSectionProps> = ({ baseProfileFormValues, sectionDefinition }) => {
    const { t } = useTranslation();

    const mapFormValuesToBasicInfo = (formValues: Partial<SectionDefinitionFormValues>): EmployeeBasicInfo => {
        return {
            firstName: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_FIRSTNAME', formValues) ?? '',
            lastName: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_LASTNAME', formValues) ?? '',
            maidenName: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_MAIDEN_NAME', formValues) ?? '',
            displayName: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_DISPLAY_NAME', formValues) ?? '',
            email: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_EMAIL', formValues) ?? '',
            phoneNumber: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_PHONE_NUMBER', formValues) ?? '',
            employeeCode: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_CODE', formValues) ?? '',
        };
    };

    const employeeBasicInfoValues = mapFormValuesToBasicInfo(baseProfileFormValues);

    const basicInfosSectionFields = useEmployeeBasicInfoSectionFields(sectionDefinition, employeeBasicInfoValues, {
        editionMode: true,
        emailEditable: true,
    });

    // Hide the display name field in the onboarding form
    const hiddenFields: SectionFieldDefinition['fieldType'][] = ['EMPLOYEE_DISPLAY_NAME'];
    const basicInfoOnboardingFields = basicInfosSectionFields.filter(field => !hiddenFields.includes(field.fieldType));

    return (
        <SectionContainer title={t('employee.sections.basic_info')}>
            {!!basicInfosSectionFields?.length &&
                basicInfoOnboardingFields.map(basicInfosSectionField => {
                    return (
                        <SectionFieldContainer
                            key={basicInfosSectionField.title}
                            formValueName={basicInfosSectionField.formValueName ?? ''}
                            title={basicInfosSectionField.title ?? ''}
                            required={basicInfosSectionField.required}
                        >
                            <EditableSectionFieldComponent field={basicInfosSectionField} />
                        </SectionFieldContainer>
                    );
                })}
        </SectionContainer>
    );
};
