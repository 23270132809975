import { Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { FC } from 'react';
import { ArrowRight01Icon } from 'hugeicons-react';

type Props = {
    activeStep: number;
    steps: string[];
};

export const WizardBreadcrumbs: FC<Props> = ({ activeStep, steps }) => {
    return (
        <Breadcrumbs aria-label='breadcrumb' separator={<ArrowRight01Icon fontSize='small' />}>
            {steps.map((step, index) => (
                <Typography key={`wizard_${index}`} color='textPrimary' sx={activeStep === index ? { color: 'primary', fontWeight: 'bold' } : {}}>
                    {step}
                </Typography>
            ))}
        </Breadcrumbs>
    );
};
