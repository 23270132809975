import { CircularProgress, CircularProgressProps, Stack } from '@mui/material';
import { FC } from 'react';

export const Spinner: FC<CircularProgressProps> = props => {
    return (
        <Stack flex='1' justifyContent='center' alignItems='center'>
            <CircularProgress {...props} />
        </Stack>
    );
};
