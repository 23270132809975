import { ColorList } from '@/components/color-list/ColorList';
import { ColorPickerPopover } from '@/components/color-picker-popover/ColorPickerPopover';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { PlanningPosition, PlanningPositionCreationMutation } from '@/domain/planning-position/PlanningPosition.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    open: boolean;
    handleSave: (data: PlanningPositionCreationMutation, id?: number) => void;
    closeDialog: () => void;
    activePositionData?: PlanningPosition;
};

export const PlanningPositionSettingsDialog: FC<Props> = ({ open, handleSave, closeDialog, activePositionData }) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        name: yup.string().trim().required(),
        color: yup.string().trim().required(),
    });

    const { palette } = useTheme();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        getValues,
    } = useForm<{ name: string; color: string }>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            color: '',
        },
    });

    useEffect(() => {
        if (open) {
            reset({
                name: activePositionData?.name ?? '',
                color: activePositionData?.color ? activePositionData?.color : palette.primary.main,
            });
        }
    }, [activePositionData, open, palette.primary.main, reset]);

    const isEdit = !!activePositionData?.id;

    const color = getValues('color');

    const onSave = (data: { name: string; color: string }) => {
        handleSave({ ...data, color }, activePositionData?.id);
    };

    const updateColor = (color: string) => {
        reset({ color });
    };
    return (
        <DialogWrapper open={open} onClose={closeDialog} header={!isEdit ? t('planning_setting_page.add_position') : t('planning_setting_page.edit_position')}>
            <Stack gap={2} component={DialogContent}>
                <FormControlLabel
                    label={t('planning_setting_page.position_name')}
                    labelPlacement='top'
                    control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
                />
                <Typography variant='body2'>{t('planning_setting_page.color')}</Typography>
                <Stack direction={'row'} flexWrap={'wrap'}>
                    <ColorList color={color} onChangeColor={updateColor} />

                    <ColorPickerPopover color={color} onChangeColor={updateColor} />
                </Stack>
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(onSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
