import { Folder } from '@/domain/document/Document.model';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

export const useFolderParam = (
    folders: Folder[],
): {
    folderId: number | undefined;
    setFolderId: (newValue: number) => void;
} => {
    const [searchParams, setSearchParams] = useSearchParams();
    const folderId = Number(searchParams.get('folderId')) || undefined;

    const setFolderId = (newValue: number) => {
        setSearchParams({ folderId: newValue?.toString() });
    };

    const defaultFolderId = folders.length > 0 ? folders[0].id : undefined;

    useEffect(() => {
        // set the first folder as default
        if (!folderId && defaultFolderId) {
            setSearchParams({ folderId: defaultFolderId.toString() });
        }
    }, [folderId, setSearchParams, defaultFolderId]);

    return { folderId, setFolderId };
};
