import { Area } from '@/domain/area/Area.model';
import { searchAreas } from '@/domain/area/Area.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetAreas = ({ locationIds }: { locationIds: number[] }): UseQueryResult<Area[]> => {
    const [areas, setAreas] = useState<Area[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchAreas = useCallback(async (locationIds: number[]) => {
        try {
            const areas = await searchAreas({
                locationIds,
            });
            setAreas(areas);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchAreas(locationIds).catch(handleError);
    }, [fetchAreas, locationIds]);

    return {
        data: areas,
        setData: setAreas,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchAreas(locationIds),
    };
};
