import { FilePickerItem } from '@/components/file-picker/FilePicker';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export const useFilePicker = (
    initials: FilePickerItem[] = [],
): {
    files: FilePickerItem[];
    setFiles: Dispatch<SetStateAction<FilePickerItem[]>>;
    removeFile: (file: FilePickerItem) => void;
    addFiles: (newFiles: FilePickerItem[]) => void;
    updateFile: (file: FilePickerItem) => void;
} => {
    const [files, setFiles] = useState<FilePickerItem[]>(initials);

    const removeFile = useCallback((file: FilePickerItem) => {
        setFiles(files => files.filter(f => f.id !== file.id));
    }, []);

    const addFiles = useCallback((newFiles: FilePickerItem[]) => {
        setFiles(files => [...files, ...newFiles]);
    }, []);

    const updateFile = useCallback((file: FilePickerItem) => {
        setFiles(files => files.map(f => (f.id === file.id ? file : f)));
    }, []);

    return {
        files,
        setFiles,
        addFiles,
        removeFile,
        updateFile,
    };
};
