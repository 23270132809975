import { EmployeePayrollLocksPage } from '@/page/payroll/EmployeePayrollLocksPage';
import { EmployeesPayrollLocksPage } from '@/page/payroll/EmployeesPayrollLocksPage';
import { PayrollLeavesPage } from '@/page/payroll/PayrollLeavesPage';
import { PayrollProfileChangesPage } from '@/page/payroll/PayrollProfileChangesPage';
import { PayrollTimesheetPaymentsPage } from '@/page/payroll/PayrollTimesheetPaymentsPage';
import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RoutesConfig } from '@/routes/Routes.type';
import { Navigate, RouteObject } from 'react-router';

const defaultBreadcrumb = [{ nameKey: 'pages.payroll' }];

const config: RoutesConfig[] = [
    {
        path: 'leaves',
        breadCrumb: defaultBreadcrumb,
        component: <PayrollLeavesPage />,
    },
    {
        path: 'profile-changes',
        breadCrumb: defaultBreadcrumb,
        component: <PayrollProfileChangesPage />,
    },
    {
        path: 'timesheet-payments',
        breadCrumb: defaultBreadcrumb,
        component: <PayrollTimesheetPaymentsPage />,
    },
    {
        path: 'locks',
        breadCrumb: defaultBreadcrumb,
        component: <EmployeesPayrollLocksPage />,
    },
    {
        path: 'locks/:employeeId',
        breadCrumb: defaultBreadcrumb,
        component: <EmployeePayrollLocksPage />,
    },
];

export const payrollRoutes: RouteObject[] = [
    {
        path: 'payroll',
        children: [
            {
                path: '',
                element: <Navigate to='/payroll/leaves' replace />,
            },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
