import { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { useTheme } from '@mui/material';
import { RatingFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';

type EmployeeReviewFeedbackRatingPDFProps = {
    rating: RatingFormType;
};

export const EmployeeReviewFeedbackRatingPDF: FC<EmployeeReviewFeedbackRatingPDFProps> = ({ rating }) => {
    const theme = useTheme();
    const ratingItemsOrdered = (rating?.items ?? []).sort((a, b) => (a.score && b.score ? a.score - b.score : 0));

    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '100%',
                gap: theme.spacing(1),
            }}
        >
            {ratingItemsOrdered.map((item, index) => (
                <View
                    key={`${item.score}_${index}`}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: theme.spacing(0.5),
                        border: '1px black solid',
                        borderRadius: theme.shape.borderRadius,
                    }}
                    wrap={false}
                >
                    <Text>{item.label}</Text>
                </View>
            ))}
        </View>
    );
};
