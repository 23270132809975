import { Alert, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getNotificationFormSchema, NotificationFormSchema } from '@/page/setting/notification/notification.schema';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { EmployeeNotification } from '@/domain/employee-notification/EmployeeNotification.model';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { NotificationsBlock } from '@/page/setting/notification/DefaultRealmNotificationPage';
import { Stack } from '@mui/system';
import { updateEmployeesNotifications } from '@/domain/employee-notification/EmployeeNotification.service';

type EmployeesNotificationsDialogProps = {
    employeesNotifications: EmployeeNotification[];
    onSave: () => void;
} & DialogWrapperProps;

export const EmployeesNotificationsDialog: FC<EmployeesNotificationsDialogProps> = props => {
    const { t } = useTranslation();

    const { employeesNotifications, onSave, onClose, ...otherProps } = props;

    const formMethods = useForm<NotificationFormSchema>({
        resolver: yupResolver(getNotificationFormSchema()),
        defaultValues: getDefaultValues(employeesNotifications),
    });

    const { handleSubmit } = formMethods;

    const employees = employeesNotifications.map(employeeNotification => employeeNotification.employee);

    const handleSave = async (data: NotificationFormSchema) => {
        const requests = employeesNotifications.map(employeeNotification => ({
            employeeId: employeeNotification.employee.id,
            leaveNotificationsEnabled: data.leaveNotificationsEnabled ?? employeeNotification.leaveNotificationsEnabled,
            leaveAttachmentNotificationsEnabled: data.leaveAttachmentNotificationsEnabled ?? employeeNotification.leaveAttachmentNotificationsEnabled,
            planningUpdatesNotificationsEnabled: data.planningUpdatesNotificationsEnabled ?? employeeNotification.planningUpdatesNotificationsEnabled,
            documentNotificationsEnabled: data.documentNotificationsEnabled ?? employeeNotification.documentNotificationsEnabled,
            profileChangeNotificationsEnabled: data.profileChangeNotificationsEnabled ?? employeeNotification.profileChangeNotificationsEnabled,
            timesheetUpdatesNotificationsEnabled: data.timesheetUpdatesNotificationsEnabled ?? employeeNotification.timesheetUpdatesNotificationsEnabled,
            announcementNotificationsEnabled: data.announcementNotificationsEnabled ?? employeeNotification.announcementNotificationsEnabled,
        }));

        try {
            await updateEmployeesNotifications(requests);
            showSnackbar(t('default_realm_notification_page.on_save'), 'success');
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    const differenceOnNotTouched = employeesNotifications.length > 1;

    return (
        <DialogWrapper onClose={handleClose} header={t('employees_notifications_dialog.title')} {...otherProps}>
            <DialogContent>
                <Stack gap={2}>
                    <Alert severity='warning' sx={{ pt: 0 }}>
                        <Typography variant='h2'>{t('employees_notifications_dialog.warning_title')}</Typography>
                        <Typography variant='body2'>{t('employees_notifications_dialog.warning_description')}</Typography>
                    </Alert>
                    <FormProvider {...formMethods}>
                        <NotificationsBlock differenceOnNotTouched={differenceOnNotTouched} />
                    </FormProvider>
                </Stack>
            </DialogContent>
            <DialogActions>
                <StackedAvatars employeeAvatars={employees} />
                <Button onClick={handleSubmit(handleSave)}>{t('general.save')}</Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getDefaultValues = (employeesNotifications: EmployeeNotification[]): NotificationFormSchema => {
    if (employeesNotifications.length === 1) {
        return {
            ...employeesNotifications[0],
        };
    } else {
        /*
         * If there are multiple employees, we want to have the default values as undefined so that we can use isDirty to see each fields we want to actually update
         * at the moment we isDirty relays with defaultValues and uses deepEqual to check if it is dirty or not, so by having undefined as default values we can see which fields are actually dirty
         */
        return {
            leaveNotificationsEnabled: undefined,
            leaveAttachmentNotificationsEnabled: undefined,
            planningUpdatesNotificationsEnabled: undefined,
            documentNotificationsEnabled: undefined,
            profileChangeNotificationsEnabled: undefined,
            timesheetUpdatesNotificationsEnabled: undefined,
            announcementNotificationsEnabled: undefined,
        };
    }
};
