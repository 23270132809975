import { Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Props = {
    description: string;
    title: string;
};

export const OptionsBlock: FC<PropsWithChildren<Props>> = ({ title, description, children }) => {
    return (
        <Stack direction='row' spacing={2} alignItems='center'>
            <Stack flex={{ xs: 12, sm: 3, lg: 3 }} gap={1}>
                <Typography variant='body2'>{title}</Typography>
                {description && <Typography variant='body2'>{description}</Typography>}
            </Stack>
            <Stack flex={{ xs: 12, sm: 9, lg: 9 }}>{children}</Stack>
        </Stack>
    );
};
