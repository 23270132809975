import { EmployeeAvatar } from '@/domain/employee/Employee.model';

export type TimesheetRecurringAdjustment = {
    id: number;
    amountInMinutes: number;
    comment?: string;
    employee: EmployeeAvatar;
    startDate: LocalDate;
    endDate: LocalDate;
    contractProrataPartialDays: boolean;
    recurringRuleType: RecurringRuleType;
    locked: boolean;
};

export type TimesheetRecurringAdjustmentUpdateMutation = Omit<TimesheetRecurringAdjustmentCreateMutation, 'employeeIds'>;

export type TimesheetRecurringAdjustmentCreateMutation = {
    employeeIds: number[];
    startDate: LocalDate;
    endDate: LocalDate;
    amountInMinutes: number;
    comment: string;
    recurringRuleType: RecurringRuleType;
    contractProrataPartialDays: boolean;
};

export type TimesheetRecurringAdjustmentSearchRequest = {
    employeeIds?: number[];
};

export enum RecurringRuleType {
    ALL_WORKING_DAYS = 'ALL_WORKING_DAYS',
    NO_LEAVES = 'NO_LEAVES',
    NO_NOT_WORKING_PAID_LEAVES = 'NO_NOT_WORKING_PAID_LEAVES',
    NO_NOT_WORKING_UNPAID_LEAVES = 'NO_NOT_WORKING_UNPAID_LEAVES',
    NO_MEDICAL_LEAVES = 'NO_MEDICAL_LEAVES',
    NO_WORKING_LEAVES = 'NO_WORKING_LEAVES',
    NO_TIMESHEET_COMPENSATION_LEAVES = 'NO_TIMESHEET_COMPENSATION_LEAVES',
}
