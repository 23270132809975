import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Department, DepartmentNode } from '@/domain/department/Department.model';
import { FC } from 'react';
import { Alert, AlertTitle, Button, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { useForm } from 'react-hook-form';
import { ArrowRight02Icon } from 'hugeicons-react';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { formatCostCentersAssignment } from '@/domain/cost-center/CostCenter.service';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmploymentBulkMutation, EmploymentBulkCreateMutationItem, EmploymentCreateReason } from '@/domain/employment/Employment.model';
import { getLocalDateTestConfig } from '@/utils/datetime.util';
import { bulkCreateEmployment } from '@/domain/employment/Employment.service';
import { handleError } from '@/utils/api.util';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { Employee } from '@/domain/employee/Employee.model';

type DepartmentUpdateEmploymentDialogProps = {
    prevDepartment: DepartmentNode;
    newDepartment: Department;
    employeesInDepartment: Employee[];
    sameManagers: boolean;
    sameCostCenters: boolean;
} & DialogWrapperProps;
export const DepartmentUpdateEmploymentDialog: FC<DepartmentUpdateEmploymentDialogProps> = ({
    prevDepartment,
    newDepartment,
    employeesInDepartment,
    sameManagers,
    sameCostCenters,
    open,
    onClose,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, watch } = useForm({
        resolver: yupResolver(getSchema()),
        defaultValues: {
            updateEmployees: true,
        },
    });

    const updateEmployees = watch('updateEmployees');

    const onSubmit = async (values: DepartmentUpdateEmploymentsFormValues) => {
        if (!values.updateEmployees) {
            return;
        }
        const mutations = employeesInDepartment.reduce<EmploymentBulkMutation[]>((acc, employee) => {
            const mutationsByEmployee: EmploymentBulkMutation = {
                action: 'CREATE',
                employeeId: employee.id,
                creationRequests: createBulkEmploymentMutationsByEmployee({
                    employee,
                    newDepartment,
                    effectiveDate: values.effectiveDate,
                    updateManagers: !sameManagers,
                    updateCostCenters: !sameCostCenters,
                }),
            };
            return [...acc, mutationsByEmployee];
        }, [] as EmploymentBulkMutation[]);

        try {
            await bulkCreateEmployment(mutations);
            onClose();
        } catch (error) {
            handleError(error);
        }
    };

    const prevCostCentersFormatted = formatCostCentersAssignment(prevDepartment.departmentCostCenters);
    const newCostCentersFormatted = formatCostCentersAssignment(newDepartment.departmentCostCenters);

    return (
        <DialogWrapper onClose={onClose} open={open} header={t('settings_organization.departments.update_employments_dialog.title')} maxWidth={'md'}>
            <DialogContent>
                <Stack gap={3}>
                    <Alert severity={'warning'}>
                        <AlertTitle>{t('settings_organization.departments.update_employments_dialog.warning_title')}</AlertTitle>
                        {t('settings_organization.departments.update_employments_dialog.warning_sub_title')}
                    </Alert>

                    <Stack direction={'row'} alignItems={'center'}>
                        <FieldSwitch
                            name={'updateEmployees'}
                            control={control}
                            label={t('settings_organization.departments.update_employments_dialog.propagate_changes_field')}
                            labelPlacement={'end'}
                        />
                        {updateEmployees && <StackedAvatars employeeAvatars={employeesInDepartment} />}
                    </Stack>

                    {updateEmployees && (
                        <>
                            <FormControlLabel
                                control={<FieldLocalDate control={control} name={'effectiveDate'} />}
                                label={t('settings_organization.departments.update_employments_dialog.effective_date')}
                            />

                            {!sameCostCenters && (
                                <Stack>
                                    <Typography variant={'body1bold'} sx={{ mb: 1 }}>
                                        {t('settings_organization.departments.update_employments_dialog.cost_centers')}
                                    </Typography>

                                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                                        <Typography
                                            variant={'body1bold'}
                                            sx={{
                                                flex: 1,
                                                textDecoration: prevCostCentersFormatted.length ? 'line-through' : 'none',
                                                color: 'red',
                                            }}
                                        >
                                            {prevCostCentersFormatted || '-'}
                                        </Typography>
                                        <Stack direction={'row'} alignItems={'center'} gap={2} flex={1}>
                                            <ArrowRight02Icon />
                                            <Typography>{newCostCentersFormatted || '-'}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )}

                            {!sameManagers && (
                                <Stack>
                                    <Typography variant={'body1bold'} sx={{ mb: 1 }}>
                                        {t('settings_organization.departments.update_employments_dialog.managers')}
                                    </Typography>

                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
                                        {prevDepartment.managers.length ? <StackedAvatars employeeAvatars={prevDepartment.managers} sx={{ flex: 1 }} /> : '-'}
                                        <Stack direction={'row'} alignItems={'center'} gap={2} flex={1}>
                                            <ArrowRight02Icon />
                                            {newDepartment.managers.length ? <StackedAvatars employeeAvatars={newDepartment.managers} /> : '-'}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )}
                        </>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                {updateEmployees ? (
                    <Button fullWidth onClick={handleSubmit(onSubmit, console.error)}>
                        {t('settings_organization.departments.update_employments_dialog.confirm_with_changes')}
                    </Button>
                ) : (
                    <Button variant={'outlined'} fullWidth onClick={onClose}>
                        {t('settings_organization.departments.update_employments_dialog.confirm_without_changes')}
                    </Button>
                )}
            </DialogActions>
        </DialogWrapper>
    );
};

const getSchema = () => {
    return yup.object().shape({
        updateEmployees: yup.boolean().required().default(true),
        effectiveDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
    });
};
type DepartmentUpdateEmploymentsFormValues = yup.InferType<ReturnType<typeof getSchema>>;

type CreateBulkParams = {
    employee: Employee;
    newDepartment: Department;
    effectiveDate: LocalDate;
    updateManagers: boolean;
    updateCostCenters: boolean;
};
const createBulkEmploymentMutationsByEmployee = ({ employee, newDepartment, effectiveDate, updateManagers, updateCostCenters }: CreateBulkParams) => {
    return employee.currentEmployments.reduce<EmploymentBulkCreateMutationItem[]>((accByEmployee, employment) => {
        const isSameDepartment = employment.department.id === newDepartment.id;

        // get managers and the cost centers from the new department if the employment is in the updated department and the managers or cost centers are updated
        const managerIds = (isSameDepartment && updateManagers ? newDepartment.managers : employment.managers).map(m => m.id);
        const employmentCostCentersAssignment = (
            isSameDepartment && updateCostCenters ? newDepartment.departmentCostCenters : employment.employmentCostCenters
        ).map(dcc => ({
            costCenterId: dcc.costCenter.id,
            percentage: dcc.percentage,
        }));

        const request: EmploymentBulkCreateMutationItem = {
            // updated attributes
            startDate: effectiveDate,
            employmentCostCenters: employmentCostCentersAssignment,
            managerIds,
            employmentCreateReason: EmploymentCreateReason.NEW_ENTITY,
            // fill values from the employment
            principal: employment.principal,
            contractType: employment.contractType,
            locationId: employment.location.id,
            jobId: employment.job.id,
            jobFamilyId: employment.jobFamily?.id,
            departmentId: employment.department.id,
            percentage: employment.percentage,
        };

        return [...accByEmployee, request];
    }, [] as EmploymentBulkCreateMutationItem[]);
};
