import { getEmployeePersonalInfo } from '@/domain/employee/Employee.service';
import { EmployeePersonalInfo } from '@/domain/employee/EmployeePersonalInfo.model';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetEmployeePersonalInfo = (employeeId: number, enabled = true): UseQueryResult<EmployeePersonalInfo> => {
    const [employeePersonalInfo, setEmployeePersonalInfo] = useState<EmployeePersonalInfo>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeePersonalInfo = useCallback(async () => {
        if (!employeeId || !enabled) {
            setIsLoading(false);
            return;
        }
        try {
            const employeeData = await getEmployeePersonalInfo(employeeId);
            setEmployeePersonalInfo(employeeData);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, [employeeId, enabled]);

    useEffect(() => {
        fetchEmployeePersonalInfo().catch(handleError);
    }, [fetchEmployeePersonalInfo]);

    return {
        data: employeePersonalInfo,
        refetch: fetchEmployeePersonalInfo,
        setData: setEmployeePersonalInfo,
        isLoading,
        isError: !!error,
        error,
    };
};
