import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { handleError } from '@/utils/api.util';
import { getSurveyTemplates } from '@/api/survey-template/SurveyTemplate.api';
import { SurveyTemplate } from '@/domain/survey-template/Survey-template.model';

export const useGetSurveyTemplates = (): UseQueryResult<SurveyTemplate[]> => {
    const [surveyTemplates, setSurveyTemplates] = useState<SurveyTemplate[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const getSurveyTemplate = useCallback(async () => {
        try {
            const surveyTemplates = await getSurveyTemplates();
            setSurveyTemplates(surveyTemplates);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getSurveyTemplate().catch(handleError);
    }, [getSurveyTemplate]);

    return {
        data: surveyTemplates,
        setData: setSurveyTemplates,
        isLoading,
        isError: !!error,
        error,
        refetch: () => getSurveyTemplate(),
    };
};
