import { API_BASE_URL, client } from '@/api/common';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import {
    AreaShift,
    DailyShiftCoverage,
    EmployeeShift,
    LeaveShiftSearchRequest,
    NonWorkingShift,
    Shift,
    ShiftCoverage,
    ShiftCoverageCreationRequest,
    ShiftCoverageSearchRequest,
    ShiftCoverageUpdateRequest,
    ShiftCreationRequest,
    ShiftPublishRequest,
    ShiftReleaseRequest,
    ShiftSearchRequest,
    ShiftUpdateRequest,
    TagShiftCoverage,
} from '@/domain/shift/Shift.model';
import { convertDateToUTCIsoString, convertUTCIsoStringToDate } from '@/utils/datetime.util';
import { AxiosResponse, GenericAbortSignal } from 'axios';

const mapShiftDTO = (shiftRule: ShiftDTO): Shift => {
    return {
        ...shiftRule,
        createdAt: convertUTCIsoStringToDate(shiftRule.createdAt),
        updatedAt: convertUTCIsoStringToDate(shiftRule.updatedAt),
        startDate: convertUTCIsoStringToDate(shiftRule.startDate),
        endDate: convertUTCIsoStringToDate(shiftRule.endDate),
        publishedAt: convertUTCIsoStringToDate(shiftRule.publishedAt),
    };
};

const getShift = async (id: number): Promise<Shift> => {
    const url = API_BASE_URL + `/shifts/${id}`;
    const { data } = await client.get<ShiftDTO>(url);
    return mapShiftDTO(data);
};

const mapEmployeeShift = (employeeShift: EmployeeShiftDTO): EmployeeShift => {
    return {
        ...employeeShift,
        shifts: employeeShift.shifts.map(mapShiftDTO),
        assignee: mapEmployeeDTO(employeeShift.assignee),
        nonWorkingShifts: employeeShift.nonWorkingShifts.map(nonWorkingShift => ({
            ...nonWorkingShift,
            startDate: convertUTCIsoStringToDate(nonWorkingShift.startDate),
            endDate: convertUTCIsoStringToDate(nonWorkingShift.endDate),
        })),
    };
};

const getLeaveShifts = async (request: LeaveShiftSearchRequest): Promise<EmployeeShift[]> => {
    const url = API_BASE_URL + '/shifts/leaves/search';
    const { data } = await client.post<EmployeeShiftDTO[], AxiosResponse<EmployeeShiftDTO[]>, LeaveShiftSearchRequestDTO>(url, {
        ...request,
    });
    return data.map(mapEmployeeShift);
};

const getPendingLeaveShifts = async (request: LeaveShiftSearchRequest): Promise<EmployeeShift[]> => {
    const url = API_BASE_URL + '/shifts/leaves/pending/search';
    const { data } = await client.post<EmployeeShiftDTO[], AxiosResponse<EmployeeShiftDTO[]>, LeaveShiftSearchRequestDTO>(url, {
        ...request,
    });
    return data.map(mapEmployeeShift);
};

const getEmployeeShifts = async (request: ShiftSearchRequest, signal?: GenericAbortSignal): Promise<EmployeeShift[]> => {
    const url = API_BASE_URL + '/shifts/employees/search';
    const { data } = await client.post<EmployeeShiftDTO[], AxiosResponse<EmployeeShiftDTO[]>, ShiftSearchRequestDTO>(
        url,
        {
            ...request,
            rangeDates: mapRangeDates(request.rangeDates),
        },
        { signal },
    );
    return data.map(mapEmployeeShift);
};

const mapRangeDates = (rangeDates: { start: Date; end: Date }[]): { start: string; end: string }[] => {
    return rangeDates.map(({ start, end }) => ({
        start: convertDateToUTCIsoString(start),
        end: convertDateToUTCIsoString(end),
    }));
};

const getAreasShifts = async (request: ShiftSearchRequest, signal?: GenericAbortSignal): Promise<AreaShift[]> => {
    const url = API_BASE_URL + '/shifts/areas/search';
    const { data } = await client.post<AreaShiftDTO[], AxiosResponse<AreaShiftDTO[]>, ShiftSearchRequestDTO>(
        url,
        {
            ...request,
            rangeDates: mapRangeDates(request.rangeDates),
        },
        { signal },
    );
    return data.map(areaShift => ({
        ...areaShift,
        shifts: areaShift.shifts.map(shift => ({
            ...shift,
            startDate: convertUTCIsoStringToDate(shift.startDate),
            endDate: convertUTCIsoStringToDate(shift.endDate),
            createdAt: convertUTCIsoStringToDate(shift.createdAt),
            updatedAt: convertUTCIsoStringToDate(shift.updatedAt),
            publishedAt: convertUTCIsoStringToDate(shift.publishedAt),
        })),
        nonWorkingShifts: areaShift.nonWorkingShifts.map(nonWorkingShift => ({
            ...nonWorkingShift,
            startDate: convertUTCIsoStringToDate(nonWorkingShift.startDate),
            endDate: convertUTCIsoStringToDate(nonWorkingShift.endDate),
        })),
    }));
};

const createShift = async (newShift: ShiftCreationRequest): Promise<Shift> => {
    const url = API_BASE_URL + '/shifts';
    const { data } = await client.post<ShiftDTO, AxiosResponse<ShiftDTO>, ShiftCreationRequestDTO>(url, {
        ...newShift,
        startDate: convertDateToUTCIsoString(newShift.startDate),
        endDate: convertDateToUTCIsoString(newShift.endDate),
    });
    return mapShiftDTO(data);
};

const shiftRelease = async (request: ShiftReleaseRequest): Promise<void> => {
    const url = API_BASE_URL + '/shifts/release';
    const { data } = await client.post(url, {
        ...request,
        rangeDates: mapRangeDates(request.rangeDates),
    });
    return data;
};

const updateShift = async (updatedShift: ShiftUpdateRequest, id: number): Promise<Shift> => {
    const url = API_BASE_URL + `/shifts/${id}`;
    const { data } = await client.put<ShiftDTO, AxiosResponse<ShiftDTO>, ShiftUpdateRequestDTO>(url, {
        ...updatedShift,
        startDate: convertDateToUTCIsoString(updatedShift.startDate),
        endDate: convertDateToUTCIsoString(updatedShift.endDate),
    });
    return mapShiftDTO(data);
};

const deleteShift = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/shifts/${id}`;
    await client.delete(url);
};

const publishShifts = async (request: ShiftPublishRequest): Promise<void> => {
    const url = API_BASE_URL + '/shifts/publish';
    const { data } = await client.post(url, request);
    return data;
};

const createShiftCoverage = async (newShift: ShiftCoverageCreationRequest): Promise<ShiftCoverage> => {
    const url = API_BASE_URL + '/shift-coverages';
    const { data } = await client.post<ShiftCoverageDTO, AxiosResponse<ShiftCoverageDTO>, ShiftCoverageCreationRequestDTO>(url, newShift);
    return data;
};

const getTagShiftCoverages = async (request: ShiftCoverageSearchRequest, signal?: GenericAbortSignal): Promise<TagShiftCoverage[]> => {
    const url = API_BASE_URL + '/shift-coverages/search';
    const { data } = await client.post<TagShiftCoverageDTO[], AxiosResponse<TagShiftCoverageDTO[]>, ShiftCoverageSearchRequestDTO>(url, request, { signal });
    return data.map(tagShiftCoverage => ({
        ...tagShiftCoverage,
        shiftCoverages: tagShiftCoverage.shiftCoverages.map(dailyShiftCoverage => ({
            ...dailyShiftCoverage,
            date: convertUTCIsoStringToDate(dailyShiftCoverage.date),
            shifts: dailyShiftCoverage.shifts.map(mapShiftDTO),
        })),
    }));
};

const updateShiftCoverage = async (id: number, updatedShift: ShiftCoverageUpdateRequest): Promise<ShiftCoverage> => {
    const url = API_BASE_URL + `/shift-coverages/${id}`;
    const { data } = await client.put<ShiftCoverageDTO, AxiosResponse<ShiftCoverageDTO>, ShiftCoverageUpdateRequestDTO>(url, updatedShift);
    return data;
};

const deleteShiftCoverage = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/shift-coverages/${id}`;
    await client.delete(url);
};

export type ShiftDTO = DateToString<Shift>;
export type NonWorkingShiftDTO = DateToString<NonWorkingShift>;
export type EmployeeShiftDTO = Overwrite<
    EmployeeShift,
    {
        assignee: EmployeeDTO;
        shifts: ShiftDTO[];
        nonWorkingShifts: NonWorkingShiftDTO[];
    }
>;
export type LeaveShiftSearchRequestDTO = Overwrite<LeaveShiftSearchRequest, { startDate: string; endDate: string }>;
export type ShiftSearchRequestDTO = Overwrite<ShiftSearchRequest, { rangeDates: { start: string; end: string }[] }>;
export type ShiftCreationRequestDTO = DateToString<
    Omit<ShiftCreationRequest, 'employeeId'> & {
        employeeIds: number[];
    }
>;
export type ShiftUpdateRequestDTO = DateToString<ShiftUpdateRequest>;
export type AreaShiftDTO = Overwrite<
    AreaShift,
    {
        shifts: DateToString<ShiftDTO>[];
        nonWorkingShifts: NonWorkingShiftDTO[];
    }
>;
export type ShiftCoverageDTO = ShiftCoverage;
export type ShiftCoverageCreationRequestDTO = ShiftCoverageCreationRequest;
export type ShiftCoverageSearchRequestDTO = ShiftCoverageSearchRequest;
type DailyShiftCoverageDTO = DateToString<Overwrite<DailyShiftCoverage, { shifts: ShiftDTO[] }>>;
export type TagShiftCoverageDTO = Overwrite<TagShiftCoverage, { shiftCoverages: DailyShiftCoverageDTO[] }>;
export type ShiftCoverageUpdateRequestDTO = ShiftCoverageUpdateRequest;

export const shiftApi = {
    getShift,
    getLeaveShifts,
    getPendingLeaveShifts,
    getEmployeeShifts,
    getAreasShifts,
    createShift,
    shiftRelease,
    updateShift,
    deleteShift,
    publishShifts,
    createShiftCoverage,
    getTagShiftCoverages,
    updateShiftCoverage,
    deleteShiftCoverage,
};
