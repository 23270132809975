import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import {
    ThirdPartyMapping,
    ThirdPartyMappingMutation,
    ThirdPartyMappingValue,
    ThirdPartyMappingValueRequest,
} from '@/domain/third-party-mapping/ThirdPartyMapping.model';

type ThirdPartyMappingDTO = ThirdPartyMapping;
type ThirdPartyMappingValueDTO = ThirdPartyMappingValue;
type ThirdPartyMappingMutationDTO = ThirdPartyMappingMutation;

const THIRD_PARTY_MAPPING_API_BASE_PATH = API_BASE_URL + `/third-party-mappings`;

const searchThirdPartyMappings = async (): Promise<ThirdPartyMapping[]> => {
    const url = THIRD_PARTY_MAPPING_API_BASE_PATH + `/search`;
    return (await client.post<ThirdPartyMappingDTO[], AxiosResponse<ThirdPartyMappingDTO[]>>(url, {})).data;
};

const getThirdPartyMappingById = async (thirdPartyMappingId: number): Promise<ThirdPartyMapping> => {
    const url = THIRD_PARTY_MAPPING_API_BASE_PATH + `/${thirdPartyMappingId}`;
    return (await client.get<ThirdPartyMappingDTO, AxiosResponse<ThirdPartyMappingDTO>>(url)).data;
};

const createThirdPartyMapping = async (request: ThirdPartyMappingMutation): Promise<ThirdPartyMapping> => {
    return (
        await client.post<ThirdPartyMappingDTO, AxiosResponse<ThirdPartyMappingDTO>, ThirdPartyMappingMutationDTO>(THIRD_PARTY_MAPPING_API_BASE_PATH, request)
    ).data;
};

const deleteThirdPartyMapping = async (thirdPartyMappingId: number): Promise<void> => {
    const url = THIRD_PARTY_MAPPING_API_BASE_PATH + `/${thirdPartyMappingId}`;
    await client.delete(url);
};

const getThirdPartyMappingValues = async (thirdPartyMappingId: number): Promise<ThirdPartyMappingValue[]> => {
    const url = THIRD_PARTY_MAPPING_API_BASE_PATH + `/${thirdPartyMappingId}/values`;
    return (await client.get<ThirdPartyMappingValueDTO[], AxiosResponse<ThirdPartyMappingValueDTO[]>>(url)).data;
};

const saveThirdPartyMappingValues = async (thirdPartyMappingId: number, values: ThirdPartyMappingValueRequest[]): Promise<void> => {
    const url = THIRD_PARTY_MAPPING_API_BASE_PATH + `/${thirdPartyMappingId}/values`;
    await client.post(url, values);
};

export const thirdPartyMappingAPI = {
    searchThirdPartyMappings,
    getThirdPartyMappingById,
    createThirdPartyMapping,
    deleteThirdPartyMapping,
    getThirdPartyMappingValues,
    saveThirdPartyMappingValues,
};
