import {
    EmployeePayrollLock,
    EmployeePayrollLockCreationMutation,
    EmployeePayrollLockCreationValidation,
    EmployeePayrollLockLatestSearchRequest,
    EmployeePayrollLockPreview,
    EmployeePayrollLockSearchRequest,
    EmployeePayrollLockUnlockMutation,
    LatestPayrollLocks,
} from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { convertDateToUTCIsoString, convertUTCIsoStringToDate } from '@/utils/datetime.util';
import { EmployeeAvatarDTO, EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';

export type EmployeePayrollLockDTO = Overwrite<
    DateToString<EmployeePayrollLock>,
    {
        employee: EmployeeDTO;
        createdBy: EmployeeAvatarDTO;
    }
>;

type LatestPayrollLocksDTO = Overwrite<
    LatestPayrollLocks,
    {
        employee: EmployeeDTO;
        employeePayrollLock: EmployeePayrollLockDTO | undefined;
    }
>;
type EmployeePayrollLockSearchRequestDTO = EmployeePayrollLockSearchRequest;
type EmployeePayrollLockLatestSearchRequestDTO = EmployeePayrollLockLatestSearchRequest;
type EmployeePayrollLockPreviewDTO = Overwrite<EmployeePayrollLockPreview, { previousLock?: EmployeePayrollLockDTO }>;

// BE needs an Instant, not a LocalDate
type EmployeePayrollLockCreationDTO = Overwrite<EmployeePayrollLockCreationMutation, { lockedAt: string }>;
// BE needs an Instant, not a LocalDate
type EmployeePayrollLockCreationValidationDTO = Overwrite<EmployeePayrollLockCreationValidation, { lockedAt: string }>;

export const mapEmployeePayrollLockDTO = (dto: EmployeePayrollLockDTO): EmployeePayrollLock => ({
    ...dto,
    employee: mapEmployeeDTO(dto.employee),
    lockedAt: convertUTCIsoStringToDate(dto.lockedAt),
    updatedAt: convertUTCIsoStringToDate(dto.updatedAt),
});

const createEmployeePayrollLock = async (mutation: EmployeePayrollLockCreationMutation): Promise<EmployeePayrollLock[]> => {
    const url = API_BASE_URL + `/employee/payroll-locks`;
    const request: EmployeePayrollLockCreationDTO = { ...mutation, lockedAt: convertDateToUTCIsoString(mutation.lockedAt) };
    const { data } = await client.post<EmployeePayrollLockDTO, AxiosResponse<EmployeePayrollLockDTO[]>, EmployeePayrollLockCreationDTO>(url, request);
    return data.map(mapEmployeePayrollLockDTO);
};

const searchEmployeePayrollLock = async (search: EmployeePayrollLockSearchRequest): Promise<EmployeePayrollLock[]> => {
    const url = API_BASE_URL + `/employee/payroll-locks/search`;
    return (await client.post<EmployeePayrollLockDTO[], AxiosResponse<EmployeePayrollLockDTO[]>, EmployeePayrollLockSearchRequestDTO>(url, search)).data.map(
        mapEmployeePayrollLockDTO,
    );
};

const mapLatestPayrollLocksDTO = (dto: LatestPayrollLocksDTO): LatestPayrollLocks => ({
    employee: mapEmployeeDTO(dto.employee),
    employeePayrollLock: dto.employeePayrollLock ? mapEmployeePayrollLockDTO(dto.employeePayrollLock) : undefined,
});

const searchLatestEmployeePayrollLock = async (search: EmployeePayrollLockLatestSearchRequest): Promise<LatestPayrollLocks[]> => {
    const url = API_BASE_URL + `/employee/payroll-locks/search/latest`;
    return (
        await client.post<LatestPayrollLocksDTO[], AxiosResponse<LatestPayrollLocksDTO[]>, EmployeePayrollLockLatestSearchRequestDTO>(url, search)
    ).data.map(mapLatestPayrollLocksDTO);
};

const unlockEmployeePayrollLock = (mutation: EmployeePayrollLockUnlockMutation): Promise<void> => {
    const url = API_BASE_URL + `/employee/payroll-locks/unlock`;
    return client.post(url, mutation);
};

const checkEmployeePayrollLockCreationFeasibility = async (request: EmployeePayrollLockCreationValidation): Promise<EmployeePayrollLockPreview[]> => {
    const url = API_BASE_URL + `/employee/payroll-locks/check`;
    const { data } = await client.post<
        EmployeePayrollLockPreviewDTO[],
        AxiosResponse<EmployeePayrollLockPreviewDTO[]>,
        EmployeePayrollLockCreationValidationDTO
    >(url, {
        ...request,
        lockedAt: convertDateToUTCIsoString(request.lockedAt),
    });

    return data.map(dto => ({
        ...dto,
        previousLock: dto.previousLock ? mapEmployeePayrollLockDTO(dto.previousLock) : undefined,
    }));
};

export const employeePayrollLockAPI = {
    createEmployeePayrollLock,
    searchEmployeePayrollLock,
    searchLatestEmployeePayrollLock,
    unlockEmployeePayrollLock,
    checkEmployeePayrollLockCreationFeasibility,
};
