import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { JobLevel } from '@/domain/job-level/JobLevel.model';
import { getJobLevels } from '@/domain/job-level/JobLevel.service';

export const useGetJobLevels = (): UseQueryResult<JobLevel[]> => {
    const [jobLevels, setJobLevels] = useState<JobLevel[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchJobLevels = useCallback(async () => {
        try {
            const jobLevels = await getJobLevels();
            setJobLevels(jobLevels);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchJobLevels().catch(handleError);
    }, [fetchJobLevels]);

    return {
        data: jobLevels,
        setData: setJobLevels,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchJobLevels(),
    };
};
