import { useAppSelector } from '@/stores/store';
import { SIDEBAR_DEFAULT_WIDTH, SIDEBAR_WIDTH_EXPANDED } from '@/config/ui.constants';

/**
 * The Drawer hook provides the drawer width based on the state of the expandMenu.
 * @returns drawerWidth
 */
export const useSideBar = (): { drawerWidth: number } => {
    const expandMenu = useAppSelector(state => state.ui.expandMenu);

    const drawerWidth = expandMenu ? SIDEBAR_WIDTH_EXPANDED : SIDEBAR_DEFAULT_WIDTH;

    return { drawerWidth };
};
