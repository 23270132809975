import { FC, useEffect } from 'react';
import { EmployeeProfileCard } from '@/page/employee-profile/employee-profile-card/EmployeeProfileCard';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';

export const EmployeeProfilePage: FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    useEffect(() => {
        // we don't want to display only the card in desktop
        if (!isMobile) {
            // relative path to handle the case when the user is on /profile/:employeeId or /profile
            navigate('personal-info', { replace: true });
        }
    }, [isMobile, navigate]);

    return <EmployeeProfileCard />;
};
