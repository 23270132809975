import { getEmployeeAddresses } from '@/domain/employee/Employee.service';
import { EmployeeAddress } from '@/domain/employee/EmployeeAddress.model';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetEmployeeAddresses = (employeeId: number): UseQueryResult<EmployeeAddress[]> => {
    const [employeeAddresses, setEmployeeAddresses] = useState<EmployeeAddress[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    // TODO replace by a hook
    const fetchEmployeeAddresses = useCallback(async (employeeId: number) => {
        try {
            setIsFetching(true);
            const data = await getEmployeeAddresses({ employeeId: employeeId });
            setEmployeeAddresses(data);
        } catch (error) {
            setError(error);
        } finally {
            setIsFetching(false);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!employeeId) {
            return;
        }
        fetchEmployeeAddresses(employeeId).catch(handleError);
    }, [employeeId, fetchEmployeeAddresses]);

    return {
        data: employeeAddresses,
        setData: setEmployeeAddresses,
        isLoading,
        isFetching,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeAddresses(employeeId),
    };
};
