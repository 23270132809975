import { AllowanceType, LeaveType, LeaveTypePolicyAssignRequest } from '@/domain/leave-type/LeaveType.model';
import { Checkbox, FormControlLabel, FormControlLabelProps, MenuItem, Select, Stack } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LeaveTypeFormValues } from '../LeavesForm';
import { getLabelTranslation } from '@/utils/language.util';

type LeavesTypeSectionProps = {
    leaveTypes: LeaveType[];
};

export const LeavesTypeSection: FC<LeavesTypeSectionProps> = ({ leaveTypes }) => {
    const { control } = useFormContext<LeaveTypeFormValues>();

    const filteredLeaveTypes = leaveTypes.filter(type => !!type.policies.length);

    return (
        <Controller
            name={'leaveTypeAssignments'}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
                <Stack direction={'column'} alignItems={'flex-start'} gap={1}>
                    {filteredLeaveTypes?.map(type => <LeaveTypeItem key={type.id} leaveType={type} value={value} onChange={onChange} {...rest} />)}
                </Stack>
            )}
        />
    );
};

const LeaveTypeItem: FC<
    {
        leaveType: LeaveType;
        onChange: (values: LeaveTypePolicyAssignRequest[]) => void;
        value: LeaveTypePolicyAssignRequest[];
    } & Omit<FormControlLabelProps, 'control' | 'label'>
> = ({ leaveType, value, onChange, ...rest }) => {
    return (
        <Stack key={leaveType.id} direction={'row'}>
            <FormControlLabel
                {...rest}
                control={
                    <Checkbox
                        checked={value.some(value => value.leaveTypeId === leaveType.id)}
                        onChange={() => {
                            if (!value.some(value => value.leaveTypeId === leaveType.id)) {
                                const leaveTypePolicyId =
                                    leaveType.allowanceType === AllowanceType.NOT_UNLIMITED && !!leaveType.policies?.length
                                        ? leaveType.policies[0].id
                                        : undefined;
                                const newValues = [
                                    ...value,
                                    {
                                        leaveTypeId: leaveType.id,
                                        leaveTypePolicyId: leaveTypePolicyId,
                                    },
                                ] satisfies LeaveTypePolicyAssignRequest[];
                                onChange(newValues);
                                return;
                            }
                            const newValues = value?.filter(value => value.leaveTypeId !== leaveType.id);
                            onChange(newValues);
                        }}
                    />
                }
                label={getLabelTranslation(leaveType?.name)}
                labelPlacement={'end'}
            />
            {/* if leave contains more than one policy, show select */}
            {leaveType.policies.length > 1 && value.some(value => value.leaveTypeId === leaveType.id && value.leaveTypePolicyId) && (
                <Select
                    value={value.find(value => value.leaveTypeId === leaveType.id)?.leaveTypePolicyId}
                    onChange={e => {
                        const newValue = value?.find(value => value.leaveTypeId === leaveType.id);
                        if (!newValue) {
                            return;
                        }
                        newValue.leaveTypePolicyId = Number(e.target.value);
                        const newValues = value?.map(value => {
                            if (value.leaveTypeId === leaveType.id) {
                                return newValue;
                            }
                            return value;
                        });
                        onChange(newValues);
                    }}
                >
                    {leaveType.policies.map(policy => {
                        return (
                            <MenuItem key={policy.id} value={policy.id}>
                                {policy.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            )}
        </Stack>
    );
};
