import { JobLevel, JobLevelCreationMutation, JobLevelUpdateMutation } from '@/domain/job-level/JobLevel.model';
import { jobLevelAPI } from '@/api/job-level/JobLevel.api';

export const getJobLevels = (): Promise<JobLevel[]> => {
    return jobLevelAPI.getJobLevels();
};

export const getJobLevelById = (jobLevelId: number): Promise<JobLevel> => {
    return jobLevelAPI.getJobLevelById(jobLevelId);
};

export const createJobLevel = (mutation: JobLevelCreationMutation): Promise<JobLevel> => {
    return jobLevelAPI.createJobLevel(mutation);
};

export const updateJobLevel = (jobLevelId: number, mutation: JobLevelUpdateMutation): Promise<JobLevel> => {
    return jobLevelAPI.updateJobLevel(jobLevelId, mutation);
};

export const deleteJobLevel = (jobLevelId: number): Promise<void> => {
    return jobLevelAPI.deleteJobLevel(jobLevelId);
};
