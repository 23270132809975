import { Document, EmployeeFolder, Folder, FolderType } from '@/domain/document/Document.model';
import {
    getCompanyDocuments,
    getCompanyFolders,
    getEmployeeDocuments,
    getEmployeeFolders,
    getFolderById,
    getFolders,
    getFoldersByType,
} from '@/domain/document/Document.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetFoldersByType = (folderType: FolderType): UseQueryResult<Folder[]> => {
    const [folders, setFolders] = useState<Folder[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchFolders = useCallback(async () => {
        try {
            const data = await getFoldersByType(folderType);
            setFolders(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [folderType]);

    useEffect(() => {
        fetchFolders().catch(handleError);
    }, [fetchFolders]);

    return {
        data: folders,
        setData: setFolders,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchFolders,
    };
};

export const useGetFolders = (): UseQueryResult<Folder[]> => {
    const [folders, setFolders] = useState<Folder[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchFolders = useCallback(async () => {
        try {
            const folders = await getFolders();
            setFolders(folders);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchFolders().catch(handleError);
    }, [fetchFolders]);

    return {
        data: folders,
        setData: setFolders,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchFolders,
    };
};

export const useGetFolderById = (folderId: number | undefined): UseQueryResult<Folder> => {
    const [folder, setFolder] = useState<Folder>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchFolders = useCallback(async () => {
        if (!folderId) {
            setIsLoading(false);
            return;
        }
        try {
            const folder = await getFolderById(folderId);
            setFolder(folder);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [folderId]);

    useEffect(() => {
        fetchFolders().catch(handleError);
    }, [fetchFolders]);

    return {
        data: folder,
        setData: setFolder,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchFolders,
    };
};

export const useGetEmployeeDocuments = (folderId: number, employeeId: number): UseQueryResult<Document[]> => {
    const [documents, setDocuments] = useState<Document[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchDocuments = useCallback(async () => {
        try {
            const documents = await getEmployeeDocuments(folderId, employeeId);
            setDocuments(documents);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [folderId, employeeId]);

    useEffect(() => {
        fetchDocuments().catch(handleError);
    }, [fetchDocuments]);

    return {
        data: documents,
        setData: setDocuments,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchDocuments,
    };
};

export const useGetEmployeeFolders = (employeeId?: number): UseQueryResult<EmployeeFolder[]> => {
    const [employeeFolders, setEmployeeFolders] = useState<EmployeeFolder[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeFolders = useCallback(async () => {
        if (!employeeId) {
            return;
        }
        try {
            const employeeFolders = await getEmployeeFolders(employeeId);
            setEmployeeFolders(employeeFolders);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [employeeId]);

    useEffect(() => {
        fetchEmployeeFolders().catch(handleError);
    }, [fetchEmployeeFolders]);

    return {
        data: employeeFolders,
        setData: setEmployeeFolders,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchEmployeeFolders,
    };
};

export const useGetCompanyFolders = (): UseQueryResult<Folder[]> => {
    const [companyFolders, setCompanyFolders] = useState<Folder[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCompanyFolders = useCallback(async () => {
        try {
            const companyFolders = await getCompanyFolders();
            setCompanyFolders(companyFolders);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchCompanyFolders().catch(handleError);
    }, [fetchCompanyFolders]);

    return {
        data: companyFolders,
        setData: setCompanyFolders,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchCompanyFolders,
    };
};

export const useGetCompanyDocuments = (folderId: number): UseQueryResult<Document[]> => {
    const [documents, setDocuments] = useState<Document[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchDocuments = useCallback(async () => {
        try {
            const documents = await getCompanyDocuments(folderId);
            setDocuments(documents);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [folderId]);

    useEffect(() => {
        fetchDocuments().catch(handleError);
    }, [fetchDocuments]);

    return {
        data: documents,
        setData: setDocuments,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchDocuments,
    };
};
