import { Stack, SvgIconProps } from '@mui/material';
import { StarIcon } from 'hugeicons-react';
import { FC } from 'react';

type StarsIconsProps = {
    starsNumber: number | undefined;
} & SvgIconProps;

export const StarsIcons: FC<StarsIconsProps> = ({ starsNumber, htmlColor, ...rest }) => {
    if (!starsNumber) {
        return <></>;
    }

    return (
        <Stack direction={'row'}>
            {[...Array(starsNumber)].map((_, index) => (
                <StarIcon key={index} size={12} {...rest} fill={htmlColor} />
            ))}
        </Stack>
    );
};
