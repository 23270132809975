import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { TimesheetAdjustment } from '@/domain/timesheet-adjustment/TimesheetAdjustment.model';
import { getTimesheetAdjustment } from '@/domain/timesheet-adjustment/TimesheetAdjustment.service';

export const useGetTimesheetAdjustment = (id: number | undefined): UseQueryResult<TimesheetAdjustment> => {
    const [adjustment, setAdjustment] = useState<TimesheetAdjustment>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchTimesheetAdjustment = useCallback(async (id: number | undefined) => {
        if (!id) {
            setIsLoading(false);
            return;
        }
        try {
            const timesheetAdjustment = await getTimesheetAdjustment(id);
            setAdjustment(timesheetAdjustment);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchTimesheetAdjustment(id).catch(handleError);
    }, [fetchTimesheetAdjustment, id]);

    return {
        data: adjustment,
        setData: setAdjustment,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchTimesheetAdjustment(id),
    };
};
