import { DocumentContentDisposition } from '@/domain/document/Document.model';
import { leaveRequestAttachmentApi } from '@/api/leave-request-attachments/LeaveRequestAttachment.api';
import { LeaveRequestAttachment, LeaveRequestAttachmentCreationRequest } from '@/domain/leave-request-attachment/LeaveRequestAttachment.model';

export const createLeaveRequestAttachment = (request: LeaveRequestAttachmentCreationRequest): Promise<LeaveRequestAttachment> => {
    return leaveRequestAttachmentApi.createLeaveRequestAttachment(request);
};

export const getLeaveRequestAttachments = (leaveRequestId: number): Promise<LeaveRequestAttachment[]> => {
    return leaveRequestAttachmentApi.getLeaveRequestAttachments(leaveRequestId);
};

export const deleteLeaveRequestAttachment = (leaveRequestAttachmentId: number): Promise<void> => {
    return leaveRequestAttachmentApi.deleteLeaveRequestAttachment(leaveRequestAttachmentId);
};

export const getLeaveRequestAttachmentUrl = (leaveRequestAttachmentId: number, documentContentDisposition: DocumentContentDisposition): Promise<string> => {
    return leaveRequestAttachmentApi.getLeaveRequestAttachmentUrl(leaveRequestAttachmentId, documentContentDisposition);
};
