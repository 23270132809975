import { Employee, EmployeeAvatar } from '@/domain/employee/Employee.model';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import * as yup from 'yup';

export type Assignment = {
    employee: Employee;
    manager: Employee | null;
};

export const cleanupStepFormSchema = yup.object().shape({
    ongoingReviewIds: yup.array().of(yup.number().required()).required(),
    assignments: yup
        .array()
        .of(
            yup.object().shape({
                employee: yup
                    .object()
                    .shape({ id: yup.number().required(), displayName: yup.string().required() })
                    .required() as yup.ObjectSchema<EmployeeAvatar>,
                manager: (yup.object().shape({ id: yup.number().required(), displayName: yup.string().required() }) as yup.ObjectSchema<EmployeeAvatar>)
                    .nullable()
                    .default(getNull()),
            }),
        )
        .required(),
    effectiveDate: yup
        .string<LocalDate>()
        .default(getNull())
        .test(getLocalDateTestConfig())
        .when('assignments', {
            is: (assignments: Assignment[]) => assignments.every(assignment => !assignment.manager),
            then: schema => schema.nullable(),
            otherwise: schema => schema.required(),
        }),
    assignNewManagerReviews: yup.boolean().required(),
});

export type CleanupStepFormValues = yup.InferType<typeof cleanupStepFormSchema>;
