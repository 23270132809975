import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Department } from '@/domain/department/Department.model';
import { searchObjectiveCategories } from '@/domain/objective-category/ObjectiveCategory.service';
import { ObjectiveSetting } from '@/domain/objective-setting/ObjectiveSetting.model';
import { Objective, OBJECTIVE_TYPE_TYPES, ObjectiveCategory, ObjectiveType } from '@/domain/objective/Objective.model';
import { createObjective, updateObjective } from '@/domain/objective/Objective.service';
import { useGetDepartments } from '@/hooks/department/Department.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';
import { handleError } from '@/utils/api.util';
import { getCurrentLocalDate, getLocalDateTestConfig } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Building06Icon, UserIcon } from 'hugeicons-react';
import { FC, FormEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Employee } from '@/domain/employee/Employee.model';
import { getDepartmentsObjectivesAvailable } from '@/domain/department/Department.service';

type AddObjectiveDialogProps = {
    open: boolean;
    activeObjective: Objective | undefined;
    onSaveObjective: () => void;
    onClose: () => void;
    employeeId?: number;
    parentObjectiveEnabled: boolean;
    disabledEmployeeSelection: boolean;
};

type AddObjectiveDialogFormProps = Omit<AddObjectiveDialogProps, 'open'> & {
    isEdit: boolean;
    objectiveSetting: ObjectiveSetting;
    objectives: Objective[];
    departments: Department[];
    parentObjectiveEnabled: boolean;
    disabledEmployeeSelection: boolean;
};

export const AddObjectiveDialog: FC<AddObjectiveDialogProps> = ({
    open,
    activeObjective,
    parentObjectiveEnabled,
    onClose,
    onSaveObjective,
    employeeId,
    disabledEmployeeSelection,
}) => {
    const { t } = useTranslation();
    const isEdit = !!activeObjective?.id;
    const title = isEdit ? t('objectives.add_objective_dialog.edit_objective') : t('objectives.add_objective_dialog.create_objective');
    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const {
        data: departmentObjectives = [],
        isLoading: isObjectivesLoading,
        error: objectivesError,
        isError: isObjectivesError,
    } = useGetObjectives({
        objectiveType: 'DEPARTMENT',
    });

    if (isEdit && activeObjective?.parent) {
        //hack to display the parent objective if it is archived and not in the list
        const containsParentObjective = departmentObjectives.some(objective => objective.id === activeObjective?.parent?.id);
        if (!containsParentObjective) {
            departmentObjectives.push(activeObjective.parent);
        }
    }

    const { data: departments, isLoading: isDepartmentsLoading, error: departmentsError, isError: isDepartmentsError } = useGetDepartments();

    const isLoading = isLoadingObjectiveSetting || isDepartmentsLoading || isObjectivesLoading;
    const isError = isErrorObjectiveSetting || isDepartmentsError || isObjectivesError;
    const error = errorObjectiveSetting || departmentsError || objectivesError;

    return (
        <DialogWrapper open={open} onClose={onClose} header={title}>
            <DialogContent>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    {objectiveSetting && departments && (
                        <AddObjectiveDialogForm
                            activeObjective={activeObjective}
                            onClose={onClose}
                            onSaveObjective={onSaveObjective}
                            employeeId={employeeId}
                            disabledEmployeeSelection={disabledEmployeeSelection}
                            isEdit={isEdit}
                            departments={departments}
                            objectiveSetting={objectiveSetting}
                            objectives={departmentObjectives}
                            parentObjectiveEnabled={parentObjectiveEnabled}
                        />
                    )}
                </StateHandler>
            </DialogContent>
            <DialogActions>
                <Button type='submit' form={'add-objective-form'} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const AddObjectiveDialogForm: FC<AddObjectiveDialogFormProps> = ({
    activeObjective,
    onClose,
    onSaveObjective,
    employeeId,
    isEdit,
    objectiveSetting,
    objectives,
    departments,
    parentObjectiveEnabled,
    disabledEmployeeSelection,
}) => {
    const { t } = useTranslation();
    const [objectiveCategories, setObjectiveCategories] = useState<ObjectiveCategory[]>([]);

    const { data: employeesOptions = [], isLoading: isLoadingEmployees } = useGetEmployees();

    const schema = yup.object().shape({
        name: yup.string().trim().required(),
        assigneeIds: yup
            .array()
            .of(yup.number().required())
            .min(1)
            .required()
            .when(['objectiveType'], {
                is: (val: ObjectiveType) => val === 'DEPARTMENT',
                then: schema => schema.max(1),
            }),
        description: yup.string().required(),
        objectiveCategoryId: objectiveSetting.objectiveCategoriesEnabled ? yup.number().required() : yup.number(),
        departmentId: yup.number().when(['objectiveType'], {
            is: (val: ObjectiveType) => val === 'DEPARTMENT',
            then: schema => schema.required(),
            otherwise: schema => schema.nullable(),
        }),
        parentId: yup.number().nullable(),
        dueDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        weight: yup.number().required().min(1).max(100).integer(),
        objectiveType: yup.mixed().oneOf(OBJECTIVE_TYPE_TYPES),
    });

    type ObjectiveFormValues = yup.InferType<typeof schema>;

    const {
        handleSubmit,
        reset,
        watch,
        register,
        control,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm<ObjectiveFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...defaultObjective,
            assigneeIds: employeeId ? [employeeId] : [],
            dueDate: getCurrentLocalDate(),
        },
    });

    const assigneeIds = watch('assigneeIds');
    const departmentId = watch('departmentId');

    useEffect(() => {
        // reset parentId when assigneeIds change
        if (assigneeIds.length) {
            setValue('parentId', undefined);
        }
    }, [setValue, assigneeIds]);

    useEffect(() => {
        // reset assigneeIds when department changes
        if (departmentId) {
            setValue('assigneeIds', []);
        }
    }, [setValue, departmentId]);

    useEffect(() => {
        const resetForm = () => {
            const data: Partial<ObjectiveFormValues> = {
                name: activeObjective?.name,
                description: activeObjective?.description,
                assigneeIds: activeObjective?.assignee?.id ? [activeObjective?.assignee?.id] : [],
                dueDate: activeObjective?.dueDate,
                objectiveCategoryId: activeObjective?.category?.id,
                departmentId: activeObjective?.department?.id,
                weight: activeObjective?.weight,
                parentId: parentObjectiveEnabled ? activeObjective?.parent?.id : undefined,
                objectiveType: activeObjective?.department?.id ? 'DEPARTMENT' : 'INDIVIDUAL',
            };
            reset(data);
        };
        if (isEdit) {
            resetForm();
        } else {
            const data = {
                ...defaultObjective,
                assigneeIds: employeeId ? [employeeId] : [],
            };
            reset(data);
        }
    }, [employeeId, activeObjective, reset, employeesOptions, isEdit, parentObjectiveEnabled]);

    useEffect(() => {
        searchObjectiveCategories({ name: '' })
            .then(data => setObjectiveCategories(data))
            .catch(handleError);
    }, []);

    const onSave = async (objectiveRequest: ObjectiveFormValues) => {
        if (isSubmitting) {
            return;
        }
        try {
            if (!isEdit) {
                await create(objectiveRequest);
            } else if (activeObjective?.id) {
                await update(activeObjective?.id, objectiveRequest);
            }
            onCloseDialog();
        } catch (error) {
            handleError(error);
        }
    };

    const create = async (objectiveFormValues: ObjectiveFormValues) => {
        const objectiveRequest = {
            ...objectiveFormValues,
            parentId: objectiveFormValues.parentId ?? undefined,
        };

        try {
            await createObjective(objectiveRequest);
            showSnackbar(t('objectives.messages.created'), 'success');
            onSaveObjective();
        } catch (error) {
            handleError(error);
        }
    };

    const update = async (id: number, objectiveFormValues: ObjectiveFormValues) => {
        const objectiveRequest = {
            ...objectiveFormValues,
            departmentId: objectiveFormValues.objectiveType === 'DEPARTMENT' ? objectiveFormValues.departmentId : undefined,
            assigneeId: objectiveFormValues.assigneeIds[0],
            parentId: objectiveFormValues.parentId ?? undefined,
        };

        try {
            await updateObjective(id, objectiveRequest);
            showSnackbar(t('objectives.messages.updated'), 'success');
            onSaveObjective();
        } catch (error) {
            handleError(error);
        }
    };

    const onCloseDialog = () => {
        onClose();
        const data = {
            ...defaultObjective,
            assigneeIds: employeeId ? [employeeId] : [],
        };
        reset(data);
    };

    const objectiveType = watch('objectiveType');

    const canSelectMultipleEmployees = objectiveType === 'INDIVIDUAL' && !isEdit;

    const stopPropagate = (e: FormEvent<HTMLFormElement>, callback: Promise<void>) => {
        // Prevent the default form submission and stop the event from propagating
        // This ensures the form does not trigger parent forms' onSubmit handlers
        e.preventDefault();
        e.stopPropagation();
        return callback;
    };

    const getParentObjectiveOptions = (assigneeIds: number[], employeesOptions: Employee[], objectives: Objective[], allDepartments: Department[]) => {
        if (assigneeIds.length === 0) {
            return objectives;
        }
        const assignees = assigneeIds.map(assigneeId => employeesOptions.find(employee => employee.id === assigneeId)).filter(item => !!item);

        const assigneeDepartments = assignees.map(assignee => assignee?.currentEmployments.map(employment => employment.department).flat());
        const departmentsObjectivesAvailableByEmployee = assigneeDepartments.map(departments =>
            departments.map(department => getDepartmentsObjectivesAvailable(department, objectives, allDepartments)).flat(),
        );
        const commonDepartmentObjectives = getCommonDepartmentObjectives(departmentsObjectivesAvailableByEmployee);
        const uniqueDepartmentObjectives = commonDepartmentObjectives.reduce((acc, current) => {
            if (!acc.find(obj => obj.id === current.id)) {
                acc.push(current);
            }
            return acc;
        }, [] as Objective[]);
        return uniqueDepartmentObjectives.filter(objective => objective.id !== activeObjective?.id);
    };

    const getCommonDepartmentObjectives = (objectives: Objective[][]) => {
        return objectives.reduce((common, current) => common.filter(objective => current.includes(objective)), objectives[0] ?? []);
    };

    const getAssigneeOptions = (employeesOptions: Employee[], departmentId: number | undefined) => {
        if (departmentId) {
            return employeesOptions.filter(employee => employee.currentEmployments.some(employment => employment.department?.id === departmentId));
        }
        return employeesOptions;
    };

    const objectiveTypeToggleAvailable = !disabledEmployeeSelection && parentObjectiveEnabled;

    return (
        <Stack gap={2} component='form' id='add-objective-form' onSubmit={e => stopPropagate(e, handleSubmit(onSave, console.error)(e))}>
            {objectiveTypeToggleAvailable && (
                <Controller
                    name='objectiveType'
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <ToggleButtonGroup
                            fullWidth
                            value={value}
                            exclusive
                            onChange={(_, type: ObjectiveType) => {
                                if (type === 'DEPARTMENT') {
                                    //because we can only assign one employee to objectives of type department we need to reset the assigneeIds
                                    setValue('assigneeIds', []);
                                }
                                onChange(type);
                            }}
                            size='small'
                            color='primary'
                            disabled={isEdit}
                        >
                            <ToggleButton value={'INDIVIDUAL'}>
                                <Stack gap={1} direction={'row'} alignItems={'center'}>
                                    <UserIcon size={20} />
                                    {t('objectives.add_objective_dialog.individual')}
                                </Stack>
                            </ToggleButton>
                            <ToggleButton value={'DEPARTMENT'}>
                                <Stack gap={1} direction={'row'} alignItems={'center'}>
                                    <Building06Icon size={20} />
                                    {t('objectives.add_objective_dialog.department')}
                                </Stack>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    )}
                />
            )}

            {objectiveType === 'DEPARTMENT' && (
                <FormControlLabel
                    label={t('objectives.add_objective_dialog.department')}
                    control={
                        <FieldSelect
                            name='departmentId'
                            control={control}
                            fullWidth
                            options={departments.flatMap(department => department.id)}
                            getOptionLabel={department => getLabelTranslation(departments.find(d => d.id === department)?.name)}
                            getOptionKey={department => department}
                        />
                    }
                />
            )}

            <Controller
                name='assigneeIds'
                control={control}
                render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                        label={t('objectives.add_objective_dialog.assignee')}
                        disabled={(objectiveType === 'INDIVIDUAL' && isEdit) || disabledEmployeeSelection}
                        control={
                            <Autocomplete
                                fullWidth
                                multiple={canSelectMultipleEmployees && !disabledEmployeeSelection}
                                value={
                                    canSelectMultipleEmployees && !disabledEmployeeSelection
                                        ? employeesOptions.filter(employee => value.includes(employee.id))
                                        : (employeesOptions.find(employee => employee.id === value[0]) ?? getNull())
                                }
                                onChange={(_, employees) => {
                                    if (Array.isArray(employees)) {
                                        const employeeIds = employees.map(employee => employee.id) ?? [];
                                        onChange(employeeIds);
                                    } else {
                                        onChange([employees?.id]);
                                    }
                                }}
                                options={getAssigneeOptions(employeesOptions, departmentId)}
                                loading={isLoadingEmployees}
                                getOptionLabel={employee => employee?.displayName ?? ''}
                                isOptionEqualToValue={(option, current) => option.id === current.id}
                                getOptionKey={employee => employee?.id}
                                renderInput={params => <TextField error={!!errors?.assigneeIds} helperText={errors?.assigneeIds?.message} {...params} />}
                            />
                        }
                    />
                )}
            />

            <FormControlLabel
                label={t('objectives.add_objective_dialog.title')}
                labelPlacement='top'
                control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
            />

            {objectiveSetting.objectiveWeightEnabled && (
                <FormControlLabel
                    label={t('objectives.add_objective_dialog.weight')}
                    control={<FieldNumber control={control} name='weight' step={1} fullWidth />}
                />
            )}

            <FormControlLabel
                label={t('objectives.add_objective_dialog.description')}
                labelPlacement='top'
                control={
                    <TextField
                        fullWidth
                        InputProps={{ multiline: true, minRows: 2 }}
                        error={!!errors.description}
                        helperText={errors.description?.message}
                        {...register('description')}
                    />
                }
            />
            {objectiveSetting.objectiveCategoriesEnabled && (
                <FormControlLabel
                    label={t('objectives.add_objective_dialog.category')}
                    control={
                        <FieldSelect
                            name='objectiveCategoryId'
                            control={control}
                            fullWidth
                            options={objectiveCategories.flatMap(category => category.id)}
                            getOptionLabel={category => getLabelTranslation(objectiveCategories.find(c => c.id === category)?.name)}
                            getOptionKey={category => category}
                            loading={isLoadingEmployees}
                        />
                    }
                />
            )}
            {parentObjectiveEnabled && (
                <FormControlLabel
                    label={t('objectives.add_objective_dialog.parent')}
                    control={
                        <FieldSelect
                            name='parentId'
                            control={control}
                            fullWidth
                            options={getParentObjectiveOptions(assigneeIds, employeesOptions, objectives, departments).map(objective => objective.id)}
                            getOptionLabel={option => objectives.find(o => o.id === option)?.name ?? ''}
                        />
                    }
                />
            )}

            <FormControlLabel label={t('objectives.add_objective_dialog.due_date')} control={<FieldLocalDate name='dueDate' control={control} />} />
        </Stack>
    );
};

const defaultObjective = {
    name: '',
    description: '',
    objectiveType: OBJECTIVE_TYPE_TYPES[0],
    dueDate: getCurrentLocalDate(),
    weight: 1,
};
