import { LeaveRequest, LeaveRequestSearchRequest } from '@/domain/leave-request/LeaveRequest.model';
import { getPendingLeaveRequests, searchLeaveRequests } from '@/domain/leave-request/LeaveRequest.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetPendingLeaveRequests = (): UseQueryResult<LeaveRequest[]> => {
    const [pendingRequests, setPendingRequests] = useState<LeaveRequest[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchPendingLeaveRequests = useCallback(async () => {
        try {
            const leaveRequests = await getPendingLeaveRequests();
            setPendingRequests(leaveRequests);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchPendingLeaveRequests().catch(handleError);
    }, [fetchPendingLeaveRequests]);

    return {
        data: pendingRequests,
        setData: setPendingRequests,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchPendingLeaveRequests,
    };
};

export const useGetLeaveRequests = (search: LeaveRequestSearchRequest): UseQueryResult<LeaveRequest[]> => {
    const [leaveRequests, setLeaveRequests] = useState<LeaveRequest[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLeaveRequests = useCallback(async (search: LeaveRequestSearchRequest) => {
        try {
            setIsFetching(true);
            const leaveRequests = await searchLeaveRequests(search);
            setLeaveRequests(leaveRequests);
        } catch (error) {
            setError(error);
        }
        setIsFetching(false);
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchLeaveRequests(search).catch(handleError);
    }, [fetchLeaveRequests, search]);

    return {
        data: leaveRequests,
        setData: setLeaveRequests,
        isLoading,
        isFetching,
        isError: !!error,
        error,
        refetch: () => fetchLeaveRequests(search),
    };
};
