import { CustomThemeOptions } from '@mui/material';

export const typography: CustomThemeOptions['typography'] = {
    button: {
        textTransform: 'none',
        fontFamily: 'Inter',
    },
    fontFamily: ['Inter', 'Poppins'].join(','),
    h1: {
        fontFamily: 'Poppins',
        fontWeight: 800,
        fontSize: 20,
        lineHeight: '24px',
    },
    h2: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
    },
    h3: {
        fontFamily: 'Poppins',
        fontWeight: 800,
        fontSize: 14,
        lineHeight: '16px',
    },
    body1: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
    },
    body1bold: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '20px',
    },
    body2: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 13,
        lineHeight: '18px',
    },
    body2bold: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: 13,
        lineHeight: '18px',
    },
    body3: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '16px',
    },
    body3bold: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: 12,
        lineHeight: '16px',
    },
    body4: {
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 10,
        lineHeight: '14px',
    },
    body4bold: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: 10,
        lineHeight: '14px',
    },
};
