import { UnitType } from '@/domain/date/Date.model';
import { getEmployeeLeaveTypePolicies } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import {
    approvePendingLeaveRequest,
    canDeclineLeaveRequest,
    canLeaveRequestBeApproved,
    checkConflictsLeaveRequest,
    convertLeavesMinutesToUnit,
    deleteLeaveRequest,
    getLeaveRequestBalanceAsString,
    getLeaveRequestById,
    getLeaveRequestDisplayUnitType,
    getLeaveRequestPeriodAsString,
    getLeaveRequestUsedAmountFormatted,
    isMedicalType,
} from '@/domain/leave-request/LeaveRequest.service';
import { LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import { canApproveRejectLeaveRequests, canEditLeaveRequest, hasConfigureLeavePolicy } from '@/domain/permission/Permission.service';
import { Shift, ShiftReleaseRequest } from '@/domain/shift/Shift.model';
import { StatusChipTooltipTitleLocked } from '@/page/employee-profile/employee-profile-timesheet/EmployeeProfileTimesheetsHistoryPage';
import { ConfirmNegativeBalanceDialog } from '@/page/leave/confirm-negative-balance-dialog/ConfirmNegativeBalanceDialog';
import { LeaveRequestAttachmentsUploaderUncontrolled } from '@/page/leave/leave-request-dialog/LeaveRequestAttachmentsUploader';
import { LeaveRequestDialog } from '@/page/leave/leave-request-dialog/LeaveRequestDialog';
import { LeavesConflictsDialog } from '@/page/leave/leaves-conflicts-dialog/LeavesConflictsDialog';
import { LeavesActionType } from '@/stores/reducers/leavesActions';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { RequestStatusChip } from '@/components/request-status-chip/RequestStatusChip';
import { shiftRelease } from '@/domain/shift/Shift.service';
import { LeaveCancellationConfirmationDialog } from '@/page/leave/LeaveCancellationConfirmationDialog';
import { desktopVisible, mobileBlockVisible } from '@/theme/responsive';
import { getCurrentLocalDate, toDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getYear } from 'date-fns';
import { ArrowRight01Icon, ArrowUp01Icon, AttachmentIcon, Calendar02Icon } from 'hugeicons-react';
import { DeclineLeaveRequestDialog } from './DeclineLeaveRequestDialog';

type EmployeeLeaveRequestListProps = {
    leaveRequests: LeaveRequest[];
    employeeId: number;
    compareDatesByAge: (dateLeft: Date, dateRight: Date) => number;
    onChange: () => void;
};

export const EmployeeLeaveRequestList: FC<EmployeeLeaveRequestListProps> = ({ leaveRequests, employeeId, compareDatesByAge, onChange }) => {
    const theme = useTheme();
    const [rowExpanded, setRowExpanded] = useState<boolean[]>([]);
    const { t } = useTranslation();
    const [leaveRequestToCancel, setLeaveRequestToCancel] = useState<LeaveRequest>();
    const [isLeaveConflictsDialogOpen, setIsLeaveConflictsDialogOpen] = useState<boolean>(false);
    const [shiftReleaseRequest, setShiftReleaseRequest] = useState<ShiftReleaseRequest>();
    const [activeLeaveRequest, setActiveLeaveRequest] = useState<LeaveRequest>();
    const [conflictingShifts, setConflictingShifts] = useState<Shift[]>();
    const [leaveRequestToUpdate, setLeaveRequestToUpdate] = useState<LeaveRequest>();
    const [currentDeclineLeaveRequest, setCurrentDeclineLeaveRequest] = useState<LeaveRequest>();
    const [leaveRequestWithNegativeBalance, setLeaveRequestWithNegativeBalance] = useState<LeaveRequest>();
    const policies = useCurrentPolicies();
    const dispatch = useDispatch();

    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const fetchLeaveTypePolicies = () => {
        getEmployeeLeaveTypePolicies(employeeId)
            .then(userLeaveTypePolicies => {
                dispatch({
                    type: LeavesActionType.USER_LEAVE_TYPE_POLICY_LOADED,
                    userLeaveTypePolicies: userLeaveTypePolicies,
                });
            })
            .catch(handleError);
    };

    const isPendingStatusOrCurrentYear = (request: LeaveRequest) => {
        return request.requestStatus === 'PENDING' || (request.endDate && getYear(getCurrentLocalDate()) <= getYear(request.endDate));
    };

    const getRequestStatusColor = (requestStatus: string) => {
        if (requestStatus === 'APPROVED') {
            return theme.palette.success.main;
        } else if (requestStatus === 'CANCELLED') {
            return theme.palette.error.main;
        } else if (requestStatus === 'DECLINED') {
            return theme.palette.error.main;
        } else if (requestStatus === 'PENDING') {
            return theme.palette.warning.main;
        }
        return '';
    };

    const renderRow = (request: LeaveRequest, expanded: boolean, onExpandStateChanged: (request: LeaveRequest, expanded: boolean) => void) => {
        const durationInDays = (request.durationInMinutes ?? 0) / 60 / 24;
        const isAttachmentMissing =
            request.leaveType?.allowAttachments &&
            !request?.attachments?.length &&
            !!request.leaveType?.attachmentRequiredAfterDays &&
            request.leaveType?.attachmentRequiredAfterDays <= durationInDays;

        return (
            <Accordion
                expanded={expanded}
                onChange={(_, expanded) => onExpandStateChanged(request, expanded)}
                sx={{
                    borderRight: { xs: `4px solid ${getRequestStatusColor(request.requestStatus)}`, sm: 'none' },
                }}
            >
                <AccordionSummary>
                    <Stack direction='row' gap={{ xs: 1, md: 2 }} alignItems='center' justifyContent='space-between' flex={1} width={'100%'}>
                        <Stack direction='row' alignItems='center' gap={{ xs: 1, md: 2 }} flex={{ md: 3, xl: 5 }}>
                            <Box display={{ xs: 'none', md: 'block' }}>
                                {expanded && <ArrowUp01Icon />}
                                {!expanded && <ArrowRight01Icon />}
                            </Box>
                            <Stack flexWrap={{ xs: 'nowrap', md: 'wrap' }} flex={{ xs: 1, md: 0 }}>
                                <Stack direction='row' gap={1} alignItems='center'>
                                    <Calendar02Icon size={16} color={theme.palette.primary.main} alignmentBaseline='central' />
                                    <Typography variant='body2' color='textSecondary' noWrap>
                                        {getLabelTranslation(request.leaveType?.name)}
                                    </Typography>
                                </Stack>
                                {/* Box used to ellipsis the text */}
                                <Box maxWidth={{ xs: '275px', md: '100%' }}>
                                    <Typography variant='body2bold' color='textPrimary' noWrap>
                                        {getLeaveRequestPeriodAsString(request)}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>

                        <Stack sx={desktopVisible} flex={1}>
                            <Typography variant='body2' color='textSecondary'>
                                {t('general.duration')}
                            </Typography>
                            <Typography variant='body2bold' color='textPrimary'>
                                {getLeaveRequestUsedAmountFormatted(request)}
                            </Typography>
                        </Stack>

                        <Stack direction='row' gap={{ xs: 1, md: 2 }} flex={1} justifyContent='flex-end'>
                            {isAttachmentMissing && (
                                <Tooltip title={t('leaves_page.document_is_required', { days: request.leaveType?.attachmentRequiredAfterDays })}>
                                    <span>
                                        <AttachmentIcon color={theme.palette.error.main} />
                                    </span>
                                </Tooltip>
                            )}
                            {!!request?.attachments?.length && <AttachmentIcon />}
                            <Box display={isMobile ? 'none' : 'block'} width={'100px'}>
                                <Tooltip title={shouldDisplayStatusChipTooltip(request) ? <StatusChipTooltipTitleStatus leave={request} /> : undefined}>
                                    <RequestStatusChip status={request.lock ? 'LOCKED' : request.requestStatus} />
                                </Tooltip>
                            </Box>

                            {!request.lock && (
                                <Box>
                                    <BasicMenu items={getUserLeaveRequestMenu(request)} />
                                </Box>
                            )}
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction='column' spacing={3}>
                        <Grid item>
                            <Grid container direction='column' justifyContent='space-between' sx={mobileBlockVisible}>
                                <Grid
                                    item
                                    paddingBottom={{
                                        xs: 1,
                                        md: 0,
                                    }}
                                >
                                    <Tooltip title={shouldDisplayStatusChipTooltip(request) ? <StatusChipTooltipTitleStatus leave={request} /> : undefined}>
                                        <RequestStatusChip status={request.lock ? 'LOCKED' : request.requestStatus} />
                                    </Tooltip>
                                </Grid>
                                <Grid
                                    item
                                    paddingBottom={{
                                        xs: 1,
                                        md: 0,
                                    }}
                                >
                                    <Typography variant='body2' color='textSecondary'>
                                        {t('general.duration')}
                                    </Typography>
                                    <Grid item>
                                        <Typography variant='body2bold' color='textPrimary'>
                                            {getLeaveRequestUsedAmountFormatted(request)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction='row'
                                spacing={5}
                                sx={{
                                    flexFlow: { xs: 'column', md: 'row' },
                                }}
                                marginTop={{ xs: 1, md: 0 }}
                            >
                                {!!request.leavePercentage && isMedicalType(request.leaveType) && (
                                    <Grid
                                        item
                                        pt={{
                                            xs: 1,
                                            md: 0,
                                        }}
                                    >
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography variant='body2'>{t('general.incapacity')}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2bold' color='textPrimary'>
                                                    {`${request.leavePercentage}%`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid
                                    item
                                    pt={{
                                        xs: 1,
                                        md: 0,
                                    }}
                                >
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography variant='body2'>{t('leaves_page.table_headers.remaining_balance')}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body2bold' color='textPrimary'>
                                                {getLeaveRequestBalanceAsString(
                                                    request.unitType,
                                                    request.leaveType,
                                                    request.availableAmountInDays,
                                                    request.availableAmountInMinutes,
                                                    request.leaveType.roundingType,
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {request.comment && (
                                    <Grid
                                        item
                                        pt={{
                                            xs: 1,
                                            md: 0,
                                        }}
                                    >
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography variant='body2'>{t('general.comment')}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2bold' color='textPrimary'>
                                                    {request.comment}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {request.requestStatus === 'DECLINED' && request.declineComment && (
                                    <Grid
                                        item
                                        pt={{
                                            xs: 1,
                                            md: 0,
                                        }}
                                    >
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography variant='body2' color='error'>
                                                    {t('leaves_page.decline_reason')}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2bold' color='textPrimary'>
                                                    {request.declineComment}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {!!request.leaveType?.employeeRequestMessage?.length && (
                                    <Grid
                                        item
                                        pt={{
                                            xs: 1,
                                            md: 0,
                                        }}
                                    >
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography variant='body2'>{t('leaves_page.message')}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2bold' color='textPrimary'>
                                                    {request.leaveType.employeeRequestMessage}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            {request.leaveType?.allowAttachments && expanded && canEditLeaveRequest(request.requestStatus, policies, employeeId) && (
                                <LeaveRequestAttachmentsUploaderUncontrolled leaveRequest={request} p={1} />
                            )}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    const handleApproveSuccess = () => {
        showSnackbar(t('leaves_page.messages.leave_request_approved'), 'success');
        onChange();
    };

    const handleOnApprove = async (leaveRequest: LeaveRequest) => {
        if (!leaveRequest?.id) {
            return;
        }

        try {
            const data = await getLeaveRequestById(leaveRequest.id);
            if (data) {
                const unitType = getLeaveRequestDisplayUnitType(data);
                const balance = convertLeavesMinutesToUnit({
                    input: (unitType === UnitType.DAYS ? data.availableAmountInDays : data.availableAmountInMinutes) ?? 0,
                    outputUnit: unitType,
                    roundingType: data.leaveType?.roundingType,
                });
                if (balance < 0) {
                    setLeaveRequestWithNegativeBalance(data);
                    return;
                }
            }
        } catch (error) {
            handleError(error);
        }
        onApproveCheckConflicts(leaveRequest);
    };

    const onApproveCheckConflicts = async (leaveRequest: LeaveRequest) => {
        if (!leaveRequest.id) {
            return;
        }
        setLeaveRequestWithNegativeBalance(undefined);
        const conflicts = await checkConflictsLeaveRequest(policies, leaveRequest.employee.id, leaveRequest);
        if (conflicts) {
            setConflictingShifts(conflicts.shifts);
            setIsLeaveConflictsDialogOpen(true);
            setShiftReleaseRequest(conflicts.shiftReleaseRequest);
            setActiveLeaveRequest(leaveRequest);
        } else {
            approvePendingLeaveRequest(leaveRequest.id)
                .then(() => {
                    handleApproveSuccess();
                })
                .catch(handleError);
        }
    };

    const getApproveMenuItem = (leaveRequest: LeaveRequest): BasicMenuItem => {
        return {
            title: t('leaves_page.approve'),
            onClick: event => {
                event.stopPropagation();
                handleOnApprove(leaveRequest);
            },
        };
    };

    const getDeclineMenuItem = (leaveRequest: LeaveRequest): BasicMenuItem => {
        return {
            title: t('leaves_page.decline'),
            onClick: event => {
                event.stopPropagation();
                setCurrentDeclineLeaveRequest(leaveRequest);
            },
        };
    };

    const getEditMenuItem = (leaveRequest: LeaveRequest): BasicMenuItem => {
        return {
            title: t('general.edit'),
            onClick: event => {
                event.stopPropagation();
                setLeaveRequestToUpdate(leaveRequest);
            },
            disabled: !canEditLeaveRequest(leaveRequest?.requestStatus, policies, employeeId),
        };
    };

    const getCancelMenuItem = (leaveRequest: LeaveRequest): BasicMenuItem => {
        return {
            title: t('general.cancel'),
            onClick: event => {
                event.stopPropagation();
                setLeaveRequestToCancel(leaveRequest);
            },
            disabled: !canEditLeaveRequest(leaveRequest?.requestStatus, policies, employeeId),
        };
    };

    const getDeleteMenuItem = (leaveRequest: LeaveRequest): BasicMenuItem => {
        return {
            title: t('general.delete'),
            onClick: event => {
                event.stopPropagation();
                if (!leaveRequest?.id) {
                    return;
                }
                setLeaveRequestToCancel(leaveRequest);
                deleteLeaveRequest(leaveRequest.id)
                    .then(() => {
                        onChange();
                    })
                    .catch(error => {
                        handleError(error);
                    });
            },
        };
    };

    const getUserLeaveRequestMenu = (leaveRequest: LeaveRequest) => {
        const menuItems: BasicMenuItem[] = [getEditMenuItem(leaveRequest), getCancelMenuItem(leaveRequest)];
        if (canApproveRejectLeaveRequests(policies, employeeId)) {
            if (leaveRequest && canLeaveRequestBeApproved(leaveRequest)) {
                menuItems.push(getApproveMenuItem(leaveRequest));
            }
            if (leaveRequest && canDeclineLeaveRequest(leaveRequest) && leaveRequest?.leaveType?.leaveActivityType !== LeaveActivityType?.MEDICAL) {
                menuItems.push(getDeclineMenuItem(leaveRequest));
            }
        }
        if (leaveRequest?.requestStatus === 'CANCELLED' && hasConfigureLeavePolicy(policies)) {
            menuItems.push(getDeleteMenuItem(leaveRequest));
        }
        return menuItems;
    };

    return (
        <>
            {leaveRequestToUpdate && (
                <LeaveRequestDialog
                    open={true}
                    onClose={() => setLeaveRequestToUpdate(undefined)}
                    onSave={() => {
                        setLeaveRequestToUpdate(undefined);
                        onChange();
                    }}
                    employeeId={leaveRequestToUpdate.employee.id}
                    leaveRequestId={leaveRequestToUpdate?.id}
                />
            )}

            <LeaveCancellationConfirmationDialog
                leaveRequest={leaveRequestToCancel}
                onSuccess={() => {
                    fetchLeaveTypePolicies();
                    setLeaveRequestToCancel(undefined);
                    onChange();
                }}
                onClose={() => setLeaveRequestToCancel(undefined)}
            />

            <Grid container direction='column' wrap='nowrap' spacing={1}>
                {[...leaveRequests]
                    .sort((a, b) => compareDatesByAge(toDate(a.startDate), toDate(b.startDate)))
                    .filter(r => r.requestStatus !== 'CANCELLED' && r.requestStatus !== 'DECLINED')
                    .map((r, idx) => {
                        if (isPendingStatusOrCurrentYear(r)) {
                            return (
                                <Grid item key={r.id}>
                                    {renderRow(r, rowExpanded[idx] ?? false, (_, newExpanded) => {
                                        const newData = [...rowExpanded];
                                        newData[idx] = newExpanded;
                                        setRowExpanded(newData);
                                    })}
                                </Grid>
                            );
                        }
                    })}
            </Grid>
            {isLeaveConflictsDialogOpen && (
                <LeavesConflictsDialog
                    open={isLeaveConflictsDialogOpen}
                    onClose={() => setIsLeaveConflictsDialogOpen(false)}
                    onSave={() => {
                        if (!shiftReleaseRequest) {
                            return;
                        }
                        shiftRelease(shiftReleaseRequest).then(() => {
                            if (!activeLeaveRequest?.id) {
                                return;
                            }
                            approvePendingLeaveRequest(activeLeaveRequest?.id).then(() => {
                                handleApproveSuccess();
                            });
                            setIsLeaveConflictsDialogOpen(false);
                        });
                        onChange();
                    }}
                    saveAndKeepConflicts={() => {
                        if (!activeLeaveRequest?.id) {
                            return;
                        }
                        approvePendingLeaveRequest(activeLeaveRequest?.id).then(() => {
                            setIsLeaveConflictsDialogOpen(false);
                            handleApproveSuccess();
                        });
                        onChange();
                    }}
                    shifts={conflictingShifts ?? []}
                />
            )}
            <DeclineLeaveRequestDialog
                leaveRequestId={currentDeclineLeaveRequest?.id}
                onCancel={() => setCurrentDeclineLeaveRequest(undefined)}
                onDeclineRequest={() => {
                    setCurrentDeclineLeaveRequest(undefined);
                    onChange();
                }}
            />
            {leaveRequestWithNegativeBalance && (
                <ConfirmNegativeBalanceDialog
                    onSave={() => {
                        onApproveCheckConflicts(leaveRequestWithNegativeBalance);
                    }}
                    onClose={() => setLeaveRequestWithNegativeBalance(undefined)}
                    leaveRequestUnitType={leaveRequestWithNegativeBalance.unitType}
                    leaveType={leaveRequestWithNegativeBalance.leaveType}
                    availableAmountInDays={leaveRequestWithNegativeBalance.availableAmountInDays}
                    availableAmountInMinutes={leaveRequestWithNegativeBalance.availableAmountInMinutes}
                />
            )}
        </>
    );
};

const StatusChipTooltipTitleStatus: FC<{ leave: LeaveRequest }> = ({ leave }) => {
    const { t } = useTranslation();

    if (leave.requestStatus === 'PENDING' && leave.createdBy) {
        return (
            <Typography variant='body2' color='common.white'>
                {t('leaves_page.requested_by', {
                    name: leave.createdBy.displayName,
                })}
            </Typography>
        );
    }

    if (leave.requestStatus === 'APPROVED' && leave.statusUpdatedAt && leave.statusUpdatedBy) {
        return (
            <Typography variant='body2' color='common.white'>
                {t('leaves_page.approved_by', {
                    name: leave.statusUpdatedBy.displayName,
                })}
            </Typography>
        );
    }

    return <StatusChipTooltipTitleLocked lock={leave.lock} />;
};

//this function is used so that we can hide the tooltip when empty
const shouldDisplayStatusChipTooltip = (leave: LeaveRequest): boolean => {
    if (leave.lock) {
        return true;
    }

    if (leave.requestStatus === 'PENDING' && leave.createdBy) {
        return true;
    }

    return leave.requestStatus === 'APPROVED' && !!leave.statusUpdatedAt && !!leave.statusUpdatedBy;
};
