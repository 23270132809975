import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, DialogActions, DialogContent, DialogContentText, Stack } from '@mui/material';
import { FC, PropsWithChildren, ReactElement } from 'react';
import * as yup from 'yup';

import { FieldText } from '@/components/form/field-text/FieldText';
import { useOpenDialog } from '@/hooks/dialog/dialog.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

type DeleteConfirmDialogProps = {
    // Possible improvement: Make the children type more generic to allow any type of children and improve mouse event type
    children?: ReactElement<HTMLButtonElement>;
    onConfirm(): void;
    fieldName: string;
    translationsProps?: {
        deleteFieldTextField: string;
        deleteFieldContent: string;
    };
} & Omit<
    DialogWrapperProps,
    'open' | 'onClose'
    // If open/onClose is defined that means that the dialog is controlled
> & {
        open?: boolean;
        onClose?(): void;
    };

export const DeleteConfirmDialog: FC<PropsWithChildren<DeleteConfirmDialogProps>> = ({
    children,
    translationsProps,
    onConfirm,
    open = false,
    onClose,
    fieldName,
    ...rest
}) => {
    const { t } = useTranslation();
    type DeleteConfirmDialogFormValues = {
        title: string;
    };

    const translations = translationsProps ?? {
        deleteFieldTextField: t('delete_confirmation_dialog.default.delete_field_text_field'),
        deleteFieldContent: t('delete_confirmation_dialog.default.delete_field_content'),
    };

    const deleteConfirmDialogSchema = yup.object().shape({
        title: yup
            .string()
            .required()
            .oneOf([fieldName], t('delete_confirmation_dialog.error_message', { name: fieldName })),
    });

    const { control, handleSubmit } = useForm<DeleteConfirmDialogFormValues>({
        resolver: yupResolver(deleteConfirmDialogSchema),
        defaultValues: {
            title: '',
        },
    });

    const { childrenWithOnOpen, handleInternalClose, handleClose, isOpen } = useOpenDialog(children, open, onClose);

    return (
        <>
            {/* Render the cloned children with the onClick event handler */}
            {childrenWithOnOpen}

            <DialogWrapper open={isOpen} onClose={handleClose} {...rest} header={translations.deleteFieldTextField}>
                <DialogContent>
                    <Stack gap={2}>
                        {/* We are using DialogContentText to ensure that the content is styled correctly */}
                        <DialogContentText>
                            <Trans i18nKey={translations.deleteFieldContent} components={{ bold: <strong /> }} />
                        </DialogContentText>

                        <FieldText
                            name='title'
                            control={control}
                            defaultValue=''
                            autoFocus
                            fullWidth
                            placeholder={t('delete_confirmation_dialog.type_field_name', { name: fieldName })}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        fullWidth
                        variant='contained'
                        color={'error'}
                        onClick={() => {
                            handleSubmit(
                                () => {
                                    onConfirm();
                                    if (!onClose) {
                                        handleInternalClose();
                                    }
                                },
                                errors => {
                                    console.error(errors);
                                },
                            )();
                        }}
                    >
                        {t('delete_confirmation_dialog.delete_permanently')}
                    </Button>
                </DialogActions>
            </DialogWrapper>
        </>
    );
};
