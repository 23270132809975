import { FilePickerItem, TemporaryFile } from '@/components/file-picker/FilePicker';

/**
 * Check if the file is a temporary file
 * @param file
 * @returns boolean
 */
export const isTemporaryFile = (file: FilePickerItem): file is TemporaryFile => {
    return 'data' in file;
};
