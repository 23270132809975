import { useGetCompanyFolders } from '@/hooks/document/Document.hook';
import { FC } from 'react';
import { CompanyDocumentContainer } from './company/CompanyDocumentContainer';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { useFolderParam } from '@/page/employee-profile/employee-profile-document/useFolderParam';

export const CompanyDocumentsPage: FC = () => {
    const { data = [], isLoading, isError, error } = useGetCompanyFolders();

    const { folderId, setFolderId } = useFolderParam(data);
    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <CompanyDocumentContainer folders={data} onFolderClick={setFolderId} folderId={folderId} />
        </StateHandler>
    );
};
