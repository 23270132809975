import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { Employee } from '@/domain/employee/Employee.model';
import { getRequestStatusTranslationKey } from '@/domain/leave-request/LeaveRequest.service';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { canManagePendingTimesheets } from '@/domain/permission/Permission.service';
import { TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { DailyTimesheetReport, DayTimesheet, Timesheet, TimesheetRequestDisplayStatus, TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { TimesheetDisplay } from '@/page/employee-profile/employee-profile-timesheet/timesheets-history/TimesheetDisplay';
import { TimesheetRow } from '@/page/employee-profile/employee-profile-timesheet/EmployeeProfileTimesheetsHistoryPage';
import { useCurrentPolicies } from '@/stores/store';
import {
    formatDurationInHours,
    formatInDayMonthYear,
    formatInDefaultHours,
    getMonthTranslationKey,
    getTimeFormatFromDate,
    MONTHS,
} from '@/utils/datetime.util';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { AlertCircleIcon, ArrowDown01Icon } from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { calculateDurationWithBreakFromHoursMinutes, getTimesheetStatus, isMissingTimesheetNoAutofill } from '../EmployeeTimesheet.util';

type MobileTimesheetHistoryProps = {
    year: string;
    month: MONTHS;
    dailyTimesheetReport: DailyTimesheetReport;
    rows: TimesheetRow[];
    menuRenderer: (row: TimesheetRow) => BasicMenuItem[];
    onEditClicked: (timesheet: TimesheetRow) => void;
    addTimesheet: () => void;
    leaveTypes: LeaveType[];
    employee: Employee;
};

export const MobileTimesheetHistory: FC<MobileTimesheetHistoryProps> = ({
    dailyTimesheetReport,
    year,
    month,
    rows,
    menuRenderer,
    addTimesheet,
    onEditClicked,
    leaveTypes,
    employee,
}) => {
    const { t } = useTranslation();
    const policies = useCurrentPolicies();
    const theme = useTheme();

    const getBorderColorByStatus = (row: TimesheetRow) => {
        if (!row || row.timesheetsGroupedByTypeRowSpan === 0) {
            return;
        }
        const status = getTimesheetStatus(row.timesheet, row.timesheetsGroupedByType);
        switch (status) {
            case TimesheetRequestDisplayStatus.APPROVED: {
                return theme.palette.success.main;
            }
            case TimesheetRequestDisplayStatus.MISSING: {
                return theme.palette.grey[100];
            }
            case TimesheetRequestDisplayStatus.DECLINED: {
                return theme.palette.error.main;
            }
            case TimesheetRequestDisplayStatus.CANCELLED: {
                return theme.palette.error.main;
            }
            case TimesheetRequestDisplayStatus.PENDING: {
                return theme.palette.warning.main;
            }
        }
    };

    const groupedRowsByDay = groupByDay(rows);

    return (
        <Stack gap={1} p={1.5}>
            <MobileTimesheetAccordion month={month} year={year} dayTimesheet={dailyTimesheetReport} />

            <Stack>{canManagePendingTimesheets(policies, employee?.id) && <Button onClick={() => addTimesheet()}>{t('timesheets.timesheet')}</Button>}</Stack>

            <Stack gap={1}>
                {groupedRowsByDay?.map(({ day, rowsByType: rowsPerDay }) => (
                    <Stack key={`${day.date}_${day?.timesheets[0]?.id}_${day?.timesheets}`}>
                        <Typography variant='body2bold'>{formatInDayMonthYear(day?.date)}</Typography>
                        {!!rowsPerDay?.length && (
                            <Stack gap={0.5} flex='1' direction={'column'} justifyContent={'space-between'}>
                                {rowsPerDay?.map(rowByType => (
                                    <Stack
                                        key={rowByType.map(row => '' + row.timesheet.id + row.timesheet.startAt).join('_')}
                                        gap={0.5}
                                        p={1}
                                        borderRadius={1}
                                        flex={1}
                                        onClick={() => {
                                            onEditClicked?.(rowByType[0]);
                                        }}
                                        bgcolor={theme.palette.grey[100]}
                                        sx={{
                                            // borderRight={rowByType[0].timesheet.type === TimesheetType.TIMESHEET ? '0.5em solid' : undefined}
                                            // TODO improve this to use border and manage the space between the border and the content
                                            boxShadow: `5px 0px 0 ${getBorderColorByStatus(rowByType[0])}`,
                                        }}
                                    >
                                        {rowByType?.map(row => (
                                            <Stack key={`${row?.day?.date}_${row?.timesheet?.id}`} direction={'row'} flex={1}>
                                                <TimesheetTypeShift
                                                    timesheetMode={row?.timesheet?.timesheetSetting?.timesheetMode}
                                                    menuRenderer={menuRenderer}
                                                    row={row}
                                                    leaveTypes={leaveTypes}
                                                />
                                            </Stack>
                                        ))}
                                    </Stack>
                                ))}
                            </Stack>
                        )}
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

type MobileTimesheetAccordionProps = {
    year: string;
    month: MONTHS;
    dayTimesheet: DailyTimesheetReport;
};

const MobileTimesheetAccordion: FC<MobileTimesheetAccordionProps> = ({ year, month, dayTimesheet }) => {
    const { t } = useTranslation();

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ArrowDown01Icon />}>
                <Stack direction={'row'} justifyContent={'space-between'} flexGrow={1}>
                    <Typography variant='body2bold'>{`${t(getMonthTranslationKey(month))} ${year}`}</Typography>

                    <Typography variant='body2'>
                        {formatDurationInHours(dayTimesheet.totalWorkedCount)} / {formatDurationInHours(dayTimesheet.totalContractCount)}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={1}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant='body2bold'>{t('timesheets.worked_hours')}</Typography>
                        <Typography variant='body2'>{formatDurationInHours(dayTimesheet.totalWorkedCount)}</Typography>
                    </Stack>

                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant='body2bold'>{t('timesheets.leaves')}</Typography>
                        <Typography variant='body2'>{formatDurationInHours(dayTimesheet.totalLeaveCount)}</Typography>
                    </Stack>

                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant='body2bold'>{t('timesheets.contract')}</Typography>
                        <Typography variant='body2'>{formatDurationInHours(dayTimesheet.totalContractCount)}</Typography>
                    </Stack>

                    <Divider />

                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography variant='body2bold'>{t('timesheets.difference')}</Typography>
                        <Typography variant='body2'>{formatDurationInHours(dayTimesheet.totalDifference)}</Typography>
                    </Stack>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

type TimesheetTypeShiftProps = {
    row: TimesheetRow;
    timesheetMode: TimesheetMode | undefined;
    leaveTypes: LeaveType[];
    menuRenderer?: (row: TimesheetRow) => BasicMenuItem[];
};
const TimesheetTypeShift: FC<TimesheetTypeShiftProps> = ({ row, timesheetMode, leaveTypes, menuRenderer }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const getTimesheetTypography = (timesheet: Timesheet) => {
        const isTimesheet = timesheet.type === TimesheetType.TIMESHEET || timesheet.type === TimesheetType.MISSING || timesheet.type === TimesheetType.AUTOFILL;
        const isNonWorkingDay = timesheet.type === TimesheetType.NON_WORKING_DAY;
        if (isMissingTimesheetNoAutofill(timesheet)) {
            if (timesheet.lock) {
                return (
                    <Stack direction={'row'} alignItems={'center'} color={theme.palette.error.main} gap={1}>
                        <Typography variant='body2'>{t(getRequestStatusTranslationKey(TimesheetRequestDisplayStatus.LOCKED))}</Typography>
                    </Stack>
                );
            }
            return (
                <Stack direction={'row'} alignItems={'center'} color={theme.palette.error.main} gap={1}>
                    <AlertCircleIcon />
                    <Typography color={theme.palette.error.main} variant='body2'>
                        {t('timesheets.table_headers.missing')}
                    </Typography>
                </Stack>
            );
        }

        return (
            <Stack direction={'row'} gap={1} minHeight={14}>
                {isTimesheet && (
                    <>
                        {(!timesheetMode || timesheetMode === TimesheetMode.NORMAL) && (
                            <Typography variant='body2'>
                                {formatInDefaultHours(timesheet.startAt)} - {formatInDefaultHours(timesheet.endAt)}{' '}
                                {timesheet.breakDuration ? `(${timesheet.breakDuration})` : undefined}
                            </Typography>
                        )}
                        {timesheetMode === TimesheetMode.SIMPLIFIED && (
                            <Typography variant='body2'>
                                {calculateDurationWithBreakFromHoursMinutes(
                                    getTimeFormatFromDate(timesheet.startAt),
                                    getTimeFormatFromDate(timesheet.endAt),
                                    timesheet?.breakDuration,
                                )}
                            </Typography>
                        )}
                        {timesheet.area?.name && (
                            <Typography variant='body2' noWrap>
                                {timesheet.area?.name}
                            </Typography>
                        )}
                    </>
                )}

                {!isTimesheet && !isNonWorkingDay && (
                    <Typography variant='body2'>
                        <TimesheetDisplay item={timesheet} userLeaveTypes={leaveTypes} timesheetSetting={row.timesheet?.timesheetSetting} />
                    </Typography>
                )}

                {isNonWorkingDay && <Typography variant='body2'>{t('timesheets.non_working_day')}</Typography>}
            </Stack>
        );
    };

    return (
        <Stack
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            direction={'row'}
            gap={1}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ width: '100%' }}
        >
            {getTimesheetTypography(row.timesheet)}

            {/* Only the first row of type stack should have the menu,
            we use the status to check if it's the first row */}
            {!!row.status && menuRenderer && (
                <Box>
                    <BasicMenu items={menuRenderer(row)} />
                </Box>
            )}
        </Stack>
    );
};

type GroupedRows = {
    day: DayTimesheet;
    // Warning only the row with the type TimesheetType.TIMESHEET will be grouped
    rowsByType: TimesheetRow[][];
};

const groupByDay = (rows: TimesheetRow[] | null | undefined): GroupedRows[] => {
    if (!rows) {
        return [];
    }

    const groupedRows: GroupedRows[] = [];
    let currentDay: DayTimesheet | undefined;

    const addRowToExistingGroup = (row: TimesheetRow, groupedRows: GroupedRows[]) => {
        const lastGroupedRow = groupedRows[groupedRows.length - 1];
        if (row.timesheet.type === TimesheetType.TIMESHEET || row.timesheet.type === TimesheetType.MISSING) {
            const timesheetRowsIndex = lastGroupedRow.rowsByType.findIndex(
                stack => stack.findIndex(r => r.timesheet.type === TimesheetType.TIMESHEET || r.timesheet.type === TimesheetType.MISSING) !== -1,
            );
            if (timesheetRowsIndex !== -1) {
                lastGroupedRow.rowsByType[timesheetRowsIndex].push(row);
                return;
            }
        }
        lastGroupedRow.rowsByType.push([row]);
    };

    const addRowToNewGroup = (row: TimesheetRow, groupedRows: GroupedRows[]) => {
        currentDay = row.day;

        if (!currentDay) {
            return;
        }
        groupedRows.push({ day: currentDay, rowsByType: [[row]] });
    };

    rows.forEach(row => {
        if (row.day) {
            addRowToNewGroup(row, groupedRows);
        } else if (currentDay) {
            addRowToExistingGroup(row, groupedRows);
        }
    });

    return groupedRows;
};
