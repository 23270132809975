import { Button, Divider, Paper, Typography } from '@mui/material';
import { Edit02Icon } from 'hugeicons-react';
import { FC, HTMLAttributes } from 'react';

type AdditionalActionPaperProps = {
    onAdditionalActionClick: () => void;
    additionalActionLabel: string;
    displayAdditionalAction: boolean;
} & HTMLAttributes<HTMLElement>;

export const AdditionalActionPaper: FC<AdditionalActionPaperProps> = ({
    children,
    onAdditionalActionClick,
    additionalActionLabel,
    displayAdditionalAction,
    ...rest
}) => {
    if (!displayAdditionalAction) {
        return <Paper {...rest}>{children}</Paper>;
    }

    return (
        <Paper {...rest}>
            {children}

            <Divider />
            <Button variant='text' fullWidth sx={{ justifyContent: 'flex-start', p: 2 }} onMouseDown={onAdditionalActionClick} startIcon={<Edit02Icon />}>
                <Typography variant='body1' color={'text.primary'}>
                    {additionalActionLabel}
                </Typography>
            </Button>
        </Paper>
    );
};
