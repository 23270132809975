import { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Omit<Theme, 'components'>>['MuiTooltip'] = {
    defaultProps: {
        arrow: true,
        placement: 'top',
    },
    styleOverrides: {
        tooltip: ({ theme }) => ({
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.common.white,
        }),
        arrow: ({ theme }) => ({
            color: theme.palette.grey[900],
        }),
    },
};
