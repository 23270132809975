import { FC } from 'react';
import { Box } from '@mui/material';

type ColorDotProps = {
    color: string | undefined;
};

export const ColorDot: FC<ColorDotProps> = ({ color }) => {
    return <Box height={'10px'} width={'10px'} bgcolor={color} borderRadius={'50%'} />;
};
