import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { ObjectiveCompletionStatus } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.model';
import { searchCompletionStatuses } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.service';
import { ObjectiveCloseMutation } from '@/domain/objective/Objective.model';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type CloseObjectiveDialogProps = {
    onClose: () => void;
    onComplete: (objectiveCompletion: ObjectiveCloseMutation) => void;
};

export const CloseObjectiveDialog: FC<CloseObjectiveDialogProps> = ({ onClose, onComplete }) => {
    const { t } = useTranslation();
    const [completionStatuses, setCompletionStatuses] = useState<ObjectiveCompletionStatus[]>([]);

    const fetchCompletionStatuses = async () => {
        try {
            const data = await searchCompletionStatuses({});
            setCompletionStatuses(data);
        } catch (e) {
            handleError(e);
        }
    };

    useEffect(() => {
        fetchCompletionStatuses().catch(handleError);
    }, []);

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm<ObjectiveCloseFormValues>({
        resolver: yupResolver(objectiveCloseFormSchema),
        defaultValues: {
            completionStatusId: undefined,
            completionComment: '',
        },
    });

    return (
        <DialogWrapper header={t('objectives.close_objective_dialog.title')} open={true} onClose={onClose}>
            <Stack gap={2} component={DialogContent}>
                <Stack>
                    <Controller
                        name='completionStatusId'
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                label={t('objectives.close_objective_dialog.completion_status') + '*'}
                                control={
                                    <Select id='completionStatus' fullWidth error={!!errors.completionStatusId} {...field}>
                                        {completionStatuses.map(objectiveCompletionStatus => (
                                            <MenuItem key={objectiveCompletionStatus.id} value={objectiveCompletionStatus.id}>
                                                {getLabelTranslation(objectiveCompletionStatus.name)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                }
                            />
                        )}
                    />
                    {!!errors.completionStatusId && (
                        <Typography variant='body2' color='error'>
                            {errors.completionStatusId.message?.toString()}
                        </Typography>
                    )}
                </Stack>

                <FormControlLabel
                    label={t('objectives.close_objective_dialog.completion_comment')}
                    labelPlacement='top'
                    control={
                        <TextField
                            fullWidth
                            multiline
                            minRows={2}
                            error={!!errors.completionComment}
                            helperText={errors.completionComment?.message}
                            {...register('completionComment')}
                        />
                    }
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(onComplete, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const objectiveCloseFormSchema = yup.object().shape({
    completionStatusId: yup.number().required(),
    completionComment: yup.string(),
});
type ObjectiveCloseFormValues = yup.InferType<typeof objectiveCloseFormSchema>;
