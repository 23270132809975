import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { getPlanningById, searchPlannings } from '@/domain/planning/Planning.service';
import { Planning, PlanningSearch } from '@/domain/planning/Planning.model';
import { handleError } from '@/utils/api.util';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetPlanningById = (planningId?: number): UseQueryResult<Planning> => {
    const [planning, setPlanning] = useState<Planning>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchPlanning = useCallback(async () => {
        setIsLoading(true);
        if (planningId) {
            try {
                const data = await getPlanningById(planningId);
                setPlanning(data);
            } catch (error) {
                setError(error);
            }
        }
        setIsLoading(false);
    }, [planningId]);

    useEffect(() => {
        fetchPlanning().catch(handleError);
    }, [fetchPlanning]);

    return {
        data: planning,
        setData: setPlanning,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchPlanning(),
    };
};

export const useSearchPlannings = (search: PlanningSearch, options = { enable: true }): UseQueryResult<Planning[]> => {
    const [plannings, setPlannings] = useState<Planning[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchPlannings = useCallback(async (search: PlanningSearch) => {
        setIsLoading(true);
        try {
            const data = await searchPlannings(search);
            setPlannings(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        if (!options.enable) {
            setIsLoading(false);
        }
        fetchPlannings(search).catch(handleError);
    }, [fetchPlannings, options.enable, search]);

    return {
        data: plannings,
        setData: setPlannings,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchPlannings(search),
    };
};
