export enum UnitType {
    DAYS = 'DAYS',
    HOURS = 'HOURS',
    BOTH = 'BOTH',
}

export enum DayPeriod {
    ALL_DAY = 'ALL_DAY',
    MORNING = 'MORNING',
    AFTERNOON = 'AFTERNOON',
}
