import { SplitButtonGroup } from '@/components/button-group/SplitButtonGroup';
import { Employee } from '@/domain/employee/Employee.model';
import { canManagePendingTimesheets, canManageTimesheetAdjustments, canManageTimesheetPayments } from '@/domain/permission/Permission.service';
import { TimesheetAction } from '@/domain/timesheet/Timesheet.model';
import { AddAdjustmentDialog } from '@/page/employee-timesheet/adjustment-dialog/AddAdjustmentDialog';
import { AddPaymentDialog } from '@/page/employee-timesheet/payment-dialog/AddPaymentDialog';
import { TimesheetDialog } from '@/page/timesheet/timesheet-dialog/TimesheetDialog';
import { useCurrentPolicies } from '@/stores/store';
import { getCurrentLocalDate } from '@/utils/datetime.util';
import { Add01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    employee: Employee;
    onExportClick: () => void;
    onSuccess: () => void;
};

export const TimesheetsHistoryActionsButtons: FC<Props> = ({ employee, onExportClick, onSuccess }) => {
    const { t } = useTranslation();

    const [addTimesheetDialogOpen, setAddTimesheetDialogOpen] = useState(false);
    const [addPaymentDialogOpen, setAddPaymentDialogOpen] = useState(false);
    const [addAdjustmentDialogOpen, setAddAdjustmentDialogOpen] = useState(false);

    const policies = useCurrentPolicies();

    // TODO improve this with TS 5.5 and filter the buttons based on the policies
    const buildMenuItems = () => {
        const menuItemsProps = [];

        if (canManagePendingTimesheets(policies, employee.id)) {
            menuItemsProps.push({
                title: t('timesheets.timesheet'),
                onClick: () => setAddTimesheetDialogOpen(true),
                key: 'addTimesheetDialogButton',
            });
        }
        if (canManageTimesheetPayments(policies, employee.id)) {
            menuItemsProps.push({
                title: t('timesheets.history_page.add_payment'),
                onClick: () => setAddPaymentDialogOpen(true),
                key: 'addPaymentsDialogButton',
            });
        }

        if (canManageTimesheetAdjustments(policies, employee.id)) {
            menuItemsProps.push({
                title: t('timesheets.history_page.add_adjustment'),
                onClick: () => setAddAdjustmentDialogOpen(true),
                key: 'addAdjustmentsDialogButton',
            });
        }

        menuItemsProps.push({
            title: t('timesheets.history_page.export_excel'),
            onClick: onExportClick,
            key: 'exportAsPDFDialogButton',
        });

        return menuItemsProps;
    };

    const menuItemsProps = buildMenuItems();

    const handleAddTimesheetDialogSave = () => {
        setAddTimesheetDialogOpen(false);
        onSuccess();
    };

    // Possible improvement: Put service call in dialog component
    const handlePaymentDialogSave = () => {
        setAddPaymentDialogOpen(false);
        onSuccess();
    };

    const handleAdjustmentDialogSave = () => {
        setAddAdjustmentDialogOpen(false);
        onSuccess();
    };

    return (
        <>
            <SplitButtonGroup
                buttonProps={{
                    variant: 'contained',
                    startIcon: <Add01Icon size={20} />,
                    size: 'small',
                }}
                menuItemsProps={menuItemsProps}
                ariaLabel={'Timesheet histories actions buttons'}
            />

            {addTimesheetDialogOpen && (
                <TimesheetDialog
                    defaultReferenceDate={getCurrentLocalDate()}
                    mode={TimesheetAction.CREATE}
                    open={true}
                    employee={employee}
                    onClose={() => setAddTimesheetDialogOpen(false)}
                    onSave={handleAddTimesheetDialogSave}
                />
            )}

            {addPaymentDialogOpen && (
                <AddPaymentDialog open={true} onClose={() => setAddPaymentDialogOpen(false)} onSave={handlePaymentDialogSave} employeeId={employee.id} />
            )}

            {addAdjustmentDialogOpen && (
                <AddAdjustmentDialog
                    open={true}
                    onClose={() => setAddAdjustmentDialogOpen(false)}
                    onSave={handleAdjustmentDialogSave}
                    employeeIds={[employee.id]}
                />
            )}
        </>
    );
};
