import { permissionGroupApi } from '@/api/permission-group/PermissionGroup.api';
import { PermissionGroup } from '@/domain/permission-group/PermissionGroup.model';

export const getPermissionGroups = (): Promise<PermissionGroup[]> => {
    return permissionGroupApi.getPermissionGroups();
};

export const getPermissionGroupById = (groupId: number): Promise<PermissionGroup> => {
    return permissionGroupApi.getPermissionGroupById(groupId);
};

export const createPermissionGroup = (permissionGroup: PermissionGroup): Promise<PermissionGroup> => {
    return permissionGroupApi.createPermissionGroup(permissionGroup);
};

export const updatePermissionGroup = (permissionGroup: PermissionGroup, groupId: number): Promise<PermissionGroup> => {
    return permissionGroupApi.updatePermissionGroup(permissionGroup, groupId);
};

export const deletePermissionGroup = (groupId: number): Promise<void> => {
    return permissionGroupApi.deletePermissionGroup(groupId);
};

export const getGroupsByEmployeeId = (employeeId: number): Promise<PermissionGroup[]> => {
    return permissionGroupApi.getGroupsByEmployeeId(employeeId);
};
