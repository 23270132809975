import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import {
    CompletionScaleCreateMutation,
    CompletionScaleUpdateMutation,
    CompletionStatusSearch,
    ObjectiveCompletionStatus,
} from '@/domain/objective-completion-status/ObjectiveCompletionStatus.model';
import { OrderMutation } from '@/domain/common';

type CompletionStatusSearchRequestDTO = CompletionStatusSearch;
type CompletionScaleCreateRequestDTO = CompletionScaleCreateMutation;
type CompletionScaleUpdateRequestDTO = CompletionScaleUpdateMutation;
export type ObjectiveCompletionStatusDTO = ObjectiveCompletionStatus;

const searchCompletionStatuses = async (search: CompletionStatusSearch = {}): Promise<ObjectiveCompletionStatus[]> => {
    return (
        await client.post<ObjectiveCompletionStatusDTO[], AxiosResponse<ObjectiveCompletionStatusDTO[]>, CompletionStatusSearchRequestDTO>(
            API_BASE_URL + `/objectives/completion-status/search`,
            search,
        )
    ).data;
};

const updateCompletionStatusOrder = async (request: OrderMutation[]): Promise<void> => {
    return client.put(API_BASE_URL + `/objectives/completion-status/order`, request);
};

const deleteCompletionStatus = async (objectiveCompletionStatusId: number): Promise<void> => {
    return client.delete(API_BASE_URL + `/objectives/completion-status/${objectiveCompletionStatusId}`);
};

const createCompletionStatus = async (mutation: CompletionScaleCreateMutation): Promise<ObjectiveCompletionStatus> => {
    return (
        await client.post<ObjectiveCompletionStatusDTO, AxiosResponse<ObjectiveCompletionStatusDTO>, CompletionScaleCreateRequestDTO>(
            API_BASE_URL + `/objectives/completion-status`,
            mutation,
        )
    ).data;
};

const updateCompletionStatus = async (request: CompletionScaleUpdateMutation, objectiveCompletionStatusId: number): Promise<ObjectiveCompletionStatus> => {
    return (
        await client.put<ObjectiveCompletionStatusDTO, AxiosResponse<ObjectiveCompletionStatusDTO>, CompletionScaleUpdateRequestDTO>(
            API_BASE_URL + `/objectives/completion-status/${objectiveCompletionStatusId}`,
            request,
        )
    ).data;
};

export const objectiveCompletionStatusApi = {
    searchCompletionStatuses,
    updateCompletionStatusOrder,
    deleteCompletionStatus,
    createCompletionStatus,
    updateCompletionStatus,
};
