import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { DefaultRealmNotification, DefaultRealmNotificationUpdate } from '@/domain/default-realm-notification/DefaultRealmNotification.model';

type DefaultRealmNotificationDTO = DefaultRealmNotification;
type DefaultRealmNotificationUpdateDTO = DefaultRealmNotificationUpdate;

const getDefaultRealmNotification = async (): Promise<DefaultRealmNotification> => {
    const url = API_BASE_URL + `/default-realm-notifications`;
    return (await client.get<DefaultRealmNotificationDTO>(url)).data;
};

const updateDefaultRealmNotification = async (
    defaultRealmNotificationId: number,
    request: DefaultRealmNotificationUpdate,
): Promise<DefaultRealmNotification> => {
    const url = API_BASE_URL + `/default-realm-notifications/${defaultRealmNotificationId}`;
    return (await client.put<DefaultRealmNotificationDTO, AxiosResponse<DefaultRealmNotificationDTO>, DefaultRealmNotificationUpdateDTO>(url, request)).data;
};

export const defaultRealmNotificationAPI = {
    getDefaultRealmNotification,
    updateDefaultRealmNotification,
};
