import { getUserEmploymentStatusTranslationKey, searchEmployees } from '@/domain/employee/Employee.service';
import { EmploymentStatus } from '@/domain/employment/Employment.model';

import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { AsyncSelectFilter, SelectFilter } from '@/components/filters-bar/FiltersBar';
import { getDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';
import { getLocations } from '@/domain/location/Location.service';
import { convertEnumToOptions } from '@/utils/enum.util';
import { getLabelTranslation } from '@/utils/language.util';
import { useTranslation } from 'react-i18next';

export type EmployeeFilter = SelectFilter | AsyncSelectFilter;

/**
 * Hook to get the list of filter for employee
 */
export const useEmployeeFilters = (): { filters: EmployeeFilter[] } => {
    const { t } = useTranslation();

    const filters: EmployeeFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_STATUS' }),
            type: 'multi-select',
            options: convertEnumToOptions(EmploymentStatus, key => t(getUserEmploymentStatusTranslationKey(), { context: key })),
            selectMode: 'SYNC',
            key: 'employmentStatuses',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'locationIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            key: 'departmentIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobs = await getJobs();
                return jobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                }));
            },
            key: 'jobIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees();
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'managerIds',
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
