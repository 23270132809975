import { EmployeeReviewDTO, mapEmployeeReminderDTO, mapEmployeeReviewerDTO } from '@/api/employee-review/EmployeeReview.api';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { reviewRatingScaleApi, ReviewRatingScaleDTO } from '@/api/review-rating-scale/ReviewRatingScale.api';
import { mapReviewTemplateDTO, ReviewTemplateDTO } from '@/api/review-template/ReviewTemplate.api';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { ReviewTemplateItemType } from '@/domain/review-template/ReviewTemplate.model';
import {
    EmployeeReviewPatchMutation,
    Review,
    ReviewCycleCreationMutation,
    ReviewEmail,
    ReviewEmailMutation,
    ReviewItem,
    ReviewOffboardingCreationMutation,
    ReviewOnboardingCreationMutation,
    ReviewOneShotCreationMutation,
    ReviewPatchMutation,
    ReviewSearch,
    ReviewSendReminderMutation,
    ReviewStartMutation,
    ReviewUpdateMutation,
} from '@/domain/review/Review.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '../common';
import { SkillDTO } from '@/api/skill/Skill.api';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

export type ReviewDTO = Overwrite<
    Review,
    {
        items: ReviewItemDTO[];
        createdBy: EmployeeDTO;
        reviewTemplate: ReviewTemplateDTO;
        employeeReviews: EmployeeReviewDTO[];
        owners: EmployeeDTO[];
        emails: ReviewEmailDTO[];
    }
>;

export type ReviewEmailDTO = ReviewEmail;
export type ReviewItemDTO = Overwrite<
    ReviewItem,
    {
        type: ReviewTemplateItemType;
        skill: SkillDTO;
        rating?: ReviewRatingScaleDTO;
    }
>;

type ReviewUpdateRequestDTO = ReviewUpdateMutation;

type ReviewCycleCreationRequestDTO = ReviewCycleCreationMutation;

type ReviewOneShotCreationRequestDTO = ReviewOneShotCreationMutation;

type ReviewOnboardingCreationRequestDTO = ReviewOnboardingCreationMutation;

type ReviewOffboardingCreationRequestDTO = ReviewOffboardingCreationMutation;

type ReviewStartRequest = Omit<ReviewStartMutation, 'feedbackDeadlineDate' | 'endDate' | 'emails'> & {
    feedbackDeadlineDate: string;
    endDate: string;
    emails: ReviewEmailRequest[];
};

type ReviewEmailRequest = ReviewEmailMutation;

type ReviewSearchRequest = ReviewSearch;

type ReviewSendReminderRequestDTO = ReviewSendReminderMutation;

export const mapReviewDTO = (review: ReviewDTO): Review => {
    return {
        ...review,
        items: review.items.map(item => ({
            ...item,
            rating: item.rating ? reviewRatingScaleApi.mapToReviewRatingScale(item.rating) : undefined,
        })),
        employeeReviews: review.employeeReviews?.map(employeeReview => mapEmployeeReviewDTO(employeeReview)),
        reviewTemplate: mapReviewTemplateDTO(review.reviewTemplate),
        createdBy: review.createdBy ? mapEmployeeDTO(review.createdBy) : undefined,
        owners: review.owners.map(mapEmployeeDTO),
    };
};

/**
 * This function is solely utilized within the mapEmployeeReviewDTO function to prevent an infinite loop scenario
 * that could occur when mapping between Review and EmployeeReview objects.
 * By avoiding direct circular references between Review and EmployeeReview objects,
 * we prevent infinite recursion in the mapping process.
 * employeeReview -> review -> employeeReview -> review ...
 */
const mapEmployeeReviewDTO = (employeeReview: EmployeeReviewDTO): EmployeeReview => {
    return {
        ...employeeReview,
        discussionStartedAt: convertUTCIsoStringToDate(employeeReview?.discussionStartedAt),
        discussionSubmittedAt: convertUTCIsoStringToDate(employeeReview.discussionSubmittedAt),
        selfReviewedAt: convertUTCIsoStringToDate(employeeReview.selfReviewedAt),
        upwardReviewers: employeeReview.upwardReviewers.map(mapEmployeeReviewerDTO),
        managers: employeeReview.managers.map(mapEmployeeReviewerDTO),
        peerReviewers: employeeReview.peerReviewers.map(mapEmployeeReviewerDTO),
        employee: mapEmployeeDTO(employeeReview.employee),
        reminders: employeeReview.reminders?.map(mapEmployeeReminderDTO),
        review: undefined,
    };
};

const getReview = async (reviewId: number | string): Promise<Review> => {
    const { data } = await client.get<ReviewDTO>(API_BASE_URL + `/reviews/${reviewId}`);
    return mapReviewDTO(data);
};

const updateReview = async (reviewId: number | string, mutation: ReviewUpdateMutation): Promise<Review> => {
    const { data } = await client.put<ReviewDTO, AxiosResponse<ReviewDTO>, ReviewUpdateRequestDTO>(API_BASE_URL + `/reviews/${reviewId}`, mutation);

    return mapReviewDTO(data);
};

const deleteReview = async (reviewId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/reviews/${reviewId}`);
};

const createReviewCycle = async (request: ReviewCycleCreationMutation): Promise<Review> => {
    const { data } = await client.post<ReviewDTO, AxiosResponse<ReviewDTO>, ReviewCycleCreationRequestDTO>(API_BASE_URL + `/reviews/cycle`, request);

    return mapReviewDTO(data);
};

const createOneShotReview = async (request: ReviewOneShotCreationMutation): Promise<Review> => {
    return (await client.post<Review, AxiosResponse<Review>, ReviewOneShotCreationRequestDTO>(API_BASE_URL + `/reviews/one-shot`, request)).data;
};

const createOnboardingReview = async (mutation: ReviewOnboardingCreationMutation): Promise<Review> => {
    const { data } = await client.post<ReviewDTO, AxiosResponse<ReviewDTO>, ReviewOnboardingCreationRequestDTO>(API_BASE_URL + `/reviews/onboarding`, mutation);

    return mapReviewDTO(data);
};

const createOffboardingReview = async (mutation: ReviewOffboardingCreationMutation): Promise<Review> => {
    const { data } = await client.post<ReviewDTO, AxiosResponse<ReviewDTO>, ReviewOffboardingCreationRequestDTO>(
        API_BASE_URL + `/reviews/offboarding`,
        mutation,
    );

    return mapReviewDTO(data);
};

const startReview = async (reviewId: number, mutation: ReviewStartMutation): Promise<Review> => {
    const { data } = await client.post<ReviewDTO, AxiosResponse<ReviewDTO>, ReviewStartRequest>(API_BASE_URL + `/reviews/${reviewId}/start`, mutation);
    return mapReviewDTO(data);
};

const closeReview = async (reviewId: number): Promise<Review> => {
    const { data } = await client.post<ReviewDTO, AxiosResponse<ReviewDTO>>(API_BASE_URL + `/reviews/${reviewId}/close`);

    return mapReviewDTO(data);
};

const reopenReview = async (reviewId: number): Promise<Review> => {
    const { data } = await client.post<ReviewDTO, AxiosResponse<ReviewDTO>>(API_BASE_URL + `/reviews/${reviewId}/reopen`);
    return mapReviewDTO(data);
};

const searchReviews = async (
    request: ReviewSearch = {
        templateName: undefined,
        reviewTypes: undefined,
    },
): Promise<Review[]> => {
    const { data } = await client.post<ReviewDTO[], AxiosResponse<ReviewDTO[]>, ReviewSearchRequest>(API_BASE_URL + `/reviews/search`, request);

    return data.map(review => mapReviewDTO(review));
};

const sendReminder = async (reviewId: number, request: ReviewSendReminderMutation): Promise<void> => {
    await client.post(API_BASE_URL + `/reviews/${reviewId}/send-reminder`, request satisfies ReviewSendReminderRequestDTO);
};

type ReviewPatchRequest = Overwrite<
    ReviewPatchMutation,
    {
        employeeReviews: EmployeeReviewPatchRequest[];
    }
>;

type EmployeeReviewPatchRequest = EmployeeReviewPatchMutation;

const patchReview = async (reviewId: number, mutation: ReviewPatchMutation): Promise<Review> => {
    const { data } = await client.patch<ReviewDTO, AxiosResponse<ReviewDTO>, ReviewPatchRequest>(API_BASE_URL + `/reviews/${reviewId}`, mutation);
    return mapReviewDTO(data);
};

export const reviewApi = {
    patchReview,
    getReview,
    updateReview,
    deleteReview,
    createReviewCycle,
    createOneShotReview,
    createOnboardingReview,
    createOffboardingReview,
    startReview,
    closeReview,
    reopenReview,
    searchReviews,
    sendReminder,
};
