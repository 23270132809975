import { canManageEmployeeCustomSections, canManagePendingEmployeeCustomSections } from '@/domain/permission/Permission.service';
import { EmployeeCustomStackSection } from '@/page/employee-profile/employee-profile-info/EmployeeCustomStackSection/EmployeeCustomStackSection';
import { EmployeeCustomTableSection } from '@/page/employee-profile/employee-profile-info/EmployeeCustomTableSection/EmployeeCustomTableSection';
import { handleError } from '@/utils/api.util';
import { FC } from 'react';

import { EmployeeProfileChange } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { EmployeeSection, EmployeeSectionRowCreationMutation, EmployeeSectionRowUpdateMutation } from '@/domain/employee-section/EmployeeSection.model';
import {
    addEmployeeSectionRow,
    createEmployeeSectionRowPendingRequest,
    deleteEmployeeSectionRow,
    deleteEmployeeSectionRowPendingRequest,
    updateEmployeeSectionRow,
    updateEmployeeSectionRowPendingRequest,
} from '@/domain/employee-section/EmployeeSection.service';
import { SectionType } from '@/domain/section-setting/Section.model';
import { convertFormValuesToFields } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSection/EmployeeCustomSection.util';
import { SectionDefinitionFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { useAppDispatch, useCurrentPolicies } from '@/stores/store';

type Props = {
    employeeSection: EmployeeSection;
    onChange(section: EmployeeSection): void;
    pendingRows: EmployeeProfileChange['pendingRows'];
};

export const EmployeeCustomSection: FC<Props> = ({ employeeSection, onChange, pendingRows }) => {
    const { sectionDefinition } = employeeSection;
    const policies = useCurrentPolicies();
    const canManageEmployeeSection = canManageEmployeeCustomSections(policies, employeeSection.employeeId, employeeSection.sectionDefinition.id);
    const canRequestApproval = canManagePendingEmployeeCustomSections(policies, employeeSection.employeeId, employeeSection.sectionDefinition.id);
    const dispatch = useAppDispatch();

    const refetchProfile = () => {
        // TODO: remove this when we have fetching library in place
        dispatch({
            type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
            refetchEmployee: true,
        });
    };

    const handleCreateRow = async (employeeSectionId: number, row: EmployeeSectionRowCreationMutation) => {
        if (canManageEmployeeSection) {
            const data = await addEmployeeSectionRow(employeeSectionId, row);
            onChange({
                ...employeeSection,
                rows: [...(employeeSection.rows ?? []), data],
            });
        }
        if (canRequestApproval && !canManageEmployeeSection) {
            await createEmployeeSectionRowPendingRequest(employeeSectionId, row);
            refetchProfile();
        }
    };

    const handleUpdateRow = async (employeeSectionId: number, rowId: number, row: EmployeeSectionRowUpdateMutation) => {
        if (canManageEmployeeSection) {
            const data = await updateEmployeeSectionRow(employeeSectionId, rowId, row);
            const rows = employeeSection.rows.map(r => (r.id === rowId ? data : r));
            onChange({
                ...employeeSection,
                rows,
            });
        }

        if (canRequestApproval && !canManageEmployeeSection) {
            await updateEmployeeSectionRowPendingRequest(employeeSectionId, rowId, row);
            refetchProfile();
        }
    };

    const handleSave = (rowId?: number) => async (formValues: SectionDefinitionFormValues) => {
        const order = rowId ? employeeSection.rows.find(row => row.id === rowId)?.order : employeeSection.rows.length;
        const row = {
            fields: convertFormValuesToFields(formValues, sectionDefinition),
            order: order ?? 0,
        };

        try {
            if (!rowId) {
                await handleCreateRow(employeeSection.id, row);
            } else {
                await handleUpdateRow(employeeSection.id, rowId, row);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const deleteRow = async (rowId: number, employeeSection: EmployeeSection) => {
        if (canManageEmployeeSection) {
            await deleteEmployeeSectionRow(employeeSection.id, rowId);
            onChange({
                ...employeeSection,
                rows: employeeSection.rows.filter(row => row.id !== rowId),
            });
        }
    };

    const deletePendingRow = async (rowId: number, employeeSection: EmployeeSection) => {
        if (canRequestApproval) {
            await deleteEmployeeSectionRowPendingRequest(employeeSection.id, rowId);
            refetchProfile();
        }
    };

    const handleDelete = async (rowId: number, isPending: boolean) => {
        try {
            if (isPending) {
                await deletePendingRow(rowId, employeeSection);
            } else {
                await deleteRow(rowId, employeeSection);
            }
        } catch (error) {
            handleError(error);
        }
    };

    switch (employeeSection.sectionDefinition.type) {
        case SectionType.CUSTOM_SINGLE_ROW:
            return (
                <EmployeeCustomStackSection
                    employeeSection={employeeSection}
                    currentSectionRow={employeeSection.rows?.[0]}
                    sectionDefinition={sectionDefinition}
                    onSave={handleSave}
                    pendingRow={pendingRows[0]}
                />
            );
        case SectionType.CUSTOM_MULTI_ROW: {
            const sectionRows = employeeSection.rows;
            sectionRows.sort((a, b) => a.order - b.order);
            return (
                <EmployeeCustomTableSection
                    section={employeeSection}
                    pendingRows={pendingRows}
                    sectionDefinition={sectionDefinition}
                    onSave={handleSave}
                    onDelete={handleDelete}
                />
            );
        }
        default:
            return undefined;
    }
};
