import { Employee } from '@/domain/employee/Employee.model';
import { RoundingType } from '@/utils/math.util';
import * as yup from 'yup';
import { leaveTypeSchema } from '@/domain/leave-type/LeaveType.schema';
import { UnitType } from '../date/Date.model';
import { LabelRequest } from '@/domain/label/Label.model';

export enum LeaveActivityType {
    NOT_WORKING_PAID = 'NOT_WORKING_PAID',
    NOT_WORKING_UNPAID = 'NOT_WORKING_UNPAID',
    MEDICAL = 'MEDICAL',
    WORKING = 'WORKING',
    TIMESHEET_COMPENSATION = 'TIMESHEET_COMPENSATION',
}

export enum RequestIncrement {
    MIN_1 = 'MIN_1',
    MIN_5 = 'MIN_5',
    MIN_15 = 'MIN_15',
    MIN_30 = 'MIN_30',
    MIN_60 = 'MIN_60',
}

export interface LeaveTypePolicy {
    id?: number;
    name: string;
    allowanceInMinutes: number;
    leaveType: LeaveType;
}

export const DisplayCounterArray = ['NEVER', 'ALWAYS', 'SHOW_IF_THERE_IS_LEAVES'] as const;

export type DisplayCounter = (typeof DisplayCounterArray)[number];

export type LeaveType = yup.InferType<typeof leaveTypeSchema>;

type LeaveTypeMutation = {
    name: LabelRequest;
    color: string;
    leaveActivityType: LeaveActivityType;
    employeeRequestMessage?: string;
    unitType: UnitType;
    allowAttachments?: boolean;
    requestCalculationType: RequestCalculationType;
    roundingType: RoundingType;
    maxDurationInMinutes?: number;
    displayCounter: DisplayCounter;
    policies?: LeaveTypePolicyCreationMutation[];
    carryoverType: CarryoverType;
    requestIncrement?: RequestIncrement;
    attachmentRequiredAfterDays?: number;
    prorateBasedOnContract: boolean;
    prorateBasedOnEmployeeStartDate: boolean;
    order?: number;
    includeInPayroll: boolean;
    availableInSelfService: boolean;
    allowOvertime: boolean;
    leaveCreationMinimumBalanceMinutes?: number;
};

export type LeaveTypeCreationMutation = LeaveTypeMutation;

export type LeaveTypeUpdateMutation = LeaveTypeMutation;

type LeaveTypePolicyMutation = {
    name: string;
    allowanceInMinutes: number;
};

export type LeaveTypePolicyCreationMutation = LeaveTypePolicyMutation;

export type LeaveTypePolicyUpdateMutation = LeaveTypePolicyMutation & {
    id?: number;
};

export type EmployeeLeaveTypePolicy = {
    id: number;
    employee?: Employee;
    leaveType: LeaveType;
    leaveTypePolicy: LeaveTypePolicy;
    applyFrom: LocalDate;
    endDate?: LocalDate;
    numFailed?: number;
    numSuccess?: number;
};

export type LeaveTypePolicyAssignRequest = {
    leaveTypeId: number;
    leaveTypePolicyId?: number;
};

export const getLeaveActivityTypeTranslationKey = (leaveActivityType: LeaveActivityType): string => {
    switch (leaveActivityType) {
        case LeaveActivityType.MEDICAL:
            return 'domain.leave_activity_type.medical';
        case LeaveActivityType.WORKING:
            return 'domain.leave_activity_type.working';
        case LeaveActivityType.NOT_WORKING_PAID:
            return 'domain.leave_activity_type.not_working_paid';
        case LeaveActivityType.NOT_WORKING_UNPAID:
            return 'domain.leave_activity_type.not_working_unpaid';
        case LeaveActivityType.TIMESHEET_COMPENSATION:
            return 'domain.leave_activity_type.timesheet_compensation';
        default:
            return '';
    }
};

export enum LeaveTransactionType {
    LEAVE_REQUEST = 'LEAVE_REQUEST',
    CORRECTION = 'CORRECTION',
    CYCLE_CARRYOVER = 'CYCLE_CARRYOVER',
    CYCLE_ACCRUED = 'CYCLE_ACCRUED',
}

export enum AllowanceType {
    UNLIMITED = 'UNLIMITED',
    NOT_UNLIMITED = 'NOT_UNLIMITED',
}

export enum RequestCalculationType {
    BASED_ON_CONTRACT_AND_PUBLIC_HOLIDAYS = 'BASED_ON_CONTRACT_AND_PUBLIC_HOLIDAYS',
    COUNT_FULL_INCLUDING_NON_WORKING_DAYS = 'COUNT_FULL_INCLUDING_NON_WORKING_DAYS',
}

export enum CarryoverType {
    YEARLY_WITH_CARRYOVER = 'YEARLY_WITH_CARRYOVER',
    YEARLY_NO_CARRYOVER = 'YEARLY_NO_CARRYOVER',
}
