import { Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC, SyntheticEvent } from 'react';

import { Folder } from '@/domain/document/Document.model';
import { getLabelTranslation } from '@/utils/language.util';
import { Folder01Icon } from 'hugeicons-react';
import { CompanyDocumentDetails } from './CompanyDocumentDetails';

type Props = {
    folders: Folder[];
    onFolderClick: (folderId: number) => void;
    folderId?: number;
};

export const CompanyDocumentContainer: FC<Props> = ({ folders, folderId, onFolderClick }) => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (_event: SyntheticEvent, newValue: number) => {
        onFolderClick(newValue);
    };

    const folder = folders.find(folder => folder.id === folderId);

    return (
        <Stack direction={{ sx: 'column', sm: 'row' }} gap={2} justifyContent='center' flex={1}>
            <Paper sx={{ minWidth: '230px' }}>
                <Tabs
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    variant='scrollable'
                    value={folderId}
                    scrollButtons={isMobile ? 'auto' : false}
                    onChange={handleChange}
                >
                    {folders?.map((folder, index) => (
                        <Tab
                            key={folder.id}
                            value={folder.id}
                            iconPosition={'start'}
                            icon={<Folder01Icon />}
                            label={getLabelTranslation(folder.name)}
                            sx={{
                                justifyContent: 'flex-start',
                                p: 2,
                                margin: 0,
                                fontSize: 14,
                                minHeight: 64,
                                minWidth: '100%',
                                textAlign: 'left',
                                '&.Mui-selected': {
                                    fontWeight: 400,
                                    color: theme.palette.primary.main,
                                },
                            }}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </Paper>

            {/* List documents in current folder */}
            {folder ? (
                <CompanyDocumentDetails folder={folder} />
            ) : (
                <Stack
                    // Hack to keep tabs on the left side event in case without folder selected
                    flex={1}
                />
            )}
        </Stack>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
};
