import { getAppConfig } from '@/config/config';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Document, PreviewData } from '@/domain/document/Document.model';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';

const config = getAppConfig();

type Props = {
    onClose: () => void;
    previewData: PreviewData | undefined;
    onDownloadClick: (documentData: Document) => void;
};

export const DocumentPreviewDialog: FC<Props> = ({ onClose, previewData, onDownloadClick }) => {
    const { t } = useTranslation();

    const renderPreview = (previewData: PreviewData) => {
        if (previewData.document.mimeType && config.MIME_TYPES.IMAGES.includes(previewData.document.mimeType)) {
            return <img style={{ maxWidth: '100%' }} alt='preview' src={previewData.url} />;
        }

        if (previewData.document.mimeType === config.MIME_TYPES.PDF) {
            return <Box component='iframe' src={previewData.url} width={'100%'} height={'100%'} />;
        }

        return <Box component='iframe' className='iframeWrapper' style={{ width: '100%' }} src={previewData?.url} />;
    };

    return (
        <DialogWrapper
            open={true}
            onClose={onClose}
            fullScreen
            header={
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
                    <Typography variant='body1bold'>{previewData?.document?.name}</Typography>
                    {!!previewData?.document && <Button onClick={() => onDownloadClick(previewData?.document)}>{t('folders.download')}</Button>}
                </Stack>
            }
        >
            <Stack height={'100%'} width={'100%'}>
                {previewData ? renderPreview(previewData) : <Typography variant='body2'>{t('documents.preview.error')}</Typography>}
            </Stack>
        </DialogWrapper>
    );
};
