import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { SearchSkill, Skill } from '@/domain/skill/Skill.model';
import { getSkill, searchSkills } from '@/domain/skill/Skill.service';
import { handleError } from '@/utils/api.util';

export const useGetReviewSkills = (request: SearchSkill = {}): UseQueryResult<Skill[]> => {
    const [skills, setSkills] = useState<Skill[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchSkills = useCallback(async (request: SearchSkill) => {
        try {
            const skills = await searchSkills(request);
            setSkills(skills);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchSkills(request).catch(handleError);
    }, [request]);

    return {
        data: skills,
        setData: setSkills,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchSkills(request),
    };
};

export const useGetReviewSkill = (skillId?: number): UseQueryResult<Skill> => {
    const [skill, setSkill] = useState<Skill>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchSkill = useCallback(async (skillId?: number) => {
        if (!skillId) {
            setIsLoading(false);
            return;
        }
        try {
            const skill = await getSkill(skillId);
            setSkill(skill);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchSkill(skillId).catch(handleError);
    }, [fetchSkill, skillId]);

    return {
        data: skill,
        setData: setSkill,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchSkill(skillId),
    };
};
