import { Components, Theme } from '@mui/material';

export const MuiToggleButtonGroup: Components<Omit<Theme, 'components'>>['MuiToggleButtonGroup'] = {
    styleOverrides: {
        groupedHorizontal: ({ theme }) => ({
            '&:not(:first-of-type)': {
                borderLeft: `1px solid ${theme.palette.primary.main}`,
            },
        }),
        groupedVertical: ({ theme }) => ({
            '&:not(:first-of-type)': {
                borderTop: `1px solid ${theme.palette.primary.main}`,
            },
        }),
    },
};
