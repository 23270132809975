import { useReducer } from 'react';
import { StepForm } from '@/components/stepper-form/StepperForm';

type StepperState = {
    currentStep: number;
};

type StepperAction = { type: 'NEXT_STEP' } | { type: 'PREV_STEP' };

type StepperOptions = {
    steps: StepForm[];
};

export type StepperValues = {
    currentStep: StepForm;
    onNextStep: () => void;
    onPrevStep: () => void;
    nextStepDisabled: boolean;
    prevStepDisabled: boolean;
    showBackButton: boolean;
    showNextButton: boolean;
    showSaveButton: boolean;
};

export const useStepper = ({ steps }: StepperOptions): StepperValues => {
    const totalSteps = steps?.length ?? 0;
    const initialStep = 0;

    const stepperReducer = (state: StepperState, action: StepperAction): StepperState => {
        switch (action.type) {
            case 'NEXT_STEP':
                return { ...state, currentStep: Math.min(state.currentStep + 1, totalSteps - 1) };
            case 'PREV_STEP':
                return { ...state, currentStep: Math.max(state.currentStep - 1, 0) };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(stepperReducer, { currentStep: initialStep });

    const onNextStep = () => {
        dispatch({ type: 'NEXT_STEP' });
    };

    const onPrevStep = () => {
        dispatch({ type: 'PREV_STEP' });
    };

    const nextStepDisabled = state.currentStep === totalSteps - 1;
    const prevStepDisabled = state.currentStep === 0;
    const showBackButton = state.currentStep > 0;
    const showNextButton = state.currentStep < totalSteps - 1;
    const showSaveButton = state.currentStep === totalSteps - 1;

    const currentStep = steps?.[state?.currentStep];

    return {
        currentStep,
        onNextStep,
        onPrevStep,
        nextStepDisabled,
        prevStepDisabled,
        showBackButton,
        showNextButton,
        showSaveButton,
    };
};
