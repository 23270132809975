import { timesheetRecurringAdjustmentApi } from '@/api/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.api';
import {
    TimesheetRecurringAdjustment,
    TimesheetRecurringAdjustmentCreateMutation,
    TimesheetRecurringAdjustmentUpdateMutation,
} from '@/domain/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.model';

export const createTimesheetRecurringAdjustment = (mutation: TimesheetRecurringAdjustmentCreateMutation): Promise<TimesheetRecurringAdjustment> => {
    return timesheetRecurringAdjustmentApi.createTimesheetRecurringAdjustment(mutation);
};

export const updateTimesheetRecurringAdjustment = (mutation: TimesheetRecurringAdjustmentUpdateMutation, id: number): Promise<TimesheetRecurringAdjustment> => {
    return timesheetRecurringAdjustmentApi.updateTimesheetRecurringAdjustment(mutation, id);
};

export const deleteTimesheetRecurringAdjustment = (id: number): Promise<void> => {
    return timesheetRecurringAdjustmentApi.deleteTimesheetRecurringAdjustment(id);
};

export const getTimesheetRecurringAdjustment = (id: number): Promise<TimesheetRecurringAdjustment> => {
    return timesheetRecurringAdjustmentApi.getTimesheetRecurringAdjustment(id);
};
