import { LeaveType, EmployeeLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { createSlice } from '@reduxjs/toolkit';
import { LeavesActions, LeavesActionType } from './leavesActions';

export type LeavesState = {
    leaveTypes: LeaveType[] | undefined;
    userLeaveTypePolicies: EmployeeLeaveTypePolicy[] | undefined;
};

const initialState: LeavesState = {
    leaveTypes: undefined,
    userLeaveTypePolicies: undefined,
};

/**
 * @deprecated
 */
function oldReducer(state = initialState, action: LeavesActions): LeavesState {
    switch (action.type) {
        case LeavesActionType.USER_LEAVE_TYPE_POLICY_LOADED:
            return {
                ...state,
                userLeaveTypePolicies: action.userLeaveTypePolicies,
            };
        case LeavesActionType.LEAVE_TYPES_LOADED:
            return {
                ...state,
                leaveTypes: action.leaveTypes,
            };
        case LeavesActionType.LEAVE_TYPE_CREATED:
            if (!state.leaveTypes) {
                return state;
            }
            return {
                ...state,
                leaveTypes: [action.leaveType, ...state.leaveTypes],
            };
        case LeavesActionType.LEAVE_TYPE_UPDATED: {
            if (!state.leaveTypes) {
                return state;
            }
            const index = state.leaveTypes.findIndex(lt => lt.id === action.leaveType.id);
            if (index < 0) {
                return state;
            }
            const newLeaveTypes = [...state.leaveTypes];
            newLeaveTypes[index] = action.leaveType;
            return {
                ...state,
                leaveTypes: newLeaveTypes,
            };
        }
        default:
            return state;
    }
}

export const leaveSlice = createSlice({
    name: 'policies',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},

    extraReducers: builder => {
        // This is a workaround to be able to use the old reducer in the new slice
        // to avoid having to change all dispatch places
        builder.addDefaultCase((state, action) => {
            return oldReducer(state, action as LeavesActions);
        });
    },
});

export const leavesReducer = leaveSlice.reducer;
