import { ICellRendererParams } from '@ag-grid-community/core';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { showSnackbar } from '@/utils/snackbar.util';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { handleError } from '@/utils/api.util';
import { ReviewTemplate } from '@/domain/review-template/ReviewTemplate.model';
import { cloneReviewTemplate, deleteReviewTemplate } from '@/domain/review-template/ReviewTemplate.service';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { useSearchReviewTemplates } from '@/hooks/review-template/ReviewTemplate.hook';
import { MoreVerticalCircle01Icon } from 'hugeicons-react';

export const ReviewTemplatesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: reviewTemplates, setData: setReviewTemplates, refetch } = useSearchReviewTemplates();

    const onClickTemplateRow = (templateId: number) => {
        if (templateId) {
            navigate(`/settings/reviews/templates/${templateId}`);
        }
    };

    const onDeleteTemplate = async (templateId: number) => {
        try {
            await deleteReviewTemplate(templateId);
            showSnackbar(t('reviews_settings_page.messages.review_template_deleted'), 'success');
            const allTemplates = [...(reviewTemplates || [])];
            const newTemplates = allTemplates.filter(item => item.id !== templateId);
            setReviewTemplates(newTemplates);
        } catch (error) {
            handleError(error);
        }
    };

    const onDuplicateTemplate = async (templateId: number) => {
        try {
            await cloneReviewTemplate(templateId);
        } catch (error) {
            handleError(error);
        } finally {
            refetch();
        }
    };

    const getMenuItems = (params: ICellRendererParams<ReviewTemplate>) => {
        return [
            {
                title: t('general.edit'),
                onClick: () => params.data?.id && onClickTemplateRow(params.data.id),
            },
            {
                title: t('general.delete'),
                onClick: () => params.data?.id && onDeleteTemplate(params.data.id),
            },
            {
                title: t('general.duplicate'),
                onClick: () => params.data?.id && onDuplicateTemplate(params.data.id),
            },
        ];
    };
    const cellActionRenderer = (params: ICellRendererParams<ReviewTemplate>) => (
        <BasicMenu items={getMenuItems(params)} endIcon={<MoreVerticalCircle01Icon />} />
    );

    const config: ConfigType<ReviewTemplate> = {
        header: {
            primaryButtonCaption: t('reviews_settings_page.template'),
            primaryButtonAction: () => navigate('/settings/reviews/templates/new'),
        },
        type: 'table',
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: ({ data, event }) => {
                    if (!event?.defaultPrevented && data) {
                        onClickTemplateRow(data.id);
                    }
                },
            },
            columns: [
                {
                    field: 'name',
                    type: 'label',
                    headerName: t('reviews_settings_page.review_template_form.name'),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    return <CompanySettingsLayout options={config} data={reviewTemplates} isSearchAvailable />;
};
