import { DateRangePickerViewType } from '@/components/date-range-picker/DateRangePicker';
import { getDateRange } from '@/components/date-range-picker/DateRangePicker.util';
import { useLocalStorage } from '@/hooks/Storage.hook';
import { formatToLocalDate, isValidDate, toDate } from '@/utils/datetime.util';

import { useState } from 'react';

export const useDateRangeStorage = ({
    storageKey,
    initialViewType = 'MONTH',
}: {
    storageKey: string;
    initialViewType?: DateRangePickerViewType;
}): {
    dateRange: [LocalDate, LocalDate];
    dateRangeViewType: DateRangePickerViewType;
    onDateRangeChange: (newDateRange: [LocalDate, LocalDate], newViewType: DateRangePickerViewType) => void;
} => {
    const [viewType, setViewType] = useLocalStorage<DateRangePickerViewType>(`${storageKey}_viewType`, initialViewType);

    // fallback to default dates depends on view type
    const defaultDates = getDateRange(viewType);
    const [dateRangeStored, setDateRangeStored] = useLocalStorage<[LocalDate, LocalDate]>(`${storageKey}_dateRange`, defaultDates);

    // Safety check to ensure that the returned date range is always valid
    const getSafetyDateRange = (range: [LocalDate | undefined, LocalDate | undefined]): [LocalDate, LocalDate] => {
        // return default dates if one date is undefined
        if (!range[0] || !range[1]) {
            return defaultDates;
        }

        // return default dates if one date is not valid or the start date is after the end date
        const rangeAsDate: [Date, Date] = [toDate(range[0], 'UTC'), toDate(range[1], 'UTC')];
        if (!isValidDate(rangeAsDate[0]) || !isValidDate(rangeAsDate[1]) || rangeAsDate[0] > rangeAsDate[1]) {
            return defaultDates;
        }

        return [formatToLocalDate(rangeAsDate[0]), formatToLocalDate(rangeAsDate[1])];
    };

    const [dateRange, setDateRange] = useState<[LocalDate, LocalDate]>(getSafetyDateRange(dateRangeStored));

    const handleDateRangeChange = (dates: [LocalDate, LocalDate], viewType: DateRangePickerViewType) => {
        setDateRange(dates);
        setViewType(viewType);
        setDateRangeStored(dates);
    };

    return {
        dateRange: dateRange,
        dateRangeViewType: viewType,
        onDateRangeChange: handleDateRangeChange,
    };
};
