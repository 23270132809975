import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, ButtonProps, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { useOpenDialog } from '@/hooks/dialog/dialog.hook';

type ConfirmDialogProps = {
    // Possible improvement: Make the children type more generic to allow any type of children and improve mouse event type
    children?: ReactElement<HTMLButtonElement>;
    onConfirm(): void;
    confirmButtonProps?: ButtonProps;
    title?: ReactNode;
    content?: ReactNode;
} & Omit<
    DialogWrapperProps,
    'open' | 'onClose'
    // If open/onClose is defined that means that the dialog is controlled
> & {
        open?: boolean;
        onClose?(): void;
    };

export const ConfirmDialog: FC<PropsWithChildren<ConfirmDialogProps>> = ({
    children,
    title,
    content,
    onConfirm,
    open = false,
    confirmButtonProps,
    onClose,
    ...rest
}) => {
    const { t } = useTranslation();

    const { childrenWithOnOpen, handleInternalClose, handleClose, isOpen } = useOpenDialog(children, open, onClose);

    const displayHeading = !title ? t('confirmation_dialog.are_you_sure') : title;

    return (
        <>
            {/* Render the cloned children with the onClick event handler */}
            {childrenWithOnOpen}

            <DialogWrapper open={isOpen} onClose={handleClose} {...rest} header={displayHeading}>
                {/* We are using DialogContentText to ensure that the content is styled correctly */}
                <DialogContent>{typeof content === 'string' ? <DialogContentText>{content}</DialogContentText> : content}</DialogContent>
                <DialogActions>
                    <Button
                        children={t('general.yes')}
                        fullWidth
                        onClick={() => {
                            onConfirm();

                            if (!onClose) {
                                handleInternalClose();
                            }
                        }}
                        {...confirmButtonProps}
                    />
                </DialogActions>
            </DialogWrapper>
        </>
    );
};
