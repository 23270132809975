import { useCallback, useState } from 'react';
import { UseQueryResult } from '@/page/Query.type';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ObjectiveCategory, ObjectiveSearch } from '@/domain/objective/Objective.model';
import { handleError } from '@/utils/api.util';
import { ObjectiveCategorySearch } from '@/domain/objective-category/ObjectiveCategory.model';
import { searchObjectiveCategories } from '@/domain/objective-category/ObjectiveCategory.service';

export const useGetObjectivesCategory = (objectiveCategorySearch: ObjectiveCategorySearch = {}): UseQueryResult<ObjectiveCategory[]> => {
    const [objectives, setObjectives] = useState<ObjectiveCategory[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchObjectivesCategory = useCallback(async (objectiveSearchRequest: ObjectiveSearch | undefined) => {
        if (!objectiveSearchRequest) {
            setIsLoading(false);
            return;
        }

        try {
            const objectivesCategory = await searchObjectiveCategories(objectiveSearchRequest);
            setObjectives(objectivesCategory);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchObjectivesCategory(objectiveCategorySearch).catch(handleError);
    }, [objectiveCategorySearch, fetchObjectivesCategory]);

    return {
        data: objectives || [],
        setData: setObjectives,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchObjectivesCategory(objectiveCategorySearch),
    };
};
