import { FC } from 'react';
import Stack from '@mui/material/Stack/Stack';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router';
import Typography from '@mui/material/Typography';
import Chip, { chipClasses } from '@mui/material/Chip';
import { ArrowRight01Icon } from 'hugeicons-react';

type PendingRowPropsType = {
    label: string;
    count?: number;
    path: string;
};
export const PendingRow: FC<PendingRowPropsType> = ({ label, count = 0, path }) => {
    return (
        <Stack>
            <Link to={path} component={RouterLink} underline={'none'}>
                <Stack direction='row' alignItems='center'>
                    <Typography variant='body1' flex={1}>
                        {label}
                    </Typography>
                    <Chip
                        label={count < 100 ? count : '99+'}
                        variant='filled'
                        size='small'
                        sx={theme => ({
                            ...(count > 0 && { color: 'white' }),
                            ...(count > 0 && { backgroundColor: theme.palette.warning.main }),
                            height: '20px',
                            borderRadius: '0.5',
                            [`& .${chipClasses.label}`]: {
                                padding: 0,
                                fontSize: '12px',
                                lineHeight: '12px',
                                fontWeight: 500,
                            },
                        })}
                        disabled={count === 0}
                    />
                    <ArrowRight01Icon />
                </Stack>
            </Link>
        </Stack>
    );
};
