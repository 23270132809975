import { getCurrentEmployeeAllLeaveTypePolicies } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { Employee, EmployeeAnniversary, EmployeeAnniversaryRequest, EmployeeSearch } from '@/domain/employee/Employee.model';
import {
    getEmployeeAvatar,
    getEmployeeById,
    searchEmployeeBirthdays,
    searchEmployees,
    searchEmployeeWorkAnniversaries,
} from '@/domain/employee/Employee.service';
import { EmployeeLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { UseInfiniteQueryResult, UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployeeById = (employeeId?: number, enabled = true): UseQueryResult<Employee> => {
    const [employee, setEmployee] = useState<Employee>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployee = useCallback(async () => {
        if (!employeeId || isNaN(employeeId) || !enabled) {
            return;
        }

        try {
            const employeeData = await getEmployeeById(employeeId);
            setEmployee(employeeData);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [employeeId, enabled]);

    useEffect(() => {
        fetchEmployee().catch(handleError);
    }, [fetchEmployee]);

    return {
        data: employee,
        refetch: fetchEmployee,
        setData: setEmployee,
        isLoading,
        isError: !!error,
        error,
    };
};

export const useGetEmployees = (search: EmployeeSearch = {}, { enabled } = { enabled: true }): UseQueryResult<Employee[]> => {
    const [employees, setEmployees] = useState<Employee[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployees = useCallback(async (search: EmployeeSearch, enabled: boolean) => {
        if (!enabled) {
            setIsLoading(false);
            return;
        }
        try {
            const employeeData = await searchEmployees(search);
            setEmployees(employeeData);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchEmployees(search, enabled).catch(handleError);
    }, [fetchEmployees, search, enabled]);

    return {
        data: employees,
        setData: setEmployees,
        refetch: () => fetchEmployees(search, enabled),
        isLoading,
        isError: !!error,
        error,
    };
};

export const useGetPaginatedEmployees = (
    search: EmployeeSearch = {},
    options: { limitPerPage?: number; enabled?: boolean } = {},
): UseInfiniteQueryResult<Employee[]> => {
    const [employees, setEmployees] = useState<Employee[]>();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();
    const [page, setPage] = useState(0);
    const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false);
    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [allEmployees, setAllEmployees] = useState<Employee[]>([]);

    const { limitPerPage = 50, enabled = true } = options;

    // fakeFetch is used to simulate pagination and avoid calling the BE multiple times
    const fetchEmployees = useCallback(
        async (search: EmployeeSearch & { offset: number; limit: number }, fakeFetch = false) => {
            // TODO set offset and limit when the BE is ready to make pagination and remove the slice
            const employeeData = !fakeFetch
                ? await searchEmployees({
                      ...search,
                      offset: undefined,
                      limit: undefined,
                  })
                : allEmployees;
            if (!fakeFetch) {
                // save all employees to be able to simulate pagination, should be removed when the BE is ready to make pagination
                setAllEmployees(employeeData);
                // TODO get the total from the BE when the BE is ready to make pagination
                setTotalCount(employeeData.length);
            }
            const employeeSliced = employeeData.slice(search.offset, search.offset + limitPerPage);
            // if the length of the data returned is less than the limit per page, there are no more pages
            setHasNextPage(employeeSliced.length === limitPerPage);

            return employeeSliced;
        },
        [allEmployees, limitPerPage],
    );

    const initialFetch = useCallback(
        async (search: EmployeeSearch, enabled: boolean) => {
            if (!enabled) {
                return;
            }
            try {
                setIsFetching(true);
                if (page !== 0) {
                    setPage(0);
                }
                const employeesData = await fetchEmployees({ ...search, offset: 0, limit: limitPerPage });
                setEmployees(employeesData);
            } catch (error) {
                setError(error);
            } finally {
                setIsFetching(false);
                setIsLoading(false);
            }
        },
        [fetchEmployees, limitPerPage, page],
    );

    const fetchNextPage = useCallback(async () => {
        setIsFetchingNextPage(true);
        setPage(page => page + 1);
        const newOffset = limitPerPage * (page + 1);
        try {
            const employeesData = await fetchEmployees({ ...search, offset: newOffset, limit: limitPerPage }, true);
            setEmployees(employees => [...(employees ?? []), ...employeesData]);
        } catch (error) {
            handleError(error);
        } finally {
            setIsFetchingNextPage(false);
        }
    }, [fetchEmployees, limitPerPage, page, search]);

    useDeepCompareEffect(() => {
        initialFetch(search, enabled).catch(handleError);
    }, [search, enabled]);

    return {
        data: employees,
        setData: setEmployees,
        refetch: () => initialFetch(search, enabled),
        isLoading,
        isFetching,
        isError: !!error,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        totalCount,
    };
};

export const useGetEmployeesBirthdays = (search: EmployeeAnniversaryRequest, enabled = true): UseQueryResult<EmployeeAnniversary[]> => {
    const [employeesBirthdays, setEmployeesBirthdays] = useState<EmployeeAnniversary[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeesBirthdays = useCallback(
        async (search: EmployeeAnniversaryRequest) => {
            if (!enabled) {
                setIsLoading(false);
                return;
            }
            try {
                const employeeBirthdaysData = await searchEmployeeBirthdays(search);
                setEmployeesBirthdays(employeeBirthdaysData);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployeesBirthdays(search).catch(handleError);
    }, [fetchEmployeesBirthdays, search]);

    return {
        data: employeesBirthdays,
        setData: setEmployeesBirthdays,
        refetch: () => fetchEmployeesBirthdays(search),
        isLoading,
        isError: !!error,
        error,
    };
};

export const useGetEmployeesWorkAnniversaries = (search: EmployeeAnniversaryRequest, enabled = true): UseQueryResult<EmployeeAnniversary[]> => {
    const [employeesWorkAnniversaries, setEmployeesWorkAnniversaries] = useState<EmployeeAnniversary[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeesWorkAnniversaries = useCallback(
        async (search: EmployeeAnniversaryRequest) => {
            if (!enabled) {
                setIsLoading(false);
                return;
            }
            try {
                const employeeWorkAnniversaries = await searchEmployeeWorkAnniversaries(search);
                setEmployeesWorkAnniversaries(employeeWorkAnniversaries);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployeesWorkAnniversaries(search).catch(handleError);
    }, [fetchEmployeesWorkAnniversaries, search]);

    return {
        data: employeesWorkAnniversaries,
        setData: setEmployeesWorkAnniversaries,
        refetch: () => fetchEmployeesWorkAnniversaries(search),
        isLoading,
        isError: !!error,
        error,
    };
};

export const useGetEmployeeLeaveTypePolicies = (): UseQueryResult<EmployeeLeaveTypePolicy[]> => {
    const [employeeLeaveTypePolicies, setEmployeeLeaveTypePolicies] = useState<EmployeeLeaveTypePolicy[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployee = useCallback(async () => {
        try {
            const data = await getCurrentEmployeeAllLeaveTypePolicies();
            setEmployeeLeaveTypePolicies(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchEmployee().catch(handleError);
    }, [fetchEmployee]);

    return {
        data: employeeLeaveTypePolicies,
        refetch: fetchEmployee,
        setData: setEmployeeLeaveTypePolicies,
        isLoading,
        isError: !!error,
        error,
    };
};

export const useGetEmployeeAvatarUrl = (employeeId: number, enabled = true): UseQueryResult<string> => {
    const [employeeAvatarUrl, setEmployeeAvatarUrl] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeAvatar = useCallback(async (employeeId: number, enabled: boolean) => {
        if (!enabled) {
            setIsLoading(false);
            return;
        }
        try {
            const data = await getEmployeeAvatar(employeeId);
            setEmployeeAvatarUrl(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchEmployeeAvatar(employeeId, enabled).catch(handleError);
    }, [employeeId, enabled, fetchEmployeeAvatar]);

    return {
        data: employeeAvatarUrl,
        refetch: () => fetchEmployeeAvatar(employeeId, enabled),
        setData: setEmployeeAvatarUrl,
        isLoading,
        isError: !!error,
        error,
    };
};
