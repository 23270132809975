import { ConfirmPopover } from '@/components/confirmation-popover/ConfirmPopover';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldText } from '@/components/form/field-text/FieldText';
import { IntegrationType, ThirdPartyPublicApiIntegrationScope } from '@/domain/third-party/ThirdParty.model';
import { ThirdPartyConnector } from '@/page/setting/third-party/third-party-setting-page/ThirdPartySettingPage';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type ThirdPartyConnectorDialogProps = DialogWrapperProps & {
    connector?: ThirdPartyConnector;
    onSave: (connector: ThirdPartyConnectorFormValues) => void;
    onGenerateApiKey?: () => void;
    publicApiOptionDisabled?: boolean;
};

export const ThirdPartyConnectorDialog: FC<ThirdPartyConnectorDialogProps> = ({
    connector,
    onSave,
    onGenerateApiKey = () => {},
    publicApiOptionDisabled = false,
    ...rest
}) => {
    const { t } = useTranslation();

    // This is to avoid to create two public api connectors
    const connectorTypeFallback = publicApiOptionDisabled ? undefined : 'PUBLIC_API';
    const defaultConnectorType = connector && 'integrationType' in connector ? connector.integrationType : connectorTypeFallback;
    const defaultScopes = connector && 'scopes' in connector ? connector.scopes : [];
    const defaultExpireAt = connector && 'scopes' in connector ? connector.expireAt : getNull();
    const defaultConfiguration = connector && 'configuration' in connector ? connector.configuration : '';

    const { control, handleSubmit, watch } = useForm<ThirdPartyConnectorFormValues>({
        resolver: yupResolver(thirdPartyConnectorSchema),
        defaultValues: {
            type: defaultConnectorType,
            scopes: defaultScopes,
            expireAt: defaultExpireAt,
            configuration: defaultConfiguration,
        },
    });

    const connectorTypesOptions = ['PUBLIC_API', ...Object.values(IntegrationType)] as const;

    const connectorScopesOptions = Object.values(ThirdPartyPublicApiIntegrationScope);

    const isExistingConnector = !!connector?.id;

    const connectorType = watch('type');
    const isPublicApiConnector = connectorType === 'PUBLIC_API';

    const isExistingPublicApiConnector = isPublicApiConnector && isExistingConnector;

    return (
        <DialogWrapper header={t('third_party.connectors.dialog_title')} {...rest}>
            <Stack component={DialogContent} gap={2}>
                <FormControlLabel
                    label={t('third_party.connectors.type')}
                    control={
                        <FieldSelect
                            name='type'
                            control={control}
                            fullWidth
                            options={connectorTypesOptions}
                            autocompleteProps={{
                                getOptionDisabled: type => type === 'PUBLIC_API' && publicApiOptionDisabled,
                            }}
                            getOptionLabel={type => t('third_party_setting_page.connectors_type', { context: type, defaultValue: type })}
                            disableClearable
                            // We don't want to allow type switching for existing connectors
                            disabled={isExistingConnector}
                        />
                    }
                />

                {isPublicApiConnector && (
                    <>
                        <FormControlLabel
                            label={t('third_party.connectors.scopes')}
                            control={
                                <FieldSelect
                                    name='scopes'
                                    control={control}
                                    fullWidth
                                    autocompleteProps={{
                                        size: 'small',
                                        limitTags: 5,
                                    }}
                                    multiple
                                    options={connectorScopesOptions}
                                    getOptionLabel={scope => t('third_party.connectors.scope_type', { context: scope, defaultValue: scope })}
                                />
                            }
                        />

                        <FormControlLabel label={t('third_party.connectors.expire_at')} control={<FieldLocalDate name='expireAt' control={control} />} />
                    </>
                )}

                {!isPublicApiConnector && (
                    <FormControlLabel
                        label={t('third_party.connectors.configuration')}
                        control={
                            <FieldText
                                name='configuration'
                                control={control}
                                placeholder={t('third_party.connectors.configuration_placeholder')}
                                fullWidth
                                textFieldProps={{
                                    multiline: true,
                                    rows: 4,
                                }}
                            />
                        }
                    />
                )}

                {isExistingPublicApiConnector && (
                    <ConfirmPopover onConfirm={onGenerateApiKey} elevation={2} content={t('third_party.connectors.confirm_to_generate_api_key')}>
                        <Button variant='text' sx={{ width: 'fit-content' }}>
                            {t('third_party.connectors.generate_api_key')}
                        </Button>
                    </ConfirmPopover>
                )}
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(onSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const scopeSchema = yup.string().required().oneOf(Object.values(ThirdPartyPublicApiIntegrationScope));

const thirdPartyConnectorSchema = yup.object().shape({
    type: yup
        .string()
        .required()
        .oneOf(['PUBLIC_API', ...Object.values(IntegrationType)] as const),
    scopes: yup.array().of(scopeSchema).required(),
    expireAt: yup
        .string<LocalDate>()
        .test(getLocalDateTestConfig())
        .nullable()
        .default(getNull())
        .when('type', {
            is: 'PUBLIC_API',
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
    configuration: yup
        .string()
        .nullable()
        .default(getNull())
        .when('type', {
            is: 'PUBLIC_API',
            then: schema => schema,
            otherwise: schema => schema.required(),
        }),
});

export type ThirdPartyConnectorFormValues = yup.InferType<typeof thirdPartyConnectorSchema>;
