import { timesheetSettingApi } from '@/api/timesheet-setting/TimesheetSetting.api';
import { TimesheetSetting, TimesheetSettingMutation } from './TimesheetSetting.model';
import { formatToLocalDate, getStartOfMonth, MONTHS, toDate } from '@/utils/datetime.util';
import { getMonth, setMonth, subYears } from 'date-fns';
import { activeMonth } from '@/page/employee-profile/employee-profile-timesheet/timesheets-history/TimesheetsHistory.util';

export function getTimesheetSettingById(timesheetSettingId: number): Promise<TimesheetSetting> {
    return timesheetSettingApi.getTimesheetSettingById(timesheetSettingId);
}

export function searchTimesheetSettings(): Promise<TimesheetSetting[]> {
    return timesheetSettingApi.searchTimesheetSettings();
}

export function postTimesheetSettings(mutation: TimesheetSettingMutation): Promise<TimesheetSetting[]> {
    return timesheetSettingApi.postTimesheetSettings(mutation);
}

export function putTimesheetSettings(mutation: TimesheetSettingMutation, timesheetSettingId: number): Promise<TimesheetSetting[]> {
    return timesheetSettingApi.putTimesheetSettings(mutation, timesheetSettingId);
}

export function deleteTimesheetSettings(timesheetSettingId: number): Promise<void> {
    return timesheetSettingApi.deleteTimesheetSettings(timesheetSettingId);
}

//CYCLE RELATED LOGIC

export const isDifferentLifeCycleStartMonth = (timesheetSettings: TimesheetSetting[]): boolean =>
    timesheetSettings?.some(timesheetSetting => timesheetSetting?.cycleStartMonth !== timesheetSettings[0]?.cycleStartMonth);

export const getCycleStartDate = (endDate: LocalDate, cycleMonth: MONTHS): LocalDate => {
    //start date with the correct month
    const startDate = formatToLocalDate(setMonth(toDate(endDate), activeMonth(cycleMonth)));
    const startDateAtStartOfMonth = getStartOfMonth(startDate);

    const yearDifference = activeMonth(cycleMonth) > getMonth(endDate) ? 1 : 0;
    const startDateWithCorrectYear = subYears(toDate(startDateAtStartOfMonth), yearDifference);
    return formatToLocalDate(startDateWithCorrectYear);
};
