import { SectionField } from '@/components/section/types';
import { employeePendingChangeAPI } from '@/api/employee-pending-change/Employee-pending-change.api';
import { employeeSectionApi } from '@/api/employee-section/EmployeeSection.api';
import { employeeAddressAPI } from '@/api/employee/EmployeeAddress.api';
import { employeePersonalInfoAPI } from '@/api/employee/EmployeePersonalInfo.api';
import {
    EmployeeProfileChange,
    EmployeeProfileChangeSearch,
    EmployeeSectionFieldProfileChange,
} from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { EmployeeSection, EmployeeSectionAction } from '@/domain/employee-section/EmployeeSection.model';
import { SectionFieldDefinition, SectionFieldValueType, SectionType } from '@/domain/section-setting/Section.model';
import { getCountry } from '@/utils/countries.util';
import { isSameDate } from '@/utils/datetime.util';

export const searchEmployeeProfilePendingChanges = (params: EmployeeProfileChangeSearch = {}): Promise<EmployeeProfileChange[]> => {
    return employeePendingChangeAPI.searchEmployeeProfilePendingChanges(params);
};

export const getEmployeePendingChanges = (employeeId: number): Promise<EmployeeProfileChange[]> => {
    return employeePendingChangeAPI.getEmployeeProfilePendingChanges(employeeId);
};

export const approvePendingChanges = (id: number, employeeSection: EmployeeSection): Promise<void> => {
    const sectionType = employeeSection.sectionDefinition.type;
    if (sectionType === SectionType.PERSONAL_INFO) {
        return employeePersonalInfoAPI.approveEmployeePersonalInfoRequest(id);
    } else if (sectionType === SectionType.ADDRESS) {
        return employeeAddressAPI.approveEmployeeAddressRequest(id);
    } else if (sectionType === SectionType.CUSTOM_SINGLE_ROW || sectionType === SectionType.CUSTOM_MULTI_ROW) {
        return employeeSectionApi.approveEmployeeCustomSectionRowPendingRequest(employeeSection.id, id);
    }
    throw new Error('Section type not supported');
};

export const cancelPendingChanges = (id: number, employeeSection: EmployeeSection): Promise<void> => {
    const sectionType = employeeSection.sectionDefinition.type;
    if (sectionType === SectionType.PERSONAL_INFO) {
        return employeePersonalInfoAPI.cancelEmployeePersonalInfoRequest(id);
    } else if (sectionType === SectionType.ADDRESS) {
        return employeeAddressAPI.deleteEmployeeAddressPendingRequest(id);
    } else if (sectionType === SectionType.CUSTOM_SINGLE_ROW || sectionType === SectionType.CUSTOM_MULTI_ROW) {
        return employeeSectionApi.deleteEmployeeSectionRowPendingRequest(employeeSection.id, id);
    }

    throw new Error('Section type not supported');
};

/**
 * Helper to get the action for the section based on the user's permissions and the pending changes status
 */
export const getSectionAction = ({
    canApprove,
    canRequestApproval,
    hasPendingChanges,
}: {
    canApprove: boolean;
    canRequestApproval: boolean;
    hasPendingChanges?: boolean;
}): EmployeeSectionAction => {
    if (hasPendingChanges && canApprove) {
        return 'NAVIGATE_ON_REQUEST_PAGE';
    }

    if (hasPendingChanges && canRequestApproval) {
        return 'EDIT_PENDING';
    }

    if (canApprove) {
        return 'EDIT';
    }

    if (canRequestApproval) {
        return 'CREATE_PENDING';
    }

    return 'NO_ACTION';
};

export const getEmployeeProfilePendingRowsBySectionType = (changes: EmployeeProfileChange[], section: SectionType): EmployeeProfileChange['pendingRows'] => {
    return changes?.find(change => change.employeeSection?.sectionDefinition?.type === section)?.pendingRows ?? [];
};

export const getEmployeeProfilePersonalInfoPendingFields = (changes: EmployeeProfileChange[]): EmployeeSectionFieldProfileChange[] => {
    return getEmployeeProfilePendingRowsBySectionType(changes, SectionType.PERSONAL_INFO)?.[0]?.fields ?? [];
};

export const getEmployeeProfilePendingRowsBySectionDefinition = (
    changes: EmployeeProfileChange[],
    sectionDefinitionId: number,
): EmployeeProfileChange['pendingRows'] => {
    return changes?.find(change => change.employeeSection?.sectionDefinition?.id === sectionDefinitionId)?.pendingRows ?? [];
};

/**
 * SectionField is a frontend domain model used in employee profile page
 * This function converts EmployeeSectionFieldProfileChange to SectionField
 *
 * @deprecated It could be possible to remove this function the day employee profile page is refactored not to use hardcoded fields
 * @param sectionFieldDefinition the definition of the field
 * @param pendingField the pending field to extract value from
 * @returns
 */
export const convertPendingFieldToSectionField = (
    sectionFieldDefinition: SectionFieldDefinition,
    pendingField: EmployeeSectionFieldProfileChange | undefined,
): SectionField => {
    const getValueForValueType = (valueType: SectionFieldValueType) => {
        if (!pendingField) {
            return {};
        }

        if (valueType === 'ENUM') {
            return {
                value: pendingField.stringValue,
            };
        }

        if (valueType === 'DATE') {
            return {
                dateValue: pendingField.dateValue,
            };
        }

        if (sectionFieldDefinition?.valueType === 'COUNTRY' && !!pendingField?.stringValue) {
            return { countryValue: getCountry(pendingField.stringValue) };
        }

        return {};
    };

    const fieldProperties = getValueForValueType(sectionFieldDefinition.valueType);

    return {
        fieldDefinitionId: sectionFieldDefinition.id,
        // customList is based on the sectionFieldDefinition and needed for component EditableSectionFieldComponent
        customList: sectionFieldDefinition?.customList,
        ...pendingField,
        ...fieldProperties,
        fieldType: sectionFieldDefinition.fieldType,
        valueType: sectionFieldDefinition.valueType,
    };
};

export const isEqualSectionFieldValue = (
    previousField: Partial<SectionField>,
    pendingField: Partial<SectionField> & { valueType: SectionFieldValueType },
): boolean => {
    const { dateValue, numberValue, stringValue, countryValue, customListItemReferences, documents, employeeReferences, labelValue } = pendingField;
    const {
        dateValue: previousDateValue,
        numberValue: previousNumberValue,
        stringValue: previousStringValue,
        countryValue: previousCountryValue,
        customListItemReferences: previousCustomListItemReferences,
        documents: previousDocuments,
        employeeReferences: previousEmployeeReferences,
        labelValue: previousLabelValue,
    } = previousField;

    switch (pendingField?.valueType) {
        case 'PHONE_NUMBER':
        case 'STRING':
        case 'IBAN_NUMBER':
        case 'AVS_NUMBER':
        case 'EMAIL':
            return (!stringValue && !previousStringValue) || stringValue?.toLowerCase() === previousStringValue?.toLowerCase();
        case 'DATE':
            return (!dateValue && !previousDateValue) || (!!dateValue && !!previousDateValue && isSameDate(dateValue, previousDateValue));
        case 'COUNTRY':
            return countryValue?.value === previousCountryValue?.value;
        case 'ENUM':
            return (!stringValue && !previousStringValue) || stringValue === previousStringValue;
        case 'NUMBER':
            return numberValue === previousNumberValue;
        case 'CUSTOM_LIST_ITEM':
        case 'CUSTOM_MULTI_LIST_ITEM':
            return (
                customListItemReferences?.length === previousCustomListItemReferences?.length &&
                !!customListItemReferences?.every((item, index) => item.id === previousCustomListItemReferences?.[index].id)
            );
        case 'SECTION_FIELD_DOCUMENT':
            return documents?.length === previousDocuments?.length && !!documents?.every((document, index) => document.id === previousDocuments?.[index].id);
        case 'EMPLOYEE':
            return (
                employeeReferences?.length === previousEmployeeReferences?.length &&
                (previousEmployeeReferences ?? []).every(prevEmployeeReference => employeeReferences?.map(e => e.id).includes(prevEmployeeReference.id))
            );
        case 'LABEL':
            return (!labelValue && !previousLabelValue) || labelValue?.id === previousLabelValue?.id;
        default:
            return true;
    }
};

export const countPendingChanges = async (request: EmployeeProfileChangeSearch = {}): Promise<number> => {
    // TODO replace by a specific endpoint to get only the count : https://rogerhr.atlassian.net/browse/RP-3992
    const employeeProfilePendingChanges = await searchEmployeeProfilePendingChanges(request);
    return employeeProfilePendingChanges?.length ?? 0;
};
