import { payrollAPI } from '@/api/payroll/Payroll.api';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import { EmployeePayrollProfileChange, PayrollProfileChangeSearch } from '@/domain/payroll/Payroll.model';
import { SectionType } from '@/domain/section-setting/Section.model';

export const getPayrollTransactions = (filters: { startDate: LocalDate }): Promise<LeaveTransaction[]> => {
    return payrollAPI.getPayrollTransactions(filters);
};

export const getPayrollEmployeeProfileChanges = (search: PayrollProfileChangeSearch): Promise<EmployeePayrollProfileChange[]> => {
    return payrollAPI.getPayrollEmployeeProfileChanges(search);
};

export const markAsReviewed = (payload: { employeeId: number; sectionType: SectionType; rowId: number }): Promise<void> => {
    return payrollAPI.markAsReviewed(payload);
};

export const unmarkAsReviewed = (payload: { employeeId: number; sectionType: SectionType; rowId: number }): Promise<void> => {
    return payrollAPI.unmarkAsReviewed(payload);
};
