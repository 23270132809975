import { calendarAPI } from '@/api/calendar/Calendar.api';
import { Calendar, CalendarMutation } from '@/domain/calendar/Calendar.model';

export const getCalendars = (): Promise<Calendar[]> => {
    return calendarAPI.getCalendars();
};

export const getCalendarById = (calendarId: number): Promise<Calendar> => {
    return calendarAPI.getCalendarById(calendarId);
};

export const createCalendar = (mutation: CalendarMutation): Promise<Calendar> => {
    return calendarAPI.createCalendar(mutation);
};

export const updateCalendar = (calendarId: number, mutation: CalendarMutation): Promise<Calendar> => {
    return calendarAPI.updateCalendar(calendarId, mutation);
};

export const deleteCalendar = (calendarId: number): Promise<void> => {
    return calendarAPI.deleteCalendar(calendarId);
};
