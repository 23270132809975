import { Components, Theme } from '@mui/material';

export const MuiMenu: Components<Omit<Theme, 'components'>>['MuiMenu'] = {
    defaultProps: {
        elevation: 5,
    },
};

export const MuiMenuItem: Components<Omit<Theme, 'components'>>['MuiMenuItem'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            padding: '4px  8px 4px 8px ',
            margin: '6px 8px 6px 8px',
            minHeight: 20,
            borderRadius: 8,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
                fill: theme.palette.primary.dark,
            },
            '& .Mui-selected, & .Mui-selected:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                fill: theme.palette.common.white,
            },
        }),
    },
};
