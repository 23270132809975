import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { searchWeeklyWorkingTimes } from '@/domain/weekly-working-time/WeeklyWorkingTime.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useSearchWeeklyWorkingTimes = (): UseQueryResult<WeeklyWorkingTime[]> => {
    const [weeklyWorkingTimes, setWeeklyWorkingTimes] = useState<WeeklyWorkingTime[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchWeeklyWorkingTimes = useCallback(async () => {
        setIsLoading(true);
        try {
            const employeeTimesheets = await searchWeeklyWorkingTimes();
            setWeeklyWorkingTimes(employeeTimesheets);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchWeeklyWorkingTimes().catch(handleError);
    }, [fetchWeeklyWorkingTimes]);

    return {
        data: weeklyWorkingTimes,
        setData: setWeeklyWorkingTimes,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchWeeklyWorkingTimes(),
    };
};
