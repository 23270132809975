import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { useGetJobFamilies } from '@/hooks/job-family/JobFamily.hook';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export const FieldSelectJobFamily: FC<{ name: string }> = ({ name }) => {
    const { data: jobFamilies = [], isLoading } = useGetJobFamilies();

    const { control } = useFormContext<Record<typeof name, { id: number } | null>>();

    return (
        <FieldSelect
            name={name}
            control={control}
            options={jobFamilies}
            loading={isLoading}
            getOptionLabel={jobFamily => jobFamilies.find(({ id }) => id === jobFamily.id)?.name ?? ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            fullWidth
        />
    );
};
