import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '../common';
import { PlanningTag, PlanningTagCreationMutation, PlanningTagsSearch, PlanningTagUpdateMutation } from '@/domain/planning-tag/PlanningTag.model';
import { OrderMutation } from '@/domain/common';

type PlanningTagDTO = PlanningTag;
type PlanningTagSearchRequestDTO = PlanningTagsSearch;
type PlanningTagCreationRequestDTO = PlanningTagCreationMutation;
type PlanningTagUpdateRequestDTO = PlanningTagUpdateMutation;
type OrderRequestDTO = OrderMutation;

const BASE_URL = API_BASE_URL + '/planning-tags';

const searchPlanningTags = async (request: PlanningTagsSearch = {}): Promise<PlanningTag[]> => {
    return (await client.post<PlanningTagDTO[], AxiosResponse<PlanningTagDTO[]>, PlanningTagSearchRequestDTO>(BASE_URL + '/search', request)).data;
};

const createPlanningTag = async (request: PlanningTagCreationMutation): Promise<PlanningTag> => {
    return (await client.post<PlanningTagDTO, AxiosResponse<PlanningTagDTO>, PlanningTagCreationRequestDTO>(BASE_URL, request)).data;
};

const updatePlanningTag = async (id: number, request: PlanningTagUpdateMutation): Promise<PlanningTag> => {
    return (await client.put<PlanningTagDTO, AxiosResponse<PlanningTag>, PlanningTagUpdateRequestDTO>(BASE_URL + `/${id}`, request)).data;
};

const deletePlanningTag = async (id: number): Promise<void> => {
    return client.delete(BASE_URL + `/${id}`);
};

const updatePlanningTagOrders = async (request: OrderMutation[]): Promise<PlanningTag> => {
    return (await client.put<PlanningTagDTO, AxiosResponse<PlanningTagDTO>, OrderRequestDTO[]>(BASE_URL + `/order`, request)).data;
};

export const planningTagApi = {
    searchPlanningTags,
    createPlanningTag,
    updatePlanningTag,
    deletePlanningTag,
    updatePlanningTagOrders,
};
