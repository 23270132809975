import { Button, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDefaultRealmNotification } from '@/hooks/default-realm-notification/DefaultRealmNotification.hook';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getNotificationFormSchema, NotificationFormSchema } from '@/page/setting/notification/notification.schema';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer } from '@/page/layout/Footer';
import { DefaultRealmNotification, DefaultRealmNotificationUpdate } from '@/domain/default-realm-notification/DefaultRealmNotification.model';
import { updateDefaultRealmNotification } from '@/domain/default-realm-notification/DefaultRealmNotification.service';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { FieldCheckbox } from '@/components/form/field-checkbox/FieldCheckbox';

export const DefaultRealmNotificationPage: FC = () => {
    const {
        data: defaultRealmNotification,
        isLoading: isLoadingDefaultRealmNotification,
        isError: isErrorDefaultRealmNotification,
        error: errorDefaultRealmNotification,
        refetch: refetchDefaultRealmNotification,
    } = useGetDefaultRealmNotification();

    const onSave = async () => {
        await refetchDefaultRealmNotification();
    };

    return (
        <StateHandler
            isLoading={isLoadingDefaultRealmNotification}
            isError={isErrorDefaultRealmNotification}
            error={errorDefaultRealmNotification}
            isEmpty={!defaultRealmNotification}
        >
            {defaultRealmNotification && <DefaultRealmNotificationForm defaultRealmNotification={defaultRealmNotification} onSave={onSave} />}
        </StateHandler>
    );
};

type DefaultRealmNotificationFormProps = {
    defaultRealmNotification: DefaultRealmNotification;
    onSave: () => void;
};

const DefaultRealmNotificationForm: FC<DefaultRealmNotificationFormProps> = ({ defaultRealmNotification, onSave }) => {
    const { t } = useTranslation();

    const formMethods = useForm<NotificationFormSchema>({
        resolver: yupResolver(getNotificationFormSchema()),
        defaultValues: {
            leaveNotificationsEnabled: defaultRealmNotification?.leaveNotificationsEnabled,
            leaveAttachmentNotificationsEnabled: defaultRealmNotification?.leaveAttachmentNotificationsEnabled,
            planningUpdatesNotificationsEnabled: defaultRealmNotification?.planningUpdatesNotificationsEnabled,
            documentNotificationsEnabled: defaultRealmNotification?.documentNotificationsEnabled,
            profileChangeNotificationsEnabled: defaultRealmNotification?.profileChangeNotificationsEnabled,
            timesheetUpdatesNotificationsEnabled: defaultRealmNotification?.timesheetUpdatesNotificationsEnabled,
            announcementNotificationsEnabled: defaultRealmNotification?.announcementNotificationsEnabled,
        },
    });
    const { handleSubmit } = formMethods;

    const handleOnSave = async (data: NotificationFormSchema) => {
        const request: DefaultRealmNotificationUpdate = {
            leaveNotificationsEnabled: data.leaveNotificationsEnabled ?? false,
            leaveAttachmentNotificationsEnabled: data.leaveAttachmentNotificationsEnabled ?? false,
            planningUpdatesNotificationsEnabled: data.planningUpdatesNotificationsEnabled ?? false,
            documentNotificationsEnabled: data.documentNotificationsEnabled ?? false,
            profileChangeNotificationsEnabled: data.profileChangeNotificationsEnabled ?? false,
            timesheetUpdatesNotificationsEnabled: data.timesheetUpdatesNotificationsEnabled ?? false,
            announcementNotificationsEnabled: data.announcementNotificationsEnabled ?? false,
        };

        try {
            await updateDefaultRealmNotification(defaultRealmNotification.id, request);
            showSnackbar(t('default_realm_notification_page.on_save'), 'success');
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <>
            <ContentContainer component={Paper} flex={1} gap={2} p={3} mb={2}>
                <Typography variant='h2'>{t('default_realm_notification_page.title')}</Typography>
                <FormProvider {...formMethods}>
                    <NotificationsBlock />
                </FormProvider>
            </ContentContainer>
            <Footer>
                <Button onClick={handleSubmit(handleOnSave, console.error)} variant='contained'>
                    {t('general.update')}
                </Button>
            </Footer>
        </>
    );
};

export type NotificationsBlockProps = {
    differenceOnNotTouched?: boolean;
};

export const NotificationsBlock: FC<NotificationsBlockProps> = ({ differenceOnNotTouched = false }) => {
    const { t } = useTranslation();

    const { control } = useFormContext<NotificationFormSchema>();

    const isLeaveFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.LEAVES);
    const isPlanningFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.PLANNING);
    const isDocumentFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS);
    const isTimesheetFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.TIMESHEET);
    const isAdvancedEmployeeProfileFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE);

    return (
        <Stack direction='column'>
            {isLeaveFeatureEnabled && (
                <>
                    <FieldCheckbox
                        control={control}
                        name='leaveNotificationsEnabled'
                        label={t('user_settings.leave_updates')}
                        labelPlacement='end'
                        differenceOnNotTouched={differenceOnNotTouched}
                    />
                    <FieldCheckbox
                        control={control}
                        name='leaveAttachmentNotificationsEnabled'
                        label={t('user_settings.leave_attachments_updates')}
                        labelPlacement='end'
                        differenceOnNotTouched={differenceOnNotTouched}
                    />
                </>
            )}
            {isPlanningFeatureEnabled && (
                <FieldCheckbox
                    control={control}
                    name='planningUpdatesNotificationsEnabled'
                    label={t('user_settings.planning_updates')}
                    labelPlacement='end'
                    differenceOnNotTouched={differenceOnNotTouched}
                />
            )}

            {isDocumentFeatureEnabled && (
                <FieldCheckbox
                    control={control}
                    name='documentNotificationsEnabled'
                    label={t('user_settings.documents_updates')}
                    labelPlacement='end'
                    differenceOnNotTouched={differenceOnNotTouched}
                />
            )}
            {isAdvancedEmployeeProfileFeatureEnabled && (
                <FieldCheckbox
                    control={control}
                    name='profileChangeNotificationsEnabled'
                    label={t('user_settings.profile_pending_updates')}
                    labelPlacement='end'
                    differenceOnNotTouched={differenceOnNotTouched}
                />
            )}
            {isTimesheetFeatureEnabled && (
                <FieldCheckbox
                    control={control}
                    name='timesheetUpdatesNotificationsEnabled'
                    label={t('user_settings.timesheets_updates')}
                    labelPlacement='end'
                    differenceOnNotTouched={differenceOnNotTouched}
                />
            )}
            <FieldCheckbox
                control={control}
                name='announcementNotificationsEnabled'
                label={t('user_settings.announcements')}
                labelPlacement='end'
                differenceOnNotTouched={differenceOnNotTouched}
            />
        </Stack>
    );
};
