import { UseQueryResult } from '@/page/Query.type';
import { EmployeeLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { useCallback, useEffect, useState } from 'react';
import { handleError } from '@/utils/api.util';
import { getEmployeeLeaveTypePolicies } from '@/domain/employee-leave-type/EmployeeLeaveType.service';

export const useGetEmployeeLeaveTypes = (employeeId: number): UseQueryResult<EmployeeLeaveTypePolicy[]> => {
    const [employeeLeaveTypePolicy, setEmployeeLeaveTypePolicy] = useState<EmployeeLeaveTypePolicy[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeLeaveTypePolicies = useCallback(async () => {
        try {
            const empLeaveTypes = await getEmployeeLeaveTypePolicies(employeeId);
            setEmployeeLeaveTypePolicy(empLeaveTypes);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [employeeId]);

    useEffect(() => {
        fetchEmployeeLeaveTypePolicies().catch(handleError);
    }, [fetchEmployeeLeaveTypePolicies]);

    return {
        data: employeeLeaveTypePolicy,
        setData: setEmployeeLeaveTypePolicy,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchEmployeeLeaveTypePolicies,
    };
};
