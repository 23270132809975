import { FormControlLabel, InputAdornment, TextField } from '@mui/material';
import { Search01Icon } from 'hugeicons-react';
import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

type SearchFieldProps = {
    label: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const SearchField: FC<SearchFieldProps> = ({ label, onChange }) => {
    const { t } = useTranslation();
    return (
        <FormControlLabel
            label={label}
            control={
                <TextField
                    autoFocus
                    fullWidth
                    placeholder={t('general.search')}
                    onChange={onChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Search01Icon />
                            </InputAdornment>
                        ),
                    }}
                />
            }
        />
    );
};
