import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import {
    WorkingPatternTemplate,
    WorkingPatternTemplateCreationRequest,
    WorkingPatternTemplateSearchRequest,
    WorkingPatternTemplateUpdateRequest,
} from '@/domain/working-pattern-template/WorkingPatternTemplate.model';

const searchWorkingPatternTemplates = async (searchRequest: WorkingPatternTemplateSearchRequest = {}): Promise<WorkingPatternTemplate[]> => {
    const url = API_BASE_URL + '/working-pattern-templates/search';
    return (
        await client.post<WorkingPatternTemplateDTO[], AxiosResponse<WorkingPatternTemplateDTO[]>, WorkingPatternTemplateSearchRequestDTO>(url, searchRequest)
    ).data;
};

const getWorkingPatternTemplateById = async (workingPatternId: number): Promise<WorkingPatternTemplateDTO> => {
    const url = API_BASE_URL + `/working-pattern-templates/${workingPatternId}`;
    return (await client.get<WorkingPatternTemplateDTO>(url)).data;
};

const createWorkingPatternTemplate = async (request: WorkingPatternTemplateCreationRequest): Promise<WorkingPatternTemplate> => {
    const url = API_BASE_URL + '/working-pattern-templates';
    return (await client.post<WorkingPatternTemplateDTO, AxiosResponse<WorkingPatternTemplateDTO>, WorkingPatternTemplateCreationRequestDTO>(url, request))
        .data;
};

const updateWorkingPatternTemplate = async (workingPatternId: number, request: WorkingPatternTemplateUpdateRequest): Promise<WorkingPatternTemplate> => {
    const url = API_BASE_URL + `/working-pattern-templates/${workingPatternId}`;
    return (await client.put<WorkingPatternTemplateDTO, AxiosResponse<WorkingPatternTemplateDTO>, WorkingPatternTemplateUpdateRequestDTO>(url, request)).data;
};

const deleteWorkingPatternTemplate = async (workingPatternId: number): Promise<void> => {
    const url = API_BASE_URL + `/working-pattern-templates/${workingPatternId}`;
    await client.delete(url);
};

type WorkingPatternTemplateDTO = WorkingPatternTemplate;

type WorkingPatternTemplateUpdateRequestDTO = WorkingPatternTemplateUpdateRequest;

type WorkingPatternTemplateCreationRequestDTO = WorkingPatternTemplateCreationRequest;

type WorkingPatternTemplateSearchRequestDTO = WorkingPatternTemplateSearchRequest;

export const workingPatternTemplateAPI = {
    searchWorkingPatternTemplates,
    getWorkingPatternTemplateById,
    createWorkingPatternTemplate,
    updateWorkingPatternTemplate,
    deleteWorkingPatternTemplate,
};
