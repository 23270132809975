import { Chip, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { getLabelTranslation, useUserLanguage } from '@/utils/language.util';
import { EmployeeAvatarWithDetails } from '@/components/employee-avatar/EmployeeAvatarWithDetails';
import { Employee } from '@/domain/employee/Employee.model';
import { getCurrentPrincipalEmployment } from '@/domain/employment/Employment.service';

type EmployeeReviewAvatarProps = {
    employee: Employee;
};

export const EmployeeReviewAvatar: FC<EmployeeReviewAvatarProps> = ({ employee }) => {
    const userLanguage = useUserLanguage();

    if (!employee) {
        return <></>;
    }

    const employment = getCurrentPrincipalEmployment(employee);

    return (
        <Stack p={2} pt={0} gap={1}>
            <EmployeeAvatarWithDetails employee={employee}>
                <Stack direction={'row'} gap={1}>
                    {employment?.job?.name && <Typography variant='body2'>{getLabelTranslation(employment?.job?.name, userLanguage)}</Typography>}
                    {employment?.job && employment?.location?.name && <Typography>·</Typography>}
                    {employment?.location?.name && <Typography variant='body2'>{employment?.location?.name}</Typography>}
                </Stack>
                {employee?.planningPositions?.[0]?.name && (
                    <Chip
                        label={employee?.planningPositions?.[0]?.name}
                        size='small'
                        variant='outlined'
                        style={{
                            borderColor: employee?.planningPositions?.[0]?.color,
                            color: employee?.planningPositions?.[0]?.color,
                        }}
                    />
                )}
            </EmployeeAvatarWithDetails>
        </Stack>
    );
};
