import {
    EmployeeReviewFeedbackSkillFormType,
    EmployeeReviewFeedbackSkillsFormType,
} from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import {
    EmployeeReviewFeedbackSummarySkillFormType,
    EmployeeReviewSummaryFeedbackSkillsFormType,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { SkillState } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSkill';

export const getSkillState = (
    employeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSkillFormType | EmployeeReviewFeedbackSummarySkillFormType,
    skillsItem: EmployeeReviewFeedbackSkillsFormType | EmployeeReviewSummaryFeedbackSkillsFormType,
): SkillState => {
    const { maxSkills, allSkillsRequired } = skillsItem;

    if (employeeReviewFeedbackSkillForm.score && employeeReviewFeedbackSkillForm.score > 0) {
        return 'reviewed';
    }

    const skillsAlreadyReviewed = skillsItem.skills.filter(skill => skill.score && skill.score > 0).length;
    const disabled = !!maxSkills && skillsAlreadyReviewed >= maxSkills && !allSkillsRequired;
    if (disabled) {
        return 'disabled';
    }
    return 'not_reviewed';
};
