import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { ImageBox } from '@/components/image-box/ImageBox';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';
import { Announcement } from '@/domain/announcement/Announcement.model';
import { useGetAnnouncementImageUrl } from '@/hooks/annoucement/Announcement.hook';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Box, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { Globe02Icon, UserMultiple02Icon } from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type AnnouncementProps = {
    announcement: Announcement;
    displayMenu?: boolean;
    onEdit?: () => void;
    onDelete?: () => void;
};
export const AnnouncementItem: FC<AnnouncementProps> = ({ announcement, displayMenu, onEdit, onDelete }) => {
    const { id, createdBy, title, content, imageUrl, startDate, departments, locations } = announcement;
    const { t } = useTranslation();
    if (displayMenu && (!onEdit || !onDelete)) {
        console.error('onEdit and onDelete are required when displayMenu is true');
    }

    const { data: announcementImageUrl } = useGetAnnouncementImageUrl(id, !!imageUrl);

    const items: BasicMenuItem[] = [
        {
            title: t('general.edit'),
            onClick: onEdit,
        },
        {
            title: t('general.delete'),
            onClick: onDelete,
        },
    ];

    const publishedToAll = !departments.length && !locations.length;

    const renderTooltipTitle = (): JSX.Element => {
        if (publishedToAll) {
            return <Box>{t('home_page.announcements.published_to_all')}</Box>;
        }
        return (
            <>
                {!!departments.length && (
                    <Box>
                        {t('home_page.announcements.published_to_departments', { departments: departments.map(d => getLabelTranslation(d.name)).join(', ') })}
                    </Box>
                )}
                {!!locations.length && <Box>{t('home_page.announcements.published_to_locations', { locations: locations.map(l => l.name).join(', ') })}</Box>}
            </>
        );
    };

    return (
        <Stack gap={2} component={Paper} p={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} gap={2}>
                    <EmployeeAvatar employeeAvatar={createdBy} />
                    <Stack>
                        <Typography variant={'body1bold'}>{createdBy.displayName}</Typography>
                        <Stack direction={'row'} alignItems={'flex-start'} gap={1}>
                            <Typography variant={'body3'}>{formatInDefaultDate(startDate)}</Typography>
                            <Tooltip title={renderTooltipTitle()} aria-label={'tooltip'}>
                                <Box> {publishedToAll ? <Globe02Icon size={14} /> : <UserMultiple02Icon size={16} />}</Box>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Stack>
                {displayMenu && <BasicMenu items={items} />}
            </Stack>
            <Stack gap={1}>
                <Typography variant='h2'>{title}</Typography>
                <RichTextTypography>{content}</RichTextTypography>
            </Stack>
            {!!announcementImageUrl && (
                <Stack>
                    <ImageBox src={announcementImageUrl} alt={title} />
                </Stack>
            )}
        </Stack>
    );
};
