import { getLocale, useUserLanguage } from '@/utils/language.util';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FC, PropsWithChildren } from 'react';

export const DatePickerLocalizationProvider: FC<PropsWithChildren> = ({ children }) => {
    const userLanguage = useUserLanguage();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(userLanguage)}>
            {children}
        </LocalizationProvider>
    );
};
