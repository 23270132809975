import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { TimesheetSetting, TimesheetSettingMutation } from '@/domain/timesheet-setting/TimesheetSetting.model';

export type TimesheetSettingSearchRequestDTO = { name?: string };
export type TimesheetSettingDTO = TimesheetSetting;
export type TimesheetSettingRequestDTO = TimesheetSettingMutation;

const getTimesheetSettingById = async (timesheetSettingId: number): Promise<TimesheetSetting> => {
    const url = API_BASE_URL + `/time-management/timesheet-settings/${timesheetSettingId}`;
    return (await client.get<TimesheetSettingDTO>(url)).data;
};

const searchTimesheetSettings = async (): Promise<TimesheetSetting[]> => {
    const url = API_BASE_URL + `/time-management/timesheet-settings/search`;
    return (await client.post<TimesheetSettingDTO, AxiosResponse<TimesheetSettingDTO[]>, TimesheetSettingSearchRequestDTO>(url, {})).data;
};

const postTimesheetSettings = async (mutation: TimesheetSettingMutation): Promise<TimesheetSetting[]> => {
    const url = API_BASE_URL + `/time-management/timesheet-settings`;
    return (await client.post<TimesheetSettingDTO[], AxiosResponse<TimesheetSettingDTO[]>, TimesheetSettingRequestDTO>(url, mutation)).data;
};

const putTimesheetSettings = async (mutation: TimesheetSettingMutation, timesheetSettingId: number): Promise<TimesheetSetting[]> => {
    const url = API_BASE_URL + `/time-management/timesheet-settings/${timesheetSettingId}`;
    return (await client.put<TimesheetSettingDTO[], AxiosResponse<TimesheetSettingDTO[]>, TimesheetSettingRequestDTO>(url, mutation)).data;
};

const deleteTimesheetSettings = async (timesheetSettingId: number): Promise<void> => {
    const url = API_BASE_URL + `/time-management/timesheet-settings/${timesheetSettingId}`;
    await client.delete(url);
};

export const timesheetSettingApi = {
    getTimesheetSettingById,
    searchTimesheetSettings,
    postTimesheetSettings,
    putTimesheetSettings,
    deleteTimesheetSettings,
};
