import { Components, Theme } from '@mui/material';

export const MuiToolbar: Components<Omit<Theme, 'components'>>['MuiToolbar'] = {
    styleOverrides: {
        dense: {
            height: 56,
            minHeight: 56,
        },
    },
};
