import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { NUMBER_DAYS_BEFORE_EOT_REVIEW, ReviewType } from '@/domain/review/Review.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useSearchReviewTemplates } from '@/hooks/review-template/ReviewTemplate.hook';
import { getLocalDateTestConfig, subDaysAndFormat } from '@/utils/datetime.util';

import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { getLabelTranslation, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

type PlannedReviewDialogProps = Omit<DialogWrapperProps, 'onSave'> & {
    title: string;
    onSuccess: SubmitHandler<PlannedReviewFormValues>;
    reviewType: ReviewType;
    currentEmployeeReview: EmployeeReview | undefined;
};

const getSchema = (userLanguage: UserLanguage) => {
    return yup.object().shape({
        name: getLabelFormSchema(userLanguage),
        reviewTemplate: yup
            .object()
            .shape({
                label: yup.string().required(),
                value: yup.number().required(),
            })
            .required(),
        managers: yup
            .array()
            .of(
                yup.object().shape({
                    label: yup.string().required(),
                    value: yup.number().required(),
                }),
            )
            .required()
            .min(1),
        employee: yup
            .object()
            .shape({
                label: yup.string().required(),
                value: yup.number().required(),
            })
            .required(),
        reviewNotificationDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        reviewEndDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
    });
};

export type PlannedReviewFormValues = yup.InferType<ReturnType<typeof getSchema>>;

export const PlannedReviewDialog: FC<PlannedReviewDialogProps> = ({ title, onSuccess, reviewType, currentEmployeeReview, ...rest }) => {
    const isEdit = !!currentEmployeeReview;
    const [translationLanguage, setTranslationLanguage] = useState<UserLanguage>(getRealmLanguage());
    const {
        data: reviewTemplates = [],
        isError: isReviewTemplatesError,
        isLoading: isReviewTemplatesLoading,
        error: reviewTemplatesError,
    } = useSearchReviewTemplates({
        reviewType: reviewType,
    });

    const { data: employees = [], error: employeesError, isError: isEmployeesError, isLoading: isEmployeesLoading } = useGetEmployees();
    const isError = isReviewTemplatesError || isEmployeesError;
    const isLoading = isReviewTemplatesLoading || isEmployeesLoading;
    const error = reviewTemplatesError || employeesError;

    const getDefaultValues = (currentReview: Partial<EmployeeReview> | undefined): Partial<PlannedReviewFormValues> => {
        if (!currentReview) {
            return {};
        }

        return {
            name: currentReview.review?.name,
            reviewTemplate: currentReview.review?.reviewTemplate?.name
                ? {
                      label: getLabelTranslation(currentReview.review.reviewTemplate.name),
                      value: currentReview.review.reviewTemplate.id,
                  }
                : undefined,
            managers: currentReview.managers?.length
                ? currentReview.managers.map(manager => ({
                      label: manager.employee.displayName,
                      value: manager.employee.id,
                  }))
                : [],
            employee: currentReview.employee
                ? {
                      label: currentReview.employee.displayName,
                      value: currentReview.employee.id,
                  }
                : undefined,
            reviewNotificationDate: currentReview.review?.startDate,
            reviewEndDate: currentReview.review?.endDate,
        };
    };

    const { handleSubmit, control, watch, setValue } = useForm<PlannedReviewFormValues>({
        resolver: yupResolver(getSchema(translationLanguage)),
        defaultValues: getDefaultValues(currentEmployeeReview),
    });

    const watchEmployee = watch('employee');
    const watchManagers = watch('managers') ?? [];

    // Use the selected employee to set the default value (notification date, etc.)
    useEffect(() => {
        if (watchEmployee) {
            const selectedEmployee = employees.find(employee => employee.id === watchEmployee.value);
            const probationEndDate = selectedEmployee?.currentEmployments?.find(employment => employment.principal)?.probationEndDate;
            if (probationEndDate) {
                const reviewNotificationDate = subDaysAndFormat(probationEndDate, NUMBER_DAYS_BEFORE_EOT_REVIEW);
                setValue('reviewNotificationDate', reviewNotificationDate);
                setValue('reviewEndDate', probationEndDate);
            }
        }
    }, [employees, setValue, watchEmployee]);

    const reviewTemplateOptions = reviewTemplates.map(template => ({
        label: getLabelTranslation(template.name) ?? '',
        value: template.id,
    }));
    const employeeOptions = employees
        .map(employee => ({
            label: employee.displayName,
            value: employee.id,
        }))
        .filter(employee => employee.value !== watchEmployee?.value)
        .filter(employee => !watchManagers.some(manager => manager.value === employee.value));

    const header = (
        <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1}>
            <Typography variant={'body1bold'}>{title}</Typography>
            <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={setTranslationLanguage} />
        </Stack>
    );

    return (
        <DialogWrapper {...rest} header={header}>
            <DialogContent>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    <Stack gap={1}>
                        <FormControlLabel
                            label={t('general.employee')}
                            control={
                                <FieldSelect
                                    name='employee'
                                    control={control}
                                    disabled={isEdit}
                                    fullWidth
                                    options={employeeOptions}
                                    getOptionLabel={option => option.label}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                />
                            }
                        />
                        <FieldLabel
                            control={control}
                            name='name'
                            defaultValue={createDefaultLabel()}
                            fullWidth
                            language={translationLanguage}
                            label={t('general.name')}
                        />
                        <FormControlLabel
                            label={t('general.template')}
                            control={
                                <FieldSelect
                                    name='reviewTemplate'
                                    control={control}
                                    fullWidth
                                    disabled={isEdit}
                                    options={reviewTemplateOptions}
                                    getOptionLabel={option => option.label}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                />
                            }
                        />

                        <FormControlLabel
                            label={t('reviews.new_review.trigger_on')}
                            control={<FieldLocalDate name='reviewNotificationDate' control={control} />}
                        />

                        <FormControlLabel label={t('reviews.new_review.end_date')} control={<FieldLocalDate name='reviewEndDate' control={control} />} />

                        <FormControlLabel
                            sx={{ width: '100%' }}
                            label={t('reviews.new_review.managers')}
                            control={
                                <FieldSelect
                                    name={'managers'}
                                    control={control}
                                    multiple
                                    fullWidth
                                    options={employeeOptions}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    getOptionLabel={option => option.label}
                                    getOptionKey={option => option.value}
                                />
                            }
                        />
                    </Stack>
                </StateHandler>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(onSuccess, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
