type FieldTypeToPropertyMap = {
    EMPLOYEE: 'employeeReferences';
    CUSTOM_LIST_ITEM: 'customListItemReferences';
    CUSTOM_MULTI_LIST_ITEM: 'customListItemReferences';
    ENUM: 'stringValue';
    DATE: 'dateValue';
    NUMBER: 'numberValue';
    STRING: 'stringValue';
    PHONE_NUMBER: 'stringValue';
    COUNTRY: 'countryValue';
    SECTION_FIELD_DOCUMENT: 'documents';
    IBAN_NUMBER: 'stringValue';
    AVS_NUMBER: 'stringValue';
    REFERENCE: 'stringValue';
    DOCUMENT: 'documents';
    LABEL: 'stringValue';
    EMAIL: 'stringValue';
};

export const getFieldValueProperty = <T extends keyof FieldTypeToPropertyMap>(fieldType: T): FieldTypeToPropertyMap[T] => {
    switch (fieldType) {
        case 'EMPLOYEE':
            return 'employeeReferences' as FieldTypeToPropertyMap[T];
        case 'CUSTOM_LIST_ITEM':
        case 'CUSTOM_MULTI_LIST_ITEM':
            return 'customListItemReferences' as FieldTypeToPropertyMap[T];
        case 'DATE':
            return 'dateValue' as FieldTypeToPropertyMap[T];
        case 'NUMBER':
            return 'numberValue' as FieldTypeToPropertyMap[T];
        case 'ENUM':
        case 'STRING':
        case 'PHONE_NUMBER':
        case 'IBAN_NUMBER':
        case 'AVS_NUMBER':
        case 'EMAIL':
            return 'stringValue' as FieldTypeToPropertyMap[T];
        case 'COUNTRY':
            return 'countryValue' as FieldTypeToPropertyMap[T];
        case 'SECTION_FIELD_DOCUMENT':
            return 'documents' as FieldTypeToPropertyMap[T];
        default:
            return 'stringValue' as FieldTypeToPropertyMap[T];
    }
};
