import { AvailableReportField, AvailableReportGroupedField, ReportFieldDefinition } from '@/domain/report/Report.model';
import { Dispatch, SetStateAction, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { isSameFieldDefinition } from '@/domain/section-setting/Section.service';
import { ReportColumn, ReportGroupedColumn } from '@/page/report/report-columns-selector/ReportColumnsSelector';

export type UseReportColumnsProps = {
    availableGroups: AvailableReportGroupedField[];
    defaultColumns?: ReportFieldDefinition[];
};
export const useReportGroupedColumns = (
    { availableGroups, defaultColumns }: UseReportColumnsProps = {
        availableGroups: [],
        defaultColumns: [],
    },
): [ReportGroupedColumn[], Dispatch<SetStateAction<ReportGroupedColumn[]>>] => {
    const [groups, setGroups] = useState<ReportGroupedColumn[]>([]);

    useDeepCompareEffect(() => {
        if (!availableGroups?.length) {
            return;
        }

        const convertAvailableFieldToColumn = (field: AvailableReportField) => {
            const fieldInReport = defaultColumns?.find(f =>
                isSameFieldDefinition(
                    {
                        ...f.sectionFieldDefinition,
                        fieldType: f.fieldType,
                    },
                    { ...field.sectionFieldDefinition, fieldType: field.fieldType },
                ),
            );
            return {
                fieldType: field.fieldType,
                valueType: field.valueType,
                sectionFieldDefinition: field.sectionFieldDefinition, // TODO check if the hack is needed
                order: fieldInReport?.order ?? 0,
                visible: !(fieldInReport?.hidden ?? true),
            } satisfies ReportColumn;
        };

        setGroups(
            availableGroups.map(group => ({
                sectionDefinitionName: group.sectionDefinitionName,
                columns: group.fields.map(convertAvailableFieldToColumn),
                type: group.type,
            })),
        );
    }, [availableGroups, defaultColumns]);

    return [groups, setGroups];
};
