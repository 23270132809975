import { API_BASE_URL, buildImportFormData, client } from '@/api/common';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { JobFamily, JobFamilyCreateMutation, JobFamilyUpdateMutation } from '@/domain/job-family/JobFamily.model';
import { AxiosResponse } from 'axios';

export type JobFamilyDTO = JobFamily;
type JobFamilyCreateMutationDTO = JobFamilyCreateMutation;
type JobFamilyUpdateMutationDTO = JobFamilyUpdateMutation;

const getJobFamilies = async (): Promise<JobFamily[]> => {
    const url = API_BASE_URL + `/job-families`;
    return (await client.get<JobFamilyDTO[]>(url)).data;
};

const getJobFamilyById = async (jobFamilyId: number): Promise<JobFamily> => {
    const url = API_BASE_URL + `/job-families/${jobFamilyId}`;
    return (await client.get<JobFamilyDTO>(url)).data;
};

const createJobFamily = async (request: JobFamilyCreateMutation): Promise<JobFamily> => {
    const url = API_BASE_URL + `/job-families`;
    return (await client.post<JobFamilyDTO, AxiosResponse<JobFamilyDTO>, JobFamilyCreateMutationDTO>(url, request)).data;
};

const updateJobFamily = async (jobFamilyId: number, request: JobFamilyUpdateMutation): Promise<JobFamily> => {
    const url = API_BASE_URL + `/job-families/${jobFamilyId}`;
    return (await client.put<JobFamilyDTO, AxiosResponse<JobFamilyDTO>, JobFamilyUpdateMutationDTO>(url, request)).data;
};

const deleteJobFamily = async (jobFamilyId: number): Promise<void> => {
    const url = API_BASE_URL + `/job-families/${jobFamilyId}`;
    await client.delete(url);
};

const importJobFamilies = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(API_BASE_URL + `/job-families/import`, formData)).data;
};

export const jobFamilyAPI = {
    getJobFamilies,
    getJobFamilyById,
    createJobFamily,
    updateJobFamily,
    deleteJobFamily,
    importJobFamilies,
};
