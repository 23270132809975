import { getLeaveBalance, getLeaveTypeHistories } from '@/domain/leave-type-history/LeaveTypeHistory.service';
import {
    EmployeeLeaveBalanceHistorySearchRequest,
    EmployeeLeaveTypeBalance,
    LeaveBalanceSearchRequest,
    LeaveTypeHistory,
} from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { useCallback, useState } from 'react';
import { UseQueryResult } from '@/page/Query.type';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { handleError } from '@/utils/api.util';

export const useGetLeaveTypeHistories = (
    employeeId: number,
    searchRequest: EmployeeLeaveBalanceHistorySearchRequest = {},
): UseQueryResult<LeaveTypeHistory[]> => {
    const [leaveTypeHistories, setLeaveTypeHistories] = useState<LeaveTypeHistory[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLeaveTypeHistories = useCallback(async (employeeId: number, searchRequest: EmployeeLeaveBalanceHistorySearchRequest) => {
        if (!employeeId) {
            return;
        }

        try {
            const leaveTypeHistories = await getLeaveTypeHistories(employeeId, searchRequest);
            setLeaveTypeHistories(leaveTypeHistories);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchLeaveTypeHistories(employeeId, searchRequest).catch(handleError);
    }, [fetchLeaveTypeHistories, employeeId, searchRequest]);

    return {
        data: leaveTypeHistories,
        setData: setLeaveTypeHistories,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchLeaveTypeHistories(employeeId, searchRequest),
    };
};

export const useGetLeaveBalance = ({
    leaveTypeId,
    searchRequest,
    options = { enabled: true },
}: {
    leaveTypeId: number;
    searchRequest: LeaveBalanceSearchRequest;
    options?: { enabled: boolean };
}): UseQueryResult<EmployeeLeaveTypeBalance[]> => {
    const [userLeaveTypeBalance, setUserLeaveTypeBalance] = useState<EmployeeLeaveTypeBalance[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchUserLeaveTypeBalance = useCallback(
        async (leaveTypeId: number, searchRequest: LeaveBalanceSearchRequest) => {
            if (!leaveTypeId || !options.enabled) {
                return;
            }

            try {
                setIsFetching(true);
                const leaveTypeBalance = await getLeaveBalance(leaveTypeId, searchRequest);
                setUserLeaveTypeBalance(leaveTypeBalance);
            } catch (error) {
                setError(error);
            }
            setIsFetching(false);
            setIsLoading(false);
        },
        [options.enabled],
    );

    useDeepCompareEffect(() => {
        fetchUserLeaveTypeBalance(leaveTypeId, searchRequest).catch(handleError);
    }, [fetchUserLeaveTypeBalance, leaveTypeId, searchRequest]);

    return {
        data: userLeaveTypeBalance,
        setData: setUserLeaveTypeBalance,
        isLoading,
        isFetching,
        isError: !!error,
        error,
        refetch: () => fetchUserLeaveTypeBalance(leaveTypeId, searchRequest),
    };
};
