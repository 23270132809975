import * as yup from 'yup';
import { getTodayDate, isValidTime, setTime, subHours } from '@/utils/datetime.util';
import i18next from 'i18next';

const MINIMUM_TIME_DURATION_HOURS = 11;

export const getCorrectClockInFormSchema = () => {
    return yup
        .object()
        .shape({
            referenceDate: yup.string<LocalDate>().required(),
            startTime: yup
                .string<LocalTime>()
                .required()
                .test({
                    name: 'minimum time',
                    message: i18next.t('correct_clock_in_dialog.minimum_time', { hours: MINIMUM_TIME_DURATION_HOURS }),
                    test: (startTime, context) => {
                        if (!isValidTime(startTime)) {
                            return false;
                        }
                        const minimumTime = subHours(getTodayDate(), MINIMUM_TIME_DURATION_HOURS);
                        const date = setTime(context.parent.referenceDate, startTime);

                        //now we need to compare if the time is before the minimum time
                        return date >= minimumTime;
                    },
                })
                .test({
                    name: 'maximum time',
                    message: i18next.t('correct_clock_in_dialog.maximum_time'),
                    test: (startTime, context) => {
                        if (!isValidTime(startTime)) {
                            return false;
                        }
                        const date = setTime(context.parent.referenceDate, startTime);
                        const maximumTime = getTodayDate();

                        return date <= maximumTime;
                    },
                }),
        })
        .required();
};

export type CorrectClockInFormSchema = yup.InferType<ReturnType<typeof getCorrectClockInFormSchema>>;
