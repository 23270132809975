import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { Department, DepartmentNode, DepartmentNodeOrganization } from '@/domain/department/Department.model';
import { getDepartmentNodeOrganizations, getDepartmentNodes, getDepartments } from '@/domain/department/Department.service';
import { handleError } from '@/utils/api.util';

export const useGetDepartments = (): UseQueryResult<Department[]> => {
    const [departments, setDepartments] = useState<Department[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchDepartments = useCallback(async () => {
        try {
            const departments = await getDepartments();
            setDepartments(departments);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchDepartments().catch(handleError);
    }, [fetchDepartments]);

    return {
        data: departments,
        setData: setDepartments,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchDepartments,
    };
};

export const useGetDepartmentNodes = (): UseQueryResult<DepartmentNode[]> => {
    const [departmentNodes, setDepartmentNodes] = useState<DepartmentNode[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchDepartmentNodes = useCallback(async () => {
        try {
            const departmentNodes = await getDepartmentNodes();
            setDepartmentNodes(departmentNodes);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchDepartmentNodes().catch(handleError);
    }, [fetchDepartmentNodes]);

    return {
        data: departmentNodes,
        setData: setDepartmentNodes,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchDepartmentNodes,
    };
};

export const useGetDepartmentNodeOrganizations = (): UseQueryResult<DepartmentNodeOrganization[]> => {
    const [departmentNodes, setDepartmentNodes] = useState<DepartmentNodeOrganization[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchDepartmentNodeOrganizations = useCallback(async () => {
        try {
            const departmentNodes = await getDepartmentNodeOrganizations();
            setDepartmentNodes(departmentNodes);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchDepartmentNodeOrganizations().catch(handleError);
    }, [fetchDepartmentNodeOrganizations]);

    return {
        data: departmentNodes,
        setData: setDepartmentNodes,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchDepartmentNodeOrganizations,
    };
};
