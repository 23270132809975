import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FilePicker, FilePickerItem, StoredFile } from '@/components/file-picker/FilePicker';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldText } from '@/components/form/field-text/FieldText';
import { ImageBox } from '@/components/image-box/ImageBox';
import { RichTextEditor } from '@/components/rich-text-editor/RichTextEditor';
import { Announcement } from '@/domain/announcement/Announcement.model';
import { useGetDepartments } from '@/hooks/department/Department.hook';
import { AnnouncementFormValues, AnnouncementImageFormValues, getAnnouncementSchema } from '@/page/announcement/AnnouncementForm.schema';
import { useGetLocations } from '@/page/setting/location/Location.hook';
import { getCurrentLocalDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, FormHelperText } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAnnouncementImageUrl } from '@/hooks/annoucement/Announcement.hook';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';

type AnnouncementDialogProps = {
    open: boolean;
    defaultValue?: Announcement;
    onClose: () => void;
    onSubmit: SubmitHandler<AnnouncementFormValues>;
    isPending: boolean;
};
export const AnnouncementDialog: FC<AnnouncementDialogProps> = ({ open, defaultValue, onClose, onSubmit, isPending }) => {
    const { t } = useTranslation();

    const { data: departments, isLoading: isDepartmentsLoading } = useGetDepartments();
    const { data: locations, isLoading: isLocationsLoading } = useGetLocations();

    const handleCloseCreateAnnouncementDialog = () => {
        onClose();
    };

    const { data: announcementImageUrl } = useGetAnnouncementImageUrl(defaultValue?.id, !!defaultValue?.imageUrl);

    const defaultImage: AnnouncementImageFormValues | null = defaultValue?.imageUrl
        ? ({
              // Hack to display image in file picker and remove it
              id: -1,
          } as StoredFile)
        : getNull();

    const { control, watch, setValue, handleSubmit } = useForm<AnnouncementFormValues>({
        resolver: yupResolver(getAnnouncementSchema()),
        defaultValues: {
            title: '',
            content: '',
            startDate: getCurrentLocalDate(),
            publishToAllDepartments: false,
            departments: [],
            publishToAllLocations: false,
            locations: [],
            image: undefined,
        },
        values: defaultValue
            ? {
                  title: defaultValue.title,
                  content: defaultValue.content,
                  startDate: defaultValue.startDate,
                  endDate: defaultValue.endDate,
                  sendEmailNotification: false,
                  publishToAllDepartments: defaultValue.departments.length === 0,
                  departments: defaultValue.departments,
                  publishToAllLocations: defaultValue.locations.length === 0,
                  locations: defaultValue.locations,
                  image: defaultImage,
              }
            : undefined,
    });

    const publishToAllDepartments = watch('publishToAllDepartments');
    const publishToAllLocations = watch('publishToAllLocations');
    const image: Nullable<FilePickerItem> = watch('image');

    return (
        <DialogWrapper onClose={handleCloseCreateAnnouncementDialog} open={open} header={t('announcement.create_dialog.title')} maxWidth={'sm'}>
            <DialogContent>
                <Stack gap={2}>
                    <FormControlLabel
                        label={t('announcement.create_dialog.title_label')}
                        sx={{ width: '100%' }}
                        control={<FieldText name='title' control={control} autoFocus fullWidth />}
                    />
                    <Stack>
                        <Typography variant={'body1'}>{t('announcement.create_dialog.content_label')}</Typography>
                        <Controller
                            name={'content'}
                            control={control}
                            render={({ field, formState, fieldState }) => {
                                return (
                                    <RichTextEditor
                                        minHeight={'120px'}
                                        errorMessage={fieldState?.error?.message}
                                        onUpdate={field.onChange}
                                        defaultValue={formState?.defaultValues?.content ?? ''}
                                        name={'content'}
                                    />
                                );
                            }}
                        />
                    </Stack>
                    <Stack gap={1}>
                        <Controller
                            name='image'
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <>
                                        <FilePicker
                                            accept={'image/*'}
                                            maxFiles={1}
                                            files={field.value ? [field.value] : []}
                                            onFileUploaded={fileToAdd => {
                                                field.onChange(fileToAdd[0]);
                                            }}
                                            onFileRemoved={() => {
                                                field.onChange(getNull());
                                            }}
                                            onFileRenamed={fileToReplace => {
                                                field.onChange(fileToReplace);
                                            }}
                                            fetchDocumentUrl={undefined}
                                        />
                                        {!!fieldState.error?.message && (
                                            <FormHelperText sx={{ marginLeft: 2 }} error={!!fieldState.error?.message}>
                                                {fieldState.error?.message}
                                            </FormHelperText>
                                        )}
                                    </>
                                );
                            }}
                        />
                        {image && isTemporaryFile(image) && image.preview && <ImageBox src={image.preview} alt={image.name ?? ''} maxHeight={'300px'} />}
                        {/* Hack to display stored file  */}
                        {image?.id === -1 && announcementImageUrl && <ImageBox src={announcementImageUrl} alt={image.name ?? ''} maxHeight={'300px'} />}
                    </Stack>
                    <Stack direction={'row'} alignItems={'flex-start'} gap={2}>
                        <FormControlLabel
                            label={t('announcement.create_dialog.startDate_label')}
                            control={<FieldLocalDate control={control} name='startDate' />}
                        />
                        <FormControlLabel label={t('announcement.create_dialog.endDate_label')} control={<FieldLocalDate control={control} name='endDate' />} />
                    </Stack>
                    <FormControlLabel
                        label={t('announcement.create_dialog.publishToAllDepartments')}
                        labelPlacement='end'
                        control={<FieldSwitch control={control} name='publishToAllDepartments' onChange={() => setValue('departments', [])} />}
                    />
                    {!publishToAllDepartments && (
                        <Stack>
                            <FormControlLabel
                                label={t('announcement.create_dialog.departments_label')}
                                control={
                                    <FieldSelect
                                        name='departments'
                                        control={control}
                                        multiple
                                        fullWidth
                                        options={departments ?? []}
                                        loading={isDepartmentsLoading}
                                        getOptionLabel={department => getLabelTranslation(department?.name)}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        getOptionKey={option => option.id}
                                    />
                                }
                            />
                        </Stack>
                    )}
                    <FormControlLabel
                        label={t('announcement.create_dialog.publishToAllLocations')}
                        labelPlacement='end'
                        control={<FieldSwitch control={control} name='publishToAllLocations' onChange={() => setValue('locations', [])} />}
                    />
                    {!publishToAllLocations && (
                        <Stack>
                            <FormControlLabel
                                label={t('announcement.create_dialog.locations_label')}
                                control={
                                    <FieldSelect
                                        name='locations'
                                        control={control}
                                        multiple
                                        fullWidth
                                        options={locations ?? []}
                                        loading={isLocationsLoading}
                                        getOptionLabel={location => location.name}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        getOptionKey={option => option.id}
                                    />
                                }
                            />
                        </Stack>
                    )}
                    <Controller
                        name='sendEmailNotification'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FormControlLabel
                                label={t('announcement.create_dialog.send_email_notification')}
                                labelPlacement='end'
                                control={<Checkbox name='sendEmailNotification' checked={value} onChange={onChange} />}
                            />
                        )}
                    />
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleSubmit(onSubmit, console.error)()} fullWidth disabled={isPending}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
