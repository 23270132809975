import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { ReportFieldDefinition, ReportGroupFieldCategory } from '@/domain/report/Report.model';
import { getLabelTranslation } from '@/utils/language.util';
import { Button, ButtonProps, Checkbox, Drawer, FormControlLabel, Stack, Typography } from '@mui/material';
import { Layout3ColumnIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '@/domain/label/Label.model';

export type ReportColumn = Pick<ReportFieldDefinition, 'fieldType' | 'order' | 'valueType' | 'sectionFieldDefinition'> & { visible?: boolean };

export type ReportGroupedColumn = {
    type: ReportGroupFieldCategory;
    sectionDefinitionName: Label | undefined;
    columns: ReportColumn[];
};

export type ReportColumnsSelectorProps = ButtonProps & {
    groups: ReportGroupedColumn[];
    onGroupChange: (group: ReportGroupedColumn) => void;
};

export const ReportColumnsSelector: FC<ReportColumnsSelectorProps> = ({ groups, onGroupChange, ...rest }) => {
    const { t } = useTranslation();

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const handleColumnChange = (groupType: string, column: ReportColumn) => {
        const group = groups.find(g => g.type === groupType);

        const updatedColumns = group?.columns.map(c => {
            if (c.fieldType !== column.fieldType) {
                return c;
            }

            if (column.fieldType === 'EMPLOYEE_CUSTOM_FIELD' && c.sectionFieldDefinition?.id !== column.sectionFieldDefinition?.id) {
                return c;
            }

            return {
                ...c,
                visible: !c.visible,
            };
        });

        if (group && updatedColumns?.length) {
            onGroupChange({
                ...group,
                columns: updatedColumns,
            });
        }
    };

    return (
        <>
            <Button variant='text' startIcon={<Layout3ColumnIcon />} onClick={() => setDrawerIsOpen(true)} {...rest}>
                {t('reports.columns')}
            </Button>
            <Drawer anchor='right' open={drawerIsOpen} onClose={() => setDrawerIsOpen(false)}>
                <Stack p={3} gap={2}>
                    <Typography variant='h1'>{t('reports.columns_selector.title')}</Typography>

                    <Stack direction='row' gap={2}>
                        <Stack width={200}>
                            {groups.map(({ sectionDefinitionName, columns, type: groupType }) => (
                                <Stack key={groupType}>
                                    <Typography variant='body1bold' noWrap>
                                        {sectionDefinitionName
                                            ? getLabelTranslation(sectionDefinitionName)
                                            : t('reports.columns_selector.group_name.enum', {
                                                  context: groupType,
                                                  defaultValue: groupType,
                                              })}
                                    </Typography>

                                    {columns?.map(column => (
                                        <FormControlLabel
                                            key={column.fieldType + column.sectionFieldDefinition?.id}
                                            labelPlacement='end'
                                            control={
                                                <Checkbox
                                                    size='small'
                                                    checked={column.visible}
                                                    onChange={() => handleColumnChange(groupType, column)}
                                                    name={column.fieldType}
                                                />
                                            }
                                            label={getFieldDefinitionTranslation(column.sectionFieldDefinition ?? { fieldType: column.fieldType })}
                                        />
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            </Drawer>
        </>
    );
};
