import { StateHandler } from '@/components/state-handler/StateHandler';
import { FC } from 'react';
import { PendingLeaveRequestsTable } from './pending-leave-requests-table/PendingLeaveRequestsTable';
import { useGetPendingLeaveRequests } from '@/hooks/leave-request/LeaveRequest.hook';

export const PendingLeaveRequestsPage: FC = () => {
    const { data: pendingLeaveRequests = [], refetch, isLoading, isError, error } = useGetPendingLeaveRequests();

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error} isEmpty={!pendingLeaveRequests.length}>
            <PendingLeaveRequestsTable defaultLeaveRequests={pendingLeaveRequests} reloadView={() => refetch()} />
        </StateHandler>
    );
};
