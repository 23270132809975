import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Select } from '@/components/form/field-select/Select';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type SelectResourceDialogProps<T> = {
    options: { label: string; value: T }[];
    onSelectValue: (selectedValue: T) => void;
    label: string;
} & Omit<DialogWrapperProps, 'onSave'>;

export const SelectResourceDialog = <T,>({ options, onSelectValue, label, ...rest }: SelectResourceDialogProps<T>): JSX.Element => {
    const { t } = useTranslation();
    const [selectedResource, setSelectedResource] = useState<T>();

    return (
        <DialogWrapper {...rest}>
            <Stack gap={2} component={DialogContent}>
                <FormControlLabel
                    label={label}
                    control={
                        <Select
                            fullWidth
                            options={options}
                            getOptionLabel={option => option.label}
                            onChange={selectedOption => setSelectedResource(selectedOption?.value)}
                            autocompleteProps={{
                                autoFocus: true,
                            }}
                        />
                    }
                />
            </Stack>
            <DialogActions>
                <Button onClick={() => (selectedResource ? onSelectValue(selectedResource) : undefined)} disabled={!selectedResource} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
