import { API_BASE_URL, buildImportFormData, client } from '@/api/common';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import {
    TimesheetAdjustment,
    TimesheetAdjustmentBulkMutation,
    TimesheetAdjustmentCreateMutation,
    TimesheetAdjustmentUpdateMutation,
} from '@/domain/timesheet-adjustment/TimesheetAdjustment.model';
import { AxiosResponse } from 'axios';

const timesheetAdjustmentRequest = async (request: TimesheetAdjustmentCreateMutation): Promise<TimesheetAdjustment> => {
    const url = API_BASE_URL + '/timesheet-adjustments';
    return (await client.post<TimesheetAdjustmentDTO, AxiosResponse<TimesheetAdjustmentDTO>, TimesheetAdjustmentCreateMutationDTO>(url, request)).data;
};

const bulkTimesheetAdjustmentCreationRequest = async (requests: TimesheetAdjustmentBulkMutation[]): Promise<TimesheetAdjustment[]> => {
    const url = API_BASE_URL + '/timesheet-adjustments/bulk';
    return (await client.post<TimesheetAdjustmentDTO[], AxiosResponse<TimesheetAdjustmentDTO[]>, TimesheetAdjustmentBulkMutationDTO[]>(url, requests)).data;
};

const timesheetAdjustmentUpdateRequest = async (request: TimesheetAdjustmentUpdateMutation, id: number): Promise<TimesheetAdjustment> => {
    const url = API_BASE_URL + `/timesheet-adjustments/${id}`;
    return (await client.put<TimesheetAdjustmentDTO, AxiosResponse<TimesheetAdjustmentDTO>, TimesheetAdjustmentUpdateMutationDTO>(url, request)).data;
};

const getTimesheetAdjustment = async (id: number): Promise<TimesheetAdjustment> => {
    const url = API_BASE_URL + `/timesheet-adjustments/${id}`;
    return (await client.get<TimesheetAdjustmentDTO>(url)).data;
};

const deleteTimesheetAdjustment = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/timesheet-adjustments/${id}`;
    return (await client.delete(url)).data;
};

const importTimesheetsAdjustments = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(API_BASE_URL + `/timesheet-adjustments/import`, formData)).data;
};

export const timesheetAdjustmentAPI = {
    timesheetAdjustmentRequest,
    bulkTimesheetAdjustmentCreationRequest,
    timesheetAdjustmentUpdateRequest,
    deleteTimesheetAdjustment,
    importTimesheetsAdjustments,
    getTimesheetAdjustment,
};

type TimesheetAdjustmentCreateMutationDTO = TimesheetAdjustmentCreateMutation;
type TimesheetAdjustmentBulkMutationDTO = TimesheetAdjustmentBulkMutation;
type TimesheetAdjustmentUpdateMutationDTO = TimesheetAdjustmentUpdateMutation;
type TimesheetAdjustmentDTO = TimesheetAdjustment;
