import countries from 'i18n-iso-countries';
import i18next from 'i18next';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import deLocale from 'i18n-iso-countries/langs/de.json';

export type Country = {
    label: string;
    value: string; // country code ISO 3166 alpha-2
};

export const getCountryList = (): Country[] => {
    const currentLanguage = i18next.language;
    countries.registerLocale(enLocale);
    countries.registerLocale(frLocale);
    countries.registerLocale(deLocale);
    const countriesI8n = countries.getNames(currentLanguage, { select: 'official' });

    return Object.entries(countriesI8n).map(([key, value]) => {
        return {
            label: value,
            value: key,
        };
    });
};

export const getCountry = (countryCode: string): Country => {
    const countryList = getCountryList();
    const country = countryList.find(country => country.value === countryCode);
    return country ?? { label: '', value: '' };
};

export const getCountryName = (countryCode: string): string => {
    const countryList = getCountryList();
    const country = countryList.find(country => country.value === countryCode);
    return country ? country.label : '';
};
