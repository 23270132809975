import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldText } from '@/components/form/field-text/FieldText';
import { Label, LabelRequest } from '@/domain/label/Label.model';
import { Report } from '@/domain/report/Report.model';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type RenameReportDialogProps = Omit<DialogWrapperProps, 'onSave'> & {
    onSave: (title: Label) => void;
    report: Pick<Report, 'title'>;
};

type RenameReportFormValues = {
    title: string;
};

const schema = yup.object().shape({
    title: yup.string().required(),
});

export const RenameReportDialog: FC<RenameReportDialogProps> = ({ onSave, ...rest }) => {
    const { t } = useTranslation();
    const { handleSubmit, control } = useForm<RenameReportFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            title: getLabelTranslation(rest.report.title),
        },
    });

    const handleSave = (values: RenameReportFormValues) => {
        const title: LabelRequest = {
            translationEn: values.title,
            translationFr: values.title,
            translationDe: values.title,
            translationIt: values.title,
        };
        onSave(title);
    };

    return (
        <DialogWrapper header={t('reports.rename_report_dialog.title')} {...rest}>
            <Stack gap={2} component={DialogContent}>
                <FormControlLabel label={t('general.title')} control={<FieldText name='title' control={control} defaultValue='' autoFocus fullWidth />} />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
