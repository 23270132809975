import { Components, Theme } from '@mui/material';
import { DIALOG_Z_INDEX } from '@/config/ui.constants';

export const MuiDialog: Components<Omit<Theme, 'components'>>['MuiDialog'] = {
    defaultProps: {
        fullWidth: true,
        maxWidth: 'xs',
    },
    styleOverrides: {
        root: () => ({
            '& .MuiModal-root': {
                left: '100px',
            },
            // Mui doesn't provide a way to override the container's alignItems
            '& .MuiDialog-container': {
                alignItems: 'flex-start',
            },
            zIndex: DIALOG_Z_INDEX,
        }),
    },
};
export const MuiDialogTitle: Components<Omit<Theme, 'components'>>['MuiDialogTitle'] = {
    defaultProps: {
        variant: 'h1',
    },
    styleOverrides: {
        root: ({ theme }) => ({
            padding: theme.spacing(3),
        }),
    },
};
export const MuiDialogActions: Components<Omit<Theme, 'components'>>['MuiDialogActions'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            padding: theme.spacing(3),
        }),
    },
};

export const MuiDialogContent: Components<Omit<Theme, 'components'>>['MuiDialogContent'] = {
    styleOverrides: {
        root: () => ({
            paddingBottom: 0,
        }),
    },
};
