import { API_BASE_URL, client } from '@/api/common';
import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import { LeaveRequestDTO, mapLeaveRequestDTO } from '@/api/leave-request/LeaveRequest.api';
import { EmployeeStatusUpdate } from '@/domain/employee/Employee.model';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import { EmployeePayrollProfileChange, PayrollProfileChangeSearch, PayrollSectionValues } from '@/domain/payroll/Payroll.model';
import { SectionType } from '@/domain/section-setting/Section.model';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

import { AxiosResponse } from 'axios';
import { mapSectionDefinitionDTO, SectionDefinitionDTO } from '@/api/section-definition/SectionDefinition.api';
import { EmployeeSectionRowDTO, mapEmployeeSectionRowDTO } from '@/api/employee-section/EmployeeSection.api';

type EmployeePayrollChangeValueDTO = EmployeeSectionRowDTO & {
    updatedAt: string;
    updatedBy: EmployeeAvatarDTO;
    status: EmployeeStatusUpdate;
    reviewedAt?: string;
    reviewedBy?: EmployeeAvatarDTO;
};

type PayrollSectionValuesDTO = {
    previousValue?: EmployeePayrollChangeValueDTO;
    currentValue: EmployeePayrollChangeValueDTO;
}[];

type PayrollSectionDTO = {
    sectionDefinition: SectionDefinitionDTO;
    values: PayrollSectionValuesDTO;
};

type EmployeePayrollProfileChangeDTO = {
    employee: EmployeeAvatarDTO;
    sections: PayrollSectionDTO[];
};

type LeaveTransactionDTO = DateToString<Overwrite<LeaveTransaction, { leaveRequest?: LeaveRequestDTO }>>;

const mapLeaveTransactionDTO = (dto: LeaveTransactionDTO): LeaveTransaction => {
    return {
        ...dto,
        leaveRequest: dto.leaveRequest ? mapLeaveRequestDTO(dto.leaveRequest) : undefined,
        createdAt: convertUTCIsoStringToDate(dto.createdAt),
        updateAt: convertUTCIsoStringToDate(dto.updateAt),
        transactionStartDate: convertUTCIsoStringToDate(dto.transactionStartDate),
        transactionEndDate: convertUTCIsoStringToDate(dto.transactionEndDate),
    };
};

const getPayrollTransactions = async ({ startDate }: { startDate: LocalDate }): Promise<LeaveTransaction[]> => {
    return (
        await client.get<
            LeaveTransactionDTO[],
            AxiosResponse<LeaveTransactionDTO[]>,
            {
                startDate: LocalDate;
            }
        >(API_BASE_URL + `/payroll/transactions`, {
            params: {
                startDate,
            },
        })
    ).data.map(mapLeaveTransactionDTO);
};

type EmployeePayrollProfileChangeSearchRequestDTO = {
    startDate: LocalDate;
    endDate: LocalDate;
    locationIds: number[];
    employeeIds: number[];
    departmentIds: number[];
};

const getPayrollEmployeeProfileChanges = async (search: PayrollProfileChangeSearch): Promise<EmployeePayrollProfileChange[]> => {
    const { data } = await client.post<
        EmployeePayrollProfileChangeDTO[],
        AxiosResponse<EmployeePayrollProfileChangeDTO[]>,
        EmployeePayrollProfileChangeSearchRequestDTO
    >(API_BASE_URL + `/payroll/profile-changes`, search);
    return data.map(mapToEmployeePayrollProfileChange);
};

const mapToEmployeePayrollProfileChange = (change: EmployeePayrollProfileChangeDTO): EmployeePayrollProfileChange => {
    const mapToValue = (value: PayrollSectionValuesDTO[0]): PayrollSectionValues[0] => {
        return {
            currentValue: {
                ...value.currentValue,
                ...mapEmployeeSectionRowDTO(value.currentValue),
                updatedAt: convertUTCIsoStringToDate(value.currentValue.updatedAt),
                reviewedAt: convertUTCIsoStringToDate(value.currentValue.reviewedAt),
            },
            previousValue: value?.previousValue
                ? {
                      ...value.previousValue,
                      ...mapEmployeeSectionRowDTO(value.previousValue),
                      updatedAt: convertUTCIsoStringToDate(value?.previousValue.updatedAt),
                      reviewedAt: convertUTCIsoStringToDate(value?.previousValue.reviewedAt),
                  }
                : undefined,
        };
    };

    return {
        employee: change.employee,
        sections: change.sections.map(section => ({
            ...section,
            sectionDefinition: mapSectionDefinitionDTO(section.sectionDefinition),
            values: section.values.map(mapToValue),
        })),
    };
};

const markAsReviewed = async (payload: { employeeId: number; sectionType: SectionType; rowId: number }): Promise<void> => {
    await client.post(API_BASE_URL + '/payroll/profile-changes/mark-reviewed', payload);
};

const unmarkAsReviewed = async (payload: { employeeId: number; sectionType: SectionType; rowId: number }): Promise<void> => {
    await client.post(API_BASE_URL + '/payroll/profile-changes/unmark-reviewed', payload);
};

export const payrollAPI = {
    getPayrollTransactions,
    getPayrollEmployeeProfileChanges,
    markAsReviewed,
    unmarkAsReviewed,
};
