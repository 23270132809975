import { FC } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { EmployeeReviewSummaryFeedbackSkillPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/EmployeeReviewSummaryFeedbackSkillPDF';
import { EmployeeReviewSummaryFeedbackSkillsFormType } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { hasAtLeastOneFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';

type EmployeeReviewSummaryFeedbackSkillsPDFProps = {
    skills: EmployeeReviewSummaryFeedbackSkillsFormType;
};

export const EmployeeReviewSummaryFeedbackSkillsPDF: FC<EmployeeReviewSummaryFeedbackSkillsPDFProps> = ({ skills }) => {
    const skillsThatHaveBeenReviewed = skills?.skills?.filter(skill => hasAtLeastOneFeedback(skill));
    const theme = useTheme();
    const { t } = useTranslation();
    if (!skillsThatHaveBeenReviewed?.length) {
        return <></>;
    }

    return (
        <View style={{ gap: theme.spacing(1) }}>
            <Text
                style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    paddingLeft: theme.spacing(2),
                }}
            >
                {t('reviews.write_feedback.review_skills')}
            </Text>

            {skillsThatHaveBeenReviewed?.map(skill => (
                <EmployeeReviewSummaryFeedbackSkillPDF key={skill.skill.id} questionItem={skill} rating={skills.rating} />
            ))}
        </View>
    );
};
