import { Chip } from '@mui/material';
import { getUserEmploymentStatusTranslationKey } from '@/domain/employee/Employee.service';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type EmploymentStatusBadgeProps = {
    employmentStatus: EmploymentStatus | undefined;
};

export const EmploymentStatusBadge: FC<EmploymentStatusBadgeProps> = ({ employmentStatus }) => {
    const { t } = useTranslation();

    if (!employmentStatus) {
        return;
    }

    let color: 'success' | 'info' | 'warning' | 'error';
    switch (employmentStatus) {
        case EmploymentStatus.EMPLOYED:
            color = 'success';
            break;
        case EmploymentStatus.HIRED:
            color = 'info';
            break;
        case EmploymentStatus.ON_LONG_LEAVE:
            color = 'warning';
            break;
        case EmploymentStatus.TERMINATED:
            color = 'error';
            break;
    }

    return <Chip color={color} size='small' variant='filled' label={t(getUserEmploymentStatusTranslationKey(), { context: employmentStatus })} />;
};
