import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type ShareReportDialogProps = Omit<DialogWrapperProps, 'onSave'> & {
    onSave: ({ viewers }: { viewers: EmployeeAvatar[] }) => void;
    viewers: EmployeeAvatar[];
};

type ShareReportFormValues = {
    viewers: EmployeeAvatar[];
};

const schema = yup.object().shape({
    viewers: yup.array().required().min(1),
});

export const ShareReportDialog: FC<ShareReportDialogProps> = ({ onSave, viewers, ...rest }) => {
    const { t } = useTranslation();
    const { handleSubmit, control } = useForm<ShareReportFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            viewers,
        },
    });

    const handleSave = (values: ShareReportFormValues) => {
        onSave(values);
    };

    const { data: employeesOptions = [], isLoading: isLoadingEmployees } = useGetEmployees();

    const getEmployeeOptionLabel = (option: ShareReportFormValues['viewers'][0]) => {
        return option.displayName;
    };

    return (
        <DialogWrapper header={t('reports.share_report_dialog.title')} {...rest}>
            <Stack gap={2} component={DialogContent}>
                <FormControlLabel
                    label={t('reports.share_report_dialog.viewers')}
                    control={
                        <FieldSelect
                            name='viewers'
                            control={control}
                            options={employeesOptions}
                            loading={isLoadingEmployees}
                            multiple={true}
                            fullWidth
                            getOptionLabel={getEmployeeOptionLabel}
                            getOptionKey={option => option.id}
                            isOptionEqualToValue={(option, current) => option.id === current.id}
                        />
                    }
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
