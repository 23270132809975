import { EmployeeAvatar } from '@/domain/employee/Employee.model';

export type TimesheetAdjustment = {
    id: number;
    amountInMinutes: number;
    comment: string;
    employee: EmployeeAvatar;
    requestDate: LocalDate;
    locked: boolean;
};

export type TimesheetAdjustmentCreateMutation = {
    employeeId: number;
    amountInMinutes: number;
    requestDate: LocalDate;
    comment?: string;
    //if we provide a leave type id, it will remove from the timesheet and add to the leave request
    leaveTypeId?: number;
};

export type TimesheetAdjustmentUpdateMutation = {
    amountInMinutes: number;
    requestDate: string;
    comment: string;
};

export type TimesheetAdjustmentBulkMutation = TimesheetAdjustmentCreateMutation;

export enum TimesheetAdjustmentAction {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
    TRANSFER = 'TRANSFER',
}
