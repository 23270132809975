export const getTableHeight = ({
    rowsLength,
    disableFooter = false,
    disableHeader = false,
}: {
    rowsLength: number;
    disableFooter?: boolean;
    disableHeader?: boolean;
}): number => {
    const ROW_HEIGHT = 50;
    const HEADER_HEIGHT = disableHeader ? 0 : 50;
    const SCROLL_BAR_HEIGHT = 15;
    const FOOTER_STATUS_BAR_HEIGHT = disableFooter ? 0 : 50;

    // We compute the height of the table based on the number of rows
    return rowsLength * ROW_HEIGHT + HEADER_HEIGHT + SCROLL_BAR_HEIGHT + FOOTER_STATUS_BAR_HEIGHT;
};
