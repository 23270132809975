import { ObjectiveHistory } from '@/domain/objective-history/ObjectiveHistory.model';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { ActivityFeed } from '@/components/activity-feed/ActivityFeed';
import { OBJECTIVE_HISTORY_DIALOG_TAB_HEIGHT } from '@/page/objective/objective-history-dialog/ObjectiveHistoryDialog.constants';

type ObjectiveHistoryDialogChangeHistoryTabProps = {
    objectiveHistories: ObjectiveHistory[];
};

export const ObjectiveHistoryDialogChangeHistoryTab: FC<ObjectiveHistoryDialogChangeHistoryTabProps> = ({ objectiveHistories }) => {
    const objectiveHistoryChangeItems = getObjectiveHistoryChanges(objectiveHistories).reverse();

    return (
        <Stack gap={2} py={2} height={OBJECTIVE_HISTORY_DIALOG_TAB_HEIGHT} overflow='auto'>
            {objectiveHistoryChangeItems.map((change, index) => (
                <Stack key={objectiveHistories?.[index]?.id} gap={1}>
                    {change}
                </Stack>
            ))}
            {objectiveHistories[0] && <ObjectiveCreationItem objectiveHistory={objectiveHistories[0]} />}
        </Stack>
    );
};

type ObjectiveDueDateChangeItemProps = {
    oldObjective: ObjectiveHistory;
    newObjective: ObjectiveHistory;
};

const buildChangeList = (histories: ObjectiveHistory[], currentIndex: number, changes: ReactNode[]): ReactNode[] => {
    if (currentIndex >= histories.length) {
        return changes;
    }

    const previousObjective = histories[currentIndex - 1];
    const currentObjective = histories[currentIndex];
    const change = generateObjectiveChangeItem(previousObjective, currentObjective);

    if (change) {
        changes = changes.concat(change);
    }

    return buildChangeList(histories, currentIndex + 1, changes);
};

const getObjectiveHistoryChanges = (objectiveHistories: ObjectiveHistory[]): ReactNode[] => {
    // If the length of objectiveHistory is 1, it indicates that it only contains the creation record with no updates.
    if (objectiveHistories.length <= 1) {
        return [];
    }

    return buildChangeList(objectiveHistories, 1, []);
};

const generateObjectiveChangeItem = (oldObjective: ObjectiveHistory, newObjective: ObjectiveHistory): ReactNode[] => {
    const changes = [];

    if (oldObjective.dueDate !== newObjective.dueDate) {
        changes.push(<ObjectiveDueDateChangeItem oldObjective={oldObjective} newObjective={newObjective} />);
    }

    if (oldObjective.name !== newObjective.name) {
        changes.push(<ObjectiveNameChangeItem oldObjective={oldObjective} newObjective={newObjective} />);
    }

    if (oldObjective.description !== newObjective.description) {
        changes.push(<ObjectiveDescriptionChangeItem oldObjective={oldObjective} newObjective={newObjective} />);
    }

    if (oldObjective.parent?.name !== newObjective.parent?.name) {
        changes.push(<ObjectiveParentChangeItem oldObjective={oldObjective} newObjective={newObjective} />);
    }

    if (oldObjective.assignee?.displayName !== newObjective.assignee?.displayName) {
        changes.push(<ObjectiveAssigneeChangeItem oldObjective={oldObjective} newObjective={newObjective} />);
    }

    if (oldObjective.weight !== newObjective.weight) {
        changes.push(<ObjectiveWeightChangeItem oldObjective={oldObjective} newObjective={newObjective} />);
    }

    if (oldObjective.category?.id !== newObjective.category?.id) {
        changes.push(<ObjectiveCategoryChangeItem oldObjective={oldObjective} newObjective={newObjective} />);
    }

    return changes;
};

const ObjectiveDueDateChangeItem: FC<ObjectiveDueDateChangeItemProps> = ({ oldObjective, newObjective }) => {
    const { t } = useTranslation();
    return (
        <ObjectiveChangeItem newObjective={newObjective}>
            {t('objectives.history_dialog.changed_due_date', {
                oldValue: formatInDefaultDate(oldObjective.dueDate),
                newValue: formatInDefaultDate(newObjective.dueDate),
            })}
        </ObjectiveChangeItem>
    );
};

type ObjectiveNameChangeItemProps = {
    oldObjective: ObjectiveHistory;
    newObjective: ObjectiveHistory;
};

const ObjectiveNameChangeItem: FC<ObjectiveNameChangeItemProps> = ({ oldObjective, newObjective }) => {
    const { t } = useTranslation();

    return (
        <ObjectiveChangeItem newObjective={newObjective}>
            {t('objectives.history_dialog.changed_title', {
                oldValue: oldObjective.name,
                newValue: newObjective.name,
            })}
        </ObjectiveChangeItem>
    );
};

type ObjectiveAssigneeChangeItemProps = {
    oldObjective: ObjectiveHistory;
    newObjective: ObjectiveHistory;
};

const ObjectiveAssigneeChangeItem: FC<ObjectiveAssigneeChangeItemProps> = ({ oldObjective, newObjective }) => {
    const { t } = useTranslation();

    return (
        <ObjectiveChangeItem newObjective={newObjective}>
            {t('objectives.history_dialog.changed_assignee', {
                oldValue: oldObjective.assignee?.displayName,
                newValue: newObjective.assignee?.displayName,
            })}
        </ObjectiveChangeItem>
    );
};

type ObjectiveParentChangeItemProps = {
    oldObjective: ObjectiveHistory;
    newObjective: ObjectiveHistory;
};
const ObjectiveParentChangeItem: FC<ObjectiveParentChangeItemProps> = ({ oldObjective, newObjective }) => {
    const { t } = useTranslation();

    return (
        <ObjectiveChangeItem newObjective={newObjective}>
            {t('objectives.history_dialog.changed_parent', {
                oldValue: oldObjective.parent?.name || '',
                newValue: newObjective.parent?.name || '',
            })}
        </ObjectiveChangeItem>
    );
};
type ObjectiveDescriptionChangeItemProps = {
    oldObjective: ObjectiveHistory;
    newObjective: ObjectiveHistory;
};
const ObjectiveDescriptionChangeItem: FC<ObjectiveDescriptionChangeItemProps> = ({ oldObjective, newObjective }) => {
    const { t } = useTranslation();

    return (
        <ObjectiveChangeItem newObjective={newObjective}>
            {t('objectives.history_dialog.changed_description', {
                oldValue: oldObjective.description,
                newValue: newObjective.description,
            })}
        </ObjectiveChangeItem>
    );
};

type ObjectiveWeightChangeItemProps = {
    oldObjective: ObjectiveHistory;
    newObjective: ObjectiveHistory;
};

const ObjectiveWeightChangeItem: FC<ObjectiveWeightChangeItemProps> = ({ oldObjective, newObjective }) => {
    const { t } = useTranslation();

    return (
        <ObjectiveChangeItem newObjective={newObjective}>
            {t('objectives.history_dialog.changed_weight', {
                oldValue: oldObjective.weight,
                newValue: newObjective.weight,
            })}
        </ObjectiveChangeItem>
    );
};

type ObjectiveCategoryChangeItemProps = {
    oldObjective: ObjectiveHistory;
    newObjective: ObjectiveHistory;
};

const ObjectiveCategoryChangeItem: FC<ObjectiveCategoryChangeItemProps> = ({ oldObjective, newObjective }) => {
    const { t } = useTranslation();

    return (
        <ObjectiveChangeItem newObjective={newObjective}>
            {t('objectives.history_dialog.changed_category', {
                oldValue: getLabelTranslation(oldObjective.category?.name),
                newValue: getLabelTranslation(newObjective.category?.name),
            })}
        </ObjectiveChangeItem>
    );
};

type ObjectiveCreationItemProps = {
    objectiveHistory: ObjectiveHistory;
};

const ObjectiveCreationItem: FC<ObjectiveCreationItemProps> = ({ objectiveHistory }) => {
    const { t } = useTranslation();
    return (
        <Stack gap={0.5} pr={2}>
            <Stack direction='row' gap={1} alignItems='center'>
                {objectiveHistory.createdBy && <EmployeeAvatar employeeAvatar={objectiveHistory.createdBy} size={'small'} />}

                {objectiveHistory.createdBy && (
                    <Typography color='text.disabled'>
                        {t('objectives.history_dialog.created_by', {
                            displayName: objectiveHistory.createdBy.displayName,
                            date: objectiveHistory.createdAt,
                        })}
                    </Typography>
                )}
            </Stack>
            <ActivityFeed>{t('objectives.history_dialog.created')}</ActivityFeed>
        </Stack>
    );
};

type ObjectiveChangeItemProps = {
    newObjective: Partial<ObjectiveHistory>;
};
const ObjectiveChangeItem: FC<PropsWithChildren<ObjectiveChangeItemProps>> = ({ newObjective, children }) => {
    const { t } = useTranslation();

    return (
        <Stack gap={0.5} pr={2}>
            <Stack direction='row' gap={1} alignItems='center'>
                {newObjective.createdBy && <EmployeeAvatar employeeAvatar={newObjective.createdBy} size={'small'} />}

                {newObjective.createdBy && (
                    <Typography color='text.disabled'>
                        {t('objectives.history_dialog.created_by', {
                            displayName: newObjective.createdBy.displayName,
                            date: newObjective.createdAt,
                        })}
                    </Typography>
                )}
            </Stack>
            <ActivityFeed>{children}</ActivityFeed>
        </Stack>
    );
};
