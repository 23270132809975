import { Department } from '@/domain/department/Department.model';
import { EmploymentStatus } from '../employment/Employment.model';
import { Employee } from '@/domain/employee/Employee.model';
import { ContractType } from '@/domain/employment/Employment.model';
import { Job } from '@/domain/job/Job.model';
import { Location } from '@/domain/location/Location.model';
import { PermissionGroupType } from '@/domain/permission-group/PermissionGroup.model';

export enum PolicyPermission {
    APPROVE_REJECT_LEAVE_REQUEST = 'APPROVE_REJECT_LEAVE_REQUEST',
    MANAGE_PENDING_LEAVE_REQUEST = 'MANAGE_PENDING_LEAVE_REQUEST',
    MANAGE_APPROVED_LEAVE_REQUEST = 'MANAGE_APPROVED_LEAVE_REQUEST',
    SEE_WHO_IS_ON_LEAVE = 'SEE_WHO_IS_ON_LEAVE',
    VIEW_EMPLOYEE_LEAVE_TYPES = 'VIEW_EMPLOYEE_LEAVE_TYPES',
    CONFIGURE_LEAVES = 'CONFIGURE_LEAVES',
    VIEW_EMPLOYEE_BALANCE = 'VIEW_EMPLOYEE_BALANCE',
    CORRECT_EMPLOYEE_BALANCE = 'CORRECT_EMPLOYEE_BALANCE',
    CONFIGURE_DOCUMENTS = 'CONFIGURE_DOCUMENTS',
    VIEW_DOCUMENTS_IN_EMPLOYEE_FOLDER = 'VIEW_DOCUMENTS_IN_EMPLOYEE_FOLDER',
    MANAGE_DOCUMENTS_IN_EMPLOYEE_FOLDER = 'MANAGE_DOCUMENTS_IN_EMPLOYEE_FOLDER',
    DELETE_DOCUMENTS_IN_EMPLOYEE_FOLDER = 'DELETE_DOCUMENTS_IN_EMPLOYEE_FOLDER',
    VIEW_DOCUMENTS_IN_COMPANY_FOLDER = 'VIEW_DOCUMENTS_IN_COMPANY_FOLDER',
    MANAGE_DOCUMENTS_IN_COMPANY_FOLDER = 'MANAGE_DOCUMENTS_IN_COMPANY_FOLDER',
    DELETE_DOCUMENTS_IN_COMPANY_FOLDER = 'DELETE_DOCUMENTS_IN_COMPANY_FOLDER',
    CONFIGURE_EMPLOYEE_PAYROLL = 'CONFIGURE_EMPLOYEE_PAYROLL',
    CONFIGURE_ORGANIZATION = 'CONFIGURE_ORGANIZATION',
    IMPORT_DATA = 'IMPORT_DATA',
    CONFIGURE_EMPLOYEE_SECTIONS = 'CONFIGURE_EMPLOYEE_SECTIONS',
    CONFIGURE_TIME_MANAGEMENT = 'CONFIGURE_TIME_MANAGEMENT',
    MANAGE_EMPLOYEE_SECTIONS = 'MANAGE_EMPLOYEE_SECTIONS',
    MANAGE_PENDING_EMPLOYEE_SECTIONS = 'MANAGE_PENDING_EMPLOYEE_SECTIONS',
    VIEW_EMPLOYEE_SECTIONS = 'VIEW_EMPLOYEE_SECTIONS',
    MANAGE_EMPLOYEE_CONTRACTS = 'MANAGE_EMPLOYEE_CONTRACTS',
    VIEW_EMPLOYEE_CONTRACTS = 'VIEW_EMPLOYEE_CONTRACTS',
    MANAGE_EMPLOYEE_PERSONAL_INFOS = 'MANAGE_EMPLOYEE_PERSONAL_INFOS',
    VIEW_EMPLOYEE_PERSONAL_INFOS = 'VIEW_EMPLOYEE_PERSONAL_INFOS',
    MANAGE_PENDING_EMPLOYEE_PERSONAL_INFOS = 'MANAGE_PENDING_EMPLOYEE_PERSONAL_INFOS',
    MANAGE_EMPLOYEE_ADDRESSES = 'MANAGE_EMPLOYEE_ADDRESSES',
    MANAGE_PENDING_EMPLOYEE_ADDRESSES = 'MANAGE_PENDING_EMPLOYEE_ADDRESSES',
    VIEW_EMPLOYEE_ADDRESSES = 'VIEW_EMPLOYEE_ADDRESSES',
    MANAGE_EMPLOYEES = 'MANAGE_EMPLOYEES',
    VIEW_EMPLOYEES = 'VIEW_EMPLOYEES',
    VIEW_EMPLOYEE_BIRTHDAYS = 'VIEW_EMPLOYEE_BIRTHDAYS',
    VIEW_EMPLOYEE_WORK_ANNIVERSARIES = 'VIEW_EMPLOYEE_WORK_ANNIVERSARIES',
    MANAGE_EMPLOYEE_AVATARS = 'MANAGE_EMPLOYEE_AVATARS',
    ONBOARD_OFFBOARD_EMPLOYEES = 'ONBOARD_OFFBOARD_EMPLOYEES',
    CONFIGURE_PERMISSIONS = 'CONFIGURE_PERMISSIONS',
    VIEW_TIMESHEETS = 'VIEW_TIMESHEETS',
    APPROVE_REJECT_TIMESHEETS = 'APPROVE_REJECT_TIMESHEETS',
    MANAGE_PENDING_TIMESHEETS = 'MANAGE_PENDING_TIMESHEETS',
    MANAGE_APPROVED_TIMESHEETS = 'MANAGE_APPROVED_TIMESHEETS',
    DELETE_TIMESHEETS = 'DELETE_TIMESHEETS',
    CLOCK_IN_TIMESHEETS = 'CLOCK_IN_TIMESHEETS',
    MANAGE_PENDING_TIMESHEET_PAYMENTS = 'MANAGE_PENDING_TIMESHEET_PAYMENTS',
    MANAGE_TIMESHEET_PAYMENTS = 'MANAGE_TIMESHEET_PAYMENTS',
    MANAGE_TIMESHEET_ADJUSTMENTS = 'MANAGE_TIMESHEET_ADJUSTMENTS',
    VIEW_TIMESHEET_INSIGHT_BALANCE = 'VIEW_TIMESHEET_INSIGHT_BALANCE',
    MANAGE_SURVEYS = 'MANAGE_SURVEYS',
    VIEW_SURVEY_RESULTS = 'VIEW_SURVEY_RESULTS',
    CONFIGURE_PLANNINGS = 'CONFIGURE_PLANNINGS',
    MANAGE_SHIFTS = 'MANAGE_SHIFTS',
    VIEW_SHIFTS = 'VIEW_SHIFTS',
    CONFIGURE_REVIEWS = 'CONFIGURE_REVIEWS',
    MANAGE_REVIEWS = 'MANAGE_REVIEWS',
    CONTRIBUTE_EMPLOYEE_REVIEWS = 'CONTRIBUTE_EMPLOYEE_REVIEWS',
    ACCESS_EMPLOYEE_REVIEWS = 'ACCESS_EMPLOYEE_REVIEWS',
    CREATE_ONE_SHOT_REVIEWS = 'CREATE_ONE_SHOT_REVIEWS',
    CONFIGURE_OBJECTIVES = 'CONFIGURE_OBJECTIVES',
    CREATE_OBJECTIVES = 'CREATE_OBJECTIVES',
    EDIT_OBJECTIVES = 'EDIT_OBJECTIVES',
    UPDATE_OBJECTIVE_STATUS = 'UPDATE_OBJECTIVE_STATUS',
    VIEW_OBJECTIVES = 'VIEW_OBJECTIVES',
    CONFIGURE_THIRD_PARTIES = 'CONFIGURE_THIRD_PARTIES',
    VIEW_REPORTS = 'VIEW_REPORTS',
    EDIT_REPORTS = 'EDIT_REPORTS',
    VIEW_ANNOUNCEMENTS = 'VIEW_ANNOUNCEMENTS',
    MANAGE_ANNOUNCEMENTS = 'MANAGE_ANNOUNCEMENTS',
    // TODO: use this new permissions
    VIEW_EMPLOYEE_PAYROLL_LOCKS = 'VIEW_EMPLOYEE_PAYROLL_LOCKS',
    LOCK_EMPLOYEE_PAYROLL_LOCKS = 'LOCK_EMPLOYEE_PAYROLL_LOCKS',
    MANAGE_EMPLOYEE_PAYROLL_LOCKS = 'MANAGE_EMPLOYEE_PAYROLL_LOCKS',
    ACCESS_ORGANIZATION_BILLING = 'ACCESS_ORGANIZATION_BILLING',
    DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
    VIEW_EMPLOYEES_DIRECTORY = 'VIEW_EMPLOYEES_DIRECTORY',
    VIEW_EMPLOYEE_PHONE_NUMBERS = 'VIEW_EMPLOYEE_PHONE_NUMBERS',
    VIEW_EMPLOYEE_EMAILS = 'VIEW_EMPLOYEE_EMAILS',
    ACCESS_ROGER_HELP_CENTER = 'ACCESS_ROGER_HELP_CENTER',
    VIEW_ORGANIZATION_CHART = 'VIEW_ORGANIZATION_CHART',
}

export enum PolicyType {
    COMPANY = 'COMPANY',
    EMPLOYEES = 'EMPLOYEES',
}

export interface PermissionFilteringConditionRequest {
    id: number;
    conditionType: PermissionFilteringConditionType;
    conditionValueIds?: number[];
    conditionValueNames?: string[];
    conditionRule: PermissionFilteringConditionRule;
}

export interface FilteringConditionValue {
    id: number;
    manager?: Employee;
    department?: Department;
    location?: Location;
    job?: Job;
    employmentStatus?: EmploymentStatus;
    contractType?: ContractType;
}

export enum PermissionFilteringConditionType {
    LOCATION = 'LOCATION',
    DEPARTMENT = 'DEPARTMENT',
    JOB = 'JOB',
    EMPLOYMENT_STATUS = 'EMPLOYMENT_STATUS',
    CONTRACT_TYPE = 'CONTRACT_TYPE',
}

export interface FilteringCondition {
    id: number;
    conditionType?: PermissionFilteringConditionType;
    filterConditionValues: FilteringConditionValue[];
    conditionRule: PermissionFilteringConditionRule;
}

export enum PermissionFilterType {
    EMPLOYEE_LIST = 'EMPLOYEE_LIST',
    SELF = 'SELF',
    DYNAMIC = 'DYNAMIC',
    MANAGER = 'MANAGER',
    ALL_EMPLOYEES = 'ALL_EMPLOYEES',
}

export enum PermissionPolicyGroup {
    ANNOUNCEMENTS = 'ANNOUNCEMENTS',
    SETTINGS = 'SETTINGS',
    LEAVE = 'LEAVE',
    EMPLOYEE_PROFILE = 'EMPLOYEE_PROFILE',
    ACCESS_ORGANIZATION_BILLING = 'ACCESS_ORGANIZATION_BILLING',
    EMPLOYEE_DOCUMENTS = 'EMPLOYEE_DOCUMENTS',
    COMPANY_DOCUMENTS = 'COMPANY_DOCUMENTS',
    SURVEYS = 'SURVEYS',
    SHIFTS = 'SHIFTS',
    TIMESHEETS = 'TIMESHEETS',
    REVIEWS = 'REVIEWS',
    OBJECTIVES = 'OBJECTIVES',
    PAYROLL = 'PAYROLL',
    REPORTS = 'REPORTS',
}

export enum PermissionPolicySubGroup {
    EVENTS = 'EVENTS',
    DIRECTORY = 'DIRECTORY',
    REQUESTS = 'REQUESTS',
    VALIDATIONS = 'VALIDATIONS',
    PAYMENTS = 'PAYMENTS',
    BALANCES = 'BALANCES',
    BASIC_INFO = 'BASIC_INFO',
    CONTRACT = 'CONTRACT',
    PERSONAL_INFOS = 'PERSONAL_INFOS',
    ADDRESSES = 'ADDRESSES',
}

export interface Policy {
    name: PolicyPermission;
    editableBy: PermissionGroupType[];
}

export enum ResourceBasedName {
    'EMPLOYEE_ADDRESSES' = 'EMPLOYEE_ADDRESSES',
    'EMPLOYEE_SECTION' = 'EMPLOYEE_SECTION',
    'EMPLOYEE_PERSONAL_INFOS' = 'EMPLOYEE_PERSONAL_INFOS',
    'EMPLOYEE_DOCUMENTS_SUBGROUP' = 'EMPLOYEE_DOCUMENTS_SUBGROUP',
    'COMPANY_DOCUMENTS_SUBGROUP' = 'COMPANY_DOCUMENTS_SUBGROUP',
}

export interface PolicyGroup {
    name: PermissionPolicyGroup;
    policySubGroup: PolicySubGroup[];
}

export interface PolicySubGroup {
    resourceBasedName?: ResourceBasedName;
    subGroupName: string;
    policies: Policy[];
}

export enum PermissionFilteringConditionRule {
    EQUALS = 'EQUALS',
    NOT_EQUALS = 'NOT_EQUALS',
    SAME_AS_VIEWER = 'SAME_AS_VIEWER',
}

export const EMPLOYEE_PERMISSION_POLICY_GROUPS: PolicyGroup[] = [
    {
        name: PermissionPolicyGroup.EMPLOYEE_PROFILE,
        policySubGroup: [
            {
                subGroupName: PermissionPolicySubGroup.EVENTS,
                policies: [
                    {
                        name: PolicyPermission.VIEW_EMPLOYEE_BIRTHDAYS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_EMPLOYEE_WORK_ANNIVERSARIES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES, PermissionGroupType.SELF],
                    },
                ],
            },
            {
                subGroupName: PermissionPolicySubGroup.DIRECTORY,
                policies: [
                    {
                        name: PolicyPermission.VIEW_EMPLOYEES_DIRECTORY,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                    {
                        name: PolicyPermission.VIEW_EMPLOYEE_PHONE_NUMBERS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                    {
                        name: PolicyPermission.VIEW_EMPLOYEE_EMAILS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                ],
            },
            {
                subGroupName: PermissionPolicySubGroup.BASIC_INFO,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_EMPLOYEES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_EMPLOYEES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                    {
                        name: PolicyPermission.MANAGE_EMPLOYEE_AVATARS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                ],
            },
            {
                subGroupName: PermissionPolicySubGroup.CONTRACT,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_EMPLOYEE_CONTRACTS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.VIEW_EMPLOYEE_CONTRACTS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                ],
            },
            {
                resourceBasedName: ResourceBasedName.EMPLOYEE_PERSONAL_INFOS,
                subGroupName: '',
                policies: [
                    {
                        name: PolicyPermission.MANAGE_EMPLOYEE_PERSONAL_INFOS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.MANAGE_PENDING_EMPLOYEE_PERSONAL_INFOS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_EMPLOYEE_PERSONAL_INFOS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                ],
            },
            {
                resourceBasedName: ResourceBasedName.EMPLOYEE_ADDRESSES,
                subGroupName: '',
                policies: [
                    {
                        name: PolicyPermission.MANAGE_EMPLOYEE_ADDRESSES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.MANAGE_PENDING_EMPLOYEE_ADDRESSES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_EMPLOYEE_ADDRESSES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                ],
            },
            {
                resourceBasedName: ResourceBasedName.EMPLOYEE_SECTION,
                subGroupName: '',
                policies: [
                    {
                        name: PolicyPermission.MANAGE_EMPLOYEE_SECTIONS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.MANAGE_PENDING_EMPLOYEE_SECTIONS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_EMPLOYEE_SECTIONS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF, PermissionGroupType.ALL_EMPLOYEES],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.LEAVE,
        policySubGroup: [
            {
                subGroupName: PermissionPolicySubGroup.REQUESTS,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_PENDING_LEAVE_REQUEST,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.MANAGE_APPROVED_LEAVE_REQUEST,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_EMPLOYEE_LEAVE_TYPES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                    {
                        name: PolicyPermission.SEE_WHO_IS_ON_LEAVE,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                ],
            },
            {
                subGroupName: PermissionPolicySubGroup.VALIDATIONS,
                policies: [
                    {
                        name: PolicyPermission.APPROVE_REJECT_LEAVE_REQUEST,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                ],
            },
            {
                subGroupName: PermissionPolicySubGroup.BALANCES,
                policies: [
                    {
                        name: PolicyPermission.CORRECT_EMPLOYEE_BALANCE,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.EMPLOYEE_DOCUMENTS,
        policySubGroup: [
            {
                resourceBasedName: ResourceBasedName.EMPLOYEE_DOCUMENTS_SUBGROUP,
                subGroupName: '',
                policies: [
                    {
                        name: PolicyPermission.DELETE_DOCUMENTS_IN_EMPLOYEE_FOLDER,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.MANAGE_DOCUMENTS_IN_EMPLOYEE_FOLDER,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_DOCUMENTS_IN_EMPLOYEE_FOLDER,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.TIMESHEETS,
        policySubGroup: [
            {
                subGroupName: PermissionPolicySubGroup.REQUESTS,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_PENDING_TIMESHEETS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.MANAGE_APPROVED_TIMESHEETS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_TIMESHEETS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.CLOCK_IN_TIMESHEETS,
                        editableBy: [PermissionGroupType.SELF],
                    },
                ],
            },
            {
                subGroupName: PermissionPolicySubGroup.VALIDATIONS,
                policies: [
                    {
                        name: PolicyPermission.APPROVE_REJECT_TIMESHEETS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                ],
            },
            {
                subGroupName: PermissionPolicySubGroup.PAYMENTS,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_PENDING_TIMESHEET_PAYMENTS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.MANAGE_TIMESHEET_PAYMENTS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                ],
            },
            {
                subGroupName: PermissionPolicySubGroup.BALANCES,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_TIMESHEET_ADJUSTMENTS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_TIMESHEET_INSIGHT_BALANCE,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.PAYROLL,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.PAYROLL,
                policies: [
                    {
                        name: PolicyPermission.CONFIGURE_EMPLOYEE_PAYROLL,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.MANAGE_EMPLOYEE_PAYROLL_LOCKS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.LOCK_EMPLOYEE_PAYROLL_LOCKS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.REVIEWS,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.REVIEWS,
                policies: [
                    {
                        name: PolicyPermission.ACCESS_EMPLOYEE_REVIEWS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.CREATE_ONE_SHOT_REVIEWS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                ],
            },
        ],
    },

    {
        name: PermissionPolicyGroup.OBJECTIVES,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.OBJECTIVES,
                policies: [
                    {
                        name: PolicyPermission.CREATE_OBJECTIVES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.EDIT_OBJECTIVES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.UPDATE_OBJECTIVE_STATUS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                    {
                        name: PolicyPermission.VIEW_OBJECTIVES,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.SELF],
                    },
                ],
            },
        ],
    },
];

export const COMPANY_PERMISSION_POLICY_GROUPS: PolicyGroup[] = [
    {
        name: PermissionPolicyGroup.EMPLOYEE_PROFILE,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.SETTINGS,
                policies: [
                    {
                        name: PolicyPermission.ONBOARD_OFFBOARD_EMPLOYEES,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.ACCESS_ROGER_HELP_CENTER,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.VIEW_ORGANIZATION_CHART,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.SETTINGS,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.SETTINGS,
                policies: [
                    {
                        name: PolicyPermission.CONFIGURE_EMPLOYEE_SECTIONS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONFIGURE_ORGANIZATION,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONFIGURE_TIME_MANAGEMENT,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONFIGURE_LEAVES,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONFIGURE_DOCUMENTS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONFIGURE_PERMISSIONS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONFIGURE_PLANNINGS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONFIGURE_REVIEWS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONFIGURE_THIRD_PARTIES,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONFIGURE_OBJECTIVES,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.IMPORT_DATA,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.COMPANY_DOCUMENTS,
        policySubGroup: [
            {
                resourceBasedName: ResourceBasedName.COMPANY_DOCUMENTS_SUBGROUP,
                subGroupName: '',
                policies: [
                    {
                        name: PolicyPermission.DELETE_DOCUMENTS_IN_COMPANY_FOLDER,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.MANAGE_DOCUMENTS_IN_COMPANY_FOLDER,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.VIEW_DOCUMENTS_IN_COMPANY_FOLDER,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.SURVEYS,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.SURVEYS,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_SURVEYS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.VIEW_SURVEY_RESULTS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.SHIFTS,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.SHIFTS,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_SHIFTS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.VIEW_SHIFTS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.TIMESHEETS,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.TIMESHEETS,
                policies: [
                    {
                        name: PolicyPermission.DELETE_TIMESHEETS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CLOCK_IN_TIMESHEETS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.REVIEWS,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.REVIEWS,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_REVIEWS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.CONTRIBUTE_EMPLOYEE_REVIEWS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.ANNOUNCEMENTS,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.ANNOUNCEMENTS,
                policies: [
                    {
                        name: PolicyPermission.MANAGE_ANNOUNCEMENTS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.VIEW_ANNOUNCEMENTS,
                        editableBy: [PermissionGroupType.CUSTOM, PermissionGroupType.ALL_EMPLOYEES],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.REPORTS,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.REPORTS,
                policies: [
                    {
                        name: PolicyPermission.EDIT_REPORTS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                    {
                        name: PolicyPermission.VIEW_REPORTS,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                ],
            },
        ],
    },
    {
        name: PermissionPolicyGroup.ACCESS_ORGANIZATION_BILLING,
        policySubGroup: [
            {
                subGroupName: PermissionPolicyGroup.ACCESS_ORGANIZATION_BILLING,
                policies: [
                    {
                        name: PolicyPermission.ACCESS_ORGANIZATION_BILLING,
                        editableBy: [PermissionGroupType.CUSTOM],
                    },
                ],
            },
        ],
    },
];

export enum ConditionType {
    SPECIFIC_CONDITIONS = 'SPECIFIC_CONDITIONS',
    ALL_EMPLOYEES = 'ALL_EMPLOYEES',
}
