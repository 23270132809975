import { Employee, EmployeePolicy } from '@/domain/employee/Employee.model';
import { LoginMethod, Realm } from '@/domain/realm/Realm.model';
import { currentEmployeeReducer } from '@/stores/reducers/currentEmployeeSlice';
import { employeeProfileReducer } from '@/stores/reducers/employeeProfileSlice';
import { leavesReducer } from '@/stores/reducers/leavesSlice';
import { planningReducer } from '@/stores/reducers/planningSlice';
import { uiReducer } from '@/stores/reducers/uiSlice';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        currentEmployee: currentEmployeeReducer,
        ui: uiReducer,
        employeeProfile: employeeProfileReducer,
        planning: planningReducer,
        leaves: leavesReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 *
 * @returns current connected employee
 * @throws Error if employee is not found, this hook should be used only when the employee is guaranteed to be connected
 */
export const useCurrentEmployee = (): Employee => {
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);

    if (!currentEmployee) {
        throw new Error('Employee not found');
    }

    return currentEmployee;
};

/**
 *
 * @returns current realm
 * @throws Error if realm is not found, this hook should be used only when the realm is guaranteed to be loaded
 */
export const useCurrentRealm = (): Realm => {
    const realm = useAppSelector(state => state.ui.currentRealm);

    if (!realm) {
        throw new Error('Realm not found');
    }
    return realm;
};

export const useCurrentPolicies = (): EmployeePolicy[] => {
    return useAppSelector(state => state.currentEmployee.grantedPolicies);
};

export const useCurrentLoginMethod = (): LoginMethod | undefined => {
    return useAppSelector(state => state.currentEmployee.loginMethod);
};
