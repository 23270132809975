import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { handleError } from '@/utils/api.util';
import { Survey } from '@/domain/survey/Survey.model';
import { getSurveyById, getSurveyEmployeesIdById, getSurveys } from '@/domain/survey/Survey.service';

export const useGetSurvey = (surveyId: number): UseQueryResult<Survey> => {
    const [survey, setSurvey] = useState<Survey>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const getSurvey = useCallback(async () => {
        try {
            const survey = await getSurveyById(surveyId);
            setSurvey(survey);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [surveyId]);

    useEffect(() => {
        getSurvey().catch(handleError);
    }, [getSurvey]);

    return {
        data: survey,
        setData: setSurvey,
        isLoading,
        isError: !!error,
        error,
        refetch: () => getSurvey(),
    };
};

export const useGetSurveys = (): UseQueryResult<Survey[]> => {
    const [surveys, setSurveys] = useState<Survey[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const getSurveysCall = useCallback(async () => {
        try {
            const surveys = await getSurveys();
            setSurveys(surveys);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getSurveysCall().catch(handleError);
    }, [getSurveysCall]);

    return {
        data: surveys,
        setData: setSurveys,
        isLoading,
        isError: !!error,
        error,
        refetch: () => getSurveysCall(),
    };
};

export const useGetSurveyEmployeesIds = (surveyId: number): UseQueryResult<number[]> => {
    const [surveyEmployeesIds, setSurveyEmployeesIds] = useState<number[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const getSurveyEmployees = useCallback(async () => {
        try {
            const employees = await getSurveyEmployeesIdById(surveyId);
            setSurveyEmployeesIds(employees);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [surveyId]);

    useEffect(() => {
        getSurveyEmployees().catch(handleError);
    }, [getSurveyEmployees]);

    return {
        data: surveyEmployeesIds,
        setData: setSurveyEmployeesIds,
        isLoading,
        isError: !!error,
        error,
        refetch: () => getSurveyEmployees(),
    };
};
