import AnonymousImg from '@/assets/images/anonymous-lock-screen.png';
import logo from '@/assets/images/logo.svg';
import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployeeSurveyHeader: FC<unknown> = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Grid
            container
            direction='row'
            justifyContent='space-between'
            sx={theme => ({
                padding: theme.spacing(3),
                marginBottom: theme.spacing(5),
                [theme.breakpoints.down('lg')]: {
                    padding: '12px 16px',
                    marginBottom: 0,
                    background: theme.palette.background.default,
                },
            })}
        >
            <Grid
                item
                sx={{
                    display: { xs: 'none', lg: 'block' },
                    [theme.breakpoints.down('lg')]: {
                        width: '100%',
                    },
                }}
            >
                <Grid container direction='row' spacing={1} wrap='nowrap' alignItems='center'>
                    <Grid item>
                        <img
                            src={logo}
                            style={{
                                width: 45,
                                height: 49,
                            }}
                            alt='Anonymous'
                        />
                    </Grid>
                    <Grid item>
                        <Typography align='center' color='textPrimary'>
                            {t('general.brand.title')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                sx={{
                    [theme.breakpoints.down('lg')]: {
                        width: '100%',
                    },
                }}
            >
                <Grid
                    container
                    direction='row'
                    spacing={0}
                    wrap='nowrap'
                    alignItems='center'
                    sx={{
                        maxWidth: 324,
                        boxShadow: '0px 16px 31px rgb(0 0 0 / 3%)',
                        borderRadius: 7,
                        background: 'white',
                        padding: '12px 16px 12px 22px',
                        [theme.breakpoints.down('lg')]: {
                            maxWidth: '100%',
                            background: 'transparent',
                            boxShadow: 'none',
                            borderRadius: 7,
                            width: '100%',
                            justifyContent: 'space-between',
                            padding: 0,
                            alignItems: 'center',
                        },
                    }}
                >
                    <Grid item>
                        <Typography
                            variant='body1'
                            sx={{
                                display: { xs: 'none', md: 'block', lg: 'none' },
                            }}
                        >
                            {t('anonymous_surveys.your_answers_are_anonymous')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img
                            src={AnonymousImg}
                            style={{
                                width: 60,
                                [theme.breakpoints.down('lg')]: {
                                    width: 38,
                                    position: 'relative',
                                    top: 3,
                                },
                            }}
                            alt='Anonymous'
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
