import {
    EmployeeTimeBalance,
    EmployeeYearlyTimeBalance,
    TimesheetInsightSearch,
    TimesheetYearlySearchRequest,
} from '@/domain/timesheet-insight/TimesheetInsight.model';
import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useState } from 'react';
import { handleError } from '@/utils/api.util';
import { getEmployeeTimeBalances, getEmployeeWeeklyTimeBalances } from '@/domain/timesheet-insight/TimesheetInsight.service';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployeeTimeBalances = (
    search: TimesheetInsightSearch,
    options: {
        enabled: boolean;
    } = { enabled: true },
): UseQueryResult<EmployeeTimeBalance[]> => {
    const [employeeTimeBalances, setEmployeeTimeBalances] = useState<EmployeeTimeBalance[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeTimeBalances = useCallback(
        async (search: TimesheetInsightSearch) => {
            if (!options.enabled) {
                return;
            }
            try {
                setIsFetching(true);
                const response = await getEmployeeTimeBalances(search);
                setEmployeeTimeBalances(response);
                setError(undefined);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
                setIsFetching(false);
            }
        },
        [options.enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployeeTimeBalances(search).catch(handleError);
    }, [fetchEmployeeTimeBalances, search]);

    return {
        data: employeeTimeBalances,
        setData: setEmployeeTimeBalances,
        isLoading,
        isFetching,
        error,
        isError: !!error,
        refetch: () => fetchEmployeeTimeBalances(search),
    };
};

export const useGetEmployeeWeeklyTimeBalances = (
    search: TimesheetYearlySearchRequest,
    options: {
        enabled: boolean;
    },
): UseQueryResult<EmployeeYearlyTimeBalance[]> => {
    const [employeeWeeklyTimeBalances, setEmployeeWeeklyTimeBalances] = useState<EmployeeYearlyTimeBalance[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeWeeklyTimeBalances = useCallback(
        async (search: TimesheetYearlySearchRequest) => {
            if (!options.enabled) {
                return;
            }
            try {
                setIsFetching(true);
                const response = await getEmployeeWeeklyTimeBalances(search);
                setEmployeeWeeklyTimeBalances(response);
                setError(undefined);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
                setIsFetching(false);
            }
        },
        [options.enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployeeWeeklyTimeBalances(search).catch(handleError);
    }, [fetchEmployeeWeeklyTimeBalances, search]);

    return {
        data: employeeWeeklyTimeBalances,
        setData: setEmployeeWeeklyTimeBalances,
        isLoading,
        isFetching,
        error,
        isError: !!error,
        refetch: () => fetchEmployeeWeeklyTimeBalances(search),
    };
};
