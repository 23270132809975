import { ObjectiveSetting, ObjectiveSettingUpdateMutation } from '@/domain/objective-setting/ObjectiveSetting.model';
import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';

type ObjectiveSettingDTO = ObjectiveSetting;
type ObjectiveSettingUpdateRequestDTO = ObjectiveSettingUpdateMutation;

const updateObjectiveSetting = async (id: number, request: ObjectiveSettingUpdateRequestDTO): Promise<ObjectiveSetting> => {
    const { data } = await client.put<ObjectiveSettingDTO, AxiosResponse<ObjectiveSettingDTO>, ObjectiveSettingUpdateRequestDTO>(
        API_BASE_URL + `/objective-settings/${id}`,
        request,
    );
    return data;
};

const getObjectiveSetting = async (): Promise<ObjectiveSetting> => {
    const { data } = await client.get<ObjectiveSettingDTO>(API_BASE_URL + '/objective-settings');
    return data;
};

export const objectiveSettingApi = {
    updateObjectiveSetting,
    getObjectiveSetting,
};
