import { LeaveCorrection, LeaveCorrectionCreateMutation, LeaveCorrectionUpdateMutation } from '@/domain/leave-correction/LeaveCorrection.model';
import { leaveCorrectionApi } from '@/api/leave-correction/LeaveCorrection.api';

const createLeaveCorrection = (mutation: LeaveCorrectionCreateMutation): Promise<LeaveCorrection> => {
    return leaveCorrectionApi.createLeaveCorrection(mutation);
};

const updateLeaveCorrection = (leaveCorrectionId: number, mutation: LeaveCorrectionUpdateMutation): Promise<LeaveCorrection> => {
    return leaveCorrectionApi.updateLeaveCorrection(leaveCorrectionId, mutation);
};

const deleteLeaveCorrection = (leaveCorrectionId: number): Promise<void> => {
    return leaveCorrectionApi.deleteLeaveCorrection(leaveCorrectionId);
};
export const leaveCorrectionService = {
    createLeaveCorrection,
    updateLeaveCorrection,
    deleteLeaveCorrection,
};
