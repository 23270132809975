import { DateRangeFilterType, FilterType, TimeFilterType } from '@/components/filters-bar/FiltersBar';
import get from 'lodash.get';

export const FILTER_HEIGHT = 31;

export const getNestedValueByPath = (data: unknown, path: string): string => {
    return get(data, path);
};

const getSelectFilterValues = (filter: FilterType | undefined) => {
    if (!filter) {
        return [];
    }
    if (filter.type === 'multi-select' || filter.type === 'select') {
        return filter.value?.flatMap(({ value }) => value) ?? [];
    }
    return [];
};

/**
 * Helper to extract number values from select
 * @param filter
 * @returns
 */
export const getSelectFilterNumberValues = (filter: FilterType | undefined): number[] => {
    const isNumber = (value: string | number): value is number => typeof value === 'number';
    return getSelectFilterValues(filter).filter(isNumber);
};

/**
 * Helper to extract string values from select
 * @param filter
 * @returns
 */
export const getSelectFilterStringValues = (filter: FilterType | undefined): string[] => {
    const isString = (value: string | number): value is string => typeof value === 'string';
    return getSelectFilterValues(filter).filter(isString);
};

export const getSelectFilterStringValuesByKey = (filters: FilterType[], key: string): string[] => {
    const isString = (value: string | number): value is string => typeof value === 'string';
    return getSelectFilterValues(filters.find(f => f.key === key)).filter(isString);
};

/**
 * Helper to get filter value by key
 * @param filters
 * @param key
 * @returns
 */
export const getFilterValueIdsByKey = (filters: FilterType[], key: string): number[] => {
    return getSelectFilterNumberValues(filters.find(filter => filter.key === key));
};

export const getDateRangeFilterValueByKey = (filters: FilterType[], key: string): DateRangeFilterType['value'] => {
    const isDateFilter = (filter: FilterType): filter is DateRangeFilterType => filter.type === 'date' && filter.dateType === 'BETWEEN';
    return filters.filter(isDateFilter).find(filter => filter.key === key)?.value;
};

export const getTimeRangeFilterValueByKey = (filters: FilterType[], key: string): TimeFilterType['value'] => {
    const isTimeFilter = (filter: FilterType): filter is TimeFilterType => filter.type === 'time';
    return filters.filter(isTimeFilter).find(filter => filter.key === key)?.value;
};
