import { ButtonBase, Stack, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
    data: WordCount[];
    onWordSelected: (word: WordCount) => void;
    selectedWord?: WordCount;
};

type WordCount = {
    value: string;
    count: number;
};

const WORD_MIN_FONT_SIZE = 12;
const WORD_MAX_FONT_SIZE = 35;

export const WordCloud: FC<Props> = ({ onWordSelected, data, selectedWord = undefined }) => {
    const getTagFontSize = (count: number) => {
        const maxNumber = data.reduce((prev, current) => (prev.count > current.count ? prev : current), { count: 0 });
        const minNumber = data.reduce((prev, current) => (prev.count < current.count ? prev : current), { count: 0 });
        return count === minNumber.count ? WORD_MIN_FONT_SIZE : (count / maxNumber.count) * (WORD_MAX_FONT_SIZE - WORD_MIN_FONT_SIZE) + WORD_MIN_FONT_SIZE;
    };

    const onWordClicked = (word: WordCount) => {
        onWordSelected(word);
    };

    const isWordSelected = (tag: WordCount) => {
        return selectedWord?.value === tag.value;
    };

    return (
        <Stack direction={'row'} alignItems={'center'} textAlign='center' justifyContent={'center'} rowGap={2} columnGap={1} flexWrap='wrap'>
            {data?.map(tag => (
                <Tooltip key={tag.value} title={`${tag.value} - ${tag.count}`}>
                    <ButtonBase
                        component={Typography}
                        onClick={() => onWordClicked(tag)}
                        variant='body2'
                        sx={{
                            fontSize: getTagFontSize(tag.count),
                            color: isWordSelected(tag) ? 'primary.dark' : 'primary.main',
                        }}
                    >
                        {tag.value}
                    </ButtonBase>
                </Tooltip>
            ))}
        </Stack>
    );
};
