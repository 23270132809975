import { API_BASE_URL, client } from '@/api/common';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { getAppConfig } from '@/config/config';
import {
    SurveyTemplate,
    SurveyTemplateCreationMutation,
    SurveyTemplateQuestionCreationMutation,
    SurveyTemplateQuestionItemCreationMutation,
    SurveyTemplateUpdateMutation,
} from '@/domain/survey-template/Survey-template.model';
import { AxiosResponse } from 'axios';

type SurveyTemplateDTO = Omit<SurveyTemplate, 'createdBy'> & {
    createdBy: EmployeeDTO;
};

type SurveyTemplateQuestionItemMutationDTO = Overwrite<
    SurveyTemplateQuestionItemCreationMutation,
    {
        imageName: string;
        file: undefined;
    }
>;

type SurveyTemplateQuestionRequestDTO = Overwrite<
    SurveyTemplateQuestionCreationMutation,
    {
        items: SurveyTemplateQuestionItemMutationDTO[];
    }
>;

type SurveyTemplateMutationRequestDTO = Overwrite<
    SurveyTemplateCreationMutation,
    {
        questions: SurveyTemplateQuestionRequestDTO[];
    }
>;

const mapSurveyTemplateDTO = (survey: SurveyTemplateDTO): SurveyTemplate => ({
    ...survey,
    createdBy: mapEmployeeDTO(survey.createdBy),
});

export const getSurveyTemplates = async (): Promise<SurveyTemplate[]> => {
    const { data } = await client.get<SurveyTemplateDTO[]>(API_BASE_URL + `/survey-templates`);

    return data.map(mapSurveyTemplateDTO);
};

const buildTemplateFormData = (request: SurveyTemplateCreationMutation | SurveyTemplateUpdateMutation): FormData => {
    const dto: SurveyTemplateMutationRequestDTO = {
        ...request,
        questions: request.questions.map(question => ({
            ...question,
            items: question.items.map(item => ({
                ...item,
                imageName: item.file ? item.file.name : '',
                // If image url is empty, set it to undefined
                imageUrl: 'imageUrl' in item && !!item.imageUrl ? item.imageUrl : undefined,
                file: undefined,
            })),
        })),
    };
    const form = new FormData();

    form.append('request', new Blob([JSON.stringify(dto)], { type: getAppConfig().MIME_TYPES.JSON }));

    const files = request.questions.flatMap(question => question.items.map(item => item.file));
    files.forEach(file => {
        if (file) {
            form.append('images', file);
        }
    });

    return form;
};

export const createSurveyTemplate = async (request: SurveyTemplateCreationMutation): Promise<SurveyTemplate> => {
    const form = buildTemplateFormData(request);

    const { data } = await client.postForm<SurveyTemplateDTO, AxiosResponse<SurveyTemplateDTO>, FormData>(API_BASE_URL + `/survey-templates`, form);
    return mapSurveyTemplateDTO(data);
};

export const getSurveyTemplate = async (surveyId: number): Promise<SurveyTemplate> => {
    const { data } = await client.get<SurveyTemplateDTO>(API_BASE_URL + `/survey-templates/${surveyId}`);

    return mapSurveyTemplateDTO(data);
};

export const deleteSurveyTemplate = async (surveyId: number): Promise<number> => {
    const { data } = await client.delete<number>(API_BASE_URL + `/survey-templates/${surveyId}`);

    return data;
};

export const updateSurveyTemplate = async (surveyId: number, request: SurveyTemplateUpdateMutation): Promise<SurveyTemplate> => {
    const form = buildTemplateFormData(request);
    const { data } = await client.putForm<SurveyTemplateDTO, AxiosResponse<SurveyTemplateDTO>, FormData>(API_BASE_URL + `/survey-templates/${surveyId}`, form);
    return mapSurveyTemplateDTO(data);
};

export const cloneSurveyTemplate = async (surveyId: number): Promise<SurveyTemplate> => {
    const { data } = await client.post<SurveyTemplateDTO, AxiosResponse<SurveyTemplateDTO>>(API_BASE_URL + `/survey-templates/${surveyId}/clone`, {});

    return mapSurveyTemplateDTO(data);
};

export const surveyTemplateApi = {
    getSurveyTemplates,
    createSurveyTemplate,
    getSurveyTemplate,
    deleteSurveyTemplate,
    updateSurveyTemplate,
    cloneSurveyTemplate,
};
