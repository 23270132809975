import { Tooltip, Typography, TypographyProps } from '@mui/material';
import { Label } from '@/domain/label/Label.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelTranslation, getUserLanguage } from '@/utils/language.util';

type TranslatableLabelTypographyProps = {
    label: Label;
} & TypographyProps;

export const TranslatableLabelTypography: FC<TranslatableLabelTypographyProps> = ({ label, ...rest }) => {
    const { t } = useTranslation();
    const userLanguage = getUserLanguage();

    const translationMissing = !getLabelTranslation(label, userLanguage);

    return (
        <Tooltip
            title={t('general.translation_missing')}
            placement='top-start'
            disableHoverListener={!translationMissing}
            disableTouchListener={!translationMissing}
        >
            <Typography color={translationMissing ? 'info' : undefined} {...rest}>
                {getLabelTranslation(label, userLanguage)}
            </Typography>
        </Tooltip>
    );
};
