import { Button, CircularProgress, DialogActions, DialogContent, Paper, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FilePicker } from '@/components/file-picker/FilePicker';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';
import { useFilePicker } from '@/components/file-picker/useFilePicker';
import { getAppConfig } from '@/config/config';
import { Document, DocumentLink, DocumentUpdateMutation, EmployeeDocumentLinkCreationMutation, Folder, PreviewData } from '@/domain/document/Document.model';
import {
    createEmployeeDocuments,
    createEmployeeDocumentsLinks,
    deleteEmployeeDocument,
    getEmployeeDocumentDownloadUrl,
    updateEmployeeDocument,
} from '@/domain/document/Document.service';
import { canDeleteDocumentsInEmployeeFolder, canManageDocumentsInEmployeeFolder } from '@/domain/permission/Permission.service';
import { useGetEmployeeDocuments } from '@/hooks/document/Document.hook';
import { DocumentDetailHeader } from '@/page/document/document-header/DocumentDetailHeader';
import { DocumentLinkDialog } from '@/page/document/document-link-dialog/DocumentLinkDialog';
import { DocumentNameDialog } from '@/page/document/document-name-dialog/DocumentNameDialog';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { DocumentTable } from '@/page/document/document-table/DocumentTable';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';

const config = getAppConfig();

type Props = {
    folder: Folder;
    employeeId: number;
};

export const EmployeeDocumentDetails: FC<Props> = ({ folder, employeeId }) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<PreviewData>();
    const [addLinkDialogOpen, setAddLinkDialogOpen] = useState<boolean>(false);
    const [documentToEdit, setDocumentToEdit] = useState<Document>();
    const [isUploadDocumentDialogOpen, setIsUploadDocumentDialogOpen] = useState<boolean>(false);
    const realm = useCurrentRealm();
    const policies = useCurrentPolicies();

    const { data: documents = [], setData: setDocuments, isLoading } = useGetEmployeeDocuments(folder.id, employeeId);

    const canManageEmployeeFolder = canManageDocumentsInEmployeeFolder(realm.realmFeatures, policies, employeeId, folder?.id);
    const canDeleteEmployeeFolder = canDeleteDocumentsInEmployeeFolder(realm.realmFeatures, policies, employeeId, folder?.id);

    const onClosePreviewDialog = () => {
        setDialogOpen(false);
    };

    const handleAddLink = async (values: DocumentLink) => {
        try {
            const employeeDocumentCreationRequest: EmployeeDocumentLinkCreationMutation = {
                employeeId: employeeId,
                folderId: folder.id,
                ...values,
            };
            const documents = await createEmployeeDocumentsLinks([employeeDocumentCreationRequest]);
            setDocuments(currentDocuments => [...(currentDocuments ?? []), ...documents]);
            setAddLinkDialogOpen(false);
        } catch (e) {
            handleError(e);
        }
    };

    const handleEdit = async (values: DocumentUpdateMutation) => {
        if (!documentToEdit?.id) {
            return;
        }
        try {
            const updated = await updateEmployeeDocument(documentToEdit.id, values);
            setDocuments(documents.map(document => (document.id === updated.id ? updated : document)));
            setDocumentToEdit(undefined);
        } catch (e) {
            handleError(e);
        }
    };

    const handleCloseUploadDocumentDialog = () => {
        setIsUploadDocumentDialogOpen(false);
    };

    const handleFolderDocumentUpdate = (documents: Document[]) => {
        setDocuments(currentDocuments => [...(currentDocuments ?? []), ...documents]);
    };

    const handleDownloadClick = (documentId: number) => {
        getEmployeeDocumentDownloadUrl(documentId, 'ATTACHMENT')
            .then(documentDownloadUrl => {
                // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
                setTimeout(() => {
                    window.open(documentDownloadUrl, '_blank');
                });
            })
            .catch(handleError);
    };
    return (
        <Stack component={Paper} direction='column' flex={1} p={2} gap={2}>
            <DocumentDetailHeader
                folder={folder}
                openFilePicker={() => setIsUploadDocumentDialogOpen(true)}
                onAddLinkClick={() => setAddLinkDialogOpen(true)}
                canManageDocument={canManageEmployeeFolder}
            />
            <Stack flex='1'>
                <DocumentTable
                    canManageDocument={canManageEmployeeFolder}
                    canDeleteDocument={canDeleteEmployeeFolder}
                    folderDocuments={documents}
                    onDeleteClicked={documentId => {
                        deleteEmployeeDocument(documentId)
                            .then(() => {
                                setDocuments(documents.filter(document => document.id !== documentId));
                            })
                            .catch(handleError);
                    }}
                    onPreviewClicked={(documentData, type: string) => {
                        getEmployeeDocumentDownloadUrl(documentData.id, 'INLINE')
                            .then(documentDownloadUrl => {
                                const previewUrl =
                                    type === 'OFFICE' ? `${config.OFFICE_PREVIEW_URL}${encodeURIComponent(documentDownloadUrl)}` : `${documentDownloadUrl}`;
                                setDialogOpen(true);
                                setPreviewData({
                                    document: documentData,
                                    url: previewUrl,
                                });
                            })
                            .catch(handleError);
                    }}
                    onDownloadClicked={doc => handleDownloadClick(doc.id)}
                    onEditClicked={setDocumentToEdit}
                    isLoading={isLoading}
                />
            </Stack>

            {dialogOpen && !!previewData && (
                <DocumentPreviewDialog onClose={onClosePreviewDialog} previewData={previewData} onDownloadClick={doc => handleDownloadClick(doc.id)} />
            )}

            {addLinkDialogOpen && <DocumentLinkDialog open={true} onClose={() => setAddLinkDialogOpen(false)} onSave={handleAddLink} />}
            {documentToEdit?.documentType === 'LINK' && (
                <DocumentLinkDialog open={true} onClose={() => setDocumentToEdit(undefined)} onSave={handleEdit} document={documentToEdit} />
            )}

            {documentToEdit?.documentType === 'DOCUMENT' && (
                <DocumentNameDialog open={true} onClose={() => setDocumentToEdit(undefined)} onSave={handleEdit} document={documentToEdit} />
            )}
            {isUploadDocumentDialogOpen && !!folder?.id && (
                <UploadDocumentDialog
                    handleCloseUploadDocumentDialog={handleCloseUploadDocumentDialog}
                    folderId={folder?.id}
                    employeeId={employeeId}
                    handleCompanyFolderDocumentUpdate={handleFolderDocumentUpdate}
                />
            )}
        </Stack>
    );
};

export interface UploadDocumentDialogProps {
    handleCloseUploadDocumentDialog: () => void;
    handleCompanyFolderDocumentUpdate?: (documents: Document[]) => void;
    folderId: number;
    employeeId: number;
}

const UploadDocumentDialog: FC<UploadDocumentDialogProps> = ({ handleCloseUploadDocumentDialog, folderId, employeeId, handleCompanyFolderDocumentUpdate }) => {
    const { t } = useTranslation();
    const { files, addFiles, removeFile, updateFile } = useFilePicker();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSettingsDialogSave = () => {
        if (!files) {
            return;
        }

        // Filter out files already stored
        const temporaryFiles = files.filter(isTemporaryFile);

        setLoading(true);
        createEmployeeDocuments({
            files: temporaryFiles,
            employeeId,
            folderId,
        })
            .then(documents => {
                handleCompanyFolderDocumentUpdate?.(documents);
                handleCloseUploadDocumentDialog();
            })
            .catch(error => {
                handleError(error);
            })
            .finally(() => setLoading(false));
    };

    return (
        <DialogWrapper header={t('documents.upload_document_dialog_title')} open={true} onClose={() => handleCloseUploadDocumentDialog()}>
            <DialogContent>
                <FilePicker onFileRenamed={updateFile} onFileUploaded={addFiles} files={files} onFileRemoved={removeFile} fetchDocumentUrl={undefined} />
            </DialogContent>
            <DialogActions>
                <Button disabled={files?.length === 0 || loading} onClick={handleSettingsDialogSave} fullWidth>
                    {loading ? <CircularProgress size={20} /> : t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
