import { EditableStackSection } from '@/components/section/StackSectionComponent/EditableStackSection';
import { StackSection } from '@/components/section/StackSectionComponent/StackSection';
import { SectionActionButton, SectionField } from '@/components/section/types';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeSectionFieldProfileChange } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { getSectionAction } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import {
    createEmployeePersonalInfoPendingRequest,
    getSectionActionButton,
    mapFieldFormValuesToPersonalInfoMutation,
    updateEmployeePersonalInfo,
    updateEmployeePersonalInfoPendingRequest,
} from '@/domain/employee/Employee.service';
import { canManageEmployeePersonalInfos, canManagePendingEmployeePersonalInfos } from '@/domain/permission/Permission.service';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { useGetEmployeePersonalInfo } from '@/hooks/employee/EmployeePersonalInfo.hook';
import {
    getSectionDefinitionSchema,
    SectionDefinitionFormValues,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { useEmployeePersonalInfoSectionFields } from '@/page/employee-profile/employee-profile-info/EmployeePersonalInfoSection/EmployeePersonalInfoSection.hook';
import { PersonalInfoPageSectionSkeleton } from '@/page/employee-profile/EmployeeProfileInfoPage';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { useAppDispatch, useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getCountry } from '@/utils/countries.util';
import { getLabelTranslation } from '@/utils/language.util';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type Props = {
    employeeId: number;
    pendingFields: EmployeeSectionFieldProfileChange[];
    sectionDefinition: SectionDefinition;
};

export const EmployeePersonalInfoSection: FC<Props> = ({ employeeId, pendingFields: employeePendingFields, sectionDefinition }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [editable, setEditable] = useState(false);
    const dispatch = useAppDispatch();
    const policies = useCurrentPolicies();

    const {
        data: employeePersonalInfo,
        isLoading: isEmployeePersonalInfoLoading,
        isError: isEmployeePersonalInfoError,
        error: employeePersonalInfoError,
        refetch: refetchEmployeePersonalInfo,
    } = useGetEmployeePersonalInfo(employeeId);

    const fields = useEmployeePersonalInfoSectionFields(sectionDefinition, employeePersonalInfo);

    const personalInfoSectionSchema = getSectionDefinitionSchema({ sectionDefinition });

    const convertPendingFieldInSectionField = (): SectionField[] => {
        return fields
            ?.map(field => {
                const pendingField = employeePendingFields.find(pending => pending.sectionFieldDefinition.fieldType === field.fieldType);

                if (pendingField) {
                    const { sectionFieldDefinition: _sectionFieldDefinition, ...restPendingField } = pendingField;
                    const countryValue =
                        pendingField?.sectionFieldDefinition?.valueType === 'COUNTRY' && pendingField.stringValue
                            ? getCountry(pendingField.stringValue)
                            : undefined;

                    return {
                        ...field,
                        ...restPendingField,
                        countryValue,
                    } satisfies SectionField;
                }
            })
            .filter(field => field !== undefined);
    };
    const pendingFields: SectionField[] = convertPendingFieldInSectionField();

    /**
     * Update personal info or request approval for employee personal info
     */
    const handleSave = async (formValues: SectionDefinitionFormValues) => {
        const employeePersonalInfoUpdateRequest = mapFieldFormValuesToPersonalInfoMutation(formValues, sectionDefinition);

        const handleSuccess = () => {
            refetchEmployeePersonalInfo().catch(handleError);
            // TODO: remove this when we have fetching library in place
            dispatch({
                type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
                refetchEmployee: true,
            });

            setEditable(false);
        };

        // The action is based on the user permissions
        // If the user can approve the employee personal info, we use the endpoint to bypass the approval process
        // in other case, we use the endpoint to request approval
        try {
            if (canManageEmployeePersonalInfos(policies, employeeId)) {
                await updateEmployeePersonalInfo(employeeId, employeePersonalInfoUpdateRequest);
            } else if (canManagePendingEmployeePersonalInfos(policies, employeeId)) {
                if (pendingFields?.length) {
                    await updateEmployeePersonalInfoPendingRequest(employeeId, employeePersonalInfoUpdateRequest);
                } else {
                    await createEmployeePersonalInfoPendingRequest(employeeId, employeePersonalInfoUpdateRequest);
                }
            }
            handleSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    const canApprove = canManageEmployeePersonalInfos(policies, employeeId);
    const canRequestApproval = canManagePendingEmployeePersonalInfos(policies, employeeId);
    const hasPendingChanges = !!pendingFields?.length;

    const action = getSectionAction({ canApprove, canRequestApproval, hasPendingChanges });
    const openMutationDialogButton = {
        title: t('general.edit'),
        onClick: () => {
            setEditable(true);
        },
    };
    const seePendingButton: SectionActionButton = {
        title: t('employee.sections.see_pending_changes'),
        onClick: () => {
            navigate('/people/employee-requests');
        },
    };

    const actionButton = getSectionActionButton(action, openMutationDialogButton, seePendingButton);

    return (
        <StateHandler
            isLoading={isEmployeePersonalInfoLoading}
            isError={isEmployeePersonalInfoError}
            loadingComponent={<PersonalInfoPageSectionSkeleton />}
            error={employeePersonalInfoError}
        >
            {editable ? (
                <EditableStackSection
                    sectionTitle={getLabelTranslation(sectionDefinition.name)}
                    fields={action === 'EDIT_PENDING' ? pendingFields : fields}
                    onSave={handleSave}
                    schema={personalInfoSectionSchema}
                    onCancel={() => setEditable(false)}
                />
            ) : (
                <StackSection
                    sectionTitle={getLabelTranslation(sectionDefinition.name)}
                    fields={fields}
                    pendingFields={pendingFields}
                    actionButtons={actionButton ? [actionButton] : []}
                />
            )}
        </StateHandler>
    );
};
