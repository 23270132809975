import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useState } from 'react';
import { handleError } from '@/utils/api.util';
import { getOrganizationBilling } from '@/domain/organization_billing/OrganizationBilling.service';
import { OrganizationBilling, OrganizationBillingSearchRequest } from '@/domain/organization_billing/OrganizationBilling.model';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetOrganizationBilling = (search: OrganizationBillingSearchRequest): UseQueryResult<OrganizationBilling> => {
    const [organizationBilling, setOrganizationBilling] = useState<OrganizationBilling>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchOrganizationBilling = useCallback(async (search: OrganizationBillingSearchRequest) => {
        try {
            const organizationBilling = await getOrganizationBilling(search);
            setOrganizationBilling(organizationBilling);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchOrganizationBilling(search).catch(handleError);
    }, [fetchOrganizationBilling, search]);

    return {
        data: organizationBilling,
        setData: setOrganizationBilling,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchOrganizationBilling(search),
    };
};
