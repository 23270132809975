import { ArrowDown01Icon, ArrowUp01Icon } from 'hugeicons-react';
import { RichTreeViewProProps } from '@mui/x-tree-view-pro/RichTreeViewPro/RichTreeViewPro.types';
import { RecursiveValue } from '@/components/tree-select/TreeSelect';
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';

export type TreeViewItem = {
    id: number;
    label: string;
    children: RecursiveValue[];
    disabled?: boolean;
};

type TreeViewProps<R extends RecursiveValue, Multiple extends boolean | undefined = undefined> = RichTreeViewProProps<R, Multiple>;

export const TreeView = <R extends RecursiveValue, Multiple extends boolean | undefined = undefined>(props: TreeViewProps<R, Multiple>): JSX.Element => {
    const { items, itemsReordering, ...restProps } = props;
    if (items.length === 0) {
        return <></>;
    }

    return (
        <RichTreeViewPro
            items={items}
            getItemId={item => item.id.toString()}
            {...restProps}
            isItemDisabled={item => Boolean(item?.disabled)}
            slots={{
                expandIcon: props.slots?.expandIcon ?? ArrowDown01Icon,
                collapseIcon: props.slots?.collapseIcon ?? ArrowUp01Icon,
                ...props.slots,
            }}
            itemsReordering={itemsReordering}
            {...(itemsReordering
                ? {
                      experimentalFeatures: {
                          ...restProps.experimentalFeatures,
                          indentationAtItemLevel: true,
                          itemsReordering: true,
                      },
                  }
                : {})}
        />
    );
};
