import { FC, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetSkillCategory } from '@/hooks/skill-category/SkillCategory.hook';
import { SkillCategory, SkillCategoryCreationMutation, SkillCategoryUpdateMutation } from '@/domain/skill-category/SkillCategory.model';
import { getRealmLanguage } from '@/utils/language.util';
import { createSkillCategory, deleteSkillCategory, updateSkillCategory } from '@/domain/skill-category/SkillCategory.service';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Label } from '@/domain/label/Label.model';
import { createDefaultLabel, isEmptyLabel } from '@/domain/label/Label.service';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { Delete02Icon } from 'hugeicons-react';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { yupResolver } from '@hookform/resolvers/yup';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { Button, FormControlLabel, IconButton, Paper, Stack, Typography } from '@mui/material';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';

export const ReviewSkillCategorySettingFormPage: FC = () => {
    const params = useParams();
    const skillCategoryId = params.skillCategoryId === 'new' ? undefined : Number(params.skillCategoryId) || undefined;

    const { data: skillCategory, isError: isSkillCategoryError, isLoading: isSkillCategoryLoading, error } = useGetSkillCategory(skillCategoryId);

    return (
        <StateHandler isLoading={isSkillCategoryLoading} isError={isSkillCategoryError} error={error}>
            <ReviewSkillCategorySettingForm skillCategory={skillCategory} skillCategoryId={skillCategoryId} />
        </StateHandler>
    );
};

export type ReviewSkillCategoryForm = {
    name: Label;
    items: ReviewSkillCategoryItem[];
};

type ReviewSkillCategoryItem = {
    label: Label;
    description: Label;
    commentRequired: boolean;
};

type ReviewSkillCategorySettingFormProps = {
    skillCategory: SkillCategory | undefined;
    skillCategoryId: number | undefined;
};

const ReviewSkillCategorySettingForm: FC<ReviewSkillCategorySettingFormProps> = ({ skillCategory, skillCategoryId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());
    const mode = skillCategoryId ? 'edit' : 'create';
    const MAX_ITEMS = 6;

    const getInitialValues = (skillCategory: SkillCategory | undefined): ReviewSkillCategoryForm => {
        if (!skillCategory) {
            return {
                name: createDefaultLabel(),
                items: [
                    {
                        label: createDefaultLabel(),
                        description: createDefaultLabel(),
                        commentRequired: false,
                    },
                    {
                        label: createDefaultLabel(),
                        description: createDefaultLabel(),
                        commentRequired: false,
                    },
                ],
            };
        }
        return {
            name: skillCategory.name,
            items: skillCategory.levels.map(item => {
                return {
                    label: item.name,
                    description: item.description && !isEmptyLabel(item.description) ? item.description : createDefaultLabel(),
                    commentRequired: item.commentRequired,
                };
            }),
        };
    };

    const itemSchema = yup.object().shape({
        label: getLabelFormSchema(translationLanguage),
        description: getLabelFormSchema(),
        commentRequired: yup.boolean().required(),
    });
    const schema = yup.object().shape({
        name: getLabelFormSchema(translationLanguage),
        items: yup.array().of(itemSchema).required(),
    });

    const defaultValues = getInitialValues(skillCategory);

    const { handleSubmit, watch, control } = useForm<ReviewSkillCategoryForm>({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const items: ReviewSkillCategoryItem[] = watch('items');
    const maxScore = items?.length;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items',
    });

    const mapFormToMutation = (reviewRatingScaleForm: ReviewSkillCategoryForm): SkillCategoryCreationMutation | SkillCategoryUpdateMutation => {
        return {
            name: reviewRatingScaleForm.name,
            levels: reviewRatingScaleForm.items.map((item, index) => {
                return {
                    description: isEmptyLabel(item.description) ? undefined : item.description,
                    score: index + 1,
                    name: item.label,
                    commentRequired: item.commentRequired,
                };
            }),
        };
    };

    const onSaveEdition = async (reviewRatingScaleForm: ReviewSkillCategoryForm) => {
        if (!skillCategoryId) {
            return;
        }
        const mutation = mapFormToMutation(reviewRatingScaleForm);
        try {
            await updateSkillCategory(skillCategoryId, mutation);
            showSnackbar(t('reviews_settings_page.messages.skill_category_updated'), 'success');
            navigate('/settings/reviews/categories');
        } catch (error) {
            handleError(error);
        }
    };

    const onSaveCreation = async (reviewRatingScaleForm: ReviewSkillCategoryForm) => {
        const mutation = mapFormToMutation(reviewRatingScaleForm);
        try {
            await createSkillCategory(mutation);
            showSnackbar(t('reviews_settings_page.messages.skill_category_added'), 'success');
            navigate('/settings/reviews/categories');
        } catch (error) {
            handleError(error);
        }
    };

    const onSave = (reviewRatingScaleForm: ReviewSkillCategoryForm) => {
        if (mode === 'create') {
            onSaveCreation(reviewRatingScaleForm).catch(handleError);
        } else if (mode === 'edit') {
            onSaveEdition(reviewRatingScaleForm).catch(handleError);
        }
    };

    const handleDelete = async () => {
        if (!skillCategoryId) {
            return;
        }
        try {
            await deleteSkillCategory(skillCategoryId);
            showSnackbar(t('reviews_settings_page.messages.rating_scale_deleted'), 'success');
            navigate('/settings/reviews/categories');
        } catch (error) {
            handleError(error);
        }
    };

    const canDeleteScore = (items: ReviewSkillCategoryItem[]) => {
        return items.length > 2;
    };

    const canAddScore = (items: ReviewSkillCategoryItem[]) => {
        return items.length < MAX_ITEMS;
    };

    const getFooterActions = (): FooterActionsProps['actions'] => {
        const footerActions: FooterActionsProps['actions'] = [];

        if (mode === 'edit') {
            footerActions.push({
                name: 'delete',
                children: t('general.delete'),
                variant: 'contained',
                color: 'error',
                onClick: () => {
                    handleDelete().catch(handleError);
                },
            });
        }
        footerActions.push({
            name: 'save',
            children: t(mode === 'edit' ? 'general.update' : 'general.create'),
            variant: 'contained',
            onClick: () => {
                handleSubmit(onSave, console.error)();
            },
        });
        return footerActions;
    };

    const footerActions = getFooterActions();

    return (
        <>
            <ContentContainer>
                <Stack gap={2}>
                    <Stack gap={2} direction='column' component={Paper} p={2}>
                        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                            <Typography variant='h1'>{t('reviews_settings_page.skill_category_form.title')}</Typography>
                            <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={setTranslationLanguage} />
                        </Stack>

                        <FieldLabel
                            control={control}
                            name='name'
                            fullWidth
                            language={translationLanguage}
                            textFieldProps={{ sx: { width: 550 } }}
                            label={t('reviews_settings_page.skill_category_form.name')}
                        />
                    </Stack>
                    <Stack gap={2}>
                        {fields.map((item, index) => {
                            return (
                                <Stack key={item?.id} component={Paper} p={2} pt={1}>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Stack gap={0.5} direction={'row'} alignItems={'center'}>
                                            <Typography variant='body2bold'>{`(${index + 1})`}</Typography>
                                            {index === 0 && <Typography variant='body2bold'>{t('reviews_settings_page.skill_category_form.worst')}</Typography>}
                                            {index === maxScore - 1 && (
                                                <Typography variant='body2bold'>{t('reviews_settings_page.skill_category_form.best')}</Typography>
                                            )}
                                        </Stack>

                                        <IconButton
                                            disabled={!canDeleteScore(items)}
                                            size='medium'
                                            onClick={() => {
                                                remove(index);
                                            }}
                                            aria-label={'delete-icon'}
                                        >
                                            <Delete02Icon />
                                        </IconButton>
                                    </Stack>

                                    <Stack gap={1}>
                                        <FieldLabel
                                            control={control}
                                            name={`items.${index}.label`}
                                            fullWidth
                                            language={translationLanguage}
                                            textFieldProps={{ sx: { width: 550 } }}
                                            label={t('reviews_settings_page.skill_category_form.name')}
                                        />

                                        <FieldLabel
                                            control={control}
                                            name={`items.${index}.description`}
                                            fullWidth
                                            language={translationLanguage}
                                            textFieldProps={{
                                                sx: { width: 550 },
                                                rows: 3,
                                            }}
                                            label={t('reviews_settings_page.skill_category_form.description')}
                                        />

                                        <FormControlLabel
                                            label={t('reviews_settings_page.skill_category_form.required_comment')}
                                            labelPlacement='end'
                                            control={<FieldSwitch name={`items.${index}.commentRequired`} control={control} />}
                                        />
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>
                    <Button
                        variant='contained'
                        disabled={!canAddScore(items)}
                        onClick={() =>
                            append({
                                label: createDefaultLabel(),
                                description: createDefaultLabel(),
                                commentRequired: false,
                            })
                        }
                        sx={{ alignSelf: 'flex-start' }}
                    >
                        {t('reviews_settings_page.skill_category_form.add_score')}
                    </Button>
                </Stack>
            </ContentContainer>

            <Footer>
                <FooterActions actions={footerActions} />
            </Footer>
        </>
    );
};
