import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Label } from '@/domain/label/Label.model';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { ObjectiveCompletionStatus } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.model';
import { getRealmLanguage, UserLanguage } from '@/utils/language.util';

type CompletionStatusesSettingsDialogProps = {
    handleSave: (name: Label) => void;
    closeDialog: () => void;
    activeCompletionStatus: Omit<ObjectiveCompletionStatus, 'id'>;
};

export const ScaleDialog: FC<CompletionStatusesSettingsDialogProps> = ({ handleSave, closeDialog, activeCompletionStatus }) => {
    const { t } = useTranslation();

    const defaultLanguage = getRealmLanguage();
    const [translationLanguage, setTranslationLanguage] = useState<UserLanguage>(defaultLanguage);

    const getSchema = (translationLanguage: UserLanguage) => {
        return yup.object().shape({
            name: getLabelFormSchema(translationLanguage),
        });
    };

    const { handleSubmit, getValues, control } = useForm({
        resolver: yupResolver(getSchema(translationLanguage)),
        defaultValues: {
            name: activeCompletionStatus?.name ?? createDefaultLabel(),
        },
    });

    const onSave = () => {
        const { name } = getValues();
        handleSave(name);
    };

    const header = (
        <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1}>
            <Typography variant={'body1bold'}>
                {activeCompletionStatus ? t('planning_setting_page.edit_position') : t('planning_setting_page.add_position')}
            </Typography>
            <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={setTranslationLanguage} />
        </Stack>
    );

    return (
        <DialogWrapper onClose={closeDialog} open={true} header={header}>
            <DialogContent>
                <Stack>
                    <FieldLabel control={control} name='name' fullWidth language={translationLanguage} label={t('planning_setting_page.position_name')} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(onSave, console.error)} variant='contained' fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
