import { API_BASE_URL, client } from '../common';
import { AxiosResponse } from 'axios';
import { LongLeave, LongLeaveCreateMutation, LongLeaveSearch, LongLeaveUpdateMutation } from '@/domain/long-leave/LongLeave.model';

type LongLeaveSearchRequestDTO = LongLeaveSearch;
type LongLeaveCreateRequestDTO = LongLeaveCreateMutation;
type LongLeaveUpdateRequestDTO = LongLeaveUpdateMutation;

const searchLongLeaves = async (request: LongLeaveSearch): Promise<LongLeave[]> => {
    return (await client.post<LongLeave[], AxiosResponse<LongLeave[]>, LongLeaveSearchRequestDTO>(API_BASE_URL + '/long-leaves/search', request)).data;
};

const createLongLeave = async (request: LongLeaveCreateRequestDTO): Promise<LongLeave> => {
    return (await client.post<LongLeave, AxiosResponse<LongLeave>, LongLeaveCreateRequestDTO>(API_BASE_URL + `/long-leaves`, request)).data;
};

const updateLongLeave = async (id: number, request: LongLeaveUpdateRequestDTO): Promise<LongLeave> => {
    return (await client.put<LongLeave, AxiosResponse<LongLeave>, LongLeaveUpdateRequestDTO>(API_BASE_URL + `/long-leaves/${id}`, request)).data;
};

const deleteLongLeave = async (id: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/long-leaves/${id}`);
};

export const longLeaveApi = {
    searchLongLeaves,
    createLongLeave,
    updateLongLeave,
    deleteLongLeave,
};
