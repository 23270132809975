import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { Announcement, EditAnnouncementMutation } from '@/domain/announcement/Announcement.model';
import { canManageAnnouncements } from '@/domain/permission/Permission.service';
import { useCreateAnnouncement, useEditAnnouncement, useGetAnnouncementsAsEditor } from '@/hooks/annoucement/Announcement.hook';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { useCurrentPolicies } from '@/stores/store';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { deleteAnnouncement } from '@/domain/announcement/Announcement.service';
import { Department } from '@/domain/department/Department.model';
import { Location } from '@/domain/location/Location.model';
import { AnnouncementDialog } from '@/page/announcement/AnnouncementDialog';
import {
    AnnouncementFormValues,
    mapAnnouncementFormValuesToCreateMutation,
    mapAnnouncementFormValuesToEditMutation,
} from '@/page/announcement/AnnouncementForm.schema';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { SubmitHandler } from 'react-hook-form';

export const AnnouncementSettingsPage: FC = () => {
    const { t } = useTranslation();
    const grantedPolicies = useCurrentPolicies();
    const [openCreateAnnouncementDialog, setOpenCreateAnnouncementDialog] = useState<boolean>(false);
    const [announcementToUpdate, setAnnouncementToUpdate] = useState<Announcement>();
    const [announcementToDelete, setAnnouncementToDelete] = useState<Announcement>();

    const canPostAnnouncement = canManageAnnouncements(grantedPolicies);
    const { data: announcements, setData: setAnnouncements, refetch: refetchAnnouncements } = useGetAnnouncementsAsEditor(canPostAnnouncement);

    const { mutate: createAnnouncement, isPending: isCreatePending } = useCreateAnnouncement();
    const { mutate: editAnnouncement, isPending: isEditPending } = useEditAnnouncement();

    // CREATE
    const handleCreateAnnouncement: SubmitHandler<AnnouncementFormValues> = async data => {
        try {
            const mutationVariables = mapAnnouncementFormValuesToCreateMutation(data);
            await createAnnouncement(mutationVariables);
            showSnackbar(t('announcement.create_dialog.success'), 'success');
            setOpenCreateAnnouncementDialog(false);
            refetchAnnouncements();
        } catch (error) {
            handleError(error);
        }
    };

    const closeConfirmationDialog = () => {
        setAnnouncementToDelete(undefined);
    };
    const handleDeleteAnnouncement = async (announcementId: number) => {
        try {
            await deleteAnnouncement(announcementId);
            closeConfirmationDialog();
            setAnnouncements(announcements?.filter(a => a.id !== announcementId));
        } catch (error) {
            handleError(error);
        }
    };

    // EDIT
    const handleEditAnnouncement = async (announcement: Announcement, data: AnnouncementFormValues) => {
        const announcementId = announcement.id;
        try {
            const mutationVariables: EditAnnouncementMutation = mapAnnouncementFormValuesToEditMutation(announcementId, data);
            const updatedAnnouncement = await editAnnouncement(mutationVariables);
            setAnnouncementToUpdate(undefined);
            setAnnouncements(announcements?.map(a => (a.id === announcementId ? updatedAnnouncement : a)));
        } catch (error) {
            handleError(error);
        }
    };

    const getItems = (data: Announcement | undefined): BasicMenuItem[] => [
        {
            title: t('general.edit'),
            onClick: () => setAnnouncementToUpdate(data),
        },
        {
            title: t('general.delete'),
            onClick: () => setAnnouncementToDelete(data),
        },
    ];

    const cellActionRenderer = ({ data }: ICellRendererParams<Announcement>) => {
        return <BasicMenu items={getItems(data)} />;
    };

    const options: ConfigType<Announcement> = {
        header: {
            primaryButtonCaption: t('announcement_settings_page.create_new'),
            primaryButtonAction: () => setOpenCreateAnnouncementDialog(true),
        },
        type: 'table',
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: ({ data, event }) => {
                    if (!event?.defaultPrevented) {
                        setAnnouncementToUpdate(data);
                    }
                },
            },
            columns: [
                {
                    field: 'title',
                    headerName: t('announcement_settings_page.columns.title'),
                    cellStyle: { minWidth: '200px' },
                },
                {
                    field: 'createdBy.displayName',
                    headerName: t('announcement_settings_page.columns.created_by'),
                },
                {
                    field: 'startDate',
                    headerName: t('announcement_settings_page.columns.start_date'),
                    type: 'date',
                },
                {
                    field: 'endDate',
                    headerName: t('announcement_settings_page.columns.end_date'),
                    type: 'date',
                },
                {
                    field: 'departments',
                    headerName: t('announcement_settings_page.columns.departments'),
                    valueFormatter: ({ value }: { value: Department[] }) => value?.map(d => getLabelTranslation(d?.name)).join(', '),
                },
                {
                    field: 'locations',
                    headerName: t('announcement_settings_page.columns.locations'),
                    valueFormatter: ({ value }: { value: Location[] }) => value?.map(l => l?.name).join(', '),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ] satisfies ColDef<Announcement>[],
        },
    };

    return (
        <>
            <CompanySettingsLayout options={options} data={announcements} isSearchAvailable={false} />
            {openCreateAnnouncementDialog && (
                <AnnouncementDialog
                    open={openCreateAnnouncementDialog}
                    onClose={() => setOpenCreateAnnouncementDialog(false)}
                    onSubmit={handleCreateAnnouncement}
                    isPending={isCreatePending}
                />
            )}
            {!!announcementToUpdate && (
                <AnnouncementDialog
                    open={true}
                    defaultValue={announcementToUpdate}
                    onClose={() => setAnnouncementToUpdate(undefined)}
                    onSubmit={data => handleEditAnnouncement(announcementToUpdate, data)}
                    isPending={isEditPending}
                />
            )}
            {!!announcementToDelete && (
                <ConfirmDialog
                    open={true}
                    title={t('home_page.announcements.delete_dialog.title')}
                    content={t('home_page.announcements.delete_dialog.confirmation_text', { announcementTitle: announcementToDelete.title })}
                    onConfirm={() => handleDeleteAnnouncement(announcementToDelete.id)}
                    onClose={() => closeConfirmationDialog()}
                />
            )}
        </>
    );
};
