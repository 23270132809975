import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { SectionActionButton, SectionField } from '@/components/section/types';
import { EmployeeSectionRow } from '@/domain/employee-section/EmployeeSection.model';
import { convertEmployeeSectionFieldsToSectionFields } from '@/domain/employee/Employee.service';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import {
    getSectionDefinitionSchema,
    SectionDefinitionFormValues,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type EmployeeSectionRowFormValues = Omit<EmployeeSectionRow, 'id'> & {
    id?: number;
};

export const EmployeeCustomSectionRowDialog: FC<{
    sectionId: number;
    sectionDefinition: SectionDefinition;
    onSave(values: SectionDefinitionFormValues): void;
    actionButton?: SectionActionButton;
    currentSectionRow: EmployeeSectionRowFormValues;
    onClose(): void;
}> = ({ sectionId, sectionDefinition, onSave, onClose, currentSectionRow }) => {
    const { t } = useTranslation();
    const fields = convertEmployeeSectionFieldsToSectionFields(sectionId, sectionDefinition.fields, currentSectionRow?.fields);

    const schema = getSectionDefinitionSchema({ sectionDefinition });

    const formMethods = useForm<SectionDefinitionFormValues>({
        resolver: yupResolver(schema),
    });

    const { handleSubmit } = formMethods;

    const handleSave = () => {
        handleSubmit(values => onSave(values), console.error)();
    };

    return (
        <DialogWrapper header={getLabelTranslation(sectionDefinition.name)} open onClose={onClose}>
            <Stack gap={1.5} component={DialogContent}>
                {fields?.map((field: SectionField) => (
                    <Stack key={field.title} gap={0.5}>
                        <Typography noWrap>
                            {field.title}
                            {field.required ? '*' : ''}
                        </Typography>
                        <FormProvider {...formMethods}>
                            <EditableSectionFieldComponent field={field} />
                        </FormProvider>
                    </Stack>
                ))}
            </Stack>
            <DialogActions>
                <Button fullWidth onClick={handleSave}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
