import { PermissionFilterType } from '@/domain/permission/Permission.model';
import * as yup from 'yup';
import { PermissionGroupType } from '@/domain/permission-group/PermissionGroup.model';

const schema = yup.object().shape({
    groupName: yup.string().trim().required(),
    description: yup.string().trim().required(),
    groupType: yup
        .string()
        .oneOf(Object.values(PermissionGroupType) as PermissionGroupType[])
        .required(),
    filterType: yup
        .string()
        .oneOf(Object.values(PermissionFilterType) as PermissionFilterType[])
        .required(),
    targetMembersFilterType: yup
        .string()
        .oneOf(Object.values(PermissionFilterType) as PermissionFilterType[])
        .required(),
    groupMembers: yup.array().required(),
    groupPolicies: yup.array().required(),
    targetMembers: yup.array().required(),
    notificationsEnabled: yup.boolean().default(true),
    groupMemberConditions: yup.array().required(),
    targetMemberConditions: yup.array().required(),
});

export const permissionSettingFormSchema = schema;

export type PermissionFormValues = yup.InferType<typeof schema>;
