import {
    TimesheetAdjustment,
    TimesheetAdjustmentAction,
    TimesheetAdjustmentBulkMutation,
    TimesheetAdjustmentCreateMutation,
    TimesheetAdjustmentUpdateMutation,
} from './TimesheetAdjustment.model';
import { timesheetAdjustmentAPI } from '@/api/timesheet-adjustment/TimesheetAdjustment.api';

export const createTimesheetAdjustment = (request: TimesheetAdjustmentCreateMutation): Promise<TimesheetAdjustment> => {
    return timesheetAdjustmentAPI.timesheetAdjustmentRequest(request);
};

export const updateTimesheetAdjustment = (request: TimesheetAdjustmentUpdateMutation, id: number): Promise<TimesheetAdjustment> => {
    return timesheetAdjustmentAPI.timesheetAdjustmentUpdateRequest(request, id);
};

export const deleteTimesheetAdjustment = (id: number): Promise<void> => {
    return timesheetAdjustmentAPI.deleteTimesheetAdjustment(id);
};

export const getTimesheetAdjustment = (id: number): Promise<TimesheetAdjustment> => {
    return timesheetAdjustmentAPI.getTimesheetAdjustment(id);
};

export const bulkTimesheetAdjustmentCreationRequest = (requests: TimesheetAdjustmentBulkMutation[]): Promise<TimesheetAdjustment[]> => {
    return timesheetAdjustmentAPI.bulkTimesheetAdjustmentCreationRequest(requests);
};

export const getTranslationKeysForTimesheetAdjustmentAction = (action: TimesheetAdjustmentAction): string => {
    switch (action) {
        case TimesheetAdjustmentAction.ADD:
            return 'general.add';
        case TimesheetAdjustmentAction.REMOVE:
            return 'general.remove';
        case TimesheetAdjustmentAction.TRANSFER:
            return 'general.transfer';
    }
};
