import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { Realm, RealmFeaturesType } from '@/domain/realm/Realm.model';
import { Stack, Tooltip, Typography } from '@mui/material';
import { StatusIcon } from '@/assets/icons/Icons';
import { FC, Fragment } from 'react';
import { Timesheet, TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { StackProps } from '@mui/material/Stack/Stack';
import {
    getAutomaticCorrectionText,
    getColor,
    getText,
    getTextMissingClockinout as getMissingClockInOutText,
} from '@/page/timesheet/clock-in-out-status-iconized-info-tooltip/ClockInOutStatusIconizedInfoTooltip.utils';

type ClockInOutStatusIconizedInfoTooltipProps = {
    timesheet: Timesheet;
    realm: Realm;
    valueToDisplay: string;
} & StackProps;

export const ClockInOutStatusIconizedInfoTooltip: FC<ClockInOutStatusIconizedInfoTooltipProps> = ({ timesheet, valueToDisplay, realm, ...rest }) => {
    const isRealmFeatureEnabled = hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.CLOCK_IN_OUT);
    const isTimesheet = timesheet.type === TimesheetType.TIMESHEET || timesheet.type === TimesheetType.SHIFT_TIMESHEET;

    const canDisplayTimesheetClockIn = isRealmFeatureEnabled && isTimesheet;

    const color = getColor(timesheet);
    const text = getText(timesheet);
    const nextLineText = getMissingClockInOutText(timesheet);

    const systemCorrection = timesheet.systemCorrection ? `(${timesheet.systemCorrection})` : '';

    return (
        <Tooltip
            placement='top'
            title={
                canDisplayTimesheetClockIn ? (
                    <span>
                        {text}
                        {!!nextLineText && (
                            <>
                                <br />
                                {nextLineText}
                            </>
                        )}
                        {text.length > 0 && <br />}
                        {timesheet.automaticCorrections.map((automaticCorrection, index) => (
                            <Fragment key={index}>
                                {getAutomaticCorrectionText(automaticCorrection, timesheet)}
                                {index !== timesheet.automaticCorrections.length - 1 && <br />}
                            </Fragment>
                        ))}
                    </span>
                ) : (
                    valueToDisplay
                )
            }
        >
            <Stack alignItems='center' direction='row' width={'100%'} {...rest}>
                {canDisplayTimesheetClockIn && isTimesheet && <StatusIcon color={color} fontSize='small' viewBox='0 0 16 16' />}
                <Typography variant='body1' noWrap>
                    {valueToDisplay}
                    {systemCorrection && (
                        <Typography color='secondary.main' component='span'>
                            {systemCorrection}
                        </Typography>
                    )}
                </Typography>
            </Stack>
        </Tooltip>
    );
};
