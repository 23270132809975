import { FC } from 'react';
import { DateFilter as DateFilterType, DateFilterRule, DateRangeType } from '@/components/filters-bar/FiltersBar';
import { useTranslation } from 'react-i18next';
import { DatePickerProps, DatePickerWrapper } from '@/components/date-picker/DatePickerWrapper';
import { FormControlLabel, List, ListItem, Radio, RadioGroup, Stack } from '@mui/material';
import { getDateRange } from '@/components/date-range-picker/DateRangePicker.util';
import { DateRangeForm } from '@/components/date-range-picker/DateRangeForm';
import { getCurrentLocalDate } from '@/utils/datetime.util';

export const DateFilter: FC<{ filter: DateFilterType; onFilterUpdated: (filter: DateFilterType) => void }> = ({ filter, onFilterUpdated }) => {
    const { t } = useTranslation();

    const getLabel = (rule: DateFilterRule) => {
        switch (rule) {
            case 'WITHIN_THE_LAST':
                return t('general.since');
            case 'MORE_THAN':
                return t('general.before');
            case 'BETWEEN':
                return t('general.range');
            default:
                return '';
        }
    };

    const availableRulesOptions: {
        type: DateFilterType['dateType'];
        label: string;
    }[] =
        filter.availableRules?.map(rule => ({
            type: rule,
            label: getLabel(rule),
        })) ?? [];

    const handleDateRangeChange = ([start, end]: [LocalDate, LocalDate]) => {
        if (filter.dateType !== 'BETWEEN') {
            return;
        }
        const updatedFilter = { ...filter, value: [start, end] as DateRangeType };
        onFilterUpdated(updatedFilter);
    };

    const handleDateChange: DatePickerProps['onChange'] = (value, context) => {
        if (filter.dateType !== 'WITHIN_THE_LAST' && filter.dateType !== 'MORE_THAN') {
            return;
        }
        if (!context.validationError) {
            if (filter.clearable === false && !value) {
                //dont allow to have no value in the filter in case it is not clearable
                return;
            }
            const updatedFilter = { ...filter, value: value ?? undefined };
            onFilterUpdated(updatedFilter);
        }
    };

    const handleDateTypeChange = (dateType: DateFilterType['dateType']) => {
        if (filter.dateType === dateType) {
            return;
        }

        switch (dateType) {
            case 'MORE_THAN':
            case 'WITHIN_THE_LAST':
                if (filter.dateType === 'BETWEEN') {
                    onFilterUpdated({ ...filter, dateType, value: filter.value?.[0] ?? getCurrentLocalDate() });
                } else {
                    // case filter.dateType is undefined or not 'BETWEEN'
                    onFilterUpdated({ ...filter, dateType });
                }
                break;
            case 'BETWEEN':
                if (filter.dateType !== 'BETWEEN') {
                    const range = getDateRange('MONTH', filter.value ?? getCurrentLocalDate());
                    onFilterUpdated({ ...filter, dateType, value: range });
                } else {
                    // case filter.dateType is undefined
                    onFilterUpdated({ ...filter, dateType });
                }
                break;
            default:
                break;
        }
    };

    const hasOnlyOneAvailableRule = availableRulesOptions?.length === 1;

    return (
        <RadioGroup value={filter.dateType ?? ''} sx={{ p: 1 }}>
            <List disablePadding>
                {availableRulesOptions.map(option => (
                    <ListItem key={option.type} sx={{ py: 0, px: 0.5 }}>
                        <Stack flex='1' px={1}>
                            {!hasOnlyOneAvailableRule && (
                                <FormControlLabel
                                    value={option.type}
                                    control={
                                        <Radio
                                            edge='start'
                                            disableRipple
                                            onChange={e => {
                                                handleDateTypeChange(e.target.value as DateFilterType['dateType']);
                                            }}
                                        />
                                    }
                                    labelPlacement='end'
                                    label={option.label}
                                    sx={{ m: 0 }}
                                />
                            )}
                            {/*CASE RANGE*/}
                            {filter.dateType === option.type && filter.dateType === 'BETWEEN' && (
                                <Stack gap={1}>
                                    <DateRangeForm
                                        dates={filter.value ?? [getCurrentLocalDate(), getCurrentLocalDate()]}
                                        onDatesChanged={handleDateRangeChange}
                                    />
                                </Stack>
                            )}

                            {/*CASE BEFORE OR AFTER*/}
                            {filter.dateType === option.type && (filter.dateType === 'WITHIN_THE_LAST' || filter.dateType === 'MORE_THAN') && (
                                <DatePickerWrapper autoFocus value={filter.value} onChange={handleDateChange} />
                            )}
                        </Stack>
                    </ListItem>
                ))}
            </List>
        </RadioGroup>
    );
};
