import { createSlice } from '@reduxjs/toolkit';
import { PlanningActions, PlanningActionType } from './planningActions';
import { ShiftTemplate } from '@/domain/shift-template/ShiftTemplate.model';

export type PlanningState = {
    shiftTemplateList: ShiftTemplate[];
};

const initialState: PlanningState = {
    shiftTemplateList: [],
};

/**
 * @deprecated
 */
function oldReducer(state = initialState, action: PlanningActions): PlanningState {
    switch (action.type) {
        case PlanningActionType.SHIFT_TEMPLATES_LOADED:
            return {
                ...state,
                shiftTemplateList: action.shiftTemplateList,
            };
        case PlanningActionType.SHIFT_TEMPLATE_DELETED: {
            const updatedShiftTemplateList = action.shiftTemplateList;
            const shiftTemplateFindIndex = updatedShiftTemplateList.findIndex(template => template.id === action.shiftTemplateId);
            updatedShiftTemplateList.splice(shiftTemplateFindIndex, 1);
            return {
                ...state,
                shiftTemplateList: [...updatedShiftTemplateList],
            };
        }
        default:
            return state;
    }
}

export const planningSlice = createSlice({
    name: 'policies',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addDefaultCase((state, action) => {
            return oldReducer(state, action as PlanningActions);
        });
    },
});

export const planningReducer = planningSlice.reducer;
