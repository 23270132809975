import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { InputNumber, InputNumberProps } from '@/components/form/field-number/InputNumber';

type InputNumberRootProps = Pick<
    InputNumberProps,
    | 'placeholder'
    | 'size'
    | 'autoFocus'
    | 'locale'
    | 'currency'
    | 'precision'
    | 'fixedPrecision'
    | 'prefix'
    | 'suffix'
    | 'min'
    | 'max'
    | 'step'
    | 'bigStep'
    | 'disabled'
    | 'fullWidth'
    | 'sx'
>;

export type FieldNumberProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    inputNumberProps?: RemoveFromType<RemoveFromType<InputNumberProps, InputNumberRootProps>, ControllerRenderProps>;
    onChange?: (value: number | null) => void;
} & InputNumberRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldNumber = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldNumberProps<TFieldValues, TName>,
): JSX.Element => {
    const {
        placeholder,
        autoFocus,
        prefix,
        suffix,
        locale,
        currency,
        precision,
        fixedPrecision,
        min,
        max,
        step,
        bigStep,
        disabled = false,
        fullWidth = false,
        sx,
        inputNumberProps,
        onChange,
        ...controllerProps
    } = props;

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState: { error } }) => (
                <InputNumber
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    prefix={prefix}
                    suffix={suffix}
                    locale={locale}
                    currency={currency}
                    precision={precision}
                    fixedPrecision={fixedPrecision}
                    min={min}
                    max={max}
                    step={step}
                    bigStep={bigStep}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    InputProps={{
                        inputProps: {
                            'aria-label': controllerProps.name,
                        },
                    }}
                    {...inputNumberProps}
                    {...field}
                    value={field.value}
                    onChange={value => {
                        field.onChange(value);
                        onChange?.(value);
                    }}
                    error={!!error}
                    helperText={error?.message}
                    sx={sx}
                />
            )}
        />
    );
};
