import { Button, ButtonProps, useTheme } from '@mui/material';
import { FC } from 'react';
import { Add01Icon, MessageEdit02Icon, Tick02Icon } from 'hugeicons-react';
import { StarsIcons } from '@/components/stars-icons/StarsIcons';

type ReviewedSkillButtonProps = {
    skillScore: number | undefined;
    skillName: string;
    handleSkillClicked: () => void;
};

export const ReviewedSkillButton: FC<ReviewedSkillButtonProps> = ({ skillScore, skillName, handleSkillClicked }) => {
    const { palette } = useTheme();
    return (
        <Button
            startIcon={<Tick02Icon />}
            endIcon={<StarsIcons starsNumber={skillScore} htmlColor={palette.primary.main} />}
            onClick={() => handleSkillClicked()}
            variant='text'
            sx={{
                backgroundColor: palette.primary.light,
                width: 'fit-content',
                px: 1,
                maxWidth: '100%',
                wordBreak: 'break-word',
            }}
        >
            {skillName}
        </Button>
    );
};

type NotReviewedSkillButtonProps = {
    skillName: string;
    handleSkillClicked: () => void;
} & ButtonProps;

export const NotReviewedSkillButton: FC<NotReviewedSkillButtonProps> = ({ skillName, handleSkillClicked, ...rest }) => {
    const { palette } = useTheme();

    return (
        <Button
            variant='text'
            startIcon={<Add01Icon />}
            onClick={() => handleSkillClicked()}
            sx={{
                backgroundColor: palette.grey['100'],
                color: palette.text.primary,
                width: 'fit-content',
                px: 1,
                maxWidth: '100%',
                wordBreak: 'break-word',
            }}
            {...rest}
        >
            {skillName}
        </Button>
    );
};

type SummaryMeetingSkillButtonProps = {
    skillName: string;
    handleSkillClicked: () => void;
};

export const SummaryMeetingSkillButton: FC<SummaryMeetingSkillButtonProps> = ({ skillName, handleSkillClicked }) => {
    const { palette } = useTheme();

    return (
        <Button
            variant='text'
            onClick={() => handleSkillClicked()}
            startIcon={<MessageEdit02Icon />}
            sx={{
                backgroundColor: palette.warning.light,
                color: palette.warning.dark,
                width: 'fit-content',
                '&:hover': {
                    backgroundColor: palette.warning.main,
                    color: palette.common.white,
                    stroke: palette.common.white,
                },
                stroke: palette.warning.dark,
                px: 1,
                maxWidth: '100%',
                wordBreak: 'break-word',
            }}
        >
            {skillName}
        </Button>
    );
};

type DisabledSkillButtonProps = {
    skillName: string;
    handleSkillClicked: () => void;
};

export const DisabledSkillButton: FC<DisabledSkillButtonProps> = ({ skillName, handleSkillClicked }) => {
    const { palette } = useTheme();

    return (
        <Button
            disabled
            onClick={() => handleSkillClicked()}
            variant='text'
            startIcon={<Add01Icon />}
            sx={{
                backgroundColor: palette.grey['100'],
                color: palette.action.disabled,
                opacity: 0.3,
                width: 'fit-content',
                px: 1,
                maxWidth: '100%',
                wordBreak: 'break-word',
            }}
        >
            {skillName}
        </Button>
    );
};
