import { FC, useState } from 'react';
import { EmployeeReviewFeedbackSummarySkillFormType } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { SkillState } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSkill';
import { Stack, Typography } from '@mui/material';
import { EmployeeReviewSkillFeedbackFromPreparation } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSkillFeedbackFromPreparation';
import { EmployeeReviewSkillReviewDialog } from '@/page/review/employee-review-skill-review-dialog/EmployeeReviewSkillReviewDialog';
import { useTranslation } from 'react-i18next';
import { hasAtLeastOneFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { DisabledSkillButton, NotReviewedSkillButton, ReviewedSkillButton, SummaryMeetingSkillButton } from '@/domain-ui/skill/SkillButton';

type EmployeeReviewFeedbackManagerSkillFormProps = {
    skillState: SkillState;
    feedbackSkillItem: EmployeeReviewFeedbackSummarySkillFormType;
    handleSkillChanged: (skillAndCategory: EmployeeReviewFeedbackSummarySkillFormType) => void;
    disabled: boolean;
};

export const EmployeeReviewFeedbackSummarySkill: FC<EmployeeReviewFeedbackManagerSkillFormProps> = ({
    skillState,
    feedbackSkillItem,
    handleSkillChanged,
    disabled,
}) => {
    const { t } = useTranslation();
    const [activeSkill, setActiveSkill] = useState<EmployeeReviewFeedbackSummarySkillFormType>();
    const handleSkillClicked = (skillAndCategory: EmployeeReviewFeedbackSummarySkillFormType) => {
        setActiveSkill(skillAndCategory);
    };

    const onClose = () => {
        setActiveSkill(undefined);
    };

    const renderSkillItem = (skillState: SkillState, feedbackSkillItem: EmployeeReviewFeedbackSummarySkillFormType) => {
        switch (skillState) {
            case 'reviewed':
                return (
                    <ReviewedSkillButton
                        skillScore={feedbackSkillItem.score}
                        skillName={feedbackSkillItem.skill.name ?? ''}
                        handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)}
                    />
                );
            case 'not_reviewed':
                return (
                    <NotReviewedSkillButton skillName={feedbackSkillItem.skill.name ?? ''} handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)} />
                );
            case 'summary_meeting':
                return (
                    <SummaryMeetingSkillButton
                        skillName={feedbackSkillItem.skill.name ?? ''}
                        handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)}
                    />
                );
            case 'disabled':
                return <DisabledSkillButton skillName={feedbackSkillItem.skill.name ?? ''} handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)} />;
        }
    };

    return (
        <>
            {renderSkillItem(skillState, feedbackSkillItem)}
            {activeSkill && (
                <EmployeeReviewSkillReviewDialog skillAndCategory={activeSkill} onConfirm={handleSkillChanged} onClose={onClose} disabled={disabled}>
                    {hasAtLeastOneFeedback(feedbackSkillItem) && (
                        <Stack>
                            <Stack gap={1} pb={2}>
                                <Typography variant='body1bold'>{t('reviews.summary.preparation_subtitle')}</Typography>

                                <Stack gap={1} pb={1}>
                                    {feedbackSkillItem.selfFeedbackQuestion && (
                                        <EmployeeReviewSkillFeedbackFromPreparation
                                            employeeReviewFeedbackForm={feedbackSkillItem.selfFeedbackQuestion}
                                            skillAndCategory={activeSkill}
                                        />
                                    )}

                                    {feedbackSkillItem.managerFeedbackQuestion?.map((managerFeedbackSkillQuestion, index) => (
                                        <EmployeeReviewSkillFeedbackFromPreparation
                                            key={index}
                                            employeeReviewFeedbackForm={managerFeedbackSkillQuestion}
                                            skillAndCategory={activeSkill}
                                        />
                                    ))}
                                    {feedbackSkillItem.upwardFeedbackQuestion?.map((upwardFeedbackSkillQuestion, index) => (
                                        <EmployeeReviewSkillFeedbackFromPreparation
                                            key={index}
                                            employeeReviewFeedbackForm={upwardFeedbackSkillQuestion}
                                            skillAndCategory={activeSkill}
                                        />
                                    ))}
                                    {feedbackSkillItem.peerFeedbackQuestion?.map((peerFeedbackSkillQuestion, index) => (
                                        <EmployeeReviewSkillFeedbackFromPreparation
                                            key={index}
                                            employeeReviewFeedbackForm={peerFeedbackSkillQuestion}
                                            skillAndCategory={activeSkill}
                                        />
                                    ))}
                                </Stack>
                            </Stack>
                            <Typography variant='body1bold'>{t('reviews.summary.summary_subtitle')}</Typography>
                        </Stack>
                    )}
                </EmployeeReviewSkillReviewDialog>
            )}
        </>
    );
};
