import * as yup from 'yup';
import {
    surveySetupEndTimeValidation,
    surveySetupRecipientIdsValidation,
    surveySetupStartDateValidation,
    surveySetupStartTimeValidation,
} from '@/page/survey/survey-setup/SurveySetupForm.schema';
import { getNull } from '@/utils/object.util';
import { getLocalDateTestConfig, getTodayDate, setTime } from '@/utils/datetime.util';
import i18next from 'i18next';

export const getEditSurveySetupFormSchema = (isSurveyStartDateEditable: boolean) => {
    return yup.object().shape({
        sendNotificationNow: yup.boolean().when({
            is: () => isSurveyStartDateEditable,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable().default(getNull()),
        }),
        startDate: isSurveyStartDateEditable ? surveySetupStartDateValidation : yup.string<LocalDate>().nullable(),
        endDate: yup
            .string<LocalDate>()
            .required()
            .test(getLocalDateTestConfig())
            .test({
                message: i18next.t('edit_survey_form_dialog.end_date_after_start_date'),
                test: (endDate, context) => {
                    const startDateTime =
                        !context.parent.sendNotificationNow && context.parent.startDate && context.parent.startTime
                            ? setTime(context.parent.startDate, context.parent.startTime)
                            : getTodayDate();
                    const endDateTime = endDate && context.parent.endTime ? setTime(endDate, context.parent.endTime) : undefined;
                    return startDateTime && endDateTime && startDateTime < endDateTime;
                },
            }),
        startTime: isSurveyStartDateEditable ? surveySetupStartTimeValidation : yup.string<LocalTime>().nullable(),
        endTime: surveySetupEndTimeValidation,
        recipientIds: surveySetupRecipientIdsValidation,
    });
};

type getEditSurveySetupFormSchemaType = ReturnType<typeof getEditSurveySetupFormSchema>;
export type EditSurveySetupFormSchemaType = yup.InferType<getEditSurveySetupFormSchemaType>;
