import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    enabled?: boolean;
};

export const EnabledStatus: FC<Props> = ({ enabled }) => {
    const { t } = useTranslation();
    return enabled && <Chip size='small' color='primary' label={t('general.enabled')} />;
};
