/**
 * Checks if two arrays contain the same elements.
 *
 */
export function equals<T>(arr1: T[], arr2: T[]): boolean {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    return arr1?.every(item => set2.has(item)) && arr2?.every(item => set1.has(item));
}

/**
 * Sorts the array passed in parameter by the order property descending.
 * @deprecated Use the sort function from lodash instead.
 * @param array
 * @returns the array sorted
 */
export function sort<T extends { order: number }>(array: T[]): T[] {
    return array.sort((a, b) => a.order - b.order);
}

/**
 * Create a new array with elements from the parameter sorted by order property descending.
 * @param array
 * @returns a copy of the array sorted
 */
export function toSorted<T extends { order: number }>(array: T[]): T[] {
    return [...array].sort((a, b) => a.order - b.order);
}

export function reorder<T extends { order: number }>(array: T[]): T[] {
    return array.map((item, index) => ({
        ...item,
        order: index + 1,
    }));
}

export function remove<T extends { order: number }>(array: T[], index: number): T[] {
    return array.filter((_, i) => i !== index);
}

export function duplicate<T extends { order: number }>(array: T[], index: number): T[] {
    const itemToDuplicate = array[index];
    const duplicatedItem = { ...itemToDuplicate, order: index + 1 };
    return [
        ...array.slice(0, index + 1),
        duplicatedItem,
        ...array.slice(index + 1).map(item => ({
            ...item,
            order: item.order + 1,
        })),
    ];
}

export function maxOrderNumber<T extends { order: number }>(fields: T[]): number {
    return fields.map(field => field.order).reduce((a, b) => Math.max(a, b), 0);
}

/**
 * Returns the first item of the array if it's the only element, otherwise returns undefined.
 * @param array
 */
export const getSingleItemIfExists = <T>(array: T[]): T | undefined => {
    if (array.length === 1) {
        return array[0];
    }
    return undefined;
};

/**
 * Returns true if the array is not empty.
 * @param array
 */
export const isNonEmptyArray = <T>(array: T[]): array is NonEmptyArray<T> => {
    return array.length > 0;
};

/**
 * Returns the array if it's not empty, otherwise throws an error.
 * This function should be used when a developer is 100% sure that the array is not empty and that there is validations in place to ensure that.
 * This is used to work around TypeScript limitations that even if you check if an array is not empty, TypeScript still thinks it can be empty. even if it is not!
 * @param array
 */
export const getNonEmptyArrayOrError = <T>(array: T[]): NonEmptyArray<T> => {
    if (!isNonEmptyArray(array)) {
        throw new Error('The array cannot be empty');
    }
    return array;
};
