import { CustomThemeOptions } from '@mui/material';
import { red } from '@mui/material/colors';

const COLOR_TEXT = '#1D2433';
const COLOR_PRIMARY = '#665CFF';
const COLOR_PRIMARY_LIGHT = '#E9E6F7';
const COLOR_PRIMARY_DARK = '#5449ff';
const COLOR_SECONDARY = '#2D0EDE';
const COLOR_SECONDARY_LIGHT = '#EEEBFF';
const COLOR_SECONDARY_DARK = '#2D0EDE';
const COLOR_SUCCESS = '#11BA9C';
const COLOR_WARNING = '#F49342';
const COLOR_ERROR = '#FE4C4D';
const COLOR_ERROR_LIGHT = '#FEE5E5';
const COLOR_INFO = '#2D60E0';
const COLOR_INFO_LIGHT = '#E7EEFE';
const GREY_50 = '#F8FAFC';
const GREY_100 = '#F1F3F5';
const GREY_200 = '#F1F3F9';
const GREY_300 = '#E1E6EF';
const GREY_400 = red['400'];
const GREY_500 = red['500'];
const GREY_600 = red['600'];
const GREY_700 = '#3F444D';
const GREY_800 = '#23272F';
const GREY_900 = '#1B1F27';
export const palette: CustomThemeOptions['palette'] = {
    primary: {
        main: COLOR_PRIMARY,
        light: COLOR_PRIMARY_LIGHT,
        dark: COLOR_PRIMARY_DARK,
    },
    secondary: {
        main: COLOR_SECONDARY,
        light: COLOR_SECONDARY_LIGHT,
        dark: COLOR_SECONDARY_DARK,
    },
    grey: {
        50: GREY_50,
        100: GREY_100,
        200: GREY_200,
        300: GREY_300,
        400: GREY_400,
        500: GREY_500,
        600: GREY_600,
        700: GREY_700,
        800: GREY_800,
        900: GREY_900,
    },
    success: {
        main: COLOR_SUCCESS,
        light: '#DAF7F2',
        dark: '#008069',
    },
    warning: {
        main: COLOR_WARNING,
        light: '#FCEDE1',
        dark: '#CF6611',
    },
    error: {
        main: COLOR_ERROR,
        light: COLOR_ERROR_LIGHT,
    },
    info: {
        main: COLOR_INFO,
        light: COLOR_INFO_LIGHT,
    },
    text: {
        primary: COLOR_TEXT,
        secondary: '#1D2433CC',
        disabled: '#1D2433A6',
    },
    background: {
        default: GREY_100,
    },
};
