import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { AsyncSelectFilter, SelectFilter } from '@/components/filters-bar/FiltersBar';
import { getLocations } from '@/domain/location/Location.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';
import { searchEmployees } from '@/domain/employee/Employee.service';

export enum ManageTimesheetPendingTableFiltersEnum {
    JOB = 'JOB',
    LOCATION = 'LOCATION',
    DEPARTMENT = 'DEPARTMENT',
    MANAGER = 'MANAGER',
}

export const useManageTimesheetPendingTableFilters = (): { filters: (SelectFilter | AsyncSelectFilter)[] } => {
    const filters: (SelectFilter | AsyncSelectFilter)[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLocations().then(locations => {
                    return locations?.map(location => ({
                        label: location.name,
                        value: location.id,
                    }));
                });
            },
            key: ManageTimesheetPendingTableFiltersEnum.LOCATION,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getDepartments().then(departments => {
                    return departments?.map(department => ({
                        label: getLabelTranslation(department.name),
                        value: department.id,
                    }));
                });
            },
            key: ManageTimesheetPendingTableFiltersEnum.DEPARTMENT,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getJobs().then(jobs => {
                    return jobs?.map(job => ({
                        label: getLabelTranslation(job.name),
                        value: job.id,
                    }));
                });
            },
            key: ManageTimesheetPendingTableFiltersEnum.JOB,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees();
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: ManageTimesheetPendingTableFiltersEnum.MANAGER,
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
