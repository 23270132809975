import { ErrorBoundary } from '@/bugsnag';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { ErrorEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { Button, Paper } from '@mui/material';
import { isAxiosError } from 'axios';
import { FC } from 'react';
import { useNavigate, useRouteError } from 'react-router';

export const RouterBoundary: FC = () => {
    const error = useRouteError();
    const navigate = useNavigate();

    // Redirect to login page if the error is 401
    if (isAxiosError(error) && error.response?.status === 401) {
        navigate('/login');
    }

    const ThrowError = () => {
        if (error) {
            throw error;
        }
        return undefined;
    };

    return (
        <ErrorBoundary FallbackComponent={Fallback}>
            <ThrowError />
        </ErrorBoundary>
    );
};

const Fallback = ({ clearError, error }: { clearError: () => void; error: Error }) => <RetryPaper retry={clearError} error={error.message} />;

const RetryPaper: FC<{ retry: () => void; error: string }> = ({ retry, error }) => {
    return (
        <Paper sx={{ display: 'flex', flex: '1' }}>
            <EmptyState
                flex={1}
                icon={<ErrorEmptyStateIcon />}
                title={error}
                action={
                    <Button onClick={() => retry} variant='outlined'>
                        Retry
                    </Button>
                }
            />
        </Paper>
    );
};
