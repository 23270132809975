import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldText } from '@/components/form/field-text/FieldText';
import { PlanningTag } from '@/domain/planning-tag/PlanningTag.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    open: boolean;
    handleSave: (data: TagForm, id?: number) => void;
    closeDialog: () => void;
    activeTagData?: PlanningTag;
    mode: 'edit' | 'create';
};

export interface TagForm {
    name: string;
}

export const PlanningTagSettingsDialog: FC<Props> = ({ open, handleSave, closeDialog, activeTagData, mode }) => {
    const { t } = useTranslation();
    const schema = yup.object().shape({
        name: yup.string().trim().required(),
    });
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
        },
    });

    useEffect(() => {
        if (mode === 'edit') {
            reset({ name: activeTagData?.name });
        } else {
            reset();
        }
    }, [activeTagData, reset, mode]);

    const onSave = (data: TagForm) => {
        handleSave(data, activeTagData?.id);
        reset();
    };
    const onClose = () => {
        reset();
        closeDialog();
    };
    return (
        <DialogWrapper open={open} onClose={onClose} header={mode === 'create' ? t('planning_setting_page.add_tag') : t('planning_setting_page.edit_tag')}>
            <Stack component={DialogContent}>
                <FormControlLabel label={t('planning_setting_page.tag_name')} control={<FieldText fullWidth control={control} name='name' />} />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(onSave, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
