import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey, getSelectFilterStringValues } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeAvailability, EmployeeAvailabilitySearchRequest } from '@/domain/employee-availability/EmployeeAvailability.model';
import { getEmployeeLeaveTypeToDisplay } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { Employee, EmployeeAnniversary, EmployeeAnniversaryRequest, EmployeeSearch } from '@/domain/employee/Employee.model';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { getCurrentPrincipalEmployment } from '@/domain/employment/Employment.service';
import { EmployeeLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { canOnboardOffboardEmployees, canSeeOtherEmployeeLeaves, hasViewEmployeeBirthdaysPolicy } from '@/domain/permission/Permission.service';
import useDebounce from '@/hooks/Debounce.hook';
import { useGetEmployeeAvailabilities } from '@/hooks/employee-availability/EmployeeAvailability.hook';
import { useGetEmployeeLeaveTypePolicies, useGetEmployeesBirthdays, useGetPaginatedEmployees } from '@/hooks/employee/Employee.hook';
import { EmployeeFilter, useEmployeeFilters } from '@/hooks/employee/EmployeeFilters.hook';
import { ContractStartInfoTag, EmployeeAnniversaryInfoTag } from '@/page/employee-profile/employee-profile-card/EmployeeProfileCard';
import { EmployeeWorkingAreaOrLeaveChip, WorkingAreaOrLeave } from '@/page/employee/employee-working-area-or-leave-chip/EmployeeWorkingAreaOrLeaveChip';
import { useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { desktopVisible } from '@/theme/responsive';
import { handleError } from '@/utils/api.util';
import { getCurrentLocalDate } from '@/utils/datetime.util';

import { getLabelTranslation } from '@/utils/language.util';
import {
    Button,
    CircularProgress,
    Divider,
    InputAdornment,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    Skeleton,
    Stack,
    StackProps,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Add01Icon, Search01Icon } from 'hugeicons-react';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink } from 'react-router';

export const MyColleaguesPage: FC = () => {
    const [searchName, setSearchName] = useState<string>('');

    const { t } = useTranslation();
    const currentEmployee = useCurrentEmployee();
    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // FILTERS
    const { filters: availableFilters } = useEmployeeFilters();

    const [filters, setFilters] = useFiltersStorage('people-filters', availableFilters);

    const findEmployeeAvailability = (employeeId: number) => {
        return employeeAvailabilities.find(employeeAvailability => employeeAvailability.employee.id === employeeId);
    };

    const debounce = useDebounce();

    const mapFiltersToSearchRequest = (filters: EmployeeFilter[]) => {
        const filtersFilled = filters?.filter(filter => !!filter.value?.length);
        const employmentStatus = getFilterValueEmploymentStatus(filtersFilled);

        const search: EmployeeSearch = {
            quickSearch: searchName,
            statuses: employmentStatus,
            locationIds: getFilterValueIdsByKey(filtersFilled, 'locationIds'),
            departmentIds: getFilterValueIdsByKey(filtersFilled, 'departmentIds'),
            jobIds: getFilterValueIdsByKey(filtersFilled, 'jobIds'),
            managerIds: getFilterValueIdsByKey(filtersFilled, 'managerIds'),
        };
        return search;
    };

    // FETCH EMPLOYEES
    const ITEMS_PER_PAGE = 50;
    const {
        data: employees = [],
        isLoading: isEmployeesLoading,
        isFetching: isEmployeesFetching = false,
        isError: isEmployeesError,
        error: employeesError,
        fetchNextPage: fetchNextEmployees,
        hasNextPage: hasMoreEmployees,
        isFetchingNextPage,
        totalCount: totalEmployeesCount,
    } = useGetPaginatedEmployees(mapFiltersToSearchRequest(filters), {
        limitPerPage: ITEMS_PER_PAGE,
        enabled: !!filters.length,
    });

    const handleLoadNextEmployees = (inView: boolean) => {
        if (inView && hasMoreEmployees) {
            fetchNextEmployees().catch(handleError);
        }
    };

    const { ref } = useInView({ onChange: handleLoadNextEmployees });

    // FETCH leaves returning info of today
    const searchRequest: EmployeeAvailabilitySearchRequest = {
        atDateTime: getCurrentLocalDate(),
    };
    const { data: employeeAvailabilities = [] } = useGetEmployeeAvailabilities(searchRequest);

    // FETCH LEAVE TYPE POLICIES
    const { data: userLeaveTypePolicies = [] } = useGetEmployeeLeaveTypePolicies();

    // FETCH  BIRTHDAYS TODAY
    const canViewEmployeeBirthday = hasViewEmployeeBirthdaysPolicy(policies);

    const birthdaySearchRequest: EmployeeAnniversaryRequest = {
        startDate: getCurrentLocalDate(),
        endDate: getCurrentLocalDate(),
        employeeIds: [],
    };

    const { data: employeeBirthdays } = useGetEmployeesBirthdays(birthdaySearchRequest, canViewEmployeeBirthday);

    if (!currentEmployee || !realm) {
        return;
    }

    // index of element which trigger next page when the element is visible. It's 30% from the end of the list
    const indexToTriggerNextPage = employees.length - Math.round(ITEMS_PER_PAGE * 0.3);

    return (
        <Stack component={isMobile ? Paper : Stack} gap={2} overflow={'hidden'} flex={1}>
            <Stack component={isMobile ? Stack : Paper} gap={1} p={{ xs: 2, sm: 1 }} pb={{ xs: 0, sm: 1 }} direction='row'>
                <Stack direction={'row'} alignItems={'center'} gap={1} sx={desktopVisible} flexWrap={'wrap'}>
                    <FiltersBar filters={filters} onFiltersChange={setFilters} />
                    {!isEmployeesLoading && (
                        <Typography variant={'body1bold'}>{t('manage_people_page.total_employees', { total: totalEmployeesCount })}</Typography>
                    )}
                </Stack>

                <Stack direction={'row'} gap={1} flex={'1 0 auto'} justifyContent={'flex-end'} alignItems={'flex-start'}>
                    <TextField
                        placeholder={t('general.search')}
                        onChange={event => {
                            debounce(() => setSearchName(event.target.value), 300);
                        }}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search01Icon size={20} />
                                    </InputAdornment>
                                ),
                                sx: { pl: 1 },
                            },
                            htmlInput: {
                                sx: { py: '5.5px' },
                            },
                        }}
                        sx={{ width: isMobile ? '100%' : '200px' }}
                    />
                    {canOnboardOffboardEmployees(policies) && (
                        <Button
                            startIcon={<Add01Icon size={20} />}
                            component={RouterLink}
                            to='/people/onboarding-form'
                            sx={{ display: isMobile ? 'none' : 'flex' }}
                        >
                            {t('manage_people_page.add_employee')}
                        </Button>
                    )}
                </Stack>
            </Stack>
            <Stack
                component={Paper}
                flex={1}
                overflow={'auto'}
                id='scrollable-list'
                sx={{
                    '&::-webkit-scrollbar': { display: 'none' },
                }}
            >
                <StateHandler
                    isLoading={isEmployeesLoading || isEmployeesFetching}
                    isError={isEmployeesError}
                    error={employeesError}
                    isEmpty={!employees.length}
                    loadingComponent={<EmployeesListSkeleton />}
                >
                    <Stack component={List} dense pt={1} pb={2}>
                        {employees.map((employee, index) => (
                            <Stack component='li' key={employee.id}>
                                <ListItemButton
                                    component={RouterLink}
                                    to={'/profile/' + employee.id}
                                    alignItems={'flex-start'}
                                    {...(index === indexToTriggerNextPage ? { ref } : {})}
                                    disableRipple
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Stack component={ListItemAvatar} sx={{ m: 0 }}>
                                        <EmployeeAvatar size={'xl'} employeeAvatar={employee} />
                                    </Stack>
                                    <EmployeeItem
                                        employee={employee}
                                        employeeBirthdays={employeeBirthdays ?? []}
                                        employeeAvailability={findEmployeeAvailability(employee.id)}
                                        userLeaveTypePolicies={userLeaveTypePolicies}
                                    />
                                </ListItemButton>
                                <Divider variant='inset' />
                            </Stack>
                        ))}
                    </Stack>
                    {isFetchingNextPage && (
                        <Stack p={2} alignItems={'center'}>
                            <CircularProgress />
                        </Stack>
                    )}
                </StateHandler>
            </Stack>
        </Stack>
    );
};

/**
 * EmployeeItem component that render a row with employee information
 */
type EmployeeItemProps = {
    employee: Employee;
    employeeBirthdays: EmployeeAnniversary[];
    employeeAvailability: EmployeeAvailability | undefined;
    userLeaveTypePolicies: EmployeeLeaveTypePolicy[];
} & StackProps;

const EmployeeItem: FC<EmployeeItemProps> = ({ employee, employeeBirthdays, employeeAvailability, userLeaveTypePolicies, ...restRoot }) => {
    const { t } = useTranslation();

    const currentEmployee = useCurrentEmployee();
    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();

    const isEmployeeAway = () => {
        return !!employeeAvailability?.leaveTypeId;
    };

    const canSeeLeaves = canSeeOtherEmployeeLeaves(realm?.realmFeatures, policies, currentEmployee.id);
    const canViewEmployeeBirthday = hasViewEmployeeBirthdaysPolicy(policies);

    const getCurrentWorkingAreaLeave = (employeeId: number): WorkingAreaOrLeave | undefined => {
        if (!employeeAvailability?.leaveTypeId || !userLeaveTypePolicies) {
            return;
        }
        const employeeLeaveTypeTitle = getEmployeeLeaveTypeToDisplay(userLeaveTypePolicies, employeeId, employeeAvailability.leaveTypeId);
        return {
            type: 'LEAVE',
            displayText: employeeLeaveTypeTitle?.name ? getLabelTranslation(employeeLeaveTypeTitle?.name) : '',
            color: employeeLeaveTypeTitle?.color,
            leaveEndDate: employeeAvailability?.returnDate,
            leaveReturnDayPeriod: employeeAvailability?.returnPeriod,
            leavePercentage: employeeAvailability?.leavePercentage,
        };
    };

    const employment = getCurrentPrincipalEmployment(employee);

    const labelWhenTerminated = employee.employmentStatus === EmploymentStatus.TERMINATED ? t('manage_people_page.former_employee') : '';

    const getEmployeeBirthDate = (employeeId: number): LocalDate | undefined => {
        return employeeBirthdays?.find(employeeBirthday => employeeBirthday.employeeId === employeeId)?.anniversaryDate;
    };

    const currentEmployments = employee.currentEmployments;

    return (
        <Stack direction={'row'} justifyContent={'space-between'} gap={0.5} flexWrap={'wrap'} alignItems='flex-start' py={1} flex={1} {...restRoot}>
            <Stack>
                <Typography variant='body1bold' color={employee.employmentStatus === EmploymentStatus.TERMINATED ? 'text.disabled' : 'main'}>
                    {employee?.displayName} {labelWhenTerminated}
                </Typography>
                <Typography variant='body1' color={'text.secondary'}>
                    {currentEmployments
                        .map(employment =>
                            t('manage_people_page.job_in_department_in_location', {
                                jobTitle: getLabelTranslation(employment.job.name),
                                department: getLabelTranslation(employment.department.name),
                                location: employment.location.name,
                            }),
                        )
                        .join(' / ')}
                </Typography>
            </Stack>
            <Stack direction={'row'} gap={0.5} flexWrap={'wrap'}>
                {canSeeLeaves && isEmployeeAway() && <EmployeeWorkingAreaOrLeaveChip currentWorkingAreaLeave={getCurrentWorkingAreaLeave(employee.id)} />}

                {canViewEmployeeBirthday && <EmployeeAnniversaryInfoTag birthday={getEmployeeBirthDate(employee.id)} />}

                <ContractStartInfoTag
                    employmentStartDate={employment?.startDate}
                    employmentCreateReason={employment?.employmentCreateReason}
                    employmentStatus={employee?.employmentStatus}
                />
            </Stack>
        </Stack>
    );
};

const EmployeesListSkeleton: FC = () => {
    return (
        <List disablePadding>
            {[...Array(25).keys()].map(i => (
                <Fragment key={i}>
                    <ListItemButton alignItems='flex-start' disableRipple>
                        <ListItemAvatar>
                            <Skeleton variant={'circular'} width={40} height={40} />
                        </ListItemAvatar>
                        <ListItemText primary={<Skeleton width={300} />} secondary={<Skeleton width={150} />} />
                    </ListItemButton>
                    <Divider variant='inset' component='li' />
                </Fragment>
            ))}
        </List>
    );
};

const isEmploymentStatus = (status: string): status is EmploymentStatus => {
    return status in EmploymentStatus;
};
const getFilterValueEmploymentStatus = (filters: EmployeeFilter[]) => {
    return getSelectFilterStringValues(filters.find(filter => filter.key === 'employmentStatuses')).filter(val => isEmploymentStatus(val));
};
