import { FC } from 'react';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { EmployeeReviewSummaryFeedbackPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/EmployeeReviewSummaryFeedbackPDF';
import { EmployeeReviewAvatar } from '@/page/review/employee-review-avatar/EmployeeReviewAvatar';
import { EmployeeReviewSummaryCompleted } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryCompleted';
import { EmployeeReviewSummaryFeedbackItemsFormType } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { Objective } from '@/domain/objective/Objective.model';
import { useTranslation } from 'react-i18next';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { showCompletedReviewSummary } from '@/domain/employee-review/EmployeeReview.service';
import { getLabelTranslation, useUserLanguage } from '@/utils/language.util';
import { Download02Icon, UnfoldLessIcon, UnfoldMoreIcon } from 'hugeicons-react';

type EmployeeReviewSummaryFeedbackFormHeaderProps = {
    employeeReviewManagerSummary: EmployeeReviewManagerSummary;
    objectives: Objective[];
    employeeReviewSummaryFeedbackItems: EmployeeReviewSummaryFeedbackItemsFormType;
    expandAll: boolean;
    handleExpandAllChange: () => void;
    employeeReview: EmployeeReview;
};

export const EmployeeReviewSummaryFeedbackFormHeader: FC<EmployeeReviewSummaryFeedbackFormHeaderProps> = ({
    employeeReviewManagerSummary,
    objectives,
    employeeReviewSummaryFeedbackItems,
    expandAll,
    handleExpandAllChange,
    employeeReview,
}) => {
    const { t } = useTranslation();
    const userLanguage = useUserLanguage();
    return (
        <Stack component={Paper}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                <Typography variant='h2'>{getLabelTranslation(employeeReviewManagerSummary?.reviewName)}</Typography>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                    <Button endIcon={<Download02Icon />}>
                        <PDFDownloadLink
                            style={{ textDecoration: 'none' }}
                            document={
                                <EmployeeReviewSummaryFeedbackPDF
                                    userLanguage={userLanguage}
                                    employeeReview={employeeReview}
                                    employeeReviewManagerSummary={employeeReviewManagerSummary}
                                    employeeReviewSummaryFeedbackItems={employeeReviewSummaryFeedbackItems}
                                    objectives={objectives ?? []}
                                />
                            }
                        >
                            <Typography color={'white'}>{t('reviews.review_summary.export')}</Typography>
                        </PDFDownloadLink>
                    </Button>
                    <Button
                        endIcon={expandAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                        variant='outlined'
                        onClick={() => {
                            handleExpandAllChange();
                        }}
                    >
                        {expandAll ? t('reviews.review_summary.collapse_all') : t('reviews.review_summary.expand_all')}
                    </Button>
                </Stack>
            </Stack>

            <EmployeeReviewAvatar employee={employeeReviewManagerSummary?.employee} />
            {showCompletedReviewSummary(employeeReview, employeeReviewManagerSummary) && (
                <EmployeeReviewSummaryCompleted employeeReviewManagerSummary={employeeReviewManagerSummary} />
            )}
        </Stack>
    );
};
