import { FC, ReactNode } from 'react';
import { Button, Checkbox, CheckboxProps, Stack, Typography } from '@mui/material';

type SquareTextCheckboxProps = {
    title: string;
    description: string | ReactNode;
} & CheckboxProps;

export const SquareTextCheckbox: FC<SquareTextCheckboxProps> = ({ title, description, checked, onClick, disabled, ...rest }) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            sx={{
                width: '300px',
                height: '120px',
                border: '1px solid',
                borderColor: theme => (checked ? theme.palette.primary.main : theme.palette.grey[300]),
                backgroundColor: theme => (checked ? theme.palette.primary.light : theme.palette.common.white),
                ':hover': {
                    backgroundColor: theme => (checked ? theme.palette.primary.light : theme.palette.common.white),
                },
                px: 0.5,
                pb: 1,
            }}
        >
            <Stack height={'100%'} justifyContent={'center'} gap={0.5} width={'100%'}>
                <Stack direction={'row'} flex={1} alignItems={'center'} height={'30%'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant={'h2'} sx={{ width: '100%' }} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} pl={4}>
                        {title}
                    </Typography>
                    <Checkbox {...rest} name={title} disableRipple onChange={undefined} checked={checked} />
                </Stack>

                <Stack height={'70%'} justifyContent={'center'}>
                    {description &&
                        (typeof description === 'string' ? (
                            <Typography variant={'body1'} textAlign={'center'} whiteSpace={'wrap'} textOverflow={'ellipsis'} overflow={'auto'}>
                                {description}
                            </Typography>
                        ) : (
                            <Stack textOverflow={'ellipsis'} overflow={'auto'}>
                                {description}
                            </Stack>
                        ))}
                </Stack>
            </Stack>
        </Button>
    );
};
