import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import * as yup from 'yup';

export const reviewStepSchema = yup.object().shape({
    organizeMeeting: yup.boolean().default(true),
    review: yup
        .object()
        .shape({
            reviewTemplate: yup
                .object()
                .shape({
                    id: yup.number().required(),
                    name: yup.string().required(),
                })
                .default(getNull())
                .required(),
            startDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
            endDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
            manager: yup
                .object()
                .shape({
                    id: yup.number().required(),
                    displayName: yup.string().required(),
                })
                .default(getNull())
                .required(),
        })
        .required()
        .when('organizeMeeting', {
            is: false,
            // TODO find a better solution for nested object with condition
            then: () => yup.object().nullable().default(getNull()),
        }),
});

export type ReviewStepFormValues = yup.InferType<typeof reviewStepSchema>;
