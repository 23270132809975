import { TerminationReason, TerminationType } from '@/domain/employment/Employment.model';
import { getLocalDateTestConfig } from '@/utils/datetime.util';

import { getNull } from '@/utils/object.util';
import * as yup from 'yup';

export const terminationStepSchema = yup.object().shape({
    employeeId: yup.number().required(),
    currentEmploymentId: yup.number().required(),
    endDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
    terminationNoticeDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).nullable().default(getNull()),
    terminationLastDayAtWork: yup.string<LocalDate>().test(getLocalDateTestConfig()).nullable().default(getNull()),
    terminationType: yup.string().oneOf(Object.values(TerminationType)).required(),
    terminationReason: yup.string().oneOf(Object.values(TerminationReason)).required(),
    terminationComment: yup.string().trim().default(''),
});

export type TerminationStepFormValues = yup.InferType<typeof terminationStepSchema>;
