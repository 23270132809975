import * as yup from 'yup';
import { getNull } from '@/utils/object.util';

export const treeSelectValueSchema = yup
    .object()
    .shape({
        id: yup.number().required(),
        label: yup.string().required(),
        children: yup.array().required().default([]),
    })
    .default(getNull());
