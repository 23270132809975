import { objectiveCompletionStatusApi } from '@/api/objective-completion-status/ObjectiveCompletionStatus.api';
import {
    CompletionScaleCreateMutation,
    CompletionScaleUpdateMutation,
    CompletionStatusSearch,
    ObjectiveCompletionStatus,
} from '@/domain/objective-completion-status/ObjectiveCompletionStatus.model';

import { OrderMutation } from '@/domain/common';

export const searchCompletionStatuses = (search: CompletionStatusSearch = {}): Promise<ObjectiveCompletionStatus[]> => {
    return objectiveCompletionStatusApi.searchCompletionStatuses(search);
};

export const updateCompletionStatusOrder = (request: OrderMutation[]): Promise<void> => {
    return objectiveCompletionStatusApi.updateCompletionStatusOrder(request);
};

export const deleteCompletionStatus = (objectiveCompletionStatusId: number): Promise<void> => {
    return objectiveCompletionStatusApi.deleteCompletionStatus(objectiveCompletionStatusId);
};

export const createCompletionStatus = (mutation: CompletionScaleCreateMutation): Promise<ObjectiveCompletionStatus> => {
    return objectiveCompletionStatusApi.createCompletionStatus(mutation);
};

export const updateCompletionStatus = (mutation: CompletionScaleUpdateMutation, objectiveCompletionStatusId: number): Promise<ObjectiveCompletionStatus> => {
    return objectiveCompletionStatusApi.updateCompletionStatus(mutation, objectiveCompletionStatusId);
};
