import { FilePickerItem, StoredFile, TemporaryFile } from '@/components/file-picker/FilePicker';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';
import { useFilePicker } from '@/components/file-picker/useFilePicker';
import {
    createLeaveRequestAttachment,
    deleteLeaveRequestAttachment,
    getLeaveRequestAttachments,
} from '@/domain/leave-request-attachment/LeaveRequestAttachment.service';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect } from 'react';

export const useLeaveRequestAttachments = (
    leaveRequestId?: number,
): {
    files: FilePickerItem[];
    handleFileRemoved: (file: FilePickerItem) => Promise<void>;
    handleFileUploaded: (files: TemporaryFile) => Promise<void>;
} => {
    const mode = leaveRequestId ? 'edit' : 'create';

    const { files, setFiles, removeFile, addFiles } = useFilePicker();

    const handleFileRemoved = async (file: FilePickerItem) => {
        try {
            if (mode === 'edit' && !isTemporaryFile(file)) {
                await deleteLeaveRequestAttachment(file.id);
            }
            removeFile(file);
        } catch (error) {
            handleError(error);
        }
    };

    const handleFileUploaded = async (file: TemporaryFile) => {
        if (!isTemporaryFile(file)) {
            return;
        }
        try {
            // In creation mode  we call the backend only after the leave request is created
            if (mode === 'edit' && leaveRequestId) {
                await createLeaveRequestAttachment({
                    file: file,
                    leaveRequestId,
                    name: file.name ?? '',
                    mimeType: file.data.type,
                });
                fetchAttachments();
            } else {
                addFiles([file]);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const fetchAttachments = useCallback(() => {
        if (leaveRequestId) {
            getLeaveRequestAttachments(leaveRequestId).then(attachments => {
                if (attachments?.length) {
                    const files: StoredFile[] = attachments.map(attachment => {
                        return {
                            id: attachment.id,
                            name: attachment.name,
                            mimeType: attachment.mimeType,
                            createdBy: attachment.createdBy,
                        };
                    });
                    setFiles(files);
                }
            });
        }
    }, [leaveRequestId, setFiles]);

    useEffect(() => {
        fetchAttachments();
    }, [fetchAttachments]);

    return {
        files,
        handleFileRemoved,
        handleFileUploaded,
    } as const;
};
