import { IconButton, IconButtonProps, Stack, Typography } from '@mui/material';
import { EmployeeAvatarPopover } from '../employee-avatar/EmployeeAvatarPopover';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { FC, MouseEvent, useState } from 'react';

type EmployeeAvatarPopoverProps = {
    counter: number;
    employeeAvatars: EmployeeAvatar[];
    onClick?: () => void;
    profileLink?: boolean;
} & IconButtonProps;

export const StackedAvatarPopover: FC<EmployeeAvatarPopoverProps> = ({ counter, employeeAvatars, onClick, profileLink, sx }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const onClickUserPopover = (event: MouseEvent<HTMLElement>) => {
        if (onClick) {
            onClick();
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const onCloseUserPopover = () => {
        setAnchorEl(undefined);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'user-list-popover' : undefined;

    return (
        <Stack direction='row' alignItems='center' justifyContent='center'>
            <IconButton
                onClick={onClickUserPopover}
                sx={{
                    width: '34px',
                    height: '34px',
                    borderRadius: '50%',
                    padding: 0,
                    bgcolor: 'primary.main',
                    '&:hover': {
                        bgcolor: 'primary.dark',
                    },
                    border: '1px solid white',
                    ...sx,
                }}
            >
                <Typography color={'white'} variant='body2bold'>
                    +{counter}
                </Typography>
            </IconButton>

            <EmployeeAvatarPopover
                employeeAvatars={employeeAvatars}
                onCloseUserPopover={onCloseUserPopover}
                open={open}
                id={id}
                anchorEl={anchorEl}
                profileLink={profileLink}
            />
        </Stack>
    );
};
