import { PreviewData } from '@/domain/document/Document.model';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { Box, Button, DialogActions, DialogContent, IconButton, IconButtonProps, Input, Stack, StackProps, useTheme } from '@mui/material';
import Uppy, { Body, Meta, UppyFile } from '@uppy/core';
import Germain from '@uppy/locales/lib/de_DE';
import French from '@uppy/locales/lib/fr_FR';
import Italian from '@uppy/locales/lib/it_IT';
import { Dashboard, DragDrop, useUppyEvent, useUppyState } from '@uppy/react';
import { Cancel01Icon, File01Icon, LinkSquare02Icon } from 'hugeicons-react';
import { FC, ReactElement, useState } from 'react';

import { ConfirmPopover } from '@/components/confirmation-popover/ConfirmPopover';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/image-editor/dist/style.min.css';
import '@uppy/status-bar/dist/style.css';
import ImageEditor from '@uppy/image-editor';
import Compressor from 'compressorjs';

import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';
import { useOpenDialog } from '@/hooks/dialog/dialog.hook';
import { getTodayDate } from '@/utils/datetime.util';
import { getUserLanguage, UserLanguage } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { useTranslation } from 'react-i18next';
import './FilePicker.css';

// File already save in the database
export type StoredFile = { id: number; name: string; mimeType: string; createdBy: EmployeeAvatar };

export type TemporaryFile = Overwrite<UppyFile<Meta, Body>, { data: File }>;

export type FilePickerItem = TemporaryFile | StoredFile;

export type FilePickerProps = {
    files: FilePickerItem[];
    onFileUploaded: (files: TemporaryFile[]) => void;
    onFileRemoved: (fileMetadata: FilePickerItem) => void;
    onFileRenamed: ((fileMetadata: FilePickerItem) => void) | undefined;
    fetchDocumentUrl: ((itemId: number) => Promise<string>) | undefined;
    maxFiles?: number;
    accept?: string | string[];
    confirmOnRemove?: boolean;
} & StackProps;

export const FilePicker: FC<FilePickerProps> = ({
    onFileUploaded,
    onFileRenamed,
    onFileRemoved,
    files,
    fetchDocumentUrl = () => Promise.resolve(''),
    confirmOnRemove = false,
    maxFiles,
    accept,
    ...rest
}) => {
    const [uppy] = useState(
        () =>
            new Uppy({
                locale: getUppyLocale(),
                restrictions: {
                    maxNumberOfFiles: maxFiles ?? getNull(),
                    allowedFileTypes: (typeof accept === 'string' ? [accept] : accept) ?? getNull(),
                },
            }),
    );

    useUppyEvent(uppy, 'file-added', file => {
        // Create a preview url from the file data
        file.preview = URL.createObjectURL(file.data);
        // To avoid typing issue with data being a Blob or File type we cast it to File
        onFileUploaded([file as TemporaryFile]);
    });

    const [documentPreviewDialogData, setDocumentPreviewDialogData] = useState<PreviewData>();

    const downloadFile = (url: string | undefined) => {
        if (!url) {
            return;
        }
        window.open(url, '_blank');
    };

    const handleFileRemoved = (file: FilePickerItem) => {
        if (isTemporaryFile(file)) {
            uppy.removeFile(file.id);
        }
        onFileRemoved(file);
    };

    const getFilePreviewData = async (file: StoredFile): Promise<PreviewData> => {
        const documentUrl = await fetchDocumentUrl(file.id);

        return {
            document: {
                id: file.id,
                documentType: 'DOCUMENT',
                name: file.name,
                mimeType: file.mimeType,
                createdAt: getTodayDate(),
            },
            url: documentUrl,
        };
    };

    const handleFileClicked = async (file: FilePickerItem) => {
        if (isTemporaryFile(file)) {
            downloadFile(file.preview);
        } else {
            const documentPreviewDialogData = await getFilePreviewData(file);
            setDocumentPreviewDialogData(documentPreviewDialogData);
        }
    };

    const onClosePreviewDialog = () => {
        setDocumentPreviewDialogData(undefined);
    };

    return (
        <Stack {...rest} gap={1} flex={1}>
            <DragDrop uppy={uppy} width='100%' height='36px' />

            {files.map(file => (
                <FileItem
                    key={file.id}
                    file={file}
                    onFileRenamed={onFileRenamed}
                    handleFileClicked={handleFileClicked}
                    onFileRemoved={handleFileRemoved}
                    confirmOnRemove={confirmOnRemove}
                />
            ))}

            {documentPreviewDialogData && (
                <DocumentPreviewDialog
                    onClose={onClosePreviewDialog}
                    previewData={documentPreviewDialogData}
                    onDownloadClick={() => downloadFile(documentPreviewDialogData?.url)}
                />
            )}
        </Stack>
    );
};

const DeleteIconButton = (props: IconButtonProps) => (
    <IconButton
        size='small'
        sx={{
            ml: 'auto',
        }}
        aria-label='delete-file'
        {...props}
    >
        <Cancel01Icon />
    </IconButton>
);

type FileItemProps = {
    file: FilePickerItem;
    onFileRenamed?: (file: FilePickerItem) => void;
    handleFileClicked: (file: FilePickerItem) => void;
    onFileRemoved: (file: FilePickerItem) => void;
    confirmOnRemove: boolean;
};
const FileItem: FC<FileItemProps> = ({ file, onFileRenamed, onFileRemoved, handleFileClicked, confirmOnRemove }) => {
    const theme = useTheme();

    const { t } = useTranslation();

    return (
        <Box border={'0.5px solid'} borderColor={theme.palette.grey[300]} borderRadius={1}>
            <Stack direction={'row'} alignItems={'center'}>
                <File01Icon
                    style={{
                        paddingLeft: 1.5,
                        paddingRight: 1.5,
                    }}
                />

                <Input
                    sx={{ border: 'none' }}
                    inputProps={{
                        style: {
                            padding: 0,
                        },
                        'aria-label': file.name,
                    }}
                    fullWidth
                    value={file.name}
                    readOnly={!onFileRenamed}
                    onChange={e => {
                        if (!onFileRenamed) {
                            return;
                        }

                        onFileRenamed({ ...file, name: e.target.value });
                    }}
                    disableUnderline={true}
                    endAdornment={
                        <IconButton onClick={() => handleFileClicked(file)} aria-label='open-file'>
                            <LinkSquare02Icon />
                        </IconButton>
                    }
                />

                {confirmOnRemove ? (
                    <ConfirmPopover onConfirm={() => onFileRemoved(file)} elevation={2} content={t('file_stack_wrapper.are_you_sure_delete')}>
                        <DeleteIconButton />
                    </ConfirmPopover>
                ) : (
                    <DeleteIconButton onClick={() => onFileRemoved(file)} />
                )}
            </Stack>
        </Box>
    );
};

export type FilePickerModalProps = Omit<DialogWrapperProps, 'open' | 'onClose'> & {
    children: ReactElement;
    onFilePicked: (file: TemporaryFile) => void;
    disabled?: boolean;
};

export const FilePickerModal: FC<FilePickerModalProps> = ({ children, onFilePicked: onSave, disabled = false, ...props }) => {
    const { t } = useTranslation();

    const [imageEditorIsOpen, setImageEditorIsOpen] = useState(false);
    const [uppy] = useState(() =>
        new Uppy({
            locale: getUppyLocale(),
            restrictions: { maxNumberOfFiles: 1 },
        }).use(ImageEditor, {
            actions: {
                revert: false,
                rotate: false,
                granularRotate: false,
                flip: false,
                zoomIn: false,
                zoomOut: false,
                cropSquare: false,
                cropWidescreen: false,
                cropWidescreenVertical: false,
            },
            cropperOptions: {
                aspectRatio: 1,
            },
        }),
    );

    useUppyEvent(uppy, 'file-editor:start', () => {
        setImageEditorIsOpen(true);
    });

    useUppyEvent(uppy, 'file-editor:complete', () => {
        setImageEditorIsOpen(false);
    });

    const uppyFiles = useUppyState(uppy, state => state.files) as Record<string, TemporaryFile>;

    const files = Object.values(uppyFiles);

    const { childrenWithOnOpen, handleClose, isOpen } = useOpenDialog(children, false);

    const handleCloseModal = () => {
        handleClose();
        uppy.clear();
    };

    const handleSave = async () => {
        const selectedFile = files[0];
        try {
            const file = await compress(files);
            // Replace the file data with the compressed one
            onSave({ ...selectedFile, data: file });
        } catch (error) {
            console.error('Error compressing file', error);
            // If the compression fails we still save the original file to provide a good user experience
            onSave(selectedFile);
        }
        handleCloseModal();
    };

    const compress = async (files: TemporaryFile[]): Promise<File> => {
        return new Promise(
            (resolve, reject) =>
                new Compressor(files[0].data, {
                    quality: 0.6,
                    success: result => {
                        resolve(result as File);
                    },
                    error: (error: Error) => {
                        reject(error);
                    },
                }),
        );
    };

    return (
        <>
            {/* Render the cloned children with the onClick event handler */}
            {childrenWithOnOpen}
            {isOpen && !disabled && (
                <DialogWrapper open={isOpen} onClose={handleCloseModal} {...props}>
                    <Stack component={DialogContent} flex={1}>
                        <Dashboard
                            width='100%'
                            height={400}
                            uppy={uppy}
                            disableStatusBar
                            hideUploadButton
                            hideCancelButton
                            singleFileFullScreen
                            autoOpen='imageEditor'
                            proudlyDisplayPoweredByUppy={false}
                        />
                    </Stack>
                    <DialogActions>
                        <Button disabled={!files.length || imageEditorIsOpen} onClick={handleSave} fullWidth>
                            {t('general.save')}
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            )}
        </>
    );
};

const getUppyLocale = () => {
    const language = getUserLanguage();
    switch (language) {
        case UserLanguage.DE:
            return Germain;
        case UserLanguage.FR:
            return French;
        case UserLanguage.IT:
            return Italian;
        case UserLanguage.EN:
        default:
            return undefined;
    }
};
