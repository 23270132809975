import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { handleError } from '@/utils/api.util';
import { SectionDefinition, SectionDefinitionSearchRequest } from '@/domain/section-setting/Section.model';
import { getSectionDefinitions, searchSectionDefinitions } from '@/domain/section-setting/Section.service';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetSectionDefinitions = (): UseQueryResult<SectionDefinition[]> => {
    const [sectionDefinitions, setSectionDefinitions] = useState<SectionDefinition[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchSectionDefinitions = useCallback(async () => {
        try {
            const sectionDefinitions = await getSectionDefinitions();
            setSectionDefinitions(sectionDefinitions);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchSectionDefinitions().catch(handleError);
    }, [fetchSectionDefinitions]);

    return {
        data: sectionDefinitions,
        setData: setSectionDefinitions,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchSectionDefinitions,
    };
};

export const useSearchSectionDefinitions = (search: SectionDefinitionSearchRequest): UseQueryResult<SectionDefinition[]> => {
    const [sectionDefinitions, setSectionDefinitions] = useState<SectionDefinition[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchSectionDefinitions = useCallback(async (search: SectionDefinitionSearchRequest) => {
        try {
            const sectionDefinitions = await searchSectionDefinitions(search);
            setSectionDefinitions(sectionDefinitions);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchSectionDefinitions(search).catch(handleError);
    }, [fetchSectionDefinitions, search]);

    return {
        data: sectionDefinitions,
        setData: setSectionDefinitions,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchSectionDefinitions(search),
    };
};
