import { locationApi } from '@/api/location/Location.api';
import { Location, LocationMutation } from '@/domain/location/Location.model';

export const getLocations = (): Promise<Location[]> => {
    return locationApi.getLocations();
};

export const getLocationById = (locationId: number): Promise<Location> => {
    return locationApi.getLocationById(locationId);
};

export const createLocation = (mutation: LocationMutation): Promise<Location> => {
    return locationApi.createLocation(mutation);
};

export const updateLocation = (locationId: number, mutation: LocationMutation): Promise<Location> => {
    return locationApi.updateLocation(locationId, mutation);
};

export const deleteLocation = (locationId: number): Promise<void> => {
    return locationApi.deleteLocation(locationId);
};
