import { Text } from '@react-pdf/renderer';
import { FC } from 'react';
import { EmployeeReviewFeedbackSectionFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { useTheme } from '@mui/material';
import { getLabelTranslation } from '@/utils/language.util';

type EmployeeReviewFeedbackSectionPDFProps = {
    sectionItem: EmployeeReviewFeedbackSectionFormType | undefined;
};

export const EmployeeReviewFeedbackSectionPDF: FC<EmployeeReviewFeedbackSectionPDFProps> = ({ sectionItem }) => {
    const theme = useTheme();

    if (!sectionItem) {
        return <></>;
    }

    return (
        <Text
            style={{
                fontSize: 16,
                fontWeight: 'bold',
                paddingLeft: theme.spacing(2),
            }}
        >
            {getLabelTranslation(sectionItem.title)}
        </Text>
    );
};
