import { Components, Theme } from '@mui/material';
import { CheckmarkCircle02Icon, CircleIcon } from 'hugeicons-react';

export const MuiRadio: Components<Omit<Theme, 'components'>>['MuiRadio'] = {
    defaultProps: {
        size: 'small',
        icon: <CircleIcon size={20} />,
        checkedIcon: <CheckmarkCircle02Icon size={20} />,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            '&.Mui-checked': {
                color: theme.palette.primary.main,
            },

            '&.Mui-disabled': {
                color: theme.palette.action.disabled,
                opacity: 0.65,
            },

            '& .MuiTouchRipple-root': {
                color: theme.palette.primary.main,
            },
        }),
    },
};
