import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { EmployeeFolder } from '@/domain/document/Document.model';
import { getLabelTranslation } from '@/utils/language.util';
import { Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Folder01Icon } from 'hugeicons-react';
import { FC } from 'react';
import { EmployeeDocumentDetails } from './EmployeeDocumentDetails';

type Props = {
    employeeFolders: EmployeeFolder[];
    employeeId: number;
    onFolderClick: (newValue: number) => void;
    folderId?: number;
};

export const EmployeeDocuments: FC<Props> = ({ employeeFolders, employeeId, folderId, onFolderClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const folder = employeeFolders.find(folder => folder.folder.id === folderId);

    return (
        <Stack direction={{ sx: 'column', sm: 'row' }} gap={2} justifyContent='center' flex={1}>
            <Paper sx={{ minWidth: '280px' }}>
                <Tabs
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    variant='scrollable'
                    value={folderId}
                    onChange={(_, newValue) => onFolderClick(newValue)}
                    aria-label='Documents tabs'
                    scrollButtons={isMobile ? 'auto' : false}
                >
                    {employeeFolders?.map((folder, index) => (
                        <Tab
                            value={folder.folder.id}
                            key={folder.folder.id}
                            label={getLabelTranslation(folder.folder.name)}
                            icon={<Folder01Icon />}
                            iconPosition={'start'}
                            wrapped={true}
                            sx={{
                                justifyContent: 'flex-start',
                                p: 2,
                                margin: 0,
                                fontSize: 14,
                                minHeight: 64,
                                minWidth: '100%',
                                textAlign: 'left',
                                '&.Mui-selected': {
                                    fontWeight: 400,
                                    color: theme.palette.primary.main,
                                },
                            }}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </Paper>

            {/* If for any reason the folder is not found, we don't render the details, but tabs are still visible and clickable */}
            {folder?.folder ? (
                <EmployeeDocumentDetails folder={folder?.folder} employeeId={employeeId} />
            ) : (
                <Stack
                    // Hack to keep tabs on the left side event in case without folder selected
                    flex={1}
                />
            )}
        </Stack>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
};
