import { AxiosResponse } from 'axios';
import { Employee } from '@/domain/employee/Employee.model';
import { API_BASE_URL, client } from '@/api/common';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { OnboardingMutation } from '@/domain/onboarding/Onboarding.model';

const submitOnboardingForm = async (mutation: OnboardingMutation): Promise<Employee> => {
    const url = API_BASE_URL + '/onboarding';
    const data = (await client.post<EmployeeDTO, AxiosResponse<EmployeeDTO>, OnboardingMutation>(url, mutation)).data;
    return mapEmployeeDTO(data);
};

export const onboardingApi = {
    submitOnboardingForm,
};
