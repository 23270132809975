import {
    EmployeeTimeBalance,
    EmployeeYearlyTimeBalance,
    TimesheetInsightSearch,
    TimesheetYearlySearchRequest,
} from '@/domain/timesheet-insight/TimesheetInsight.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';

const TIMESHEET_INSIGHT_API_BASE_PATH = API_BASE_URL + `/timesheet-insights`;

type TimesheetInsightSearchRequestDTO = TimesheetInsightSearch;
type TimesheetWeeklySearchRequestDTO = TimesheetYearlySearchRequest;
type EmployeeTimeBalanceDTO = Omit<EmployeeTimeBalance, 'employee'> & { employee: EmployeeDTO };
type EmployeeWeeklyTimeBalanceDTO = Omit<EmployeeYearlyTimeBalance, 'employee'> & { employee: EmployeeDTO };

const getEmployeeTimeBalances = async (search: TimesheetInsightSearch): Promise<EmployeeTimeBalance[]> => {
    const url = TIMESHEET_INSIGHT_API_BASE_PATH + '/time-balances';
    const { data: employeeTimeBalances } = await client.post<
        EmployeeTimeBalanceDTO[],
        AxiosResponse<EmployeeTimeBalanceDTO[]>,
        TimesheetInsightSearchRequestDTO
    >(url, search);
    return employeeTimeBalances.map(mapToEmployeeTimeBalance);
};

const getEmployeeWeeklyTimeBalances = async (search: TimesheetYearlySearchRequest): Promise<EmployeeYearlyTimeBalance[]> => {
    const url = TIMESHEET_INSIGHT_API_BASE_PATH + '/time-balances/weekly';
    const { data: employeeTimeBalances } = await client.post<
        EmployeeWeeklyTimeBalanceDTO[],
        AxiosResponse<EmployeeWeeklyTimeBalanceDTO[]>,
        TimesheetWeeklySearchRequestDTO
    >(url, search);
    return employeeTimeBalances.map(mapToEmployeeWeeklyTimeBalance);
};

const mapToEmployeeTimeBalance = (dto: EmployeeTimeBalanceDTO): EmployeeTimeBalance => {
    return {
        ...dto,
        employee: mapEmployeeDTO(dto.employee),
    };
};

const mapToEmployeeWeeklyTimeBalance = (weeklyDTO: EmployeeWeeklyTimeBalanceDTO): EmployeeYearlyTimeBalance => {
    return {
        ...weeklyDTO,
        employee: mapEmployeeDTO(weeklyDTO.employee),
    };
};

export const timesheetInsightApi = {
    getEmployeeTimeBalances,
    getEmployeeWeeklyTimeBalances,
};
