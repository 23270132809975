import { ShiftType, ShiftTypeCreationRequest, ShiftTypeSearchRequest, ShiftTypeUpdateRequest } from '@/domain/shift-type/ShiftType.model';
import { shiftTypeApi } from '@/api/shift-type/ShiftType.api';

export const searchShiftTypes = (request: ShiftTypeSearchRequest = {}): Promise<ShiftType[]> => {
    return shiftTypeApi.searchShiftTypes(request);
};

export const createShiftType = (request: ShiftTypeCreationRequest): Promise<ShiftType> => {
    return shiftTypeApi.createShiftType(request);
};

export const updateShiftType = (id: number, request: ShiftTypeUpdateRequest): Promise<ShiftType> => {
    return shiftTypeApi.updateShiftType(id, request);
};

export const deleteShiftType = (id: number): Promise<void> => {
    return shiftTypeApi.deleteShiftType(id);
};
