import { FC } from 'react';
import { useSearchParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ChangePasswordForm } from './change-password-form/ChangePasswordForm';

export const ActivateAccountPage: FC = () => {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const username = searchParams.get('username');
    const code = searchParams.get('activation_code');
    return (
        <ChangePasswordForm username={username} code={code} title={t('activate_account_page.title')} actionLabel={t('activate_account_page.action_label')} />
    );
};
