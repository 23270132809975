import { API_BASE_URL, client } from '../common';
import { AxiosResponse } from 'axios';
import { ShiftType, ShiftTypeCreationRequest, ShiftTypeSearchRequest, ShiftTypeUpdateRequest } from '@/domain/shift-type/ShiftType.model';

type ShiftTypeDTO = ShiftType;
type ShiftTypeSearchRequestDTO = ShiftTypeSearchRequest;
type ShiftTypeCreationRequestDTO = ShiftTypeCreationRequest;
type ShiftTypeUpdateRequestDTO = ShiftTypeUpdateRequest;

const searchShiftTypes = async (request: ShiftTypeSearchRequest): Promise<ShiftType[]> => {
    return (await client.post<ShiftTypeDTO[], AxiosResponse<ShiftTypeDTO[]>, ShiftTypeSearchRequestDTO>(API_BASE_URL + '/shift-types/search', request)).data;
};

const createShiftType = async (request: ShiftTypeCreationRequest): Promise<ShiftType> => {
    return (await client.post<ShiftTypeDTO, AxiosResponse<ShiftTypeDTO>, ShiftTypeCreationRequestDTO>(API_BASE_URL + '/shift-types', request)).data;
};

const updateShiftType = async (id: number, request: ShiftTypeUpdateRequest): Promise<ShiftType> => {
    return (await client.put<ShiftTypeDTO, AxiosResponse<ShiftTypeDTO>, ShiftTypeUpdateRequestDTO>(API_BASE_URL + `/shift-types/${id}`, request)).data;
};

const deleteShiftType = async (id: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/shift-types/${id}`);
};

export const shiftTypeApi = {
    searchShiftTypes,
    createShiftType,
    updateShiftType,
    deleteShiftType,
};
