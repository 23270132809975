import { PermissionGroup } from '@/domain/permission-group/PermissionGroup.model';
import { getPermissionGroups } from '@/domain/permission-group/PermissionGroup.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetPermissionGroups = (): UseQueryResult<PermissionGroup[]> => {
    const [permissionGroups, setPermissionGroups] = useState<PermissionGroup[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchPermissionGroups = useCallback(async () => {
        try {
            const permissionGroups = await getPermissionGroups();
            setPermissionGroups(permissionGroups);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchPermissionGroups().catch(handleError);
    }, [fetchPermissionGroups]);

    return {
        data: permissionGroups,
        setData: setPermissionGroups,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchPermissionGroups().catch(handleError),
    };
};
