import {
    CompanyDocumentCreationMutation,
    CompanyDocumentLinkCreationMutation,
    Document,
    DocumentContentDisposition,
    DocumentUpdateMutation,
    EmployeeDocumentCreationMutation,
    EmployeeDocumentLinkCreationMutation,
    EmployeeFolder,
    Folder,
    FolderMutation,
} from '@/domain/document/Document.model';
import { AxiosResponse } from 'axios';
import { OrderMutation } from '@/domain/common';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';
import { getAppConfig } from '@/config/config';
import { API_BASE_URL, client } from '@/api/common';

// Folder Documents

type EmployeeFolderDTO = EmployeeFolder;

type FolderDTO = Folder;

type FolderMutationDTO = FolderMutation;

export type DocumentDTO = Overwrite<Document, { createdAt: string }>;

// End of Folder Documents

const getFolders = async (): Promise<Folder[]> => {
    return (await client.get<FolderDTO[]>(API_BASE_URL + `/folders`)).data;
};

const getEmployeeFolders = async (employeeId: number): Promise<EmployeeFolder[]> => {
    return (await client.get<EmployeeFolderDTO[]>(API_BASE_URL + `/folders/employee/${employeeId}`)).data;
};

const getCompanyFolders = async (): Promise<Folder[]> => {
    return (await client.get<FolderDTO[]>(API_BASE_URL + `/folders/company`)).data;
};

const getFolder = async (folderId: number): Promise<Folder> => {
    return (await client.get<FolderDTO>(API_BASE_URL + `/folders/${folderId}`)).data;
};

export const mapDocumentDTO = (document: DocumentDTO): Document => {
    return {
        ...document,
        createdAt: convertUTCIsoStringToDate(document.createdAt),
    };
};

const getCompanyDocuments = async (folderId: number): Promise<Document[]> => {
    const { data } = await client.post<DocumentDTO[]>(API_BASE_URL + `/documents/search`, { folderId });
    return data.map(mapDocumentDTO);
};

const getEmployeeDocuments = async (folderId: number, employeeId: number): Promise<Document[]> => {
    const { data } = await client.post<DocumentDTO[]>(API_BASE_URL + `/employee-documents/search`, {
        folderId,
        employeeId,
    });
    return data.map(mapDocumentDTO);
};

const deleteFolder = async (folderId: number): Promise<void> => {
    return await client.delete(API_BASE_URL + `/folders/${folderId}`);
};

const deleteEmployeeDocument = async (documentId: number): Promise<void> => {
    return await client.delete(API_BASE_URL + `/employee-documents/${documentId}`);
};

const deleteCompanyDocument = async (documentId: number): Promise<void> => {
    return await client.delete(API_BASE_URL + `/documents/${documentId}`);
};

const createFolder = async (folder: FolderMutation): Promise<Folder> => {
    return (await client.post<FolderDTO, AxiosResponse<FolderDTO>, FolderMutationDTO>(API_BASE_URL + '/folders', folder)).data;
};

const updateFolder = async (folder: FolderMutation, folderId: number): Promise<Folder> => {
    return (await client.put<FolderDTO, AxiosResponse<FolderDTO>, FolderMutation>(API_BASE_URL + `/folders/${folderId}`, folder)).data;
};

const updateFoldersOrder = async (request: OrderMutation[]): Promise<void> => {
    return await client.put(API_BASE_URL + `/folders/order`, request satisfies OrderMutation[]);
};

const createEmployeeDocuments = async (requests: EmployeeDocumentCreationMutation): Promise<Document[]> => {
    const form = new FormData();
    form.append(
        'metadata',
        new Blob([JSON.stringify({ employeeId: requests.employeeId, folderId: requests.folderId })], { type: getAppConfig().MIME_TYPES.JSON }),
    );

    for (const file of requests.files) {
        form.append('files', file.data, file.name);
    }

    const { data } = await client.postForm<DocumentDTO[], AxiosResponse<DocumentDTO[]>, FormData>(API_BASE_URL + `/employee-documents/files`, form);

    return data.map(mapDocumentDTO);
};

type EmployeeDocumentLinkCreationRequestDTO = EmployeeDocumentLinkCreationMutation;

const createEmployeeDocumentsLinks = async (request: EmployeeDocumentLinkCreationMutation[]): Promise<Document[]> => {
    const { data } = await client.post<DocumentDTO[], AxiosResponse<DocumentDTO[]>, EmployeeDocumentLinkCreationRequestDTO[]>(
        API_BASE_URL + `/employee-documents/links`,
        request,
    );

    return data.map(mapDocumentDTO);
};

const createCompanyDocumentLink = async (request: CompanyDocumentLinkCreationMutation[]): Promise<Document[]> => {
    const { data } = await client.post<DocumentDTO[], AxiosResponse<DocumentDTO[]>, CompanyDocumentLinkCreationMutation[]>(
        API_BASE_URL + `/documents/links`,
        request,
    );

    return data.map(mapDocumentDTO);
};

const createCompanyDocuments = async (requests: CompanyDocumentCreationMutation): Promise<Document[]> => {
    const form = new FormData();
    form.append('metadata', new Blob([JSON.stringify({ folderId: requests.folderId })], { type: getAppConfig().MIME_TYPES.JSON }));

    for (const file of requests.files) {
        form.append('files', file.data, file.name);
    }
    const { data } = await client.postForm<DocumentDTO[], AxiosResponse<DocumentDTO[]>, FormData>(API_BASE_URL + `/documents/files`, form);
    return data.map(mapDocumentDTO);
};

const getEmployeeDocumentDownloadUrl = async (documentId: number, documentContentDisposition: DocumentContentDisposition): Promise<string> => {
    return (await client.get<string>(API_BASE_URL + `/employee-documents/${documentId}/url/${documentContentDisposition}`)).data;
};

const getCompanyDocumentDownloadUrl = async (documentId: number, documentContentDisposition: DocumentContentDisposition): Promise<string> => {
    return (await client.get<string>(API_BASE_URL + `/documents/${documentId}/url/${documentContentDisposition}`)).data;
};

const updateEmployeeDocument = async (documentId: number, request: DocumentUpdateMutation): Promise<Document> => {
    return (await client.put<Document>(API_BASE_URL + `/employee-documents/${documentId}`, request)).data;
};

const updateCompanyDocument = async (documentId: number, request: DocumentUpdateMutation): Promise<Document> => {
    return (await client.put(API_BASE_URL + `/documents/${documentId}`, request)).data;
};

export const documentAPI = {
    getFolders,
    updateFoldersOrder,
    getFolder,
    createFolder,
    updateFolder,
    deleteFolder,
    getCompanyFolders,
    getEmployeeFolders,
    getCompanyDocuments,
    getEmployeeDocuments,
    deleteCompanyDocument,
    deleteEmployeeDocument,
    getCompanyDocumentDownloadUrl,
    getEmployeeDocumentDownloadUrl,
    createCompanyDocuments,
    createCompanyDocumentLink,
    createEmployeeDocuments,
    createEmployeeDocumentsLinks,
    updateEmployeeDocument,
    updateCompanyDocument,
};
