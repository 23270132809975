import { Page } from '@/page/Pages';

const documentPage = {
    labelKey: 'sidebar.company_documents',
    path: '/documents/company-documents',
};

export const getDocumentPages = (): Page[] => {
    return [documentPage];
};
