import { PageContainer } from '@/routes/PageContainer';
import { RoutesConfig } from '@/routes/Routes.type';
import { TimesheetsAllPage } from '@/page/timesheet/TimesheetsAllPage';
import { TimesheetsBalancePage } from '@/page/timesheet/TimesheetsBalancePage';
import { TimesheetsMonthlySummaryPage } from '@/page/timesheet/TimesheetsMonthlySummaryPage';
import { Navigate, RouteObject } from 'react-router';
import { TimesheetsPendingPage } from '@/page/timesheet/TimesheetsPendingPage';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { TimesheetsInsightPage } from '@/page/timesheet/TimesheetsInsightPage';
import { TimesheetsWeeklyInsightPage } from '@/page/timesheet/TimesheetsWeeklyInsightPage';

const config: RoutesConfig[] = [
    {
        path: 'all',
        breadCrumb: [{ nameKey: 'pages.all_timesheets' }],
        component: <TimesheetsAllPage />,
    },
    {
        path: 'monthly-summary',
        breadCrumb: [{ nameKey: 'pages.monthly_summary' }],
        component: <TimesheetsMonthlySummaryPage />,
    },
    {
        path: 'pending',
        breadCrumb: [{ nameKey: 'pages.pending_timesheets' }],
        component: <TimesheetsPendingPage />,
    },
    {
        path: 'insights',
        breadCrumb: [{ nameKey: 'pages.timesheets_insights' }],
        component: <TimesheetsInsightPage />,
    },
    {
        path: 'weekly-insights',
        breadCrumb: [{ nameKey: 'pages.statutory_overtime' }],
        component: <TimesheetsWeeklyInsightPage />,
    },
    {
        path: 'balances',
        breadCrumb: [{ nameKey: 'pages.timesheets_balance' }],
        component: <TimesheetsBalancePage />,
    },
];

export const timesheetRoutes: RouteObject[] = [
    {
        path: 'timesheets',
        errorElement: <RouterBoundary />,
        children: [
            {
                path: '',
                element: <Navigate to='/timesheets/monthly-summary' replace />,
            },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
