import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { getLocationById, getLocations } from '@/domain/location/Location.service';
import { Location } from '@/domain/location/Location.model';
import { handleError } from '@/utils/api.util';

export const useGetLocations = (): UseQueryResult<Location[]> => {
    const [locations, setLocations] = useState<Location[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLocations = useCallback(async () => {
        setIsLoading(true);

        try {
            const locations = await getLocations();
            setLocations(locations);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchLocations().catch(handleError);
    }, [fetchLocations]);

    return {
        data: locations,
        setData: setLocations,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchLocations(),
    };
};

export const useGetLocation = (id?: number): UseQueryResult<Location> => {
    const [location, setLocation] = useState<Location>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLocation = useCallback(async () => {
        if (id) {
            try {
                const location = await getLocationById(id);
                setLocation(location);
            } catch (error) {
                setError(error);
            }
        }

        setIsLoading(false);
    }, [id]);

    useEffect(() => {
        fetchLocation().catch(handleError);
    }, [fetchLocation]);

    return {
        data: location,
        setData: setLocation,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchLocation(),
    };
};
