import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { getEmployeeSkills } from '@/domain/employee-skill/EmployeeSkill.service';
import { EmployeeSkill } from '@/domain/employee-skill/EmployeeSkill.model';

export const useGetEmployeeSkills = (employeeId?: number): UseQueryResult<EmployeeSkill[]> => {
    const [employeeSkill, setEmployeeSkill] = useState<EmployeeSkill[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeSkills = useCallback(async () => {
        if (!employeeId || isNaN(employeeId)) {
            setIsLoading(false);
            return;
        }
        try {
            const employeeSkillsData = await getEmployeeSkills(employeeId);
            setEmployeeSkill(employeeSkillsData);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [employeeId]);

    useEffect(() => {
        fetchEmployeeSkills().catch(handleError);
    }, [fetchEmployeeSkills]);

    return {
        data: employeeSkill,
        refetch: fetchEmployeeSkills,
        setData: setEmployeeSkill,
        isLoading,
        isError: !!error,
        error,
    };
};
