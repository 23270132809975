import { DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { Employee } from '@/domain/employee/Employee.model';
import {
    DailyTimesheetReport,
    EmployeeTimesheetMutation,
    Timesheet,
    TimesheetAction,
    TimesheetMutation,
    TimesheetType,
} from '@/domain/timesheet/Timesheet.model';
import { addHours, addMinutes, differenceInMinutes, formatDurationInTime, setHoursMinutes, splitHoursMinutes } from '@/utils/datetime.util';
import { FC, useEffect, useRef } from 'react';

import { FieldTime } from '@/components/form/field-time/FieldTime';
import { canApproveRejectTimesheets } from '@/domain/permission/Permission.service';
import { createTimesheets, updatePendingTimesheet, updateTimesheet } from '@/domain/timesheet/Timesheet.service';
import { useGetAreas } from '@/hooks/area/Area.hook';
import { TIMESHEET_DEFAULT_END_TIME_HOUR, TIMESHEET_DEFAULT_START_TIME_HOUR } from '@/page/employee-timesheet/EmployeeTimesheet.util';
import {
    EmployeeTimesheetSimplifiedModeFormValues,
    getEmployeeTimesheetSimplifiedModeSchema,
    TimesheetSimplifiedModeFormValues,
} from '@/page/timesheet/timesheet-dialog/TimesheetDialog.schema';
import {
    getOriginalTimesheets,
    hasApprovedTimesheets,
    hasAtLeastOneTimesheetType,
    isForceCreation,
    TIMESHEET_DEFAULT_DURATION,
} from '@/page/timesheet/timesheet-dialog/TimesheetDialog.util';
import { AreaField, CommentField, MissingDatesField, ReferenceDateField } from '@/page/timesheet/timesheet-dialog/TimesheetDialogCommonFields';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, DialogActions, DialogContent, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { Add01Icon, Delete02Icon } from 'hugeicons-react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TimesheetSimplifiedModeDialogContentProps = {
    referenceDate: LocalDate;
    onReferenceDateChange: (date: LocalDate) => void;
    dailyTimesheetReports: DailyTimesheetReport[];
    mode: TimesheetAction;
    onSave: () => void;
    employee: Employee;
    missingEntriesDates?: LocalDate[]; //used when we want to complete multiple missing timesheets at once
} & DialogWrapperProps;

export const TimesheetSimplifiedModeDialogContent: FC<TimesheetSimplifiedModeDialogContentProps> = props => {
    const { referenceDate, onReferenceDateChange, dailyTimesheetReports, mode, onSave, employee, missingEntriesDates = [] } = props;
    const refTimesheetErrorMessage = useRef<HTMLDivElement>(getNull());
    const timesheetSetting = employee.currentWorkingPattern?.timesheetSetting;

    const { t } = useTranslation();
    const policies = useCurrentPolicies();

    const { data: areas = [] } = useGetAreas({
        locationIds: employee.currentEmployments.flatMap(employment => employment.location.id),
    });

    const getTimesheetDefaultValues = (referenceDate: LocalDate) => {
        const originalTimesheets = getOriginalTimesheets(dailyTimesheetReports);
        if (originalTimesheets.length === 0) {
            return createDefaultTimesheetFormValues(referenceDate);
        }

        const availableTimesheets = originalTimesheets.filter(
            timesheet =>
                timesheet.type === TimesheetType.TIMESHEET ||
                timesheet.type === TimesheetType.SHIFT_TIMESHEET ||
                timesheet.type === TimesheetType.MISSING ||
                timesheet.type === TimesheetType.AUTOFILL,
        );

        return availableTimesheets.length > 0 ? mapTimesheetDialogData(originalTimesheets) : createDefaultTimesheetFormValues(referenceDate);
    };

    const formMethods = useForm<EmployeeTimesheetSimplifiedModeFormValues>({
        //TODO dont use the 'all' and use validate start end and break
        mode: 'all',
        resolver: yupResolver(getEmployeeTimesheetSimplifiedModeSchema(timesheetSetting)),
        values: {
            missingDates: missingEntriesDates,
            employeeId: employee.id,
            referenceDate: referenceDate,
            timesheets: getTimesheetDefaultValues(referenceDate),
        },
    });

    const {
        formState: { errors, isDirty },
        control,
        handleSubmit,
        watch,
        getValues,
    } = formMethods;

    const {
        fields: timesheetsFields,
        append: addTimesheet,
        remove: removeTimesheet,
    } = useFieldArray({
        control,
        name: 'timesheets',
    });

    const timesheets = watch('timesheets');
    const hasTimesheetError = !!errors?.timesheets?.message || !!errors?.timesheets?.root?.message;

    // Scroll to the error message
    useEffect(() => {
        const elementRef = refTimesheetErrorMessage.current;
        if (hasTimesheetError && elementRef) {
            elementRef?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [hasTimesheetError]);

    // add a new slot
    const handleAddNewTimesheet = () => {
        const lastTimesheet = timesheets[timesheets.length - 1];

        addTimesheet({
            duration: lastTimesheet.duration,
            comment: '',
            area: getNull(),
        });
    };

    const handleSaveTimesheet = (formValues: EmployeeTimesheetSimplifiedModeFormValues) => {
        if (mode === TimesheetAction.MISSING_TIMESHEET) {
            saveTimesheetsForMissingTimesheets(formValues);
        } else {
            createOrEditTimesheets(formValues);
        }
    };

    const hasExistingTimesheetsInCurrentDate = hasAtLeastOneTimesheetType(getOriginalTimesheets(dailyTimesheetReports));

    const createOrEditTimesheets = (formValues: EmployeeTimesheetSimplifiedModeFormValues) => {
        const payload: EmployeeTimesheetMutation = {
            ...formValues,
            timesheets: mapToTimesheetMutation(formValues.timesheets, formValues.referenceDate),
        };

        if (isForceCreation(mode, hasExistingTimesheetsInCurrentDate)) {
            handleTimesheetCreation(payload).catch(handleError);
        } else {
            handleTimesheetEditing(payload).catch(handleError);
        }
    };

    const handleTimesheetCreation = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        try {
            await createTimesheets(employeeTimesheetMutation, { withApproval: canApproveRejectTimesheets(policies, employeeTimesheetMutation.employeeId) });
            onSave();
        } catch (error) {
            handleErrorOnSave(error);
        }
    };

    const handleTimesheetEditing = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        const originalTimesheets = getOriginalTimesheets(dailyTimesheetReports);
        try {
            if (hasApprovedTimesheets(originalTimesheets)) {
                await updateTimesheet(employeeTimesheetMutation, { withApproval: canApproveRejectTimesheets(policies, employeeTimesheetMutation.employeeId) });
            } else {
                await updatePendingTimesheet(employeeTimesheetMutation, {
                    withApproval: canApproveRejectTimesheets(policies, employeeTimesheetMutation.employeeId),
                });
            }
            onSave();
        } catch (error) {
            handleErrorOnSave(error);
        }
    };

    const handleErrorOnSave = (error: unknown) => {
        if (axios.isAxiosError(error) && error.response?.status === 409) {
            showSnackbar(t('timesheets.error_overlapping_timesheets'), 'error');
        } else {
            handleError(error);
        }
    };

    const saveTimesheetsForMissingTimesheets = (formValues: EmployeeTimesheetSimplifiedModeFormValues) => {
        const { missingDates: missingDatesFromForm } = getValues();
        if (!missingEntriesDates || !missingDatesFromForm) {
            return;
        }

        //search with the dates!
        const missingDates = missingEntriesDates.filter(date => {
            return missingDatesFromForm.some(missingDate => {
                return missingDate === date;
            });
        });

        missingDates.forEach(missingDate => {
            const missingEmployeeTimesheet: EmployeeTimesheetMutation = {
                employeeId: employee.id,
                referenceDate: missingDate,
                timesheets: mapToTimesheetMutation(formValues.timesheets, missingDate),
            };

            handleTimesheetEditing(missingEmployeeTimesheet).catch(handleError);
        });
    };

    return (
        <>
            <DialogContent>
                <FormProvider {...formMethods}>
                    <Stack gap={2}>
                        {mode === TimesheetAction.MISSING_TIMESHEET && missingEntriesDates ? (
                            <MissingDatesField missingEntriesDates={missingEntriesDates} />
                        ) : (
                            <ReferenceDateField mode={mode} onChange={onReferenceDateChange} />
                        )}
                        {timesheetsFields?.map((timesheetCreationFormat, timesheetIndex) => {
                            return (
                                <Stack key={timesheetCreationFormat.id} gap={0.5}>
                                    <Stack direction={'row'} alignItems={'flex-end'}>
                                        <FormControlLabel
                                            label={t('general.duration')}
                                            sx={{ width: '100%' }}
                                            control={<FieldTime name={`timesheets.${timesheetIndex}.duration`} control={control} sx={{ width: '100%' }} />}
                                        />
                                        {timesheetsFields?.length > 1 && (
                                            <IconButton onClick={() => removeTimesheet(timesheetIndex)} sx={{ color: 'text.primary' }}>
                                                <Delete02Icon />
                                            </IconButton>
                                        )}
                                    </Stack>

                                    {/*AREA*/}
                                    {!!areas.length && <AreaField timesheetIndex={timesheetIndex} areas={areas} />}

                                    {/*COMMENT*/}
                                    <CommentField timesheetIndex={timesheetIndex} />
                                </Stack>
                            );
                        })}

                        <Button onClick={handleAddNewTimesheet} startIcon={<Add01Icon />} color='primary' variant={'text'} fullWidth>
                            {t('timesheets.add_another_timeslot')}
                        </Button>

                        <Stack ref={refTimesheetErrorMessage} sx={{ width: '100%' }}>
                            {hasTimesheetError && (
                                <Alert severity='error' elevation={0} sx={{ alignItems: 'center' }}>
                                    <Typography variant='body2'>{errors?.timesheets?.message ?? errors?.timesheets?.root?.message}</Typography>
                                </Alert>
                            )}
                        </Stack>
                    </Stack>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(handleSaveTimesheet, console.error)} fullWidth disabled={hasExistingTimesheetsInCurrentDate && !isDirty}>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </>
    );
};

const createDefaultTimesheetFormValues = (date: LocalDate): TimesheetSimplifiedModeFormValues[] => {
    const startTime = setHoursMinutes(date, TIMESHEET_DEFAULT_START_TIME_HOUR, 0, 0, 0);
    startTime.setHours(TIMESHEET_DEFAULT_START_TIME_HOUR, 0, 0, 0);

    const intervalInHours = TIMESHEET_DEFAULT_END_TIME_HOUR - TIMESHEET_DEFAULT_START_TIME_HOUR;

    return [
        {
            // startTime: startTime,
            duration: formatDurationInTime(intervalInHours * 60),
            comment: '',
            area: getNull(),
        },
    ];
};

const mapTimesheetDialogData = (timesheets: Timesheet[]): TimesheetSimplifiedModeFormValues[] => {
    return timesheets.map(timesheet => {
        const durationInMinutes = timesheet.endAt ? differenceInMinutes(timesheet.endAt, timesheet.startAt) : TIMESHEET_DEFAULT_DURATION;
        return {
            id: timesheet.id,
            duration: formatDurationInTime(durationInMinutes),
            comment: timesheet.comment,
            area: timesheet?.area ?? getNull(),
        };
    });
};

/**
 * build startTime and endTime for each timesheet based on durations
 */
const mapToTimesheetMutation = (timesheets: TimesheetSimplifiedModeFormValues[], referenceDate: LocalDate) => {
    return timesheets.reduce<TimesheetMutation[]>((accumulator, timesheet, index) => {
        const newStartDate = index === 0 ? setHoursMinutes(referenceDate, TIMESHEET_DEFAULT_START_TIME_HOUR, 0, 0) : accumulator[index - 1].endTime;

        const calculateEndTime = (start: Date, duration: LocalTime): Date => {
            const { hours, minutes } = splitHoursMinutes(duration);
            return addMinutes(addHours(start, hours), minutes);
        };

        const { duration, ...restTimesheet } = timesheet;
        const updatedTimesheet: TimesheetMutation = {
            ...restTimesheet,
            startTime: newStartDate,
            endTime: calculateEndTime(newStartDate, duration),
            id: timesheet.id ?? undefined,
            areaId: timesheet.area?.id,
        };
        accumulator.push(updatedTimesheet);

        return accumulator;
    }, []);
};
