import { API_BASE_URL, buildImportFormData, client } from '@/api/common';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { AxiosResponse } from 'axios';

const importBasicInformation = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(API_BASE_URL + `/employees/basic-info/import`, formData)).data;
};

export const employeeBasicInfoAPI = {
    importBasicInformation,
};
