import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { EmployeeAvatar as EmployeeAvatarType, EmployeeStatus } from '@/domain/employee/Employee.model';
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { createDefaultLabel } from '@/domain/label/Label.service';

type Props = {
    text: string;
    selectedWord?: string;
    employee?: EmployeeAvatarType;
};

export const Comment: FC<Props> = ({ text, selectedWord, employee }) => {
    const defaultUser = {
        id: 0,
        firstName: 'John',
        lastName: 'Doe',
        email: '',
        userStatus: EmployeeStatus.INACTIVE,
        username: '',
        avatarImageUrl: '',
        jobTitle: createDefaultLabel(),
    };

    const makeBold = (text: string, selectedWord: string) => {
        return text.replace(new RegExp('(\\b)(' + selectedWord + ')(\\b)', 'ig'), '$1<b>$2</b>$3');
    };

    if (!text) {
        return <></>;
    }

    return (
        <Grid container spacing={2} alignItems={'top'} sx={{ mb: 2 }}>
            <Grid item>
                <Grid container direction='row' spacing={1} width={34} alignItems={'center'}>
                    <Tooltip placement='left' title={employee ? `${employee.displayName}` : ''}>
                        <Grid item>
                            {!employee ? (
                                <Avatar
                                    alt={`${defaultUser.firstName} ${defaultUser.lastName}`}
                                    src={defaultUser.avatarImageUrl}
                                    sx={theme => ({
                                        width: 24,
                                        height: 24,
                                        fontSize: 16,
                                        borderRadius: 64,
                                        backgroundColor: theme.palette.grey[200],
                                        '& svg': {
                                            fill: theme.palette.text.primary,
                                        },
                                    })}
                                />
                            ) : (
                                <EmployeeAvatar employeeAvatar={employee} />
                            )}
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>

            {text && (
                <Grid item xs={12} sm>
                    {selectedWord ? (
                        <Typography variant='body2' dangerouslySetInnerHTML={{ __html: makeBold(text, selectedWord) }} />
                    ) : (
                        <Typography variant='body2'>{text}</Typography>
                    )}
                </Grid>
            )}
        </Grid>
    );
};
