import { EmployeeReviewFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeReviewFeedbackItemForm } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { Objective } from '@/domain/objective/Objective.model';
import { Employee } from '@/domain/employee/Employee.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Chip, Paper, Stack, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { EmployeeReviewFeedbackFormPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackFormPDF';
import { isManagerHasPreparationAccess } from '@/domain/employee-review/EmployeeReview.service';
import { EmployeeReviewFeedbackViewFeedbacks } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackViewFeedbacks';
import { EmployeeReviewAvatar } from '@/page/review/employee-review-avatar/EmployeeReviewAvatar';
import { getLabelTranslation } from '@/utils/language.util';

type EmployeeReviewFeedbackFormPropsHeader = {
    employeeReviewFeedback: EmployeeReviewFeedback | undefined;
    employeeReviewFeedbackItems: EmployeeReviewFeedbackItemForm[];
    employeeReview: EmployeeReview;
    isPreview: boolean;
    objectives: Objective[];
    currentEmployee: Employee;
};

export const EmployeeReviewFeedbackFormHeader: FC<EmployeeReviewFeedbackFormPropsHeader> = ({
    employeeReviewFeedback,
    employeeReview,
    isPreview,
    objectives,
    currentEmployee,
    employeeReviewFeedbackItems,
}) => {
    const { t } = useTranslation();

    const getReviewFormTitleName = (employeeReview: EmployeeReview | undefined, employeeReviewFeedback: EmployeeReviewFeedback | undefined) => {
        if (isPreview && employeeReview?.review) {
            return getLabelTranslation(employeeReview.review.name);
        }
        if (employeeReviewFeedback) {
            return getLabelTranslation(employeeReviewFeedback.reviewName);
        }
    };

    return (
        <Stack component={Paper}>
            {employeeReviewFeedback && (
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                    <Typography variant='h2'>{getReviewFormTitleName(employeeReview, employeeReviewFeedback)}</Typography>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        {isPreview && <Chip color='warning' size='small' label={t('reviews.write_feedback.preview_mode')} />}
                        <Button>
                            <PDFDownloadLink
                                style={{ textDecoration: 'none' }}
                                document={
                                    <EmployeeReviewFeedbackFormPDF
                                        employeeReviewFeedback={employeeReviewFeedback}
                                        employeeReviewFeedbackItems={employeeReviewFeedbackItems}
                                        employeeReview={employeeReview}
                                        objectives={objectives ?? []}
                                    />
                                }
                            >
                                <Typography color={'white'}>{t('reviews.review_summary.export')}</Typography>
                            </PDFDownloadLink>
                        </Button>
                        {employeeReview && currentEmployee && isManagerHasPreparationAccess(employeeReview, currentEmployee) && (
                            <EmployeeReviewFeedbackViewFeedbacks employeeReview={employeeReview} />
                        )}
                    </Stack>
                </Stack>
            )}

            <Stack direction={'row'} justifyContent={'space-between'}>
                {employeeReviewFeedback?.employee && <EmployeeReviewAvatar employee={employeeReviewFeedback?.employee} />}
            </Stack>
        </Stack>
    );
};
