import { PageContainer } from '@/routes/PageContainer';
import { EmployeeSettingsPage } from '@/page/employee-setting/EmployeeSettingsPage';
import { RouteObject } from 'react-router';
import { RouterBoundary } from '@/routes/RouterBoundary';

export const employeeSettingsRoutes: RouteObject[] = [
    {
        path: '/user',
        element: (
            <PageContainer breadCrumb={[{ nameKey: 'pages.user' }]}>
                <EmployeeSettingsPage />
            </PageContainer>
        ),
        errorElement: <RouterBoundary />,
    },
];
