import { Components, Theme } from '@mui/material';

export const MuiInputBase: Components<Omit<Theme, 'components'>>['MuiInputBase'] = {
    defaultProps: {
        size: 'small',
    },
    styleOverrides: {
        input: {
            height: '20px',
        },
    },
};

export const MuiOutlinedInput: Components<Omit<Theme, 'components'>>['MuiOutlinedInput'] = {
    defaultProps: {},
    styleOverrides: {},
    variants: [
        {
            props: {},
            style: ({ theme }) => ({
                backgroundColor: theme.palette.common.white,
                borderRadius: theme.shape.borderRadius,
                // we don't want to change the color of the label when the input is hovered
                '&.MuiOutlinedInput-root:hover:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.grey[300],
                },
                '&.Mui-disabled .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.grey[300],
                },
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.grey[200],
                },
            }),
        },
    ],
};
