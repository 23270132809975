import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { UnitType } from '@/domain/date/Date.model';
import { getLeaveRequestBalanceAsString } from '@/domain/leave-request/LeaveRequest.service';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type ConfirmNegativeBalanceDialogProps = {
    onSave: () => void;
    onClose: () => void;
    leaveRequestUnitType: UnitType;
    leaveType: LeaveType;
    availableAmountInDays: number | undefined;
    availableAmountInMinutes: number | undefined;
};

export const ConfirmNegativeBalanceDialog: FC<ConfirmNegativeBalanceDialogProps> = ({
    onSave,
    onClose,
    leaveRequestUnitType,
    leaveType,
    availableAmountInDays = 0,
    availableAmountInMinutes = 0,
}) => {
    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        onSave();
    };

    return (
        <DialogWrapper open={true} onClose={handleClose} header={t('confirm_negative_balance_dialog.title')}>
            <DialogContent>
                <Typography>
                    {t('confirm_negative_balance_dialog.message', {
                        duration: getLeaveRequestBalanceAsString(
                            leaveRequestUnitType,
                            leaveType,
                            availableAmountInDays,
                            availableAmountInMinutes,
                            leaveType?.roundingType,
                        ),
                    })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button fullWidth onClick={handleClose} color='inherit'>
                    {t('confirm_negative_balance_dialog.cancel')}
                </Button>
                <Button fullWidth onClick={handleSave}>
                    {t('confirm_negative_balance_dialog.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
