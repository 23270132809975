import { ImportPageType, ImportResult, ImportType } from '@/domain/import/Import.model';
import { getUserLanguage } from '@/utils/language.util';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { CustomSectionsImportCard } from './custom-section-import-card/CustomSectionsImportCard';
import { DefaultInformation, DefaultSectionsImportCard } from './custom-section-import-card/DefaultSectionsImportCard';
import { ImportExcelDialog } from './import-excel-dialog/ImportExcelDialog';

type ImportPageProps = {
    importPageType: ImportPageType;
};

export const ImportPage: FC<ImportPageProps> = ({ importPageType }) => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const importType = searchParams.get('importType') as ImportType | undefined;
    const sectionId = searchParams.get('sectionId') ? Number(searchParams.get('sectionId')) : undefined;

    const handleUploadClick = (importType: ImportType, sectionId?: number) => {
        if (sectionId) {
            // replace option is used to prevent loop of opening and closing the modal with the back button
            setSearchParams(
                {
                    importType: importType.toString(),
                    sectionId: sectionId.toString(),
                },
                { replace: true },
            );
        } else {
            setSearchParams(
                {
                    importType: importType.toString(),
                },
                { replace: true },
            );
        }
    };

    const getDefaultInformationByImportPageType = (importPageType: ImportPageType) => {
        return defaultInformationByImportPageType[importPageType];
    };

    const getDefaultInformationByImportType = (importType: ImportType) => {
        const defaultInformation = getDefaultInformationByImportPageType(importPageType);
        return defaultInformation.find(defaultInformation => {
            return defaultInformation.importType === importType;
        });
    };

    const handleCloseModal = () => {
        // replace option is used to prevent loop of opening and closing the modal with the back button
        setSearchParams({}, { replace: true });
    };

    const handleImportResponse = (importResult: ImportResult, importType: ImportType) => {
        if (importType === ImportType.CUSTOM_SECTION) {
            navigate(`/settings/results/custom-sections/${sectionId}`, { state: importResult });
            return;
        }

        const resultUrl = getDefaultInformationByImportType(importType)?.resultUrl;
        if (resultUrl) {
            navigate(resultUrl, { state: importResult });
        }
    };

    return (
        <Stack gap={2}>
            <DefaultSectionsImportCard
                titleKey={'import_page.default_information'}
                defaultInformation={getDefaultInformationByImportPageType(importPageType)}
                handleUploadClick={handleUploadClick}
            />
            {importPageType === ImportPageType.EMPLOYEE && <CustomSectionsImportCard handleUploadClick={handleUploadClick} />}
            {!!importType && (
                <ImportExcelDialog
                    sectionId={sectionId}
                    importType={importType}
                    handleCloseModal={handleCloseModal}
                    handleImportResponse={handleImportResponse}
                />
            )}
        </Stack>
    );
};

const defaultInformationByImportPageType: Record<ImportPageType, DefaultInformation[]> = {
    [ImportPageType.EMPLOYEE]: [
        {
            titleTranslationKey: 'import_page.create_employees_title',
            downloadTemplateLink: `/import_templates/employee_import_template_${getUserLanguage()}.xlsx`,
            importType: ImportType.EMPLOYEE,
            resultUrl: '/settings/results/employees',
        },
        {
            titleTranslationKey: 'import_page.basic_information_title',
            downloadTemplateLink: `/import_templates/basic_info_import_template_${getUserLanguage()}.xlsx`,
            importType: ImportType.BASIC_INFORMATION,
            resultUrl: '/settings/results/basic_information',
        },
        {
            titleTranslationKey: 'import_page.addresses_title',
            downloadTemplateLink: `/import_templates/address_import_template_${getUserLanguage()}.xlsx`,
            importType: ImportType.ADDRESS,
            resultUrl: '/settings/results/addresses',
        },
    ],
    [ImportPageType.LEAVES]: [
        {
            titleTranslationKey: 'import_page.leaves',
            downloadTemplateLink: '/import_templates/leave_import_template.xlsx',
            importType: ImportType.LEAVE,
            resultUrl: '/settings/results/leaves',
        },
        {
            titleTranslationKey: 'import_page.leave_balance_adjustments',
            downloadTemplateLink: '/import_templates/leave_correction_import_template.xlsx',
            importType: ImportType.LEAVE_CORRECTION,
            resultUrl: '/settings/results/leave_balance_adjustments',
        },
    ],
    [ImportPageType.TIMESHEET]: [
        {
            titleTranslationKey: 'import_page.timesheets',
            downloadTemplateLink: '/import_templates/timesheet_import_template.xlsx',
            importType: ImportType.TIMESHEET,
            resultUrl: '/settings/results/timesheets',
        },
        {
            titleTranslationKey: 'import_page.timesheets_adjustments',
            downloadTemplateLink: '/import_templates/timesheet_adjustment_import_template.xlsx',
            importType: ImportType.TIMESHEET_ADJUSTMENT,
            resultUrl: '/settings/results/timesheets_adjustments',
        },
    ],
    [ImportPageType.ORGANIZATION]: [
        {
            titleTranslationKey: 'import_page.job_families',
            downloadTemplateLink: `/import_templates/job_families_import_template_${getUserLanguage()}.xlsx`,
            importType: ImportType.JOB_FAMILY,
            resultUrl: '/settings/results/job-families',
        },
        {
            titleTranslationKey: 'import_page.jobs',
            downloadTemplateLink: `/import_templates/jobs_import_template_${getUserLanguage()}.xlsx`,
            importType: ImportType.JOB,
            resultUrl: '/settings/results/jobs',
        },
        {
            titleTranslationKey: 'import_page.cost_centers',
            downloadTemplateLink: `/import_templates/import_cost_centers.xlsx`,
            importType: ImportType.COST_CENTER,
            resultUrl: '/settings/results/cost-centers',
        },
        {
            titleTranslationKey: 'import_page.departments',
            downloadTemplateLink: `/import_templates/import_departments.xlsx`,
            importType: ImportType.DEPARTMENT,
            resultUrl: '/settings/results/departments',
        },
        {
            titleTranslationKey: 'import_page.planning_positions',
            downloadTemplateLink: `/import_templates/planning_positions_import_template.xlsx`,
            importType: ImportType.PLANNING_POSITION,
            resultUrl: '/settings/results/planning-positions',
        },
        {
            titleTranslationKey: 'import_page.calendar_days_title',
            downloadTemplateLink: `/import_templates/calendar_days_import_template_${getUserLanguage()}.xlsx`,
            importType: ImportType.CALENDAR_DAYS,
            resultUrl: '/settings/results/calendar_days',
        },
    ],
    [ImportPageType.OBJECTIVE]: [
        {
            titleTranslationKey: 'import_page.objectives',
            downloadTemplateLink: `/import_templates/import_objectives.xlsx`,
            importType: ImportType.OBJECTIVE,
            resultUrl: '/settings/results/objectives',
        },
    ],
    [ImportPageType.SKILL]: [
        {
            titleTranslationKey: 'import_page.skills',
            downloadTemplateLink: `/import_templates/skills_import_template_${getUserLanguage()}.xlsx`,
            importType: ImportType.SKILL,
            resultUrl: '/settings/results/skills',
        },
    ],
};
