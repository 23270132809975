import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    onClosePhotoPreview: () => void;
    imageUrl: string;
    onDeletePhoto: () => void;
};

export const PhotoPreview: FC<Props> = ({ open, onClosePhotoPreview, imageUrl, onDeletePhoto }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={() => onClosePhotoPreview()}>
            <DialogContent>
                <Box textAlign={'center'}>{imageUrl ? <img src={imageUrl} alt={'Preview'} /> : <></>}</Box>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='error' onClick={onDeletePhoto}>
                    {t('general.delete')}
                </Button>
                <Button onClick={onClosePhotoPreview}>{t('general.close')}</Button>
            </DialogActions>
        </Dialog>
    );
};
