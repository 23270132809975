import { EmptyState } from '@/components/empty-state/EmptyState';
import { ErrorEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { FullScreenProgress } from '@/components/full-screen-progress/FullScreenProgress';
import { getAppEnv } from '@/config/config';
import { getCurrentEmployee, updateEmployeeLogin } from '@/domain/employee/Employee.service';
import { getRealmByName } from '@/domain/realm/Realm.service';
import { employeeAuthenticated } from '@/stores/reducers/currentEmployeeSlice';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { useAppDispatch } from '@/stores/store';
import { setRealmLanguage, setUserLanguage } from '@/utils/language.util';
import { substringBeforeFirst } from '@/utils/strings.util';
import { Button, Paper, Stack } from '@mui/material';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { isAxiosError } from 'axios';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [apiNotAvailable, setApiNotAvailable] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);

    const onUserSignIn = () => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                updateEmployeeLogin({
                    username: user.username,
                }).catch(error => {
                    console.error(error);
                });
            })
            .catch(error => {
                console.error(error);
            });
    };

    // Listener for authentication events
    Hub.listen(
        'auth',
        data => {
            switch (data.payload.event) {
                case 'customOAuthState':
                    // data contains the attempted url before login
                    if (data.payload.data) {
                        window.history.pushState({}, '', data.payload.data);
                    }
                    break;
                case 'signIn':
                    onUserSignIn();
                    break;
                case 'signIn_failure':
                    console.error('user sign in failed');
                    break;
                case 'signOut':
                case 'signUp':
                case 'configured':
                default:
                // nothing to do here
            }
        },
        'authListener',
    );

    useEffect(() => {
        setLoading(true);
        const host = window.location.host;
        const subdomain = substringBeforeFirst(host, '.');
        const env = getAppEnv();
        if (!subdomain || !env) {
            console.error('invalid url');
            setNotFound(true);
            setLoading(false);
            dispatch({ type: UiActionType.REALM_LOADED, realm: undefined });
            return;
        }
        getRealmByName(subdomain)
            .then(realm => {
                setRealmLanguage(realm.defaultLanguage);
                Amplify.configure({
                    Auth: {
                        mandatorySignIn: true,
                        region: realm.region,
                        userPoolId: realm.tenantId,
                        //identityPoolId: config.cognito.IDENTITY_POOL_ID, //not necessary if not using aws federation
                        userPoolWebClientId: realm.appClientId,
                        authenticationFlowType: 'USER_PASSWORD_AUTH',
                        oauth: {
                            domain: realm.domain,
                            scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
                            redirectSignIn: `https://${window.location.host}`,
                            redirectSignOut: `https://${window.location.host}`,
                            responseType: 'code',
                            options: {
                                AdvancedSecurityDataCollectionFlag: false,
                            },
                        },
                    },
                });

                Auth.currentAuthenticatedUser({ bypassCache: true })
                    .then(async () => {
                        // user already signed in
                        try {
                            const data = await getCurrentEmployee();
                            if (data?.employee?.language) {
                                await setUserLanguage(data?.employee?.language);
                            }
                            dispatch(employeeAuthenticated(data));
                        } finally {
                            setLoading(false);
                        }
                    })
                    .catch(() => {
                        // user not signed in
                        setLoading(false);
                    });

                dispatch({ type: UiActionType.REALM_LOADED, realm: realm });
            })
            .catch(error => {
                // Display error message if the API returns an error
                if (isAxiosError(error)) {
                    setApiNotAvailable(true);
                }
                console.error(error);
                dispatch({ type: UiActionType.REALM_LOADED, realm: undefined });
                setLoading(false);
            });
    }, [dispatch]);

    if (loading) {
        return <FullScreenProgress />;
    }

    if (apiNotAvailable || notFound) {
        return (
            <Stack
                component={Paper}
                width={{ xs: '80vw', sm: 440 }}
                p={{ xs: 3, sm: 5 }}
                left='50%'
                top='50%'
                position='absolute'
                sx={{
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <EmptyState
                    title={notFound ? t('general.errors.not_found') : t('general.errors.not_available')}
                    subTitle={notFound ? t('general.errors.not_found_sub_title') : t('general.errors.not_available_sub_title')}
                    icon={<ErrorEmptyStateIcon />}
                    action={
                        <Button variant='contained' color='primary' onClick={() => window.location.reload()}>
                            {t('general.reload')}
                        </Button>
                    }
                />
            </Stack>
        );
    }

    return children;
};
