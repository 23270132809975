import { Stack, StackProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type EmptyStateProps = StackProps & {
    icon?: ReactNode;
    title?: string;
    subTitle?: string;
    action?: ReactNode;
};

export const EmptyState: FC<EmptyStateProps> = ({ icon, title, subTitle, action, ...rest }) => {
    return (
        <Stack justifyContent='center' alignItems='center' gap={2} {...rest}>
            {icon && <Stack justifyContent='flex-end'>{icon}</Stack>}
            {title && <Typography variant='h1'>{title}</Typography>}
            {subTitle && <Typography variant='body1'>{subTitle}</Typography>}
            {action}
        </Stack>
    );
};
