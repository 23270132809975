import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { handleError } from '@/utils/api.util';
import { EmployeeNotification, EmployeeNotificationSearchRequest } from '@/domain/employee-notification/EmployeeNotification.model';
import { getOwnEmployeeNotifications, searchEmployeeNotifications } from '@/domain/employee-notification/EmployeeNotification.service';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetOwnEmployeeNotification = (): UseQueryResult<EmployeeNotification> => {
    const [employeeNotification, setEmployeeNotification] = useState<EmployeeNotification>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchOwnEmployeeNotification = useCallback(async () => {
        try {
            const empNotification = await getOwnEmployeeNotifications();
            setEmployeeNotification(empNotification);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchOwnEmployeeNotification().catch(handleError);
    }, [fetchOwnEmployeeNotification]);

    return {
        data: employeeNotification,
        setData: setEmployeeNotification,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchOwnEmployeeNotification,
    };
};

export const useSearchEmployeeNotification = (searchRequest: EmployeeNotificationSearchRequest = {}): UseQueryResult<EmployeeNotification[]> => {
    const [employeeNotifications, setEmployeeNotifications] = useState<EmployeeNotification[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const searchEmployeesNotification = useCallback(async (searchRequest: EmployeeNotificationSearchRequest) => {
        try {
            const empsNotifications = await searchEmployeeNotifications(searchRequest);
            setEmployeeNotifications(empsNotifications);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        searchEmployeesNotification(searchRequest).catch(handleError);
    }, [searchRequest, searchEmployeesNotification]);

    return {
        data: employeeNotifications,
        setData: setEmployeeNotifications,
        isLoading,
        isError: !!error,
        error,
        refetch: () => searchEmployeesNotification(searchRequest),
    };
};
