import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { getCustomLists } from '@/domain/custom-list/CustomList.service';
import { CustomList } from '@/domain/custom-list/CustomList.model';

export const useGetCustomLists = (): UseQueryResult<CustomList[]> => {
    const [customLists, setCustomLists] = useState<CustomList[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCustomLists = useCallback(async () => {
        try {
            const customLists = await getCustomLists();
            setCustomLists(customLists);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchCustomLists().catch(handleError);
    }, [fetchCustomLists]);

    return {
        data: customLists,
        setData: setCustomLists,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchCustomLists(),
    };
};
