import { Paper, Stack, Typography } from '@mui/material';
import { ArrowRight01Icon } from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    available?: string;
    remaining?: string;
    remainingAmountInMinutes?: number;
};

export const HoursAvailableComponent: FC<Props> = ({ available, remaining, remainingAmountInMinutes }) => {
    const { t } = useTranslation();
    const isRemainingNegative = !!remainingAmountInMinutes && remainingAmountInMinutes < 0;
    return (
        <Stack component={Paper} direction='row' alignItems='center' justifyContent='space-around' p={1} border={1} borderColor={'grey.300'}>
            <Stack spacing={0}>
                <Typography variant='h2'>{available ?? '-'}</Typography>
                <Typography variant='body2'>{t('request_overtime_dialog.current')}</Typography>
            </Stack>

            <ArrowRight01Icon />

            <Stack spacing={0}>
                <Typography variant='h2' color={isRemainingNegative ? 'error' : undefined}>
                    {remaining ?? '-'}
                </Typography>
                <Typography variant='body2'>{t('request_overtime_dialog.new_balance')}</Typography>
            </Stack>
        </Stack>
    );
};
