import { EmployeesSelectionDialog } from '@/components/employee-selection-dialog/EmployeesSelectionDialog';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { StepperWorkflow } from '@/components/stepper-workflow/StepperWorkflow';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { Employee } from '@/domain/employee/Employee.model';
import { filterEmployeesByIds } from '@/domain/employee/Employee.service';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { ReviewTemplate } from '@/domain/review-template/ReviewTemplate.model';
import { Review, ReviewCycleCreationMutation, ReviewUpdateMutation } from '@/domain/review/Review.model';
import { createReviewCycle, getSetupStep, updateReview } from '@/domain/review/Review.service';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useSearchReviewTemplates } from '@/hooks/review-template/ReviewTemplate.hook';
import { useGetReview } from '@/hooks/review/Review.hook';
import { useCurrentEmployee } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, FormHelperText, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { AddTeamIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';

export const ReviewSetupFormPage: FC = () => {
    const { reviewId } = useParams();
    const reviewIdNumber = isNaN(Number(reviewId)) ? undefined : Number(reviewId);

    const { data: allEmployees = [], isError: isEmployeesError, isLoading: isEmployeesLoading, error: employeesError } = useGetEmployees();
    const { data: review, isError: isReviewError, isLoading: isReviewLoading, error: reviewError } = useGetReview(reviewIdNumber);
    const {
        data: reviewTemplates = [],
        isError: isReviewTemplatesError,
        isLoading: isReviewTemplatesLoading,
        error: reviewTemplatesError,
    } = useSearchReviewTemplates({
        reviewType: 'CYCLE',
    });
    const currentEmployee = useCurrentEmployee();
    const isLoading = isEmployeesLoading || (isReviewLoading && !!reviewIdNumber) || isReviewTemplatesLoading || !currentEmployee;
    const isError = isEmployeesError || (isReviewError && !!reviewIdNumber) || isReviewTemplatesError || !currentEmployee;
    const error = employeesError || reviewError || reviewTemplatesError;

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            {currentEmployee && (
                <ReviewSetupForm employees={allEmployees} review={review} reviewTemplates={reviewTemplates} currentEmployee={currentEmployee} />
            )}
        </StateHandler>
    );
};

type ReviewSetupFormProps = {
    employees: Employee[];
    review?: Review;
    reviewTemplates: ReviewTemplate[];
    currentEmployee: Employee;
};

const ReviewSetupForm: FC<ReviewSetupFormProps> = ({ employees, review, currentEmployee, reviewTemplates }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [translationLanguage, setTranslationLanguage] = useState<UserLanguage>(getRealmLanguage());

    const [ownersDialogOpen, setOwnersDialogOpen] = useState<boolean>(false);
    const [employeesDialogOpen, setEmployeesDialogOpen] = useState<boolean>(false);

    const reviewTemplateOptions = reviewTemplates.map(template => {
        return { id: template.id, name: template.name };
    });

    const getSchema = (userLanguage: UserLanguage) => {
        return yup.object().shape({
            name: getLabelFormSchema(userLanguage),
            reviewTemplateId: yup.number().required(),
            employeeIds: yup.array().of(yup.number().required()).required().min(1, t('general.validations.required')),
            ownerIds: yup.array().of(yup.number().required()).required().min(1, t('general.validations.required')),
        });
    };

    type ReviewSetupFormValues = yup.InferType<ReturnType<typeof getSchema>>;

    const getDefaultValues = (): Partial<ReviewSetupFormValues> => {
        const defaultFormValues: Partial<ReviewSetupFormValues> = {
            name: createDefaultLabel(),
            employeeIds: [],
            ownerIds: [currentEmployee.id],
            reviewTemplateId: undefined,
        };

        if (!review) {
            return defaultFormValues;
        }

        const existingDataValues: Partial<ReviewSetupFormValues> = {
            name: review.name,
            reviewTemplateId: review.reviewTemplate?.id,
            employeeIds: (review.employeeReviews ?? []).map(employeeReview => employeeReview.employee.id),
            ownerIds: review.owners.map(owner => owner.id),
        };

        return { ...defaultFormValues, ...existingDataValues };
    };

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm<ReviewSetupFormValues>({
        resolver: yupResolver(getSchema(translationLanguage)),
        defaultValues: getDefaultValues(),
    });

    const handleCreateReviewCycle = async (data: ReviewSetupFormValues) => {
        const reviewCycleMutation: ReviewCycleCreationMutation = {
            name: data.name,
            reviewTemplateId: data.reviewTemplateId,
            employeeIds: data.employeeIds,
            ownerIds: data.ownerIds,
        };

        try {
            const data = await createReviewCycle(reviewCycleMutation);
            showSnackbar(t('reviews.messages.review_created'), 'success');
            const reviewId = data.id;
            navigate(`/reviews/manage-reviews/${reviewId}/reviewers`);
        } catch (error) {
            handleError(error);
        }
    };

    const handleUpdateReview = async (data: ReviewSetupFormValues, review: Review) => {
        const reviewUpdateMutation: ReviewUpdateMutation = {
            name: data.name,
            employeeIds: data.employeeIds,
            ownerIds: data.ownerIds,
            instructions: review.instructions,
            emailInstructions: review.emailInstructions,
            endDate: review.endDate,
            feedbackDeadlineDate: review.feedbackDeadlineDate,
            emails: review.emails,
        };

        try {
            const data = await updateReview(review.id, reviewUpdateMutation);
            showSnackbar(t('reviews.messages.review_updated'), 'success');
            const reviewId = data.id;
            navigate(`/reviews/manage-reviews/${reviewId}/reviewers`);
        } catch (error) {
            handleError(error);
        }
    };

    const onSave = (data: ReviewSetupFormValues) => {
        if (!review) {
            handleCreateReviewCycle(data).catch(handleError);
        } else {
            handleUpdateReview(data, review).catch(handleError);
        }
    };

    const assignedOwners = filterEmployeesByIds(watch('ownerIds'), employees);
    const assignedEmployees = filterEmployeesByIds(watch('employeeIds'), employees);

    const steps = getSetupStep().steps;
    const setupStep = getSetupStep().getStep('SETUP');

    return (
        <Stack justifyContent={'space-between'} flex={1} gap={2} mb={8.5}>
            <Stack>
                <Stack direction='column' component={Paper} elevation={1} p={2} gap={1}>
                    <Stack gap={2} direction={'row'} justifyContent={'space-between'}>
                        <Typography variant='h1'>{t('reviews.new_review.about')}</Typography>
                        <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={setTranslationLanguage} />
                    </Stack>

                    <Stack gap={2}>
                        <Stack gap={0.5}>
                            <Typography variant='body1'>{t('reviews.performance_review_forms.owners')}</Typography>
                            <Stack direction={'row'} gap={1}>
                                <Button variant='contained' endIcon={<AddTeamIcon />} color='primary' onClick={() => setOwnersDialogOpen(true)}>
                                    {t('reviews.new_review.choose_button_label')}
                                </Button>
                                <StackedAvatars employeeAvatars={assignedOwners} />
                            </Stack>
                            {errors.ownerIds?.message && (
                                <Typography variant='body1' color='error'>
                                    {errors.ownerIds.message}
                                </Typography>
                            )}
                        </Stack>
                        <FieldLabel
                            control={control}
                            name='name'
                            fullWidth
                            language={translationLanguage}
                            textFieldProps={{
                                sx: { width: '500px' },
                            }}
                            label={t('reviews.new_review.review_name')}
                        />

                        <Stack>
                            <Controller
                                name='reviewTemplateId'
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        label={t('reviews.new_review.review_template')}
                                        disabled={!!review}
                                        control={
                                            <Select sx={{ width: '500px' }} {...field} error={!!errors?.reviewTemplateId?.message}>
                                                {reviewTemplateOptions.map(item => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {getLabelTranslation(item.name)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        }
                                    />
                                )}
                            />
                            {!!errors?.reviewTemplateId?.message && (
                                <FormHelperText sx={{ marginLeft: 1.75 }} error={!!errors?.reviewTemplateId?.message}>
                                    {t('general.validations.required')}
                                </FormHelperText>
                            )}
                        </Stack>
                        <Stack>
                            <Stack alignItems={'flex-start'} gap={0.5}>
                                <Typography variant='body1'>{t('reviews.new_review.who_will_be_reviewed')}</Typography>

                                <Stack direction={'row'} alignItems={'center'} gap={1}>
                                    <Button variant='contained' endIcon={<AddTeamIcon />} color='primary' onClick={() => setEmployeesDialogOpen(true)}>
                                        {t('reviews.new_review.choose_button_label')}
                                    </Button>
                                    {assignedEmployees && <StackedAvatars employeeAvatars={assignedEmployees} />}
                                </Stack>
                            </Stack>
                            {!!errors?.employeeIds?.message && (
                                <FormHelperText sx={{ marginLeft: 1.75 }} error={!!errors.employeeIds?.message}>
                                    {errors.employeeIds?.message}
                                </FormHelperText>
                            )}
                        </Stack>

                        {ownersDialogOpen && (
                            <Controller
                                name='ownerIds'
                                control={control}
                                render={({ field }) => (
                                    <EmployeesSelectionDialog
                                        assignedEmployees={filterEmployeesByIds(field.value, employees)}
                                        allEmployees={employees}
                                        open={ownersDialogOpen}
                                        onClose={() => setOwnersDialogOpen(false)}
                                        onSave={(assigned: Employee[]) => {
                                            const assignedIds = assigned.map((employee: Employee) => employee.id);
                                            field.onChange(assignedIds);
                                            setOwnersDialogOpen(false);
                                        }}
                                    />
                                )}
                            />
                        )}
                        {employeesDialogOpen && (
                            <Controller
                                name='employeeIds'
                                control={control}
                                render={({ field }) => (
                                    <EmployeesSelectionDialog
                                        assignedEmployees={filterEmployeesByIds(field.value, employees)}
                                        allEmployees={employees}
                                        open={employeesDialogOpen}
                                        onClose={() => setEmployeesDialogOpen(false)}
                                        onSave={(assigned: Employee[]) => {
                                            const assignedIds = assigned.map((employee: Employee) => employee.id);
                                            field.onChange(assignedIds);
                                            setEmployeesDialogOpen(false);
                                        }}
                                    />
                                )}
                            />
                        )}
                    </Stack>
                </Stack>
            </Stack>

            <StepperWorkflow steps={steps} currentStep={setupStep}>
                <Button onClick={() => handleSubmit(onSave, console.error)()} variant={'contained'}>
                    {t('reviews.wizard.next')}
                </Button>
            </StepperWorkflow>
        </Stack>
    );
};
