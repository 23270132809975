import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { PlanningSetting, PlanningSettingMutation } from '@/domain/planning-setting/PlanningSetting.model';

export type PlanningSettingDTO = PlanningSetting;
export type PlanningSettingUpdateRequestDTO = PlanningSettingMutation;

const getPlanningSettings = async (): Promise<PlanningSetting> => {
    const url = API_BASE_URL + `/time-management/planning-settings`;
    return (await client.get<PlanningSettingDTO>(url)).data;
};

const updatePlanningSettings = async (planningSettingId: number, mutation: PlanningSettingMutation): Promise<PlanningSetting> => {
    const url = API_BASE_URL + `/time-management/planning-settings/${planningSettingId}`;
    return (await client.put<PlanningSettingDTO, AxiosResponse<PlanningSettingDTO>, PlanningSettingUpdateRequestDTO>(url, mutation)).data;
};

export const planningSettingApi = {
    getPlanningSettings,
    updatePlanningSettings,
};
