import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { StackedAvatarPopover } from '@/components/stacked-avatar/StackedAvatarPopover';
import { EmployeeAvatar as EmployeeAvatarType } from '@/domain/employee/Employee.model';
import { avatarClasses, AvatarGroup, AvatarGroupProps, useTheme } from '@mui/material';
import { FC } from 'react';
import { getAvatarSizeProps } from '@/components/employee-avatar/EmployeeAvatar.util';

const MAX_ITEMS = 4;

type Props = AvatarGroupProps & {
    employeeAvatars: EmployeeAvatarType[];
    onClick?: () => void;
    size?: 'small' | 'medium' | 'large';
    orientation?: 'vertical' | 'horizontal';
    grid?: boolean;
    profileLink?: boolean;
};

export const StackedAvatars: FC<Props> = ({
    employeeAvatars: employeeAvatarsProp = [],
    size = 'medium',
    onClick,
    orientation = 'horizontal',
    grid,
    sx,
    profileLink,
    ...rest
}) => {
    const sizeProps = getAvatarSizeProps(size);
    const theme = useTheme();

    const SPACING = {
        small: -2,
        medium: -1,
    } as const;

    const spacing = rest.spacing === 'small' || rest.spacing === 'medium' ? theme.spacing(SPACING[rest.spacing]) : theme.spacing(rest.spacing ?? -1);

    const verticalProps: AvatarGroupProps['sx'] = {
        display: 'flex',
        flexDirection: 'column',
        [`& .${avatarClasses.root}`]: {
            marginLeft: 0,
            marginTop: spacing,
        },
        [`& .${avatarClasses.root}:first-of-type`]: {
            marginTop: 0,
        },
    };

    const horizontalProps: AvatarGroupProps['sx'] = {
        // by default, flex direction is row-reverse, so we want to start displaying from the left
        justifyContent: 'flex-end',
        [`& .${avatarClasses.root}`]: {
            marginLeft: spacing,
        },
    };

    const defaultSx: AvatarGroupProps['sx'] = orientation === 'vertical' ? verticalProps : horizontalProps;

    // Due to multiple employment with the same manager, it's possible to have duplicates
    // This component filters out duplicates to avoid showing the same avatar multiple times
    const employeeAvatars = employeeAvatarsProp.filter((employee, index, self) => self.findIndex(e => e.id === employee.id) === index);

    if (grid) {
        const MAX_GRID_ITEMS = 6;
        const maxIndex = MAX_GRID_ITEMS - 1;
        return (
            <>
                {employeeAvatars.slice(0, maxIndex).map(e => (
                    <EmployeeAvatar key={e.id} employeeAvatar={e} size={size} profileLink={profileLink} />
                ))}

                {employeeAvatars.length === MAX_GRID_ITEMS && (
                    <EmployeeAvatar employeeAvatar={employeeAvatars[maxIndex]} size={size} profileLink={profileLink} />
                )}

                {employeeAvatars.length > MAX_GRID_ITEMS && (
                    <StackedAvatarPopover
                        employeeAvatars={employeeAvatars.slice(maxIndex)}
                        counter={employeeAvatars.length - maxIndex}
                        sx={getAvatarSizeProps(size)}
                        onClick={onClick}
                        profileLink={profileLink}
                    />
                )}
            </>
        );
    }

    return (
        <AvatarGroup
            max={MAX_ITEMS}
            total={employeeAvatars?.length}
            renderSurplus={surplus => (
                <StackedAvatarPopover counter={surplus} employeeAvatars={employeeAvatars.slice(MAX_ITEMS - 1)} onClick={onClick} profileLink={profileLink} />
            )}
            slotProps={{
                additionalAvatar: {
                    sx: {
                        ...sizeProps,
                    },
                },
            }}
            sx={{ ...defaultSx, ...sx } as AvatarGroupProps['sx']}
            {...rest}
            spacing='medium'
        >
            {employeeAvatars?.map(visibleAvatar => (
                <EmployeeAvatar key={visibleAvatar.id} employeeAvatar={visibleAvatar} onClick={onClick} size={size} profileLink={profileLink} />
            ))}
        </AvatarGroup>
    );
};
