import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { Skill } from '@/domain/skill/Skill.model';
import {
    SkillCategory,
    SkillCategoryCreationMutation,
    SkillCategoryLevel,
    SkillCategorySearch,
    SkillCategoryUpdateMutation,
} from '@/domain/skill-category/SkillCategory.model';
import { OrderMutation } from '@/domain/common';

export type SkillCategoryDTO = Omit<SkillCategory, 'levels'> & {
    levels: SkillCategoryLevelDTO[];
};

export type SkillCategoryLevelDTO = SkillCategoryLevel;
export type SkillCategoryCreationRequest = SkillCategoryCreationMutation;
export type SkillCategoryUpdateRequest = SkillCategoryUpdateMutation;
export type SkillCategorySearchDTO = SkillCategorySearch;

const getSkillCategory = async (skillCategoryId: number): Promise<SkillCategory> => {
    return (await client.get<SkillCategoryDTO, AxiosResponse<SkillCategoryDTO>, number>(API_BASE_URL + `/skill-categories/${skillCategoryId}`)).data;
};
const updateSkillCategory = async (skillCategoryId: number, request: SkillCategoryUpdateMutation): Promise<SkillCategory> => {
    return (
        await client.put<SkillCategoryDTO, AxiosResponse<SkillCategoryDTO>, SkillCategoryUpdateRequest>(
            API_BASE_URL + `/skill-categories/${skillCategoryId}`,
            request,
        )
    ).data;
};

const deleteSkillCategory = async (skillCategoryId: number): Promise<void> => {
    await client.delete<unknown, AxiosResponse<void>, number>(API_BASE_URL + `/skill-categories/${skillCategoryId}`);
};
const createSkillCategory = async (request: SkillCategoryCreationMutation): Promise<SkillCategory> => {
    return (await client.post<SkillCategoryDTO, AxiosResponse<SkillCategoryDTO>, SkillCategoryCreationRequest>(API_BASE_URL + `/skill-categories`, request))
        .data;
};
const searchSkillCategories = async (request: SkillCategorySearch = {}): Promise<SkillCategory[]> => {
    return (
        await client.post<SkillCategoryDTO[], AxiosResponse<SkillCategoryDTO[]>, SkillCategorySearchDTO>(API_BASE_URL + `/skill-categories/search`, request)
    ).data;
};
const reorderSkillCategoriesRequest = async (request: OrderMutation[]): Promise<Skill[]> => {
    return (await client.put<Skill[], AxiosResponse<Skill[]>, OrderMutation[]>(API_BASE_URL + `/skill-categories/order`, request)).data;
};
export const skillCategoryApi = {
    getSkillCategory,
    updateSkillCategory,
    deleteSkillCategory,
    createSkillCategory,
    searchSkillCategories,
    reorderSkillCategoriesRequest,
};
