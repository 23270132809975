import { API_BASE_URL, client } from '@/api/common';
import { TemporaryFile } from '@/components/file-picker/FilePicker';
import { LegalUnit, LegalUnitCreateMutation, LegalUnitUpdateMutation } from '@/domain/legal-unit/LegalUnit.model';
import { AxiosResponse } from 'axios';

type LegalUnitDTO = LegalUnit;
type LegalUnitCreateMutationDTO = LegalUnitCreateMutation;
type LegalUnitUpdateMutationDTO = LegalUnitUpdateMutation;

const getLegalUnits = async (): Promise<LegalUnit[]> => {
    return (await client.get<LegalUnitDTO[]>(API_BASE_URL + '/legal-units')).data;
};

const getLegalUnit = async (id: number): Promise<LegalUnit> => {
    return (await client.get<LegalUnitDTO>(API_BASE_URL + `/legal-units/${id}`)).data;
};

const createLegalUnit = async (legalUnit: LegalUnitCreateMutation): Promise<LegalUnit> => {
    return (await client.post<LegalUnitDTO, AxiosResponse<LegalUnitDTO>, LegalUnitCreateMutationDTO>(API_BASE_URL + '/legal-units', legalUnit)).data;
};

const updateLegalUnit = async (id: number, legalUnit: LegalUnitUpdateMutation): Promise<LegalUnit> => {
    return (await client.put<LegalUnitDTO, AxiosResponse<LegalUnitDTO>, LegalUnitUpdateMutationDTO>(API_BASE_URL + `/legal-units/${id}`, legalUnit)).data;
};

const updateLegalUnitLogo = async (id: number, logo: TemporaryFile): Promise<LegalUnit> => {
    const formData = new FormData();

    formData.append('logo', logo.data, logo.name);
    return (await client.putForm<LegalUnitDTO, AxiosResponse<LegalUnitDTO>, FormData>(API_BASE_URL + `/legal-units/${id}/logo`, formData)).data;
};

const deleteLegalUnitLogo = async (id: number): Promise<void> => {
    return client.delete(API_BASE_URL + `/legal-units/${id}/logo`);
};

const getLegalUnitLogoUrl = async (id: number): Promise<string> => {
    return (await client.get<string>(API_BASE_URL + `/legal-units/${id}/logo`)).data;
};

export const legalUnitApi = {
    getLegalUnits,
    getLegalUnit,
    createLegalUnit,
    updateLegalUnit,
    updateLegalUnitLogo,
    deleteLegalUnitLogo,
    getLegalUnitLogoUrl,
};
