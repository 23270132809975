import { StateHandler } from '@/components/state-handler/StateHandler';
import {
    getEmployeeProfilePendingRowsBySectionDefinition,
    getEmployeeProfilePendingRowsBySectionType,
    getEmployeeProfilePersonalInfoPendingFields,
} from '@/domain/employee-pending-change/EmployeePendingChange.service';
import {
    canViewCustomSections,
    canViewEmployeeAddresses,
    canViewEmployeePersonalInfos,
    canViewEmployeesBasicInfo,
} from '@/domain/permission/Permission.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { SectionType } from '@/domain/section-setting/Section.model';
import { useGetEmployeeProfilePendingChanges } from '@/hooks/employee-pending-change/EmployeePendingChanges.hook';
import { useGetEmployeeById } from '@/hooks/employee/Employee.hook';
import { useGetEmployeeSection } from '@/hooks/employee/EmployeeSection.hook';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';
import { EmployeeAddressSection } from '@/page/employee-profile/employee-profile-info/EmployeeAddressSection/EmployeeAddressSection';
import { EmployeeBasicInfoSection } from '@/page/employee-profile/employee-profile-info/EmployeeBasicInfoSection/EmployeeBasicInfoSection';
import { EmployeeCustomSection } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSection/EmployeeCustomSection';
import { EmployeePersonalInfoSection } from '@/page/employee-profile/employee-profile-info/EmployeePersonalInfoSection/EmployeePersonalInfoSection';
import { PERSONAL_SECTIONS } from '@/page/employee-profile/employeeProfile.util';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { useAppDispatch, useAppSelector, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Paper, Skeleton, Stack } from '@mui/material';
import { FC, useEffect } from 'react';

export const EmployeeProfilePersonalInfoPage: FC = () => {
    const employeeId = useEmployeeProfileId();

    const dispatch = useAppDispatch();
    const realm = useCurrentRealm();
    // For now we are using the store and the refetchEmployee flag to refetch the pending changes after a mutation
    // This should be changed to use the useQuery hook and queryClient.invalidateQueries
    const mustRefetchEmployeeProfile = useAppSelector(state => state.employeeProfile.refetchEmployee);
    const policies = useCurrentPolicies();

    // TODO: create a custom hook to load all the data with one hook
    const {
        data: employee,
        refetch: refetchEmployeeById,
        isLoading: isEmployeeByIdLoading,
        isError: isEmployeeByIdError,
        error: employeeError,
    } = useGetEmployeeById(employeeId);

    const {
        data: sectionDefinitions = [],
        isLoading: isSectionDefinitionsLoading,
        isError: isSectionDefinitionsError,
        error: sectionDefinitionsError,
    } = useGetSectionDefinitions();

    const {
        data: employeeSection,
        isLoading: isEmployeeSectionLoading,
        isError: isEmployeeSectionError,
        error: employeeSectionError,
        refetch: refetchEmployeeSection,
    } = useGetEmployeeSection({ employeeId });

    const handleEmployeeUpdate = () => {
        refetchEmployeeById().catch(handleError);
        dispatch({
            type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
            refetchEmployee: true,
        });
    };

    const { data: employeeProfileChanges = [], refetch: refetchPendingChanges } = useGetEmployeeProfilePendingChanges(employeeId);

    useEffect(() => {
        if (mustRefetchEmployeeProfile) {
            refetchEmployeeById().catch(handleError);
            refetchPendingChanges().catch(handleError);
        }
    }, [mustRefetchEmployeeProfile, refetchEmployeeById, refetchPendingChanges]);

    const visibleSections = sectionDefinitions?.filter(({ type, tab }) => PERSONAL_SECTIONS.includes(type) || tab === 'PROFILE');

    const handleCustomSectionChange = () => {
        refetchEmployeeSection().catch(handleError);
    };
    return (
        <StateHandler
            loadingComponent={<PersonalInfoPageSkeleton />}
            isLoading={isEmployeeByIdLoading || isSectionDefinitionsLoading || isEmployeeSectionLoading}
            isError={isEmployeeByIdError || isSectionDefinitionsError || isEmployeeSectionError}
            error={employeeError || sectionDefinitionsError || employeeSectionError}
        >
            {!!employee && (
                <Stack gap={2} overflow='auto'>
                    {visibleSections?.map(sectionDefinition => {
                        const customSection = employeeSection?.find(section => section.sectionDefinition?.id === sectionDefinition.id);

                        switch (sectionDefinition.type) {
                            case SectionType.BASIC_INFO:
                                return (
                                    canViewEmployeesBasicInfo(policies, employeeId) && (
                                        <EmployeeBasicInfoSection
                                            key={SectionType.BASIC_INFO}
                                            employee={employee}
                                            onUpdateEmployeeBasicInfo={handleEmployeeUpdate}
                                            sectionDefinition={sectionDefinition}
                                        />
                                    )
                                );
                            case SectionType.PERSONAL_INFO:
                                return (
                                    hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE) &&
                                    canViewEmployeePersonalInfos(policies, employeeId) && (
                                        <EmployeePersonalInfoSection
                                            key={SectionType.PERSONAL_INFO}
                                            employeeId={employeeId}
                                            pendingFields={getEmployeeProfilePersonalInfoPendingFields(employeeProfileChanges)}
                                            sectionDefinition={sectionDefinition}
                                        />
                                    )
                                );
                            case SectionType.ADDRESS:
                                return (
                                    hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE) &&
                                    canViewEmployeeAddresses(policies, employeeId) && (
                                        <EmployeeAddressSection
                                            key={SectionType.ADDRESS}
                                            // Use computed employeeId variable to avoid async issues with the employee object, when the get employee by id is called in parallel
                                            employeeId={employeeId}
                                            pendingRows={getEmployeeProfilePendingRowsBySectionType(employeeProfileChanges, SectionType.ADDRESS)}
                                            sectionDefinition={sectionDefinition}
                                        />
                                    )
                                );
                            case SectionType.CUSTOM_SINGLE_ROW:
                            case SectionType.CUSTOM_MULTI_ROW:
                                return (
                                    customSection &&
                                    canViewCustomSections(policies, employeeId, customSection.sectionDefinition.id) && (
                                        <EmployeeCustomSection
                                            key={sectionDefinition.id}
                                            employeeSection={customSection}
                                            onChange={handleCustomSectionChange}
                                            pendingRows={getEmployeeProfilePendingRowsBySectionDefinition(employeeProfileChanges, sectionDefinition.id)}
                                        />
                                    )
                                );
                            default:
                                return undefined;
                        }
                    })}
                </Stack>
            )}
        </StateHandler>
    );
};

export const PersonalInfoPageSkeleton: FC = () => {
    return (
        <Stack gap={2}>
            <PersonalInfoPageSectionSkeleton />
            <PersonalInfoPageSectionSkeleton />
            <PersonalInfoPageSectionSkeleton />
            <PersonalInfoPageSectionSkeleton />
        </Stack>
    );
};

export const PersonalInfoPageSectionSkeleton: FC = () => {
    return (
        <Paper>
            <Stack direction={'row'}>
                <Stack p={2} gap={2} flex={1}>
                    <Skeleton variant='text' height={36} />
                    <Skeleton variant='text' height={24} />
                    <Skeleton variant='text' height={24} />
                    <Skeleton variant='text' height={24} />
                    <Skeleton variant='text' height={24} />
                    <Skeleton variant='text' height={24} />
                    <Skeleton variant='text' height={24} />
                </Stack>
                <Stack p={2} gap={2} flex={3} justifyContent={'flex-end'}>
                    <Skeleton variant='text' height={24} width={'90%'} />
                    <Skeleton variant='text' height={24} width={'20%'} />
                    <Skeleton variant='text' height={24} width={'40%'} />
                    <Skeleton variant='text' height={24} width={'90%'} />
                    <Skeleton variant='text' height={24} width={'20%'} />
                    <Skeleton variant='text' height={24} width={'40%'} />
                </Stack>
            </Stack>
        </Paper>
    );
};
