import { planningApi } from '@/api/planning/Planning.api';
import { Planning, PlanningMutation, PlanningSearch } from '@/domain/planning/Planning.model';
import { ShiftCoverage, ShiftCoverageCreationRequest, ShiftCoverageUpdateRequest, ShiftStatus } from '@/domain/shift/Shift.model';
import { ShiftCoverageFormValues } from '@/page/planning/shift-coverage-dialog/ShiftCoverageDialog';
import { DEFAULT_SHIFT_BACKGROUND_COLOR } from '@/utils/colors.util';
import { lighten } from '@mui/material';

export const getPlanningById = (planningId: number): Promise<Planning> => {
    return planningApi.getPlanningById(planningId);
};

export const updatePlanning = (planningId: number, mutation: PlanningMutation): Promise<Planning> => {
    return planningApi.updatePlanning(planningId, mutation);
};

export const deletePlanning = (planningId: number): Promise<void> => {
    return planningApi.deletePlanning(planningId);
};

export const createPlanning = (mutation: PlanningMutation): Promise<Planning> => {
    return planningApi.createPlanning(mutation);
};

export const searchPlannings = (request: PlanningSearch = {}): Promise<Planning[]> => {
    return planningApi.searchPlannings(request);
};
export const convertShiftCoverageToShiftCoverageFormValues = (shiftCoverage: ShiftCoverage): ShiftCoverageFormValues => {
    return {
        tagId: shiftCoverage.tag.id,
        locationId: shiftCoverage.location.id,
        startDate: shiftCoverage.startDate,
        endDate: shiftCoverage.endDate,
        repeatEveryXWeek: shiftCoverage.repeatEveryXWeek,
        repeatOn: shiftCoverage.repeatOn,
        startTime: shiftCoverage.startTime,
        endTime: shiftCoverage.endTime,
        requiredAmount: shiftCoverage.requiredAmount,
    };
};
export const convertFormValuesToMutationRequest = (formValues: ShiftCoverageFormValues): ShiftCoverageCreationRequest | ShiftCoverageUpdateRequest => {
    const { tagId, locationId, ...restValues } = formValues;
    return {
        tagId: Number(tagId),
        locationId: Number(locationId),
        ...restValues,
    };
};

export const getShiftColors = (
    color: string | undefined,
    status: ShiftStatus,
    simulationMode: boolean,
    hasError?: boolean,
    errorColor?: string,
): {
    shiftFontColor: string;
    shiftBackgroundColor: string;
    shiftBorderColor: string;
} => {
    const isShiftPublished = status === ShiftStatus.SHIFT_PUBLISHED;
    const shiftFontColor = !isShiftPublished ? (color ?? DEFAULT_SHIFT_BACKGROUND_COLOR) : 'common.white';
    let shiftBackgroundColor = isShiftPublished ? (color ?? DEFAULT_SHIFT_BACKGROUND_COLOR) : 'common.white';
    if (simulationMode && color) {
        shiftBackgroundColor = lighten(color, 0.9);
    }
    let shiftBorderColor = color ?? DEFAULT_SHIFT_BACKGROUND_COLOR;

    if (hasError && errorColor) {
        shiftBorderColor = errorColor;
        shiftBackgroundColor = isShiftPublished ? lighten(shiftBackgroundColor, 0.3) : lighten(errorColor ?? DEFAULT_SHIFT_BACKGROUND_COLOR, 0.95);
    }
    return { shiftFontColor, shiftBackgroundColor, shiftBorderColor };
};
