import { formatInDefaultDate, isValidDate } from '@/utils/datetime.util';
import { FormControlLabel } from '@mui/material';

import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldText } from '@/components/form/field-text/FieldText';
import { TimesheetAction } from '@/domain/timesheet/Timesheet.model';
import { EmployeeTimesheetFormValues } from '@/page/timesheet/timesheet-dialog/TimesheetDialog.schema';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type MissingDatesFieldProps = {
    missingEntriesDates: LocalDate[];
};
export const MissingDatesField: FC<MissingDatesFieldProps> = ({ missingEntriesDates }) => {
    const { control } = useFormContext<EmployeeTimesheetFormValues>();

    return (
        <FieldSelect
            name='missingDates'
            control={control}
            multiple
            options={missingEntriesDates}
            fullWidth
            getOptionLabel={option => formatInDefaultDate(option)}
            getOptionKey={option => option}
        />
    );
};

type ReferenceDateFieldProps = {
    mode: TimesheetAction;
    onChange: (date: LocalDate) => void;
};
export const ReferenceDateField: FC<ReferenceDateFieldProps> = ({ mode, onChange }) => {
    const { control } = useFormContext<EmployeeTimesheetFormValues>();

    return (
        <FieldLocalDate
            name='referenceDate'
            control={control}
            disabled={mode === TimesheetAction.EDIT || mode === TimesheetAction.SHIFT_TIMESHEET}
            onChange={date => {
                if (!isValidDate(date)) {
                    return;
                }
                onChange(date);
            }}
            mobileOnly
        />
    );
};

type AreaFieldProps = {
    timesheetIndex: number;
    areas: { id: number; name: string }[];
};
export const AreaField: FC<AreaFieldProps> = ({ timesheetIndex, areas }) => {
    const { control } = useFormContext<EmployeeTimesheetFormValues>();
    const { t } = useTranslation();

    return (
        <FormControlLabel
            label={''}
            sx={{ width: '100%' }}
            control={
                <FieldSelect
                    name={`timesheets.${timesheetIndex}.area`}
                    control={control}
                    autocompleteProps={{
                        id: 'area-select',
                        disableListWrap: true,
                    }}
                    options={areas}
                    fullWidth
                    getOptionLabel={option => option.name}
                    placeholder={t('planning.area')}
                    isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue?.id}
                />
            }
        />
    );
};

type CommentFieldProps = {
    timesheetIndex: number;
};
export const CommentField: FC<CommentFieldProps> = ({ timesheetIndex }) => {
    const { control } = useFormContext<EmployeeTimesheetFormValues>();
    const { t } = useTranslation();

    return (
        <FieldText
            name={`timesheets.${timesheetIndex}.comment`}
            control={control}
            fullWidth
            textFieldProps={{ multiline: true, minRows: 1 }}
            placeholder={t('general.comment')}
        />
    );
};
