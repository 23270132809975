import {
    PlanningPosition,
    PlanningPositionCreationMutation,
    PlanningPositionSearch,
    PlanningPositionUpdateMutation,
} from '@/domain/planning-position/PlanningPosition.model';
import { planningPositionApi } from '@/api/planning-position/PlanningPosition.api';

import { OrderMutation } from '@/domain/common';

export const searchPlanningPosition = (planningPositionSearch: PlanningPositionSearch = {}): Promise<PlanningPosition[]> => {
    return planningPositionApi.searchPlanningPositions(planningPositionSearch);
};

export const createPlanningPosition = (mutation: PlanningPositionCreationMutation): Promise<PlanningPosition> => {
    return planningPositionApi.createPlanningPosition(mutation);
};

export const updatePlanningPosition = (id: number, mutation: PlanningPositionUpdateMutation): Promise<PlanningPosition> => {
    return planningPositionApi.updatePlanningPosition(id, mutation);
};

export const updatePlanningPositionOrders = (mutation: OrderMutation[]): Promise<PlanningPosition> => {
    return planningPositionApi.updatePlanningPositionOrders(mutation);
};

export const deletePlanningPosition = (id: number): Promise<void> => {
    return planningPositionApi.deletePlanningPosition(id);
};
