import { StackProps, Typography } from '@mui/material';
import { FC } from 'react';
import { EmployeeReviewFeedbackSectionFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { getLabelTranslation } from '@/utils/language.util';

type EmployeeReviewFeedbackSectionProps = {
    sectionItem: EmployeeReviewFeedbackSectionFormType;
} & StackProps;

export const EmployeeReviewFeedbackSection: FC<EmployeeReviewFeedbackSectionProps> = ({ sectionItem }) => {
    return (
        <Typography
            variant='h1'
            sx={{
                px: 1,
            }}
        >
            {getLabelTranslation(sectionItem.title) ?? ''}
        </Typography>
    );
};
