import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';
import { StarsIcons } from '@/components/stars-icons/StarsIcons';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { NotReviewedSkillButton, ReviewedSkillButton } from '@/domain-ui/skill/SkillButton';
import { EmployeeSkill } from '@/domain/employee-skill/EmployeeSkill.model';
import { getEmployeeSkillsGroupedByCategoryId } from '@/domain/employee-skill/EmployeeSkill.service';
import { Label } from '@/domain/label/Label.model';
import { useGetEmployeeSkills } from '@/hooks/employee-skill/EmployeeSkill.hook';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { getLabelTranslation } from '@/utils/language.util';
import { Box, DialogContent, Paper, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployeeProfileSkillsPage: FC = () => {
    const employeeId = useEmployeeProfileId();

    const { data: employeeSkills = [], isLoading, error, isError } = useGetEmployeeSkills(employeeId);

    return (
        <StateHandler isLoading={isLoading} error={error} isError={isError}>
            {employeeSkills && <CategorizedSkillsList employeeSkills={employeeSkills} />}
        </StateHandler>
    );
};

type CategorizedSkillsListType = {
    employeeSkills: EmployeeSkill[];
};
const CategorizedSkillsList: FC<CategorizedSkillsListType> = ({ employeeSkills }) => {
    const groupedEmployeeSkillsByCategoryId = getEmployeeSkillsGroupedByCategoryId(employeeSkills);
    const { t } = useTranslation();
    return (
        <Stack gap={2} component={Paper} p={2} flex={1}>
            <Typography variant={'h2'}>{t('my_skills.title')}</Typography>
            <Stack flexWrap={'wrap'} gap={2}>
                {groupedEmployeeSkillsByCategoryId.map(groupedEmployeeSkillsByCategoryId => (
                    <CategorySkillsGroup
                        key={groupedEmployeeSkillsByCategoryId.category.id}
                        categoryName={groupedEmployeeSkillsByCategoryId.category.name}
                        employeeSkills={groupedEmployeeSkillsByCategoryId.groupedSkills}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

type CategorySkillsGroupType = {
    categoryName: Label;
    employeeSkills: EmployeeSkill[];
};

const CategorySkillsGroup: FC<CategorySkillsGroupType> = ({ categoryName, employeeSkills }) => {
    const [currentEmployeeSkill, setCurrentEmployeeSkill] = useState<EmployeeSkill>();
    return (
        <Stack gap={1} maxWidth={'100%'}>
            <Typography variant={'body2bold'}>{getLabelTranslation(categoryName)}</Typography>
            <Stack gap={2} direction={'row'} flexWrap={'wrap'} maxWidth={'100%'}>
                {employeeSkills.map(employeeSkill => (
                    <SkillButton
                        key={employeeSkill.skill.id}
                        employeeSkill={employeeSkill}
                        handleSkillClicked={employeeSkill => setCurrentEmployeeSkill(employeeSkill)}
                    />
                ))}
            </Stack>

            {currentEmployeeSkill && <SkillDetailsDialog employeeSkill={currentEmployeeSkill} handleClose={() => setCurrentEmployeeSkill(undefined)} />}
        </Stack>
    );
};

type SkillButtonType = {
    employeeSkill: EmployeeSkill;
    handleSkillClicked: (employeeSkill: EmployeeSkill) => void;
};

const SkillButton: FC<SkillButtonType> = ({ employeeSkill, handleSkillClicked }) => {
    if (employeeSkill.score) {
        return (
            <ReviewedSkillButton
                skillScore={employeeSkill.score}
                skillName={getLabelTranslation(employeeSkill.skill.name)}
                handleSkillClicked={() => handleSkillClicked(employeeSkill)}
            />
        );
    } else {
        return (
            <NotReviewedSkillButton
                skillName={getLabelTranslation(employeeSkill.skill.name)}
                handleSkillClicked={() => handleSkillClicked(employeeSkill)}
                startIcon={undefined}
            />
        );
    }
};

type SkillDetailsDialogType = {
    employeeSkill: EmployeeSkill;
    handleClose: () => void;
};

const SkillDetailsDialog: FC<SkillDetailsDialogType> = ({ employeeSkill, handleClose }) => {
    const getRatingName = (employeeSkill: EmployeeSkill) => {
        return employeeSkill.skill.category.levels.find(level => level.score === employeeSkill.score)?.name;
    };
    return (
        <DialogWrapper header={t('my_skills.employee_skill_dialog.title')} onClose={handleClose} open={true} maxWidth={'sm'}>
            <DialogContent>
                <Stack pb={3} gap={1}>
                    <Typography variant='body1bold'>{getLabelTranslation(employeeSkill.skill.name)}</Typography>
                    <RichTextTypography variant='body1'>{getLabelTranslation(employeeSkill.skill.description)}</RichTextTypography>
                </Stack>

                {!!employeeSkill.score && (
                    <Stack direction={'row'} gap={2} alignItems={'center'} mb={3}>
                        <Typography variant='body2bold'>{t('my_skills.employee_skill_dialog.current_score')}</Typography>
                        <StarRatingCard rating={employeeSkill.score} name={getLabelTranslation(getRatingName(employeeSkill))} />
                    </Stack>
                )}
            </DialogContent>
        </DialogWrapper>
    );
};

type StarRatingCardProps = {
    rating: number;
    name: string;
};

const StarRatingCard: FC<StarRatingCardProps> = ({ rating, name }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                padding: 1,
                border: '1px solid black',
                borderRadius: 1,
                width: 'fit-content',
            }}
        >
            <StarsIcons starsNumber={rating} htmlColor='black' />
            <Typography variant='body2'>{name}</Typography>
        </Box>
    );
};
