export enum ImportType {
    EMPLOYEE = 'employee',
    LEAVE = 'leave',
    LEAVE_CORRECTION = 'leave_correction',
    TIMESHEET_ADJUSTMENT = 'timesheet_adjustment',
    BASIC_INFORMATION = 'basic_information',
    TIMESHEET = 'timesheet',
    JOB = 'job',
    JOB_FAMILY = 'job-family',
    DEPARTMENT = 'department',
    COST_CENTER = 'cost_center',
    PLANNING_POSITION = 'planning_position',
    CUSTOM_SECTION = 'custom_section',
    ADDRESS = 'address',
    OBJECTIVE = 'objective',
    SKILL = 'skill',
    CALENDAR_DAYS = 'calendar_days',
}

export enum ImportOption {
    SHOULD_REPLACE = 'SHOULD_REPLACE',
    ONLY_IF_EMPTY = 'ONLY_IF_EMPTY',
}

export enum ImportPageType {
    EMPLOYEE = 'employee',
    LEAVES = 'leaves',
    TIMESHEET = 'timesheets',
    ORGANIZATION = 'organization',
    OBJECTIVE = 'objectives',
    SKILL = 'skills',
}

export type ImportRequest = {
    file: File;
    shouldReplace?: boolean;
    thirdPartyId?: number;
};
export type ImportError = {
    code: string;
    message: string;
};
export type ImportFailedRow = {
    error: ImportError;
    rawData: string[];
    rowIndex: number;
    cellIndex: number;
};
export type ImportResult = {
    error: ImportError;
    totalRows: number;
    headers: string[];
    failedRows: ImportFailedRow[];
};
