import { Editor as ReactEditor, useEditor } from '@tiptap/react';

import { Link } from '@tiptap/extension-link';
import { StarterKit } from '@tiptap/starter-kit';
import { useMemo } from 'react';

export type RichTextEditorWrapper = {
    editor: ReactEditor;
    customCommands: ReturnType<typeof getCustomCommands>;
};

export const useRichTextEditorWrapper = (defaultValue = '', enabledEditorDependency = true): RichTextEditorWrapper | undefined => {
    const LinkConfig = Link.configure({
        validate: href => /^https?:\/\//.test(href),
        openOnClick: false,
    });
    const editor = useEditor(
        {
            extensions: [StarterKit, LinkConfig],
            content: defaultValue,
        },
        // The dependency is useful to reset the editor when the content is changed from outside
        // When we want to avoid tip tap optimization, we can disable the dependency
        enabledEditorDependency ? [defaultValue] : [],
    );

    const customCommands = useMemo(() => getCustomCommands(editor), [editor]);

    if (!editor) {
        return undefined;
    }

    return { editor, customCommands };
};

/**
 * Wrapped commands to interact with the editor
 * @param editor
 */
const getCustomCommands = (editor: ReactEditor | null) => ({
    setContent: (content: string) => {
        editor?.commands?.setContent(content);
    },
    focus: () => {
        editor?.commands?.focus();
    },
});
