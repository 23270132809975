import { API_BASE_URL, client } from '@/api/common';
import { EmployeePersonalInfo, EmployeePersonalInfoMutation } from '@/domain/employee/EmployeePersonalInfo.model';
import { AxiosResponse } from 'axios';

type EmployeePersonalInfoUpdateRequestDTO = EmployeePersonalInfoMutation;
type EmployeePersonalInfoDTO = EmployeePersonalInfo;

const getEmployeePersonalInfo = async (employeeId: number): Promise<EmployeePersonalInfo> => {
    return (await client.get<EmployeePersonalInfoDTO>(API_BASE_URL + `/employee/personal-info/${employeeId}`)).data;
};

/**
 * API call to update employee personal info, without approval process
 * @param employeeId
 * @param request
 * @returns
 */
const updateEmployeePersonalInfo = async (employeeId: number, request: EmployeePersonalInfoMutation): Promise<EmployeePersonalInfo> => {
    return (
        await client.post<EmployeePersonalInfo, AxiosResponse<EmployeePersonalInfo>, EmployeePersonalInfoUpdateRequestDTO>(
            API_BASE_URL + `/employee/personal-info/${employeeId}`,
            request,
        )
    ).data;
};

/**
 * API call to update employee personal info, with approval process
 * @param employeeId
 * @param request
 * @returns
 */
const createEmployeePersonalInfoPendingRequest = async (employeeId: number, request: EmployeePersonalInfoMutation): Promise<void> => {
    return (await client.post(API_BASE_URL + `/employee/personal-info/${employeeId}/pending`, request satisfies EmployeePersonalInfoUpdateRequestDTO)).data;
};

const approveEmployeePersonalInfoRequest = async (employeeId: number): Promise<void> => {
    return (await client.post(API_BASE_URL + `/employee/personal-info/${employeeId}/approve`)).data;
};

const cancelEmployeePersonalInfoRequest = async (employeeId: number): Promise<void> => {
    return (await client.delete(API_BASE_URL + `/employee/personal-info/${employeeId}/cancel`)).data;
};

export const employeePersonalInfoAPI = {
    getEmployeePersonalInfo,
    updateEmployeePersonalInfo,
    createEmployeePersonalInfoPendingRequest,
    approveEmployeePersonalInfoRequest,
    cancelEmployeePersonalInfoRequest,
};
