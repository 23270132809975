import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionPanel } from './ConditionPanel';
import { ConditionType, FilteringCondition, PermissionFilteringConditionRule, PermissionFilteringConditionType } from '@/domain/permission/Permission.model';
import { EmploymentStatus } from '@/domain/employment/Employment.model';

type ConditionEditorDialogProps = {
    open: boolean;
    availableRules: PermissionFilteringConditionRule[];
    defaultConditions: FilteringCondition[];
    onCancel: () => void;
    onSave: (conditionType: ConditionType, conditions: FilteringCondition[]) => void;
    isAllEmployeeFilterType: boolean;
};

const defaultCondition: FilteringCondition = {
    id: 0,
    filterConditionValues: [
        {
            id: 0,
            employmentStatus: EmploymentStatus.EMPLOYED,
        },
        {
            id: 0,
            employmentStatus: EmploymentStatus.ON_LONG_LEAVE,
        },
    ],
    conditionType: PermissionFilteringConditionType.EMPLOYMENT_STATUS,
    conditionRule: PermissionFilteringConditionRule.EQUALS,
};

export const ConditionEditorDialog: FC<ConditionEditorDialogProps> = ({
    open,
    onCancel,
    onSave,
    availableRules,
    defaultConditions,
    isAllEmployeeFilterType,
}) => {
    const { t } = useTranslation();
    const [conditions, setConditions] = useState<FilteringCondition[]>(defaultConditions?.length !== 0 ? defaultConditions : [defaultCondition]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [conditionType, setConditionType] = useState<ConditionType>(
        isAllEmployeeFilterType ? ConditionType.ALL_EMPLOYEES : ConditionType.SPECIFIC_CONDITIONS,
    );

    const isConditionValueAdded = (): boolean => {
        return conditions?.some(item => item.conditionRule !== PermissionFilteringConditionRule.SAME_AS_VIEWER && item.filterConditionValues.length === 0);
    };

    const getAvailableConditionsType = () =>
        Object.keys(PermissionFilteringConditionType).filter(defaultCondition => conditions?.every(condition => condition.conditionType !== defaultCondition));

    const isFormValid = (): boolean => {
        return conditionType !== ConditionType.SPECIFIC_CONDITIONS || conditions.length === 0 || !isConditionValueAdded();
    };

    const addCondition = () => {
        const newCondition: FilteringCondition = {
            id: 0,
            filterConditionValues: [],
            conditionType: getAvailableConditionsType()[0] as PermissionFilteringConditionType,
            conditionRule: PermissionFilteringConditionRule.EQUALS,
        };
        setConditions([...conditions, newCondition]);
    };

    const handleConditionTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const conditionType = event.target.value as ConditionType;
        setConditionType(conditionType);
    };

    return (
        <Dialog open={open} fullScreen={fullScreen} maxWidth='md'>
            <DialogTitle>{t('group_condition_dialog.select_members')}</DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <FormControl>
                        <RadioGroup row value={conditionType} onChange={handleConditionTypeChange}>
                            <FormControlLabel
                                value={ConditionType.SPECIFIC_CONDITIONS}
                                control={<Radio />}
                                label={t('group_dialog.specific_conditions')}
                                labelPlacement={'end'}
                            />
                        </RadioGroup>
                    </FormControl>
                    {conditionType === ConditionType.SPECIFIC_CONDITIONS && (
                        <Stack direction='column' justifyContent='center' alignItems='flex-start' gap={2}>
                            {conditions?.map((condition, index) => {
                                return (
                                    <ConditionPanel
                                        key={condition?.conditionType ?? '' + condition?.id + index}
                                        condition={condition}
                                        onChange={newCondition => {
                                            conditions[index] = newCondition;
                                            setConditions([...conditions]);
                                        }}
                                        onDeleted={() => {
                                            conditions.splice(index, 1);
                                            setConditions([...conditions]);
                                        }}
                                        availableRules={availableRules}
                                        isDeleteConditionDisabled={conditions?.length <= 1}
                                        availableConditionType={getAvailableConditionsType()}
                                    />
                                );
                            })}
                            {getAvailableConditionsType().length !== 0 && (
                                <Button variant='text' onClick={() => addCondition()}>
                                    {t('group_condition_dialog.add_filter')}
                                </Button>
                            )}
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()} variant='text' fullWidth>
                    {t('general.cancel')}
                </Button>
                <Button variant='contained' color='primary' disabled={!isFormValid()} onClick={() => onSave(conditionType, conditions)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
