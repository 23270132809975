import { useCallback, useEffect, useState } from 'react';
import { UseQueryResult } from '@/page/Query.type';
import { countPendingLeaveRequests, getLeaveRequestById } from '@/domain/leave-request/LeaveRequest.service';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { handleError } from '@/utils/api.util';

export const useCountPendingLeaveRequests = (enabled = true): UseQueryResult<number> => {
    const [count, setCount] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCountPendingLeaveRequests = useCallback(async (enabled: boolean) => {
        if (!enabled) {
            setCount(0);
            setIsLoading(false);
            return;
        }
        try {
            const countLeaveRequests = await countPendingLeaveRequests();
            setCount(countLeaveRequests);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchCountPendingLeaveRequests(enabled).catch(handleError);
    }, [fetchCountPendingLeaveRequests, enabled]);

    return {
        data: count,
        setData: setCount,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchCountPendingLeaveRequests(enabled),
    };
};

export const useGetLeaveRequestById = (id?: number): UseQueryResult<LeaveRequest> => {
    const [leaveRequest, setLeaveRequest] = useState<LeaveRequest>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchGetLeaveRequestById = useCallback(async () => {
        if (id) {
            try {
                const leaveRequest = await getLeaveRequestById(id);
                setLeaveRequest(leaveRequest);
            } catch (error) {
                setError(error);
            }
        }
        setIsLoading(false);
    }, [id]);

    useEffect(() => {
        fetchGetLeaveRequestById();
    }, [fetchGetLeaveRequestById]);

    return {
        data: leaveRequest,
        setData: setLeaveRequest,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchGetLeaveRequestById,
    };
};
