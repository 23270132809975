import { Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getRequestStatusTranslationKey } from '@/domain/leave-request/LeaveRequest.service';
import { forwardRef } from 'react';
import { TimesheetRequestDisplayStatus } from '@/domain/timesheet/Timesheet.model';
import { Alert02Icon } from 'hugeicons-react';

type Props = {
    status: string;
};

/*eslint @typescript-eslint/no-explicit-any: off*/
export const RequestStatusChip = forwardRef<HTMLDivElement, Props>(function RequestStatusChip(props, ref) {
    const { t } = useTranslation();
    if (!props.status) {
        return;
    }

    const getStatusColor = (status: string) => {
        if (status === 'PENDING') {
            return 'warning';
        } else if (status === 'APPROVED') {
            //LeaveRequestStatus
            return 'success';
        } else if (status === 'DECLINED') {
            //LeaveRequestStatus
            return 'error';
        } else if (status === 'CANCELLED') {
            //LeaveRequestStatus
            return 'error';
        } else if (status === TimesheetRequestDisplayStatus.AUTO_FILLED) {
            return 'success';
        } else if (status === TimesheetRequestDisplayStatus.SHIFT_TIMESHEET) {
            return 'success';
        } else if (status === TimesheetRequestDisplayStatus.LOCKED || status === 'LOCKED') {
            return 'default';
        }
    };

    return props.status === TimesheetRequestDisplayStatus.MISSING ? (
        <Typography
            variant='body2'
            color='error'
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: 28,
                paddingY: 0.5,
                paddingX: 1,
                '& svg': {
                    marginRight: 0.5,
                },
            }}
        >
            <Alert02Icon size={18} /> {t('timesheets.missing')}
        </Typography>
    ) : (
        <Chip
            size='small'
            {...props}
            disabled={false} //needs to have this so the props doest spread this value
            ref={ref}
            color={getStatusColor(props.status)}
            label={t(getRequestStatusTranslationKey(props.status))}
            sx={{ width: '100%' }}
        />
    );
});
