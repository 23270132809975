import { SCALE_COLOR_SCHEME } from '@/domain/survey/Survey.service';

export const getColorProgressBarIndex = (score: number): number => {
    const scoreRoundNumber = score.toFixed(0);
    if (parseInt(scoreRoundNumber) < SCALE_COLOR_SCHEME.length) {
        return parseInt(scoreRoundNumber);
    } else {
        return 0;
    }
};

const SCALE_COLORS_RGB = [
    {
        r: '255',
        g: '0',
        b: '0',
    },
    {
        r: '255',
        g: '214',
        b: '0',
    },
    {
        r: '4',
        g: '204',
        b: '0',
    },
];

export const getRgbFromScale = (fraction: number): string => {
    const DEFAULT_WHITE_VALUE = '#F8FAFC';

    const isFractionValid = fraction >= 0 && fraction <= 1;
    if (!isFractionValid) {
        return DEFAULT_WHITE_VALUE;
    }

    let color1 = SCALE_COLORS_RGB[0];
    let color2 = SCALE_COLORS_RGB[1];
    let fade = fraction * 2;

    if (fade >= 1) {
        fade -= 1;
        color1 = SCALE_COLORS_RGB[1];
        color2 = SCALE_COLORS_RGB[2];
    }

    const diffRed = parseInt(color2.r) - parseInt(color1.r);
    const diffGreen = parseInt(color2.g) - parseInt(color1.g);
    const diffBlue = parseInt(color2.b) - parseInt(color1.b);

    const gradient = {
        r: Math.floor(parseInt(color1.r) + diffRed * fade),
        g: Math.floor(parseInt(color1.g) + diffGreen * fade),
        b: Math.floor(parseInt(color1.b) + diffBlue * fade),
    };

    return 'rgb(' + gradient.r + ',' + gradient.g + ',' + gradient.b + ')';
};

export const DEFAULT_SHIFT_BACKGROUND_COLOR = '#616161';

export const LIGHTEN_COEFFICIENT = 0.7;
