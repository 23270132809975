import { IconButton, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { ArrowLeft01Icon } from 'hugeicons-react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';

export type BackButtonProps = {
    title: string;
} & StackProps;

export const HeaderBackButton: FC<BackButtonProps> = ({ title, ...rest }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();

    const handleBackButtonClick = (): void => {
        navigate(-1);
    };

    const hasHistory = (): boolean => {
        return location?.key !== 'default';
    };

    return (
        <Stack direction={'row'} alignItems='center' gap={1} {...rest}>
            {hasHistory() && (
                // Todo: improve theming of IconButton
                <IconButton
                    onClick={() => handleBackButtonClick()}
                    sx={{
                        border: '1px solid',
                        borderRadius: 1,
                        borderColor: theme.palette.grey[300],
                        height: 32,
                        width: 32,
                    }}
                >
                    <ArrowLeft01Icon color={theme.palette.text.primary} />
                </IconButton>
            )}

            <Typography variant='h1'>{title}</Typography>
        </Stack>
    );
};
