import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { JobLevel } from '@/domain/job-level/JobLevel.model';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { TranslationLanguageSelector } from '@/components/translation-language-selector/TranslationLanguageSelector';
import { FieldLabel } from '@/components/form/field-label/FieldLabel';

type JobLevelSettingsDialogProps = {
    onSave: (data: JobLevelFormValues) => void;
    jobLevel: JobLevel | undefined;
} & DialogWrapperProps;

const jobLevelSchema = (translationLanguage: UserLanguage) => {
    return yup.object().shape({
        name: getLabelFormSchema(translationLanguage),
    });
};

export type JobLevelFormValues = yup.InferType<ReturnType<typeof jobLevelSchema>>;

export const JobLevelSettingsDialog: FC<JobLevelSettingsDialogProps> = ({ onSave, jobLevel, ...restDialog }) => {
    const { t } = useTranslation();
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());

    const { control, handleSubmit } = useForm<JobLevelFormValues>({
        resolver: yupResolver(jobLevelSchema(translationLanguage)),
        defaultValues: {
            name: jobLevel?.name ?? createDefaultLabel(),
        },
    });

    const handleOnSave = (data: JobLevelFormValues) => {
        onSave(data);
    };

    const header = (
        <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1}>
            <Typography variant={'body1bold'}>
                {jobLevel ? t('job_level_setting_page.dialog.edit_title') : t('job_level_setting_page.dialog.add_title')}
            </Typography>
            <TranslationLanguageSelector translationLanguage={translationLanguage} onLanguageChange={setTranslationLanguage} />
        </Stack>
    );

    return (
        <DialogWrapper header={header} {...restDialog}>
            <Stack component={DialogContent}>
                <FieldLabel
                    control={control}
                    name='name'
                    aria-label={'job-level-name'}
                    fullWidth
                    language={translationLanguage}
                    label={t('job_level_setting_page.dialog.name_input')}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleOnSave, console.error)} fullWidth>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
