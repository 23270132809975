import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FilePicker } from '@/components/file-picker/FilePicker';
import { isTemporaryFile } from '@/components/file-picker/FilePicker.util';
import { useFilePicker } from '@/components/file-picker/useFilePicker';
import { getAppConfig } from '@/config/config';
import { CompanyDocumentLinkCreationMutation, Document, DocumentLink, DocumentUpdateMutation, Folder, PreviewData } from '@/domain/document/Document.model';
import {
    createCompanyDocumentLink,
    createCompanyDocuments,
    deleteCompanyDocument,
    getCompanyDocumentDownloadUrl,
    updateCompanyDocument,
} from '@/domain/document/Document.service';
import { canDeleteDocumentsInCompanyFolder, canManageDocumentsInCompanyFolder } from '@/domain/permission/Permission.service';
import { useGetCompanyDocuments } from '@/hooks/document/Document.hook';
import { DocumentDetailHeader } from '@/page/document/document-header/DocumentDetailHeader';
import { DocumentLinkDialog } from '@/page/document/document-link-dialog/DocumentLinkDialog';
import { DocumentNameDialog } from '@/page/document/document-name-dialog/DocumentNameDialog';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { DocumentTable } from '@/page/document/document-table/DocumentTable';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Button, CircularProgress, DialogActions, DialogContent, Paper, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    folder: Folder;
};

const config = getAppConfig();

export const CompanyDocumentDetails: FC<Props> = ({ folder }) => {
    const [documentPreviewDialogOpen, setDocumentPreviewDialogOpen] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<PreviewData>();
    const [addLinkDialogOpen, setAddLinkDialogOpen] = useState<boolean>(false);
    const [documentToEdit, setDocumentToEdit] = useState<Document>();
    const [isUploadDocumentDialogOpen, setIsUploadDocumentDialogOpen] = useState<boolean>(false);
    const realm = useCurrentRealm();
    const policies = useCurrentPolicies();

    const { data: companyFolderDocuments = [], setData: setCompanyFolderDocuments, isLoading } = useGetCompanyDocuments(folder?.id);

    const canManageCompanyFolder = canManageDocumentsInCompanyFolder(realm.realmFeatures, policies, folder?.id);
    const canDeleteEmployeeFolder = canDeleteDocumentsInCompanyFolder(realm.realmFeatures, policies, folder?.id);

    const handleAddLink = async (values: DocumentLink) => {
        try {
            const companyDocumentCreationRequest: CompanyDocumentLinkCreationMutation = {
                folderId: folder.id,
                ...values,
            };
            const documents = await createCompanyDocumentLink([companyDocumentCreationRequest]);
            setCompanyFolderDocuments([...companyFolderDocuments, ...documents]);
            setAddLinkDialogOpen(false);
        } catch (e) {
            handleError(e);
        }
    };

    const handleEdit = async (values: DocumentUpdateMutation) => {
        if (!documentToEdit?.id) {
            return;
        }
        try {
            const updated = await updateCompanyDocument(documentToEdit.id, values);
            setCompanyFolderDocuments(companyFolderDocuments.map(document => (document.id === updated.id ? updated : document)));
            setDocumentToEdit(undefined);
        } catch (e) {
            handleError(e);
        }
    };

    const handleCloseUploadDocumentDialog = () => {
        setIsUploadDocumentDialogOpen(false);
    };

    const handleCompanyFolderDocumentUpdate = (documents: Document[]) => {
        setCompanyFolderDocuments([...companyFolderDocuments, ...documents]);
    };

    const onDownloadClick = (documentData: Document) => {
        getCompanyDocumentDownloadUrl(documentData.id, 'ATTACHMENT')
            .then(documentDownloadUrl => {
                // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
                setTimeout(() => {
                    window.open(documentDownloadUrl, '_blank');
                });
            })
            .catch(handleError);
    };

    return (
        <Stack component={Paper} direction='column' flex={1} p={2} gap={2}>
            <DocumentDetailHeader
                folder={folder}
                openFilePicker={() => setIsUploadDocumentDialogOpen(true)}
                onAddLinkClick={() => setAddLinkDialogOpen(true)}
                canManageDocument={canManageCompanyFolder}
            />
            <Stack flex='1'>
                <DocumentTable
                    canManageDocument={canManageCompanyFolder}
                    canDeleteDocument={canDeleteEmployeeFolder}
                    folderDocuments={companyFolderDocuments}
                    onDeleteClicked={(documentId: number) => {
                        deleteCompanyDocument(documentId)
                            .then(() => {
                                setCompanyFolderDocuments(companyFolderDocuments.filter(document => document.id !== documentId));
                            })
                            .catch(handleError);
                    }}
                    onPreviewClicked={(documentData, type: string) => {
                        getCompanyDocumentDownloadUrl(documentData.id, 'INLINE')
                            .then(documentDownloadUrl => {
                                const previewUrl =
                                    type === 'OFFICE' ? `${config.OFFICE_PREVIEW_URL}${encodeURIComponent(documentDownloadUrl)}` : `${documentDownloadUrl}`;
                                setDocumentPreviewDialogOpen(true);
                                setPreviewData({
                                    document: documentData,
                                    url: previewUrl,
                                });
                            })
                            .catch(handleError);
                    }}
                    onDownloadClicked={onDownloadClick}
                    onEditClicked={documentData => setDocumentToEdit(documentData)}
                    isLoading={isLoading}
                />
            </Stack>
            {documentPreviewDialogOpen && !!previewData && (
                <DocumentPreviewDialog onClose={() => setDocumentPreviewDialogOpen(false)} previewData={previewData} onDownloadClick={onDownloadClick} />
            )}
            {addLinkDialogOpen && <DocumentLinkDialog open={true} onClose={() => setAddLinkDialogOpen(false)} onSave={handleAddLink} />}
            {documentToEdit?.documentType === 'LINK' && (
                <DocumentLinkDialog open={true} onClose={() => setDocumentToEdit(undefined)} onSave={handleEdit} document={documentToEdit} />
            )}
            {documentToEdit?.documentType === 'DOCUMENT' && (
                <DocumentNameDialog open={true} onClose={() => setDocumentToEdit(undefined)} onSave={handleEdit} document={documentToEdit} />
            )}

            {isUploadDocumentDialogOpen && (
                <UploadCompanyDocumentDialog
                    handleCloseUploadDocumentDialog={handleCloseUploadDocumentDialog}
                    folderId={folder?.id}
                    handleCompanyFolderDocumentUpdate={handleCompanyFolderDocumentUpdate}
                />
            )}
        </Stack>
    );
};

type UploadCompanyDocumentDialogProps = {
    handleCloseUploadDocumentDialog: () => void;
    handleCompanyFolderDocumentUpdate?: (documents: Document[]) => void;
    folderId: number;
};

const UploadCompanyDocumentDialog: FC<UploadCompanyDocumentDialogProps> = ({
    handleCloseUploadDocumentDialog,
    folderId,
    handleCompanyFolderDocumentUpdate,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const { files, removeFile, addFiles, updateFile } = useFilePicker([]);

    const handleSettingsDialogSave = () => {
        if (!files) {
            return;
        }

        const temporaryFiles = files.filter(isTemporaryFile);

        setLoading(true);
        createCompanyDocuments({
            folderId,
            files: temporaryFiles,
        })
            .then(documents => {
                handleCompanyFolderDocumentUpdate?.(documents);
                handleCloseUploadDocumentDialog();
            })
            .catch(handleError)
            .finally(() => setLoading(false));
    };

    return (
        <DialogWrapper header={t('documents.upload_document_dialog_title')} open={true} onClose={() => handleCloseUploadDocumentDialog()}>
            <DialogContent>
                <FilePicker onFileRenamed={updateFile} onFileUploaded={addFiles} onFileRemoved={removeFile} files={files} fetchDocumentUrl={undefined} />
            </DialogContent>
            <DialogActions>
                <Button disabled={files?.length === 0 || loading} onClick={handleSettingsDialogSave} fullWidth>
                    {loading ? <CircularProgress size={20} /> : t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
