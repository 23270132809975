import { client } from '@/api/common';
import { getApiBaseUrl } from '@/config/config';
import { ActuatorInfo } from '@/domain/api-info/ApiInfo.model';

const getApiInfo = async (): Promise<ActuatorInfo> => {
    const { data } = await client.get(getApiBaseUrl() + '/actuator/info');
    return data?.build;
};

export const ApiInfoApi = {
    getApiInfo,
};
