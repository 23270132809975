import { EmployeeAvatar as EmployeeAvatarType } from '@/domain/employee/Employee.model';
import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { EmployeeAvatar, EmployeeAvatarProps } from './EmployeeAvatar';

type EmployeeCardProps = {
    employee: EmployeeAvatarType | undefined;
    avatarProps?: Omit<EmployeeAvatarProps, 'employeeAvatar'>;
    infoWidth?: TypographyProps['width'];
} & StackProps;

export const EmployeeAvatarWithDetails: FC<PropsWithChildren<EmployeeCardProps>> = ({ employee, avatarProps, children, infoWidth, ...rest }) => {
    return (
        <Stack direction='row' gap={1} alignItems='center' {...rest}>
            {employee && <EmployeeAvatar employeeAvatar={employee} {...avatarProps} />}
            <Stack width={infoWidth}>
                <Typography variant='body1bold'>{employee?.displayName}</Typography>

                {children}
            </Stack>
        </Stack>
    );
};
