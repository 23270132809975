import { API_BASE_URL, client } from '@/api/common';
import { Area, AreaSearchRequest } from '@/domain/area/Area.model';

const searchAreas = async (request: AreaSearchRequest): Promise<Area[]> => {
    const { data } = await client.post(API_BASE_URL + '/areas/search', request);
    return data;
};

export const areaApi = {
    searchAreas,
};
