import { JobFamily, JobFamilyCreateMutation, JobFamilyUpdateMutation } from '@/domain/job-family/JobFamily.model';
import { jobFamilyAPI } from '@/api/job-family/JobFamily.api';

export const getJobFamilies = (): Promise<JobFamily[]> => {
    return jobFamilyAPI.getJobFamilies();
};

export const getJobFamilyById = (jobFamilyId: number): Promise<JobFamily> => {
    return jobFamilyAPI.getJobFamilyById(jobFamilyId);
};

export const createJobFamily = (mutation: JobFamilyCreateMutation): Promise<JobFamily> => {
    return jobFamilyAPI.createJobFamily(mutation);
};

export const updateJobFamily = (jobFamilyId: number, mutation: JobFamilyUpdateMutation): Promise<JobFamily> => {
    return jobFamilyAPI.updateJobFamily(jobFamilyId, mutation);
};

export const deleteJobFamily = (jobFamilyId: number): Promise<void> => {
    return jobFamilyAPI.deleteJobFamily(jobFamilyId);
};
