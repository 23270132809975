import { API_BASE_URL } from '@/api/common';
import { Realm, RealmUpdateMutation } from '@/domain/realm/Realm.model';
import { Auth } from 'aws-amplify';
import axios from 'axios';

const searchRealmByName = async (realmName: string): Promise<Realm[]> => {
    const { data } = await axios.post(API_BASE_URL + `/realms/search`, { name: realmName });
    return data;
};

const updateRealm = async (mutation: RealmUpdateMutation, tenantId: string): Promise<void> => {
    const session = await Auth.currentSession();
    const jwt = session.getIdToken().getJwtToken();
    await axios.put(API_BASE_URL + `/realms/${tenantId}`, mutation, {
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    });
};

export const realmApi = {
    searchRealmByName,
    updateRealm,
};
