import { WorkingPatternTemplate } from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { searchWorkingPatternTemplates } from '@/domain/working-pattern-template/WorkingPatternTemplate.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetWorkingTemplates = (): UseQueryResult<WorkingPatternTemplate[]> => {
    const [workingPatternTemplates, setWorkingPatternTemplates] = useState<WorkingPatternTemplate[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchData = useCallback(async () => {
        try {
            const data = await searchWorkingPatternTemplates();
            setWorkingPatternTemplates(data);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchData().catch(handleError);
    }, [fetchData]);

    return {
        data: workingPatternTemplates,
        setData: setWorkingPatternTemplates,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchData(),
    };
};
