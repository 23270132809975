import { SurveyQuestion } from '@/domain/survey/Survey.model';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import { Grid, Paper, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import { FC } from 'react';

type Props = {
    question: SurveyQuestion;
    selectedLanguage: UserLanguage;
};

export const SurveySection: FC<Props> = ({ question, selectedLanguage }) => {
    if (!question) {
        return <></>;
    }

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Paper
                sx={{
                    padding: 6,
                    maxWidth: 720,
                    margin: 'auto',
                }}
            >
                <Grid item>
                    <Typography
                        variant='h1'
                        sx={theme => ({
                            marginBottom: 4,
                            display: 'block',
                            textAlign: 'center',
                            [theme.breakpoints.down('md')]: {
                                marginBottom: 24,
                                fontSize: 18,
                                display: 'block',
                            },
                        })}
                    >
                        {getLabelTranslation(question.question, selectedLanguage)}
                    </Typography>
                </Grid>
            </Paper>
        </Slide>
    );
};
