import { Button } from '@mui/material';
import { Survey } from '@/domain/survey/Survey.model';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { Analytics01Icon } from 'hugeicons-react';

type Props = {
    survey: Survey;
};

export const SurveyResultsButton: FC<Props> = ({ survey }) => {
    const navigate = useNavigate();

    const goToResultPage = () => {
        navigate(`/manage-surveys/surveys/${survey.id}/results`);
    };

    return (
        <Button
            onClick={goToResultPage}
            disabled={survey.participationCount < 1}
            // This is a hack to make a square icon button
            sx={{ paddingX: 0.8, minWidth: 'auto' }}
        >
            <Analytics01Icon />
        </Button>
    );
};
