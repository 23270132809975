import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { CostCenter } from '@/domain/cost-center/CostCenter.model';
import { searchCostCenters } from '@/domain/cost-center/CostCenter.service';

export const useGetCostCenters = (): UseQueryResult<CostCenter[]> => {
    const [costCenters, setCostCenters] = useState<CostCenter[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCostCenters = useCallback(async () => {
        try {
            const costCenters = await searchCostCenters();
            setCostCenters(costCenters);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchCostCenters().catch(handleError);
    }, [fetchCostCenters]);

    return {
        data: costCenters,
        setData: setCostCenters,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchCostCenters(),
    };
};
