import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { RequestStatusChip } from '@/components/request-status-chip/RequestStatusChip';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { Employee } from '@/domain/employee/Employee.model';
import { EmployeeLeaveBalanceHistorySearchRequest } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import { MonthlyTimesheetReport, MonthTimesheet, TimesheetCycle, TimesheetSearch } from '@/domain/timesheet/Timesheet.model';
import { getYearFromTimesheetCycle } from '@/domain/timesheet/Timesheet.service';
import { useGetEmployeeById } from '@/hooks/employee/Employee.hook';
import { useGetLeaveTypeHistories } from '@/hooks/leave-type-history/LeaveTypeHistory.hook';
import { useGetEmployeeMonthlyTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { activeMonth } from '@/page/employee-profile/employee-profile-timesheet/timesheets-history/TimesheetsHistory.util';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { MobileMonthlyView } from '@/page/employee-timesheet/mobile-monthly-view/MobileMonthlyView';
import { MissingCount } from '@/page/timesheet/missing-count/MissingCount';
import { desktopVisible, mobileVisible } from '@/theme/responsive';
import {
    addYears,
    formatDate,
    formatDurationInHours,
    formatToLocalDate,
    getAllYears,
    getCurrentLocalDate,
    getEndOfYear,
    getMonthTranslationKey,
    getStartOfYear,
    getTodayDate,
    isAfterDate,
    isBeforeDate,
    MONTHS,
    subDaysAndFormat,
    toDate,
} from '@/utils/datetime.util';
import { Stack, Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TimesheetHeader } from '../../employee-timesheet/TimesheetHeader';
import { StatusChipTooltipTitleLocked } from './EmployeeProfileTimesheetsHistoryPage';

export const EmployeeProfileTimesheetsPage: FC = () => {
    const employeeId = useEmployeeProfileId();

    const { data: employee, isLoading, isError, error } = useGetEmployeeById(employeeId);

    if (!employee) {
        return;
    }

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <TimesheetsTabPageView employee={employee} />
        </StateHandler>
    );
};

type TimesheetsTabPageViewProps = {
    employee: Employee;
};

const TimesheetsTabPageView: FC<TimesheetsTabPageViewProps> = ({ employee }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const timesheetCycles = buildCycles(employee);

    const currentTimesheetCycle =
        timesheetCycles.find(cycle => !isAfterDate(cycle.cycleStartDate, getCurrentLocalDate()) && !isBeforeDate(cycle.cycleEndDate, getCurrentLocalDate())) ??
        defaultCycle;

    const [selectedTimesheetCycle, setSelectedTimesheetCycle] = useState<TimesheetCycle>(currentTimesheetCycle);
    const balanceEndDate = getCurrentLocalDate();

    const filtersToday: EmployeeLeaveBalanceHistorySearchRequest = {
        leaveActivityTypes: [LeaveActivityType.TIMESHEET_COMPENSATION],
        endDate: balanceEndDate,
    };
    const filtersForecasted: EmployeeLeaveBalanceHistorySearchRequest = {
        leaveActivityTypes: [LeaveActivityType.TIMESHEET_COMPENSATION],
        //no need to send the endDate for forecasted leave type histories because the BE will calculate until the end of the year
    };

    const {
        data: leaveTypeHistoriesToday = [],
        isLoading: isLoadingToday,
        isError: isErrorToday,
        error: leaveTypesError,
    } = useGetLeaveTypeHistories(employee.id, filtersToday);
    const {
        data: leaveTypeHistoriesForecasted = [],
        isLoading: isLoadingForecasted,
        isError: isErrorForecasted,
        error: forecastedLeaveTypesError,
    } = useGetLeaveTypeHistories(employee.id, filtersForecasted);

    const getTimesheetSearch = (): TimesheetSearch => {
        if (selectedTimesheetCycle) {
            return {
                employeeIds: [employee.id],
                startDate: selectedTimesheetCycle.cycleStartDate,
                endDate: selectedTimesheetCycle.cycleEndDate,
            };
        }
        return {
            employeeIds: [employee.id],
            startDate: currentTimesheetCycle?.cycleStartDate ?? getStartOfYear(),
            endDate: currentTimesheetCycle?.cycleEndDate ?? getEndOfYear(),
        };
    };

    const timesheetSearch = getTimesheetSearch();

    const {
        data: monthlyTimesheetReports = [],
        isFetching = true,
        isError: isErrorMonthly,
        error: errorMonthly,
        refetch: refetchMonthly,
    } = useGetEmployeeMonthlyTimesheets({
        timesheetSearch: timesheetSearch,
    });

    const timesheetsMonthly = monthlyTimesheetReports ? monthlyTimesheetReports[0] : undefined;

    const createBottomPinnedData = (timesheetsMonthly?: MonthlyTimesheetReport) => {
        if (!timesheetsMonthly) {
            return [];
        }
        return [
            {
                month: t('timesheets.total'),
                totalWorkedCount: timesheetsMonthly.totalWorkedCount,
                totalLeaveCount: timesheetsMonthly.totalForecastedLeaveCount + timesheetsMonthly.totalPublicHolidayCount,
                totalContractCount: timesheetsMonthly.totalContractCount + timesheetsMonthly.totalPublicHolidayCount,
                totalDifference: timesheetsMonthly.totalForecastedDifference + timesheetsMonthly.previousCarryover,
                totalBonusCount: timesheetsMonthly.totalForecastedBonusCount,
                totalMissingCount: timesheetsMonthly.totalMissingCount,
                totalCompensationCount: timesheetsMonthly.totalForecastedCompensationCount,
                totalFutureCompensationCount: 0, // hack to display the totalCompensationCount
                totalPaymentCount: timesheetsMonthly.totalForecastedPaymentCount,
                totalAdjustmentCount: timesheetsMonthly.totalForecastedAdjustmentCount,
            },
        ];
    };

    const createTopPinnedData = (timesheetsMonthly?: MonthlyTimesheetReport) => {
        const startDate = subDaysAndFormat(selectedTimesheetCycle.cycleStartDate, 1);
        const formattedDate = formatDate(startDate, selectedTimesheetCycle.startMonth === MONTHS.JANUARY ? 'yyyy' : 'MMM yyyy');
        return [
            {
                month: `${t('timesheets.carryover')} ${formattedDate}`,
                totalDifference: timesheetsMonthly?.previousCarryover,
            },
        ];
    };

    const columnDefs: RogerColDef<MonthTimesheet>[] = [
        {
            headerName: t('timesheets.timesheet_tab_page_table_headers.period'),
            field: 'month',
            valueFormatter: data => {
                if (data?.data?.month && Object.values(MONTHS).includes(data?.data?.month)) {
                    return t(getMonthTranslationKey(data?.data?.month));
                }
                return data?.data?.month ?? '';
            },
        },
        {
            headerName: t('timesheets.timesheet_tab_page_table_headers.worked_count'),
            field: 'totalWorkedCount',
            type: 'minutesToHours',
        },
        {
            headerName: t('timesheets.timesheet_tab_page_table_headers.leave_count'),
            field: 'totalLeaveCount',
            type: 'hours',
            valueGetter: data => (data?.data?.totalLeaveCount ?? 0) + (data?.data?.totalPublicHolidayCount ?? 0) + (data?.data?.totalFutureLeaveCount ?? 0),
        },
        {
            headerName: t('timesheets.timesheet_tab_page_table_headers.contract_count'),
            field: 'totalContractCount',
            valueGetter: data => (data?.data?.totalContractCount ?? 0) + (data?.data?.totalPublicHolidayCount ?? 0),
            type: 'hours',
        },
        {
            headerName: t('timesheets.timesheet_tab_page_table_headers.difference'),
            field: 'totalDifference',
            type: 'hours',
        },
        {
            field: 'totalBonusCount',
            headerName: t('timesheets.timesheet_tab_page_table_headers.bonus_count'),
            type: 'hours',
            hide: timesheetsMonthly?.monthTimesheets?.every(monthTimesheet => !monthTimesheet?.totalBonusCount),
        },
        {
            headerName: t('timesheets.timesheet_tab_page_table_headers.payments'),
            field: 'totalPaymentCount',
            valueFormatter: data => (data?.data?.totalPaymentCount ? `-${formatDurationInHours(data.data.totalPaymentCount)}` : '-'),
            hide: timesheetsMonthly?.monthTimesheets?.every(monthTimesheet => !monthTimesheet?.totalPaymentCount),
        },
        {
            headerName: t('timesheets.timesheet_tab_page_table_headers.compensations'),
            field: 'totalCompensationCount',
            valueFormatter: data =>
                (data?.data?.totalCompensationCount ?? 0) + (data?.data?.totalFutureCompensationCount ?? 0)
                    ? `-${formatDurationInHours((data?.data?.totalCompensationCount ?? 0) + (data?.data?.totalFutureCompensationCount ?? 0))}`
                    : '-',
            hide: timesheetsMonthly?.monthTimesheets?.every(
                monthTimesheet => !monthTimesheet?.totalCompensationCount && !monthTimesheet?.totalFutureCompensationCount,
            ),
        },
        {
            headerName: t('timesheets.timesheet_tab_page_table_headers.adjustments'),
            field: 'totalAdjustmentCount',
            type: 'minutesToHours',
            hide: timesheetsMonthly?.monthTimesheets?.every(monthTimesheet => !monthTimesheet?.totalAdjustmentCount),
        },
    ];

    if (timesheetsMonthly?.monthTimesheets?.some(monthTimesheet => monthTimesheet?.missingCount !== 0 || monthTimesheet?.locked)) {
        const statusCellRenderer = ({ data }: { data?: MonthTimesheet }) => (
            <Status lastEmployeePayrollLock={data?.lastEmployeePayrollLock} isLocked={data?.locked} missingCount={data?.missingCount} />
        );

        columnDefs.push({
            headerName: t('timesheets.timesheet_tab_page_table_headers.status'),
            colId: 'status',
            cellRenderer: statusCellRenderer,
            cellStyle: () => ({
                display: 'flex',
                maxWidth: '150px',
            }),
        });
    }

    const isLoading = isFetching || isLoadingToday || isLoadingForecasted;
    const isError = isErrorToday || isErrorForecasted || isErrorMonthly;
    const error = leaveTypesError || forecastedLeaveTypesError || errorMonthly;

    return (
        <Stack gap={1} flexGrow={1}>
            <StateHandler isLoading={isLoading} isError={isError} isEmpty={!monthlyTimesheetReports?.length} error={error}>
                <TimesheetHeader
                    employee={employee}
                    selectedTimesheetCycle={selectedTimesheetCycle}
                    timesheetCycles={timesheetCycles}
                    onSuccess={refetchMonthly}
                    timesheetsMonthly={timesheetsMonthly}
                    currentTimesheetCycle={currentTimesheetCycle}
                    onUpdateTimesheetRequest={(cycle: TimesheetCycle) => {
                        setSelectedTimesheetCycle(cycle);
                    }}
                    leaveTypeHistoriesToday={leaveTypeHistoriesToday}
                    leaveTypeHistoriesForecasted={leaveTypeHistoriesForecasted}
                />
                {/* Desktop view */}
                <Stack direction='row' gap={2} justifyContent='space-between' alignItems='top' flexGrow={1} sx={desktopVisible}>
                    <Stack sx={{ flexGrow: 1 }}>
                        <AgGridWrapper<MonthTimesheet>
                            rowData={timesheetsMonthly?.monthTimesheets}
                            onRowClicked={gridParams => {
                                if (gridParams.data && Object.values(MONTHS).includes(gridParams.data.month)) {
                                    navigate(
                                        `/profile/${employee.id}/timesheets/history/${getYearFromTimesheetCycle(gridParams.data.month, selectedTimesheetCycle)}/${gridParams.data.month}`,
                                    );
                                }
                            }}
                            pinnedTopRowData={createTopPinnedData(timesheetsMonthly)}
                            pinnedBottomRowData={createBottomPinnedData(timesheetsMonthly)}
                            columnDefs={columnDefs}
                            statusBar={undefined}
                            compact={false}
                            loading={false}
                        />
                    </Stack>
                </Stack>
                {/* Mobile view */}
                {timesheetsMonthly && (
                    <Stack direction={'column'} spacing={2} sx={mobileVisible}>
                        <MobileMonthlyView timesheetsMonthly={timesheetsMonthly} employeeId={employee.id} selectedTimesheetCycle={selectedTimesheetCycle} />
                    </Stack>
                )}
            </StateHandler>
        </Stack>
    );
};

const Status: FC<{ lastEmployeePayrollLock?: EmployeePayrollLock; missingCount?: number; isLocked?: boolean }> = ({
    lastEmployeePayrollLock,
    missingCount,
    isLocked,
}) => {
    if (lastEmployeePayrollLock && isLocked) {
        return (
            <Tooltip title={<StatusChipTooltipTitleLocked lock={lastEmployeePayrollLock} />}>
                <RequestStatusChip status={'LOCKED'} />
            </Tooltip>
        );
    }
    return <MissingCount missingCount={missingCount} />;
};

const buildCycles = (employee: Employee): TimesheetCycle[] => {
    const timesheetSettings = employee?.currentWorkingPattern?.timesheetSetting;
    return getAllYears().map(year => {
        //e.g: 2021 -> september 2021 -> october 2022
        const month = timesheetSettings?.cycleStartMonth ?? MONTHS.JANUARY;
        const startDate = toDate(getCurrentLocalDate());
        startDate.setFullYear(year);
        startDate.setMonth(activeMonth(month));
        startDate.setDate(1);
        const endDate = subDaysAndFormat(addYears(startDate, 1), 1);
        return {
            cycleStartDate: formatToLocalDate(startDate),
            cycleEndDate: endDate,
            startMonth: month,
            year: year,
        };
    });
};

const defaultCycle = {
    cycleStartDate: getStartOfYear(),
    cycleEndDate: getEndOfYear(),
    startMonth: MONTHS.JANUARY,
    year: getTodayDate().getFullYear(),
};
