import { FC, ReactNode } from 'react';
import { Paper, Stack, Typography } from '@mui/material';

type SectionContainerProps = {
    children: ReactNode;
    title?: string;
};

export const SectionContainer: FC<SectionContainerProps> = ({ children, title }) => {
    return (
        <Paper elevation={0} sx={{ p: 2 }}>
            <Stack justifyContent='center' spacing={1} flex={1}>
                {title && (
                    <Stack direction='row' justifyContent='space-between' alignItems='flex-start' spacing={1} sx={{ width: '100%' }}>
                        <Typography variant='h1'>{title}</Typography>
                    </Stack>
                )}
                {children}
            </Stack>
        </Paper>
    );
};
