import { FC } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import logo from '@/assets/images/logo.svg';

type Props = {
    variant?: 'light' | 'dark';
    logoOnly?: boolean;
};

export const LogoRoger: FC<Props> = ({ variant = 'dark', logoOnly = false }) => {
    const { palette } = useTheme();
    return (
        <Stack direction='row' spacing={1} flexWrap='nowrap' alignItems='center'>
            <Box width={45} textAlign='center'>
                <img src={logo} alt='logo' />
            </Box>
            {!logoOnly && (
                <Typography align='center' color={variant === 'dark' ? palette.common.white : palette.common.black}>
                    ROGER
                </Typography>
            )}
        </Stack>
    );
};
