import { DatePickerWrapper } from '@/components/date-picker/DatePickerWrapper';
import { CalendarDay, CalendarDayType, getCalendarDayTypeTranslationKey } from '@/domain/calendar/Calendar.model';
import { isValidDate } from '@/utils/datetime.util';

import { showSnackbar } from '@/utils/snackbar.util';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Cancel01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    onClose: () => void;
    onSave: (day: CalendarDay) => void;
    onDelete: (day: CalendarDay) => void;
    day?: CalendarDay;
};

type FormValues = Overwrite<CalendarDay, { date: LocalDate | undefined }>;

export const CalendarDayDialog: FC<Props> = ({ open, onSave, onDelete, day, onClose }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [calendarDay, setCalendarDay] = useState<FormValues>(day ?? createDefaultCalendarDay());

    // TODO refactor this dialog to use react hook form and yup for validation
    const isFormValid = () => {
        //the date comes from the BE as a LocalDate and to validate it we need to convert to a Date object
        //when we do the refactor to use the react use form we can transform the date to a Date object in the default values
        return calendarDay.name && calendarDay.date && calendarDay.dayType && !!calendarDay.date && isValidDate(calendarDay.date);
    };

    const handleSave = () => {
        const { date, ...restData } = calendarDay;
        if (!date) {
            return;
        }
        onSave({ date, ...restData });
        showSnackbar(!day ? t('calendar_dialog.messages.calendar_day_added') : t('calendar_dialog.messages.calendar_day_updated'), 'success');
    };

    const handleDelete = () => {
        const { date, ...restData } = calendarDay;
        if (!date) {
            return;
        }
        onDelete({ date, ...restData });
        showSnackbar(t('calendar_dialog.messages.calendar_day_deleted'), 'success');
    };

    return (
        <Dialog open={open} fullScreen={fullScreen} onClose={onClose}>
            <DialogTitle>
                <Stack direction='row' justifyContent='space-between'>
                    {!day ? t('calendar_day_dialog.title_add_day') : t('calendar_day_dialog.title_edit_day')}
                    <IconButton aria-label='close' onClick={onClose} size='large'>
                        <Cancel01Icon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    <FormControlLabel
                        label={t('calendar_day_dialog.day_name')}
                        labelPlacement='top'
                        control={
                            <TextField
                                value={calendarDay.name}
                                fullWidth
                                onChange={event => {
                                    setCalendarDay({ ...calendarDay, name: event.target.value });
                                }}
                            />
                        }
                    />
                    <FormControlLabel
                        label={t('calendar_day_dialog.date')}
                        control={
                            <DatePickerWrapper
                                value={calendarDay.date}
                                onChange={newValue => {
                                    setCalendarDay({
                                        ...calendarDay,
                                        date: newValue ?? undefined,
                                    });
                                }}
                            />
                        }
                    />
                    {/* TODO replace by auto complete with options */}
                    <Select
                        label={t('calendar_day_dialog.type')}
                        id='calendar-day-type'
                        value={calendarDay.dayType}
                        onChange={event => {
                            setCalendarDay({
                                ...calendarDay,
                                dayType: event.target.value as CalendarDayType,
                            });
                        }}
                    >
                        <MenuItem key={CalendarDayType.HOLIDAY} value={CalendarDayType.HOLIDAY}>
                            {t(getCalendarDayTypeTranslationKey(CalendarDayType.HOLIDAY))}
                        </MenuItem>
                        <MenuItem key={CalendarDayType.WORKING_DAY} value={CalendarDayType.WORKING_DAY}>
                            {t(getCalendarDayTypeTranslationKey(CalendarDayType.WORKING_DAY))}
                        </MenuItem>
                    </Select>
                </Stack>
            </DialogContent>
            <DialogActions>
                {day && (
                    <Button variant='text' color='error' onClick={() => handleDelete()} fullWidth>
                        {t('calendar_day_dialog.delete_day')}
                    </Button>
                )}
                <Button fullWidth disabled={!isFormValid()} onClick={() => handleSave()}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const createDefaultCalendarDay = (): FormValues => {
    return {
        id: 0,
        dayType: CalendarDayType.HOLIDAY,
        name: '',
        date: undefined,
    };
};
