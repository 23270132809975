import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { SectionField } from '@/components/section/types';
import { SectionContainer } from '@/page/people/on-boarding-form/SectionContainer';
import { SectionFieldContainer } from '@/page/people/on-boarding-form/SectionFieldContainer';
import { getEnumFieldTypeOptions } from '@/domain/section-setting/Section.service';

export const TerminationStepForm: FC = () => {
    const { t } = useTranslation();

    const sectionFields: (Omit<SectionField, 'formValueName' | 'title'> & {
        formValueName: string;
        title: string;
    })[] = [
        {
            fieldDefinitionId: undefined,
            formValueName: 'terminationNoticeDate',
            title: t('employee.employment.termination.notice_date'),
            valueType: 'DATE',
            required: false,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_NOTICE_DATE',
        },
        {
            fieldDefinitionId: undefined,
            formValueName: 'terminationLastDayAtWork',
            title: t('employee.employment.termination.last_day_at_work'),
            valueType: 'DATE',
            required: false,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY',
        },
        {
            fieldDefinitionId: undefined,
            formValueName: 'endDate',
            title: t('employee.employment.termination.termination_date'),
            valueType: 'DATE',
            required: true,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY',
        },
        {
            fieldDefinitionId: undefined,
            formValueName: 'terminationType',
            enumList: getEnumFieldTypeOptions('CURRENT_EMPLOYMENT_TERMINATION_TYPE'),
            title: t('employee.employment.termination_type.termination_type'),
            valueType: 'ENUM',
            required: true,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_TYPE',
        },
        {
            fieldDefinitionId: undefined,
            formValueName: 'terminationReason',
            enumList: getEnumFieldTypeOptions('CURRENT_EMPLOYMENT_TERMINATION_REASON'),
            title: t('employee.employment.termination_reason.termination_reason'),
            valueType: 'ENUM',
            required: true,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_REASON',
        },
        {
            fieldDefinitionId: undefined,
            formValueName: 'terminationComment',
            title: t('general.comment'),
            valueType: 'STRING',
            required: false,
            fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_COMMENT',
        },
    ];

    return (
        <SectionContainer title={t('employee.employment.termination.dates_reason')}>
            {!!sectionFields?.length &&
                sectionFields.map(sectionField => {
                    return (
                        <SectionFieldContainer
                            key={sectionField.title}
                            formValueName={sectionField.formValueName}
                            title={sectionField.title}
                            required={sectionField.required}
                        >
                            {<EditableSectionFieldComponent field={sectionField} />}
                        </SectionFieldContainer>
                    );
                })}
        </SectionContainer>
    );
};
