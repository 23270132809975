import { employeeNotificationAPI } from '@/api/employee-notification/EmployeeNotification.api';
import {
    EmployeeNotification,
    EmployeeNotificationSearchRequest,
    EmployeeNotificationUpdateMutation,
} from '@/domain/employee-notification/EmployeeNotification.model';

export const searchEmployeeNotifications = async (searchRequest: EmployeeNotificationSearchRequest): Promise<EmployeeNotification[]> => {
    return employeeNotificationAPI.searchEmployeeNotifications(searchRequest);
};

export const getOwnEmployeeNotifications = async (): Promise<EmployeeNotification> => {
    return employeeNotificationAPI.getOwnEmployeeNotifications();
};

export const updateOwnEmployeeNotifications = async (mutation: EmployeeNotificationUpdateMutation): Promise<EmployeeNotification> => {
    return employeeNotificationAPI.updateOwnEmployeeNotifications(mutation);
};

export const updateEmployeesNotifications = async (mutations: EmployeeNotificationUpdateMutation[]): Promise<EmployeeNotification[]> => {
    return employeeNotificationAPI.updateEmployeesNotifications(mutations);
};
