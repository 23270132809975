import { API_BASE_URL, client } from '../common';
import { AxiosResponse } from 'axios';
import {
    SectionDefinition,
    SectionDefinitionCreateRequest,
    SectionDefinitionIncludeInPayrollRequest,
    SectionDefinitionSearchRequest,
    SectionDefinitionUpdateRequest,
    SectionFieldDefinition,
} from '@/domain/section-setting/Section.model';
import { OrderMutation } from '@/domain/common';

export type SectionDefinitionDTO = Overwrite<
    SectionDefinition,
    {
        fields: SectionFieldDefinitionDTO[];
    }
>;
export type SectionFieldDefinitionDTO = Omit<SectionFieldDefinition, 'formId'>;
type SectionDefinitionCreateRequestDTO = SectionDefinitionCreateRequest;
type SectionDefinitionIncludeInPayrollRequestDTO = SectionDefinitionIncludeInPayrollRequest;
type SectionDefinitionUpdateRequestDTO = SectionDefinitionUpdateRequest;

const getSectionDefinitions = async (): Promise<SectionDefinition[]> => {
    const { data } = await client.get<SectionDefinitionDTO[]>(API_BASE_URL + '/section-definitions');
    return data.map(mapSectionDefinitionDTO);
};

const searchSectionDefinitions = async (request: SectionDefinitionSearchRequest): Promise<SectionDefinition[]> => {
    const { data } = await client.post<SectionDefinitionDTO[]>(API_BASE_URL + '/section-definitions/search', request);
    return data.map(mapSectionDefinitionDTO);
};

const getSectionDefinitionById = async (id: number): Promise<SectionDefinition> => {
    const { data } = await client.get<SectionDefinitionDTO>(API_BASE_URL + `/section-definitions/${id}`);
    return mapSectionDefinitionDTO(data);
};

const createSectionDefinition = async (request: SectionDefinitionCreateRequest): Promise<SectionDefinition> => {
    const { data } = await client.post<SectionDefinitionDTO, AxiosResponse<SectionDefinitionDTO>, SectionDefinitionCreateRequestDTO>(
        API_BASE_URL + `/section-definitions`,
        request,
    );
    return mapSectionDefinitionDTO(data);
};

const updateIncludeInPayrollSectionDefinition = async (id: number, request: SectionDefinitionIncludeInPayrollRequest): Promise<SectionDefinition> => {
    const { data } = await client.patch<SectionDefinitionDTO, AxiosResponse<SectionDefinitionDTO>, SectionDefinitionIncludeInPayrollRequestDTO>(
        API_BASE_URL + `/section-definitions/${id}/include-in-payroll`,
        request,
    );
    return mapSectionDefinitionDTO(data);
};

const updateSectionDefinition = async (id: number, request: SectionDefinitionUpdateRequest): Promise<SectionDefinition> => {
    const { data } = await client.put<SectionDefinitionDTO, AxiosResponse<SectionDefinitionDTO>, SectionDefinitionUpdateRequestDTO>(
        API_BASE_URL + `/section-definitions/${id}`,
        request,
    );
    return mapSectionDefinitionDTO(data);
};

const deleteSectionDefinition = async (id: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/section-definitions/${id}`);
};

const archiveSectionDefinition = async (id: number): Promise<SectionDefinition> => {
    const { data } = await client.post<SectionDefinitionDTO>(API_BASE_URL + `/section-definitions/${id}/archive`);
    return mapSectionDefinitionDTO(data);
};

const unarchiveSectionDefinition = async (id: number): Promise<SectionDefinition> => {
    const { data } = await client.post<SectionDefinitionDTO>(API_BASE_URL + `/section-definitions/${id}/unarchive`);
    return mapSectionDefinitionDTO(data);
};

const archiveSectionFieldDefinition = async (fieldId: number): Promise<SectionFieldDefinition> => {
    const { data } = await client.post<SectionFieldDefinitionDTO>(API_BASE_URL + `/section-definitions/fields/${fieldId}/archive`);
    return mapSectionFieldDefinitionDTO(data);
};

const unarchiveSectionFieldDefinition = async (fieldId: number): Promise<SectionFieldDefinition> => {
    const { data } = await client.post<SectionFieldDefinitionDTO>(API_BASE_URL + `/section-definitions/fields/${fieldId}/unarchive`);
    return mapSectionFieldDefinitionDTO(data);
};

const updateSectionDefinitionsOrder = async (request: OrderMutation[]): Promise<void> => {
    await client.put(API_BASE_URL + `/section-definitions/order`, request);
};

export const sectionDefinitionApi = {
    getSectionDefinitions,
    searchSectionDefinitions,
    getSectionDefinitionById,
    createSectionDefinition,
    updateIncludeInPayrollSectionDefinition,
    updateSectionDefinition,
    deleteSectionDefinition,
    archiveSectionDefinition,
    unarchiveSectionDefinition,
    archiveSectionFieldDefinition,
    unarchiveSectionFieldDefinition,
    updateSectionDefinitionsOrder,
};

export const mapSectionDefinitionDTO = (dto: SectionDefinitionDTO): SectionDefinition => {
    return {
        ...dto,
        fields: dto.fields.map(mapSectionFieldDefinitionDTO),
    };
};

export const mapSectionFieldDefinitionDTO = (dto: SectionFieldDefinitionDTO): SectionFieldDefinition => {
    return {
        ...dto,
        formId: dto.id?.toString() ?? dto.fieldType,
    };
};
