import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { PlanningSetting } from '@/domain/planning-setting/PlanningSetting.model';
import { getPlanningSettings } from '@/domain/planning-setting/PlanningSetting.service';
import { Shift } from '@/domain/shift/Shift.model';
import { handleError } from '@/utils/api.util';
import { formatInDefaultHours, formatInDefaultWeekName } from '@/utils/datetime.util';
import { Button, DialogActions, DialogContent, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import List from '@mui/material/List';
import { InformationCircleIcon } from 'hugeicons-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    saveAndKeepConflicts: () => void;
    shifts: Shift[];
};

export const LeavesConflictsDialog: FC<Props> = ({ open, onClose, onSave, shifts, saveAndKeepConflicts }) => {
    const { t } = useTranslation();
    const [planningSettings, setPlanningSettings] = useState<PlanningSetting>();

    useEffect(() => {
        getPlanningSettings()
            .then(settings => setPlanningSettings(settings))
            .catch(handleError);
    }, []);

    return (
        <DialogWrapper open={open} onClose={onClose} header={t('leaves_page.what_would_you_like_to_do')}>
            <Stack gap={1} component={DialogContent}>
                <Typography variant='body1bold' style={{ marginBottom: 8 }}>
                    {t('leaves_page.you_have_conflicting_shifts')}
                </Typography>
                <List>
                    {shifts?.map(shift => {
                        return (
                            <ListItem key={shift.id}>
                                <Stack direction='row' gap={1} alignItems='center'>
                                    <InformationCircleIcon />
                                    <ListItemText
                                        primary={shift?.area?.name}
                                        secondary={`${formatInDefaultWeekName(shift?.startDate)} - (${formatInDefaultHours(
                                            shift?.startDate,
                                        )} - ${formatInDefaultHours(shift?.endDate)})`}
                                        disableTypography={false}
                                    />
                                </Stack>
                            </ListItem>
                        );
                    })}
                </List>
            </Stack>
            <DialogActions>
                <Button onClick={saveAndKeepConflicts} color='inherit' fullWidth>
                    {t('leaves_page.keep_shifts')}
                </Button>
                <Button onClick={onSave} fullWidth>
                    {t(planningSettings?.deleteConflictOnEmployeeRelease ? 'leaves_page.delete_conflict' : 'leaves_page.release_employee')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
