import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { ReviewRatingScale, ReviewRatingScaleSearch } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { getReviewRatingScale, searchReviewRatingScales } from '@/domain/review-rating-scale/ReviewRatingScale.service';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleError } from '@/utils/api.util';

export const useGetReviewRatingScales = (reviewRatingScaleSearch: ReviewRatingScaleSearch): UseQueryResult<ReviewRatingScale[]> => {
    const [reviewRatingScales, setReviewRatingScales] = useState<ReviewRatingScale[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchReviewRatingScales = useCallback(async (reviewRatingScaleSearch: ReviewRatingScaleSearch) => {
        try {
            const reviewRatingScales = await searchReviewRatingScales(reviewRatingScaleSearch);
            setReviewRatingScales(reviewRatingScales);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchReviewRatingScales(reviewRatingScaleSearch).catch(handleError);
    }, [fetchReviewRatingScales, fetchReviewRatingScales]);

    return {
        data: reviewRatingScales,
        setData: setReviewRatingScales,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchReviewRatingScales(reviewRatingScaleSearch),
    };
};

export const useGetReviewRatingScale = (ratingId?: number): UseQueryResult<ReviewRatingScale> => {
    const [reviewRatingScale, setReviewRatingScale] = useState<ReviewRatingScale>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchReviewRatingScale = useCallback(async (ratingId?: number) => {
        if (!ratingId) {
            setIsLoading(false);
            return;
        }
        try {
            const reviewRatingScale = await getReviewRatingScale(ratingId);
            setReviewRatingScale(reviewRatingScale);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchReviewRatingScale(ratingId).catch(handleError);
    }, [fetchReviewRatingScale, ratingId]);

    return {
        data: reviewRatingScale,
        setData: setReviewRatingScale,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchReviewRatingScale(ratingId),
    };
};
