import { employeeReviewFeedbackApi } from '@/api/employee-review-feedback/EmployeeReviewFeedback.api';
import {
    EmployeeReviewFeedback,
    EmployeeReviewFeedbackItem,
    EmployeeReviewFeedbackItemSearch,
    EmployeeReviewFeedbackMutation,
    EmployeeReviewFeedbackResult,
} from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { ReviewTemplateItemType } from '@/domain/review-template/ReviewTemplate.model';
import { ReviewItem } from '@/domain/review/Review.model';

import { EmployeeReviewSearch } from '@/domain/employee-review/EmployeeReview.model';
import {
    EmployeeReviewFeedbackSummarySkillFormType,
    EmployeeReviewSummaryFeedbackQuestionFormType,
    EmployeeReviewSummaryFeedbackSkillQuestionFormType,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { getLabelTranslation } from '@/utils/language.util';

export const flatEmployeeReviewFeedbacksItems = (feedbacksItems: EmployeeReviewFeedbackResult[]): EmployeeReviewFeedbackResult['items'] => {
    return feedbacksItems.flatMap(({ items }) => items);
};

export const groupEmployeeReviewFeedbackItemByQuestion = (
    feedbacksItems: EmployeeReviewFeedbackResult['items'],
): {
    question: string;
    items: EmployeeReviewFeedbackResult['items'];
}[] => {
    return feedbacksItems.reduce<
        {
            question: string;
            items: EmployeeReviewFeedbackResult['items'];
        }[]
    >((acc, feedbackItem) => {
        const question = getLabelTranslation(feedbackItem.reviewItem.title);
        const existingQuestion = acc.find(it => it.question === question);
        if (existingQuestion) {
            existingQuestion.items.push(feedbackItem);
        } else {
            acc.push({ question, items: [feedbackItem] });
        }
        return acc;
    }, []);
};

const questionTypes: ReviewTemplateItemType[] = ['QUESTION', 'PRIVATE_EMPLOYEE_QUESTION', 'PRIVATE_MANAGER_QUESTION'];

export const isQuestionFeedbackItem = (feedbackItem: { reviewItem: ReviewItem }): boolean => {
    return questionTypes.includes(feedbackItem.reviewItem.type);
};

export const isRatingQuestionFeedbackItem = (feedbackItem: { reviewItem: ReviewItem }): boolean => {
    return employeeReviewFeedbackService.isQuestionFeedbackItem(feedbackItem) && !!feedbackItem.reviewItem.rating;
};

export const getQuestionsFromFeedbacksItems = (feedbacksItems: { reviewItem: ReviewItem }[]): string[] => {
    const questions = feedbacksItems.filter(isQuestionFeedbackItem).map(feedbackItem => getLabelTranslation(feedbackItem.reviewItem.title));
    return Array.from(new Set(questions)).filter(Boolean);
};

export const getRatingQuestionsFromFeedbacksItems = (feedbacksItems: { reviewItem: ReviewItem }[]): string[] => {
    const questions = feedbacksItems.filter(isRatingQuestionFeedbackItem).map(feedbackItem => getLabelTranslation(feedbackItem.reviewItem.title));
    return Array.from(new Set(questions)).filter(Boolean);
};

export const getEmployeeReviewFeedback = async (employeeReviewId: number): Promise<EmployeeReviewFeedback> => {
    return employeeReviewFeedbackApi.getEmployeeReviewFeedback(employeeReviewId);
};

export const getEmployeeReviewFeedbacksResultsItems = async (search: EmployeeReviewFeedbackItemSearch): Promise<EmployeeReviewFeedbackResult[]> => {
    return employeeReviewFeedbackApi.getEmployeeReviewFeedbacksResultsItems(search);
};

export const submitEmployeeReviewFeedback = async (
    employeeReviewId: number,
    mutation: EmployeeReviewFeedbackMutation[],
): Promise<EmployeeReviewFeedbackItem> => {
    return employeeReviewFeedbackApi.submitEmployeeReviewFeedback(employeeReviewId, mutation);
};

export const updateEmployeeReviewFeedbackItem = async (
    employeeReviewId: number,
    mutation: EmployeeReviewFeedbackMutation,
): Promise<EmployeeReviewFeedbackItem> => {
    return employeeReviewFeedbackApi.updateEmployeeReviewFeedbacksItem(employeeReviewId, mutation);
};

export const searchEmployeeReviewFeedbacks = async (request: EmployeeReviewSearch): Promise<EmployeeReviewFeedback[]> => {
    return employeeReviewFeedbackApi.searchEmployeeReviewFeedbacks(request);
};
export const hasAtLeastOneFeedback = (
    employeeReviewSummaryFeedbackSkillForm:
        | EmployeeReviewSummaryFeedbackQuestionFormType
        | EmployeeReviewSummaryFeedbackSkillQuestionFormType
        | EmployeeReviewFeedbackSummarySkillFormType,
): boolean => {
    const isAtLeastOneSelfFeedback =
        (!!employeeReviewSummaryFeedbackSkillForm?.selfFeedbackQuestion?.score && employeeReviewSummaryFeedbackSkillForm.selfFeedbackQuestion.score > 0) ||
        !!employeeReviewSummaryFeedbackSkillForm?.selfFeedbackQuestion?.comment;

    const isAtLeastOneManagerFeedback = (employeeReviewSummaryFeedbackSkillForm.managerFeedbackQuestion ?? []).some(
        managerFeedbackQuestion => (managerFeedbackQuestion?.score && managerFeedbackQuestion.score > 0) || managerFeedbackQuestion?.comment,
    );

    return (
        isAtLeastOneSelfFeedback ||
        isAtLeastOneManagerFeedback ||
        employeeReviewFeedbackService.isAtLeastOnePeerOrUpwardFeedback(employeeReviewSummaryFeedbackSkillForm)
    );
};

export const isAtLeastOnePeerOrUpwardFeedback = (
    employeeReviewSummaryFeedbackSkillForm:
        | EmployeeReviewSummaryFeedbackQuestionFormType
        | EmployeeReviewSummaryFeedbackSkillQuestionFormType
        | EmployeeReviewFeedbackSummarySkillFormType,
): boolean => {
    const isAtLeastOnePeerFeedback = (employeeReviewSummaryFeedbackSkillForm.peerFeedbackQuestion ?? []).some(
        peerFeedbackQuestion => (peerFeedbackQuestion?.score && peerFeedbackQuestion.score > 0) || peerFeedbackQuestion?.comment,
    );
    const isAtLeastOneUpwardFeedback = (employeeReviewSummaryFeedbackSkillForm.upwardFeedbackQuestion ?? []).some(
        upwardFeedbackQuestion => (upwardFeedbackQuestion?.score && upwardFeedbackQuestion.score > 0) || upwardFeedbackQuestion?.comment,
    );
    return isAtLeastOnePeerFeedback || isAtLeastOneUpwardFeedback;
};

export const employeeReviewFeedbackService = {
    flatEmployeeReviewFeedbacksItems,
    groupEmployeeReviewFeedbackItemByQuestion,
    getQuestionsFromFeedbacksItems,
    getRatingQuestionsFromFeedbacksItems,
    getEmployeeReviewFeedback,
    getEmployeeReviewFeedbacksResultsItems,
    submitEmployeeReviewFeedback,
    updateEmployeeReviewFeedbackItem,
    searchEmployeeReviewFeedbacks,
    hasAtLeastOneFeedback,
    isAtLeastOnePeerOrUpwardFeedback,
    isQuestionFeedbackItem,
    isRatingQuestionFeedbackItem,
};
