import { defaultRealmNotificationAPI } from '@/api/default-realm-notification/DefaultRealmNotification.api';
import { DefaultRealmNotification, DefaultRealmNotificationUpdate } from '@/domain/default-realm-notification/DefaultRealmNotification.model';

export const getDefaultRealmNotification = async (): Promise<DefaultRealmNotification> => {
    return defaultRealmNotificationAPI.getDefaultRealmNotification();
};

export const updateDefaultRealmNotification = async (
    defaultRealmNotificationId: number,
    request: DefaultRealmNotificationUpdate,
): Promise<DefaultRealmNotification> => {
    return defaultRealmNotificationAPI.updateDefaultRealmNotification(defaultRealmNotificationId, request);
};
