import { Components, Theme } from '@mui/material';

export const MuiTabs: Components<Omit<Theme, 'components'>>['MuiTabs'] = {
    styleOverrides: {
        flexContainer: ({ ownerState }) => {
            if (ownerState?.scrollButtons === 'auto') {
                return {
                    // We need a fix width, doesn't matter witch one, to make the right button visible when the tabs are scrollable
                    width: '10px',
                };
            }
        },
        scrollButtons: {
            '&.Mui-disabled': {
                opacity: 0.3,
            },
        },
    },
};
