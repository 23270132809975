import { getObjectiveHistories } from '@/domain/objective-history/ObjectiveHistory.service';
import { UseQueryResult } from '@/page/Query.type';
import { ObjectiveHistory } from '@/domain/objective-history/ObjectiveHistory.model';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleError } from '@/utils/api.util';

export const useGetObjectiveHistories = (objectiveId: number): UseQueryResult<ObjectiveHistory[]> => {
    const [objectiveHistories, setObjectiveHistories] = useState<ObjectiveHistory[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchObjectiveHistories = useCallback(async () => {
        try {
            const data = await getObjectiveHistories(objectiveId);
            setObjectiveHistories(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [objectiveId]);

    useDeepCompareEffect(() => {
        fetchObjectiveHistories().catch(handleError);
    }, [fetchObjectiveHistories]);

    return {
        data: objectiveHistories,
        setData: setObjectiveHistories,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchObjectiveHistories,
    };
};
