export const getAvatarSizeProps = <T extends keyof typeof sizePropsMap>(size: T): (typeof sizePropsMap)[T] => {
    return sizePropsMap[size];
};

const sizePropsMap = {
    small: {
        width: 22,
        height: 22,
        fontSize: 10,
        fontWeight: 'bold',
    },
    medium: {
        width: 30,
        height: 30,
        fontSize: 13,
        fontWeight: 'bold',
    },
    large: {
        width: 38,
        height: 38,
        fontSize: 14,
        fontWeight: 'bold',
    },
    xl: {
        width: 48,
        height: 48,
        fontSize: 16,
        fontWeight: 'bold',
    },
} as const;
