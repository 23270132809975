import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetEmployeeFolders } from '@/hooks/document/Document.hook';
import { EmployeeDocuments } from '@/page/employee-profile/employee-profile-document/EmployeeDocuments';
import { useFolderParam } from '@/page/employee-profile/employee-profile-document/useFolderParam';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { FC } from 'react';

export const EmployeeProfileDocumentsPage: FC = () => {
    const employeeId = useEmployeeProfileId();

    const { data: employeeFolders = [], isLoading, error, isError } = useGetEmployeeFolders(employeeId);

    const { folderId, setFolderId } = useFolderParam(employeeFolders.flatMap(ef => ef.folder));
    return (
        <StateHandler isLoading={isLoading} error={error} isError={isError}>
            <EmployeeDocuments employeeId={employeeId} employeeFolders={employeeFolders} onFolderClick={setFolderId} folderId={folderId} />
        </StateHandler>
    );
};
