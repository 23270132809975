import { Label } from '../label/Label.model';
import { SectionFieldDefinition, SectionFieldValueType } from '@/domain/section-setting/Section.model';
import { Employee, EmployeeAvatar } from '@/domain/employee/Employee.model';
import { EmployeeSectionFieldDocument } from '@/domain/employee-section/EmployeeSection.model';
import { Document } from '@/domain/document/Document.model';
import { EMPLOYEE_FIELD_TYPES } from '@/domain/employee/EmployeeFields.model';

export type Report = {
    id: number;
    title: Label;
    reportType: ReportType;
    createdBy: Employee;
    createdAt: string;
    updateBy: Employee;
    updatedAt: string;
    reportItemId: number;
    fieldDefinitions: ReportFieldDefinition[];
    filters: ReportFilter[];
    viewers: EmployeeAvatar[];
    includeHistory: boolean;
    historyStartDate?: LocalDate;
    historyEndDate?: LocalDate;
    groupByFields: ReportGroupBy[];
    displayMode: 'TABLE' | 'MATRIX';
    sorts: ReportSort[];
};

export type ReportFieldDefinition = {
    id: number | undefined;
    fieldType: ReportFieldType;
    valueType: SectionFieldValueType;
    order: number;
    sectionFieldDefinition: SectionFieldDefinition | undefined;
    size: number;
    hidden: boolean;
};

export type ReportFilter = {
    id: number;
    fieldDefinition: SectionFieldDefinition;
    fieldType: ReportFieldType;
    rule: ReportFilterRule;
    values: {
        id: number;
        stringValue: string;
        numberValue: number;
        dateValue: LocalDate;
        referenceId: number;
    }[];
};

export type ReportGroupBy = {
    fieldType: ReportFieldType;
    sectionFieldDefinitionId: number | undefined;
};

type ReportCommonMutation = {
    title: Label;
    reportType: ReportType;
    fields: ReportFieldDefinitionMutation[];
    filters: ReportFilterMutation[];
    includeHistory: boolean;
    historyRange: [LocalDate, LocalDate] | [];
    groupByFields: ReportGroupBy[];
    displayMode: Report['displayMode'];
    sorts: ReportSortMutation[];
};

export type ReportSort = {
    fieldType: ReportFieldType;
    sectionFieldDefinitionId: number | undefined;
    direction: 'ASC' | 'DESC';
    order: number;
};

export type ReportSortMutation = ReportSort;

type EmployeeSectionReportCreateMutation = ReportCommonMutation & {
    sectionDefinitionId: number;
};
type ReviewReportCreateMutation = ReportCommonMutation & {
    reviewId: number;
};

export type ReportCreateMutation = ReportCommonMutation | EmployeeSectionReportCreateMutation | ReviewReportCreateMutation;

export type ReportUpdateMutation = ReportCommonMutation & {
    id: number;
};

export type ReportUpdateViewerMutation = {
    id: number;
    reportType: ReportType;
    viewers: EmployeeAvatar[];
};

export type ReportFieldDefinitionMutation = Omit<ReportFieldDefinition, 'id' | 'valueType' | 'sectionFieldDefinition'> & {
    sectionFieldDefinitionId: number | undefined;
};

export type ReportFilterMutation = {
    fieldType: ReportFieldType;
    rule: ReportFilterRule;
    // TODO replace by union type
    values: {
        stringValue?: string;
        dateValue?: LocalDate;
        numberValue?: number;
        referenceId?: number;
    }[];
    sectionFieldDefinitionId?: number;
};

type ReportCommonPreviewSearch = {
    reportType: ReportType;
    filters: ReportFilterMutation[];
    fieldDefinitions: ReportFieldDefinitionMutation[];
};

type ItemBasedReportPreview = ReportCommonPreviewSearch & {
    reportType: 'EMPLOYEE_SECTION_REPORT' | 'REVIEW_FEEDBACK_REPORT';
    reportItemId: number;
};

export type ReportPreview = ReportCommonPreviewSearch | ItemBasedReportPreview;

// TODO: Improve this Object to use api dto type to avoid duplication and inconsistency (need api and dto generation from swagger)
export const REPORT_CATEGORIES = {
    REPORT_EMPLOYEE: [
        'EMPLOYEE_PERSONAL_REPORT',
        'EMPLOYMENT_REPORT',
        'EMPLOYEE_ADDRESS_REPORT',
        'EMPLOYEE_DOCUMENT_REPORT',
        'WORKING_PATTERN_REPORT',
        'EMPLOYEE_SECTION_REPORT',
    ],
    REPORT_LEAVE: [
        // TODO update when API is ready
        // 'LEAVE_REQUEST_REPORT', 'LEAVE_BALANCE_REPORT',
        'LEAVE_TYPE_POLICY_REPORT',
        'LEAVE_CORRECTION_REPORT',
    ],
    REPORT_TIMESHEET: ['TIMESHEET_ADJUSTMENT_REPORT', 'TIMESHEET_PAYMENT_REPORT'],
    REPORT_OBJECTIVE: ['OBJECTIVES_REPORT'],
    REPORT_SHIFT: ['SHIFTS'],
    REPORT_REVIEW: ['REVIEW_FEEDBACK_REPORT'],
    REPORT_LONG_LEAVE: ['LONG_LEAVE_REPORT'],
} as const;

export type ReportCategory = keyof typeof REPORT_CATEGORIES;

export type EmployeeReportType = (typeof REPORT_CATEGORIES)['REPORT_EMPLOYEE'][number];

export type LeaveReportType = (typeof REPORT_CATEGORIES)['REPORT_LEAVE'][number];
export type TimesheetReportType = (typeof REPORT_CATEGORIES)['REPORT_TIMESHEET'][number];
export type ObjectiveReportType = (typeof REPORT_CATEGORIES)['REPORT_OBJECTIVE'][number];
export type ShiftReportType = (typeof REPORT_CATEGORIES)['REPORT_SHIFT'][number];
export type ReviewReportType = (typeof REPORT_CATEGORIES)['REPORT_REVIEW'][number];
export type LongLeaveReportType = (typeof REPORT_CATEGORIES)['REPORT_LONG_LEAVE'][number];
export type ReportType =
    | EmployeeReportType
    | LeaveReportType
    | TimesheetReportType
    | ObjectiveReportType
    | ShiftReportType
    | ReviewReportType
    | LongLeaveReportType;

export type Option = {
    label: string;
    value: string;
};

export enum ReportFilterRule {
    GREATER_THAN = 'GREATER_THAN',
    GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS',
    LOWER_THAN = 'LOWER_THAN',
    LOWER_THAN_EQUALS = 'LOWER_THAN_EQUALS',
    BETWEEN = 'BETWEEN',
    EQUALS = 'EQUALS',
    CONTAINS = 'CONTAINS',
    NOT_EQUALS = 'NOT_EQUALS',
    IN = 'IN',
    NOT_IN = 'NOT_IN',
}

export type ReportRow = {
    resourceId: number;
    employeeId: number;
    fields: ReportField[];
};

type ReportFieldReference = {
    type: 'REFERENCE';
    fieldDefinition: ReportFieldDefinition;
    stringValue: string;
    numberValue: number;
    updatedAt?: Date;
};

type ReportFieldString = {
    type: 'STRING' | 'ENUM' | 'PHONE_NUMBER' | 'IBAN_NUMBER' | 'AVS_NUMBER' | 'EMAIL';
    fieldDefinition: ReportFieldDefinition;
    stringValue: string;
    updatedAt?: Date;
};
export type ReportFieldNumber = {
    type: 'NUMBER';
    fieldDefinition: ReportFieldDefinition;
    numberValue: number;
    updatedAt?: Date;
};
type ReportFieldDate = {
    type: 'DATE';
    fieldDefinition: ReportFieldDefinition;
    dateValue: LocalDate | undefined;
    updatedAt?: Date;
};
type ReportFieldCustomList = {
    type: 'CUSTOM_LIST_ITEM' | 'CUSTOM_MULTI_LIST_ITEM';
    fieldDefinition: ReportFieldDefinition;
    customListItemReferences: { id: number; label: Label }[];
    updatedAt?: Date;
};

type ReportFieldLabel = {
    type: 'LABEL';
    fieldDefinition: ReportFieldDefinition;
    labelValue: Label;
    updatedAt?: Date;
};

type ReportFieldSectionFieldDocument = {
    type: 'SECTION_FIELD_DOCUMENT';
    fieldDefinition: ReportFieldDefinition;
    sectionFieldDocumentValues: EmployeeSectionFieldDocument[];
    updatedAt?: Date;
};

type ReportFieldDocument = {
    type: 'DOCUMENT';
    fieldDefinition: ReportFieldDefinition;
    documentValue?: Document;
    updatedAt?: Date;
};

type ReportFieldCountry = {
    type: 'COUNTRY';
    fieldDefinition: ReportFieldDefinition;
    stringValue: string;
    updatedAt?: Date;
};

export type ReportFieldEmployees = {
    type: 'EMPLOYEE';
    fieldDefinition: ReportFieldDefinition;
    employeeValues: EmployeeAvatar[];
    updatedAt?: Date;
};

export type ReportField =
    | ReportFieldString
    | ReportFieldNumber
    | ReportFieldDate
    | ReportFieldCustomList
    | ReportFieldReference
    | ReportFieldLabel
    | ReportFieldSectionFieldDocument
    | ReportFieldDocument
    | ReportFieldEmployees
    | ReportFieldCountry;

export type ReportCommonRow = {
    fields: {
        fieldDefinition: ReportFieldDefinition;
        stringValue: string;
        numberValue: number;
        dateValue: LocalDate | undefined;
        labelValue: Label;
        customListItemReferences: { id: number; label: Label }[];
        documentValue: Document | undefined;
        sectionFieldDocumentValues: EmployeeSectionFieldDocument[];
        employeeValues: EmployeeAvatar[];
        updatedAt?: Date;
    }[];
};

export type ReportEmployeeRow = ReportCommonRow & {
    employeeId: number;
};

export enum ReportGroupFieldCategory {
    EMPLOYEE_BASIC_INFO = 'EMPLOYEE_BASIC_INFO',
    EMPLOYEE_PERSONAL_INFO = 'EMPLOYEE_PERSONAL_INFO',
    CURRENT_ADDRESS = 'CURRENT_ADDRESS',
    CURRENT_EMPLOYMENT = 'CURRENT_EMPLOYMENT',
    CURRENT_CONTRACT = 'CURRENT_CONTRACT',
    CURRENT_WORKING_PATTERN = 'CURRENT_WORKING_PATTERN',
    CUSTOM_SECTION = 'CUSTOM_SECTION',
    ADDRESS = 'ADDRESS',
    DOCUMENT = 'DOCUMENT',
    EMPLOYMENT = 'EMPLOYMENT',
    LEAVE_CORRECTION = 'LEAVE_CORRECTION',
    LONG_LEAVE = 'LONG_LEAVE',
    LEAVE_REQUEST = 'LEAVE_REQUEST',
    LEAVE_TYPE_POLICY = 'LEAVE_TYPE_POLICY',
    OBJECTIVE = 'OBJECTIVE',
    REVIEW_FEEDBACK = 'REVIEW_FEEDBACK',
    TIMESHEET_ADJUSTMENT = 'TIMESHEET_ADJUSTMENT',
    TIMESHEET_PAYMENT = 'TIMESHEET_PAYMENT',
    WORKING_PATTERN = 'WORKING_PATTERN',
}

export type AvailableReportGroupedField = {
    sectionDefinitionName: Label | undefined;
    fields: AvailableReportField[];
    type: ReportGroupFieldCategory;
};

export type AvailableReportField = {
    fieldType: ReportFieldType;
    filterType: SectionFieldValueType;
    valueType: SectionFieldValueType;
    sectionFieldDefinition: SectionFieldDefinition | undefined;
};

export const REPORT_FIELD_TYPES = {
    ...EMPLOYEE_FIELD_TYPES,
    EMPLOYMENT_STATUS: 'EMPLOYMENT_STATUS',
    EMPLOYMENT_CALENDAR: 'EMPLOYMENT_CALENDAR',
    EMPLOYMENT_CONTRACT_TYPE: 'EMPLOYMENT_CONTRACT_TYPE',
    EMPLOYMENT_START_DATE: 'EMPLOYMENT_START_DATE',
    EMPLOYMENT_END_DATE: 'EMPLOYMENT_END_DATE',
    EMPLOYMENT_TERMINATION_NOTICE_DATE: 'EMPLOYMENT_TERMINATION_NOTICE_DATE',
    EMPLOYMENT_LOCATION: 'EMPLOYMENT_LOCATION',
    EMPLOYMENT_DEPARTMENT: 'EMPLOYMENT_DEPARTMENT',
    EMPLOYMENT_JOB: 'EMPLOYMENT_JOB',
    EMPLOYMENT_JOB_FAMILY: 'EMPLOYMENT_JOB_FAMILY',
    EMPLOYMENT_MANAGER: 'EMPLOYMENT_MANAGER',
    EMPLOYMENT_CREATION_REASON: 'EMPLOYMENT_CREATION_REASON',
    EMPLOYMENT_TERMINATION_REASON: 'EMPLOYMENT_TERMINATION_REASON',
    EMPLOYMENT_TERMINATION_TYPE: 'EMPLOYMENT_TERMINATION_TYPE',
    EMPLOYMENT_TERMINATION_COMMENT: 'EMPLOYMENT_TERMINATION_COMMENT',
    EMPLOYMENT_TERMINATION_LAST_DAY: 'EMPLOYMENT_TERMINATION_LAST_DAY',
    EMPLOYMENT_SUBORDINATES: 'EMPLOYMENT_SUBORDINATES',
    EMPLOYMENT_COST_CENTERS: 'EMPLOYMENT_COST_CENTERS',

    // Address
    ADDRESS_START_DATE: 'ADDRESS_START_DATE',
    ADDRESS_END_DATE: 'ADDRESS_END_DATE',
    ADDRESS_COUNTRY: 'ADDRESS_COUNTRY',
    ADDRESS_REGION: 'ADDRESS_REGION',
    ADDRESS_POST_CODE: 'ADDRESS_POST_CODE',
    ADDRESS_CITY: 'ADDRESS_CITY',
    ADDRESS_ADDRESS_LINE_1: 'ADDRESS_ADDRESS_LINE_1',
    ADDRESS_ADDRESS_LINE_2: 'ADDRESS_ADDRESS_LINE_2',
    ADDRESS_UPDATED_BY: 'ADDRESS_UPDATED_BY',
    ADDRESS_STATUS: 'ADDRESS_STATUS',

    // Working pattern
    WORKING_PATTERN_NAME: 'WORKING_PATTERN_NAME',
    WORKING_PATTERN_TYPE: 'WORKING_PATTERN_TYPE',
    WORKING_PATTERN_START_DATE: 'WORKING_PATTERN_START_DATE',
    WORKING_PATTERN_END_DATE: 'WORKING_PATTERN_END_DATE',
    WORKING_PATTERN_WEEKLY_AMOUNT_WORKED: 'WORKING_PATTERN_WEEKLY_AMOUNT_WORKED',
    WORKING_PATTERN_TOTAL_WEEKLY_WORKING_TIME: 'WORKING_PATTERN_TOTAL_WEEKLY_WORKING_TIME',
    WORKING_PATTERN_TOTAL_WORK_RATE: 'WORKING_PATTERN_TOTAL_WORK_RATE',
    WORKING_PATTERN_TIMESHEET_SETTING_NAME: 'WORKING_PATTERN_TIMESHEET_SETTING_NAME',

    // Reviews
    REVIEW_FEEDBACK_COMMENT: 'REVIEW_FEEDBACK_COMMENT',
    REVIEW_FEEDBACK_RATING: 'REVIEW_FEEDBACK_RATING',
    REVIEW_FEEDBACK_SCORE: 'REVIEW_FEEDBACK_SCORE',
    REVIEW_FEEDBACK_CONTRIBUTOR_TYPE: 'REVIEW_FEEDBACK_CONTRIBUTOR_TYPE',
    REVIEW_FEEDBACK_REVIEWER: 'REVIEW_FEEDBACK_REVIEWER',
    REVIEW_FEEDBACK_TYPE: 'REVIEW_FEEDBACK_TYPE',
    REVIEW_FEEDBACK_ITEM_SKILL: 'REVIEW_FEEDBACK_ITEM_SKILL',
    REVIEW_FEEDBACK_ITEM_TYPE: 'REVIEW_FEEDBACK_ITEM_TYPE',
    REVIEW_FEEDBACK_ITEM_QUESTION: 'REVIEW_FEEDBACK_ITEM_QUESTION',

    // Employee Review
    EMPLOYEE_REVIEW_STATUS: 'EMPLOYEE_REVIEW_STATUS',

    // Leave request
    LEAVE_REQUEST_STATUS: 'LEAVE_REQUEST_STATUS',

    // Leave type policies
    LEAVE_TYPE_POLICY_START_DATE: 'LEAVE_TYPE_POLICY_START_DATE',
    LEAVE_TYPE_POLICY_END_DATE: 'LEAVE_TYPE_POLICY_END_DATE',
    LEAVE_TYPE_POLICY_NAME: 'LEAVE_TYPE_POLICY_NAME',
    LEAVE_TYPE_POLICY_LEAVE_TYPE_ALLOWANCE: 'LEAVE_TYPE_POLICY_LEAVE_TYPE_ALLOWANCE',
    LEAVE_TYPE_POLICY_LEAVE_TYPE_ALLOWANCE_TYPE: 'LEAVE_TYPE_POLICY_LEAVE_TYPE_ALLOWANCE_TYPE',
    LEAVE_TYPE_POLICY_LEAVE_TYPE_TITLE: 'LEAVE_TYPE_POLICY_LEAVE_TYPE_TITLE',

    // Leave corrections
    LEAVE_CORRECTION_EFFECTIVE_DATE: 'LEAVE_CORRECTION_EFFECTIVE_DATE',
    LEAVE_CORRECTION_AMOUNT: 'LEAVE_CORRECTION_AMOUNT',
    LEAVE_CORRECTION_COMMENT: 'LEAVE_CORRECTION_COMMENT',
    LEAVE_CORRECTION_LEAVE_TYPE_TITLE: 'LEAVE_CORRECTION_LEAVE_TYPE_TITLE',

    // Timesheet adjustment
    TIMESHEET_ADJUSTMENT_REQUEST_DATE: 'TIMESHEET_ADJUSTMENT_REQUEST_DATE',
    TIMESHEET_ADJUSTMENT_AMOUNT: 'TIMESHEET_ADJUSTMENT_AMOUNT',
    TIMESHEET_ADJUSTMENT_COMMENT: 'TIMESHEET_ADJUSTMENT_COMMENT',

    // Timesheet payment
    TIMESHEET_PAYMENT_REQUEST_DATE: 'TIMESHEET_PAYMENT_REQUEST_DATE',
    TIMESHEET_PAYMENT_PAYMENT_DUE_DATE: 'TIMESHEET_PAYMENT_PAYMENT_DUE_DATE',
    TIMESHEET_PAYMENT_AMOUNT: 'TIMESHEET_PAYMENT_AMOUNT',
    TIMESHEET_PAYMENT_STATUS: 'TIMESHEET_PAYMENT_STATUS',
    TIMESHEET_PAYMENT_COMMENT: 'TIMESHEET_PAYMENT_COMMENT',

    // Objective
    OBJECTIVE_ASSIGNEE: 'OBJECTIVE_ASSIGNEE',
    OBJECTIVE_TITLE: 'OBJECTIVE_TITLE',
    OBJECTIVE_DESCRIPTION: 'OBJECTIVE_DESCRIPTION',
    OBJECTIVE_CATEGORY: 'OBJECTIVE_CATEGORY',
    OBJECTIVE_WEIGHT: 'OBJECTIVE_WEIGHT',
    OBJECTIVE_DUE_DATE: 'OBJECTIVE_DUE_DATE',
    OBJECTIVE_COMPLETION_STATUS: 'OBJECTIVE_COMPLETION_STATUS',
    OBJECTIVE_COMPLETION_COMMENT: 'OBJECTIVE_COMPLETION_COMMENT',
    OBJECTIVE_COMPLETION_DATE: 'OBJECTIVE_COMPLETION_DATE',

    // Employee sections
    EMPLOYEE_SECTION_ROW_UPDATED_BY: 'EMPLOYEE_SECTION_ROW_UPDATED_BY',
    EMPLOYEE_SECTION_ROW_STATUS: 'EMPLOYEE_SECTION_ROW_STATUS',
    // ...LEAVE_FIELD_TYPES,

    // Document
    DOCUMENT_NAME: 'DOCUMENT_NAME',
    DOCUMENT_FOLDER_NAME: 'DOCUMENT_FOLDER_NAME',
    DOCUMENT_EXTERNAL_URL: 'DOCUMENT_EXTERNAL_URL',
    DOCUMENT_CREATED_AT: 'DOCUMENT_CREATED_AT',
    DOCUMENT_CREATED_BY: 'DOCUMENT_CREATED_BY',
};

export type ReportFieldType = keyof typeof REPORT_FIELD_TYPES;
