import { UseMutationResult } from '@/page/Query.type';
import { useCallback, useState } from 'react';
import { ImportRequest, ImportResult, ImportType } from '@/domain/import/Import.model';
import { getImportRequestWithImportType, importEmployeeSection } from '@/domain/import/import.service';
import { EmployeeSectionImportRequest } from '@/domain/employee-section/EmployeeSection.model';

type ImportByTypeMutation = { request: ImportRequest; importType: ImportType };
export const useImportWithType = (): UseMutationResult<ImportResult, ImportByTypeMutation> => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const mutate = useCallback(async (mutation: ImportByTypeMutation) => {
        setIsPending(true);
        try {
            const { importType, request } = mutation;
            return await getImportRequestWithImportType(importType, request);
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setIsPending(false);
        }
    }, []);

    return {
        mutate,
        isPending,
        isError: !!error,
        error,
    };
};

type ImportEmployeeSectionMutation = {
    request: EmployeeSectionImportRequest;
    sectionDefinitionId: number;
};
export const useImportEmployeeSection = (): UseMutationResult<ImportResult, ImportEmployeeSectionMutation> => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const mutate = useCallback(async (mutation: ImportEmployeeSectionMutation) => {
        setIsPending(true);

        try {
            const { request, sectionDefinitionId } = mutation;
            return await importEmployeeSection(request, sectionDefinitionId);
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setIsPending(false);
        }
    }, []);

    return {
        mutate,
        isPending,
        isError: !!error,
        error,
    };
};
