import * as yup from 'yup';
import {
    employeeReviewFeedbackObjectiveSchema,
    employeeReviewFeedbackQuestionSchema,
    employeeReviewFeedbackSectionSchema,
    employeeReviewFeedbackSkillQuestionSchema,
    employeeReviewFeedbackSkillSchema,
    employeeReviewFeedbackSkillsSchema,
} from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { t } from 'i18next';
import { hasAtLeastOneFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';

// Schema for the completed feedback skill question form filled out by the employee, manager, upward, and peer
const employeeReviewFeedbackSchema = yup
    .object()
    .shape({
        scoreLevelName: yup.string().default(undefined).notRequired(),
        score: yup.number().default(undefined).notRequired(),
        comment: yup.string().default(undefined).notRequired(),
        reviewer: yup.object().shape({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            displayName: yup.string().required(),
            avatarImageUrl: yup.string(),
            id: yup.number().required(),
            email: yup.string().required(),
        }),
    })
    .default(undefined)
    .notRequired();

export type EmployeeReviewFeedbackFormType = yup.InferType<typeof employeeReviewFeedbackSchema>;

const employeeReviewFeedbackSummarySkillSchema = yup
    .object()
    .concat(employeeReviewFeedbackSkillSchema)
    .shape({
        selfFeedbackQuestion: employeeReviewFeedbackSchema,
        managerFeedbackQuestion: yup.array().of(employeeReviewFeedbackSchema),
        upwardFeedbackQuestion: yup.array().of(employeeReviewFeedbackSchema),
        peerFeedbackQuestion: yup.array().of(employeeReviewFeedbackSchema),
    })
    .default(undefined);
export type EmployeeReviewFeedbackSummarySkillFormType = yup.InferType<typeof employeeReviewFeedbackSummarySkillSchema>;

const employeeReviewSummaryFeedbackSectionSchema = employeeReviewFeedbackSectionSchema;
export type EmployeeReviewSummaryFeedbackSectionFormType = yup.InferType<typeof employeeReviewSummaryFeedbackSectionSchema>;

const employeeReviewSummaryFeedbackSkillsSchema = yup
    .object()
    .concat(employeeReviewFeedbackSkillsSchema)
    .omit(['skills'])
    .shape({
        skills: yup.array().of(employeeReviewFeedbackSummarySkillSchema).required(),
    })
    .test({
        name: 'manager-skills-validation',
        test: function (value) {
            const { skills } = value;
            return validateManagerSkills(skills);
        },
        message: function ({ value }) {
            const { allSkillsRequired } = value;
            if (allSkillsRequired) {
                return t('reviews.write_feedback.all_skills_required');
            }
            return t('reviews.write_feedback.all_prepared_skills_required');
        },
    });
export type EmployeeReviewSummaryFeedbackSkillsFormType = yup.InferType<typeof employeeReviewSummaryFeedbackSkillsSchema>;

const allSkillsHaveSummaryPositiveScore = (skills: EmployeeReviewFeedbackSummarySkillFormType[]): boolean =>
    skills.every(skill => {
        return skill.score && skill.score > 0;
    });

const areAllReviewedSkillsHaveSummaryPositiveScores = (skills: EmployeeReviewFeedbackSummarySkillFormType[]) => {
    const skillsWithFeedback = skills.filter(skill => {
        return hasAtLeastOneFeedback(skill);
    });
    return allSkillsHaveSummaryPositiveScore(skillsWithFeedback);
};

const validateManagerSkills = (skills: EmployeeReviewFeedbackSummarySkillFormType[]): boolean => {
    return areAllReviewedSkillsHaveSummaryPositiveScores(skills);
};

const employeeReviewSummaryFeedbackObjectiveSchema = employeeReviewFeedbackObjectiveSchema;
export type EmployeeReviewSummaryFeedbackObjectiveFormType = yup.InferType<typeof employeeReviewSummaryFeedbackObjectiveSchema>;

const employeeReviewSummaryFeedbackQuestionSchema = yup
    .object()
    .concat(employeeReviewFeedbackQuestionSchema)
    .shape({
        selfFeedbackQuestion: employeeReviewFeedbackSchema,
        managerFeedbackQuestion: yup.array().of(employeeReviewFeedbackSchema).default(undefined).notRequired(),
        upwardFeedbackQuestion: yup.array().of(employeeReviewFeedbackSchema).default(undefined).notRequired(),
        peerFeedbackQuestion: yup.array().of(employeeReviewFeedbackSchema).default(undefined).notRequired(),
    });
export type EmployeeReviewSummaryFeedbackQuestionFormType = yup.InferType<typeof employeeReviewSummaryFeedbackQuestionSchema>;

const employeeReviewSummaryFeedbackSkillQuestionSchema = yup
    .object()
    .concat(employeeReviewFeedbackSkillQuestionSchema)
    .shape({
        selfFeedbackQuestion: employeeReviewFeedbackSchema,
        managerFeedbackQuestion: yup.array().of(employeeReviewFeedbackSchema),
        upwardFeedbackQuestion: yup.array().of(employeeReviewFeedbackSchema),
        peerFeedbackQuestion: yup.array().of(employeeReviewFeedbackSchema),
    })
    .default(undefined);
export type EmployeeReviewSummaryFeedbackSkillQuestionFormType = yup.InferType<typeof employeeReviewSummaryFeedbackSkillQuestionSchema>;

const employeeReviewSummaryFeedbackItemSchema = yup.lazy(item => {
    const { type } = item;

    switch (type) {
        case 'SECTION':
            return employeeReviewSummaryFeedbackSectionSchema;
        case 'SKILL':
            return employeeReviewSummaryFeedbackSkillQuestionSchema;
        case 'OBJECTIVES':
            return employeeReviewSummaryFeedbackObjectiveSchema;
        case 'SKILLS':
            return employeeReviewSummaryFeedbackSkillsSchema;
        case 'PRIVATE_EMPLOYEE_QUESTION':
        case 'PRIVATE_MANAGER_QUESTION':
        case 'QUESTION':
        default:
            return employeeReviewSummaryFeedbackQuestionSchema;
    }
});
export type EmployeeReviewSummaryFeedbackItemFormType = yup.InferType<typeof employeeReviewSummaryFeedbackItemSchema>;

const employeeReviewSummaryFeedbackItemsSchema = yup.array().of(employeeReviewSummaryFeedbackItemSchema).required();
export type EmployeeReviewSummaryFeedbackItemsFormType = yup.InferType<typeof employeeReviewSummaryFeedbackItemsSchema>;

export const employeeReviewSummaryFeedbackFormSchema = yup.object().shape({
    employeeReviewSummaryFeedbackItems: employeeReviewSummaryFeedbackItemsSchema,
});
export type EmployeeReviewSummaryFeedbackFormType = yup.InferType<typeof employeeReviewSummaryFeedbackFormSchema>;
