import { FilePicker, FilePickerItem, StoredFile } from '@/components/file-picker/FilePicker';
import { getEmployeeSectionFieldDocumentUrl } from '@/domain/employee-section/EmployeeSection.service';
import { FormHelperText, Stack } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

type EditableDocumentFieldProps = {
    documentsValue: StoredFile[];
    formValueName: string;
};

export const EditableDocumentField: FC<EditableDocumentFieldProps> = ({ formValueName, documentsValue }) => {
    const { setValue, getValues, control } = useFormContext<Record<string, Nullable<FilePickerItem[]>>>();

    // Set documentsValue to the form state
    useDeepCompareEffect(() => {
        // If the documentsValue is already in the form state, we don't need to set it again
        if (getValues(formValueName)?.length) {
            return;
        }
        setValue(formValueName, documentsValue ?? []);
    }, [documentsValue, formValueName, setValue]);

    const onFileUploaded = (files: FilePickerItem[], onChange: (documents: FilePickerItem[]) => void) => {
        const documentsFromFormState = getValues(formValueName) ?? [];
        const updatedFieldDocuments = addFileToDocuments(files, documentsFromFormState);

        onChange(updatedFieldDocuments);
    };

    const onFileRenamed = (fileMetadata: FilePickerItem, onChange: (documents: FilePickerItem[]) => void) => {
        const documentsFromFormState = getValues(formValueName) ?? [];
        const updatedFieldDocuments = replaceFileFromDocuments(fileMetadata, documentsFromFormState);

        onChange(updatedFieldDocuments);
    };
    const onFileRemoved = (fileMetadata: FilePickerItem, onChange: (documents: FilePickerItem[]) => void) => {
        const documentsFromFormState = getValues(formValueName) ?? [];
        const updatedFieldDocuments = removedFileFromDocuments(fileMetadata, documentsFromFormState);

        onChange(updatedFieldDocuments);
    };

    return (
        <Stack width={'100%'}>
            <Controller
                name={formValueName}
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                        <FilePicker
                            width={'100%'}
                            onFileRenamed={data => onFileRenamed(data, onChange)}
                            onFileUploaded={data => onFileUploaded(data, onChange)}
                            onFileRemoved={data => onFileRemoved(data, onChange)}
                            files={value ?? []}
                            fetchDocumentUrl={id => getEmployeeSectionFieldDocumentUrl(id, 'ATTACHMENT').then(documentDownloadUrl => documentDownloadUrl)}
                        />
                        {!!error && (
                            <FormHelperText sx={{ marginLeft: 1.75 }} error={!!error}>
                                {error?.message}
                            </FormHelperText>
                        )}
                    </>
                )}
            />
        </Stack>
    );
};

const addFileToDocuments = (files: FilePickerItem[], currentDocuments: FilePickerItem[]): FilePickerItem[] => {
    if (!currentDocuments) {
        return [];
    }
    return [...currentDocuments, ...files];
};

const replaceFileFromDocuments = (fileMetadata: FilePickerItem, currentDocuments: FilePickerItem[]): FilePickerItem[] => {
    if (!currentDocuments) {
        return [];
    }
    return currentDocuments?.map(document => (isSameDocument(document, fileMetadata) ? fileMetadata : document));
};
const isSameDocument = (document1: FilePickerItem, document2: FilePickerItem): boolean => {
    return document1.id === document2.id;
};

/**
 * Remove file from the list of documents
 * File could be an existing document or a newly uploaded document
 * if the file is an existing document, it will have an itemId
 * if the file is a newly uploaded document, it will not have an itemId
 * @param fileMetadata
 * @param currentDocuments
 * @returns
 */
const removedFileFromDocuments = (fileMetadata: FilePickerItem, currentDocuments: FilePickerItem[]): FilePickerItem[] => {
    if (!currentDocuments) {
        return [];
    }
    return currentDocuments?.filter(document => !isSameDocument(document, fileMetadata));
};
