import { PageContainer } from '@/routes/PageContainer';
import { RouteObject } from 'react-router';
import { ObjectivesPage } from './ObjectivesPage';
import { RouterBoundary } from '@/routes/RouterBoundary';

export const objectivesRoutes: RouteObject[] = [
    {
        path: 'objectives',
        element: (
            <PageContainer breadCrumb={[{ nameKey: 'pages.objectives' }]}>
                <ObjectivesPage />
            </PageContainer>
        ),
        errorElement: <RouterBoundary />,
    },
];
