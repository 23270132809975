// Todo move this customHook
import { EmployeeProfileChange, EmployeeProfileChangeSearch } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { countPendingChanges, getEmployeePendingChanges } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployeeProfilePendingChanges = (employeeId: number): UseQueryResult<EmployeeProfileChange[]> => {
    const [employeeProfileChanges, setEmployeeProfileChanges] = useState<EmployeeProfileChange[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeProfilePendingChanges = useCallback(async () => {
        try {
            const data = await getEmployeePendingChanges(employeeId);
            setEmployeeProfileChanges(data);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, [employeeId]);

    useEffect(() => {
        fetchEmployeeProfilePendingChanges().catch(handleError);
    }, [fetchEmployeeProfilePendingChanges]);

    return {
        data: employeeProfileChanges,
        refetch: fetchEmployeeProfilePendingChanges,
        setData: setEmployeeProfileChanges,
        isLoading,
        isError: !!error,
        error,
    };
};

export const useCountEmployeePendingChanges = (request: EmployeeProfileChangeSearch = {}, enabled = true): UseQueryResult<number> => {
    const [count, setCount] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCountEmployeePendingChanges = useCallback(async (request: EmployeeProfileChangeSearch = {}, enabled: boolean) => {
        if (!enabled) {
            setCount(0);
            setIsLoading(false);
            return;
        }
        try {
            const count = await countPendingChanges(request);
            setCount(count);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useDeepCompareEffect(() => {
        fetchCountEmployeePendingChanges(request, enabled).catch(handleError);
    }, [fetchCountEmployeePendingChanges, enabled, request]);

    return {
        data: count,
        setData: setCount,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchCountEmployeePendingChanges(request, enabled),
    };
};
