import { EmployeeReviewFeedbackItemSearch, EmployeeReviewFeedbackResult } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getReview, searchReviews } from '@/domain/review/Review.service';
import { Review, ReviewSearch } from '@/domain/review/Review.model';
import { getEmployeeReviewFeedbacksResultsItems } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { handleError } from '@/utils/api.util';

export const useGetEmployeeReviewFeedbacksItems = (
    search: EmployeeReviewFeedbackItemSearch = {},
    options: { enabled?: boolean } = { enabled: true },
): UseQueryResult<EmployeeReviewFeedbackResult[]> => {
    const [feedbacksItems, setFeedbacksItems] = useState<EmployeeReviewFeedbackResult[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeReviewFeedbacksItems = useCallback(
        async (filterParams: EmployeeReviewFeedbackItemSearch) => {
            if (!options.enabled) {
                return;
            }
            try {
                const data = await getEmployeeReviewFeedbacksResultsItems(filterParams);
                setFeedbacksItems(data);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [options.enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployeeReviewFeedbacksItems(search).catch(handleError);
    }, [fetchEmployeeReviewFeedbacksItems, search]);

    return {
        data: feedbacksItems,
        setData: setFeedbacksItems,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeReviewFeedbacksItems(search),
    };
};

export const useGetReview = (reviewId: number | undefined): UseQueryResult<Review> => {
    const [review, setReview] = useState<Review>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchReview = useCallback(async () => {
        if (!reviewId) {
            return;
        }

        try {
            const data = await getReview(reviewId);
            setReview(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [reviewId]);

    useDeepCompareEffect(() => {
        fetchReview().catch(handleError);
    }, [fetchReview]);

    return {
        data: review,
        setData: setReview,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchReview,
    };
};

export const useGetReviews = (search: ReviewSearch = {}): UseQueryResult<Review[]> => {
    const [reviews, setReviews] = useState<Review[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchReviews = useCallback(async (search: ReviewSearch = {}) => {
        try {
            const data = await searchReviews(search);
            setReviews(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchReviews(search).catch(handleError);
    }, [fetchReviews, search]);

    return {
        data: reviews,
        setData: setReviews,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchReviews(search),
    };
};
