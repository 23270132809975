import { Button, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { canSeeSurveyResults } from '@/domain/permission/Permission.service';
import { Survey, SurveyStatus } from '@/domain/survey/Survey.model';
import { SurveyResultsButton } from '@/page/survey/survey/SurveyResultsButton';
import { SurveyStatusChip } from '@/page/survey/survey/SurveyStatusChip';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { Add01Icon } from 'hugeicons-react';
import { useNavigate } from 'react-router';

import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { deleteSurvey, surveySendReminder } from '@/domain/survey/Survey.service';
import { useGetSurveys } from '@/hooks/survey/Survey.hook';
import { EditSurveySetupFormDialog } from '@/page/survey/edit-survey-setup-dialog/EditSurveySetupFormDialog';
import { Participation } from '@/page/survey/participation/Participation';

export const SurveysPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [surveyIdToEdit, setSurveyIdToEdit] = useState<number | undefined>(undefined);

    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();

    const agGridWrapper = useAgGridWrapper<Survey>();

    const { data: surveys = [], refetch: refetchSurveys } = useGetSurveys();

    if (!canSeeSurveyResults(realm.realmFeatures, policies)) {
        return <></>;
    }

    const handleDeleteClicked = async (surveyId: number) => {
        try {
            await deleteSurvey(surveyId);
            showSnackbar(t('survey_templates_page.survey_deleted'), 'success');
        } catch (error) {
            handleError(error);
        } finally {
            await refetchSurveys();
        }
    };

    const isDeleteDisabled = (survey: Survey) => {
        return survey.participationCount > 0;
    };

    const isSendReminderDisabled = (survey: Survey) => {
        return survey.status === SurveyStatus.CLOSED;
    };

    const menuRenderer = (data: ICellRendererParams<Survey>) => {
        if (!data?.data?.id) {
            return;
        }

        const surveyId = data.data.id;

        const getActionMenuItems = (survey: Survey) => {
            return [
                {
                    title: t('survey_templates_page.cycles.edit'),
                    onClick: () => setSurveyIdToEdit(surveyId),
                },
                {
                    title: t('general.delete'),
                    onClick: () => handleDeleteClicked(surveyId),
                    confirmationRequired: true,
                    hide: isDeleteDisabled(survey),
                },
                {
                    title: t('survey_templates_page.cycles.send_reminder_now'),
                    onClick: async () => {
                        try {
                            await surveySendReminder(surveyId);
                            showSnackbar(t('survey_templates_page.cycles.reminder_sent_successfully'), 'success');
                        } catch (error) {
                            handleError(error);
                            showSnackbar(t('survey_templates_page.cycles.reminder_sent_error'), 'error');
                        } finally {
                            await refetchSurveys();
                        }
                    },
                    hide: isSendReminderDisabled(survey),
                },
            ];
        };
        const actionMenuItems = getActionMenuItems(data.data);

        if (actionMenuItems.every(item => item.hide)) {
            return <></>;
        }

        return <BasicMenu items={getActionMenuItems(data.data)} />;
    };

    const columnDefs: RogerColDef<Survey>[] = [
        {
            field: 'name',
            headerName: t('survey_templates_page.cycles.cycle_table.survey_name'),
            type: 'label',
        },
        {
            field: 'startDate',
            headerName: t('survey_templates_page.cycles.cycle_table.start_date'),
            type: 'date',
        },
        {
            field: 'endDate',
            headerName: t('survey_templates_page.cycles.cycle_table.end_date'),
            type: 'date',
        },
        {
            field: 'lastReminderSentAt',
            headerName: t('survey_templates_page.cycles.cycle_table.sent_on'),
            type: 'date',
        },
        {
            headerName: t('survey_templates_page.cycles.cycle_table.status'),
            cellRenderer: StatusCell,
        },
        {
            headerName: t('survey_templates_page.cycles.cycle_table.participation'),
            cellRenderer: ParticipationCell,
            cellClass: ['display-flex'],
            sortable: false,
        },
        {
            cellRenderer: ResultCell,
            sortable: false,
        },
        {
            type: 'actionMenu',
            cellRenderer: menuRenderer,
        },
    ];
    const goToResultPage = (survey: Survey) => {
        navigate(`/manage-surveys/surveys/${survey.id}/results`);
    };
    return (
        <Stack gap={2} flex={1}>
            <Stack direction='row-reverse' alignItems='center' justifyContent='space-between'>
                <Button
                    startIcon={<Add01Icon />}
                    onClick={() => {
                        navigate('/manage-surveys/surveys/new');
                    }}
                >
                    {t('survey_templates_page.new_survey')}
                </Button>
            </Stack>
            <Stack flex={1}>
                <AgGridWrapper<Survey>
                    rowData={surveys ?? []}
                    initRef={agGridWrapper.setGridRef}
                    columnDefs={columnDefs}
                    onRowClicked={({ data, event }) => {
                        if (!event?.defaultPrevented && data?.participationCount && data?.participationCount > 0) {
                            goToResultPage(data);
                        }
                    }}
                    loading={false}
                />
            </Stack>

            {!!surveyIdToEdit && (
                <EditSurveySetupFormDialog
                    surveyId={surveyIdToEdit}
                    onClose={() => setSurveyIdToEdit(undefined)}
                    onSave={async () => {
                        setSurveyIdToEdit(undefined);
                        await refetchSurveys();
                    }}
                />
            )}
        </Stack>
    );
};

export const ResultCell = ({ data }: ICellRendererParams<Survey>): ColDef['cellRenderer'] => (data ? <SurveyResultsButton survey={data} /> : undefined);
export const ParticipationCell = ({ data }: ICellRendererParams<Survey>): ColDef['cellRenderer'] => (data ? <Participation survey={data} /> : undefined);
export const StatusCell = ({ data }: ICellRendererParams<Survey>): ColDef['cellRenderer'] => (data ? <SurveyStatusChip survey={data} /> : undefined);
