import { FC, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { Auth } from 'aws-amplify';
import { getCurrentEmployee } from '@/domain/employee/Employee.service';
import { setUserLanguage } from '@/utils/language.util';
import { handleError } from '@/utils/api.util';
import { employeeAuthenticated } from '@/stores/reducers/currentEmployeeSlice';
import { FormHelperText, Link, Paper, Stack, Typography } from '@mui/material';
import { LogoRoger } from '@/components/logo-roger/LogoRoger';
import { AlertCircleIcon } from 'hugeicons-react';
import { PasswordForm } from './PasswordForm';

type Props = {
    username: string | null;
    code: string | null;
    title: string;
    actionLabel: string;
};
export const ChangePasswordForm: FC<Props> = ({ username, code, title, actionLabel }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const realm = useAppSelector(state => state.ui.currentRealm);

    const [validLink, setValidLink] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            if (username && code) {
                try {
                    const response = await Auth.signIn(username, code);
                    if (response.challengeName !== 'NEW_PASSWORD_REQUIRED') {
                        // Sign-in successful but no need to change password
                        navigate('/login');
                    }
                } catch (error) {
                    // Sign-in error, link is probably incorrect
                    console.error(error);
                    setValidLink(false);
                }
            }
        }

        fetchData();
    }, [username, code, navigate]);

    const completeNewPassword = (user: unknown, newPassword: string) => {
        Auth.completeNewPassword(user, newPassword)
            .then(() => {
                // at this time the user is logged in! \o/
                getCurrentEmployee()
                    .then(data => {
                        if (data?.employee?.language) {
                            setUserLanguage(data?.employee.language).catch(handleError);
                        }
                        dispatch(employeeAuthenticated(data));
                        navigate('/profile/personal-info');
                    })
                    .catch(error => {
                        console.error(error);
                        setErrorMessage(error.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch(error => {
                console.error(error);
                setErrorMessage(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = (username: string, code: string, newPassword: string) => {
        if (!username || !code) {
            return;
        }
        setLoading(true);

        Auth.signIn(username, code)
            .then(response => {
                if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    completeNewPassword(response, newPassword);
                } else {
                    throw new Error('Unsupported cognito response ' + response.challengeName + ' on account activation');
                }
            })
            .catch(error => {
                console.error(error);
                setErrorMessage(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Stack height='100vh'>
            <Stack component={Paper} gap={3} alignItems='stretch' margin='auto' p={2} width='440px'>
                <LogoRoger variant='light' />

                {!realm && <ErrorMessage message={t('login_form.realm_not_found')} />}

                {(!username || !code || !validLink) && (
                    <Stack gap={2}>
                        <ErrorMessage message={t('change_password_form.invalid_link')} />
                        <Link to='/' component={RouterLink} color='primary'>
                            {t('change_password_form.back_to_login')}
                        </Link>
                    </Stack>
                )}

                {realm && username && code && validLink && (
                    <Stack>
                        <PasswordForm
                            title={title}
                            buttonLabel={actionLabel}
                            loading={loading}
                            onSubmitPassword={password => handleSubmit(username, code, password)}
                        />
                        {errorMessage && <ErrorMessage message={errorMessage} />}
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

type ErrorMessageProps = {
    message: string;
};
const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
    return (
        <FormHelperText component='div' error={true}>
            <Stack direction='row' spacing={1} alignItems='center'>
                <AlertCircleIcon size={16} />
                <Typography variant='body1' color='error'>
                    {message}
                </Typography>
            </Stack>
        </FormHelperText>
    );
};
