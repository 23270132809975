import { FC } from 'react';
import { ButtonBase } from '@mui/material';

const DEFAULT_COLORS = [
    '#FF6F69',
    '#37CC24',
    '#FFB400',
    '#2196F3',
    '#360EFF',
    '#01CDCD',
    '#F3AE4F',
    '#B1976A',
    '#E98E5E',
    '#E6736F',
    '#9360A3',
    '#8167DA',
    '#625BC9',
    '#685fff',
    '#7CA6E8',
    '#3F88E5',
    '#58C1A0',
    '#59954F',
    '#466C82',
    '#6595B1',
    '#8E746A',
];

type Props = {
    color: string | undefined;
    onChangeColor: (color: string) => void;
};

export const ColorList: FC<Props> = ({ onChangeColor, color }) => {
    return DEFAULT_COLORS.map(col => (
        <ButtonBase
            key={col}
            onClick={() => onChangeColor(col)}
            sx={{
                width: 24,
                height: 24,
                borderRadius: '50%',
                marginRight: 0.5,
                marginBottom: 0.5,
                background: col,
                boxShadow: color === col ? 'inset 0px 0px 0px 3px white' : 'none',
                border: `1px solid ${color === col ? color : 'transparent'}`,
            }}
            aria-label={col}
        >
            <span />
        </ButtonBase>
    ));
};
