import { API_BASE_URL, client } from '@/api/common';
import { DocumentContentDisposition } from '@/domain/document/Document.model';
import { LeaveRequestAttachment, LeaveRequestAttachmentCreationRequest } from '@/domain/leave-request-attachment/LeaveRequestAttachment.model';
import { AxiosResponse } from 'axios';

const createLeaveRequestAttachment = async (request: LeaveRequestAttachmentCreationRequest): Promise<LeaveRequestAttachment> => {
    const url = API_BASE_URL + `/leave-request-attachments`;

    const formData = new FormData();
    formData.append('file', request.file.data, request.file.name);
    formData.append('name', request.name);
    formData.append('mimeType', request.mimeType);
    formData.append('leaveRequestId', request.leaveRequestId.toString());
    return (await client.postForm<LeaveRequestAttachmentCreationRequestDTO, AxiosResponse<LeaveRequestAttachment>, FormData>(url, formData)).data;
};

const getLeaveRequestAttachments = async (leaveRequestId: number): Promise<LeaveRequestAttachment[]> => {
    const url = API_BASE_URL + `/leave-request-attachments/leave-request/${leaveRequestId}`;
    return (await client.get<LeaveRequestAttachmentDTO[]>(url)).data;
};

const deleteLeaveRequestAttachment = async (leaveRequestAttachmentId: number): Promise<void> => {
    const url = API_BASE_URL + `/leave-request-attachments/${leaveRequestAttachmentId}`;
    await client.delete(url);
};

const getLeaveRequestAttachmentUrl = async (leaveRequestAttachmentId: number, documentContentDisposition: DocumentContentDisposition): Promise<string> => {
    return (await client.get<string>(API_BASE_URL + `/leave-request-attachments/${leaveRequestAttachmentId}/url/${documentContentDisposition}`)).data;
};

export const leaveRequestAttachmentApi = {
    createLeaveRequestAttachment,
    getLeaveRequestAttachments,
    deleteLeaveRequestAttachment,
    getLeaveRequestAttachmentUrl,
};

type LeaveRequestAttachmentDTO = LeaveRequestAttachment;
type LeaveRequestAttachmentCreationRequestDTO = LeaveRequestAttachmentCreationRequest;
