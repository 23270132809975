import { Button } from '@mui/material';
import { FC, MouseEvent } from 'react';

type Props = {
    handleClick: (event: MouseEvent<HTMLElement>) => void;
    buttonName: string;
};
export const SSOLoginForm: FC<Props> = ({ handleClick, buttonName }) => {
    return (
        <Button fullWidth onClick={handleClick}>
            {buttonName}
        </Button>
    );
};
