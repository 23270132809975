import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { getTimesheetSettingById, searchTimesheetSettings } from '@/domain/timesheet-setting/TimesheetSetting.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetTimesheetSettingById = (timesheetSettingId: number | undefined): UseQueryResult<TimesheetSetting> => {
    const [timesheetSetting, setTimesheetSetting] = useState<TimesheetSetting>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchTimesheetSettingById = useCallback(async () => {
        if (timesheetSettingId) {
            try {
                const timesheetSetting = await getTimesheetSettingById(timesheetSettingId);
                setTimesheetSetting(timesheetSetting);
            } catch (error) {
                setError(error);
            }
        }
        setIsLoading(false);
    }, [timesheetSettingId]);

    useEffect(() => {
        fetchTimesheetSettingById().catch(handleError);
    }, [fetchTimesheetSettingById]);

    return {
        data: timesheetSetting,
        setData: setTimesheetSetting,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchTimesheetSettingById,
    };
};

export const useSearchTimesheetSettings = (): UseQueryResult<TimesheetSetting[]> => {
    const [timesheetSettings, setTimesheetSettings] = useState<TimesheetSetting[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchSearchTimesheetSettings = useCallback(async () => {
        try {
            const timesheetSettings = await searchTimesheetSettings();
            setTimesheetSettings(timesheetSettings);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchSearchTimesheetSettings().catch(handleError);
    }, [fetchSearchTimesheetSettings]);

    return {
        data: timesheetSettings,
        setData: setTimesheetSettings,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchSearchTimesheetSettings(),
    };
};
