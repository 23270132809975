import { SkillCategory, SkillCategorySearch } from '@/domain/skill-category/SkillCategory.model';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { searchSkillCategories } from '@/domain/skill-category/SkillCategory.service';

export const useGetReviewsCategories = (request: SkillCategorySearch = {}): UseQueryResult<SkillCategory[]> => {
    const [categories, setCategories] = useState<SkillCategory[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCategories = useCallback(async (request: SkillCategorySearch) => {
        try {
            const categories = await searchSkillCategories(request);
            setCategories(categories);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchCategories(request).catch(handleError);
    }, [request]);

    return {
        data: categories,
        setData: setCategories,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchCategories(request),
    };
};
