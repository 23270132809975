import { ComponentProps, CSSProperties, forwardRef, KeyboardEvent, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { NumericFormat, numericFormatter } from 'react-number-format';

import { DEFAULT_I18N_LANGUAGE } from '@/i18n/i18n';
import { getNull } from '@/utils/object.util';
import { TextField, TextFieldProps } from '@mui/material';
import { KeyboardKey } from '@/types/keyboard';

type InputNumberCustomProps = {
    value: number | null;
    defaultValue?: number | null;
    onChange(value: number | null): void;
    placeholder?: string | number;
    locale?: string;
    currency?: string | null;
    prefix?: string;
    suffix?: string;
    precision?: number;
    fixedPrecision?: boolean;
    step?: number;
    bigStep?: number;
    min?: number;
    max?: number;
    clampValueOnBlur?: boolean;
    type?: 'text';
    textAlign?: CSSProperties['textAlign'];
};

export type InputNumberProps = Overwrite<TextFieldProps, InputNumberCustomProps>;

/**
 * A number input field that formats the number according to the locale
 *
 * This component is a wrapper around the react-number-format component
 *
 */
export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(
    (
        {
            value,
            defaultValue,
            locale,
            currency = getNull(),
            prefix = '',
            suffix = '',
            precision = 0,
            step = 1,
            bigStep = step * 10,
            min = Number.MIN_SAFE_INTEGER,
            max = Number.MAX_SAFE_INTEGER,
            clampValueOnBlur = true,
            fixedPrecision = false,
            onChange = () => undefined,
            placeholder,
            textAlign = 'left',
            InputProps,
            ...rest
        },
        ref,
    ) => {
        const { i18n } = useTranslation();
        const { decimalsSeparator, groupSeparator, currencyPrefix, currencySuffix } = getNumberFormatInfo({
            locale: locale ?? i18n.language ?? DEFAULT_I18N_LANGUAGE,
            currency: currency ?? 'EUR',
        });
        const [isFocused, setIsFocused] = useState(false);
        const tmpValueRef = useRef(value ?? defaultValue ?? getNull());

        const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
            const v = tmpValueRef.current;

            if (e.key === KeyboardKey.ENTER) {
                onChange(v);
            }
            if (e.key === KeyboardKey.ARROW_UP) {
                onChange(clamp((v ?? 0) + (e.shiftKey ? bigStep : step), min, max));
            }
            if (e.key === KeyboardKey.ARROW_DOWN) {
                onChange(clamp((v ?? 0) - (e.shiftKey ? bigStep : step), min, max));
            }
            rest.onKeyDown?.(e);
        };

        const getNumericFormatOptions = {
            getInputRef: ref,
            decimalScale: precision,
            fixedDecimalScale: !isFocused ? fixedPrecision : false,
            decimalSeparator: decimalsSeparator ?? '.',
            thousandSeparator: groupSeparator ?? ',',
            suffix: `${currency ? currencySuffix : ''}${suffix}`,
            prefix: `${currency ? currencyPrefix : ''}${prefix}`,
            onValueChange: values => {
                tmpValueRef.current = values.floatValue ?? getNull();

                // Prevent -0 to be replaced with 0 when input is controlled
                if (values.floatValue === 0) {
                    // TODO the controller is not notified, do we have to call onChange with 0 ?
                    // https://rogerhr.atlassian.net/browse/RP-5509
                    return;
                }

                onChange(values.floatValue ?? getNull());
            },
        } satisfies ComponentProps<typeof NumericFormat>;

        return (
            <NumericFormat
                {...rest}
                {...getNumericFormatOptions}
                InputProps={{
                    ...InputProps,
                    inputProps: {
                        ...InputProps?.inputProps,
                        style: {
                            fontVariantNumeric: 'tabular-nums',
                            textAlign,
                        },
                    },
                }}
                customInput={TextField}
                value={value === undefined ? undefined : (value ?? '')}
                defaultValue={defaultValue ?? undefined}
                placeholder={
                    typeof placeholder === 'number'
                        ? numericFormatter(String(placeholder), {
                              ...getNumericFormatOptions,
                              fixedDecimalScale: fixedPrecision,
                          })
                        : placeholder
                }
                onFocus={e => {
                    setIsFocused(true);
                    rest.onFocus?.(e);
                }}
                onBlur={e => {
                    setIsFocused(false);
                    const v = tmpValueRef.current;
                    const clampedValue = v === getNull() ? getNull() : clamp(v, min, max);
                    // TODO when the user save the form just after typing a number out of bounds
                    // the value is changed and saved to the clamped value but the user can't see it
                    // https://rogerhr.atlassian.net/browse/RP-5509
                    onChange(clampValueOnBlur ? clampedValue : tmpValueRef.current);
                    rest.onBlur?.(e);
                }}
                onKeyDown={handleOnKeyDown}
            />
        );
    },
);

/**
 * Get the number format information for a given locale and currency.
 * @param params The parameters
 * @returns The number format information
 */
const getNumberFormatInfo = (params: { locale: string; currency?: string | null }) => {
    const intl = new Intl.NumberFormat(
        params.locale,
        params.currency
            ? {
                  style: 'currency',
                  currency: params.currency,
              }
            : undefined,
    );

    const decimalsSeparator = intl.formatToParts(1).find(part => part.type === 'decimal')?.value;

    const groupSeparator = intl.formatToParts(1000.1).find(part => part.type === 'group')?.value;

    const currencySymbol = intl.formatToParts(1).find(part => part.type === 'currency')?.value;

    const currencyPosition = intl.formatToParts(1).findIndex(part => part.type === 'currency') === 0 ? 'start' : 'end';

    const currencyPrefix = currencyPosition === 'start' && currencySymbol ? currencySymbol : '';
    const currencySuffix = currencyPosition === 'end' && currencySymbol ? ` ${currencySymbol}` : '';

    return {
        decimalsSeparator,
        groupSeparator,
        currencySymbol,
        currencyPosition,
        currencyPrefix,
        currencySuffix,
    } as const;
};

/**
 * Clamp a number between two bounds.
 * @param number The number to clamp
 * @param boundOne The first bound
 * @param boundTwo The second bound
 * @returns The clamped
 * @example
 * clamp(5, 0, 10) // 5
 * clamp(5, 10, 0) // 5
 *
 */
const clamp = (number: number, boundOne: number, boundTwo: number) => {
    if (!boundTwo) {
        return Math.max(number, boundOne) === boundOne ? number : boundOne;
    } else if (Math.min(number, boundOne) === number) {
        return boundOne;
    } else if (Math.max(number, boundTwo) === number) {
        return boundTwo;
    }
    return number;
};
