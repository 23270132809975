import { Spinner } from '@/components/spinner/Spinner';
import { PlanningLazyPage } from '@/page/planning/PlanningLazyPages';
import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { Suspense } from 'react';
import { RouteObject } from 'react-router';

export const planningRoutes: RouteObject[] = [
    {
        path: 'planning',
        element: (
            <PageContainer
                breadCrumb={[
                    {
                        nameKey: 'pages.planning',
                    },
                ]}
            >
                <Suspense fallback={<Spinner />}>
                    <PlanningLazyPage />
                </Suspense>
            </PageContainer>
        ),
        errorElement: <RouterBoundary />,
    },
];
