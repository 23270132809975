import { FC } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const SurveyTokenError: FC<unknown> = () => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Paper
                sx={{
                    padding: 6,
                    textAlign: 'center',
                    maxWidth: 450,
                    margin: 'auto',
                }}
            >
                <Typography variant='body1'>{t('anonymous_surveys.this_page_does_not_exist')}</Typography>
            </Paper>
        </Box>
    );
};
