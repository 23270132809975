import { StateHandler } from '@/components/state-handler/StateHandler';
import { flatEmployeeReviewFeedbacksItems } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { useGetEmployeeReviewFeedbacksItems } from '@/hooks/review/Review.hook';
import { ReviewReportMatrixView } from '@/page/review/review-report/ReviewReportMatrixView';
import { useEmployeeFeedbacksResultFilters } from '@/hooks/review/EmployeeFeedbackResultFilters.hook';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router';
import { getReviewReportView } from '@/domain/report/Report.service';
import { getSelectFilterNumberValues } from '@/components/filters-bar/FiltersBar.util';
import { ReviewReportQuestionView } from '@/page/review/review-report/ReviewReportQuestionView';

const useReviewReportView = (): ReturnType<typeof getReviewReportView> => {
    const [searchParams] = useSearchParams();
    return getReviewReportView(searchParams.get('view'));
};

export const ReviewCycleReportPage: FC = () => {
    const { reviewId: reviewIdString } = useParams();
    const reviewId = isNaN(Number(reviewIdString)) ? undefined : Number(reviewIdString);

    const [filters, setFilters] = useEmployeeFeedbacksResultFilters();

    const view = useReviewReportView();

    const reviewIds = reviewId ? [reviewId] : [];
    // Filters definitions positions are important
    const locationIds = getSelectFilterNumberValues(filters?.[0]);
    const departmentIds = getSelectFilterNumberValues(filters?.[1]);
    const managerIds = getSelectFilterNumberValues(filters?.[2]);
    const roleIds = getSelectFilterNumberValues(filters?.[3]);

    const {
        data: feedbacksItemsGroupByEmployee = [],
        isLoading: isLoadingFeedbacksItems,
        error: feedbacksItemsError,
        isError,
    } = useGetEmployeeReviewFeedbacksItems(
        {
            reviewIds,
            locationIds,
            departmentIds,
            managerIds,
            roleIds,
        },
        { enabled: !!filters.length },
    );

    return (
        <Stack
            flex={1}
            gap={2}
            // This is to disable the scroll from the main layout
            overflow='hidden'
        >
            <StateHandler isLoading={isLoadingFeedbacksItems} isError={isError} error={feedbacksItemsError}>
                {view === 'MATRIX' && (
                    <ReviewReportMatrixView feedbacksItemsGroupByEmployee={feedbacksItemsGroupByEmployee} filters={filters} onFiltersChange={setFilters} />
                )}
                {view === 'QUESTION' && (
                    <ReviewReportQuestionView
                        feedbacksItems={flatEmployeeReviewFeedbacksItems(feedbacksItemsGroupByEmployee)}
                        filters={filters}
                        onFiltersChange={setFilters}
                    />
                )}
            </StateHandler>
        </Stack>
    );
};
