import { SectionField } from '@/components/section/types';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { getLabelTranslation } from '@/utils/language.util';
import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { EmployeeBasicInfoFieldType } from '@/domain/employee/EmployeeFields.model';
import { Employee } from '@/domain/employee/Employee.model';
import { useAppSelector } from '@/stores/store';

export type EmployeeBasicInfo = Partial<Pick<Employee, 'firstName' | 'lastName' | 'maidenName' | 'displayName' | 'email' | 'phoneNumber' | 'employeeCode'>>;
export const useEmployeeBasicInfoSectionFields = (
    sectionDefinition: SectionDefinition,
    employee: EmployeeBasicInfo,
    { editionMode = true, emailEditable = false }: { editionMode: boolean; emailEditable: boolean },
): SectionField[] => {
    const realm = useAppSelector(state => state.ui.currentRealm);

    // Check if the display name is changed to hide it if it is generated by the BE when we are in edit mode
    const beGeneratedDisplayName = realm?.reverseDisplayName ? `${employee.lastName} ${employee.firstName}` : `${employee.firstName} ${employee.lastName}`;
    const isDisplayNameChanged = () => {
        return !editionMode || employee.displayName !== beGeneratedDisplayName;
    };

    const employeeBasicInfoFieldValueMapping: Record<EmployeeBasicInfoFieldType, string | undefined> = {
        EMPLOYEE_FIRSTNAME: employee.firstName,
        EMPLOYEE_LASTNAME: employee.lastName,
        EMPLOYEE_MAIDEN_NAME: employee.maidenName,
        EMPLOYEE_DISPLAY_NAME: isDisplayNameChanged() ? employee.displayName : '',
        EMPLOYEE_EMAIL: employee.email,
        EMPLOYEE_PHONE_NUMBER: employee.phoneNumber,
        EMPLOYEE_CODE: employee.employeeCode,
    };
    return sectionDefinition.fields.map(fieldDefinition => {
        const employeeBasicInfoFieldType = fieldDefinition.fieldType as EmployeeBasicInfoFieldType;
        return {
            fieldDefinitionId: fieldDefinition.id,
            formValueName: fieldDefinition.formId,
            title: getLabelTranslation(fieldDefinition.name),
            valueType: fieldDefinition.valueType,
            [getFieldValueProperty(fieldDefinition.valueType)]: employeeBasicInfoFieldValueMapping[employeeBasicInfoFieldType],
            required: fieldDefinition.mandatory,
            fieldType: employeeBasicInfoFieldType,
            order: fieldDefinition.order,
            disabled: fieldDefinition.fieldType === 'EMPLOYEE_EMAIL' && !emailEditable,
        };
    });
};
