import { Components, Theme } from '@mui/material';

export const MuiAvatar: Components<Omit<Theme, 'components'>>['MuiAvatar'] = {
    styleOverrides: {
        colorDefault: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
        }),
        // In roger we want to have a border for single avatars and not only for AvatarGroup
        root: ({ theme, ownerState }) => {
            // Hack to get avatar size
            const sx = ownerState.sx as Record<string, unknown> | undefined;
            const height = typeof sx?.height === 'number' ? sx?.height : 0;
            return {
                borderStyle: 'solid',
                // Border is bigger for bigger avatars
                // We are forced to use !important because of the specificity of the MuiAvatarGroup style
                // I didn't find a better way to override it
                borderWidth: `${height >= 48 ? 3 : 1}px !important`,
                borderColor: theme.palette.grey[100],
            };
        },
    },
};

export const MuiAvatarGroup: Components<Omit<Theme, 'components'>>['MuiAvatarGroup'] = {
    styleOverrides: {
        avatar: ({ theme }) => ({
            borderStyle: 'solid',
            borderColor: theme.palette.grey[100],
        }),
    },
};
