import { EmployeeShift, LeaveShiftSearchRequest } from '@/domain/shift/Shift.model';
import { getLeaveShifts } from '@/domain/shift/Shift.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetLeaveShifts = (search: LeaveShiftSearchRequest, enabled = true): UseQueryResult<EmployeeShift[]> => {
    const [employeeShifts, setEmployeeShifts] = useState<EmployeeShift[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeShifts = useCallback(
        async (search: LeaveShiftSearchRequest) => {
            if (!enabled) {
                setIsLoading(false);
                return;
            }
            try {
                const employeeShifts = await getLeaveShifts(search);
                const withLeaves = employeeShifts.filter(s => s.shifts.length > 0);
                setEmployeeShifts(withLeaves);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployeeShifts(search).catch(handleError);
    }, [fetchEmployeeShifts, search]);

    return {
        data: employeeShifts,
        setData: setEmployeeShifts,
        refetch: () => fetchEmployeeShifts(search),
        isLoading,
        isError: !!error,
        error,
    };
};
