import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { BasicMenu } from '@/components/basic-menu/BasicMenu';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { ReviewEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeReview, UpdateEmployeeReviewMutation } from '@/domain/employee-review/EmployeeReview.model';
import {
    cancelEmployeeReview,
    closeEmployeeReview,
    isCurrentEmployeeManager,
    isOngoingReview,
    updateEmployeeReview,
} from '@/domain/employee-review/EmployeeReview.service';
import { Employee } from '@/domain/employee/Employee.model';
import { Review, ReviewOffboardingCreationMutation, ReviewOnboardingCreationMutation, ReviewPatchMutation, ReviewType } from '@/domain/review/Review.model';
import { createOffboardingReview, createOnboardingReview, patchReview } from '@/domain/review/Review.service';
import { useGetReviews } from '@/hooks/review/Review.hook';
import { OneShotReviewDialog } from '@/page/employee-profile/employee-profile-review/OneShotReviewDialog';
import { SendEmployeeReviewReminderDialog } from '@/page/review/employee-review-reminder-dialog/SendEmployeeReviewReminderDialog';
import { EmployeeReviewSummaryStatusChip } from '@/page/review/employee-review-summary-status-chip/EmployeeReviewSummaryStatusChip';
import { EmployeeReviewUpdateReviewersDialog } from '@/page/review/employee-review-update-reviewers-dialog/EmployeeReviewUpdateReviewersDialog';
import { CloseEmployeeReviewDialog } from '@/page/review/employee-reviews-management/CloseEmployeeReviewDialog';
import { PlannedReviewDialog, PlannedReviewFormValues } from '@/page/review/employee-reviews-management/PlannedReviewDialog';
import { useCurrentEmployee } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { isFutureDate } from '@/utils/datetime.util';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Button, Paper, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export type EmployeeReviewsProps = {
    reviewType: ReviewType;
};

export type PlannedReviewDialogState = {
    employeeReview: EmployeeReview | undefined;
    open: boolean;
};

export const EmployeeReviews: FC<EmployeeReviewsProps> = ({ reviewType }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentEmployee = useCurrentEmployee();
    const [employeeReviewToClose, setEmployeeReviewToClose] = useState<EmployeeReview>();
    const [employeeReviewToUpdateContributor, setEmployeeReviewToUpdateContributor] = useState<EmployeeReview>();
    const [plannedReviewDialogState, setPlannedReviewDialogState] = useState<PlannedReviewDialogState>({
        employeeReview: undefined,
        open: false,
    });
    const [oneShotReviewDialogOpen, setOneShotReviewDialogOpen] = useState(false);
    const [employeeReviewToSendReminder, setEmployeeReviewToSendReminder] = useState<EmployeeReview>();

    const {
        data: reviews = [],
        isError: isReviewsError,
        isLoading: isLoadingReviews,
        refetch: refetchReviews,
        error,
    } = useGetReviews({ reviewTypes: [reviewType] });

    const employeeReviews = mapReviewsToEmployeeReviews(reviews);

    const handleCreateReviewSuccess = () => {
        setPlannedReviewDialogState({
            employeeReview: undefined,
            open: false,
        });
        setOneShotReviewDialogOpen(false);
        refetchReviews().catch(handleError);
    };

    const handleCreateOnBoardingReview = async (values: PlannedReviewFormValues) => {
        const mutation = mapBoardingFormValuesToCreationMutation(values);

        try {
            await createOnboardingReview(mutation);
            handleCreateReviewSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    const handleCreateOffBoardingReview = async (values: PlannedReviewFormValues) => {
        const mutation = mapBoardingFormValuesToCreationMutation(values);

        try {
            await createOffboardingReview(mutation);
            handleCreateReviewSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    const handlePatchReview = async (values: PlannedReviewFormValues, currentEmployeeReview: EmployeeReview) => {
        const mutation = mapBoardingFormValuesToPatchMutation(values, currentEmployeeReview);
        const reviewId = currentEmployeeReview.review?.id;

        if (!reviewId) {
            throw new Error('Review id is not set');
        }
        try {
            await patchReview(reviewId, mutation);
            handleCreateReviewSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    const onOpenNewReview = (reviewType: ReviewType) => {
        if (reviewType === 'ONBOARDING' || reviewType === 'OFFBOARDING') {
            setPlannedReviewDialogState({
                employeeReview: undefined,
                open: true,
            });
        } else {
            setOneShotReviewDialogOpen(true);
        }
    };

    const emptyState = (
        <Paper sx={{ display: 'flex', flex: '1' }}>
            <EmptyState icon={<ReviewEmptyStateIcon />} flex={1} title={t('reviews.employee_reviews.no_reviews')} />
        </Paper>
    );

    const handleCancelEmployeeReview = async (employeeReviewId: number) => {
        try {
            await cancelEmployeeReview(employeeReviewId);
            await refetchReviews();
        } catch (error) {
            handleError(error);
        }
    };

    const handleCloseEmployeeReview = async ({ comment }: { comment: string }) => {
        if (!employeeReviewToClose) {
            throw new Error('Employee review to close is not set');
        }
        try {
            await closeEmployeeReview(employeeReviewToClose.id, comment);
            await refetchReviews();
            setEmployeeReviewToClose(undefined);
        } catch (error) {
            handleError(error);
        }
    };

    const handleUpdateContributor = async (request: UpdateEmployeeReviewMutation, id: number) => {
        try {
            await updateEmployeeReview(id, request);
            await refetchReviews();
            setEmployeeReviewToUpdateContributor(undefined);
        } catch (error) {
            handleError(error);
        }
    };

    const contextMenuRender = (data: ICellRendererParams<EmployeeReview>) => {
        const { data: employeeReview } = data;
        const review = employeeReview?.review;
        const menuListItem = [];

        if (!employeeReview || !review) {
            throw new Error('Employee review or review is not set');
        }

        const isEditEmployeeReviewEnabled =
            !!employeeReview.review?.startDate &&
            isFutureDate(employeeReview.review.startDate) &&
            (reviewType === 'ONBOARDING' || reviewType === 'OFFBOARDING');

        if (isEditEmployeeReviewEnabled) {
            menuListItem.push({
                title: t('general.edit'),
                onClick: () => {
                    setPlannedReviewDialogState({
                        employeeReview,
                        open: true,
                    });
                },
            });
        }

        if (isOngoingReview(employeeReview)) {
            menuListItem.push(
                {
                    title: t('general.cancel'),
                    onClick: () => handleCancelEmployeeReview(employeeReview.id),
                },
                {
                    title: t('reviews.employee_review.close'),
                    onClick: () => setEmployeeReviewToClose(employeeReview),
                },
                {
                    title: t('reviews.employee_review.update_reviewers'),
                    onClick: () => setEmployeeReviewToUpdateContributor(employeeReview),
                },
                {
                    title: t('reviews.reminder.send_reminder'),
                    onClick: () => {
                        setEmployeeReviewToSendReminder(employeeReview);
                    },
                    disabled: review.reviewStatus !== 'STARTED',
                },
            );
        }

        if (!menuListItem.length) {
            return;
        }

        return <BasicMenu items={menuListItem} />;
    };

    const columnDefs: RogerColDef<EmployeeReview>[] = [
        {
            field: 'employee',
            type: 'employee',
            headerName: t('general.employee'),
        },
        {
            headerName: t('general.manager'),
            valueGetter: ({ data }) => data?.managers.flatMap(manager => manager.employee),
            type: 'stackedAvatars',
            cellRendererParams: () => ({
                cellNavDisabled: true,
            }),
        },
        {
            field: 'review.name',
            headerName: t('reviews.employee_reviews_table.review_name'),
            type: 'label',
        },
        {
            field: 'review.startDate',
            headerName: t('reviews.employee_reviews_table.start_date'),
            type: 'date',
            hide: reviewType === 'ONE_SHOT',
        },
        {
            field: 'review.endDate',
            headerName: t('reviews.employee_reviews_table.end_date'),
            type: 'date',
        },
        {
            field: 'review.feedbackDeadlineDate',
            headerName: t('reviews.employee_reviews_table.feedback_deadline_date'),
            type: 'date',
            hide: reviewType !== 'ONE_SHOT',
        },
        {
            field: 'status',
            headerName: t('general.status'),
            cellRenderer: StatusCell,
        },
        {
            type: 'actionMenu',
            cellRenderer: contextMenuRender,
        },
    ];

    const goToReviewSummary = (employeeReview: EmployeeReview, currentEmployee: Employee) => {
        if (employeeReview.employee && isCurrentEmployeeManager(employeeReview, currentEmployee)) {
            navigate(`/reviews/${employeeReview.id}/manager-summary`);
            return;
        }
        navigate(`/reviews/${employeeReview.id}/summary`);
    };

    const handlePlannedReviewDialogSuccess = (values: PlannedReviewFormValues, reviewType: ReviewType, currentEmployeeReview: EmployeeReview | undefined) => {
        const reviewId = currentEmployeeReview?.review?.id;
        if (reviewId && currentEmployeeReview) {
            handlePatchReview(values, currentEmployeeReview).catch(handleError);
            return;
        }

        if (reviewType === 'ONBOARDING') {
            handleCreateOnBoardingReview(values).catch(handleError);
            return;
        }

        if (reviewType === 'OFFBOARDING') {
            handleCreateOffBoardingReview(values).catch(handleError);
        }
    };
    return (
        <Stack flex={1} gap={2}>
            <Stack direction='row-reverse'>
                <Button
                    onClick={() => {
                        onOpenNewReview(reviewType);
                    }}
                >
                    {t('reviews.employee_reviews.new_review', {
                        context: reviewType,
                    })}
                </Button>
            </Stack>
            <StateHandler
                isLoading={isLoadingReviews}
                isEmpty={!employeeReviews?.length}
                emptyStateComponent={emptyState}
                isError={isReviewsError}
                error={error}
            >
                <>
                    <Stack flex={1} width='100%'>
                        <AgGridWrapper<EmployeeReview>
                            columnDefs={columnDefs}
                            rowData={employeeReviews}
                            onRowClicked={({ data, event }) => {
                                if (!event?.defaultPrevented && data?.id && currentEmployee && data.status !== 'CANCELLED') {
                                    goToReviewSummary(data, currentEmployee);
                                }
                            }}
                            loading={false}
                            compact={false}
                        />
                    </Stack>
                    {employeeReviewToClose && (
                        <CloseEmployeeReviewDialog onClose={() => setEmployeeReviewToClose(undefined)} open={true} onSave={handleCloseEmployeeReview} />
                    )}
                    {!!employeeReviewToUpdateContributor && (
                        <EmployeeReviewUpdateReviewersDialog
                            open={!!employeeReviewToUpdateContributor}
                            activeEmployeeReview={employeeReviewToUpdateContributor}
                            onSave={handleUpdateContributor}
                            onClose={() => setEmployeeReviewToUpdateContributor(undefined)}
                        />
                    )}
                </>
            </StateHandler>
            {plannedReviewDialogState.open && (
                <PlannedReviewDialog
                    title={reviewType === 'ONBOARDING' ? t('reviews.new_review_onboarding.dialog_title') : t('reviews.new_review_offboarding.dialog_title')}
                    open={plannedReviewDialogState.open}
                    currentEmployeeReview={plannedReviewDialogState.employeeReview}
                    onClose={() => {
                        setPlannedReviewDialogState({
                            employeeReview: undefined,
                            open: false,
                        });
                    }}
                    onSuccess={values => handlePlannedReviewDialogSuccess(values, reviewType, plannedReviewDialogState.employeeReview)}
                    reviewType={reviewType}
                />
            )}
            {oneShotReviewDialogOpen && (
                <OneShotReviewDialog
                    open={true}
                    onClose={() => setOneShotReviewDialogOpen(false)}
                    onSuccess={handleCreateReviewSuccess}
                    employeeId={undefined}
                />
            )}

            {employeeReviewToSendReminder?.review && (
                <SendEmployeeReviewReminderDialog
                    open={true}
                    onClose={() => setEmployeeReviewToSendReminder(undefined)}
                    review={employeeReviewToSendReminder.review}
                    employeeReviews={[employeeReviewToSendReminder]}
                />
            )}
        </Stack>
    );
};
const StatusCell = ({ value }: { value: EmployeeReview['status'] }) => <EmployeeReviewSummaryStatusChip status={value} />;

const mapReviewsToEmployeeReviews = (reviews: Review[]): EmployeeReview[] => {
    return reviews?.flatMap(review =>
        (review?.employeeReviews ?? []).map(employeeReview => ({
            ...employeeReview,
            review,
        })),
    );
};

const mapBoardingFormValuesToCreationMutation = (values: PlannedReviewFormValues): ReviewOffboardingCreationMutation | ReviewOnboardingCreationMutation => {
    return {
        name: values.name,
        reviewTemplateId: values.reviewTemplate.value,
        managerIds: values.managers.map(manager => manager.value),
        employeeId: values.employee.value,
        startDate: values.reviewNotificationDate,
        endDate: values.reviewEndDate,
    };
};

const mapBoardingFormValuesToPatchMutation = (values: PlannedReviewFormValues, currentEmployeeReview: EmployeeReview): ReviewPatchMutation => {
    return {
        startDate: values.reviewNotificationDate,
        endDate: values.reviewEndDate,
        employeeReviews: mapEmployeeReviewPatchMutation(values, currentEmployeeReview),
    };
};

const mapEmployeeReviewPatchMutation = (values: PlannedReviewFormValues, currentEmployeeReview: EmployeeReview): ReviewPatchMutation['employeeReviews'] => {
    return [
        {
            employeeReviewId: currentEmployeeReview.id,
            managerIds: values.managers.map(manager => manager.value),
        },
    ];
};
