import { Components, Theme } from '@mui/material';
import { CheckmarkSquare02Icon, RemoveSquareIcon, SquareIcon } from 'hugeicons-react';

export const MuiCheckbox: Components<Omit<Theme, 'components'>>['MuiCheckbox'] = {
    defaultProps: {
        size: 'small',
        icon: <SquareIcon size={20} />,
        checkedIcon: <CheckmarkSquare02Icon size={20} />,
        indeterminateIcon: <RemoveSquareIcon size={20} />,
    },
    styleOverrides: {
        root: ({ theme }) => ({
            padding: theme.spacing(0.75),
            '&.Mui-checked': {
                color: theme.palette.primary.main,
            },

            '&.Mui-disabled': {
                color: theme.palette.action.disabled,
                opacity: 0.65,
            },

            '& .MuiTouchRipple-root': {
                color: theme.palette.primary.main,
            },
        }),
    },
};
