import { Employee } from '@/domain/employee/Employee.model';
import { createSlice } from '@reduxjs/toolkit';
import { EmployeeProfileActions, EmployeeProfileActionType } from './employeeProfileActions';

export type EmployeeProfileState = {
    employee?: Employee;
    refetchEmployee?: boolean;
};

const initialState: EmployeeProfileState = {
    employee: undefined,
    refetchEmployee: false,
};

/**
 * @deprecated
 */
function oldReducer(state = initialState, action: EmployeeProfileActions): EmployeeProfileState {
    switch (action.type) {
        case EmployeeProfileActionType.EMPLOYEE_PROFILE_LOADED:
            return {
                ...state,
                employee: action.employee,
            };
        case EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE:
            return {
                ...state,
                refetchEmployee: action.refetchEmployee,
            };
        default:
            return state;
    }
}
export const employeeProfileSlice = createSlice({
    name: 'policies',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addDefaultCase((state, action) => {
            return oldReducer(state, action as EmployeeProfileActions);
        });
    },
});

export const employeeProfileReducer = employeeProfileSlice.reducer;
