import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';

import { ImportType } from '@/domain/import/Import.model';
import { Download02Icon } from 'hugeicons-react';

export type DefaultInformation = {
    titleTranslationKey: string;
    downloadTemplateLink: string;
    importType: ImportType;
    uploadRoute?: string;
    resultUrl?: string;
};

type DefaultSectionsImportCardProps = {
    titleKey: string;
    defaultInformation: DefaultInformation[];
    handleUploadClick: (importType: ImportType) => void;
};
export const DefaultSectionsImportCard: FC<DefaultSectionsImportCardProps> = ({ titleKey, defaultInformation: defaultInformationArray, handleUploadClick }) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Paper elevation={0} sx={{ p: 2 }}>
                <Stack gap={2}>
                    <Typography variant='h2'>{t(titleKey)}</Typography>
                    <Stack gap={2}>
                        {defaultInformationArray.map(defaultInformation => {
                            return (
                                <Stack
                                    key={defaultInformation.titleTranslationKey + defaultInformation.downloadTemplateLink}
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                >
                                    <Typography variant={'body2bold'}>{t(defaultInformation.titleTranslationKey)}</Typography>
                                    <Stack direction={'row'} gap={2}>
                                        <Button variant='text' endIcon={<Download02Icon />} href={defaultInformation.downloadTemplateLink} download>
                                            {t('import_page.download_template')}
                                        </Button>
                                        <Button onClick={() => handleUploadClick(defaultInformation?.importType)}>{t('general.upload')}</Button>
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>
                </Stack>
            </Paper>
        </Box>
    );
};
