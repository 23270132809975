import { StateHandler } from '@/components/state-handler/StateHandler';
import { getCurrentEmployee } from '@/domain/employee/Employee.service';
import { useGetProfilePages } from '@/page/employee-profile/EmployeeProfilePages.hook';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { useEmployeeProfileTab } from '@/page/employee-profile/useEmployeeProfileTab';
import { Page } from '@/page/Pages';
import { employeeAuthenticated } from '@/stores/reducers/currentEmployeeSlice';
import { useAppDispatch, useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { Paper, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const EmployeeProfileTabs: FC = () => {
    const dispatch = useAppDispatch();
    const currentEmployee = useCurrentEmployee();
    const employeeId = useEmployeeProfileId();
    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();

    /**
     * Fetches the current employee and dispatches the granted policies and permission groups.
     * This prevents incorrect behavior when users navigate without refreshing the page.
     * For instance, if we update a permission and then navigate to the profile page.
     */
    const fetchCurrentEmployee = useCallback(async () => {
        try {
            const data = await getCurrentEmployee();
            dispatch(employeeAuthenticated(data));
        } catch (error) {
            console.error(error);
        }
    }, [dispatch]);

    useEffect(() => {
        fetchCurrentEmployee();
    }, [fetchCurrentEmployee]);

    const isCurrentEmployee = currentEmployee?.id === employeeId;
    const profileTabsItems = useGetProfilePages(employeeId);

    return (
        <StateHandler loadingComponent={<TabsSkeleton />} isLoading={!realm} isError={false} error={undefined}>
            {policies && !!profileTabsItems.length && (
                <PaperTabs employeeId={employeeId} isCurrentEmployee={isCurrentEmployee} profileTabsItems={profileTabsItems} />
            )}
        </StateHandler>
    );
};

type PaperTabsProps = {
    employeeId: number;
    isCurrentEmployee: boolean;
    profileTabsItems: Page[];
};

const PaperTabs: FC<PaperTabsProps> = ({ employeeId, isCurrentEmployee, profileTabsItems }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getProfilePath = (path: string) => {
        return path.replace('/profile/', '');
    };

    const activeProfileTab = useEmployeeProfileTab();

    if (!profileTabsItems.find(item => item.path.includes(activeProfileTab))) {
        return;
    }

    return (
        <Paper>
            <Tabs
                value={activeProfileTab}
                variant='scrollable'
                scrollButtons='auto'
                onChange={(_, newValue) => {
                    navigate(isCurrentEmployee ? `${newValue}` : `${employeeId}/${newValue}`);
                }}
            >
                {profileTabsItems.map(item => (
                    <Tab key={item.path} value={getProfilePath(item.path)} label={t(item.labelKey)} />
                ))}
            </Tabs>
        </Paper>
    );
};

export const TabsSkeleton: FC = () => {
    return (
        <Paper sx={{ minHeight: 48 }}>
            <Stack direction='row' alignItems={'center'} height={'100%'} gap={2} pl={2}>
                <Skeleton variant='rounded' width={160} height={24} />
                <Skeleton variant='rounded' width={120} height={24} />
                <Skeleton variant='rounded' width={160} height={24} />
                <Skeleton variant='rounded' width={120} height={24} />
                <Skeleton variant='rounded' width={160} height={24} />
                <Skeleton variant='rounded' width={120} height={24} />
            </Stack>
        </Paper>
    );
};
