import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArrowDown01Icon } from 'hugeicons-react';
import Switch from '@mui/material/Switch';

export const TimeManagementRestrictions: FC = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();

    const timesheetMode = watch('timesheetMode');
    const isSimplified = timesheetMode === TimesheetMode.SIMPLIFIED;
    const isForceBreakToBeTakenEnabled = watch('isForceBreakToBeTakenEnabled');
    const forceBreakClockInOut = watch('forceBreakClockInOut');

    const disableBreakDisplay = forceBreakClockInOut && isForceBreakToBeTakenEnabled && !isSimplified;

    return (
        <Paper>
            <Stack component={Accordion} py={1} px={3}>
                <AccordionSummary expandIcon={<ArrowDown01Icon />} aria-controls='panel-res' id='panel-res' sx={{ p: 0 }}>
                    <Typography variant={'h2'}>{t('time_management_settings_page.time_management_configuration.time_management_restrictions')}</Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ p: 0, pb: 3 }}>
                    <Stack gap={2}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <FieldSwitch
                                name={'mandatoryComment'}
                                labelPlacement='end'
                                label={t('time_management_settings_page.time_management_configuration.mandatory_comment_timesheet')}
                                control={control}
                            />
                        </Stack>

                        {!isSimplified &&
                            (!disableBreakDisplay ? (
                                <FieldSwitch
                                    invert
                                    name={'breakDisplayEnabled'}
                                    labelPlacement='end'
                                    label={t('time_management_settings_page.time_management_configuration.break_display')}
                                    control={control}
                                />
                            ) : (
                                <FormControlLabel // This is a workaround to keep the switch disabled when the conditions are met
                                    disabled={disableBreakDisplay}
                                    label={t('time_management_settings_page.time_management_configuration.break_display')}
                                    labelPlacement='end'
                                    control={<Switch checked={false} />}
                                />
                            ))}

                        {!isSimplified && (
                            <FieldSwitch
                                invert
                                name={'allowClockInOutOutsideWorkHours'}
                                labelPlacement='end'
                                label={t('time_management_settings_page.time_management_configuration.allow_clock_in_out_outside_work_hours')}
                                control={control}
                            />
                        )}

                        <FieldSwitch
                            invert
                            name={'allowClockInOutOnSundayAndPublicHolidays'}
                            labelPlacement='end'
                            label={t('time_management_settings_page.time_management_configuration.allow_clock_in_out_on_sunday_and_public_holidays')}
                            control={control}
                        />

                        {!isSimplified && (
                            <FieldSwitch
                                invert
                                name={'allowFutureInputs'}
                                labelPlacement='end'
                                label={t('time_management_settings_page.time_management_configuration.allow_future_timesheet_inputs')}
                                control={control}
                            />
                        )}
                    </Stack>
                </AccordionDetails>
            </Stack>
        </Paper>
    );
};
