import { FC, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { calculatePercentage } from '@/utils/math.util';

type Props = {
    data: PieChartData;
};

interface PieChartData {
    title: string;
    average: PieChartValue;
    total: PieChartValue;
}

interface PieResults {
    id: string;
    label: string;
    value: number;
}

interface PieChartValue {
    title: string;
    value: number;
}

export const TotalPieChart: FC<Props> = ({ data }) => {
    const [pieResults, setPieResults] = useState<PieResults[]>();

    useEffect(() => {
        const result = [];
        const averageNumber = {
            id: data.average.title,
            label: `${calculatePercentage(data.average.value, data.total.value)}% ${data.average.title}`,
            value: data.average.value,
        };
        const totalNumber = {
            id: data.total.title,
            label: `${calculatePercentage(data.total.value - data.average.value, data.total.value)}% ${data.total.title}`,
            value: data.total.value - data.average.value,
        };
        result[0] = averageNumber;
        result[1] = totalNumber;
        setPieResults(result);
    }, [data]);

    return (
        <Box
            sx={{
                width: 310,
                height: 160,
                position: 'relative',
                '& svg': {
                    width: 350,
                    height: 300,
                },
            }}
            style={{ fontFamily: 'sans-serif' }}
        >
            <ResponsivePie
                data={pieResults ?? []}
                theme={{
                    text: {
                        fontFamily: 'Arial',
                    },
                    axis: {
                        legend: {
                            text: {
                                fontSize: 120,
                                fill: '#333333',
                            },
                        },
                    },
                }}
                margin={{ top: 10, right: 140, bottom: 0, left: 0 }}
                innerRadius={0.85}
                padAngle={0.9}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor='#333333'
                colors={['#01CDCD', '#01153C', '#EAF8F9']}
                // keys={['earnings', 'bonus']}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                }}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 60,
                        translateY: 0,
                        itemsSpacing: 12,
                        itemWidth: 50,
                        itemHeight: 22,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000',
                                },
                            },
                        ],
                    },
                ]}
            />
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    width: 80,
                    height: 80,
                    top: 0,
                    left: 47,
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    fontSize: 30,
                    textAlign: 'center',
                    bottom: 0,
                    margin: 'auto',
                }}
            >
                <Grid item>
                    <Typography
                        sx={{
                            fontSize: 20,
                            color: '#01153C',
                        }}
                    >
                        {calculatePercentage(data.average.value, data.total.value)}%
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            color: '#585869',
                        }}
                    >
                        {data.title}
                    </Typography>
                </Grid>
            </Box>
        </Box>
    );
};
