import { KeyboardEvent, useState } from 'react';
import { getNull } from '@/utils/object.util';
import { FieldSelectedSections } from '@mui/x-date-pickers';

type useFieldBackspaceReturnValue = {
    selectedSections: FieldSelectedSections;
    setSelectedSections: (value: FieldSelectedSections) => void;
    handleBackspace: (e: KeyboardEvent<HTMLInputElement>) => void;
};

type useFieldBackspaceParams = {
    valueFormat: string;
    separator: string;
};
/**
 * Hook to handle backspace keydown event on a field with a specific format
 * It will move the cursor to the previous section when user press backspace on an empty section
 * @param valueFormat 'dd.mm.yyyy' format of the field (example dates)
 * @param separator separator between sections (example: '.')
 * @param manageCursorEnd if true, the cursor will be moved to the last section when the cursor is at the end of the input
 * @returns An object with the following properties:
 * @returns selectedSections: the section that is currently selected
 * @returns setSelectedSections: function to set the selected section
 * @returns handleKeyDown: function to handle backspace keydown event
 */
export const useFieldBackspace = ({ valueFormat, separator }: useFieldBackspaceParams): useFieldBackspaceReturnValue => {
    // section of the field value that is currently selected. A section is a part of the field value separated by the separator provided
    // Example : dd.MM.yyyy -> 0: day, 1: month, 2: year
    const [selectedSections, setSelectedSections] = useState<FieldSelectedSections>(getNull());

    const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
        const inputTarget = e.target as HTMLInputElement;
        if (e.key === 'Backspace') {
            const maxSections = valueFormat.split(separator).length;
            // case of cursor is at the end of the input
            // we want to move the cursor to the last section
            if (inputTarget.selectionStart === valueFormat.length) {
                setSelectedSections(maxSections - 1);
                return;
            }

            // we do nothing if the selected section is null or not a number
            if (selectedSections === getNull() || typeof selectedSections !== 'number') {
                return;
            }

            // if the section value is not a number, it means that the current selected section is already cleared
            // we want to move to the previous section
            const sectionValue = inputTarget.value.split(separator)[selectedSections];
            if (isNaN(Number(sectionValue))) {
                setSelectedSections(prev => {
                    // we only move to the previous section if the previous selected section is a number
                    // which mean it is a part of the mask (example dd or mm or yyyy for date case)
                    if (!prev || typeof prev !== 'number') {
                        return prev;
                    }

                    if (prev > 0) {
                        return prev - 1;
                    }
                    return prev;
                });
            }
        }
    };

    return {
        selectedSections,
        setSelectedSections,
        handleBackspace: handleKeyUp,
    };
};
