import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { Calendar } from '@/domain/calendar/Calendar.model';
import { getCalendarById, getCalendars } from '@/domain/calendar/Calendar.service';
import { handleError } from '@/utils/api.util';

export const useGetCalendars = (): UseQueryResult<Calendar[]> => {
    const [calendars, setCalendars] = useState<Calendar[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCalendars = useCallback(async () => {
        try {
            const calendars = await getCalendars();
            setCalendars(calendars);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchCalendars().catch(handleError);
    }, [fetchCalendars]);

    return {
        data: calendars,
        setData: setCalendars,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchCalendars(),
    };
};

export const useGetCalendar = (id: number | undefined): UseQueryResult<Calendar> => {
    const [calendar, setCalendar] = useState<Calendar>();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<unknown>();

    const fetchCalendar = useCallback(async () => {
        try {
            if (id) {
                const calendarData = await getCalendarById(id);
                setCalendar(calendarData);
            }
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchCalendar();
    }, [fetchCalendar]);

    return {
        data: calendar,
        setData: setCalendar,
        isLoading,
        error,
        isError: !!error,
        refetch: fetchCalendar,
    };
};
