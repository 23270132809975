import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { Planning, PlanningMutation, PlanningSearch } from '@/domain/planning/Planning.model';

const getPlanningById = async (planningsId: number): Promise<Planning> => {
    const url = API_BASE_URL + `/plannings/${planningsId}`;
    return (await client.get<PlanningDTO>(url)).data;
};

const updatePlanning = async (planningId: number, mutation: PlanningMutation): Promise<Planning> => {
    const url = API_BASE_URL + `/plannings/${planningId}`;
    return (await client.put<PlanningDTO, AxiosResponse<PlanningDTO>, PlanningMutation>(url, mutation)).data;
};

const deletePlanning = async (planningId: number): Promise<void> => {
    const url = API_BASE_URL + `/plannings/${planningId}`;
    await client.delete(url);
};

const createPlanning = async (mutation: PlanningMutation): Promise<Planning> => {
    const url = API_BASE_URL + `/plannings`;
    return (await client.post<PlanningDTO, AxiosResponse<PlanningDTO>, PlanningMutation>(url, mutation)).data;
};

const searchPlannings = async (request: PlanningSearch): Promise<Planning[]> => {
    const url = API_BASE_URL + `/plannings/search`;
    return (await client.post<PlanningDTO[], AxiosResponse<PlanningDTO[]>, PlanningSearch>(url, request)).data;
};

export const planningApi = {
    getPlanningById,
    updatePlanning,
    deletePlanning,
    createPlanning,
    searchPlannings,
};

export type PlanningDTO = Planning;
