import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { getAppConfig } from '@/config/config';
import { LeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { MonthlyTimesheetReport, TimesheetCycle } from '@/domain/timesheet/Timesheet.model';
import { formatDate, formatDurationInHours, MONTHS, subDays } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Button, DialogContent, Stack, Typography, TypographyProps, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type OvertimeCardField = {
    title: string;
    value?: number;
    variant: TypographyProps['variant'];
};

type OvertimeFieldProps = {
    timesheetsMonthly: MonthlyTimesheetReport;
    currentTimesheetCycle: TimesheetCycle;
    selectedTimesheetCycle: TimesheetCycle;
    leaveTypeHistoriesToday: LeaveTypeHistory[];
    leaveTypeHistoriesForecasted: LeaveTypeHistory[];
    employeeId: number;
};

const timesheetsMonthlyContainsValues = (timesheetsMonthly: MonthlyTimesheetReport): boolean => {
    return (
        timesheetsMonthly.previousCarryover !== 0 ||
        timesheetsMonthly.totalAdjustmentCount !== 0 ||
        timesheetsMonthly.totalForecastedAdjustmentCount !== 0 ||
        timesheetsMonthly.totalBonusCount !== 0 ||
        timesheetsMonthly.totalForecastedBonusCount !== 0
    );
};

const leaveTypeHistoriesContainsValues = (leaveTypeHistories: LeaveTypeHistory[]): boolean => {
    return leaveTypeHistories.some(history => history.usedAmountInMinutes !== 0);
};

const detailsDialogContainsValues = (
    timesheetsMonthly: MonthlyTimesheetReport,
    leaveTypeHistoriesToday: LeaveTypeHistory[],
    leaveTypeHistoriesForecasted: LeaveTypeHistory[],
): boolean => {
    return (
        timesheetsMonthlyContainsValues(timesheetsMonthly) ||
        timesheetsMonthly.totalPaymentCount !== 0 ||
        timesheetsMonthly.totalForecastedPaymentCount !== 0 ||
        leaveTypeHistoriesContainsValues(leaveTypeHistoriesToday) ||
        leaveTypeHistoriesContainsValues(leaveTypeHistoriesForecasted)
    );
};

export const TotalOvertimeFields: FC<OvertimeFieldProps> = ({
    timesheetsMonthly,
    selectedTimesheetCycle,
    currentTimesheetCycle,
    leaveTypeHistoriesToday,
    leaveTypeHistoriesForecasted,
}) => {
    const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
    const { t } = useTranslation();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const handleClose = () => {
        setShowDetailsDialog(false);
    };

    const isSelectedCycleTheCurrent = selectedTimesheetCycle.year === currentTimesheetCycle.year;
    const shouldDisplayDetailsButton =
        isSelectedCycleTheCurrent && detailsDialogContainsValues(timesheetsMonthly, leaveTypeHistoriesToday ?? [], leaveTypeHistoriesForecasted ?? []);

    return (
        <Stack>
            <Stack padding={isMobile ? 0 : 1} pb={0} gap={0.5}>
                <OvertimeField title={t('timesheets.overtime_balance_card.current_time_balance')} value={timesheetsMonthly?.balance} variant={'h2'} />

                <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <OvertimeField
                        title={t('timesheets.overtime_balance_card.end_of_year_forecast')}
                        value={timesheetsMonthly?.forecastedBalance}
                        variant={'body2'}
                    />
                    {shouldDisplayDetailsButton && (
                        <Button
                            variant='text'
                            color='primary'
                            onClick={() => {
                                setShowDetailsDialog(true);
                            }}
                        >
                            {t('timesheets.overtime_balance_card.view_details')}
                        </Button>
                    )}
                </Stack>
            </Stack>

            {showDetailsDialog && (
                <OvertimeCardDetailsDialog
                    selectedTimesheetCycle={selectedTimesheetCycle}
                    timesheetsMonthly={timesheetsMonthly}
                    open={showDetailsDialog}
                    onClose={handleClose}
                    leaveTypeHistoriesToday={leaveTypeHistoriesToday ?? []}
                    leaveTypeHistoriesForecasted={leaveTypeHistoriesForecasted ?? []}
                />
            )}
        </Stack>
    );
};

const OvertimeField = (overtimeCardField: OvertimeCardField) => {
    return (
        <Stack key={overtimeCardField?.title} direction={'row'} alignItems={'center'} gap={1}>
            <Typography variant={overtimeCardField.variant}>{overtimeCardField?.title}</Typography>
            <Typography variant={overtimeCardField.variant}>
                {overtimeCardField?.value !== undefined ? formatDurationInHours(overtimeCardField?.value) : '-'}
            </Typography>
        </Stack>
    );
};

type OvertimeCardDetailsDialogProps = {
    timesheetsMonthly: MonthlyTimesheetReport;
    selectedTimesheetCycle: TimesheetCycle;
    leaveTypeHistoriesToday: LeaveTypeHistory[];
    leaveTypeHistoriesForecasted: LeaveTypeHistory[];
} & DialogWrapperProps;

type AggregatedLeaveTypeHistory = {
    today: LeaveTypeHistory | undefined;
    forecasted: LeaveTypeHistory | undefined;
};

const getLeavesTypeHistories = (
    leaveTypeHistoriesToday: LeaveTypeHistory[],
    leaveTypeHistoriesForecasted: LeaveTypeHistory[],
): Map<LeaveType, AggregatedLeaveTypeHistory>[] => {
    const leaveTypesToday = leaveTypeHistoriesToday.map(history => history.leaveType);
    const leaveTypesForecasted = leaveTypeHistoriesForecasted.map(history => history.leaveType);

    const leaveTypes: LeaveType[] = [...leaveTypesToday, ...leaveTypesForecasted].reduce<LeaveType[]>((acc: LeaveType[], item: LeaveType) => {
        if (!acc.some(existingItem => existingItem.id === item.id)) {
            acc.push(item);
        }
        return acc;
    }, []);

    return leaveTypes.map(leaveType => {
        const leavesTypeHistoriesMap = new Map<LeaveType, AggregatedLeaveTypeHistory>();
        const today = leaveTypeHistoriesToday.find(history => history.leaveType.id === leaveType.id);
        const forecasted = leaveTypeHistoriesForecasted.find(history => history.leaveType.id === leaveType.id);
        leavesTypeHistoriesMap.set(leaveType, { today, forecasted });
        return leavesTypeHistoriesMap;
    });
};
const config = getAppConfig();

const OvertimeCardDetailsDialog: FC<OvertimeCardDetailsDialogProps> = ({
    timesheetsMonthly,
    selectedTimesheetCycle,
    leaveTypeHistoriesToday,
    leaveTypeHistoriesForecasted,
    ...rest
}) => {
    const { t } = useTranslation();

    const leavesTypeHistories = getLeavesTypeHistories(leaveTypeHistoriesToday, leaveTypeHistoriesForecasted);
    const previousCarryoverDate = formatDate(
        subDays(selectedTimesheetCycle.cycleStartDate, 1),
        selectedTimesheetCycle.startMonth === MONTHS.JANUARY ? 'yyyy' : 'MMM yyyy',
    );

    const forecastedDate = formatDate(
        selectedTimesheetCycle.cycleEndDate,
        selectedTimesheetCycle.startMonth === MONTHS.JANUARY ? 'dd.MM' : config.DEFAULT_DATE_FORMAT,
    );

    const shouldDisplayCompensations =
        leaveTypeHistoriesContainsValues(leaveTypeHistoriesToday) ||
        leaveTypeHistoriesContainsValues(leaveTypeHistoriesForecasted) ||
        timesheetsMonthly.totalPaymentCount !== 0 ||
        timesheetsMonthly.totalForecastedPaymentCount !== 0;

    return (
        <DialogWrapper header={t('timesheets.overtime_balance_details_card.title')} {...rest}>
            <Stack
                display={'grid'}
                gridTemplateColumns={'auto 100px 120px'}
                component={DialogContent}
                sx={{
                    pb: 3,
                }}
            >
                {/*empty cell*/}
                <Stack />
                <Typography align={'center'} variant='body1bold'>
                    {t('timesheets.overtime_balance_details_card.today')}
                </Typography>
                <Typography align={'center'} variant='body1bold'>
                    {t('timesheets.overtime_balance_details_card.forecasted', { date: forecastedDate })}
                </Typography>

                <>
                    <Typography variant='body1bold'>{t('timesheets.overtime_balance_details_card.accrued')}</Typography>
                    {/*empty cell*/}
                    <Stack />
                    {/*empty cell*/}
                    <Stack />
                </>

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.carryover', { year: previousCarryoverDate })}
                    todayValueInMinutes={timesheetsMonthly.previousCarryover}
                    forecastedValueInMinutes={timesheetsMonthly.previousCarryover}
                />

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.adjustments')}
                    todayValueInMinutes={timesheetsMonthly.totalAdjustmentCount}
                    forecastedValueInMinutes={timesheetsMonthly.totalForecastedAdjustmentCount}
                />

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.daily_difference')}
                    todayValueInMinutes={timesheetsMonthly.totalDifference}
                    forecastedValueInMinutes={timesheetsMonthly.totalForecastedDifference}
                    alwaysDisplay={true}
                />

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.bonus_time')}
                    todayValueInMinutes={timesheetsMonthly.totalBonusCount}
                    forecastedValueInMinutes={timesheetsMonthly.totalForecastedBonusCount}
                />

                {shouldDisplayCompensations && (
                    <>
                        <Typography pt={1} variant='body1bold'>
                            {t('timesheets.overtime_balance_details_card.compensations')}
                        </Typography>
                        {/*empty cell*/}
                        <Stack />
                        {/*empty cell*/}
                        <Stack />
                    </>
                )}

                {leavesTypeHistories.map(leaveTypeHistoryMap => {
                    const leaveType = leaveTypeHistoryMap.keys().next().value;
                    if (leaveType === undefined) {
                        return;
                    }
                    const leaveTypeHistory = leaveTypeHistoryMap.get(leaveType);
                    return (
                        <OvertimeCardDetailField
                            key={leaveType.id}
                            title={getLabelTranslation(leaveType.name)}
                            todayValueInMinutes={-(leaveTypeHistory?.today?.usedAmountInMinutes ?? 0)}
                            forecastedValueInMinutes={-(leaveTypeHistory?.forecasted?.usedAmountInMinutes ?? 0)}
                        />
                    );
                })}

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.payments')}
                    todayValueInMinutes={-timesheetsMonthly.totalPaymentCount}
                    forecastedValueInMinutes={-timesheetsMonthly.totalForecastedPaymentCount}
                />

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.time_balance')}
                    todayValueInMinutes={timesheetsMonthly.balance}
                    forecastedValueInMinutes={timesheetsMonthly.forecastedBalance}
                    variant={'body1bold'}
                    bgcolor={'grey.100'}
                    pt={1}
                    pb={1}
                    mt={1}
                    alwaysDisplay={true}
                />
            </Stack>
        </DialogWrapper>
    );
};

type OvertimeCardDetailFieldProps = {
    title: string;
    todayValueInMinutes: number;
    forecastedValueInMinutes: number;
    alwaysDisplay?: boolean;
} & TypographyProps;

const OvertimeCardDetailField: FC<OvertimeCardDetailFieldProps> = ({
    title,
    todayValueInMinutes = 0,
    forecastedValueInMinutes = 0,
    alwaysDisplay = false,
    ...rest
}) => {
    if (!alwaysDisplay && todayValueInMinutes === 0 && forecastedValueInMinutes === 0) {
        return undefined;
    }

    return (
        <>
            <Typography py={0.5} pl={1} variant='body1' borderBottom={'solid 1px'} borderColor={'grey.300'} {...rest}>
                {title}
            </Typography>
            <Typography py={0.5} align={'center'} variant='body1' borderBottom={'solid 1px'} borderColor={'grey.300'} {...rest}>
                {todayValueInMinutes ? formatDurationInHours(todayValueInMinutes) : '0h'}
            </Typography>
            <Typography py={0.5} align={'center'} variant='body1' borderBottom={'solid 1px'} borderColor={'grey.300'} {...rest}>
                {forecastedValueInMinutes ? formatDurationInHours(forecastedValueInMinutes) : '0h'}
            </Typography>
        </>
    );
};
