import { Button, ButtonProps, Popover, PopoverProps, Stack, Typography } from '@mui/material';
import { cloneElement, FC, MouseEvent, PropsWithChildren, ReactElement, ReactNode, useState } from 'react';

import { useTranslation } from 'react-i18next';

type ConfirmDialogProps = {
    children: ReactElement<HTMLButtonElement>;
    onConfirm(): void;
    confirmButtonProps?: ButtonProps;
    content?: ReactNode;
} & Omit<PopoverProps, 'open'>;
export const ConfirmPopover: FC<PropsWithChildren<ConfirmDialogProps>> = ({ children, content, onConfirm, confirmButtonProps, ...rest }) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    // Children should be always defined, but we need to handle the case when it's not until we provide a component to handle confirmation from menu items
    const childrenWithOnOpen = children
        ? cloneElement(children, {
              onClick: (e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
              },
          })
        : undefined;

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <>
            {/* Render the cloned children with the onClick event handler */}
            {childrenWithOnOpen}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                {...rest}
            >
                <Stack p={2} gap={2} minWidth={200} maxWidth={500}>
                    <Typography variant='body1bold' noWrap>
                        {content ?? t('confirmation_popover.are_you_sure')}
                    </Typography>

                    <Stack direction={'row'} justifyContent='space-between'>
                        <Button children={t('general.no')} variant='outlined' onClick={handleClose} />
                        <Button
                            children={t('general.yes')}
                            onClick={() => {
                                onConfirm();
                                handleClose();
                            }}
                            {...confirmButtonProps}
                        />
                    </Stack>
                </Stack>
            </Popover>
        </>
    );
};
