import { Checkbox, Stack, Typography } from '@mui/material';
import { FC, HTMLAttributes } from 'react';

type CheckboxAutocompleteOptionProps = { selected: boolean; label: string } & HTMLAttributes<HTMLLIElement>;

export const CheckboxAutocompleteOption: FC<CheckboxAutocompleteOptionProps> = ({ selected, label, ...restProps }) => {
    return (
        <Stack direction='row' component='li' {...restProps} style={{ paddingBottom: 0, paddingTop: 0 }} aria-label={label}>
            <Checkbox size='small' edge='start' checked={selected} disableRipple inputProps={{ 'aria-labelledby': label, 'aria-label': label }} />
            <Typography>{label}</Typography>
        </Stack>
    );
};
