import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { getLocalDateMinTestConfig, getLocalDateTestConfig } from '@/utils/datetime.util';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Stack, StackProps } from '@mui/material';
import i18next from 'i18next';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type DateRangeFormProps = {
    dates: [LocalDate | undefined, LocalDate | undefined];
    onDatesChanged: (dates: [LocalDate, LocalDate]) => void;
} & StackProps;

export const DateRangeForm: FC<DateRangeFormProps> = ({ dates, onDatesChanged, ...restRoot }) => {
    const { t } = useTranslation();
    const [start, end] = dates;
    const { control, handleSubmit } = useForm<DateRangeFilterValues>({
        defaultValues: {
            start,
            end,
        },
        resolver: yupResolver(getDateRangeFilterSchema()),
    });

    const onSubmit = ({ start, end }: DateRangeFilterValues) => {
        onDatesChanged([start, end]);
    };

    return (
        <Stack gap={1} {...restRoot}>
            <Stack direction={'row'} gap={2} alignItems={'flex-start'}>
                <FormControlLabel label={t('general.start')} control={<FieldLocalDate name={'start'} control={control} />} sx={{ flex: 1 }} />

                <FormControlLabel label={t('general.end')} control={<FieldLocalDate name={'end'} control={control} />} sx={{ flex: 1 }} />
            </Stack>
            <Button onClick={handleSubmit(onSubmit, console.error)} variant={'text'} sx={{ alignSelf: 'end' }}>
                {t('general.submit')}
            </Button>
        </Stack>
    );
};

const getDateRangeFilterSchema = () => {
    const dateSchema = yup.string<LocalDate>().test(getLocalDateTestConfig()).required();

    return yup.object().shape({
        start: dateSchema,
        end: dateSchema.when('start', ([start], schema) => schema.test(getLocalDateMinTestConfig(start, i18next.t('general.end_date_after_start_date')))),
    });
};
type DateRangeFilterValues = yup.InferType<ReturnType<typeof getDateRangeFilterSchema>>;
