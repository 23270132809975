import { RealmFeature } from '@/domain/realm/Realm.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { Page } from '@/page/Pages';
import { canContributeEmployeeReview, canManageReview } from '@/domain/permission/Permission.service';

export const getReviewPages = (realmFeatures: RealmFeature[], policies: EmployeePolicy[]): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'reviews.tabs.tasks',
            path: '/reviews/tasks',
            condition: () => canContributeEmployeeReview(policies),
        },
        {
            labelKey: 'reviews.tabs.team_reviews',
            path: '/reviews/team-reviews',
            condition: () => canContributeEmployeeReview(policies),
        },
        {
            labelKey: 'reviews.tabs.manage_reviews',
            path: '/reviews/manage-reviews',
            condition: () => canManageReview(policies),
        },
        {
            labelKey: 'reviews.tabs.onboarding_reviews',
            path: '/reviews/onboarding-employee-reviews',
            condition: () => canManageReview(policies),
        },
        {
            labelKey: 'reviews.tabs.offboarding_reviews',
            path: '/reviews/offboarding-employee-reviews',
            condition: () => canManageReview(policies),
        },
        {
            labelKey: 'reviews.tabs.one_shot_reviews',
            path: '/reviews/one-shot-employee-reviews',
            condition: () => canManageReview(policies),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(realmFeatures, policies));
};
