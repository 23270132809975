import * as yup from 'yup';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { UserLanguage } from '@/utils/language.util';
import { getCostCenterAssignmentListObjectSchema } from '@/page/cost-center/CostCentersAssignment.schema';
import { treeSelectValueSchema } from '@/components/tree-select/TreeSelectValue.schema';

const departmentManagerObjectSchema = yup.object().shape({
    id: yup.number().required(),
    displayName: yup.string().required(),
});

export type DepartmentManagerFormValue = yup.InferType<typeof departmentManagerObjectSchema>;

export const getDepartmentFormSchema = (translationLanguage: UserLanguage) =>
    yup
        .object()
        .shape({
            name: getLabelFormSchema(translationLanguage).required(),
            parent: treeSelectValueSchema.optional().nullable(),
            managers: yup.array().of(departmentManagerObjectSchema).default([]),
            departmentCostCenters: getCostCenterAssignmentListObjectSchema(),
        })
        .required();

export type DepartmentFormValues = yup.InferType<ReturnType<typeof getDepartmentFormSchema>>;
