import { AgGridWrapper, RogerColDef } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { Folder } from '@/domain/document/Document.model';
import { getContractTypeTranslationKey, getUserEmploymentStatusTranslationKey } from '@/domain/employee/Employee.service';
import { PermissionGroup } from '@/domain/permission-group/PermissionGroup.model';
import {
    COMPANY_PERMISSION_POLICY_GROUPS,
    EMPLOYEE_PERMISSION_POLICY_GROUPS,
    FilteringCondition,
    PermissionFilteringConditionType,
    PermissionFilterType,
    PermissionPolicyGroup,
    PolicyGroup,
    PolicyPermission,
    PolicySubGroup,
    ResourceBasedName,
} from '@/domain/permission/Permission.model';
import { getPermissionFilteringConditionDisplay } from '@/domain/permission/Permission.service';
import { SectionDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { useGetFolders } from '@/hooks/document/Document.hook';
import { getLabelTranslation } from '@/utils/language.util';
import { ColDef } from '@ag-grid-community/core';
import { Box, Button } from '@mui/material';
import { Download02Icon } from 'hugeicons-react';
import i18next from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';

type PermissionGroupWithFlags = PermissionGroup & { flags: Record<string, boolean> };
type PermissionGroupColumn = Omit<PermissionGroupWithFlags, 'flags'> & Record<string, boolean>;

type PermissionsSettingsExportButtonProps = {
    permissionGroups: PermissionGroup[];
};

/**
 * This component is using AgGridWrapper to export permission groups to an excel file.
 * It transposes the data so that each column becomes a row.
 * The grid is hidden and the export button triggers the export.
 */
export const PermissionsSettingsExportButton: FC<PermissionsSettingsExportButtonProps> = ({ permissionGroups }) => {
    const { t } = useTranslation();
    const agGridWrapper = useAgGridWrapper<TransposedData>();

    // Section definitions are used to set permissions for sections
    const { data: sectionDefinitions = [], isLoading: isLoadingSectionDefinitions, isError: isSectionDefinitionsError } = useGetSectionDefinitions();
    // Folders are used to set permissions for folders
    const { data: folders = [], isLoading: isLoadingFolders, isError: isFoldersError } = useGetFolders();

    const permissionGroupsWithFlags = getPermissionGroupsWithFlags(permissionGroups, sectionDefinitions, folders);

    // Default columns
    const columnDefs: RogerColDef<PermissionGroupColumn>[] = [
        {
            field: 'groupName',
            headerName: t('permissions_setting_page.group_name'),
        },
        {
            field: 'description',
            headerName: t('permissions_setting_page.description'),
        },
        {
            field: 'groupMembers',
            headerName: t('permissions_setting_page.employee_management_rights'),
        },
        {
            field: 'targetMembers',
            headerName: t('permissions_setting_page.applies_to'),
        },
    ];

    // Dynamic columns based on policies
    // we are using first permission group to get the permission paths
    const permissionGroupModel: PermissionGroupWithFlags | undefined = permissionGroupsWithFlags?.[0];
    const permissionGroupsPaths = Object.keys(permissionGroupModel?.flags ?? {});

    permissionGroupsPaths.forEach(path => {
        columnDefs.push({
            field: path,
            headerName: path,
        });
    });

    const transposedColumns: ColDef<TransposedData>[] = [
        // First column is the name of the row
        {
            field: 'column',
            headerName: 'Group Name',
            cellStyle: { fontWeight: 'bold' },
        },
        ...permissionGroups.map((row, index) => ({
            field: index.toString(),
            headerName: row.groupName,
        })),
    ];

    const groupRecords = formatPermissionGroupForTransposing(permissionGroupsWithFlags);

    // We are removing the first row because it is the column name
    const transposedData = transposeData(groupRecords, columnDefs).slice(1);

    const handleExport = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel();
    };

    const isLoading = isLoadingSectionDefinitions || isLoadingFolders;
    const isError = isSectionDefinitionsError || isFoldersError;

    return (
        <>
            <Button onClick={handleExport} sx={{ paddingX: 0.8, minWidth: 'auto' }} aria-label='Export grid' disabled={isLoading || isError}>
                <Download02Icon />
            </Button>
            {/*
                The grid is hidden because we only want to export the data.
                The grid is not visible to the user.
            */}
            <Box visibility='hidden'>
                <AgGridWrapper initRef={agGridWrapper.setGridRef} columnDefs={transposedColumns} rowData={transposedData} disableAutoSize loading={false} />
            </Box>
        </>
    );
};

/**
 * Helper function to transpose data and columns for ag-grid
 *
 * @param data - Array of PermissionGroup objects
 * @param columns - Array of RogerColDef objects defining the columns
 * @returns Transposed data where each column becomes a row
 */
const transposeData = (data: Record<string, string>[], columns: RogerColDef<PermissionGroupColumn>[]): TransposedData[] => {
    // Group Name, Description
    // Group 1, Description 1
    // Group 2, Description 2
    // [{ groupName: 'Group 1', description: 'Description 1' }, { groupName: 'Group 2', description: 'Description 2' }]

    // First column is the name of the row, the rest are the values
    // Group Name, Group 1, Group 2
    // Description, Description 1, Description 2

    // Result should be:
    // [{ column: 'Group Name', 0: 'Group 1', 1: 'Group 2' }, { column: 'Description', 0: 'Description 1', 1: 'Description 2' }]

    return columns.map(({ field, headerName }) => {
        // Create a new row with the column name
        const row: TransposedData = { column: headerName ?? '' };

        // Convert each original row (Permission Group) to a column
        data.forEach((permissionGroup, index) => {
            row[index] = permissionGroup[field as keyof PermissionGroup];
        });

        return row;
    });
};

type TransposedData = Record<string, string> & { column: string };

/**
 * Get string representation of the filter type
 */
const formatFilterType = (group: PermissionGroup, field: 'groupMembers' | 'targetMembers') => {
    const type = field === 'groupMembers' ? group.filterType : group.targetMembersFilterType;
    switch (type) {
        case PermissionFilterType.DYNAMIC: {
            const conditions = field === 'groupMembers' ? group.groupMemberConditions : group.targetMemberConditions;

            return getTranslatedFilterConditions(conditions).join(` ${i18next.t('general.and')} `);
        }

        case PermissionFilterType.EMPLOYEE_LIST:
            return group[field].map(e => e.displayName).join(', ');

        case PermissionFilterType.ALL_EMPLOYEES:
            return i18next.t('permissions_setting_page.all_employees');

        case PermissionFilterType.SELF:
            return i18next.t('permissions_setting_page.self');

        case PermissionFilterType.MANAGER:
            return i18next.t('permissions_setting_page.manager');
        default:
            return '';
    }
};

const getTranslatedFilterConditions = (conditions: FilteringCondition[]) => {
    return conditions.map(c => {
        const translatedFieldType = i18next.t('domain.employee_field_type', {
            context: c.conditionType,
        });
        const translatedConditionRuleType = i18next.t('domain.condition_rule_type', {
            context: getPermissionFilteringConditionDisplay(c.conditionRule, c.conditionType),
        });

        const getFilterConditionValues = () => {
            return c.filterConditionValues
                .map(condition => {
                    switch (c.conditionType) {
                        case PermissionFilteringConditionType.LOCATION:
                            return condition.location?.name ?? '';
                        case PermissionFilteringConditionType.DEPARTMENT:
                            return getLabelTranslation(condition.department?.name);
                        case PermissionFilteringConditionType.JOB:
                            return getLabelTranslation(condition.job?.name);
                        case PermissionFilteringConditionType.EMPLOYMENT_STATUS:
                            return i18next.t(getUserEmploymentStatusTranslationKey(), { context: condition.employmentStatus });
                        case PermissionFilteringConditionType.CONTRACT_TYPE:
                            return i18next.t(getContractTypeTranslationKey(), { context: condition.contractType });
                        default:
                            return '';
                    }
                })
                .join(` ${i18next.t('permissions_setting_page.or')} `);
        };

        const filterConditionValues = getFilterConditionValues();

        return `${translatedFieldType} ${translatedConditionRuleType} ${filterConditionValues}`;
    });
};

/**
 * Get permission groups with flags for each permission
 * @param permissionGroups
 * @param sectionDefinitions
 * @param folders
 * @returns PermissionGroupWithFlags[] - Permission groups with flags for each permission
 */
const getPermissionGroupsWithFlags = (
    permissionGroups: PermissionGroup[],
    // Section definitions are used in employee permissions
    sectionDefinitions: SectionDefinition[],
    // Folders are used in employee permissions and company permissions
    folders: Folder[],
): PermissionGroupWithFlags[] => {
    return permissionGroups.map(permissionGroup => {
        const flags: Record<string, boolean> = {};

        // Step 1: Flatten the employee groups
        let flattenEmployeeGroups = flattenPolicyGroups(EMPLOYEE_PERMISSION_POLICY_GROUPS);

        // Get all the permissions with the group name 'Employee Documents'
        const isDocumentEmployeeGroup = (eg: (typeof flattenEmployeeGroups)[number]) => eg.groupName === PermissionPolicyGroup.EMPLOYEE_DOCUMENTS;
        const employeeDocumentsPermissions = flattenEmployeeGroups.filter(isDocumentEmployeeGroup);

        // Remove the 'Employee Documents' group from the flattenEmployeeGroups
        flattenEmployeeGroups = flattenEmployeeGroups.filter(eg => !isDocumentEmployeeGroup(eg));

        // Duplicate all policies for each employee folder
        const employeeFoldersGroups = folders
            .filter(folder => folder.folderType === 'EMPLOYEE')
            .map(folder => ({
                folderGroup: employeeDocumentsPermissions.map(group => {
                    return {
                        ...group,
                        // Replace the sub group name with the folder name
                        policySubGroup: {
                            ...group.policySubGroup,
                            subGroupName: getLabelTranslation(folder.name),
                        },
                        // Add resource id to check if the permission is enabled for the folder
                        policySubGroupResourceId: folder.id,
                    };
                }),
            }))
            .flatMap(group => group.folderGroup);

        // Add the duplicated policies to the flattenEmployeeGroups
        flattenEmployeeGroups = [...flattenEmployeeGroups, ...employeeFoldersGroups];

        // Get all the permissions with the group name EMPLOYEE_PROFILE
        const isEmployeeSectionProfileGroup = (eg: (typeof flattenEmployeeGroups)[number]) =>
            eg.policySubGroup.resourceBasedName === ResourceBasedName.EMPLOYEE_SECTION;
        const employeeProfilePermissions = flattenEmployeeGroups.filter(isEmployeeSectionProfileGroup);

        // Remove the 'Employee SECTION' group from the flattenEmployeeGroups
        flattenEmployeeGroups = flattenEmployeeGroups.filter(eg => !isEmployeeSectionProfileGroup(eg));

        // Duplicate all policies for each employee section
        const employeeSectionsGroups = sectionDefinitions
            // Get only the sections that are custom single row or custom multiple rows
            .filter(section => section.type === SectionType.CUSTOM_SINGLE_ROW || section.type === SectionType.CUSTOM_MULTI_ROW)
            .map(section => {
                return employeeProfilePermissions.map(group => {
                    return {
                        ...group,
                        // Replace the sub group name with the section name
                        policySubGroup: {
                            ...group.policySubGroup,
                            subGroupName: getLabelTranslation(section.name),
                        },
                        // Add resource id to check if the permission is enabled for the section
                        policySubGroupResourceId: section.id,
                    };
                });
            })
            .flatMap(group => group);

        // Add the duplicated policies to the flattenEmployeeGroups

        flattenEmployeeGroups = [...flattenEmployeeGroups, ...employeeSectionsGroups];

        // Step 2: Create a flag for each permission in the group
        flattenEmployeeGroups.forEach(group => {
            flags[formatEmployeePermissionPath(group.groupName, group.policySubGroup, group.policyName)] = permissionGroup.groupPolicies.some(g =>
                group.policySubGroupResourceId
                    ? // When policySubGroupResourceId is defined, check if the permission is enabled for the resource
                      g.policy === group.policyName && g.resourceId === group.policySubGroupResourceId
                    : // otherwise, check if the permission is enabled only for the policy name
                      g.policy === group.policyName,
            );
        });

        // Step 1: Flatten the company groups
        let flattenCompanyGroups = flattenPolicyGroups(COMPANY_PERMISSION_POLICY_GROUPS);

        // Get all the permissions with the group name 'Company Documents'
        const isDocumentCompanyGroup = (cg: (typeof flattenCompanyGroups)[number]) => cg.groupName === PermissionPolicyGroup.COMPANY_DOCUMENTS;
        const companyDocumentsPermissions = flattenCompanyGroups.filter(isDocumentCompanyGroup);

        // Remove the 'Company Documents' group from the flattenCompanyGroups
        flattenCompanyGroups = flattenCompanyGroups.filter(cg => !isDocumentCompanyGroup(cg));

        // Duplicate all policies for each company folder
        const companyFoldersGroups = folders
            .filter(folder => folder.folderType === 'COMPANY')
            .map(folder => ({
                folderGroup: companyDocumentsPermissions.map(group => {
                    return {
                        ...group,
                        // Replace the sub group name with the folder name
                        policySubGroup: {
                            ...group.policySubGroup,
                            subGroupName: getLabelTranslation(folder.name),
                        },
                        // Add resource id to check if the permission is enabled for the folder
                        policySubGroupResourceId: folder.id,
                    };
                }),
            }))
            .flatMap(group => group.folderGroup);

        // Add the duplicated policies to the flattenCompanyGroups
        flattenCompanyGroups = [...flattenCompanyGroups, ...companyFoldersGroups];

        // Step 2: Create a flag for each permission in the group
        flattenCompanyGroups.forEach(group => {
            flags[formatCompanyPermissionPath(group.groupName, group.policySubGroup, group.policyName)] = permissionGroup.groupPolicies.some(
                g =>
                    g.policy === group.policyName &&
                    // Useful for dynamic permissions (Folders)
                    (g.resourceId ?? undefined) === (group.policySubGroupResourceId ?? undefined),
            );
        });

        return { ...permissionGroup, flags } as PermissionGroupWithFlags;
    });
};

const formatEmployeePermissionPath = (groupName: string, subGroup: PolicySubGroup, policyName: string): string => {
    const groupTitle = i18next.t('permissions_setting_page.groups', {
        context: groupName,
    });
    const subGroupTitle = getTitle(subGroup);

    return `${i18next.t('permissions_setting_page.employee_level')}/${groupTitle}/${subGroupTitle}/${i18next.t('domain.policy.enum', { context: policyName })}`;
};

const formatCompanyPermissionPath = (groupName: string, subGroup: PolicySubGroup, policyName: string): string => {
    const groupTitle = i18next.t('permissions_setting_page.groups', {
        context: groupName,
    });
    const subGroupTitle = getTitle(subGroup);
    return `${i18next.t('permissions_setting_page.company_level')}/${groupTitle}/${subGroupTitle}/${i18next.t('domain.policy.enum', { context: policyName })}`;
};

const getTitle = (group: PolicySubGroup): string => {
    const { subGroupName, resourceBasedName } = group;
    if (
        resourceBasedName === ResourceBasedName.EMPLOYEE_SECTION ||
        resourceBasedName === ResourceBasedName.EMPLOYEE_DOCUMENTS_SUBGROUP ||
        resourceBasedName === ResourceBasedName.COMPANY_DOCUMENTS_SUBGROUP
    ) {
        // There is no translation for this subGroupName as they are defined by the employee
        return subGroupName;
    }
    // Workaround to handle mess from translations files
    const titleFromSubGroupTranslation =
        i18next.t('permissions_setting_page.subgroups', {
            context: subGroupName ? subGroupName : resourceBasedName,
            defaultValue: '',
        }) ?? undefined;

    const titleFromGroupTranslation =
        i18next.t('permissions_setting_page.groups', {
            context: subGroupName,
            defaultValue: '',
        }) ?? undefined;

    return titleFromSubGroupTranslation || titleFromGroupTranslation || subGroupName;
};

const formatPermissionGroupForTransposing = (permissionGroupsWithFlags: PermissionGroupWithFlags[]): Record<string, string>[] => {
    // Permission groups fields must be string
    return permissionGroupsWithFlags.map(permissionGroup => {
        const flags = Object.keys(permissionGroup.flags).reduce<Record<string, string>>(
            (acc, key) => ({
                ...acc,
                [key]: permissionGroup.flags[key] ? '✓' : '',
            }),

            {} as Record<string, string>,
        );
        return {
            ...flags,
            groupName: permissionGroup.groupName,
            description: permissionGroup.description,
            // Group members & Target members can be a selected employees array or ALL_EMPLOYEES or conditions
            groupMembers: formatFilterType(permissionGroup, 'groupMembers'),
            targetMembers: formatFilterType(permissionGroup, 'targetMembers'),
        };
    });
};

const flattenPolicyGroups = (
    policyGroups: PolicyGroup[],
): {
    groupName: PermissionPolicyGroup;
    policySubGroup: PolicySubGroup;
    policyName: PolicyPermission;
    policySubGroupResourceId?: number;
}[] => {
    return policyGroups.flatMap(group => {
        return group.policySubGroup.flatMap(subGroup => {
            return subGroup.policies.map(p => {
                return {
                    groupName: group.name,
                    policySubGroup: subGroup,
                    policySubGroupResourceId: undefined,
                    policyName: p.name,
                };
            });
        });
    });
};
