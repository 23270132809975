import { SurveyTemplate, SurveyTemplateCreationMutation, SurveyTemplateUpdateMutation } from '@/domain/survey-template/Survey-template.model';
import { surveyTemplateApi } from '@/api/survey-template/SurveyTemplate.api';

export function getSurveyTemplates(): Promise<SurveyTemplate[]> {
    return surveyTemplateApi.getSurveyTemplates();
}

export function createSurveyTemplate(request: SurveyTemplateCreationMutation): Promise<SurveyTemplate> {
    return surveyTemplateApi.createSurveyTemplate(request);
}

export function getSurveyTemplate(surveyId: number): Promise<SurveyTemplate> {
    return surveyTemplateApi.getSurveyTemplate(surveyId);
}

export function deleteSurveyTemplate(surveyId: number): Promise<number> {
    return surveyTemplateApi.deleteSurveyTemplate(surveyId);
}

export function updateSurveyTemplate(surveyId: number, request: SurveyTemplateUpdateMutation): Promise<SurveyTemplate> {
    return surveyTemplateApi.updateSurveyTemplate(surveyId, request);
}

export function cloneSurveyTemplate(surveyId: number): Promise<SurveyTemplate> {
    return surveyTemplateApi.cloneSurveyTemplate(surveyId);
}
