import { SkillCategory, SkillCategorySearch } from '@/domain/skill-category/SkillCategory.model';
import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { getSkillCategory, searchSkillCategories } from '@/domain/skill-category/SkillCategory.service';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleError } from '@/utils/api.util';

export const useSearchSkillCategories = (request: SkillCategorySearch = {}): UseQueryResult<SkillCategory[]> => {
    const [categories, setCategories] = useState<SkillCategory[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCategories = useCallback(async (request: SkillCategorySearch) => {
        try {
            const categories = await searchSkillCategories(request);
            setCategories(categories);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchCategories(request).catch(handleError);
    }, [request]);

    return {
        data: categories,
        setData: setCategories,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchCategories(request),
    };
};

export const useGetSkillCategory = (id: number | undefined): UseQueryResult<SkillCategory> => {
    const [category, setCategory] = useState<SkillCategory>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCategory = useCallback(async (id: number | undefined) => {
        if (!id) {
            setIsLoading(false);
            return;
        }

        try {
            const data = await getSkillCategory(id);
            setCategory(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchCategory(id).catch(handleError);
    }, [fetchCategory, id]);

    return {
        data: category,
        setData: setCategory,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchCategory(id),
    };
};
