import { Alert, FormControlLabel, Stack, Typography } from '@mui/material';

import { DurationTimePicker } from '@/components/duration-time-picker/DurationTimePicker';
import { UnitType } from '@/domain/date/Date.model';
import { AllowanceType, LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import { formatDurationInHours } from '@/utils/datetime.util';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { MultipleDatePicker } from '@/components/multi-date-picker/MultipleDatePicker';
import { LeaveRequestPreview } from '@/domain/leave-request/LeaveRequest.model';
import { HoursAvailableComponent } from '@/page/leave/leave-request-dialog/HoursAvailableComponent';
import { LeaveRequestFormValues } from '@/page/leave/leave-request-dialog/LeaveRequestForm.schema';
import { Controller, useFormContext } from 'react-hook-form';

type HourlyLeaveTypeDetailsProps = {
    isEdit: boolean;
    availableAmountInMinutes?: number;
    remainingAmountInMinutes?: number;
    previewResult?: LeaveRequestPreview;
};

export const HourlyLeaveTypeDetails: FC<PropsWithChildren<HourlyLeaveTypeDetailsProps>> = ({
    isEdit,
    availableAmountInMinutes = 0,
    remainingAmountInMinutes = 0,
    children,
    previewResult,
}) => {
    const { t } = useTranslation();

    const { control, watch, setValue } = useFormContext<LeaveRequestFormValues>();

    const leaveType = watch('leaveType');
    const startTimeInMinutes = watch('startTimeInMinutes');
    const endTimeInMinutes = watch('endTimeInMinutes');
    const isMultipleDays = watch('isMultipleDays');

    const getAvailableLeaveAmount = () => {
        return formatDurationInHours(availableAmountInMinutes);
    };

    const getNewRemainingLeaveAmount = () => {
        return formatDurationInHours(remainingAmountInMinutes);
    };

    const displayHoursAvailableComponent =
        leaveType.allowanceType === AllowanceType.NOT_UNLIMITED || leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION;

    const isLeaveRequestOnWorkingDays = (previewResult: LeaveRequestPreview): boolean => {
        //we need to validate the start/end time is the same between the preview and the leave request because there might have some discrepancy
        return previewResult.totalAmountInMinutes === 0 && (endTimeInMinutes ?? 0) - (startTimeInMinutes ?? 0) > 0;
    };

    const shouldDisplayMaxDurationWarning =
        !!leaveType?.maxDurationInMinutes && previewResult?.previews?.some(preview => (leaveType?.maxDurationInMinutes ?? 0) < (preview.amountInMinutes ?? 0));

    const displayDuration = startTimeInMinutes !== undefined && endTimeInMinutes !== undefined && startTimeInMinutes + endTimeInMinutes > 0;

    return (
        <Stack direction='column' spacing={2}>
            {leaveType.employeeRequestMessage && <Typography variant='body2'>{leaveType.employeeRequestMessage}</Typography>}
            {displayHoursAvailableComponent && (
                <HoursAvailableComponent
                    available={getAvailableLeaveAmount()}
                    remaining={getNewRemainingLeaveAmount()}
                    remainingAmountInMinutes={remainingAmountInMinutes}
                />
            )}

            {isMultipleDays ? <MultipleDays /> : <StartDate />}

            {!isEdit && (
                <FormControlLabel
                    label={t('request_leave_dialog.multiple_days')}
                    labelPlacement='end'
                    sx={{ mr: 0 }}
                    control={<FieldSwitch control={control} name='isMultipleDays' />}
                />
            )}

            <Stack>
                <Typography variant='body2'>{t('request_overtime_dialog.start')}</Typography>
                <Controller
                    name={'startTimeInMinutes'}
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                        <DurationTimePicker
                            {...restField}
                            endTimeInMinutes={endTimeInMinutes ?? 0}
                            startTimeInMinutes={startTimeInMinutes ?? 0}
                            requestIncrement={leaveType.requestIncrement}
                            totalMinutes={startTimeInMinutes ?? 0}
                            endTime={false}
                            onChange={totalMinutes => {
                                const updatedEndTimeInMinutes = totalMinutes > (endTimeInMinutes ?? 0) ? totalMinutes : endTimeInMinutes;
                                //first update the endTimeInMinutes because on every onChange there is validations being triggered and this sends the wrong data to the BE
                                setValue('endTimeInMinutes', updatedEndTimeInMinutes);
                                onChange(totalMinutes);
                            }}
                        />
                    )}
                />
            </Stack>
            <Stack>
                <Typography variant='body2'>{t('request_overtime_dialog.end')}</Typography>
                <Controller
                    name={'endTimeInMinutes'}
                    control={control}
                    render={({ field: { onChange, ...restField } }) => (
                        <DurationTimePicker
                            {...restField}
                            endTimeInMinutes={endTimeInMinutes ?? 0}
                            startTimeInMinutes={startTimeInMinutes ?? 0}
                            requestIncrement={leaveType.requestIncrement}
                            totalMinutes={endTimeInMinutes ?? 0}
                            endTime={true}
                            onChange={totalMinutes => {
                                const updatedStartTimeInMinutes = (startTimeInMinutes ?? 0) > totalMinutes ? totalMinutes : startTimeInMinutes;
                                //first update the startTimeInMinutes because on every onChange there is validations being triggered and this sends the wrong data to the BE
                                setValue('startTimeInMinutes', updatedStartTimeInMinutes);
                                onChange(totalMinutes);
                            }}
                        />
                    )}
                />
            </Stack>
            {displayDuration ? (
                <Typography variant='body2'>
                    {t('request_leave_dialog.hourly.you_will_use', {
                        duration: formatDurationInHours(endTimeInMinutes - startTimeInMinutes),
                    })}
                </Typography>
            ) : undefined}

            {shouldDisplayMaxDurationWarning && (
                <Alert severity='error' elevation={0}>
                    <Typography variant='body2'>
                        {t('request_leave_dialog.messages.you_can_not_request', {
                            context: UnitType.HOURS,
                            hours: (leaveType?.maxDurationInMinutes ?? 0) / 60,
                        })}
                    </Typography>
                </Alert>
            )}

            {previewResult && isLeaveRequestOnWorkingDays(previewResult) && (
                <Alert severity='warning' elevation={0}>
                    <Typography variant='body2'>{t('request_leave_dialog.messages.you_can_only_submit_request_on_working_days')}</Typography>
                </Alert>
            )}

            {children}
        </Stack>
    );
};

const MultipleDays: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<LeaveRequestFormValues>();

    return (
        <Controller
            name={'dates'}
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
                <FormControlLabel
                    label={t('request_leave_dialog.hourly.dates')}
                    labelPlacement='top'
                    style={{ width: '100%' }}
                    control={<MultipleDatePicker selectedDays={value} onClose={onChange} {...field} />}
                />
            )}
        />
    );
};

const StartDate: FC = () => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext<LeaveRequestFormValues>();

    return (
        <FormControlLabel
            label={t('request_leave_dialog.hourly.day')}
            style={{ width: '100%' }}
            control={
                <FieldLocalDate
                    name='startDate'
                    control={control}
                    mobileOnly
                    onChange={newValue => {
                        if (newValue) {
                            setValue('endDate', newValue);
                        }
                    }}
                />
            }
        />
    );
};
