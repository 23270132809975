import { AlertColor } from '@mui/material';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { store } from '@/stores/store';

export const showSnackbar = (message: string, severity: AlertColor): void => {
    store.dispatch({
        type: UiActionType.SNACKBAR_CHANGED,
        snackbar: {
            open: true,
            message: message,
            severity: severity,
        },
    });
};

const snackbar = {
    showSnackbar,
};
export default snackbar;
