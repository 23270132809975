import { RouteObject } from 'react-router';
import { ReportsPage } from './ReportsPage';
import { PageContainer } from '@/routes/PageContainer';
import { ReportPage } from '@/page/report/ReportPage';
import { RouterBoundary } from '@/routes/RouterBoundary';

export const reportRoutes: RouteObject[] = [
    {
        path: 'reports',
        children: [
            {
                path: '',
                element: (
                    <PageContainer breadCrumb={[{ nameKey: 'pages.reports' }]}>
                        <ReportsPage />
                    </PageContainer>
                ),
                errorElement: <RouterBoundary />,
            },
            {
                path: ':reportType/:reportId',
                element: (
                    <PageContainer breadCrumb={[{ nameKey: 'pages.reports' }]}>
                        <ReportPage />
                    </PageContainer>
                ),
                errorElement: <RouterBoundary />,
            },
        ],
    },
];
