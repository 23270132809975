import { ChipOwnProps, Components, CSSInterpolation, Palette, PaletteColor, Theme } from '@mui/material';
import { CommonProps } from '@mui/material/OverridableComponent';
import { MouseEventHandler } from 'react';

export const MuiChip: Components<Omit<Theme, 'components'>>['MuiChip'] = {
    styleOverrides: {
        root: ({ ownerState, theme }) => {
            const commonStyle: CSSInterpolation = {
                height: '28px',
                width: 'fit-content',
                padding: '4px 8px',
                ...theme.typography.body1,
            };

            if (ownerState.variant === 'filled') {
                return { ...commonStyle, ...getFilled({ ownerState, theme }) };
            }
            if (ownerState.variant === 'hight') {
                return {
                    ...commonStyle,
                    borderRadius: theme.shape.borderRadius,
                    color: theme.palette.common.white,
                    backgroundColor: ownerState.color === 'default' ? theme.palette.grey[700] : undefined,
                };
            }

            return {
                ...commonStyle,
                borderRadius: theme.shape.borderRadius,
            };
        },
    },
};

const getFilled = ({
    ownerState,
    theme,
}: {
    ownerState: ChipOwnProps &
        CommonProps & {
            onClick?: MouseEventHandler;
        };
    theme: Omit<Theme, 'components'>;
}) => {
    const color = theme.palette[ownerState.color as keyof Palette] as PaletteColor;

    const hoverStyle = {
        backgroundColor: ownerState.color === 'default' ? theme.palette.grey[100] : color?.light,
        color: ownerState.color === 'default' ? theme.palette.grey[700] : color?.dark,
    };

    return {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: ownerState.color === 'default' ? theme.palette.grey[100] : color?.light,
        color: ownerState.color === 'default' ? theme.palette.grey[700] : color?.dark,
        '&:hover': ownerState.clickable || ownerState.onClick ? hoverStyle : undefined,
        '& .MuiChip-deleteIcon': {
            color: ownerState.color === 'default' ? theme.palette.grey[700] : color?.dark,
            '&:hover': ownerState.clickable || ownerState.onClick ? hoverStyle : undefined,
        },
    };
};
