import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { DefaultRealmNotification } from '@/domain/default-realm-notification/DefaultRealmNotification.model';
import { getDefaultRealmNotification } from '@/domain/default-realm-notification/DefaultRealmNotification.service';
import { handleError } from '@/utils/api.util';

export const useGetDefaultRealmNotification = (): UseQueryResult<DefaultRealmNotification> => {
    const [defaultRealmNotification, setDefaultRealmNotification] = useState<DefaultRealmNotification>();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<unknown>();

    const fetchDefaultRealmNotification = useCallback(async () => {
        try {
            const defaultRealmNotificationData = await getDefaultRealmNotification();
            setDefaultRealmNotification(defaultRealmNotificationData);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchDefaultRealmNotification().catch(handleError);
    }, [fetchDefaultRealmNotification]);

    return {
        data: defaultRealmNotification,
        setData: setDefaultRealmNotification,
        isLoading,
        error,
        isError: !!error,
        refetch: fetchDefaultRealmNotification,
    };
};
