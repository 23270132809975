export const getRatingBackgroundOpacity = <
    T extends {
        score?: number;
    },
>(
    ratingItem: T,
    ratingItems: T[],
): number => {
    // Sort rating items by score in ascending order
    const sortedRatingItems = [...ratingItems].sort((a, b) => (a?.score && b?.score ? a.score - b.score : 0));

    // Determine the index of the current item
    const currentIndex = sortedRatingItems.findIndex(item => item.score === ratingItem.score);

    // Determine the total number of rating items
    const totalItems = ratingItems.length;

    // Calculate the opacity step between each item
    const minOpacity = 0.1;
    const maxOpacity = 1.0;
    const step = (maxOpacity - minOpacity) / (totalItems - 1);

    // Calculate the opacity for the current item
    return minOpacity + currentIndex * step;
};

export const getRatingBackground = <
    T extends {
        score?: number;
    },
>(
    ratingItem: T,
    ratingItems: T[] | undefined,
): string => {
    const COLOR_PRIMARY_DARK = 'rgba(14, 180, 180';
    const opacity = ratingItems ? getRatingBackgroundOpacity(ratingItem, ratingItems) : 0;
    return `${COLOR_PRIMARY_DARK}, ${opacity})`;
};
