import { handleError } from '@/utils/api.util';
import {
    formatToLocalDate,
    getCurrentLocalDate,
    getTimeFormatFromDate,
    getTodayDate,
    isAfterTime,
    isValidTime,
    setTime,
    subDays,
    subHours,
} from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CorrectClockInFormSchema, getCorrectClockInFormSchema } from '@/page/employee-timesheet/correct-clock-in-dialog/CorrectClockInForm.schema';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldTime } from '@/components/form/field-time/FieldTime';
import { correctClockIn } from '@/domain/timesheet/Timesheet.service';
import { ClockInOutCreationMutation } from '@/domain/timesheet/Timesheet.model';

type CorrectClockInDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    referenceDate: LocalDate;
    employeeId: number;
};

const MINIMUM_TIME_DURATION_HOURS = 11;

export const CorrectClockInDialog: FC<CorrectClockInDialogProps> = ({ open, onClose, onSave, referenceDate, employeeId }) => {
    const { t } = useTranslation();

    const { control, handleSubmit, setValue, getValues } = useForm<CorrectClockInFormSchema>({
        resolver: yupResolver(getCorrectClockInFormSchema()),
        defaultValues: getDefaultValues(referenceDate),
    });

    const handleCorrectClockInCreation = async (data: CorrectClockInFormSchema) => {
        const mutation: ClockInOutCreationMutation = {
            employeeId,
            dateTime: setTime(data.referenceDate, data.startTime),
        };
        try {
            await correctClockIn(mutation);
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const updateReferenceDate = (startTime: LocalTime) => {
        const nowTime = getTimeFormatFromDate(getTodayDate());

        if (isAfterTime(startTime, nowTime)) {
            const minimumTime = subHours(getTodayDate(), MINIMUM_TIME_DURATION_HOURS);
            const referenceDate = getValues('referenceDate');
            const yesterday = formatToLocalDate(subDays(referenceDate, 1));
            const date = setTime(yesterday, startTime);
            const isAfterMinimumAllowedTime = date > minimumTime;
            if (isAfterMinimumAllowedTime) {
                setValue('referenceDate', yesterday);
            }
        } else {
            // If the start time is not after the current time, set the reference date to today
            setValue('referenceDate', getCurrentLocalDate());
        }
    };

    return (
        <DialogWrapper open={open} onClose={() => onClose()} header={t('correct_clock_in_dialog.title')}>
            <DialogContent>
                <Stack gap={2}>
                    <FormControlLabel
                        sx={{ width: '100%' }}
                        label={t('correct_clock_in_dialog.reference_date')}
                        control={<FieldLocalDate name='referenceDate' control={control} disabled />}
                    />
                    <FormControlLabel
                        label={t('correct_clock_in_dialog.time')}
                        aria-label={`startTime`}
                        control={
                            <FieldTime
                                name={`startTime`}
                                control={control}
                                timePickerProps={{
                                    fullWidth: true,
                                }}
                                onChange={startTime => {
                                    if (!isValidTime(startTime)) {
                                        return;
                                    }
                                    updateReferenceDate(startTime);
                                }}
                            />
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button fullWidth onClick={() => handleSubmit(handleCorrectClockInCreation, console.error)()}>
                    {t('general.update')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getDefaultValues = (referenceDate: LocalDate): CorrectClockInFormSchema => {
    return {
        referenceDate: referenceDate,
        startTime: getTimeFormatFromDate(getTodayDate()),
    };
};
