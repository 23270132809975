import { Shadows } from '@mui/material';

export const shadows: Shadows = [
    'none',
    '0px 2px 4px rgba(0, 0, 0, 0.08)',
    '0px 2px 8px rgba(0, 0, 0, 0.12)',
    '0px 4px 12px rgba(0, 0, 0, 0.16)',
    '0px 8px 16px rgba(0, 0, 0, 0.12)',
    '0px 8px 32px rgba(0, 0, 0, 0.16)',
    // We don't use more than 5 shadows
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
];
