import { Employee } from '@/domain/employee/Employee.model';

export enum EmployeeProfileActionType {
    EMPLOYEE_PROFILE_LOADED = 'EMPLOYEE_PROFILE_LOADED',
    REFETCH_EMPLOYEE_PROFILE = 'REFETCH_EMPLOYEE_PROFILE',
}

export interface EmployeeProfileLoaded {
    type: EmployeeProfileActionType.EMPLOYEE_PROFILE_LOADED;
    employee: Employee;
}

export interface RefetchEmployeeProfile {
    type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE;
    refetchEmployee: boolean;
}

export type EmployeeProfileActions = EmployeeProfileLoaded | RefetchEmployeeProfile;
