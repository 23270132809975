import { ObjectiveSetting } from '@/domain/objective-setting/ObjectiveSetting.model';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { getObjectiveSetting } from '@/domain/objective-setting/ObjectiveSetting.service';

export const useGetObjectiveSetting = (): UseQueryResult<ObjectiveSetting> => {
    const [objectiveSetting, setObjectiveSetting] = useState<ObjectiveSetting>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchObjectiveSetting = useCallback(async () => {
        try {
            const objectiveSetting = await getObjectiveSetting();
            setObjectiveSetting(objectiveSetting);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchObjectiveSetting().catch(handleError);
    }, [fetchObjectiveSetting]);

    return {
        data: objectiveSetting,
        setData: setObjectiveSetting,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchObjectiveSetting(),
    };
};
