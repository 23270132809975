import { FC, PropsWithChildren } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { EmployeeReviewFeedbackScorePDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackScorePDF';
import { useTheme } from '@mui/material';
import {
    EmployeeReviewFeedbackQuestionFormType,
    EmployeeReviewFeedbackSkillFormType,
    EmployeeReviewFeedbackSkillQuestionFormType,
    RatingFormType,
} from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { EmployeeReviewFeedbackRatingPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackRatingPDF';
import { getLabelTranslation } from '@/utils/language.util';
import { Label } from '@/domain/label/Label.model';
import { htmlToPlainText } from '@/utils/strings.util';

type EmployeeReviewFeedbackQuestionPDFProps<
    T extends
        | EmployeeReviewFeedbackQuestionFormType
        | EmployeeReviewFeedbackSkillQuestionFormType
        | (EmployeeReviewFeedbackSkillFormType & {
              title: string | Label;
          }),
> = {
    questionItem: T | undefined;
    rating: RatingFormType | undefined;
};

export const EmployeeReviewFeedbackQuestionPDF: FC<
    PropsWithChildren<
        EmployeeReviewFeedbackQuestionPDFProps<
            | EmployeeReviewFeedbackQuestionFormType
            | EmployeeReviewFeedbackSkillQuestionFormType
            | (EmployeeReviewFeedbackSkillFormType & {
                  title: string | Label;
              })
        >
    >
> = ({ questionItem, rating, children }) => {
    const theme = useTheme();

    if (!questionItem) {
        return <></>;
    }

    return (
        <View
            style={{
                display: 'flex',
                backgroundColor: theme.palette.grey[50],
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                gap: theme.spacing(1),
            }}
        >
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: theme.spacing(1),
                }}
            >
                <Text
                    style={{
                        fontSize: 14,
                        fontWeight: 'bold',
                    }}
                >
                    {typeof questionItem.title === 'string' ? questionItem.title : getLabelTranslation(questionItem.title as Label)}
                </Text>
                {'instruction' in questionItem && (
                    <Text
                        style={{
                            fontSize: 12,
                        }}
                    >
                        {htmlToPlainText(getLabelTranslation(questionItem.instruction as Label))}
                    </Text>
                )}

                {children}
                {rating && questionItem.score && <EmployeeReviewFeedbackScorePDF rating={rating} score={questionItem.score} />}
                {/* When there is no score, display rating labels, useful to print the review and fill it */}
                {!questionItem.score && rating && <EmployeeReviewFeedbackRatingPDF rating={rating} />}
                <View
                    style={{
                        minHeight: 80,
                        border: '1px black solid',
                        borderColor: theme.palette.common.black,
                        borderRadius: theme.shape.borderRadius,
                        padding: theme.spacing(1),
                        width: '100%',
                    }}
                    wrap={false}
                >
                    <Text>{questionItem.comment}</Text>
                </View>
            </View>
        </View>
    );
};
