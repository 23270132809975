import { useState } from 'react';

const DEFAULT_DEBOUNCE_DELAY = 500;

function useDebounce<F extends (...args: Parameters<F>) => ReturnType<F>>(): (func: F, delay?: number) => void {
    const [timeoutId, setTimeoutId] = useState<number>();
    return (debouncedFunction: F, delay?: number) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setTimeoutId(setTimeout(debouncedFunction, delay ?? DEFAULT_DEBOUNCE_DELAY));
    };
}

export default useDebounce;
