import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { JobFamily } from '@/domain/job-family/JobFamily.model';
import { getJobFamilies } from '@/domain/job-family/JobFamily.service';

export const useGetJobFamilies = (): UseQueryResult<JobFamily[]> => {
    const [jobFamilies, setJobFamilies] = useState<JobFamily[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchJobFamilies = useCallback(async () => {
        try {
            const jobFamilies = await getJobFamilies();
            setJobFamilies(jobFamilies);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchJobFamilies().catch(handleError);
    }, [fetchJobFamilies]);

    return {
        data: jobFamilies,
        setData: setJobFamilies,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchJobFamilies(),
    };
};
