import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import {
    EmployeeNotification,
    EmployeeNotificationSearchRequest,
    EmployeeNotificationUpdateMutation,
} from '@/domain/employee-notification/EmployeeNotification.model';

export type EmployeeNotificationDTO = EmployeeNotification;
export type EmployeeNotificationUpdateMutationDTO = EmployeeNotificationUpdateMutation;
export type EmployeeNotificationSearchRequestDTO = EmployeeNotificationSearchRequest;

const searchEmployeeNotifications = async (searchRequest: EmployeeNotificationSearchRequest): Promise<EmployeeNotification[]> => {
    const url = API_BASE_URL + `/employee-notifications/search`;
    return (await client.post<EmployeeNotificationDTO[], AxiosResponse<EmployeeNotificationDTO[]>, EmployeeNotificationSearchRequestDTO>(url, searchRequest))
        .data;
};

const getOwnEmployeeNotifications = async (): Promise<EmployeeNotification> => {
    const url = API_BASE_URL + `/employee-notifications/me`;
    return (await client.get<EmployeeNotificationDTO, AxiosResponse<EmployeeNotificationDTO>>(url)).data;
};

const updateOwnEmployeeNotifications = async (mutation: EmployeeNotificationUpdateMutation): Promise<EmployeeNotification> => {
    const url = API_BASE_URL + `/employee-notifications/me`;
    return (await client.put<EmployeeNotificationDTO, AxiosResponse<EmployeeNotificationDTO>, EmployeeNotificationUpdateMutationDTO>(url, mutation)).data;
};

const updateEmployeesNotifications = async (mutations: EmployeeNotificationUpdateMutation[]): Promise<EmployeeNotification[]> => {
    const url = API_BASE_URL + `/employee-notifications`;
    return (await client.put<EmployeeNotificationDTO[], AxiosResponse<EmployeeNotificationDTO[]>, EmployeeNotificationUpdateMutationDTO[]>(url, mutations))
        .data;
};

export const employeeNotificationAPI = {
    searchEmployeeNotifications,
    getOwnEmployeeNotifications,
    updateOwnEmployeeNotifications,
    updateEmployeesNotifications,
};
