import { DateRangePickerViewType } from '@/components/date-range-picker/DateRangePicker';
import {
    addDays,
    addMonths,
    addYears,
    endOfMonth,
    endOfYear,
    formatToLocalDate,
    getCurrentLocalDate,
    getEndOfMonth,
    getEndOfWeek,
    getEndOfYear,
    getStartOfMonth,
    getStartOfWeek,
    getStartOfYear,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subDays,
    subDaysAndFormat,
    subMonths,
    subYears,
    toDate,
} from '@/utils/datetime.util';

export const WEEK_DURATION = 7;
export const calculateUpdatedDateRange = (
    selectedDate: LocalDate,
    direction: 'prev' | 'next',
    calendarViewType: DateRangePickerViewType,
): [LocalDate, LocalDate] => {
    let start: Date;
    let end: Date;
    const isNext = direction === 'next';
    const addOrSub = isNext ? addDays : subDays;
    const addOrSubMonths = isNext ? addMonths : subMonths;
    const addOrSubYears = isNext ? addYears : subYears;

    const currentDate = toDate(selectedDate);

    switch (calendarViewType) {
        case 'YEAR':
            start = addOrSubYears(startOfYear(currentDate), 1);
            end = endOfYear(start);
            break;
        case 'MONTH':
            start = addOrSubMonths(startOfMonth(currentDate), 1);
            end = endOfMonth(start);
            break;
        case 'FOUR_WEEKS':
            start = addOrSub(startOfWeek(currentDate, { weekStartsOn: 1 }), WEEK_DURATION * 4);
            end = addDays(start, WEEK_DURATION * 4 - 1);
            break;
        case 'TWO_WEEKS':
            start = addOrSub(startOfWeek(currentDate, { weekStartsOn: 1 }), WEEK_DURATION * 2);
            end = addDays(start, WEEK_DURATION * 2 - 1);
            break;
        case 'WEEK':
            start = addOrSub(startOfWeek(currentDate, { weekStartsOn: 1 }), WEEK_DURATION);
            end = addDays(start, WEEK_DURATION - 1);
            break;
        default:
            start = addOrSub(currentDate, 1);
            end = start;
            break;
    }

    return [formatToLocalDate(start), formatToLocalDate(end)];
};

export const getDateRange = (viewType: DateRangePickerViewType, currentDate: LocalDate = getCurrentLocalDate()): [LocalDate, LocalDate] => {
    switch (viewType) {
        case 'YEAR':
            return [getStartOfYear(currentDate), getEndOfYear(currentDate)];
        case 'MONTH':
            return [getStartOfMonth(currentDate), getEndOfMonth(currentDate)];
        case 'FOUR_WEEKS': {
            const start = getStartOfWeek(currentDate, { weekStartsOn: 1 });
            const end = subDaysAndFormat(addDays(start, WEEK_DURATION * 4), 1);
            return [start, end];
        }
        case 'TWO_WEEKS': {
            const start = getStartOfWeek(currentDate, { weekStartsOn: 1 });
            const end = subDaysAndFormat(addDays(start, WEEK_DURATION * 2), 1);
            return [start, end];
        }
        // break;
        case 'WEEK':
            return [getStartOfWeek(currentDate, { weekStartsOn: 1 }), getEndOfWeek(currentDate, { weekStartsOn: 1 })];
        case 'DAY':
        case 'RANGE':
            return [currentDate, currentDate];
    }
};
