import { ThirdParty, ThirdPartyIntegrationLog } from '@/domain/third-party/ThirdParty.model';
import { getThirdParty, getThirdPartyIntegrationLogs, searchThirdParties } from '@/domain/third-party/ThirdParty.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetThirdParties = (
    {
        enabled,
    }: {
        enabled: boolean;
    } = { enabled: true },
): UseQueryResult<ThirdParty[]> => {
    const [thirdParties, setThirdParties] = useState<ThirdParty[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchThirdParties = useCallback(async () => {
        if (!enabled) {
            return;
        }
        try {
            const thirdParties = await searchThirdParties();
            setThirdParties(thirdParties);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, [enabled]);

    useEffect(() => {
        fetchThirdParties().catch(handleError);
    }, [fetchThirdParties]);

    return {
        data: thirdParties,
        setData: setThirdParties,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchThirdParties(),
    };
};

export const useGetThirdParty = (thirdPartyId: number | undefined): UseQueryResult<ThirdParty> => {
    const [thirdParty, setThirdParty] = useState<ThirdParty>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchThirdParty = useCallback(async () => {
        if (thirdPartyId) {
            try {
                const thirdParty = await getThirdParty(thirdPartyId);
                setThirdParty(thirdParty);
            } catch (error) {
                setError(error);
            }
        }

        setIsLoading(false);
    }, [thirdPartyId]);

    useEffect(() => {
        fetchThirdParty().catch(handleError);
    }, [fetchThirdParty]);

    return {
        data: thirdParty,
        setData: setThirdParty,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchThirdParty(),
    };
};

export const useGetThirdPartyIntegrationLogs = (thirdPartyIntegrationId: number | undefined): UseQueryResult<ThirdPartyIntegrationLog[]> => {
    const [thirdPartyIntegrationLogs, setThirdPartyIntegrationLogs] = useState<ThirdPartyIntegrationLog[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchThirdPartyIntegrationLogs = useCallback(async () => {
        if (thirdPartyIntegrationId) {
            try {
                const thirdPartyIntegrationLogs = await getThirdPartyIntegrationLogs(thirdPartyIntegrationId);
                setThirdPartyIntegrationLogs(thirdPartyIntegrationLogs);
            } catch (error) {
                setError(error);
            }

            setIsLoading(false);
        }
    }, [thirdPartyIntegrationId]);

    useEffect(() => {
        fetchThirdPartyIntegrationLogs().catch(handleError);
    }, [fetchThirdPartyIntegrationLogs]);

    return {
        data: thirdPartyIntegrationLogs,
        setData: setThirdPartyIntegrationLogs,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchThirdPartyIntegrationLogs(),
    };
};
