import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { InputNumber } from '@/components/form/field-number/InputNumber';
import { FieldText } from '@/components/form/field-text/FieldText';
import { FieldTime } from '@/components/form/field-time/FieldTime';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { hoursMinutesSchema } from '@/utils/datetime.schema';
import { formatDurationInTime, getDurationFromTime, getHoursMinutesFromMinutes, getMinutesFromHoursMinutes, HoursMinutes } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, formHelperTextClasses, InputAdornment, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import i18next from 'i18next';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    onSave: (weekly: WeeklyWorkingTimeFormValues) => void;
    onClose: () => void;
    /**
     * @property defaultWeeklyWorkingTime if undefined, it means we are creating a new one
     */
    defaultWeeklyWorkingTime?: WeeklyWorkingTime;
};

const schema = yup
    .object()
    .shape({
        name: yup.string().required(),
        weeklyAmountWorked: hoursMinutesSchema.required().test({
            message: i18next.t('general.validations.required'),
            test: (value: HoursMinutes) => getMinutesFromHoursMinutes(value) > 0,
        }),
        endTime: yup.string<LocalTime>().required(),
        startTime: yup.string<LocalTime>().required(),
        breakStartTime: yup.string<LocalTime>().required(),
        breakDuration: yup.number().required(),
    })
    .required();

export type WeeklyWorkingTimeFormValues = yup.InferType<typeof schema>;

export const WeeklyWorkingTimeDialog: FC<Props> = ({ onSave, onClose, defaultWeeklyWorkingTime }) => {
    const isEdit = !!defaultWeeklyWorkingTime?.id;
    const { t } = useTranslation();

    const { handleSubmit, control, getValues, setValue } = useForm<WeeklyWorkingTimeFormValues>({
        resolver: yupResolver(schema),
        defaultValues: convertToFormValues(defaultWeeklyWorkingTime) ?? {
            name: '',
            weeklyAmountWorked: { hours: undefined, minutes: undefined },
            breakStartTime: '12:00',
            breakDuration: 60,
            startTime: '08:30',
            endTime: '17:30',
        },
    });

    const updateEndTime = (startTime: LocalTime, breakDuration: number) => {
        const startTimeInMinutes = getDurationFromTime(startTime);
        const { weeklyAmountWorked } = getValues();
        const weeklyAmountWorkedInMinutes = getMinutesFromHoursMinutes(weeklyAmountWorked);
        const endTimeInMinutes = startTimeInMinutes + Number(breakDuration) + weeklyAmountWorkedInMinutes / 5;

        if (startTimeInMinutes < endTimeInMinutes) {
            setValue('endTime', formatDurationInTime(endTimeInMinutes));
        } else {
            setValue('endTime', startTime);
        }
    };

    const keyPrefix = 'settings_time_management.weekly_working_times.';

    return (
        <DialogWrapper
            maxWidth='sm'
            open={true}
            onClose={onClose}
            header={isEdit ? t(keyPrefix + 'update_dialog_title') : t(keyPrefix + 'create_dialog_title')}
        >
            <Stack gap={2} component={DialogContent}>
                <FormControlLabel label={t(keyPrefix + 'fields.name')} control={<FieldText name={`name`} control={control} fullWidth />} />

                <FormControlLabel
                    label={t(keyPrefix + 'fields.weeklyAmountWorked')}
                    control={
                        <Controller
                            name={'weeklyAmountWorked'}
                            control={control}
                            render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                <Stack direction={'row'} alignItems={'flex-start'} gap={0.5}>
                                    <InputNumber
                                        {...restField}
                                        value={value.hours}
                                        onChange={hours => {
                                            const { weeklyAmountWorked } = getValues();
                                            onChange({ hours: hours ?? 0, minutes: weeklyAmountWorked.minutes });
                                        }}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        error={!!error}
                                        helperText={error?.message}
                                        sx={{
                                            width: '70px',
                                            [`& .${formHelperTextClasses.root}`]: {
                                                whiteSpace: 'nowrap',
                                            },
                                        }}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position='end'>h</InputAdornment>,
                                            },
                                        }}
                                    />
                                    <InputNumber
                                        {...restField}
                                        value={value.minutes}
                                        onChange={minutes => {
                                            const { weeklyAmountWorked } = getValues();
                                            onChange({ hours: weeklyAmountWorked.hours, minutes: minutes && minutes >= 0 ? minutes : 0 });
                                        }}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        error={!!error}
                                        sx={{ width: '70px' }}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position='end'>m</InputAdornment>,
                                            },
                                        }}
                                    />
                                </Stack>
                            )}
                        />
                    }
                />

                <Typography variant='h2'>{t(keyPrefix + 'base_schedule')}</Typography>
                <Stack direction='row' gap={2} alignItems='flex-start'>
                    <FormControlLabel
                        label={t(keyPrefix + 'fields.startTime')}
                        control={
                            <FieldTime
                                name={'startTime'}
                                control={control}
                                onChange={newStartTime => {
                                    if (newStartTime) {
                                        const { breakDuration } = getValues();
                                        updateEndTime(newStartTime, breakDuration);
                                    }
                                }}
                            />
                        }
                    />

                    <FormControlLabel label={t(keyPrefix + 'fields.endTime')} control={<FieldTime name={'endTime'} control={control} />} />

                    <FormControlLabel label={t(keyPrefix + 'fields.breakStartTime')} control={<FieldTime name={'breakStartTime'} control={control} />} />

                    <FormControlLabel
                        label={t(keyPrefix + 'fields.breakTime')}
                        control={
                            <FieldNumber
                                name='breakDuration'
                                control={control}
                                onChange={newBreakDuration => {
                                    if (newBreakDuration) {
                                        const { startTime } = getValues();
                                        updateEndTime(startTime, newBreakDuration);
                                    }
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
            <DialogActions>
                <Button fullWidth onClick={handleSubmit(onSave, console.error)}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const convertToFormValues = (weekly: WeeklyWorkingTime | undefined): WeeklyWorkingTimeFormValues | undefined => {
    if (!weekly) {
        return undefined;
    }
    return {
        name: weekly.name,
        weeklyAmountWorked: getHoursMinutesFromMinutes(weekly.weeklyAmountWorked),
        breakStartTime: weekly.breakStartTime,
        breakDuration: weekly.breakDuration,
        startTime: weekly.startTime,
        endTime: weekly.endTime,
    };
};
