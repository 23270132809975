import { API_BASE_URL, buildImportFormData, client } from '@/api/common';
import { Calendar, CalendarDay, CalendarDayType, CalendarMutation, CalendarTemplate } from '@/domain/calendar/Calendar.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { AxiosResponse } from 'axios';

export type CalendarDTO = Overwrite<
    Calendar,
    {
        days?: CalendarDayDTO[];
    }
>;

export type CalendarDayDTO = CalendarDay;

const getCalendars = async (): Promise<Calendar[]> => {
    const url = API_BASE_URL + '/calendars';
    return (await client.get<Calendar[]>(url)).data;
};

const getCalendarById = async (calendarId: number): Promise<Calendar> => {
    const url = API_BASE_URL + `/calendars/${calendarId}`;
    return (await client.get<Calendar>(url)).data;
};

const createCalendar = async (mutation: CalendarMutation): Promise<Calendar> => {
    const url = API_BASE_URL + '/calendars';

    const data = (await client.post<CalendarDTO, AxiosResponse<CalendarDTO>, CalendarMutationDTO>(url, mutation)).data;

    return {
        ...data,
        days: data.days ?? [],
    };
};

const updateCalendar = async (calendarId: number, mutation: CalendarMutation): Promise<Calendar> => {
    const url = API_BASE_URL + `/calendars/${calendarId}`;

    return (await client.put<Calendar, AxiosResponse<Calendar>, CalendarMutationDTO>(url, mutation)).data;
};

const deleteCalendar = (calendarId: number): Promise<void> => {
    const url = API_BASE_URL + `/calendars/${calendarId}`;
    return client.delete(url);
};

const importCalendarDays = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(API_BASE_URL + `/calendars/import/days`, formData)).data;
};

export const calendarAPI = {
    getCalendars,
    getCalendarById,
    createCalendar,
    updateCalendar,
    deleteCalendar,
    importCalendarDays,
};

type CalendarMutationDTO = {
    name: string;
    days?: CalendarDayRequestDTO[];
    template?: CalendarTemplate;
};

type CalendarDayRequestDTO = {
    dayType: CalendarDayType;
    name: string;
    date: string;
};
