import { Components, Theme } from '@mui/material';

export const MuiSwitch: Components<Omit<Theme, 'components'>>['MuiSwitch'] = {
    styleOverrides: {
        root: () => ({
            width: 24,
            height: 14,
            padding: 0,
            marginRight: 4,
            marginLeft: 4,
        }),
        switchBase: ({ theme }) => ({
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(10px)',
                color: theme.palette.common.white,
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                },
            },
        }),
        thumb: ({ theme }) => ({
            boxShadow: theme.shadows[1],
            width: 10,
            height: 10,
            color: theme.palette.common.white,
            transition: 'width 200ms',
        }),
        track: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius,
            opacity: 0.65,
            backgroundColor: theme.palette.action.disabled,
        }),
    },
};
