import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { JobLevel, JobLevelCreationMutation, JobLevelUpdateMutation } from '@/domain/job-level/JobLevel.model';

export type JobLevelDTO = JobLevel;
type JobLevelCreationMutationDTO = JobLevelCreationMutation;
type JobLevelUpdateMutationDTO = JobLevelUpdateMutation;

const getJobLevels = async (): Promise<JobLevel[]> => {
    const url = API_BASE_URL + `/job-levels`;
    return (await client.get<JobLevelDTO[]>(url)).data;
};

const getJobLevelById = async (jobLevelId: number): Promise<JobLevel> => {
    const url = API_BASE_URL + `/job-levels/${jobLevelId}`;
    return (await client.get<JobLevelDTO>(url)).data;
};

const createJobLevel = async (request: JobLevelCreationMutation): Promise<JobLevel> => {
    const url = API_BASE_URL + `/job-levels`;
    return (await client.post<JobLevelDTO, AxiosResponse<JobLevelDTO>, JobLevelCreationMutationDTO>(url, request)).data;
};

const updateJobLevel = async (jobLevelId: number, request: JobLevelUpdateMutation): Promise<JobLevel> => {
    const url = API_BASE_URL + `/job-levels/${jobLevelId}`;
    return (await client.put<JobLevelDTO, AxiosResponse<JobLevelDTO>, JobLevelUpdateMutationDTO>(url, request)).data;
};

const deleteJobLevel = async (jobLevelId: number): Promise<void> => {
    const url = API_BASE_URL + `/job-levels/${jobLevelId}`;
    await client.delete(url);
};

export const jobLevelAPI = {
    getJobLevels,
    getJobLevelById,
    createJobLevel,
    updateJobLevel,
    deleteJobLevel,
};
