import * as yup from 'yup';
import { leaveTypeSchema } from '@/domain/leave-type/LeaveType.schema';
import { hoursMinutesSchema } from '@/utils/datetime.schema';
import { getMinutesFromHoursMinutes, HoursMinutes, isValidDate } from '@/utils/datetime.util';
import { LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import { RecurringRuleType } from '@/domain/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.model';
import i18next from 'i18next';
import { TimesheetAdjustmentAction } from '@/domain/timesheet-adjustment/TimesheetAdjustment.model';

export const RecurringRuleTypeOptions = Object.entries(RecurringRuleType)
    .filter(value => value[1] !== 'ALL_WORKING_DAYS') // Filter out the value you don't want do display
    .map(value => value[1]);

export type ExcludedLeaveType = LeaveActivityType;

export const getAdjustmentFormSchema = () => {
    return yup
        .object()
        .shape({
            isRecurring: yup.boolean().required(),
            leaveType: leaveTypeSchema.nullable().when(['actionType', 'isRecurring'], {
                is: (actionType: TimesheetAdjustmentAction, isRecurring: boolean) => !isRecurring && actionType === TimesheetAdjustmentAction.TRANSFER,
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
            actionType: yup
                .string()
                .oneOf(Object.values(TimesheetAdjustmentAction))
                .when(['isRecurring'], {
                    is: (isRecurring: boolean) => isRecurring,
                    then: schema => schema.nullable(),
                    otherwise: schema => schema.required(),
                }),
            hoursMinutes: hoursMinutesSchema.required().test({
                message: i18next.t('general.validations.required'),
                test: (value: HoursMinutes) => getMinutesFromHoursMinutes(value) > 0,
            }),
            comment: yup.string().default(''),
            startDate: yup.date().required(),
            endDate: yup.date().when(['isRecurring'], {
                is: (isRecurring: boolean) => isRecurring,
                then: schema =>
                    schema.required().test({
                        message: i18next.t('add_adjustment_dialog.end_date_error'),
                        test: (endDate, meta) => validateEndDate(meta.parent.startDate, endDate),
                    }),
                otherwise: schema => schema.nullable(),
            }),
            contractProrataPartialDays: yup.boolean(),
            dontApplyOnLeave: yup.boolean(),
            recurringRuleType: yup
                .string()
                .oneOf(Object.values(RecurringRuleTypeOptions))
                .when(['isRecurring', 'dontApplyOnLeave'], {
                    is: (isRecurring: boolean, dontApplyOnLeave: boolean) => isRecurring && dontApplyOnLeave,
                    then: schema => schema.required(),
                    otherwise: schema => schema.nullable(),
                }),
        })
        .required();
};

const validateEndDate = (startDate: Date, endDate: Date) => {
    if (isValidDate(startDate) && isValidDate(endDate)) {
        return startDate <= endDate;
    }
    return false;
};

export type AdjustmentFormSchema = yup.InferType<ReturnType<typeof getAdjustmentFormSchema>>;
