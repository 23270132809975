import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { DateTimeFieldWrapper, DateTimeFieldWrapperProps, TimeFieldWrapper, TimeFieldWrapperProps } from '@/components/time-field-wrapper/TimeFieldWrapper';

type FieldTimeRootProps = Pick<TimeFieldWrapperProps, 'disabled' | 'sx' | 'onChange'>;

type FieldTimeProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    timePickerProps?: RemoveFromType<RemoveFromType<TimeFieldWrapperProps, FieldTimeRootProps>, ControllerRenderProps>;
} & FieldTimeRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldTime = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldTimeProps<TFieldValues, TName>,
): JSX.Element => {
    const { disabled, sx, onChange, timePickerProps = {}, ...controllerProps } = props;
    const { slotProps = {}, ...restTimePickerProps } = timePickerProps;
    const { textField: textFieldSlotProps = {}, ...restSlotProps } = slotProps;

    return (
        <Controller
            {...controllerProps}
            disabled={disabled}
            render={({ field, fieldState: { error } }) => (
                <TimeFieldWrapper
                    disabled={disabled}
                    slotProps={{
                        textField: {
                            error: !!error,
                            helperText: error?.message,
                            ...textFieldSlotProps,
                        },
                        ...restSlotProps,
                    }}
                    sx={sx}
                    {...restTimePickerProps}
                    {...field}
                    value={field.value}
                    onChange={(newTime, context) => {
                        field.onChange(newTime);
                        onChange?.(newTime, context);
                    }}
                />
            )}
        />
    );
};

/**
 * FieldDateTime is to be used if you need to keep the information of date in the object
 */

type FieldDateTimeRootProps = Pick<DateTimeFieldWrapperProps, 'disabled' | 'sx' | 'onChange'>;

export type FieldDateTimeProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    timePickerProps?: RemoveFromType<RemoveFromType<DateTimeFieldWrapperProps, FieldDateTimeRootProps>, ControllerRenderProps>;
} & FieldDateTimeRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldDateTime = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldDateTimeProps<TFieldValues, TName>,
): JSX.Element => {
    const { disabled, sx, onChange, timePickerProps = {}, ...controllerProps } = props;
    const { slotProps = {}, ...restTimePickerProps } = timePickerProps;
    const { textField: textFieldSlotProps = {}, ...restSlotProps } = slotProps;

    return (
        <Controller
            {...controllerProps}
            disabled={disabled}
            render={({ field, fieldState: { error } }) => (
                <DateTimeFieldWrapper
                    disabled={disabled}
                    slotProps={{
                        textField: {
                            error: !!error,
                            helperText: error?.message,
                            ...textFieldSlotProps,
                        },
                        ...restSlotProps,
                    }}
                    sx={sx}
                    {...restTimePickerProps}
                    {...field}
                    value={field.value}
                    onChange={(dateTime, context) => {
                        field.onChange(dateTime);
                        onChange?.(dateTime, context);
                    }}
                />
            )}
        />
    );
};
