import { PermissionGroup } from '@/domain/permission-group/PermissionGroup.model';
import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { getPermissionGroupById } from '@/domain/permission-group/PermissionGroup.service';

export const useGetPermissionGroup = (groupId?: number): UseQueryResult<PermissionGroup> => {
    const [permissionGroup, setPermissionGroup] = useState<PermissionGroup>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchPermissionGroup = useCallback(async () => {
        if (!groupId) {
            setIsLoading(false);
            return;
        }
        try {
            const data = await getPermissionGroupById(groupId);
            setPermissionGroup(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [groupId]);

    useEffect(() => {
        fetchPermissionGroup();
    }, [fetchPermissionGroup]);

    return {
        data: permissionGroup,
        setData: setPermissionGroup,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchPermissionGroup,
    };
};
