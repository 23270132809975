import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { ReviewTemplate, SearchReviewTemplate } from '@/domain/review-template/ReviewTemplate.model';
import { getReviewTemplate, searchReviewTemplates } from '@/domain/review-template/ReviewTemplate.service';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleError } from '@/utils/api.util';

export const useGetReviewTemplate = (reviewTemplateId: number | undefined): UseQueryResult<ReviewTemplate> => {
    const [reviewTemplate, setReviewTemplate] = useState<ReviewTemplate>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchReviewTemplate = useCallback(async () => {
        if (!reviewTemplateId) {
            setIsLoading(false);
            return;
        }
        try {
            const reviewTemplate = await getReviewTemplate(reviewTemplateId);
            setReviewTemplate(reviewTemplate);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [reviewTemplateId]);

    useEffect(() => {
        fetchReviewTemplate().catch(handleError);
    }, [fetchReviewTemplate]);

    return {
        data: reviewTemplate,
        setData: setReviewTemplate,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchReviewTemplate,
    };
};

export const useSearchReviewTemplates = (request: SearchReviewTemplate = {}): UseQueryResult<ReviewTemplate[]> => {
    const [reviewTemplates, setReviewTemplates] = useState<ReviewTemplate[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchReviewTemplates = useCallback(async (request: SearchReviewTemplate) => {
        try {
            const reviewTemplates = await searchReviewTemplates(request);
            setReviewTemplates(reviewTemplates);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchReviewTemplates(request).catch(handleError);
    }, [request]);

    return {
        data: reviewTemplates,
        setData: setReviewTemplates,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchReviewTemplates(request),
    };
};
