import { EmployeeSkill } from '@/domain/employee-skill/EmployeeSkill.model';
import { employeeSkillAPI } from '@/api/employee-skill/EmployeeSkill.api';
import { getGroupedSkillsByCategoryId } from '@/domain/skill/Skill.service';
import { SkillCategory } from '@/domain/skill-category/SkillCategory.model';

export const getEmployeeSkills = (employeeId: number): Promise<EmployeeSkill[]> => {
    return employeeSkillAPI.getEmployeeSkills(employeeId);
};

export const getEmployeeSkillsGroupedByCategoryId = (
    employeeSkills: EmployeeSkill[],
): {
    category: SkillCategory;
    groupedSkills: EmployeeSkill[];
}[] => {
    const skills = employeeSkills.map(({ skill }) => skill);
    const groupedSkills = getGroupedSkillsByCategoryId(skills);

    return groupedSkills.map(group => ({
        category: group.category as SkillCategory,
        groupedSkills: employeeSkills.filter(({ skill }) => skill.category.id === group.category.id).sort((a, b) => (b?.score ?? 0) - (a?.score ?? 0)),
    }));
};
