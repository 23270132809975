import { API_BASE_URL, client } from '@/api/common';
import {
    ReviewRatingScale,
    ReviewRatingScaleCreationMutation,
    ReviewRatingScaleItem,
    ReviewRatingScaleSearch,
} from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { AxiosResponse } from 'axios';
import { Label } from '@/domain/label/Label.model';

export type ReviewRatingScaleItemDTO = ReviewRatingScaleItem;

export type ReviewRatingScaleDTO = Omit<ReviewRatingScale, 'items'> & {
    items: ReviewRatingScaleItemDTO[];
};

export const mapToReviewRatingScale = (dto: ReviewRatingScaleDTO): ReviewRatingScale => {
    const items =
        dto.items?.map(item => ({
            ...item,
            description: item.description,
        })) ?? [];

    return {
        id: dto.id,
        name: dto.name,
        items,
    };
};

const getRating = async (ratingId: number): Promise<ReviewRatingScale> => {
    const response = await client.get<ReviewRatingScaleDTO, AxiosResponse<ReviewRatingScaleDTO>>(API_BASE_URL + `/review-rating-scales/${ratingId}`);

    return mapToReviewRatingScale(response.data);
};

export type ReviewRatingScaleUpdateRequestDTO = {
    name: Label;
    commentRequired?: boolean;
    commentRequiredThreshold?: number;
    items: ReviewRatingScaleItemDTO[];
};

const updateRating = async (ratingId: number, request: ReviewRatingScaleUpdateRequestDTO): Promise<ReviewRatingScale> => {
    const { data } = await client.put<ReviewRatingScaleDTO, AxiosResponse<ReviewRatingScaleDTO>, ReviewRatingScaleUpdateRequestDTO>(
        API_BASE_URL + `/review-rating-scales/${ratingId}`,
        request,
    );
    return mapToReviewRatingScale(data);
};

const deleteRating = async (ratingId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/review-rating-scales/${ratingId}`);
};

export type ReviewRatingScaleCreationRequestDTO = Omit<ReviewRatingScaleCreationMutation, 'items'> & {
    items: ReviewRatingScaleItemDTO[];
};

const createRating = async (request: ReviewRatingScaleCreationRequestDTO): Promise<ReviewRatingScale> => {
    const { data } = await client.post<ReviewRatingScaleDTO, AxiosResponse<ReviewRatingScaleDTO>, ReviewRatingScaleCreationRequestDTO>(
        API_BASE_URL + `/review-rating-scales`,
        request,
    );
    return mapToReviewRatingScale(data);
};

export type ReviewRatingScaleSearchRequestDTO = ReviewRatingScaleSearch;

const searchRatings = async (request: ReviewRatingScaleSearchRequestDTO): Promise<ReviewRatingScale[]> => {
    const { data } = await client.post<ReviewRatingScaleDTO[], AxiosResponse<ReviewRatingScaleDTO[]>, ReviewRatingScaleSearchRequestDTO>(
        API_BASE_URL + `/review-rating-scales/search`,
        request,
    );
    return data.map(mapToReviewRatingScale);
};

export const reviewRatingScaleApi = {
    getRating,
    updateRating,
    deleteRating,
    createRating,
    searchRatings,
    mapToReviewRatingScale,
};
