import { skillCategoryApi } from '@/api/skill-category/SkillCategory.api';
import { SkillCategory, SkillCategoryCreationMutation, SkillCategorySearch, SkillCategoryUpdateMutation } from '@/domain/skill-category/SkillCategory.model';
import { Skill } from '@/domain/skill/Skill.model';

import { OrderMutation } from '@/domain/common';

export const getSkillCategory = (skillCategoryId: number): Promise<SkillCategory> => skillCategoryApi.getSkillCategory(skillCategoryId);

export const updateSkillCategory = (skillCategoryId: number, request: SkillCategoryUpdateMutation): Promise<SkillCategory> =>
    skillCategoryApi.updateSkillCategory(skillCategoryId, request);

export const deleteSkillCategory = (skillCategoryId: number): Promise<void> => skillCategoryApi.deleteSkillCategory(skillCategoryId);

export const createSkillCategory = (request: SkillCategoryCreationMutation): Promise<SkillCategory> => skillCategoryApi.createSkillCategory(request);

export const searchSkillCategories = (request: SkillCategorySearch = {}): Promise<SkillCategory[]> => skillCategoryApi.searchSkillCategories(request);

export const reorderSkillCategoriesRequest = (request: OrderMutation[]): Promise<Skill[]> => skillCategoryApi.reorderSkillCategoriesRequest(request);
