import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeSection, EmployeeSectionSearch } from '@/domain/employee-section/EmployeeSection.model';
import { getEmployeeSections } from '@/domain/employee-section/EmployeeSection.service';
import { handleError } from '@/utils/api.util';

export const useGetEmployeeSection = ({ employeeId }: EmployeeSectionSearch): UseQueryResult<EmployeeSection[]> => {
    const [employeeSection, setEmployeeSection] = useState<EmployeeSection[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchMe = useCallback(async ({ employeeId }: EmployeeSectionSearch) => {
        try {
            const employeeSection = await getEmployeeSections({ employeeId });
            setEmployeeSection(employeeSection);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchMe({ employeeId }).catch(handleError);
    }, [fetchMe, employeeId]);

    return {
        data: employeeSection,
        setData: setEmployeeSection,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchMe({ employeeId }),
    };
};
