import { Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FilteringCondition, PermissionFilteringConditionType } from '@/domain/permission/Permission.model';
import { getPermissionFilteringConditionDisplay } from '@/domain/permission/Permission.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getContractTypeTranslationKey, getUserEmploymentStatusTranslationKey } from '@/domain/employee/Employee.service';

type RenderConditionProps = {
    item: FilteringCondition;
    isTooltip?: boolean;
};

export const RenderCondition: FC<RenderConditionProps> = ({ item, isTooltip = false }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const typoColor = isTooltip ? theme.palette.info.light : '';
    const getFilterConditionRow = (conditionName: string, index: number) => {
        return (
            <Stack direction={'row'} gap={1} key={conditionName}>
                <Typography color={typoColor} variant='body2bold'>
                    {conditionName}
                </Typography>
                {index !== item.filterConditionValues.length - 1 && (
                    <Typography color={typoColor} variant='body2'>
                        {t('permissions_setting_page.or')}
                    </Typography>
                )}
            </Stack>
        );
    };

    return (
        <Stack gap={1} direction={'row'} bgcolor={isTooltip ? 'transparent' : theme.palette.grey[100]} py={0.5} px={1} borderRadius={1} flexWrap={'wrap'}>
            <Typography variant='body2bold' color={typoColor}>
                {t('domain.employee_field_type', {
                    context: item.conditionType,
                })}
            </Typography>{' '}
            <Typography variant='body2' color={typoColor}>
                {t('domain.condition_rule_type', { context: getPermissionFilteringConditionDisplay(item.conditionRule, item.conditionType) })}
            </Typography>
            {item.filterConditionValues.map((condition, index) => {
                switch (item.conditionType) {
                    case PermissionFilteringConditionType.LOCATION:
                        return getFilterConditionRow(condition.location?.name ?? '', index);
                    case PermissionFilteringConditionType.DEPARTMENT:
                        return getFilterConditionRow(getLabelTranslation(condition.department?.name), index);
                    case PermissionFilteringConditionType.JOB:
                        return getFilterConditionRow(getLabelTranslation(condition.job?.name), index);
                    case PermissionFilteringConditionType.EMPLOYMENT_STATUS:
                        return getFilterConditionRow(t(getUserEmploymentStatusTranslationKey(), { context: condition.employmentStatus }), index);
                    case PermissionFilteringConditionType.CONTRACT_TYPE:
                        return getFilterConditionRow(t(getContractTypeTranslationKey(), { context: condition.contractType }), index);
                }
            })}
        </Stack>
    );
};
