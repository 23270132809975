import { Button, Stack, TextField } from '@mui/material';
import { Download02Icon } from 'hugeicons-react';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

type DatatableAdditionalActionProps = {
    quickFilter: (event: ChangeEvent<HTMLInputElement>) => void;
    onBtnExport?: () => void;
    disabled?: boolean;
};

export const DatatableAdditionalAction: FC<DatatableAdditionalActionProps> = ({ quickFilter, onBtnExport, disabled = false }) => {
    const { t } = useTranslation();
    return (
        <Stack gap={1} direction='row'>
            <TextField onChange={quickFilter} placeholder={t('general.search')} disabled={disabled} />
            {onBtnExport && (
                <Button onClick={onBtnExport} sx={{ paddingX: 0.8, minWidth: 'auto' }} disabled={disabled} aria-label='Export grid'>
                    <Download02Icon />
                </Button>
            )}
        </Stack>
    );
};
