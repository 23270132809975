import { planningSettingApi } from '@/api/planning-setting/PlanningSetting.api';
import { PlanningSetting, PlanningSettingMutation } from '@/domain/planning-setting/PlanningSetting.model';

export const getPlanningSettings = (): Promise<PlanningSetting> => {
    return planningSettingApi.getPlanningSettings();
};

export const updatePlanningSettings = (planningSettingId: number, mutation: PlanningSettingMutation): Promise<PlanningSetting> => {
    return planningSettingApi.updatePlanningSettings(planningSettingId, mutation);
};
