import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { Stack } from '@mui/material';
import { FC } from 'react';

import { Employee } from '@/domain/employee/Employee.model';

type Props = {
    employee: Employee;
};

export const EmployeeDialogHeader: FC<Props> = ({ employee }) => {
    const name = `${employee?.firstName} ${employee?.lastName}`;

    return (
        <Stack gap={1} direction='row' alignItems='center'>
            {employee && (
                <>
                    <EmployeeAvatar employeeAvatar={employee} />
                    {name}
                    <b>{employee.planningPositions?.[0]?.name}</b>
                </>
            )}
        </Stack>
    );
};
