import { Tab, Tabs } from '@mui/material';
import { FC } from 'react';

type Props = {
    activeTab: string;
    onChangeTab: (value: string) => void;
    tabConfig: TabsConfig;
};

export interface TabConfig {
    name: string;
    index: string;
    isEnabled: boolean;
}

export interface TabsConfig {
    tabs: TabConfig[];
}

export const TabsContainer: FC<Props> = ({ activeTab, onChangeTab, tabConfig }) => {
    return (
        <Tabs
            value={activeTab}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
            onChange={(_, value) => {
                onChangeTab(value);
            }}
            sx={{
                width: '100%',
                '& .MuiTabScrollButton-root ': {
                    display: 'none',
                },
            }}
        >
            {tabConfig?.tabs?.map(tab => {
                return tab.isEnabled ? <Tab tabIndex={0} key={tab.index} label={tab.name} value={tab.index} /> : undefined;
            })}
        </Tabs>
    );
};
