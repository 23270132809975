import { FC, PropsWithChildren } from 'react';
import { Stack, StackProps } from '@mui/material';

export const ContentContainer: FC<PropsWithChildren<StackProps>> = ({ children = false, ...rest }) => {
    return (
        <Stack
            id='page-content'
            flex={1}
            overflow='auto'
            sx={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}
            pb={2}
            {...rest}
        >
            {children}
        </Stack>
    );
};
