import { EditableSectionFieldComponent } from '@/components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { OnboardingProfileStepFormValues } from '@/page/people/on-boarding-form/EmployeeInformationsForm/EmployeeInformationForm';
import { SectionContainer } from '@/page/people/on-boarding-form/SectionContainer';
import { SectionFieldContainer } from '@/page/people/on-boarding-form/SectionFieldContainer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormValueByFieldType } from '@/domain/section-setting/Section.service';
import { SectionDefinitionFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { useEmployeePersonalInfoSectionFields } from '@/page/employee-profile/employee-profile-info/EmployeePersonalInfoSection/EmployeePersonalInfoSection.hook';
import { EmployeePersonalInfo } from '@/domain/employee/EmployeePersonalInfo.model';
import { SectionDefinition, SectionFieldDefinition } from '@/domain/section-setting/Section.model';

type PersonalInfosSectionProps = {
    baseProfileFormValues: Partial<OnboardingProfileStepFormValues>;
    sectionDefinition: SectionDefinition;
};

export const PersonalInfosSection: FC<PersonalInfosSectionProps> = ({ baseProfileFormValues, sectionDefinition }) => {
    const { t } = useTranslation();

    const mapFormValuesToPersonalInfo = (formValues: Partial<SectionDefinitionFormValues>): EmployeePersonalInfo => {
        return {
            birthdate: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_BIRTH_DATE', formValues),
            nationality: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_NATIONALITY', formValues)?.value,
            maritalStatus: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_MARITAL_STATUS', formValues),
            maritalStatusSince: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_MARITAL_STATUS_SINCE', formValues),
            avsNumber: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_AVS', formValues),
            personalPhoneNumber: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_PERSONAL_PHONE_NUMBER', formValues),
            personalEmail: getFormValueByFieldType(sectionDefinition, 'EMPLOYEE_PERSONAL_EMAIL', formValues),
        };
    };

    const employeePersonalInfoValues = mapFormValuesToPersonalInfo(baseProfileFormValues);

    const personalInfosFields = useEmployeePersonalInfoSectionFields(sectionDefinition, employeePersonalInfoValues);

    // Hide the personal email and phone number fields in the onboarding form
    const hiddenFields: SectionFieldDefinition['fieldType'][] = ['EMPLOYEE_PERSONAL_EMAIL', 'EMPLOYEE_PERSONAL_PHONE_NUMBER'];
    const personalInfoOnboardingFields = personalInfosFields.filter(field => !hiddenFields.includes(field.fieldType));

    return (
        <SectionContainer title={t('employee.sections.personal_info')}>
            {!!personalInfosFields?.length &&
                personalInfoOnboardingFields.map(personalInfosField => {
                    return (
                        <SectionFieldContainer
                            key={personalInfosField.title}
                            formValueName={personalInfosField.formValueName ?? ''}
                            title={personalInfosField.title ?? ''}
                            required={personalInfosField.required}
                        >
                            {<EditableSectionFieldComponent field={personalInfosField} />}
                        </SectionFieldContainer>
                    );
                })}
        </SectionContainer>
    );
};
