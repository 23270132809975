import { Objective } from '@/domain/objective/Objective.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { EmployeeAvatarWithDetails } from '@/components/employee-avatar/EmployeeAvatarWithDetails';
import { getLabelTranslation } from '@/utils/language.util';
import { formatInDefaultDate } from '@/utils/datetime.util';

type ObjectiveHistoryDialogDetailsPanelProps = {
    objective: Objective;
};

export const ObjectiveHistoryDialogDetailsPanel: FC<ObjectiveHistoryDialogDetailsPanelProps> = ({ objective }) => {
    const { t } = useTranslation();

    return (
        <Stack flex={1} alignItems={'flex-start'} direction={'row'} gap={2}>
            <Stack gap={2}>
                {objective.assignee && (
                    <Stack gap={0.5}>
                        <Typography variant={'body1'}>{t('objectives.history_dialog.assignee')}</Typography>
                        <EmployeeAvatarWithDetails employee={objective.assignee} />
                    </Stack>
                )}
                {objective.category && (
                    <Stack gap={0.5} alignItems={'flex-start'}>
                        <Typography variant={'body1'}>{t('objectives.history_dialog.category')}</Typography>
                        <Typography variant={'body1bold'}>{getLabelTranslation(objective.category.name)}</Typography>
                    </Stack>
                )}
                <Typography variant={'body1'}>
                    {t('objectives.history_dialog.created_on')}{' '}
                    <Typography component='span' variant={'body1bold'}>
                        {formatInDefaultDate(objective.createdAt)}
                    </Typography>
                </Typography>
                {objective.lastStatusUpdate && (
                    <Typography variant={'body1'}>
                        {t('objectives.history_dialog.last_update')}{' '}
                        <Typography component='span' variant={'body1bold'}>
                            {formatInDefaultDate(objective.lastStatusUpdate.updatedAt)}
                        </Typography>
                    </Typography>
                )}
                {objective.dueDate && (
                    <Typography variant={'body1'}>
                        {t('objectives.history_dialog.due_date')}{' '}
                        <Typography component='span' variant={'body1bold'}>
                            {formatInDefaultDate(objective.dueDate)}
                        </Typography>
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};
