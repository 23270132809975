export const getCalendarDayTypeTranslationKey = (calendarDayType: CalendarDayType): string => {
    switch (calendarDayType) {
        case CalendarDayType.HOLIDAY:
            return 'domain.calendar_day_type.holiday';
        case CalendarDayType.WORKING_DAY:
            return 'domain.calendar_day_type.working_day';
        default:
            return '';
    }
};

export type Calendar = {
    id: number;
    name: string;
    days?: CalendarDay[];
    template?: CalendarTemplate;
};

export type CalendarMutation = {
    name: string;
    days: CalendarDay[];
    template?: CalendarTemplate;
};

export enum CalendarDayType {
    HOLIDAY = 'HOLIDAY',
    WORKING_DAY = 'WORKING_DAY',
}

export type CalendarDay = {
    id: number;
    dayType: CalendarDayType;
    name: string;
    date: LocalDate;
};

export enum CalendarTemplate {
    AARGAU = 'AARGAU',
    APPENZEL_AUSSERRHAUDEN = 'APPENZEL_AUSSERRHAUDEN',
    APPENZEL_INNERRHAUDEN = 'APPENZEL_INNERRHAUDEN',
    BASEL_LANDSHAFT = 'BASEL_LANDSHAFT',
    BASEL_STADT = 'BASEL_STADT',
    BERN = 'BERN',
    FRANCE = 'FRANCE',
    FRIBOURG = 'FRIBOURG',
    GENEVA = 'GENEVA',
    GLARUS = 'GLARUS',
    GRAUBUNDEN = 'GRAUBUNDEN',
    JURA = 'JURA',
    LUCERNE = 'LUCERNE',
    NEUCHATEL = 'NEUCHATEL',
    NIDWALDEN = 'NIDWALDEN',
    OBWALDEN = 'OBWALDEN',
    SCHAFFHAUSEN = 'SCHAFFHAUSEN',
    SCHWYZ = 'SCHWYZ',
    SOLOTHURN = 'SOLOTHURN',
    ST_GALLEN = 'ST_GALLEN',
    THURGAU = 'THURGAU',
    TICINO = 'TICINO',
    URI = 'URI',
    VALAIS = 'VALAIS',
    VAUD = 'VAUD',
    ZUG = 'ZUG',
    ZURICH = 'ZURICH',
}
