import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';
import { forwardRef } from 'react';
import { useUserLanguage } from '@/utils/language.util';

export type InputPhoneNumberProps = Omit<MuiTelInputProps, 'forceCallingCode'>;

export const InputPhoneNumber = forwardRef<HTMLInputElement, InputPhoneNumberProps>((props, ref) => {
    const userLanguage = useUserLanguage();
    return (
        <MuiTelInput
            ref={ref}
            // We don't want to force the calling code to force the user to select a country code himself
            // when the phone number doesn't start with a country code
            forceCallingCode={false}
            focusOnSelectCountry={true}
            langOfCountryName={userLanguage}
            {...props}
            onChange={(_, info) => {
                if (props.onChange) {
                    // info.numberValue contains the phone number without any formatting
                    props.onChange(info.numberValue ?? '', info);
                }
            }}
        />
    );
});
