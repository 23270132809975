import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldText } from '@/components/form/field-text/FieldText';
import { CostCenter } from '@/domain/cost-center/CostCenter.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    open: boolean;
    onSave: (data: CostCenterFormValues) => void;
    closeDialog: () => void;
    costCenter: CostCenter | undefined;
};

const costCenterSchema = yup.object().shape({
    name: yup.string().trim().required(),
});

export type CostCenterFormValues = yup.InferType<typeof costCenterSchema>;

export const CostCenterSettingsDialog: FC<Props> = ({ open, onSave, closeDialog, costCenter }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<CostCenterFormValues>({
        resolver: yupResolver(costCenterSchema),
        defaultValues: {
            name: costCenter?.name ?? '',
        },
    });

    const handleOnSave = (data: CostCenterFormValues) => {
        onSave(data);
    };
    const onClose = () => {
        closeDialog();
    };

    const title = costCenter ? t('cost_center_setting_page.dialog.edit_title') : t('cost_center_setting_page.dialog.add_title');

    return (
        <DialogWrapper header={title} open={open} onClose={onClose}>
            <Stack component={DialogContent}>
                <FormControlLabel
                    aria-label={'cost-center-name'}
                    label={t('cost_center_setting_page.dialog.name_input')}
                    control={<FieldText fullWidth name='name' control={control} />}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleOnSave, console.error)} variant='contained' fullWidth>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
