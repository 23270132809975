import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeReviewFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { getEmployeeReviewFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { handleError } from '@/utils/api.util';

export const useGetEmployeeReviewFeedback = (employeeReviewId: number | undefined): UseQueryResult<EmployeeReviewFeedback> => {
    const [employeeReviewFeedback, setEmployeeReviewFeedback] = useState<EmployeeReviewFeedback>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeReviewFeedback = useCallback(async () => {
        if (!employeeReviewId) {
            setIsLoading(false);
            return;
        }

        try {
            const employeeReviewFeedback = await getEmployeeReviewFeedback(employeeReviewId);
            setEmployeeReviewFeedback(employeeReviewFeedback);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, [employeeReviewId]);

    useEffect(() => {
        fetchEmployeeReviewFeedback().catch(handleError);
    }, [fetchEmployeeReviewFeedback]);

    return {
        data: employeeReviewFeedback,
        setData: setEmployeeReviewFeedback,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchEmployeeReviewFeedback,
    };
};
