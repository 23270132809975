import { FC } from 'react';
import { EmployeeReviewFeedbackFormType } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { useTheme } from '@mui/material';
import { Text, View } from '@react-pdf/renderer';
import { EmployeeReviewStarsIconsPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewStarsIconsPDF';

type EmployeeReviewSkillFeedbackFromPreparationPDFProps = {
    employeeReviewFeedbackForm: EmployeeReviewFeedbackFormType;
};

export const EmployeeReviewSkillFeedbackFromPreparationPDF: FC<EmployeeReviewSkillFeedbackFromPreparationPDFProps> = ({ employeeReviewFeedbackForm }) => {
    const theme = useTheme();

    if (!employeeReviewFeedbackForm) {
        return <></>;
    }

    return (
        <View
            style={{
                display: 'flex',
            }}
        >
            <View
                style={{
                    flexDirection: 'row',
                    gap: theme.spacing(1),
                    alignItems: 'center',
                }}
            >
                <Text
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    {employeeReviewFeedbackForm.reviewer.displayName}
                </Text>
                {employeeReviewFeedbackForm.score && <EmployeeReviewStarsIconsPDF score={employeeReviewFeedbackForm.score} />}
            </View>
            <Text>{employeeReviewFeedbackForm.comment}</Text>
        </View>
    );
};
