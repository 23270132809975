import { EmployeeAvailability, EmployeeAvailabilitySearchRequest } from '@/domain/employee-availability/EmployeeAvailability.model';
import { getEmployeesAvailability } from '@/domain/employee-availability/EmployeeAvailability.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployeeAvailabilities = (search: EmployeeAvailabilitySearchRequest): UseQueryResult<EmployeeAvailability[]> => {
    const [employeeAvailabilities, setEmployeeAvailabilities] = useState<EmployeeAvailability[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeAvailabilities = useCallback(async (search: EmployeeAvailabilitySearchRequest) => {
        try {
            const employeeAvailabilities = await getEmployeesAvailability(search);
            setEmployeeAvailabilities(employeeAvailabilities);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchEmployeeAvailabilities(search).catch(handleError);
    }, [fetchEmployeeAvailabilities, search]);

    return {
        data: employeeAvailabilities,
        setData: setEmployeeAvailabilities,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeAvailabilities(search),
    };
};
