import {
    ReviewRatingScale,
    ReviewRatingScaleCreationMutation,
    ReviewRatingScaleSearch,
    ReviewRatingScaleUpdateMutation,
} from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { reviewRatingScaleApi } from '@/api/review-rating-scale/ReviewRatingScale.api';

export const getReviewRatingScale = (ratingId: number): Promise<ReviewRatingScale> => {
    return reviewRatingScaleApi.getRating(ratingId);
};

export const updateReviewRatingScale = (ratingId: number, reviewRatingScaleUpdateMutation: ReviewRatingScaleUpdateMutation): Promise<ReviewRatingScale> => {
    return reviewRatingScaleApi.updateRating(ratingId, reviewRatingScaleUpdateMutation);
};

export const deleteReviewRatingScale = (ratingId: number): Promise<void> => {
    return reviewRatingScaleApi.deleteRating(ratingId);
};
export const createReviewRatingScale = (reviewRatingScaleCreationMutation: ReviewRatingScaleCreationMutation): Promise<ReviewRatingScale> => {
    return reviewRatingScaleApi.createRating(reviewRatingScaleCreationMutation);
};

export const searchReviewRatingScales = (reviewRatingScaleSearch: ReviewRatingScaleSearch): Promise<ReviewRatingScale[]> => {
    return reviewRatingScaleApi.searchRatings(reviewRatingScaleSearch);
};
