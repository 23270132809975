import { Employee } from '@/domain/employee/Employee.model';

import {
    EmployeeReview,
    EmployeeReviewApproveMutation,
    EmployeeReviewContributorType,
    EmployeeReviewSearch,
    LabelContext,
    ReviewStatusBarConfig,
    UpdateEmployeeReviewMutation,
} from '@/domain/employee-review/EmployeeReview.model';
import { employeeReviewApi } from '@/api/employee-review/EmployeeReview.api';
import { ContributorType } from '@/domain/review/Review.model';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { isManagerReviewRequired, isPeerReviewAsked, isSelfReviewRequired, isUpwardReviewAsked } from '@/domain/review/Review.service';
import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { getLocale, UserLanguage } from '@/utils/language.util';
import { formatInDefaultLiteral, isBeforeDate } from '@/utils/datetime.util';

export const getEmployeeReviewContributorType = (employeeReview: EmployeeReview, currentEmployee: Employee): EmployeeReviewContributorType => {
    if (employeeReview.employee.id === currentEmployee.id) {
        return 'EMPLOYEE';
    } else if (isCurrentEmployeeManager(employeeReview, currentEmployee)) {
        return 'MANAGER';
    } else if (
        employeeReview.peerReviewers?.some(p => p.employee.id === currentEmployee.id) ||
        employeeReview.upwardReviewers?.some(u => u.employee.id === currentEmployee.id)
    ) {
        return 'OTHER_CONTRIBUTOR';
    } else {
        return 'NOT_CONTRIBUTOR';
    }
};

export const getEmployeeReviewContributors = (employeeReview: EmployeeReview): Employee[] => {
    const upwardReviewers = employeeReview.upwardReviewers.map(upwardReviewer => upwardReviewer.employee);
    const peerReviewers = employeeReview.peerReviewers.map(peerReviewer => peerReviewer.employee);
    const managerContributors = employeeReview?.managers?.map(manager => manager.employee);
    const selfContributor = employeeReview.employee;
    return [selfContributor, ...managerContributors, ...upwardReviewers, ...peerReviewers];
};

export const getReviewingContributorsExceptManager = (employeeReview: EmployeeReview): Employee[] => {
    const contributors = getEmployeeReviewContributors(employeeReview);

    return contributors.filter(contributor => {
        return (
            (employeeReview.employee.id === contributor.id && employeeReview.selfReviewed) ||
            employeeReview.peerReviewers.some(peerReviewer => peerReviewer.employee.id === contributor.id && peerReviewer.reviewed) ||
            employeeReview.upwardReviewers.some(upwardReviewer => upwardReviewer.employee.id === contributor.id && upwardReviewer.reviewed)
        );
    });
};

export const isCurrentEmployeeManager = (employeeReview: EmployeeReview, currentEmployee: Employee): boolean => {
    return employeeReview?.managers?.some(manager => manager.employee.id === currentEmployee.id);
};

export const getCurrentContributorType = (employeeReview: EmployeeReview, currentEmployee: Employee): ContributorType | undefined => {
    if (employeeReview.employee.id === currentEmployee.id) {
        return 'SELF';
    } else if (employeeReview.managers?.some(manager => manager.employee.id === currentEmployee.id)) {
        return 'MANAGER';
    } else if (employeeReview.peerReviewers?.some(p => p.employee.id === currentEmployee.id)) {
        return 'PEER';
    } else if (employeeReview.upwardReviewers?.some(u => u.employee.id === currentEmployee.id)) {
        return 'UPWARD';
    }
};

export const isManagerHasPreparationAccess = (employeeReview: EmployeeReview, currentEmployee: Employee): boolean => {
    if (!employeeReview?.review) {
        return false;
    }
    return employeeReview?.review?.managerPreparationAccess && isCurrentEmployeeManager(employeeReview, currentEmployee);
};

export function getEmployeeReview(employeeReviewId: number): Promise<EmployeeReview> {
    return employeeReviewApi.getEmployeeReview(employeeReviewId);
}

export function updateEmployeeReview(employeeReviewId: number, employeeReview: UpdateEmployeeReviewMutation): Promise<EmployeeReview> {
    return employeeReviewApi.updateEmployeeReview(employeeReviewId, employeeReview);
}

export function submitEmployeeReviewDiscussion(employeeReviewId: number): Promise<EmployeeReview> {
    return employeeReviewApi.submitEmployeeReviewDiscussion(employeeReviewId);
}

export function startEmployeeReviewDiscussion(employeeReviewId: number): Promise<EmployeeReview> {
    return employeeReviewApi.startEmployeeReviewDiscussion(employeeReviewId);
}

export function closeEmployeeReview(employeeReviewId: number, comment: string): Promise<EmployeeReview> {
    return employeeReviewApi.closeEmployeeReview(employeeReviewId, { comment });
}

export function cancelEmployeeReview(employeeReviewId: number): Promise<EmployeeReview> {
    return employeeReviewApi.cancelEmployeeReview(employeeReviewId);
}

export function resetEmployeeReview(employeeReviewId: number, status: EmployeeReviewStatus): Promise<EmployeeReview> {
    return employeeReviewApi.resetEmployeeReview(employeeReviewId, { status });
}

export function approveEmployeeReview(employeeReviewId: number, request: EmployeeReviewApproveMutation): Promise<EmployeeReview> {
    return employeeReviewApi.approveEmployeeReview(employeeReviewId, request);
}

export function searchEmployeeReviews(employeeReviewSearch: EmployeeReviewSearch): Promise<EmployeeReview[]> {
    return employeeReviewApi.searchEmployeeReviews(employeeReviewSearch);
}

export const countEmployeeReviews = async (employeeReviewSearch: EmployeeReviewSearch, currentEmployee: Employee): Promise<number> => {
    // TODO replace by a specific endpoint to get only the count : https://rogerhr.atlassian.net/browse/RP-3992
    const data = await employeeReviewApi.searchEmployeeReviewAsContributor(employeeReviewSearch);
    const ongoingReviewsWithActionNeeded = data.filter(review => isEmployeeReviewActionNeeded(review, currentEmployee));
    return ongoingReviewsWithActionNeeded.length ?? 0;
};

export function searchEmployeeReviewAsContributor(employeeReviewSearch: EmployeeReviewSearch): Promise<EmployeeReview[]> {
    return employeeReviewApi.searchEmployeeReviewAsContributor(employeeReviewSearch);
}

export const isOngoingReview = (employeeReview: EmployeeReview): boolean =>
    employeeReview.status !== EmployeeReviewStatus.CANCELLED &&
    employeeReview.status !== EmployeeReviewStatus.CLOSED &&
    employeeReview.status !== EmployeeReviewStatus.EMPLOYEE_APPROVED;
export const getOngoingReviews = (employeeReviews: EmployeeReview[] = []): EmployeeReview[] => {
    return employeeReviews.filter(employeeReview => isOngoingReview(employeeReview));
};

export const getPastReviews = (employeeReviews: EmployeeReview[] = []): EmployeeReview[] => {
    return employeeReviews.filter(employeeReview => !isOngoingReview(employeeReview)).filter(review => review.status !== EmployeeReviewStatus.CANCELLED);
};
export const isManagerReviewDone = (employeeReview: EmployeeReview, currentEmployee: Employee): boolean => {
    return employeeReview.managers?.some(manager => manager.employee.id === currentEmployee.id && manager.reviewed);
};
export const isPeerReviewDone = (employeeReview: EmployeeReview, currentEmployee: Employee): boolean => {
    return employeeReview.peerReviewers?.some(peer => peer.employee.id === currentEmployee.id && peer.reviewed);
};
export const isUpwardReviewDone = (employeeReview: EmployeeReview, currentEmployee: Employee): boolean => {
    return employeeReview.upwardReviewers?.some(upward => upward.employee.id === currentEmployee.id && upward.reviewed);
};

export const employeeSelfReviewDone = (employeeReview: EmployeeReview): boolean => employeeReview.selfReviewed;

export const isEmployeeReviewInputNeeded = (employeeReviewStatus: EmployeeReviewStatus): boolean => employeeReviewStatus === EmployeeReviewStatus.INPUT_NEEDED;
export const isEmployeeReviewStarted = (employeeReviewStatus: EmployeeReviewStatus): boolean =>
    employeeReviewStatus === EmployeeReviewStatus.DISCUSSION_STARTED;
export const isEmployeeReviewSubmitted = (employeeReviewStatus: EmployeeReviewStatus): boolean =>
    employeeReviewStatus === EmployeeReviewStatus.DISCUSSION_SUBMITTED;

export const isEmployeeReviewCompleted = (employeeReview: EmployeeReview): boolean =>
    employeeReview?.status === EmployeeReviewStatus.EMPLOYEE_APPROVED ||
    employeeReview?.status === EmployeeReviewStatus.CLOSED ||
    employeeReview?.status === EmployeeReviewStatus.CANCELLED;
export const isEmployeeReviewEmployeeApproved = (employeeReviewStatus: EmployeeReviewStatus): boolean =>
    employeeReviewStatus === EmployeeReviewStatus.EMPLOYEE_APPROVED;
export const isEmployeeReviewCancelled = (employeeReviewStatus: EmployeeReviewStatus): boolean => employeeReviewStatus === EmployeeReviewStatus.CANCELLED;
export const isEmployeeReviewClosed = (employeeReviewStatus: EmployeeReviewStatus): boolean => employeeReviewStatus === EmployeeReviewStatus.CLOSED;

export const hasManagerReviewers = (employeeReview: EmployeeReview): boolean => {
    return employeeReview?.managers?.length > 0;
};

export const hasPeerReviewers = (employeeReview: EmployeeReview): boolean => {
    return employeeReview?.peerReviewers?.length > 0;
};

export const hasUpwardReviewers = (employeeReview: EmployeeReview): boolean => {
    return employeeReview?.upwardReviewers?.length > 0;
};

export const isManagerReviewSubmitted = (employeeReview: EmployeeReview, currentEmployee: Employee): boolean => {
    return employeeReview.managers.some(manager => manager.employee.id === currentEmployee.id && manager.reviewed);
};

export const getAvailableStatuses = (employeeReview: EmployeeReview): EmployeeReviewStatus[] => {
    const availableStatuses: EmployeeReviewStatus[] = [];

    if (employeeReview.review?.includePreparationStep && employeeReview.status !== EmployeeReviewStatus.INPUT_NEEDED) {
        availableStatuses.push(EmployeeReviewStatus.INPUT_NEEDED);
    }

    if (employeeReview.status !== EmployeeReviewStatus.DISCUSSION_STARTED) {
        availableStatuses.push(EmployeeReviewStatus.DISCUSSION_STARTED);
    }

    if (employeeReview.status !== EmployeeReviewStatus.EMPLOYEE_APPROVED && employeeReview.status !== EmployeeReviewStatus.CLOSED) {
        availableStatuses.push(EmployeeReviewStatus.CLOSED);
    }

    return availableStatuses;
};

export const getEmployeeReviewNavigationLink = (employeeReview: EmployeeReview, currentEmployee: Employee): string => {
    const contributorType = getEmployeeReviewContributorType(employeeReview, currentEmployee);
    switch (contributorType) {
        case 'EMPLOYEE':
            return getEmployeeReviewContributorNavigationLink(employeeReview);
        case 'MANAGER':
            return getManagerEmployeeReviewNavigationLink(employeeReview, currentEmployee);
        case 'OTHER_CONTRIBUTOR':
            return getOtherContributorNavigationLink(employeeReview, currentEmployee);
        case 'NOT_CONTRIBUTOR':
            return getNotContributorNavigationLink(employeeReview);
    }

    function getEmployeeReviewContributorNavigationLink(employeeReview: EmployeeReview): string {
        if (!employeeReview?.review) {
            return '';
        }
        const reviewStatus = employeeReview.status;
        const includeSelfPreparation = isSelfReviewRequired(employeeReview.review);
        const isSelfReviewDone = employeeReview.selfReviewed;
        const isManagersReviewDone = employeeReview.managers?.every(manager => manager.reviewed);
        const isEmployeePreparationAccess = employeeReview.review.employeePreparationAccess;
        const employeeReviewId = employeeReview.id;
        switch (reviewStatus) {
            case EmployeeReviewStatus.INPUT_NEEDED:
                if (isSelfReviewDone && isManagersReviewDone && isEmployeePreparationAccess) {
                    return getSummaryLink(employeeReviewId);
                }
                return includeSelfPreparation ? getFeedbackLink(employeeReviewId) : '';
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                if (isSelfReviewRequired(employeeReview.review)) {
                    if (isEmployeePreparationAccess) {
                        return getSummaryLink(employeeReviewId);
                    }
                    return getFeedbackLink(employeeReviewId);
                }
                return '';
            default:
                return getSummaryLink(employeeReviewId);
        }
    }

    function getManagerEmployeeReviewNavigationLink(employeeReview: EmployeeReview, currentEmployee: Employee): string {
        if (!employeeReview?.review) {
            return '';
        }

        const employeeReviewId = employeeReview.id;
        const includeManagerPreparation = isManagerReviewRequired(employeeReview.review);
        const includeSelfPreparation = isSelfReviewRequired(employeeReview.review);
        const selfPreparationDone = employeeReview.selfReviewed;

        switch (employeeReview.status) {
            case EmployeeReviewStatus.INPUT_NEEDED:
                if (includeManagerPreparation) {
                    if (!isManagerReviewDone(employeeReview, currentEmployee)) {
                        return getFeedbackLink(employeeReviewId);
                    }

                    if (includeSelfPreparation && !selfPreparationDone) {
                        return getFeedbackLink(employeeReviewId);
                    }

                    return getManagerSummaryLink(employeeReviewId);
                }

                if (!includeManagerPreparation && includeSelfPreparation && !selfPreparationDone) {
                    return '';
                }

                return getManagerSummaryLink(employeeReviewId);
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
            default:
                return getManagerSummaryLink(employeeReviewId);
        }
    }

    function getOtherContributorNavigationLink(employeeReview: EmployeeReview, currentEmployee: Employee): string {
        const isOtherContributorReviewDone = isPeerReviewDone(employeeReview, currentEmployee) || isUpwardReviewDone(employeeReview, currentEmployee);
        const employeeReviewId = employeeReview.id;

        if (employeeReview.status === EmployeeReviewStatus.INPUT_NEEDED) {
            return getFeedbackLink(employeeReviewId);
        }
        return isOtherContributorReviewDone ? getFeedbackLink(employeeReviewId) : ``;
    }

    function getNotContributorNavigationLink(employeeReview: EmployeeReview): string {
        const employeeReviewId = employeeReview.id;
        const isCanSeeReview =
            employeeReview.status === EmployeeReviewStatus.CLOSED ||
            employeeReview.status === EmployeeReviewStatus.EMPLOYEE_APPROVED ||
            employeeReview.status === EmployeeReviewStatus.CANCELLED ||
            employeeReview.status === EmployeeReviewStatus.DISCUSSION_SUBMITTED;
        return isCanSeeReview ? getSummaryLink(employeeReviewId) : '';
    }
};

/**
 * Filter employee reviews by status and end date.  It separates the reviews into two categories: those requiring action and those not requiring action.
 * @param employeeReviews
 * @param currentEmployee
 */
export const filterEmployeeReviewsByStatusAndEndDate = (employeeReviews: EmployeeReview[], currentEmployee: Employee): EmployeeReview[] => {
    const sortedReviews = [...employeeReviews].sort((a, b) => {
        const aDeadline = getEmployeeReviewDeadlineDate(getEmployeeReviewContributorType(a, currentEmployee), a, currentEmployee);
        const bDeadline = getEmployeeReviewDeadlineDate(getEmployeeReviewContributorType(b, currentEmployee), b, currentEmployee);
        if (!aDeadline && !bDeadline) {
            return 0;
        }

        if (!aDeadline || (bDeadline && isBeforeDate(aDeadline, bDeadline))) {
            return -1;
        }

        return 1;
    });
    const adHocReviews = sortedReviews.filter(review => review.review && review?.review.type === 'ONE_SHOT');
    const nonAdHocReviews = sortedReviews.filter(review => review.review && review.review.type !== 'ONE_SHOT');
    const employeeReviewWithActionNeeded = [...nonAdHocReviews].filter(employeeReview => isEmployeeReviewActionNeeded(employeeReview, currentEmployee));
    const employeeReviewWithoutActionNeeded = [...nonAdHocReviews].filter(employeeReview => !isEmployeeReviewActionNeeded(employeeReview, currentEmployee));
    return [...adHocReviews, ...employeeReviewWithActionNeeded, ...employeeReviewWithoutActionNeeded];
};

const isEmployeeReviewActionNeeded = (employeeReview: EmployeeReview, currentEmployee: Employee): boolean => {
    const employeeReviewContributorType = getEmployeeReviewContributorType(employeeReview, currentEmployee);
    return !!getEmployeeReviewDeadlineDate(employeeReviewContributorType, employeeReview, currentEmployee);
};

const getFeedbackLink = (employeeReviewId: number): string => `/reviews/${employeeReviewId}/feedbacks`;
const getSummaryLink = (employeeReviewId: number): string => `/reviews/${employeeReviewId}/summary`;
const getManagerSummaryLink = (employeeReviewId: number): string => `/reviews/${employeeReviewId}/manager-summary`;
export const hasMissingFeedbacks = (employeeReview: EmployeeReview): boolean => {
    if (!employeeReview?.review) {
        return false;
    }

    const review = employeeReview?.review;
    return (
        (isSelfReviewRequired(review) && !employeeReview?.selfReviewed) ||
        (isManagerReviewRequired(review) && employeeReview?.managers?.some(managerReview => !managerReview?.reviewed)) ||
        (isPeerReviewAsked(review) && employeeReview?.peerReviewers?.some(peerReview => !peerReview?.reviewed)) ||
        (isUpwardReviewAsked(review) && employeeReview?.upwardReviewers?.some(upwardReview => !upwardReview?.reviewed))
    );
};

export const getEmployeeReviewDeadlineDate = (
    currentEmployeeContributorType: EmployeeReviewContributorType,
    employeeReview: EmployeeReview,
    currentEmployee: Employee,
): LocalDate | undefined => {
    switch (currentEmployeeContributorType) {
        case 'EMPLOYEE':
            return getEmployeeDeadlineDate(employeeReview);
        case 'MANAGER':
            return getManagerDeadlineDate(employeeReview, currentEmployee);
        case 'OTHER_CONTRIBUTOR':
            return getOtherContributorDeadlineDate(employeeReview, currentEmployee);
        case 'NOT_CONTRIBUTOR':
            return undefined;
    }

    function getOtherContributorDeadlineDate(employeeReview: EmployeeReview, currentEmployee: Employee): LocalDate | undefined {
        const isOtherContributorReviewDone = isPeerReviewDone(employeeReview, currentEmployee) || isUpwardReviewDone(employeeReview, currentEmployee);
        if (employeeReview.status === EmployeeReviewStatus.INPUT_NEEDED) {
            return isOtherContributorReviewDone ? undefined : employeeReview?.review?.feedbackDeadlineDate;
        } else {
            return undefined;
        }
    }

    function getManagerDeadlineDate(employeeReview: EmployeeReview, currentEmployee: Employee): LocalDate | undefined {
        switch (employeeReview.status) {
            case EmployeeReviewStatus.INPUT_NEEDED:
                return getManagerInputNeededDeadlineDate(employeeReview, currentEmployee);
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                return employeeReview?.review?.endDate;
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
            case EmployeeReviewStatus.CLOSED:
            case EmployeeReviewStatus.CANCELLED:
                return undefined;
        }

        function getManagerInputNeededDeadlineDate(employeeReview: EmployeeReview, currentEmployee: Employee): LocalDate | undefined {
            if (!employeeReview.review) {
                return undefined;
            }

            const includeManagerPreparation = isManagerReviewRequired(employeeReview.review);
            const includeSelfPreparation = isSelfReviewRequired(employeeReview.review);
            const selfPreparationDone = employeeReview.selfReviewed;

            if (includeManagerPreparation) {
                if (!isManagerReviewDone(employeeReview, currentEmployee)) {
                    return employeeReview.review.feedbackDeadlineDate;
                }

                if (includeSelfPreparation && !selfPreparationDone) {
                    return undefined;
                }
                return employeeReview.review.endDate;
            }

            if (includeSelfPreparation && !selfPreparationDone) {
                return undefined;
            }

            return employeeReview.review.endDate;
        }
    }

    function getEmployeeDeadlineDate(employeeReview: EmployeeReview): LocalDate | undefined {
        switch (employeeReview.status) {
            case EmployeeReviewStatus.INPUT_NEEDED:
                return getEmployeeInputNeededDeadlineDate(employeeReview);
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
                return employeeReview?.review?.endDate;
            case EmployeeReviewStatus.DISCUSSION_STARTED:
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
            case EmployeeReviewStatus.CLOSED:
            case EmployeeReviewStatus.CANCELLED:
                return undefined;
        }

        function getEmployeeInputNeededDeadlineDate(employeeReview: EmployeeReview): LocalDate | undefined {
            const includeSelfPreparation = employeeReview.review ? isSelfReviewRequired(employeeReview.review) : false;
            const selfPreparationDone = employeeReview.selfReviewed;
            return includeSelfPreparation && !selfPreparationDone ? employeeReview?.review?.feedbackDeadlineDate : undefined;
        }
    }
};
export const getReviewStatusBarConfig = ({
    employeeReview,
    currentEmployeeContributorType,
    currentEmployee,
}: {
    employeeReview: EmployeeReview;
    currentEmployeeContributorType: EmployeeReviewContributorType;
    currentEmployee: Employee;
}): ReviewStatusBarConfig => {
    const link = getEmployeeReviewNavigationLink(employeeReview, currentEmployee);
    const deadlineDate = getEmployeeReviewDeadlineDate(currentEmployeeContributorType, employeeReview, currentEmployee);
    const labelContext = getReviewStatusBarLabelContext(currentEmployeeContributorType, employeeReview, currentEmployee);
    return { labelContext, link, deadlineDate };
};
const getReviewStatusBarLabelContext = (
    currentEmployeeContributorType: EmployeeReviewContributorType,
    employeeReview: EmployeeReview,
    currentEmployee: Employee,
): LabelContext => {
    switch (currentEmployeeContributorType) {
        case 'EMPLOYEE':
            return getEmployeeReviewStatusBarLabelContext(employeeReview);
        case 'MANAGER':
            return getManagerReviewStatusBarLabelContext(employeeReview, currentEmployee);
        case 'OTHER_CONTRIBUTOR':
            return getOtherContributorReviewStatusBarLabelContext(employeeReview, currentEmployee);
        case 'NOT_CONTRIBUTOR':
            return getNotContributorReviewStatusBarConfig(employeeReview);
    }

    function getNotContributorReviewStatusBarConfig(employeeReview: EmployeeReview): LabelContext {
        const isCanSeeReview =
            employeeReview.status === EmployeeReviewStatus.CLOSED ||
            employeeReview.status === EmployeeReviewStatus.EMPLOYEE_APPROVED ||
            employeeReview.status === EmployeeReviewStatus.CANCELLED ||
            employeeReview.status === EmployeeReviewStatus.DISCUSSION_SUBMITTED;

        return isCanSeeReview ? 'NOT_CONTRIBUTOR_READ' : 'NOT_CONTRIBUTOR_WAITING';
    }

    function getOtherContributorReviewStatusBarLabelContext(employeeReview: EmployeeReview, currentEmployee: Employee): LabelContext {
        const isOtherContributorReviewDone = isPeerReviewDone(employeeReview, currentEmployee) || isUpwardReviewDone(employeeReview, currentEmployee);
        if (employeeReview.status === EmployeeReviewStatus.INPUT_NEEDED) {
            return isOtherContributorReviewDone ? 'OTHER_CONTRIBUTOR_READ_MY_FEEDBACK' : 'OTHER_CONTRIBUTOR_INPUT_NEEDED_FILL_IN_PEER_REVIEW';
        } else {
            return isOtherContributorReviewDone ? 'OTHER_CONTRIBUTOR_READ_MY_FEEDBACK' : 'OTHER_CONTRIBUTOR_FEEDBACK_NOT_POSSIBLE';
        }
    }

    function getEmployeeReviewStatusBarLabelContext(employeeReview: EmployeeReview): LabelContext {
        switch (employeeReview.status) {
            case EmployeeReviewStatus.INPUT_NEEDED:
                return getEmployeeInputNeededReviewStatusBarConfig(employeeReview);
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                return 'EMPLOYEE_DISCUSSION_STARTED';
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
                return 'EMPLOYEE_DISCUSSION_SUBMITTED';
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
            case EmployeeReviewStatus.CLOSED:
            case EmployeeReviewStatus.CANCELLED:
                return 'EMPLOYEE_READ';
        }

        function getEmployeeInputNeededReviewStatusBarConfig(employeeReview: EmployeeReview): LabelContext {
            const includeSelfPreparation = employeeReview?.review ? isSelfReviewRequired(employeeReview.review) : false;
            const selfPreparationDone = employeeReview.selfReviewed;

            const isSelfReviewDone = employeeReview.selfReviewed;
            const isManagersReviewDone = employeeReview.managers?.every(manager => manager.reviewed);
            const isEmployeePreparationAccess = employeeReview?.review?.employeePreparationAccess;
            if (isSelfReviewDone && isManagersReviewDone && isEmployeePreparationAccess) {
                return 'EMPLOYEE_INPUT_NEEDED_WAITING_FOR_DISCUSSION';
            }

            return includeSelfPreparation && !selfPreparationDone
                ? 'EMPLOYEE_INPUT_NEEDED_FILL_IN_SELF_REVIEW'
                : 'EMPLOYEE_INPUT_NEEDED_WAITING_FOR_DISCUSSION';
        }
    }

    function getManagerReviewStatusBarLabelContext(employeeReview: EmployeeReview, currentEmployee: Employee): LabelContext {
        switch (employeeReview.status) {
            case EmployeeReviewStatus.INPUT_NEEDED:
                return getManagerInputNeededReviewStatusBarLabelContext(employeeReview, currentEmployee);
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                return 'MANAGER_DISCUSSION_STARTED';
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
                return 'MANAGER_DISCUSSION_SUBMITTED';
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
            case EmployeeReviewStatus.CLOSED:
            case EmployeeReviewStatus.CANCELLED:
                return 'MANAGER_READ';
        }

        function getManagerInputNeededReviewStatusBarLabelContext(employeeReview: EmployeeReview, currentEmployee: Employee): LabelContext {
            const includeManagerPreparation = employeeReview.review ? isManagerReviewRequired(employeeReview.review) : false;
            const includeSelfPreparation = employeeReview.review ? isSelfReviewRequired(employeeReview.review) : false;
            const selfPreparationDone = employeeReview.selfReviewed;

            if (includeManagerPreparation) {
                if (!isManagerReviewDone(employeeReview, currentEmployee)) {
                    return 'MANAGER_INPUT_NEEDED_FILL_IN_MANAGER_REVIEW';
                }

                if (includeSelfPreparation && !selfPreparationDone) {
                    return 'MANAGER_INPUT_NEEDED_WAITING_FOR_SELF_REVIEW';
                }

                return 'MANAGER_INPUT_NEEDED_START_DISCUSSION';
            }

            if (!includeManagerPreparation && includeSelfPreparation && !selfPreparationDone) {
                return 'MANAGER_INPUT_NEEDED_WAITING_FOR_SELF_REVIEW';
            }

            return 'MANAGER_INPUT_NEEDED_START_DISCUSSION';
        }
    }
};
export const selfReviewSubmitted = (employeeReview: EmployeeReview): boolean => {
    return (employeeReview?.review && !isSelfReviewRequired(employeeReview?.review)) || employeeReview?.selfReviewed;
};
export const managerReviewSubmitted = (employeeReview: EmployeeReview): boolean => {
    return (
        (employeeReview?.review && !isManagerReviewRequired(employeeReview?.review)) || employeeReview?.managers?.some(managerReview => managerReview.reviewed)
    );
};

export const showCompletedReviewSummary = (employeeReview: EmployeeReview, employeeReviewManagerSummary: EmployeeReviewManagerSummary): boolean =>
    employeeReview &&
    (isEmployeeReviewEmployeeApproved(employeeReview.status) || isEmployeeReviewClosed(employeeReview.status)) &&
    !!employeeReviewManagerSummary;
export const hasAtLeastOneMissingPeerOrUpwardFeedback = (employeeReview: EmployeeReview): boolean => {
    return (
        employeeReview.peerReviewers?.some(peerReviewer => !peerReviewer.reviewed) ||
        employeeReview.upwardReviewers?.some(upwardReviewer => !upwardReviewer.reviewed)
    );
};

export const employeeReviewService = {
    countEmployeeReviews,
};

export const getEmployeeReviewManagerSummaryDate = (employeeReviewManagerSummary: EmployeeReviewManagerSummary, userLanguage: UserLanguage): string => {
    const isReviewApproved = employeeReviewManagerSummary?.employeeReviewStatus === EmployeeReviewStatus.EMPLOYEE_APPROVED;
    const isReviewClosed = employeeReviewManagerSummary?.employeeReviewStatus === EmployeeReviewStatus.CLOSED;

    const locale = getLocale(userLanguage);

    if (isReviewApproved) {
        const approvedAt = employeeReviewManagerSummary?.approvedAt;
        return formatInDefaultLiteral(approvedAt, locale);
    }
    if (isReviewClosed) {
        const closedAt = employeeReviewManagerSummary?.closedAt;
        return formatInDefaultLiteral(closedAt, locale);
    }
    return '';
};

export const getEmployeeReviewManagerSummaryReviewerEmployee = (employeeReviewManagerSummary: EmployeeReviewManagerSummary): Employee | undefined => {
    const isReviewApproved = employeeReviewManagerSummary?.employeeReviewStatus === EmployeeReviewStatus.EMPLOYEE_APPROVED;
    const isReviewClosed = employeeReviewManagerSummary?.employeeReviewStatus === EmployeeReviewStatus.CLOSED;
    if (isReviewApproved) {
        return employeeReviewManagerSummary.employee;
    }
    if (isReviewClosed) {
        return employeeReviewManagerSummary?.closedBy;
    }
};

export const getEmployeeReviewManagerSummaryComment = (employeeReviewManagerSummary: EmployeeReviewManagerSummary): string => {
    const isReviewApproved = employeeReviewManagerSummary?.employeeReviewStatus === EmployeeReviewStatus.EMPLOYEE_APPROVED;
    const isReviewClosed = employeeReviewManagerSummary?.employeeReviewStatus === EmployeeReviewStatus.CLOSED;
    if (isReviewApproved) {
        return employeeReviewManagerSummary?.approvalComment || '';
    }
    if (isReviewClosed) {
        return employeeReviewManagerSummary?.closeComment || '';
    }
    return '';
};
