import { Survey } from '@/domain/survey/Survey.model';
import { calculatePercentage } from '@/utils/math.util';
import { Grid, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { FC } from 'react';

type Props = {
    survey: Survey;
};

export const Participation: FC<Props> = ({ survey }) => {
    return (
        <Grid container direction='column' width={'100%'}>
            <Grid item>
                <Typography>
                    {calculatePercentage(survey.participationCount, survey.recipientsCount)}% ({survey.participationCount} / {survey.recipientsCount})
                </Typography>
            </Grid>
            <Grid item>
                <LinearProgress variant='determinate' value={calculatePercentage(survey.participationCount, survey.recipientsCount)} />
            </Grid>
        </Grid>
    );
};
