import { Objective, ObjectiveStatus } from '@/domain/objective/Objective.model';
import { getDueLabelKey } from '@/domain/objective/Objective.service';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { useTheme } from '@mui/material';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getLabelTranslation } from '@/utils/language.util';

type EmployeeReviewFeedbackObjectivesPDFProps = {
    objectives: Objective[];
};

export const EmployeeReviewFeedbackObjectivesPDF: FC<EmployeeReviewFeedbackObjectivesPDFProps> = ({ objectives }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <View style={{ gap: theme.spacing(1) }}>
            <Text
                style={{
                    fontSize: 16,
                    paddingLeft: theme.spacing(2),
                    fontWeight: 'bold',
                }}
            >
                {t('reviews.review_summary.objectives')}
            </Text>

            {objectives?.map(objective => <ObjectiveItem objective={objective} key={objective?.id} />)}
        </View>
    );
};

// Todo: add these color to the theme
const OBJECTIVES_STATUS_ON_TRACK_COLOR = '#37CC24';
const OBJECTIVES_STATUS_AT_RISK_COLOR = '#FFA226';
const OBJECTIVES_STATUS_OFF_TRACK_COLOR = '#FF6F69';

const OBJECTIVES_STATUSES = [
    {
        value: ObjectiveStatus.DELAYED,
        labelKey: 'objectives.status.off_track',
        color: OBJECTIVES_STATUS_OFF_TRACK_COLOR,
    },
    {
        value: ObjectiveStatus.AT_RISK,
        labelKey: 'objectives.status.at_risk',
        color: OBJECTIVES_STATUS_AT_RISK_COLOR,
    },
    {
        value: ObjectiveStatus.ON_TRACK,
        labelKey: 'objectives.status.on_track',
        color: OBJECTIVES_STATUS_ON_TRACK_COLOR,
    },
];

type ObjectiveItemProps = {
    objective: Objective;
};

const ObjectiveItem: FC<ObjectiveItemProps> = ({ objective }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const objectiveStatus = OBJECTIVES_STATUSES.find(status => status.value === objective?.lastStatusUpdate?.status);

    return (
        <View
            key={objective?.id}
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                backgroundColor: theme.palette.grey[50],
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
                gap: theme.spacing(1),
            }}
        >
            <View
                style={{
                    gap: theme.spacing(1),
                }}
            >
                <View>
                    <Text
                        style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                        }}
                    >
                        {objective?.name}
                    </Text>
                    <Text
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {getLabelTranslation(objective?.category?.name)}
                    </Text>
                </View>
                <Text>{objective?.description}</Text>;
            </View>
            <View
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: theme.spacing(1),
                    alignItems: 'center',
                }}
            >
                <Text
                    style={{
                        backgroundColor: objectiveStatus?.color,
                        border: '1px black solid',
                        borderRadius: theme.shape.borderRadius,
                        alignItems: 'center',
                        padding: theme.spacing(1),
                        display: !objectiveStatus ? 'none' : undefined,
                    }}
                >
                    {objectiveStatus?.labelKey ? t(objectiveStatus?.labelKey) : ''}{' '}
                </Text>

                <View style={{ alignItems: 'flex-end' }}>
                    {!objective.completionStatus && !!objective?.dueDate && (
                        <Text>
                            {t(getDueLabelKey(objective.dueDate))} : {formatInDefaultDate(objective.dueDate)}
                        </Text>
                    )}

                    {objective?.completionStatus && (
                        <Text>
                            {getLabelTranslation(objective?.completionStatus.name)} {objective?.completedAt ? formatInDefaultDate(objective.completedAt) : ''}
                        </Text>
                    )}
                </View>
            </View>
            ;
        </View>
    );
};
