import { FC } from 'react';
import { RatingFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { useTheme } from '@mui/material';
import { EmployeeReviewFeedbackSkillPDF } from '@/page/review/employee-review-feedback-form-pdf/EmployeeReviewFeedbackSkillPDF';
import { EmployeeReviewFeedbackSummarySkillFormType } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { EmployeeReviewSkillFeedbackFromPreparationPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/EmployeeReviewSkillFeedbackFromPreparationPDF';
import { Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

type EmployeeReviewFeedbackSkillPDFProps = {
    questionItem: EmployeeReviewFeedbackSummarySkillFormType;
    rating: RatingFormType | undefined;
};

export const EmployeeReviewSummaryFeedbackSkillPDF: FC<EmployeeReviewFeedbackSkillPDFProps> = ({ questionItem, rating }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <EmployeeReviewFeedbackSkillPDF questionItem={questionItem} rating={rating}>
            {questionItem.selfFeedbackQuestion && (
                <EmployeeReviewSkillFeedbackFromPreparationPDF employeeReviewFeedbackForm={questionItem.selfFeedbackQuestion} />
            )}

            {questionItem.managerFeedbackQuestion?.map((managerFeedbackSkillQuestion, index) => (
                <EmployeeReviewSkillFeedbackFromPreparationPDF key={index} employeeReviewFeedbackForm={managerFeedbackSkillQuestion} />
            ))}

            {questionItem.upwardFeedbackQuestion?.map((upwardFeedbackSkillQuestion, index) => (
                <EmployeeReviewSkillFeedbackFromPreparationPDF key={index} employeeReviewFeedbackForm={upwardFeedbackSkillQuestion} />
            ))}
            {questionItem.peerFeedbackQuestion?.map((peerFeedbackSkillQuestion, index) => (
                <EmployeeReviewSkillFeedbackFromPreparationPDF key={index} employeeReviewFeedbackForm={peerFeedbackSkillQuestion} />
            ))}

            <Text
                style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    paddingTop: theme.spacing(1),
                }}
            >
                {t('reviews.summary.summary_subtitle')}
            </Text>
        </EmployeeReviewFeedbackSkillPDF>
    );
};
