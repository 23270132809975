import { DayOfWeek } from '@/utils/datetime.util';
import { DayPeriod } from '../date/Date.model';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';

export enum WorkingPatternType {
    FIXED = 'FIXED',
    HOURLY = 'HOURLY',
}

export type WorkingPatternTemplateSearchRequest = {
    name?: string;
};

export type WorkingPatternTemplate = {
    id: number;
    name: string;
    workingPatternType: WorkingPatternType;
    workingPatternTemplateWeekDays: WorkingPatternTemplateWeek[];
    weeklyWorkingTime: WeeklyWorkingTime;
};

export type WorkingPatternTemplateWeek = {
    workingPatternTemplateDays: WorkingPatternTemplateDay[];
};

export type WorkingPatternTemplateDay = {
    dayOfWeek: DayOfWeek;
    dayPeriod: DayPeriod;
    amountWorked: number;
};

export type WorkingPatternTemplateUpdateRequest = {
    name: string;
    type: WorkingPatternType;
    workingPatternTemplateWeekDays: WorkingPatternTemplateWeekRequest[];
    weeklyWorkingTimeId: number;
};

export type WorkingPatternTemplateCreationRequest = {
    name: string;
    type: WorkingPatternType;
    workingPatternTemplateWeekDays: WorkingPatternTemplateWeekRequest[];
    weeklyWorkingTimeId: number;
};

type WorkingPatternTemplateWeekRequest = {
    workingPatternTemplateDays: WorkingPatternTemplateDayRequest[];
};

type WorkingPatternTemplateDayRequest = {
    dayOfWeek: DayOfWeek;
    dayPeriod: DayPeriod;
    amountWorked: number;
};
