import { Components, Theme } from '@mui/material';

export const MuiFormControlLabel: Components<Omit<Theme, 'components'>>['MuiFormControlLabel'] = {
    defaultProps: {
        labelPlacement: 'top',
    },
    styleOverrides: {
        root: {
            // TODO: remove once it's fixed
            marginLeft: 0,
            marginRight: 0,
            textAlign: 'start',
            '.MuiFormControlLabel-label': {
                paddingLeft: 4,
                paddingRight: 4,
            },
        },
        labelPlacementStart: {
            textAlign: 'end',
            alignItems: 'baseline',
        },
        labelPlacementTop: {
            alignItems: 'flex-start',
            // TODO: remove once it's fixed https://github.com/mui/material-ui/issues/34039
            marginLeft: 0,
            marginRight: 0,
        },
        labelPlacementBottom: {
            textAlign: 'center',
        },
    },
};
