import { ButtonBase, Popover, useTheme } from '@mui/material';
import { ColorPickerIcon } from 'hugeicons-react';
import { FC, MouseEvent, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

type Props = {
    color: string;
    onChangeColor: (color: string) => void;
};

export const ColorPickerPopover: FC<Props> = ({ color, onChangeColor }) => {
    const theme = useTheme();
    const grayBorderColor = theme.palette.grey['700'];
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleColorChange = (color: string) => {
        onChangeColor(color);
    };

    return (
        <>
            <ButtonBase
                onClick={handleClick}
                sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    marginRight: 0.5,
                    marginBottom: 0.5,
                    background: color !== '' ? color : grayBorderColor,
                    '& svg': {
                        stroke: 'white',
                    },
                }}
                aria-label='color-picker'
            >
                <ColorPickerIcon size={20} />
            </ButtonBase>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        sx: {
                            overflow: 'visible',
                        },
                    },
                }}
            >
                <HexColorPicker color={color ?? grayBorderColor} onChange={handleColorChange} />
            </Popover>
        </>
    );
};
