import { handleError } from '@/utils/api.util';
import { TimesheetMode, TimesheetSetting, TimesheetSettingMutation } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { deleteTimesheetSettings, postTimesheetSettings, putTimesheetSettings } from '@/domain/timesheet-setting/TimesheetSetting.service';

import { useGetTimesheetSettingById } from '@/page/setting/time-management/TimesheetSettings.hook';
import { TimeManagementSettingForm, TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';

const convertFormValueToMutation = (timesheetSettingForm: TimesheetSettingForm): TimesheetSettingMutation => {
    let timesheetSettingMutation: TimesheetSettingMutation = { ...timesheetSettingForm };

    const isSimplified = timesheetSettingForm.timesheetMode === TimesheetMode.SIMPLIFIED;

    if (isSimplified) {
        //if the mode is simplified there is some fields that should not be visible and the values should not be taken into account
        timesheetSettingMutation = {
            ...timesheetSettingMutation,
            forceBreakClockInOut: false,
            allowClockInOutOutsideWorkHours: false,
            forceShiftStartTimeOnClockIn: false,
            forceShiftStartTimeBeforeInMinutes: 0,
            forceShiftEndTimeOnClockOut: false,
            forceShiftEndTimeAfterInMinutes: 0,
            paidBreaksSaturday: false,
            paidBreaksSundayPublicHolidays: false,
            mobileClockInOut: false,
            nightBonusPercentage: 0,
            nightBonusStartTime: undefined,
            nightBonusEndTime: undefined,
            breakDisplayEnabled: false,
            allowFutureInputs: false,
        };
    }

    if (!timesheetSettingForm.isBonusEnabled) {
        timesheetSettingMutation = {
            ...timesheetSettingMutation,
            nightBonusPercentage: 0,
            nightBonusStartTime: undefined,
            nightBonusEndTime: undefined,
            saturdayBonusPercentage: 0,
            saturdayBonusStartTime: undefined,
            saturdayBonusEndTime: undefined,
            sundayBonusPercentage: 0,
            sundayBonusStartTime: undefined,
            sundayBonusEndTime: undefined,
        };
    }

    if (!timesheetSettingForm.isPaidBreaksEnabled || isSimplified) {
        timesheetSettingMutation = {
            ...timesheetSettingMutation,
            rule1PaidBreaksFrom: undefined,
            rule1PaidBreaksTo: undefined,
            rule1PaidBreaksMaximumDurationInMinutes: 0,
            rule2PaidBreaksFrom: undefined,
            rule2PaidBreaksTo: undefined,
            rule2PaidBreaksMaximumDurationInMinutes: 0,
        };
    }

    if (timesheetSettingMutation.rule1PaidBreaksMaximumDurationInMinutes === 0) {
        timesheetSettingMutation = {
            ...timesheetSettingMutation,
            rule1PaidBreaksFrom: undefined,
            rule1PaidBreaksTo: undefined,
        };
    }

    if (timesheetSettingMutation.rule2PaidBreaksMaximumDurationInMinutes === 0) {
        timesheetSettingMutation = {
            ...timesheetSettingMutation,
            rule2PaidBreaksFrom: undefined,
            rule2PaidBreaksTo: undefined,
        };
    }

    if (!timesheetSettingForm.isForceBreakToBeTakenEnabled || !timesheetSettingMutation.forceBreakClockInOut || isSimplified) {
        timesheetSettingMutation = {
            ...timesheetSettingMutation,
            forceBreakToBeTakenFrom: undefined,
            forceBreakToBeTakenTo: undefined,
        };
    } else {
        timesheetSettingMutation = {
            ...timesheetSettingMutation,
            breakDisplayEnabled: false,
        };
    }

    if (!timesheetSettingMutation.forceBreakClockInOut) {
        timesheetSettingMutation = {
            ...timesheetSettingMutation,
            forceSmallBreakAfterXHours: 0,
            forceSmallBreakDurationInMinutes: 0,
            forceBigBreakAfterXHours: 0,
            forceBigBreakDurationInMinutes: 0,
        };
    }

    timesheetSettingMutation = {
        ...timesheetSettingMutation,
        maximumWeeklyAdditionalWorkingTime: timesheetSettingForm.maximumWeeklyAdditionalWorkingTime * 60,
        autoApproveDifferenceThresholdInMinutes: timesheetSettingForm.isAutoApproveDifferenceThresholdInMinutesEnabled
            ? timesheetSettingForm.autoApproveDifferenceThresholdInMinutes
            : undefined,
    };

    return timesheetSettingMutation;
};

export const TimeManagementSettingPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const isEdit = !!params?.timeManagementId && params?.timeManagementId !== 'new';

    const timeManagementId = isEdit && params?.timeManagementId ? Number(params.timeManagementId) : undefined;

    const [timesheetSettingToDelete, setTimesheetSettingToDelete] = useState<TimesheetSetting>();

    const { data: timesheetSetting, isLoading, isError, error } = useGetTimesheetSettingById(timeManagementId);

    const handleDeleteTimesheetSettings = async (timesheetSettingsId: number) => {
        try {
            await deleteTimesheetSettings(timesheetSettingsId);
            navigate('/settings/time-management/settings');
        } catch (error) {
            handleError(error);
        } finally {
            setTimesheetSettingToDelete(undefined);
        }
    };
    const onSave = async (timesheetSettingForm: TimesheetSettingForm) => {
        const timesheetSettingMutation = convertFormValueToMutation(timesheetSettingForm);
        if (isEdit && timesheetSetting?.id) {
            try {
                await putTimesheetSettings(timesheetSettingMutation, timesheetSetting?.id);
                navigate('/settings/time-management/settings');
            } catch (error) {
                handleError(error);
            }
        } else {
            try {
                await postTimesheetSettings(timesheetSettingMutation);
                navigate('/settings/time-management/settings');
            } catch (error) {
                handleError(error);
            }
        }
    };

    const onDeleteTimesheetSetting = () => {
        setTimesheetSettingToDelete(timesheetSetting);
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <TimeManagementSettingForm timesheetSetting={timesheetSetting} isEdit={isEdit} onSave={onSave} onDelete={onDeleteTimesheetSetting} />

            <ConfirmDialog
                open={!!timesheetSettingToDelete?.id}
                title={t('time_management_settings_page.are_you_sure_delete_setting')}
                content={t('general.action_irreversible')}
                onClose={() => {
                    setTimesheetSettingToDelete(undefined);
                }}
                onConfirm={() => {
                    if (timesheetSettingToDelete?.id) {
                        handleDeleteTimesheetSettings(timesheetSettingToDelete?.id).catch(handleError);
                    }
                }}
                // Override default confirm button props
                confirmButtonProps={{
                    children: t('general.delete'),
                    color: 'error',
                }}
            />
        </StateHandler>
    );
};
