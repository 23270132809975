import { FormControlLabel } from '@mui/material';
import { FC } from 'react';
// todo remove link with planning / scheduler components
import { Select } from '@/components/form/field-select/Select';
import { SelectOption } from '@/page/planning/scheduler-calendar/types';
import { defaultToNull } from '@/utils/object.util';

type SelectResourceProps = {
    label?: string;
    value?: number;
    onUpdate?: (val: number | undefined) => void;
    options: SelectOption[];
    clearButton?: boolean;
    disabled?: boolean;
    isFocusEnabled?: boolean;
    placeHolder?: string;
    isError?: boolean;
};

export const SelectResource: FC<SelectResourceProps> = ({
    label,
    placeHolder,
    value,
    onUpdate,
    options,
    clearButton = false,
    disabled = false,
    isFocusEnabled = false,
    isError,
}) => {
    return (
        <FormControlLabel
            label={label}
            aria-label={label ?? placeHolder ?? 'Select Resource'}
            sx={{ width: '100%' }}
            control={
                <Select
                    value={defaultToNull(options.find(option => option.id === value))}
                    options={options ?? []}
                    disabled={disabled}
                    disableClearable={!clearButton}
                    fullWidth
                    getOptionLabel={option => option.name}
                    onChange={newValue => {
                        onUpdate?.(newValue?.id !== undefined ? Number(newValue.id) : undefined);
                    }}
                    placeholder={placeHolder}
                    autocompleteProps={{
                        autoFocus: isFocusEnabled,
                        // Todo remove this functional id
                        id: 'area-select',
                        disableListWrap: true,
                    }}
                    error={isError}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
            }
        />
    );
};
