import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import { TimesheetRecurringAdjustment } from '@/domain/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.model';
import { getTimesheetRecurringAdjustment } from '@/domain/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.service';

export const useGetTimesheetRecurringAdjustment = (id: number | undefined): UseQueryResult<TimesheetRecurringAdjustment> => {
    const [recurringAdjustment, setRecurringAdjustment] = useState<TimesheetRecurringAdjustment>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchTimesheetRecurringAdjustment = useCallback(async (id: number | undefined) => {
        if (!id) {
            setIsLoading(false);
            return;
        }
        try {
            const timesheetRecurringAdjustment = await getTimesheetRecurringAdjustment(id);
            setRecurringAdjustment(timesheetRecurringAdjustment);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchTimesheetRecurringAdjustment(id).catch(handleError);
    }, [fetchTimesheetRecurringAdjustment, id]);

    return {
        data: recurringAdjustment,
        setData: setRecurringAdjustment,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchTimesheetRecurringAdjustment(id),
    };
};
