import { employeeReviewFeedbackSummaryApi } from '@/api/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.api';
import {
    EmployeeReviewFeedbackSummary,
    EmployeeReviewManagerSummary,
    EmployeeReviewSelfSummary,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { EmployeeReviewFeedbackMutation } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';

export const getEmployeeReviewFeedbacksManagerSummary = async (employeeId: number): Promise<EmployeeReviewManagerSummary> => {
    return employeeReviewFeedbackSummaryApi.getEmployeeReviewFeedbacksManagerSummary(employeeId);
};

export const createEmployeeReviewFeedbacksManagerSummary = async (
    employeeId: number,
    employeeReviewFeedbackSummaryMutations: EmployeeReviewFeedbackMutation[],
): Promise<EmployeeReviewFeedbackSummary[]> => {
    return employeeReviewFeedbackSummaryApi.createEmployeeReviewFeedbacksManagerSummary(employeeId, employeeReviewFeedbackSummaryMutations);
};

export const createEmployeeReviewFeedbacksManagerSummaryItem = async (
    employeeId: number,
    employeeReviewFeedbackSummaryMutation: EmployeeReviewFeedbackMutation,
): Promise<EmployeeReviewFeedbackSummary> => {
    return employeeReviewFeedbackSummaryApi.createEmployeeReviewFeedbacksManagerSummaryItem(employeeId, employeeReviewFeedbackSummaryMutation);
};

export const getEmployeeReviewFeedbacksSummary = async (employeeId: number): Promise<EmployeeReviewManagerSummary> => {
    return employeeReviewFeedbackSummaryApi.getEmployeeReviewFeedbacksSummary(employeeId);
};

export const getEmployeeReviewFeedbacksSelfSummary = async (employeeId: number): Promise<EmployeeReviewSelfSummary> => {
    return employeeReviewFeedbackSummaryApi.getEmployeeReviewFeedbacksSelfSummary(employeeId);
};
