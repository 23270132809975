import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { Country, getCountryList } from '@/utils/countries.util';
import { Box } from '@mui/material';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export type CountrySelectProps = {
    formValueName: string;
    countryValue: Country | null;
    disabled?: boolean;
};

export const CountrySelect: FC<CountrySelectProps> = ({ formValueName, countryValue, disabled }) => {
    const countryList = getCountryList();

    const { control } = useFormContext<Record<string, CountrySelectProps['countryValue']>>();

    return (
        <FieldSelect
            name={formValueName}
            control={control}
            defaultValue={countryValue}
            isOptionEqualToValue={(option, anotherOption) => option.value === anotherOption.value}
            fullWidth
            options={countryList}
            getOptionLabel={option => option.label}
            autocompleteProps={{
                id: formValueName,
                autoHighlight: true,
                renderOption: (props, option) => (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading='lazy'
                            width='20'
                            src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                            alt=''
                        />
                        {option.label} ({option.value})
                    </Box>
                ),
            }}
            // 'aria-label': formValueName,
            disabled={disabled}
        />
    );
};
