import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { CustomList, CustomListCreateMutation, CustomListItem, CustomListUpdateMutation } from '@/domain/custom-list/CustomList.model';

type CustomListItemDTO = CustomListItem;
type CustomListDTO = Overwrite<CustomList, { items: CustomListItemDTO[] }>;
type CustomListCreateRequestDTO = CustomListCreateMutation;
type CustomListUpdateRequestDTO = CustomListUpdateMutation;

const getCustomLists = async (): Promise<CustomList[]> => {
    return (await client.get<CustomListDTO[]>(API_BASE_URL + '/custom-lists')).data;
};

const createCustomList = async (mutation: CustomListCreateMutation): Promise<CustomList> => {
    return (await client.post<CustomListDTO, AxiosResponse<CustomListDTO>, CustomListCreateRequestDTO>(API_BASE_URL + `/custom-lists`, mutation)).data;
};

const updateCustomList = async (id: number, mutation: CustomListUpdateMutation): Promise<CustomList> => {
    return (await client.put<CustomListDTO, AxiosResponse<CustomListDTO>, CustomListUpdateRequestDTO>(API_BASE_URL + `/custom-lists/${id}`, mutation)).data;
};

const deleteCustomListById = async (id: number): Promise<void> => {
    return client.delete(API_BASE_URL + `/custom-lists/${id}`);
};

export const customListApi = {
    getCustomLists,
    createCustomList,
    updateCustomList,
    deleteCustomListById,
};
