import { Components, linearProgressClasses, Theme } from '@mui/material';

export const MuiLinearProgress: Components<Omit<Theme, 'components'>>['MuiLinearProgress'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            height: 10,
            borderRadius: 5,
            [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: theme.palette.primary.light,
                ...theme.applyStyles('dark', {
                    backgroundColor: theme.palette.primary.light,
                }),
            },
        }),
    },
};
