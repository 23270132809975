import { SectionDefinition } from '@/domain/section-setting/Section.model';

import { ImportType } from '@/domain/import/Import.model';

const isTableImport = (importType: ImportType, activeCustomSection: SectionDefinition | undefined): boolean => {
    const isCustomTableImport = !!activeCustomSection && activeCustomSection.type === 'CUSTOM_MULTI_ROW';
    const isNormalTableImport = importType === ImportType.ADDRESS;
    return isCustomTableImport || isNormalTableImport;
};

export const getCheckboxesTranslationKey = (
    importType: ImportType,
    activeCustomSection: SectionDefinition | undefined,
): {
    onlyIfEmptyTranslationKey: string;
    shouldReplaceTranslationKey: string;
} => {
    if (isTableImport(importType, activeCustomSection)) {
        return {
            onlyIfEmptyTranslationKey: 'import_page.add_rows',
            shouldReplaceTranslationKey: 'import_page.delete_and_replace_all_rows',
        };
    }
    return {
        onlyIfEmptyTranslationKey: 'import_page.add_only_if_empty',
        shouldReplaceTranslationKey: 'import_page.delete_and_replace',
    };
};
