import { SnackbarWrapper } from '@/components/snackbar-wrapper/SnackbarWrapper';
import { DatePickerLocalizationProvider } from '@/app-entry-point/DatePickerLocalizationProvider';
import { getCurrentAppVersion } from '@/config/config';
import { Router } from '@/routes/Router';
import { store } from '@/stores/store';
import { createApplicationTheme } from '@/theme/theme';
import { Button, Snackbar, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { AuthProvider } from './AuthProvider';

export const AppEntryPoint: FC = () => {
    const theme = createApplicationTheme();

    const { t } = useTranslation();

    const { hasNewVersion, reload } = useAppVersion();

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <DatePickerLocalizationProvider>
                        <StyledEngineProvider injectFirst>
                            <SnackbarWrapper />
                            <Router />
                        </StyledEngineProvider>
                    </DatePickerLocalizationProvider>
                </AuthProvider>

                <Snackbar
                    open={hasNewVersion}
                    message={t('version.new_version')}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    action={<Button onClick={reload}>{t('version.reload')}</Button>}
                />
            </ThemeProvider>
        </Provider>
    );
};

const useAppVersion = () => {
    const [hasNewVersion, setHasNewVersion] = useState(false);

    // fetch app version every minute
    const APP_VERSION_FETCH_INTERVAL = 60000;

    useEffect(() => {
        const id = setInterval(fetchNewVersion, APP_VERSION_FETCH_INTERVAL);
        return () => clearInterval(id);
    }, []);

    const fetchNewVersion = async () => {
        try {
            const newVersion = await fetchVersion();
            const isNonDev = !import.meta.env.DEV;
            const currentVersion = getCurrentAppVersion();
            const isNewVersion = !!currentVersion && !!newVersion && currentVersion?.trim() !== newVersion?.trim();

            if (isNonDev && isNewVersion) {
                setHasNewVersion(true);
            }
        } catch (err) {
            console.error('Error fetching app version:', err);
        }
    };

    const reload = () => {
        try {
            window.sessionStorage.clear();

            const keys = window.localStorage.getItem('storageKeys');
            // We are using local storage to store the keys of the items we want to remove
            if (keys) {
                (JSON.parse(keys) as string[]).forEach(key => {
                    window.localStorage.removeItem(key);
                });
            }
            window.location.reload();
        } catch (e) {
            console.error('Error reloading app:', e);
        }
    };
    return { hasNewVersion, reload };
};

const fetchVersion = (): Promise<string> => {
    return fetch('/roger_version', { cache: 'no-cache' }).then(response => response.text());
};
