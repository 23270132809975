import * as yup from 'yup';
import { formatDurationInHours, getDurationFromTime, isValidTime } from '@/utils/datetime.util';

import i18next from 'i18next';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { getNull } from '@/utils/object.util';
import { diffTimesInMinutesWithOvernight, MAX_TIME_PER_DAY_IN_MINUTES } from '@/page/timesheet/timesheet-dialog/TimesheetDialog.util';

const getEmployeeTimesheetCommonSchema = () => {
    return yup.object().shape({
        missingDates: yup.array().required().of(yup.string<LocalDate>().required()).default([]),
        employeeId: yup.number().required(),
        referenceDate: yup.string<LocalDate>().required(),
    });
};
export const getEmployeeTimesheetNormalModeSchema = (timesheetSetting?: TimesheetSetting) => {
    return getEmployeeTimesheetCommonSchema().concat(
        yup.object().shape({
            timesheets: getTimesheetNormalModeArraySchema(timesheetSetting),
        }),
    );
};

export const getEmployeeTimesheetSimplifiedModeSchema = (timesheetSetting?: TimesheetSetting) => {
    return getEmployeeTimesheetCommonSchema().concat(
        yup.object().shape({
            timesheets: getTimesheetSimplifiedModeArraySchema(timesheetSetting),
        }),
    );
};

export type EmployeeTimesheetNormalModeFormValues = yup.InferType<ReturnType<typeof getEmployeeTimesheetNormalModeSchema>>;
export type EmployeeTimesheetSimplifiedModeFormValues = yup.InferType<ReturnType<typeof getEmployeeTimesheetSimplifiedModeSchema>>;
export type EmployeeTimesheetFormValues = EmployeeTimesheetNormalModeFormValues | EmployeeTimesheetSimplifiedModeFormValues;

const getTimesheetNormalModeArraySchema = (timesheetSetting?: TimesheetSetting) => {
    return yup
        .array()
        .required()
        .of(getTimesheetNormalModeSchema(timesheetSetting))
        .test({
            message: i18next.t('timesheets.error_missing_end_date'),
            test: timesheets => {
                return timesheets?.every(timesheet => {
                    return isValidTime(timesheet.endTime);
                });
            },
        })
        .test({
            message: i18next.t('timesheets.error_shifts_too_long', { maxHours: formatDurationInHours(MAX_TIME_PER_DAY_IN_MINUTES) }),
            test: timesheets => {
                if (!timesheets) {
                    return false;
                }
                const totalTime = timesheets.reduce((accumulator, timesheet) => {
                    return accumulator + diffTimesInMinutesWithOvernight(timesheet.startTime, timesheet.endTime) - (timesheet.breakDuration ?? 0);
                }, 0);
                return totalTime <= MAX_TIME_PER_DAY_IN_MINUTES;
            },
        })
        .test({
            message: i18next.t('timesheets.error_missing_break', {
                minutes: timesheetSetting?.forceSmallBreakDurationInMinutes,
                hours: formatDurationInHours((timesheetSetting?.forceSmallBreakAfterXHours ?? 0) * 60),
            }),
            test: timesheets => {
                if (
                    !shouldCheckForceBreaks(
                        timesheets as TimesheetNormalModeFormValues[],
                        timesheetSetting?.forceSmallBreakAfterXHours,
                        timesheetSetting?.forceSmallBreakDurationInMinutes,
                        timesheetSetting?.forceBreakToBeTakenTo,
                        timesheetSetting?.forceBreakToBeTakenFrom,
                    )
                ) {
                    return true;
                }

                const totalTimeMinutes =
                    timesheets?.reduce((accumulator, timesheet) => {
                        return accumulator + diffTimesInMinutesWithOvernight(timesheet.startTime, timesheet.endTime) - (timesheet.breakDuration ?? 0);
                    }, 0) ?? 0;

                const breakDurationMinutes = calculateBreakDuration(timesheets as TimesheetNormalModeFormValues[]);

                if (
                    totalTimeMinutes > (timesheetSetting?.forceSmallBreakAfterXHours ?? 0) * 60 &&
                    breakDurationMinutes < (timesheetSetting?.forceSmallBreakDurationInMinutes ?? 0)
                ) {
                    //in case the duration is bigger than the big break, we dont want to display the message for small break
                    return !!timesheetSetting?.forceBigBreakAfterXHours && totalTimeMinutes > timesheetSetting?.forceBigBreakAfterXHours * 60;
                }

                return true;
            },
        })
        .test({
            message: i18next.t('timesheets.error_missing_break', {
                minutes: timesheetSetting?.forceBigBreakDurationInMinutes,
                hours: formatDurationInHours((timesheetSetting?.forceBigBreakAfterXHours ?? 0) * 60),
            }),
            test: timesheets => {
                if (
                    !shouldCheckForceBreaks(
                        timesheets as TimesheetNormalModeFormValues[],
                        timesheetSetting?.forceBigBreakAfterXHours,
                        timesheetSetting?.forceBigBreakDurationInMinutes,
                        timesheetSetting?.forceBreakToBeTakenTo,
                        timesheetSetting?.forceBreakToBeTakenFrom,
                    )
                ) {
                    return true;
                }

                const totalTimeMinutes =
                    timesheets?.reduce((accumulator, timesheet) => {
                        return accumulator + diffTimesInMinutesWithOvernight(timesheet.startTime, timesheet.endTime) - (timesheet.breakDuration ?? 0);
                    }, 0) ?? 0;

                const breakDurationMinutes = calculateBreakDuration(timesheets as TimesheetNormalModeFormValues[]);

                return !(
                    totalTimeMinutes > (timesheetSetting?.forceBigBreakAfterXHours ?? 0) * 60 &&
                    breakDurationMinutes < (timesheetSetting?.forceBigBreakDurationInMinutes ?? 0)
                );
            },
        });
};

const getTimesheetSimplifiedModeArraySchema = (timesheetSetting?: TimesheetSetting) => {
    return yup
        .array()
        .required()
        .of(getTimesheetSimplifiedModeSchema(timesheetSetting))
        .test({
            message: i18next.t('timesheets.error_missing_end_date'),
            test: timesheets => {
                return timesheets?.every(timesheet => {
                    return isValidTime(timesheet.duration);
                });
            },
        })
        .test({
            message: i18next.t('timesheets.error_shifts_too_long', { maxHours: formatDurationInHours(MAX_TIME_PER_DAY_IN_MINUTES) }),
            test: timesheets => {
                if (!timesheets) {
                    return false;
                }
                const totalTime = timesheets.reduce((accumulator, timesheet) => {
                    return accumulator + getDurationFromTime(timesheet.duration);
                }, 0);
                return totalTime <= MAX_TIME_PER_DAY_IN_MINUTES;
            },
        });
};

const getTimesheetCommonSchema = (timesheetSetting?: TimesheetSetting) =>
    yup.object().shape({
        id: yup.number().nullable(),
        comment: yup.string().when({
            is: () => !timesheetSetting?.mandatoryComment,
            then: schema => schema.nullable(),
            otherwise: schema => schema.required(),
        }),
        area: yup
            .object()
            .shape({
                id: yup.number().required(),
                name: yup.string().required(),
            })
            .nullable()
            .default(getNull()),
    });

const getTimesheetNormalModeSchema = (timesheetSetting?: TimesheetSetting) => {
    const normalModeFieldsSchema = yup.object().shape({
        startTime: yup
            .string<LocalTime>()
            .required()
            .test('max between timesheets', (startTime, context) => {
                //https://github.com/jquense/yup/issues/204
                //how to get the index from
                const index = parseInt(context.path.split('[')[1].split(']')[0]);
                if (index === 0) {
                    return true;
                }

                return !isStartTimeFieldInError(startTime, context.parent.endTime);
            }),
        endTime: yup
            .string<LocalTime>()
            .required()
            .test(endTime => isValidTime(endTime)),
        breakDuration: yup
            .number()
            .required()
            .test((breakDuration, context) => {
                const endTime = context.parent.endTime;
                const startTime = context.parent.startTime;
                return !isBreakFieldInError(breakDuration, startTime, endTime);
            }),
    });
    return getTimesheetCommonSchema(timesheetSetting).concat(normalModeFieldsSchema);
};

const getTimesheetSimplifiedModeSchema = (timesheetSetting?: TimesheetSetting) => {
    const simplifiedModeFieldsSchema = yup.object().shape({
        duration: yup
            .string<LocalTime>()
            .required()
            .test(duration => getDurationFromTime(duration) < MAX_TIME_PER_DAY_IN_MINUTES),
    });
    return getTimesheetCommonSchema(timesheetSetting).concat(simplifiedModeFieldsSchema);
};

export type TimesheetNormalModeFormValues = yup.InferType<ReturnType<typeof getTimesheetNormalModeSchema>>;
export type TimesheetSimplifiedModeFormValues = yup.InferType<ReturnType<typeof getTimesheetSimplifiedModeSchema>>;

const isStartTimeFieldInError = (newStartTime: LocalTime, endTime: LocalTime): boolean => {
    // check duration of timesheet
    return diffTimesInMinutesWithOvernight(newStartTime, endTime) >= MAX_TIME_PER_DAY_IN_MINUTES;
};

const isBreakFieldInError = (breakDuration: number, startTime: LocalTime, endTime: LocalTime) => {
    const duration = diffTimesInMinutesWithOvernight(startTime, endTime);
    return breakDuration > duration;
};

const shouldCheckForceBreaks = (
    timesheets: TimesheetNormalModeFormValues[],
    forceBreakAfterXHours: number | undefined,
    forceBreakDurationInMinutes: number | undefined,
    forceBreakToBeTakenFrom: string | undefined,
    forceBreakToBeTakenTo: string | undefined,
) => {
    if (!timesheets || !forceBreakAfterXHours || !forceBreakDurationInMinutes) {
        return false;
    }
    //if we have a force break to be taken at a certain time, we dont do the validation in the FE we do it in the BE for now
    if (forceBreakToBeTakenFrom && forceBreakToBeTakenTo) {
        return false;
    }

    const areTimesheetsInvalid = timesheets.some(timesheet => {
        return !isValidTime(timesheet.endTime);
    });

    return !areTimesheetsInvalid;
};

const calculateBreakDuration = (timesheets: TimesheetNormalModeFormValues[]) => {
    return timesheets.reduce((totalInterval, currentTimesheet, index, array) => {
        // Add break duration of current timesheet
        totalInterval += currentTimesheet.breakDuration ?? 0;

        // Calculate interval to previous timesheet (if it exists)
        if (index > 0) {
            const previousTimesheet = array[index - 1];
            const intervalBetweenTimesheets = diffTimesInMinutesWithOvernight(previousTimesheet.endTime, currentTimesheet.startTime);
            totalInterval += intervalBetweenTimesheets;
        }
        return totalInterval;
    }, 0);
};
