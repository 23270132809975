import { canContributeEmployeeReview, canManageReview } from '@/domain/permission/Permission.service';
import { ManageEmployeeReviewsCyclePage } from '@/page/review/employee-review-cycle-page/ManageEmployeeReviewsCyclePage';
import { EmployeeReviewFeedbackFormPage } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage';
import { EmployeeReviewManagerSummaryFeedbackFormPage } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewManagerSummaryFeedbackFormPage';
import { EmployeeReviewSummaryFeedbackFormPage } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackFormPage';
import { AdHocEmployeeReviewsPage } from '@/page/review/employee-reviews-management/AdHocEmployeeReviewsPage';
import { EmployeeReviewsMeetingPage } from '@/page/review/employee-reviews-management/EmployeeReviewsMeetingPage';
import { OffboardingEmployeeReviewsPage } from '@/page/review/employee-reviews-management/OffboardingEmployeeReviewsPage';
import { OnboardingEmployeeReviewsPage } from '@/page/review/employee-reviews-management/OnboardingEmployeeReviewsPage';
import { AddReviewersPreviewFormPage } from '@/page/review/review-create-cycle-workflow/AddReviewersPreviewFormPage';
import { InvitationsFormPage } from '@/page/review/review-create-cycle-workflow/InvitationsFormPage';
import { ReviewSetupFormPage } from '@/page/review/review-create-cycle-workflow/ReviewSetupFormPage';
import { ReviewCycleReportPage } from '@/page/review/review-cycle-report-page/ReviewCycleReportPage';
import { ReviewTasks } from '@/page/review/review-tasks/ReviewTasks';
import { ManageReviewsCyclePage } from '@/page/review/reviews-management/ManageReviewsCyclePage';
import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RoutesConfig } from '@/routes/Routes.type';
import { useCurrentPolicies } from '@/stores/store';
import { FC } from 'react';
import { Navigate, RouteObject } from 'react-router';

const config: RoutesConfig[] = [
    {
        path: 'tasks',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <ReviewTasks />,
    },
    {
        path: 'manage-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <ManageReviewsCyclePage />,
    },
    {
        path: 'team-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <EmployeeReviewsMeetingPage />,
    },
    {
        path: 'onboarding-employee-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <OnboardingEmployeeReviewsPage />,
    },
    {
        path: 'offboarding-employee-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <OffboardingEmployeeReviewsPage />,
    },
    {
        path: 'one-shot-employee-reviews',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <AdHocEmployeeReviewsPage />,
    },
];

const subRoutes: RoutesConfig[] = [
    {
        path: 'manage-reviews/:reviewId/setup',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <ReviewSetupFormPage />,
    },
    {
        path: 'manage-reviews/:reviewId/reviewers',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <AddReviewersPreviewFormPage />,
    },
    {
        path: 'manage-reviews/:reviewId/invitations',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <InvitationsFormPage />,
    },
    {
        path: 'manage-reviews/:reviewId',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <ManageEmployeeReviewsCyclePage />,
    },
    {
        path: 'manage-reviews/:reviewId/report',
        breadCrumb: [{ nameKey: 'pages.reviews_cycle_report' }],
        component: <ReviewCycleReportPage />,
    },
    {
        path: ':employeeReviewId/manager-summary',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <EmployeeReviewManagerSummaryFeedbackFormPage />,
    },
    {
        path: ':employeeReviewId/summary',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <EmployeeReviewSummaryFeedbackFormPage />,
    },
    {
        path: ':employeeReviewId/feedbacks',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <EmployeeReviewFeedbackFormPage isPreview={false} />,
    },
    {
        path: ':employeeReviewId/feedbacks/preview',
        breadCrumb: [{ nameKey: 'pages.reviews' }],
        component: <EmployeeReviewFeedbackFormPage isPreview={true} />,
    },
];

export const Fallback: FC = () => {
    const policies = useCurrentPolicies();

    const getDefaultUrl = (): string => {
        if (canContributeEmployeeReview(policies)) {
            return '/reviews/tasks';
        }
        if (canManageReview(policies)) {
            return '/reviews/manage-reviews';
        }
        return '';
    };

    return <Navigate to={getDefaultUrl()} replace />;
};
// eslint-disable-next-line react-refresh/only-export-components
export const reviewRoutes: RouteObject[] = [
    {
        path: 'reviews',
        children: [
            {
                path: '',
                errorElement: <RouterBoundary />,
                children: [
                    {
                        path: '',
                        element: <Fallback />,
                    },
                    ...config.map(config => ({
                        path: config.path,
                        element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                        errorElement: <RouterBoundary />,
                    })),
                ],
            },
            ...subRoutes.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
