import { OrganizationBilling, OrganizationBillingSearchRequest } from '@/domain/organization_billing/OrganizationBilling.model';
import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';

type OrganizationBillingDTO = OrganizationBilling;
type OrganizationBillingSearchRequestDTO = OrganizationBillingSearchRequest;

const getOrganizationBilling = async (search: OrganizationBillingSearchRequest): Promise<OrganizationBillingDTO> => {
    return (
        await client.post<OrganizationBillingDTO, AxiosResponse<OrganizationBillingDTO>, OrganizationBillingSearchRequestDTO>(
            API_BASE_URL + '/organization/billing',
            search,
        )
    ).data;
};

export const organizationBillingApi = {
    getOrganizationBilling,
};
