import { LongLeave, LongLeaveCreateMutation, LongLeaveSearch, LongLeaveUpdateMutation } from '@/domain/long-leave/LongLeave.model';
import { longLeaveApi } from '@/api/long-leave/LongLeave.api';

export const searchLongLeaves = async (request: LongLeaveSearch): Promise<LongLeave[]> => {
    return longLeaveApi.searchLongLeaves(request);
};

export const createLongLeave = async (mutation: LongLeaveCreateMutation): Promise<LongLeave> => {
    return longLeaveApi.createLongLeave(mutation);
};

export const updateLongLeave = async (longLeaveId: number, mutation: LongLeaveUpdateMutation): Promise<LongLeave> => {
    return longLeaveApi.updateLongLeave(longLeaveId, mutation);
};

export const deleteLongLeave = async (id: number): Promise<void> => {
    return longLeaveApi.deleteLongLeave(id);
};
