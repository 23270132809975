import { useLocation } from 'react-router';

export const useEmployeeProfileTab = (): PROFILE_TABS => {
    const location = useLocation();

    const activeProfileTab = location.pathname.split('/').pop();

    if (!activeProfileTab) {
        throw new Error('No active profile tab found');
    }

    if (isProfileTab(activeProfileTab)) {
        return activeProfileTab;
    }

    throw new Error('Invalid profile tab');
};

const isProfileTab = (tab: string): tab is PROFILE_TABS => {
    return PROFILE_TABS.includes(tab);
};

const PROFILE_TABS = ['personal-info', 'job-info', 'leaves', 'documents', 'history', 'planning', 'reviews', 'objectives', 'roles', 'timesheets', 'skills'];
export type PROFILE_TABS = (typeof PROFILE_TABS)[number];
