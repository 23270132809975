import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
    onChange: (totalMinutes: number) => void;
    totalMinutes: number;
    endTime: boolean;
    startTimeInMinutes: number;
    endTimeInMinutes: number;
    requestIncrement?: `MIN_${number}`;
};

export const DurationTimePicker: FC<Props> = ({ startTimeInMinutes, endTimeInMinutes, requestIncrement, onChange, totalMinutes, endTime }) => {
    const getMinutes = () => {
        if (!requestIncrement) {
            return [];
        }
        const minutesStep: number | undefined = Number(requestIncrement.split('_')[1]);
        const minutes: number[] = [];
        if (minutesStep) {
            for (let minute = 0; minute < 60; minute = minute + minutesStep) {
                minutes.push(minute);
            }
        }
        if (startTimeInMinutes && endTime && startTimeInMinutes === endTimeInMinutes) {
            const startMinutes = Math.floor(startTimeInMinutes % 60);
            const minutesIndex = minutes.findIndex(el => el === startMinutes);
            minutes.splice(0, minutesIndex);
        }
        return minutes;
    };

    const getMinutesInTimeFormat = (number: number) => {
        const numberLength = number.toString().length;
        if (numberLength === 1) {
            return `0${number}`;
        } else {
            return number;
        }
    };

    return (
        <Stack gap={1} direction='row' alignItems='center' divider={<Typography variant='body2bold'>:</Typography>}>
            <Select
                disabled={!requestIncrement}
                id='hours'
                fullWidth={true}
                value={convertToHours(totalMinutes)}
                onChange={event => {
                    const totalMin = Number(event.target.value) * 60 + convertToMinutes(totalMinutes);
                    onChange(totalMin);
                }}
            >
                {getHours()?.map(hour => (
                    <MenuItem key={hour} value={hour}>
                        {getMinutesInTimeFormat(hour)}
                    </MenuItem>
                ))}
            </Select>
            <Select
                id='minutes'
                fullWidth={true}
                disabled={!requestIncrement}
                value={convertToMinutes(totalMinutes)}
                onChange={event => {
                    const totalMin = convertToHours(totalMinutes) * 60 + Number(event.target.value);
                    onChange(totalMin);
                }}
            >
                {getMinutes()?.map(minute => (
                    <MenuItem key={minute} value={minute}>
                        {getMinutesInTimeFormat(minute)}
                    </MenuItem>
                ))}
            </Select>
        </Stack>
    );
};

function convertToHours(minutes: number): number {
    return Math.floor(minutes / 60);
}

function convertToMinutes(minutes: number): number {
    return Math.floor(minutes % 60);
}

const getHours = (startTimeInMinutes?: number, endTime?: number) => {
    const hours: number[] = [];
    for (let hour = 0; hour < 24; hour++) {
        hours.push(hour);
    }

    if (startTimeInMinutes && endTime) {
        hours.splice(0, convertToHours(startTimeInMinutes));
    }
    return hours;
};
