import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { EmployeeReview, EmployeeReviewSearch } from '@/domain/employee-review/EmployeeReview.model';
import {
    employeeReviewService,
    getEmployeeReview,
    getOngoingReviews,
    searchEmployeeReviewAsContributor,
    searchEmployeeReviews,
} from '@/domain/employee-review/EmployeeReview.service';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Employee } from '@/domain/employee/Employee.model';
import { ReviewStatus } from '@/domain/review/Review.model';
import { EmployeeReviewManagerSummary, EmployeeReviewSelfSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import {
    getEmployeeReviewFeedbacksManagerSummary,
    getEmployeeReviewFeedbacksSelfSummary,
    getEmployeeReviewFeedbacksSummary,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.service';
import { handleError } from '@/utils/api.util';

export const useGetEmployeeReviewAsContributor = ({
    employeeReviewSearch,
}: {
    employeeReviewSearch: EmployeeReviewSearch;
}): UseQueryResult<EmployeeReview[]> => {
    const [employeeReviewsAsContributor, setEmployeeReviewsAsContributor] = useState<EmployeeReview[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeReviewAsContributor = useCallback(async (employeeReviewSearch: EmployeeReviewSearch) => {
        try {
            const employeeReviewsAsContributor = await searchEmployeeReviewAsContributor(employeeReviewSearch);
            setEmployeeReviewsAsContributor(employeeReviewsAsContributor);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchEmployeeReviewAsContributor(employeeReviewSearch).catch(handleError);
    }, [employeeReviewSearch]);

    return {
        data: employeeReviewsAsContributor,
        setData: setEmployeeReviewsAsContributor,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeReviewAsContributor(employeeReviewSearch),
    };
};

export const useGetEmployeeReview = (employeeReviewId: number | undefined): UseQueryResult<EmployeeReview> => {
    const [employeeReview, setEmployeeReview] = useState<EmployeeReview>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeReview = useCallback(async () => {
        if (!employeeReviewId) {
            setIsLoading(false);
            return;
        }

        try {
            const employeeReview = await getEmployeeReview(employeeReviewId);
            setEmployeeReview(employeeReview);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, [employeeReviewId]);

    useEffect(() => {
        fetchEmployeeReview().catch(handleError);
    }, [fetchEmployeeReview]);

    return {
        data: employeeReview,
        setData: setEmployeeReview,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchEmployeeReview,
    };
};

export const useGetOngoingEmployeeReviews = (employeeId: number): UseQueryResult<EmployeeReview[]> => {
    const [employeeReviews, setEmployeeReviews] = useState<EmployeeReview[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchOngoingEmployeeReviews = useCallback(async () => {
        try {
            const employeeReviews = await searchEmployeeReviews({
                reviewStatuses: [ReviewStatus.STARTED],
                employeeId,
            });
            setEmployeeReviews(getOngoingReviews(employeeReviews));
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, [employeeId]);

    useEffect(() => {
        fetchOngoingEmployeeReviews().catch(handleError);
    }, [fetchOngoingEmployeeReviews]);

    return {
        data: employeeReviews,
        setData: setEmployeeReviews,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchOngoingEmployeeReviews,
    };
};

export const useCountOngoingEmployeeReviews = (currentEmployee: Employee, enabled = true): UseQueryResult<number> => {
    const [count, setCount] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCountOngoingEmployeeReviews = useCallback(async (currentEmployee: Employee, enabled: boolean) => {
        if (!enabled) {
            setCount(0);
            setIsLoading(false);
            return;
        }
        try {
            const data = await employeeReviewService.countEmployeeReviews({ reviewStatuses: [ReviewStatus.STARTED] }, currentEmployee);
            setCount(data);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useDeepCompareEffect(() => {
        fetchCountOngoingEmployeeReviews(currentEmployee, enabled).catch(handleError);
    }, [fetchCountOngoingEmployeeReviews, enabled, currentEmployee]);

    return {
        data: count,
        setData: setCount,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchCountOngoingEmployeeReviews(currentEmployee, enabled),
    };
};

export const useGetEmployeeReviewManagerSummary = (employeeReviewId: number | undefined): UseQueryResult<EmployeeReviewManagerSummary> => {
    const [employeeReviewManagerSummary, setEmployeeReviewManagerSummary] = useState<EmployeeReviewManagerSummary>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeReviewManagerSummary = useCallback(async () => {
        if (!employeeReviewId) {
            setIsLoading(false);
            return;
        }

        try {
            const employeeReviewManagerSummary = await getEmployeeReviewFeedbacksManagerSummary(employeeReviewId);
            setEmployeeReviewManagerSummary(employeeReviewManagerSummary);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, [employeeReviewId]);

    useEffect(() => {
        fetchEmployeeReviewManagerSummary().catch(handleError);
    }, [fetchEmployeeReviewManagerSummary]);

    return {
        data: employeeReviewManagerSummary,
        setData: setEmployeeReviewManagerSummary,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchEmployeeReviewManagerSummary,
    };
};

export const useGetEmployeeReviewSelfSummary = (employeeReviewId: number | undefined, enabled = false): UseQueryResult<EmployeeReviewSelfSummary> => {
    const [employeeReviewSelfSummary, setEmployeeReviewSelfSummary] = useState<EmployeeReviewSelfSummary>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeReviewSelfSummary = useCallback(async () => {
        if (!employeeReviewId || !enabled) {
            setIsLoading(false);
            return;
        }

        try {
            const employeeReviewSelfSummary = await getEmployeeReviewFeedbacksSelfSummary(employeeReviewId);
            setEmployeeReviewSelfSummary(employeeReviewSelfSummary);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, [employeeReviewId, enabled]);

    useEffect(() => {
        fetchEmployeeReviewSelfSummary().catch(handleError);
    }, [fetchEmployeeReviewSelfSummary]);

    return {
        data: employeeReviewSelfSummary,
        setData: setEmployeeReviewSelfSummary,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchEmployeeReviewSelfSummary,
    };
};

export const useGetEmployeeReviewSummary = (employeeReviewId: number | undefined, enabled = false): UseQueryResult<EmployeeReviewManagerSummary> => {
    const [employeeReviewSummary, setEmployeeReviewSummary] = useState<EmployeeReviewManagerSummary>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeReviewSummary = useCallback(async () => {
        if (!employeeReviewId || !enabled) {
            setIsLoading(false);
            return;
        }

        try {
            const employeeReviewSummary = await getEmployeeReviewFeedbacksSummary(employeeReviewId);
            setEmployeeReviewSummary(employeeReviewSummary);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, [employeeReviewId, enabled]);

    useEffect(() => {
        fetchEmployeeReviewSummary().catch(handleError);
    }, [fetchEmployeeReviewSummary]);

    return {
        data: employeeReviewSummary,
        setData: setEmployeeReviewSummary,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchEmployeeReviewSummary,
    };
};
