import { FC } from 'react';
import { ChangePasswordForm } from './change-password-form/ChangePasswordForm';
import { useSearchParams } from 'react-router';
import { useTranslation } from 'react-i18next';

export const ResetPasswordPage: FC = () => {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const username = searchParams.get('username');
    const code = searchParams.get('confirmation_code');
    return <ChangePasswordForm username={username} code={code} title={t('reset_password_page.title')} actionLabel={t('reset_password_page.action_label')} />;
};
