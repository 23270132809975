import { SectionField } from '@/components/section/types';
import { EmployeePersonalInfo } from '@/domain/employee/EmployeePersonalInfo.model';
import { Country, getCountry } from '@/utils/countries.util';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { getLabelTranslation } from '@/utils/language.util';
import { getFieldValueProperty } from '@/components/section/SectionFieldComponent/SectionField.util';
import { getEnumFieldTypeOptions } from '@/domain/section-setting/Section.service';
import { EmployeePersonalInfoFieldType } from '@/domain/employee/EmployeeFields.model';

export const useEmployeePersonalInfoSectionFields = (sectionDefinition: SectionDefinition, personalInfo: EmployeePersonalInfo | undefined): SectionField[] => {
    if (!personalInfo) {
        return [];
    }

    const employeePersonalInfoFieldValueMapping: Record<EmployeePersonalInfoFieldType, string | Country | undefined> = {
        EMPLOYEE_BIRTH_DATE: personalInfo.birthdate,
        EMPLOYEE_GENDER: personalInfo.gender ?? '',
        EMPLOYEE_MARITAL_STATUS: personalInfo.maritalStatus ?? '',
        EMPLOYEE_MARITAL_STATUS_SINCE: personalInfo.maritalStatusSince ?? '',
        EMPLOYEE_NATIONALITY: personalInfo?.nationality ? getCountry(personalInfo?.nationality) : undefined,
        EMPLOYEE_AVS: personalInfo.avsNumber ?? '',
        EMPLOYEE_PERSONAL_PHONE_NUMBER: personalInfo.personalPhoneNumber ?? '',
        EMPLOYEE_PERSONAL_EMAIL: personalInfo.personalEmail ?? '',
    };

    return sectionDefinition.fields.map(fieldDefinition => {
        const employeePersonalInfoFieldType = fieldDefinition.fieldType as EmployeePersonalInfoFieldType;
        return {
            fieldDefinitionId: fieldDefinition.id,
            formValueName: fieldDefinition.formId,
            title: getLabelTranslation(fieldDefinition.name),
            valueType: fieldDefinition.valueType,
            [getFieldValueProperty(fieldDefinition.valueType)]: employeePersonalInfoFieldValueMapping[employeePersonalInfoFieldType],
            required: fieldDefinition.mandatory,
            fieldType: employeePersonalInfoFieldType,
            order: fieldDefinition.order,
            enumList: getEnumFieldTypeOptions(fieldDefinition.fieldType),
        };
    });
};
