type EnumType<T extends Record<string, string>> = T[keyof T];

export const convertEnumToOptions = <T extends Record<string, string>>(
    enumObject: T,
    getLabel: (key: EnumType<T>) => string,
): { label: string; value: string }[] => {
    return Object.keys(enumObject).map(key => ({
        label: getLabel(key as EnumType<T>) ?? key,
        value: key,
    }));
};

export const convertEnumToMap = <T extends Record<string, string>>(enumObject: T, getLabel: (key: EnumType<T>) => string): Map<string, string> => {
    const map = new Map<string, string>();
    Object.keys(enumObject).forEach(key => {
        map.set(key, getLabel(key as EnumType<T>) ?? key);
    });
    return map;
};
