import { AllDocumentsPage } from '@/page/document/AllDocumentsPage';
import { CompanyDocumentsPage } from '@/page/document/CompanyDocumentsPage';
import { PageContainer } from '@/routes/PageContainer';
import { RoutesConfig } from '@/routes/Routes.type';
import { Navigate, RouteObject } from 'react-router';
import { RouterBoundary } from '@/routes/RouterBoundary';

const config: RoutesConfig[] = [
    {
        path: 'company-documents',
        breadCrumb: [{ nameKey: 'pages.company_documents' }],
        component: <CompanyDocumentsPage />,
    },
    {
        path: 'all-documents',
        breadCrumb: [{ nameKey: 'pages.all_documents' }],
        component: <AllDocumentsPage />,
    },
];

export const documentRoutes: RouteObject[] = [
    {
        path: 'documents',
        children: [
            { path: '', element: <Navigate to='/documents/company-documents' replace /> },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}>{config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
