import { Announcements } from '@/page/home/announcement/Announcements';
import { Events } from '@/page/home/events/Events';
import { HomeHeader } from '@/page/home/header/HomeHeader';
import { PendingTasks } from '@/page/home/pending/PendingTasks';
import { Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';

export const HomePage: FC = () => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    return (
        <Container disableGutters sx={{ display: 'flex', flex: 1 }}>
            <Stack gap={2} flex={1}>
                <HomeHeader />
                <Stack direction={'row'} alignItems={'flex-start'} flex={1} gap={2}>
                    <Stack gap={2} flex={{ xs: 1, md: 2 }} sx={{ height: '100%' }}>
                        <PendingTasks />
                        {isMobile && <Events mobile />}
                        <Announcements />
                    </Stack>
                    {!isMobile && <Events flex={{ md: 1 }} />}
                </Stack>
            </Stack>
        </Container>
    );
};
