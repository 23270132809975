import { FC, PropsWithChildren } from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';

export const ActivityFeed: FC<PropsWithChildren<TypographyProps>> = ({ children, ...rest }) => {
    return (
        <Box component={Typography} bgcolor='grey.100' p={1} flex={1} borderRadius='8px 8px 8px 0' variant='body1' {...rest}>
            {children}
        </Box>
    );
};
