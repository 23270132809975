import { CheckboxAutocompleteOption } from '@/components/autocomplete-wrapper/CheckboxAutocompleteOption';
import { useSelectAll } from '@/components/form/field-select/useSelectAlll';
import { Autocomplete, AutocompleteProps, TextField, TextFieldProps, UseAutocompleteProps } from '@mui/material';
import { KeyboardEvent } from 'react';

export type SelectProps<TValue, Multiple extends boolean | undefined = undefined, DisableClearable extends boolean | undefined = undefined> = {
    onChange?: RemoveFirstParam<AutocompleteProps<TValue, Multiple, DisableClearable, false>['onChange']>;
    placeholder?: string;
    name?: string;
    error?: boolean;
    helperText?: string;
    textFieldProps?: TextFieldProps;
    // select all is only available for multiple select
    selectAll?: Multiple extends true ? boolean : never;
    autocompleteProps?: Omit<
        AutocompleteProps<TValue, Multiple, DisableClearable, false>,
        | 'renderInput'
        | 'value'
        | 'options'
        | 'onChange'
        | 'getOptionLabel'
        | 'isOptionEqualToValue'
        | 'fullWidth'
        | 'disableClearable'
        | 'disableCloseOnSelect'
        | 'defaultValue'
        | 'getOptionKey'
        | 'loading'
        | 'disabled'
        | 'multiple'
        | 'renderOption'
        | 'freeSolo'
    >;
} & Pick<
    AutocompleteProps<TValue, Multiple, DisableClearable, false>,
    | 'getOptionLabel'
    | 'isOptionEqualToValue'
    | 'fullWidth'
    | 'disableClearable'
    | 'disableCloseOnSelect'
    | 'getOptionKey'
    | 'loading'
    | 'value'
    | 'disabled'
    | 'options'
    | 'renderOption'
    | 'multiple'
    | 'freeSolo'
>;

export const Select = <TValue, Multiple extends boolean | undefined = false, DisableClearable extends boolean | undefined = false>(
    props: SelectProps<TValue, Multiple, DisableClearable>,
): JSX.Element => {
    const {
        name,
        disabled,
        disableClearable,
        disableCloseOnSelect,
        fullWidth,
        loading,
        options,
        getOptionLabel: getOptionLabelProp,
        isOptionEqualToValue,
        getOptionKey: getOptionKeyProp,
        placeholder,
        value: valueProp,
        onChange: onChangeProp,
        error,
        helperText,
        textFieldProps,
        multiple,
        renderOption: renderOptionProp,
        selectAll,
        autocompleteProps,
    } = props;

    const selectAllAutocompleteProps = useSelectAll({
        getOptionLabel: getOptionLabelProp,
        getOptionKey: getOptionKeyProp,
        value: valueProp as TValue[],
        onChange: onChangeProp as never,
    });

    const renderMultipleOption: typeof renderOptionProp = (props, option, { selected }, { getOptionLabel, getOptionKey }) => {
        const key = getOptionKey?.(option);
        const label = getOptionLabel(option) ?? '';
        return <CheckboxAutocompleteOption {...props} key={key} selected={selected} label={label} />;
    };

    const renderOption: typeof renderOptionProp = renderOptionProp ?? (multiple ? renderMultipleOption : undefined);

    const onChange: UseAutocompleteProps<TValue, Multiple, DisableClearable, false>['onChange'] = (event, value, reason, details) => {
        if (event.type === 'keydown') {
            const e = event as KeyboardEvent<HTMLDivElement>;
            if (e.code === 'Backspace') {
                return;
            }
        }
        onChangeProp?.(value, reason, details);
    };

    return (
        <Autocomplete<TValue, Multiple, DisableClearable, false>
            disabled={disabled}
            options={options}
            getOptionLabel={getOptionLabelProp}
            getOptionKey={getOptionKeyProp}
            isOptionEqualToValue={isOptionEqualToValue}
            fullWidth={fullWidth}
            disableClearable={disableClearable}
            loading={loading}
            multiple={multiple}
            value={valueProp}
            limitTags={5}
            disableCloseOnSelect={multiple ?? disableCloseOnSelect}
            // Did not find a way to type this onChange prop without using any, unknown is not possible here
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={onChange as any}
            // Did not find a way to type this renderOption prop without using any, unknown is not possible here
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderOption={renderOption as any}
            renderInput={params => (
                <TextField
                    {...params}
                    {...textFieldProps}
                    slotProps={{
                        htmlInput: {
                            ...params.inputProps,
                            // use full for testing
                            'aria-label': name,
                        },
                    }}
                    placeholder={placeholder}
                    error={error}
                    helperText={helperText}
                />
            )}
            // If selectAll is true, override props with selectAllAutocompleteProps
            {...(selectAll ? selectAllAutocompleteProps : {})}
            {...autocompleteProps}
        />
    );
};

// Helper function to remove event parameter from onChange prop
type RemoveFirstParam<T> = T extends (arg1: never, ...args: infer Rest) => infer ReturnType ? (...args: Rest) => ReturnType : T;
