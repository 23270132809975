import { API_BASE_URL, client } from '@/api/common';
import { EmployeeSkill } from '@/domain/employee-skill/EmployeeSkill.model';
import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import { SkillDTO } from '@/api/skill/Skill.api';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

export type EmployeeSkillDTO = Omit<EmployeeSkill, 'employee' | 'updatedAt' | 'skill'> & {
    employee: EmployeeAvatarDTO;
    skill: SkillDTO;
    updatedAt?: string;
};

const getEmployeeSkills = async (employeeId: number): Promise<EmployeeSkill[]> => {
    const url = API_BASE_URL + `/employee-skill/${employeeId}`;
    return mapEmployeeSkills((await client.get<EmployeeSkillDTO[]>(url)).data);
};

export const employeeSkillAPI = {
    getEmployeeSkills,
};

const mapEmployeeSkills = (employeeSkills: EmployeeSkillDTO[]): EmployeeSkill[] => {
    return employeeSkills.map(employeeSkill => ({
        ...employeeSkill,
        updatedAt: employeeSkill.updatedAt ? convertUTCIsoStringToDate(employeeSkill.updatedAt) : undefined,
    }));
};
