import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { Review } from '@/domain/review/Review.model';
import { ReviewCycleRemindersDialog } from '@/page/review/review-cycle-dialog/ReviewCycleRemindersDialog';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Button, List, ListItemButton, ListItemText, Menu, Paper, Stack, Typography } from '@mui/material';
import { AnalyticsUpIcon, ArrowDown01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

type FeedbacksListHeaderProps = {
    review: Review;
};

export const ManageEmployeeReviewsCyclePageHeader: FC<FeedbacksListHeaderProps> = ({ review }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const [openViewRemindersDialog, setOpenViewRemindersDialog] = useState<boolean>(false);

    const reminders = review.employeeReviews?.map(er => er.reminders).flat() ?? [];

    return (
        <Stack direction='row' component={Paper} p={2} justifyContent='space-between'>
            <Stack direction='row' spacing={4}>
                <Stack>
                    <Typography variant='body2'>{t('reviews.employee_reviews_table.review_template_name')}</Typography>
                    <Typography variant='body2bold'>{getLabelTranslation(review?.reviewTemplate?.name)}</Typography>
                </Stack>
                <Stack>
                    <Typography variant='body2'>{t('reviews.employee_reviews_table.feedback_deadline')}</Typography>
                    <Typography variant='body2bold'>{formatInDefaultDate(review.feedbackDeadlineDate) || '–'}</Typography>
                </Stack>
                <Stack>
                    <Typography variant='body2'>{t('reviews.employee_reviews_table.end_date')}</Typography>
                    <Typography variant='body2bold'>{formatInDefaultDate(review.endDate) || '–'}</Typography>
                </Stack>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={2}>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='body2bold'>{t('reviews.employee_reviews_table.cycle_owners')}</Typography>
                    <StackedAvatars employeeAvatars={review?.owners} />
                </Stack>
                <Button
                    component={Link}
                    to={`/reviews/manage-reviews/${review?.id}/report`}
                    size='small'
                    variant='text'
                    startIcon={<AnalyticsUpIcon />}
                    color='inherit'
                >
                    {t('reviews.employee_reviews_table.see_cycle_report')}
                </Button>
                <Button size='small' variant='text' onClick={event => setAnchorEl(event.currentTarget)} endIcon={<ArrowDown01Icon size={12} />}>
                    {t('reviews.employee_reviews_table.actions')}
                </Button>
                <Menu
                    id='manage-leaves-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    anchorOrigin={{ vertical: 34, horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(undefined)}
                >
                    <List component='nav'>
                        <ListItemButton key={'see-reminders'} onClick={() => setOpenViewRemindersDialog(true)}>
                            <ListItemText primary={t('reviews.employee_reviews_table.see_reminders')} />
                        </ListItemButton>
                    </List>
                </Menu>
            </Stack>

            {openViewRemindersDialog && <ReviewCycleRemindersDialog open={true} reminders={reminders} onClose={() => setOpenViewRemindersDialog(false)} />}
        </Stack>
    );
};
