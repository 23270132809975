import { Select, SelectProps } from '@/components/form/field-select/Select';
import { getNull } from '@/utils/object.util';
import { AutocompleteProps } from '@mui/material';
import { Controller, ControllerProps, FieldPath, FieldPathValue, FieldValues } from 'react-hook-form';

export type FieldSelectProps<
    TOption,
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
> = {
    options: readonly TOption[];
    getOptionLabel?: (option: TOption) => string;
    getOptionKey?: (option: TOption) => string | number;
    isOptionEqualToValue?: (option: TOption, value: TOption) => boolean;
    onChange?: SelectProps<TOption, Multiple, DisableClearable>['onChange'];
    placeholder?: string;
    multiple?: Multiple;
    autocompleteProps?: Omit<
        AutocompleteProps<TOption, Multiple, DisableClearable, false>,
        | 'renderInput'
        | 'value'
        | 'onChange'
        | 'options'
        | 'getOptionLabel'
        | 'isOptionEqualToValue'
        | 'fullWidth'
        | 'disableClearable'
        | 'defaultValue'
        | 'getOptionKey'
        | 'loading'
        | 'multiple'
    >;
} & Omit<ControllerProps<TFieldValues, TName>, 'render'> &
    Pick<AutocompleteProps<TOption, Multiple, DisableClearable, false>, 'fullWidth' | 'disableClearable' | 'loading' | 'renderOption'>;

export const FieldSelect = <
    TOption,
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    TValue extends FieldPathValue<TFieldValues, TName> = FieldPathValue<TFieldValues, TName>,
>(
    props: FieldSelectProps<TOption, TFieldValues, TName, Multiple, DisableClearable>,
): JSX.Element => {
    const {
        disabled,
        disableClearable,
        options,
        getOptionLabel,
        isOptionEqualToValue,
        fullWidth,
        getOptionKey,
        loading,
        placeholder,
        autocompleteProps,
        onChange: onChangeProp,
        multiple,
        ...controllerProps
    } = props;

    return (
        <Controller
            {...controllerProps}
            render={({ field: { value, onChange, ...restField }, fieldState }) => (
                <Select
                    disabled={disabled}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    fullWidth={fullWidth}
                    disableClearable={disableClearable}
                    getOptionKey={getOptionKey}
                    loading={loading}
                    autocompleteProps={autocompleteProps}
                    value={value ?? (multiple ? ([] as TValue) : (getNull() as TValue))}
                    onChange={(value, reason) => {
                        onChange(value);
                        onChangeProp?.(value, reason);
                    }}
                    multiple={multiple}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    name={controllerProps.name}
                    textFieldProps={restField}
                    placeholder={placeholder}
                />
            )}
        />
    );
};
