import { OffboardingMutation } from '@/domain/offboarding/Offboarding.model';
import { API_BASE_URL, client } from '../common';

type OffboardingRequestDTO = OffboardingMutation;

const offboardEmployee = async (mutation: OffboardingMutation): Promise<void> => {
    await client.post(API_BASE_URL + '/offboarding', mutation satisfies OffboardingRequestDTO);
};

export const offboardingApi = {
    offboardEmployee,
};
