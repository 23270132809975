import { AsyncSelectFilter, SelectFilter } from '@/components/filters-bar/FiltersBar';
import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { getLocations } from '@/domain/location/Location.service';
import { getDepartments } from '@/domain/department/Department.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getJobs } from '@/domain/job/Job.service';
import { EmployeeFilterType, getUserEmploymentStatusTranslationKey, searchEmployees } from '@/domain/employee/Employee.service';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { useTranslation } from 'react-i18next';

export type PayrollFilter = SelectFilter | AsyncSelectFilter;

/**
 * Hook to get the list of filter for employee payroll locks page
 */
export const useEmployeePayrollLocksPageFilters = (): { filters: PayrollFilter[] } => {
    const { t } = useTranslation();

    const employmentStatusOptions = Object.values(EmploymentStatus)
        .filter(status => status !== EmploymentStatus.HIRED) // Filter hired status
        .map(status => ({
            label: t(getUserEmploymentStatusTranslationKey(), { context: status }),
            value: status,
        }));
    const defaultValue = {
        label: t(getUserEmploymentStatusTranslationKey(), { context: EmploymentStatus.EMPLOYED }),
        value: EmploymentStatus.EMPLOYED,
    };

    const filters: PayrollFilter[] = [
        {
            filterName: t('payroll_locks_page.filter_employment_status'),
            type: 'select',
            selectMode: 'SYNC',
            options: employmentStatusOptions,
            value: [defaultValue],
            key: 'EMPLOYMENT_STATUS',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees({ statuses: [EmploymentStatus.HIRED, EmploymentStatus.EMPLOYED, EmploymentStatus.ON_LONG_LEAVE] });
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'EMPLOYEE',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobs = await getJobs();
                return jobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                }));
            },
            key: 'JOB_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
