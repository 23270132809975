import { UiActionType } from '@/stores/reducers/uiSlice';
import { useAppDispatch } from '@/stores/store';
import { FC, PropsWithChildren, useEffect } from 'react';

export type BreadCrumbItem = {
    nameKey: string | undefined;
    label?: string;
};

export interface PageContainerProps {
    breadCrumb?: BreadCrumbItem[];
}

export const PageContainer: FC<PropsWithChildren<PageContainerProps>> = ({ breadCrumb, children }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (breadCrumb?.length) {
            dispatch({ type: UiActionType.BREADCRUMB, breadCrumb: breadCrumb });
        }
    }, [breadCrumb, dispatch]);

    return children;
};
