import {
    EmployeePayrollLock,
    EmployeePayrollLockCreationMutation,
    EmployeePayrollLockCreationValidation,
    EmployeePayrollLockLatestSearchRequest,
    EmployeePayrollLockPreview,
    EmployeePayrollLockSearchRequest,
    EmployeePayrollLockUnlockMutation,
    LatestPayrollLocks,
} from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { employeePayrollLockAPI } from '@/api/employee-payroll-lock/EmployeePayrollLock.api';

export const createEmployeePayrollLock = (creationMutation: EmployeePayrollLockCreationMutation): Promise<EmployeePayrollLock[]> => {
    return employeePayrollLockAPI.createEmployeePayrollLock(creationMutation);
};

export const searchEmployeePayrollLock = (
    search: EmployeePayrollLockSearchRequest = {
        employeeIds: [],
    },
): Promise<EmployeePayrollLock[]> => {
    return employeePayrollLockAPI.searchEmployeePayrollLock(search);
};

export const searchLatestEmployeePayrollLock = (search: EmployeePayrollLockLatestSearchRequest): Promise<LatestPayrollLocks[]> => {
    return employeePayrollLockAPI.searchLatestEmployeePayrollLock(search);
};

export const unlockEmployeePayrollLock = (mutation: EmployeePayrollLockUnlockMutation): Promise<void> => {
    return employeePayrollLockAPI.unlockEmployeePayrollLock(mutation);
};

export const checkEmployeePayrollLockCreationFeasibility = (request: EmployeePayrollLockCreationValidation): Promise<EmployeePayrollLockPreview[]> => {
    return employeePayrollLockAPI.checkEmployeePayrollLockCreationFeasibility(request);
};
