import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { Job, JobCreateMutation, JobSearch, JobUpdateMutation } from '@/domain/job/Job.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, buildImportFormData, client } from '../common';

export type JobDTO = Job;
type JobSearchRequestDTO = JobSearch;
type JobCreateMutationDTO = JobCreateMutation;
type JobUpdateMutationDTO = JobUpdateMutation;

const getJobs = async (search: JobSearch = {}): Promise<Job[]> => {
    return (await client.post<JobDTO[], AxiosResponse<JobDTO[]>, JobSearchRequestDTO>(API_BASE_URL + '/jobs/search', search)).data;
};

const getJobById = async (jobId: number): Promise<Job> => {
    return (await client.get<JobDTO, AxiosResponse<JobDTO>>(API_BASE_URL + `/jobs/${jobId}`)).data;
};

const createJob = async (mutation: JobCreateMutation): Promise<Job> => {
    return (await client.post<JobDTO, AxiosResponse<JobDTO>, JobCreateMutationDTO>(API_BASE_URL + '/jobs', mutation)).data;
};

const updateJob = async (jobId: number, mutation: JobUpdateMutation): Promise<Job> => {
    return (await client.put<JobDTO, AxiosResponse<JobDTO>, JobUpdateMutationDTO>(API_BASE_URL + `/jobs/${jobId}`, mutation)).data;
};

const deleteJob = async (jobId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/jobs/${jobId}`);
};

const importJobs = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(API_BASE_URL + `/jobs/import`, formData)).data;
};

export const jobAPI = {
    getJobs,
    getJobById,
    createJob,
    updateJob,
    deleteJob,
    importJobs,
};
