import { LegalUnit } from '@/domain/legal-unit/LegalUnit.model';
import { getLegalUnit, getLegalUnitLogoUrl, getLegalUnits } from '@/domain/legal-unit/LegalUnit.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetLegalUnits = (): UseQueryResult<LegalUnit[]> => {
    const [legalUnits, setLegalUnits] = useState<LegalUnit[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLegalUnits = useCallback(async () => {
        try {
            const legalUnits = await getLegalUnits();
            setLegalUnits(legalUnits);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchLegalUnits().catch(handleError);
    }, [fetchLegalUnits]);

    return {
        data: legalUnits,
        setData: setLegalUnits,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchLegalUnits,
    };
};

export const useGetLegalUnit = (id: number | undefined): UseQueryResult<LegalUnit> => {
    const [legalUnit, setLegalUnit] = useState<LegalUnit>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLegalUnit = useCallback(async () => {
        if (!id) {
            setIsLoading(false);
            return;
        }
        try {
            const legalUnit = await getLegalUnit(id);
            setLegalUnit(legalUnit);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [id]);

    useEffect(() => {
        fetchLegalUnit().catch(handleError);
    }, [fetchLegalUnit]);

    return {
        data: legalUnit,
        setData: setLegalUnit,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchLegalUnit,
    };
};

export const useGetLegalUnitLogoUrl = (legalUnitId: number | undefined, enabled = true): UseQueryResult<string> => {
    const [legalUnitLogoUrl, setLegalUnitLogoUrl] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLegalUnitLogo = useCallback(async (legalUnitId: number | undefined, enabled: boolean) => {
        if (!enabled || !legalUnitId) {
            setIsLoading(false);
            return;
        }
        try {
            const data = await getLegalUnitLogoUrl(legalUnitId);
            setLegalUnitLogoUrl(data);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchLegalUnitLogo(legalUnitId, enabled).catch(handleError);
    }, [legalUnitId, enabled, fetchLegalUnitLogo]);

    return {
        data: legalUnitLogoUrl,
        refetch: () => fetchLegalUnitLogo(legalUnitId, enabled),
        setData: setLegalUnitLogoUrl,
        isLoading,
        isError: !!error,
        error,
    };
};
