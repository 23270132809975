import {
    EmployeeLeaveBalanceHistorySearchRequest,
    EmployeeLeaveTypeBalance,
    LeaveBalanceSearchRequest,
    LeaveTypeHistory,
} from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { leaveTypeHistoryApi } from '@/api/leave-type-history/LeaveTypeHistory.api';

export function getLeaveTypeHistories(userId: number, searchRequest: EmployeeLeaveBalanceHistorySearchRequest): Promise<LeaveTypeHistory[]> {
    return leaveTypeHistoryApi.getLeaveTypeHistories(userId, searchRequest);
}

export function getLeaveTypeHistory(userId: number, userLeaveTypeId: number): Promise<LeaveTypeHistory> {
    return leaveTypeHistoryApi.getLeaveTypeHistory(userId, userLeaveTypeId);
}

export function getLeaveBalance(leaveTypeId: number, searchRequest: LeaveBalanceSearchRequest): Promise<EmployeeLeaveTypeBalance[]> {
    return leaveTypeHistoryApi.getLeaveBalance(leaveTypeId, searchRequest);
}
