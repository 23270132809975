import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { EmployeeLeaveRequestList } from '@/page/leave/EmployeeLeaveRequestList';
import { compareAsc, compareDesc, isFutureDate, isTodayDate } from '@/utils/datetime.util';
import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { LeavesEmptyStateIcon } from '@/components/empty-state/icons/LeavesEmptyStateIcon';

type UpcomingLeavesProps = {
    employeeId: number;
    leaveRequests: LeaveRequest[];
    onChange: () => void;
};

export const EmployeeLeaves: FC<UpcomingLeavesProps> = ({ employeeId, leaveRequests, onChange }) => {
    const { t } = useTranslation();

    const upcomingPredicate = (leaveRequest: LeaveRequest) => {
        return !leaveRequest.endDate || isFutureDate(leaveRequest.endDate) || isTodayDate(leaveRequest.endDate);
    };
    const upcomingLeaves = leaveRequests.filter(upcomingPredicate);
    const pastLeaves = leaveRequests.filter(leaveRequest => !upcomingPredicate(leaveRequest));

    const hasValidLeaves = (leaves: { requestStatus: string }[]): boolean => {
        return leaves.some(leave => !['CANCELLED', 'DECLINED'].includes(leave.requestStatus));
    };

    const emptyUpcomingLeavesState = (
        <Stack component={Paper} flex={1} p={2}>
            <EmptyState icon={<LeavesEmptyStateIcon />} flex={1} title={t('my_leaves.no_leaves')} />
        </Stack>
    );

    return (
        <Stack direction='column' gap={2} flex={1}>
            <Stack gap={2} flex={!hasValidLeaves(upcomingLeaves) ? 1 : undefined}>
                <Typography variant='h2'>{t('my_leaves.upcoming_leaves')}</Typography>
                {hasValidLeaves(upcomingLeaves) ? (
                    <EmployeeLeaveRequestList leaveRequests={upcomingLeaves} employeeId={employeeId} compareDatesByAge={compareAsc} onChange={onChange} />
                ) : (
                    emptyUpcomingLeavesState
                )}
            </Stack>
            {hasValidLeaves(pastLeaves) && (
                <Stack gap={2}>
                    <Typography variant='h2'>{t('my_leaves.past_leaves')}</Typography>

                    <EmployeeLeaveRequestList leaveRequests={pastLeaves} employeeId={employeeId} compareDatesByAge={compareDesc} onChange={onChange} />
                </Stack>
            )}
        </Stack>
    );
};
