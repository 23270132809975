import {
    EmployeeTimeBalance,
    EmployeeYearlyTimeBalance,
    TimesheetInsightSearch,
    TimesheetYearlySearchRequest,
} from '@/domain/timesheet-insight/TimesheetInsight.model';
import { timesheetInsightApi } from '@/api/timesheet-insight/TimesheetInsight.api';

export const getEmployeeTimeBalances = async (search: TimesheetInsightSearch): Promise<EmployeeTimeBalance[]> => {
    return timesheetInsightApi.getEmployeeTimeBalances(search);
};

export const getEmployeeWeeklyTimeBalances = async (search: TimesheetYearlySearchRequest): Promise<EmployeeYearlyTimeBalance[]> => {
    return timesheetInsightApi.getEmployeeWeeklyTimeBalances(search);
};
