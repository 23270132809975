import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { Location, LocationMutation } from '@/domain/location/Location.model';

const getLocations = async (): Promise<Location[]> => {
    const url = API_BASE_URL + '/locations';
    return (await client.get<LocationDTO[]>(url)).data;
};

const getLocationById = async (locationId: number): Promise<Location> => {
    const url = API_BASE_URL + `/locations/${locationId}`;
    return (await client.get<LocationDTO>(url)).data;
};

const createLocation = async (mutation: LocationMutation): Promise<Location> => {
    const url = API_BASE_URL + '/locations';
    return (await client.post<LocationDTO, AxiosResponse<LocationDTO>, LocationMutation>(url, mutation)).data;
};

const updateLocation = async (locationId: number, mutation: LocationMutation): Promise<Location> => {
    const url = API_BASE_URL + `/locations/${locationId}`;
    return (await client.put<LocationDTO, AxiosResponse<LocationDTO>, LocationMutation>(url, mutation)).data;
};

const deleteLocation = (locationId: number): Promise<void> => {
    const url = API_BASE_URL + `/locations/${locationId}`;
    return client.delete(url);
};

export const locationApi = {
    getLocations,
    getLocationById,
    createLocation,
    updateLocation,
    deleteLocation,
};

export type LocationDTO = Location;
