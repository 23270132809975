import { API_BASE_URL, client } from '@/api/common';

const AUTH_API_BASE_PATH = API_BASE_URL + `/auth`;

const resetPassword = async (tenantId: string, email: string): Promise<void> => {
    await client.post(`${AUTH_API_BASE_PATH}/reset-password`, { email, tenantId });
};

const signOut = async (accessToken: string): Promise<void> => {
    await client.post(`${AUTH_API_BASE_PATH}/sign-out`, { accessToken });
};

const newPassword = async (accessToken: string, oldPassword: string, newPassword: string): Promise<void> => {
    await client.post(`${AUTH_API_BASE_PATH}/new-password`, { accessToken, oldPassword, newPassword });
};

export const authApi = {
    resetPassword,
    signOut,
    newPassword,
};
