import { AxiosResponse } from 'axios';
import { ObjectiveCategory } from '@/domain/objective/Objective.model';
import { API_BASE_URL, client } from '@/api/common';
import { ObjectiveCategoryCreateMutation, ObjectiveCategorySearch, ObjectiveCategoryUpdateMutation } from '@/domain/objective-category/ObjectiveCategory.model';
import { OrderMutation } from '@/domain/common';

type ObjectiveCategoryCreateRequestDTO = ObjectiveCategoryCreateMutation;
type ObjectiveCategoryUpdateRequestDTO = ObjectiveCategoryUpdateMutation;
type ObjectiveCategorySearchRequestDTO = ObjectiveCategorySearch;

export type ObjectiveCategoryDTO = ObjectiveCategory;

const getObjectiveCategory = async (objectiveCategoryId: number): Promise<ObjectiveCategory> => {
    return (await client.get<ObjectiveCategoryDTO>(API_BASE_URL + `/objective-categories/${objectiveCategoryId}`)).data;
};

const updateObjectiveCategory = async (objectiveCategoryId: number, mutation: ObjectiveCategoryUpdateMutation): Promise<ObjectiveCategory> => {
    return (
        await client.put<ObjectiveCategoryDTO, AxiosResponse<ObjectiveCategoryDTO>, ObjectiveCategoryUpdateRequestDTO>(
            API_BASE_URL + `/objective-categories/${objectiveCategoryId}`,
            mutation,
        )
    ).data;
};

const deleteObjectiveCategory = async (objectiveCategoryId: number): Promise<void> => {
    return client.delete(API_BASE_URL + `/objective-categories/${objectiveCategoryId}`);
};

const updateObjectiveCategoryOrder = async (request: OrderMutation[]): Promise<void> => {
    return client.put(API_BASE_URL + `/objective-categories/order`, request);
};

const createObjectiveCategory = async (mutation: ObjectiveCategoryCreateMutation): Promise<ObjectiveCategory> => {
    return (
        await client.post<ObjectiveCategoryDTO, AxiosResponse<ObjectiveCategoryDTO>, ObjectiveCategoryCreateRequestDTO>(
            API_BASE_URL + `/objective-categories`,
            mutation,
        )
    ).data;
};

const searchObjectiveCategories = async (request: ObjectiveCategorySearch): Promise<ObjectiveCategory[]> => {
    return (
        await client.post<ObjectiveCategoryDTO[], AxiosResponse<ObjectiveCategoryDTO[]>, ObjectiveCategorySearchRequestDTO>(
            API_BASE_URL + `/objective-categories/search`,
            request,
        )
    ).data;
};

export const objectiveCategoryAPI = {
    getObjectiveCategory,
    updateObjectiveCategory,
    deleteObjectiveCategory,
    updateObjectiveCategoryOrder,
    createObjectiveCategory,
    searchObjectiveCategories,
};
