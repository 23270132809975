import { thirdPartyAPI } from '@/api/third-party/ThirdParty.api';
import { ThirdParty, ThirdPartyIntegrationLog, ThirdPartyMutation } from '@/domain/third-party/ThirdParty.model';

export const searchThirdParties = async (): Promise<ThirdParty[]> => {
    return thirdPartyAPI.searchThirdParties();
};

export const getThirdParty = async (thirdPartyId: number): Promise<ThirdParty> => {
    return thirdPartyAPI.getThirdParty(thirdPartyId);
};

export const createThirdParty = async (request: ThirdPartyMutation): Promise<ThirdParty> => {
    return thirdPartyAPI.createThirdParty(request);
};

export const updateThirdParty = async (thirdPartyId: number, request: ThirdPartyMutation): Promise<ThirdParty> => {
    return thirdPartyAPI.updateThirdParty(thirdPartyId, request);
};

export const deleteThirdParty = async (thirdPartyId: number): Promise<void> => {
    return thirdPartyAPI.deleteThirdParty(thirdPartyId);
};

export const toggleThirdParty = async (thirdPartyId: number, enabled: boolean): Promise<ThirdParty> => {
    return thirdPartyAPI.toggleThirdParty(thirdPartyId, enabled);
};

export const generateThirdPartyApiKey = async (thirdPartyId: number): Promise<string> => {
    return thirdPartyAPI.generateApiKey(thirdPartyId);
};

export const getThirdPartyIntegrationLogs = async (thirdPartyIntegrationId: number): Promise<ThirdPartyIntegrationLog[]> => {
    return thirdPartyAPI.getThirdPartyIntegrationLogs(thirdPartyIntegrationId);
};
