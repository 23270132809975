import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { SectionLoading } from '@/components/section/SectionLoading';
import { StackSection } from '@/components/section/StackSectionComponent/StackSection';
import { TableSection } from '@/components/section/TableSection/TableSection';
import { SectionColumn, SectionField, SectionRow } from '@/components/section/types';
import { EmployeeWorkingPattern } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { deleteEmployeeWorkingPattern, searchEmployeeWorkingPatterns } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.service';
import { Employee } from '@/domain/employee/Employee.model';
import { canManageEmployeeContracts } from '@/domain/permission/Permission.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { getEmployeeWorkingPatternTitle } from '@/domain/working-pattern-template/WorkingPatternTemplate.service';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams } from '@ag-grid-community/core';
import { useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeWorkingPatternDialog } from './Components/EmployeeWorkingPatternDialog';

type EmployeeWorkPatternSectionProps = {
    employee: Employee;
    onUpdateWorkPattern: () => void;
};

export const EmployeeWorkPatternSection: FC<EmployeeWorkPatternSectionProps> = ({ employee, onUpdateWorkPattern }) => {
    const { t } = useTranslation();
    const [employeeWorkingPatterns, setEmployeeWorkingPatterns] = useState<EmployeeWorkingPattern[]>();
    const [employeeWorkPatternDialogOpen, setEmployeeWorkPatternDialogOpen] = useState<boolean>(false);
    const [selectedEmployeeWorkingPattern, setSelectedEmployeeWorkingPattern] = useState<EmployeeWorkingPattern>();
    const policies = useCurrentPolicies();
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
    const realm = useCurrentRealm();

    useEffect(() => {
        if (!employee.id) {
            return;
        }
        searchEmployeeWorkingPatterns({ employeeIds: [employee.id] })
            .then(data => {
                setEmployeeWorkingPatterns(data);
            })
            .catch(handleError);
    }, [employee.id]);

    if (!employeeWorkingPatterns) {
        return <SectionLoading sectionTitle={'employee.sections.work_pattern'} />;
    }

    const columns: SectionColumn[] = [
        {
            fieldDefinitionId: undefined,
            title: t('employee.work_pattern.start_date'),
            valueType: 'DATE',
        },
        {
            fieldDefinitionId: undefined,
            title: t('employee.work_pattern.title'),
            valueType: 'STRING',
        },
    ];

    const hasTimesheetRealmFeature = hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.TIMESHEET);
    if (hasTimesheetRealmFeature) {
        columns.push({
            fieldDefinitionId: undefined,
            title: t('employee.work_pattern.timesheet_setting'),
            valueType: 'STRING',
        });
    }

    if (hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.LEAVES) || hasTimesheetRealmFeature) {
        columns.push({
            fieldDefinitionId: undefined,
            title: t('employee.work_pattern.calendar'),
            valueType: 'STRING',
        });
    }

    const currentEmploymentFields: SectionField[] = [
        {
            fieldDefinitionId: undefined,
            title: t('employee.work_pattern.start_date'),
            valueType: 'DATE',
            dateValue: employee.currentWorkingPattern?.startDate,
            required: true,
            fieldType: 'CURRENT_WORKING_PATTERN_START_DATE',
        },
        {
            fieldDefinitionId: undefined,
            title: t('employee.work_pattern.title'),
            valueType: 'STRING',
            stringValue: getEmployeeWorkingPatternTitle(employee?.currentWorkingPattern, t),
            fieldType: 'CURRENT_WORKING_PATTERN_NAME',
        },
    ];

    const rows: SectionRow[] = employeeWorkingPatterns.map(employeeWorkingPattern => {
        return {
            id: employeeWorkingPattern?.id,
            isPending: false,
            fields: [
                {
                    fieldDefinitionId: undefined,
                    title: t('employee.work_pattern.start_date'),
                    valueType: 'DATE',
                    dateValue: employeeWorkingPattern?.startDate,
                    required: true,
                    fieldType: 'CURRENT_WORKING_PATTERN_START_DATE',
                },
                {
                    fieldDefinitionId: undefined,
                    title: t('employee.work_pattern.title'),
                    valueType: 'STRING',
                    stringValue: getEmployeeWorkingPatternTitle(employeeWorkingPattern, t),
                    fieldType: 'CURRENT_WORKING_PATTERN_NAME',
                },
                {
                    fieldDefinitionId: undefined,
                    title: t('employee.work_pattern.timesheet_setting'),
                    valueType: 'STRING',
                    stringValue: employeeWorkingPattern.timesheetSetting.name,
                    fieldType: 'CURRENT_WORKING_PATTERN_TIMESHEET_SETTING_NAME',
                },
                {
                    fieldDefinitionId: undefined,
                    title: t('employee.work_pattern.calendar'),
                    valueType: 'STRING',
                    stringValue: employeeWorkingPattern.calendar?.name,
                    fieldType: 'CURRENT_WORKING_PATTERN_CALENDAR',
                },
            ],
        };
    });

    const addActionButton = {
        title: t('employee.work_pattern.add_work_pattern'),
        onClick: () => {
            setSelectedEmployeeWorkingPattern(undefined);
            setEmployeeWorkPatternDialogOpen(true);
        },
    };

    const editActionButton = {
        title: t('employee.work_pattern.edit_work_pattern'),
        onClick: () => {
            setSelectedEmployeeWorkingPattern(employee.currentWorkingPattern);
            setEmployeeWorkPatternDialogOpen(true);
        },
    };

    const getActionButton = () => {
        if (canManageEmployeeContracts(policies, employee?.id)) {
            return addActionButton;
        }
    };

    const getDropdownActionButtons = () => {
        if (canManageEmployeeContracts(policies, employee?.id)) {
            return [addActionButton, editActionButton];
        }
    };

    const handleEditClicked = (row: SectionRow) => () => {
        setSelectedEmployeeWorkingPattern(employeeWorkingPatterns.find(ewp => ewp.id === row.id));
        setEmployeeWorkPatternDialogOpen(true);
    };

    const handleDeleteClicked = async (row: SectionRow) => {
        try {
            await deleteEmployeeWorkingPattern(row.id);
            const data = await searchEmployeeWorkingPatterns({ employeeIds: [employee.id] });
            setEmployeeWorkingPatterns(data);
        } catch (error) {
            handleError(error);
        }
    };

    const canEditStartDate = () => {
        if (!selectedEmployeeWorkingPattern?.id) {
            return true;
        }
        const ewpIndex = employeeWorkingPatterns.findIndex(ewp => ewp.id === selectedEmployeeWorkingPattern?.id);
        //only allow to edit the first and the last working pattern
        return ewpIndex === 0 || ewpIndex === employeeWorkingPatterns.length - 1;
    };

    const actionCellMenuRenderer = canManageEmployeeContracts(policies, employee?.id)
        ? (params: ICellRendererParams<SectionRow>) => {
              const data = params.data;
              if (!data) {
                  return;
              }
              const menuItems: BasicMenuItem[] = [
                  {
                      title: t('general.edit'),
                      onClick: handleEditClicked(data),
                  },
                  {
                      title: t('general.delete'),
                      onClick: async () => {
                          try {
                              await handleDeleteClicked(data);
                          } catch (error) {
                              handleError(error);
                          }
                      },
                  },
              ];
              return <BasicMenu items={menuItems} />;
          }
        : undefined;
    return (
        <>
            {!isSmallDevice ? (
                <TableSection
                    sectionTitle={t('employee.work_pattern.title')}
                    columns={columns}
                    rows={rows}
                    actionButton={getActionButton()}
                    actionMenuCellRenderer={actionCellMenuRenderer}
                />
            ) : (
                <StackSection
                    sectionTitle={t('employee.work_pattern.title')}
                    fields={currentEmploymentFields}
                    dropdownTitle={t('my_profile.actions')}
                    dropdownActionButtons={getDropdownActionButtons()}
                />
            )}

            {employeeWorkPatternDialogOpen && (
                <EmployeeWorkingPatternDialog
                    open={employeeWorkPatternDialogOpen}
                    employee={employee}
                    selectedEmployeeWorkingPattern={selectedEmployeeWorkingPattern}
                    canEditStartDate={canEditStartDate()}
                    onSave={() => {
                        setEmployeeWorkingPatterns(undefined);
                        setEmployeeWorkPatternDialogOpen(false);
                        onUpdateWorkPattern();
                        searchEmployeeWorkingPatterns({ employeeIds: [employee.id] })
                            .then(data => {
                                setEmployeeWorkingPatterns(data);
                            })
                            .catch(error => {
                                handleError(error);
                            });
                    }}
                    onClose={(): void => {
                        setSelectedEmployeeWorkingPattern(undefined);
                        setEmployeeWorkPatternDialogOpen(false);
                    }}
                />
            )}
        </>
    );
};
