import { useCallback, useEffect, useState } from 'react';
import { UseQueryResult } from '@/page/Query.type';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getObjective, searchObjectives } from '@/domain/objective/Objective.service';
import { Objective, ObjectiveSearch } from '@/domain/objective/Objective.model';
import { handleError } from '@/utils/api.util';

export const useGetObjectives = (
    objectiveSearchRequest: ObjectiveSearch | undefined,
    { enabled = true }: { enabled?: boolean } = {},
): UseQueryResult<Objective[]> => {
    const [objectives, setObjectives] = useState<Objective[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchObjectives = useCallback(
        async (objectiveSearchRequest: ObjectiveSearch | undefined) => {
            if (!enabled) {
                return;
            }
            if (!objectiveSearchRequest) {
                setIsLoading(false);
                return;
            }

            try {
                const objectives = await searchObjectives(objectiveSearchRequest);
                setObjectives(objectives);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [enabled],
    );

    useDeepCompareEffect(() => {
        fetchObjectives(objectiveSearchRequest).catch(handleError);
    }, [objectiveSearchRequest, fetchObjectives]);

    return {
        data: objectives || [],
        setData: setObjectives,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchObjectives(objectiveSearchRequest),
    };
};

export const useGetObjective = (objectiveId: number): UseQueryResult<Objective> => {
    const [objective, setObjective] = useState<Objective>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchObjective = useCallback(async (objectiveId: number) => {
        try {
            const objective = await getObjective(objectiveId);
            setObjective(objective);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchObjective(objectiveId).catch(handleError);
    }, [objectiveId, fetchObjective]);

    return {
        data: objective,
        setData: setObjective,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchObjective(objectiveId),
    };
};
