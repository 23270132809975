import { UiActionType } from '@/stores/reducers/uiSlice';
import { Alert, AlertTitle, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/stores/store';
import { FC } from 'react';

export const SnackbarWrapper: FC = () => {
    // TODO remove redux from snackbar and use context
    const snackbarState = useAppSelector(state => state.ui.snackbar);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const closeSnackbar = () => {
        dispatch({ type: UiActionType.SNACKBAR_CHANGED, snackbar: { open: false, message: '', severity: 'success' } });
    };
    if (!snackbarState?.open) {
        return <></>;
    }

    return (
        <Snackbar
            open={snackbarState.open}
            onClick={() => closeSnackbar()}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={(_, reason) => {
                if (reason !== 'clickaway') {
                    closeSnackbar();
                }
            }}
        >
            <Alert onClose={() => closeSnackbar()} severity={snackbarState.severity}>
                <AlertTitle>{snackbarState.severity === 'success' ? t('layout.success') : 'Error'}</AlertTitle>
                <Typography>{snackbarState.message}</Typography>
            </Alert>
        </Snackbar>
    );
};
