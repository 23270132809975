import { StoredFile } from '@/components/file-picker/FilePicker';
import { EmployeeSectionFieldMutation } from '@/domain/employee-section/EmployeeSection.model';
import { SectionDefinition, SectionFieldValueType } from '@/domain/section-setting/Section.model';
import { SectionDefinitionFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { Country } from '@/utils/countries.util';
import { deleteWhitespace } from '@/utils/strings.util';

type GetPropertyValueReturnType = Omit<EmployeeSectionFieldMutation, 'sectionFieldDefinitionId' | 'order' | 'employeeReferenceId'> & {
    employeeReferenceId?: number;
};
/**
 * Format the given value to the correct type
 */
const getPropertyValue = (type: SectionFieldValueType, value: unknown): GetPropertyValueReturnType | undefined => {
    switch (type) {
        case 'COUNTRY':
            return { stringValue: (value as Country)?.value };
        case 'STRING':
        case 'PHONE_NUMBER':
        case 'EMAIL':
            return { stringValue: value as string };
        case 'IBAN_NUMBER':
            return { stringValue: deleteWhitespace(value as string) };
        case 'NUMBER':
            return { numberValue: value || value === 0 ? Number(value) : undefined };
        case 'DATE':
            return { dateValue: value as LocalDate };
        case 'EMPLOYEE':
            return { employeeReferenceId: Number(value) };
        case 'CUSTOM_LIST_ITEM':
            return { customListItemReferenceIds: value ? ([value] as number[]) : [] };
        case 'CUSTOM_MULTI_LIST_ITEM':
            return { customListItemReferenceIds: value as number[] };
        case 'SECTION_FIELD_DOCUMENT':
            return { documents: value as StoredFile[] };
        default:
            return undefined;
    }
};

/**
 * Convert the form values to a fields array
 * @param formValues
 * @param sectionDefinition
 * @returns the edited fields
 */
export const convertFormValuesToFields = (formValues: SectionDefinitionFormValues, sectionDefinition: SectionDefinition): EmployeeSectionFieldMutation[] => {
    // We create the row with the fields
    const fields: EmployeeSectionFieldMutation[] = Object.keys(formValues)?.map(key => {
        // we store the field id in the form value name
        const sectionFieldDefinitionId = parseInt(key);

        const sectionFieldDefinition = sectionDefinition.fields.find(field => field.id === sectionFieldDefinitionId);

        if (!sectionFieldDefinition) {
            throw new Error(`Field with id ${sectionFieldDefinitionId} not found`);
        }

        return {
            ...getPropertyValue(sectionFieldDefinition.valueType, formValues[key]),
            sectionFieldDefinitionId,
            order: sectionFieldDefinition.order ?? 0,
            employeeReferenceId: undefined,
        };
    });

    return fields;
};
